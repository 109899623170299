import { QueryKeys } from 'queries/QueryKeys';
import { UIStore } from 'stores/domain/UIStore';
import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UseQueryOptions } from 'react-query';
import { SubmitZegoPaymentResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitZegoPaymentResultV1';
import {
    CheckZegoPaymentCommandV1,
    createCheckZegoPaymentCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/CheckZegoPaymentCommandV1';

export function generateCheckPaymentStatusQueryKey(
    propertyCd: string,
    residentId: number,
    paymentRefId: string,
    paymentRequestId: number,
): (string | number)[] {
    return [QueryKeys.CheckPaymentStatus, propertyCd, residentId, paymentRefId, paymentRequestId];
}

export const useCheckPaymentStatus = (
    propertyCd: string,
    residentId: number,
    paymentRefId: string,
    paymentRequestId: number,
    enabled: boolean,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, SubmitZegoPaymentResultV1>,
) => {
    //make the  query key
    const queryKey = generateCheckPaymentStatusQueryKey(propertyCd, residentId, paymentRefId, paymentRequestId);

    //make the command
    const theCommand = createCheckZegoPaymentCommandV1();
    theCommand.propertyCd = propertyCd;
    theCommand.residentId = residentId;
    theCommand.paymentRefId = paymentRefId;
    theCommand.paymentRequestId = paymentRequestId;

    //call the command
    const query = useCommandQuery<CheckZegoPaymentCommandV1, SubmitZegoPaymentResultV1>(
        theCommand,
        uiStore,
        queryKey,
        queryOptions || {
            enabled: enabled,
        },
    );

    return {
        queryKey,
        query,
    };
};
