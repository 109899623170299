import { UIStore } from 'stores/domain/UIStore';
import { MutationOptions, useMutation } from 'react-query';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { SmarthubResidencyV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';
import { LogResidencyAuditEventResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/LogResidencyAuditEventResultV1';
import { createLogResidencyAuditEventCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/LogResidencyAuditEventCommandV1';
import { ResidencyAuditEventType } from 'models/remotecmds/com/ocs/nirvana/businesslogic/residency/dataobjects/ResidencyAuditEventType';
import Constants from 'expo-constants';

export type ResidencyAuditLog = {
    auditLogId: number | null;
    eventType: ResidencyAuditEventType;
    currentValue: string;
    newValue: string;
};
export const useLogResidencyAuditEventMutation = (
    uiStore: UIStore,
    sessionActiveResidency: SmarthubResidencyV1,
    mutationOptions?: MutationOptions<LogResidencyAuditEventResultV1, any, ResidencyAuditLog>,
) => {
    return useMutation<LogResidencyAuditEventResultV1, any, ResidencyAuditLog>(async (auditLog: ResidencyAuditLog) => {
        const cmd = createLogResidencyAuditEventCommandV1();
        cmd.propertyCd = sessionActiveResidency.propertyCd;
        cmd.residencyId = sessionActiveResidency.residencyId;
        cmd.auditLogId = auditLog.auditLogId;
        cmd.currentValue = auditLog.currentValue;
        cmd.newValue = auditLog.newValue;
        cmd.eventType = auditLog.eventType;
        cmd.sessionId = Constants.sessionId;

        return dispatchCommandAsync(uiStore, cmd, true);
    }, mutationOptions);
};
