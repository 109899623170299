import { RootStore } from '../RootStore';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { action, IObservableArray, makeAutoObservable, observable } from 'mobx';
import { SmarthubTransactionV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionV1';
import { createGetTransactionsCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetTransactionsCommandV1';
import { SmarthubAutopayV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubAutopayV1';
import { GetTransactionsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetTransactionsResultV1';
import { isPaymentTransaction } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionType';
import { v4 as uuid } from 'uuid';

export class TransactionsStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, { startIndx: false, rowLimit: false });
    }

    getSessionActiveResidency() {
        return this.rootStore.userSessionStore.sessionActiveResidency;
    }

    getHasSessionActiveResidency() {
        return this.rootStore.userSessionStore.hasSessionActiveResidency;
    }

    transactions: IObservableArray<SmarthubTransactionV1> = observable<SmarthubTransactionV1>([]);

    autopays: IObservableArray<SmarthubAutopayV1> = observable<SmarthubAutopayV1>([]);

    setTransactions = action((newTransactions: Array<SmarthubTransactionV1>) => {
        if (!this.transactions) {
            this.transactions = observable<SmarthubTransactionV1>([]);
        }
        this.transactions = observable<SmarthubTransactionV1>(this.transactions.concat(newTransactions));
        this.startIndx += this.rowLimit;
    });

    setAutopays = action((newAutopays: Array<SmarthubAutopayV1>) => {
        this.autopays.clear();
        this.autopays = observable<SmarthubAutopayV1>(newAutopays);
    });

    startIndx = 0;
    rowLimit = 10;

    obtainTransactionsFromServer = action(async (forceRefresh?: boolean, reset?: boolean): Promise<void> => {
        const { uiStore } = this.rootStore;
        try {
            if (forceRefresh || (this.transactions.length === 0 && this.getHasSessionActiveResidency())) {
                logInfo(INFO_LOGGER, 'Loading transactions from server ; forceRefresh:', forceRefresh ? 'yes' : 'no');

                if (reset) {
                    this.startIndx = 0;
                    this.transactions.clear();
                }

                const cmd = createGetTransactionsCommandV1();
                cmd.propertyCd = this.getSessionActiveResidency().propertyCd;
                cmd.residencyId = this.getSessionActiveResidency().residencyId;
                cmd.startIndex = this.startIndx;
                cmd.rowLimit = this.rowLimit;

                uiStore.showActivityLoader();
                try {
                    const result: GetTransactionsResultV1 = await dispatchCommandAsync(uiStore, cmd, false);
                    logInfo(INFO_LOGGER, 'Obtained transactions from server: ', JSON.stringify(result.transactions));
                    this.setTransactions(result.transactions);
                } finally {
                    uiStore.hideActivityLoader();
                }
            } else {
                logInfo(INFO_LOGGER, 'Skipping loading transactions');
            }
        } catch (e) {
            logError(ERROR_LOGGER, 'Error getting Transactions:   ', JSON.stringify(e));
            /*uiStore.addToast({
                    toastType: 'error',
                    message: ' Unfortunately we were unable to load your statements and payments',
                });*/
        } finally {
            //nada
        }
    });

    currentTransaction: SmarthubTransactionV1 | null | undefined = null;

    uniquePaymentReferenceId: string = uuid().toString().substring(0, 30);

    setUniquePaymentReferenceId = action(() => {
        this.uniquePaymentReferenceId = uuid().toString().substring(0, 30);
    });

    setCurrentTransaction = action((smarthubTransactionV1: SmarthubTransactionV1 | null | undefined) => {
        this.currentTransaction = smarthubTransactionV1;
    });

    setLatestPaymentAsCurrent = action(() => {
        this.currentTransaction = this.latestNonFailedNonCancelledPayment;
    });

    setLatestStatementAsCurrent = action(() => {
        this.currentTransaction = this.latestStatement;
    });

    setCurrentPaymentByRefNumber = action((refNum: string) => {
        this.currentTransaction = this.paymentsSortedDescending.filter(p => p.referenceNumber === refNum)[0];
    });

    setCurrentStatementByRefNumber = action((refNum: string) => {
        this.currentTransaction = this.statementsSortedDescending.filter(s => s.referenceNumber === refNum)[0];
    });

    get statementsSortedDescending(): Array<SmarthubTransactionV1> {
        return this.transactions
            .filter(t => t.type === 'Statement' || t.type === 'FinalStatement')
            .sort((a, b) =>
                Number(a.period) < Number(b.period) ||
                (b.type === 'FinalStatement' && Number(a.period) === Number(b.period))
                    ? 1
                    : -1,
            ); //final statement wins over regular statement in same period
    }

    get paymentsSortedDescending(): Array<SmarthubTransactionV1> {
        return this.transactions
            .filter(t => isPaymentTransaction(t.type))
            .sort((a, b) => (a.transactionDtt < b.transactionDtt ? 1 : -1));
    }

    get latestStatement(): SmarthubTransactionV1 | null {
        return this.statementsSortedDescending[0];
    }

    get latestNonFailedNonCancelledPayment(): SmarthubTransactionV1 | null {
        return this.paymentsSortedDescending.filter(p => p.status !== 'Failed' && p.status !== 'Cancelled')[0];
    }

    get previousStatements(): Array<SmarthubTransactionV1> {
        return this.statementsSortedDescending.slice(1);
    }
}
