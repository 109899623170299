import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { SmarthubTheme, portalTheme } from 'theme/SmarthubTheme';
import { Portal, Modal as RNPModal } from 'react-native-paper';
import CloseButton from 'components/primitives/CloseButton';

interface DialogProps {
    testID?: string;
    visible?: boolean;
    children?: React.ReactNode;
    onDismiss?: () => void;
    showCloseButton?: boolean;
}

const defaultProps: DialogProps = {};

const Dialog: React.FC<DialogProps> = (props: DialogProps) => {
    const _onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    };

    return (
        <View testID={props.testID}>
            <Portal theme={portalTheme}>
                <RNPModal
                    visible={props.visible === true}
                    onDismiss={props.onDismiss}
                    style={styles.modal}
                    contentContainerStyle={styles.container}>
                    {props.showCloseButton && <CloseButton onClick={props.onDismiss} style={styles.close} />}
                    <View style={styles.dialogWrapper}>{props.children}</View>
                </RNPModal>
            </Portal>
        </View>
    );
};

Dialog.defaultProps = defaultProps;

export default Dialog;

const styles = StyleSheet.create({
    modal: {
        alignItems: 'center',
    },
    container: {
        maxWidth: 400,
        width: Platform.OS === 'web' ? 400 : '94%',
        backgroundColor: SmarthubTheme.colors.white,
        margin: SmarthubTheme.layout.GRIDINCREMENT * 2,
        padding: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    dialogWrapper: {
        width: '100%',
        alignItems: 'center',
    },
    close: {
        alignSelf: 'flex-end',
        paddingVertical: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});
