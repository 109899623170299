import React, { useEffect } from 'react';
import { View, Animated, Easing, StyleSheet, TouchableWithoutFeedback, StyleProp } from 'react-native';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { SmallText } from 'components/primitives/StyledText';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { observer } from 'mobx-react-lite';

interface ToastProps {
    testID?: string;
    toastType?: 'standard' | 'error';
    autoDismiss?: boolean;
    autoDismissInterval?: number;
    message?: string;
    onDismiss?: () => void;
}

const defaultProps: ToastProps = {
    toastType: 'standard',
    autoDismiss: true,
    autoDismissInterval: SmarthubTheme.layout.DEFAULTMESSAGEINTERVAL,
};

const Toast: React.FC<ToastProps> = (props: ToastProps) => {
    const fadeAnim = new Animated.Value(0);
    const slideAnim = new Animated.Value(20);

    const { uiStore } = useStore();

    useEffect(() => {
        fadeIn();

        if (props.autoDismiss) {
            setTimeout(() => {
                _dismissToast();
            }, props.autoDismissInterval);
        }
    }, []);

    const _dismissToast = () => {
        uiStore.dismissToast();

        if (props.onDismiss) {
            props.onDismiss();
        }
    };

    const fadeIn = () => {
        fadeAnim.setValue(0);

        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 250,
            delay: 0,
            easing: Easing.linear,
            useNativeDriver: true,
        }).start();

        slideAnim.setValue(10);

        Animated.timing(slideAnim, {
            toValue: 0,
            duration: 250,
            easing: Easing.linear,
            useNativeDriver: true,
        }).start();
    };

    let toastStyle: StyleProp<any> = props.toastType === 'error' ? [styles.toast, styles.toastError] : styles.toast;
    toastStyle = [
        ...[toastStyle],
        {
            opacity: fadeAnim,
            transform: [
                {
                    translateY: slideAnim,
                },
            ],
        },
    ];
    const toastTextStyle = props.toastType === 'error' ? [styles.toastText, styles.toastTextError] : styles.toastText;
    const closeButtonColor = props.toastType === 'error' ? SmarthubTheme.colors.text : SmarthubTheme.colors.white;

    return (
        <TouchableWithoutFeedback
            testID={props.testID}
            onPress={() => {
                _dismissToast();
            }}>
            <Animated.View style={toastStyle}>
                <SmallText style={toastTextStyle}>{props.message}</SmallText>
                <View style={styles.closeButton}>
                    <MaterialCommunityIcons name='close' size={28} color={closeButtonColor} />
                </View>
            </Animated.View>
        </TouchableWithoutFeedback>
    );
};

Toast.defaultProps = defaultProps;

export default observer(Toast);

const styles = StyleSheet.create({
    toast: {
        width: '96%',
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 2,
        marginLeft: '2%',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: SmarthubTheme.colors.mediumblue,
        backgroundColor: SmarthubTheme.colors.mediumblue,
        color: SmarthubTheme.colors.white,
        shadowColor: SmarthubTheme.colors.dropshadow,
        shadowOpacity: 0.1,
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowRadius: 5,
    },
    toastError: {
        backgroundColor: SmarthubTheme.colors.tagRedFaded,
        borderColor: SmarthubTheme.colors.red,
    },
    toastText: {
        padding: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingRight: SmarthubTheme.layout.GRIDINCREMENT * 6,
        color: SmarthubTheme.colors.white,
    },
    toastTextError: {
        color: SmarthubTheme.colors.text,
    },
    closeButton: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        height: '100%',
        right: SmarthubTheme.layout.GRIDINCREMENT,
        justifyContent: 'center',
        borderColor: SmarthubTheme.colors.border,
        color: SmarthubTheme.colors.white,
    },
});
