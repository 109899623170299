import React, { useEffect } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'react-native-paper';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { globalStyles } from 'theme/style/GlobalStyles';
import { logError } from 'utils/logging/Logger';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import { WebView } from 'react-native-webview';
import { WebViewErrorEvent } from 'react-native-webview/lib/WebViewTypes';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { getRouteParam } from 'utils/routeUtils';
import {
    useMarkLeaseNotificationSeenInLocalStorage,
    useSmarthubNotifications,
} from 'screens/home/requests/NotificationsHooks';

type LeaseNotificationDetailParamList = {
    LeaseNotificationDetail: {
        notificationId: number;
    };
};
type LeaseNotificationDetailScreenRouteProp = RouteProp<LeaseNotificationDetailParamList, 'LeaseNotificationDetail'>;
type LeaseNotificationDetailProps = {
    navigation: StackNavigationProp<any, string>;
    route: LeaseNotificationDetailScreenRouteProp;
};

const LeaseNotificationDetail: React.FC<LeaseNotificationDetailProps> = observer(
    ({ navigation, route }: LeaseNotificationDetailProps) => {
        const theme = useTheme();

        const notificationId: number = getRouteParam(route, 'notificationId') || -1;

        const notificationsQuery = useSmarthubNotifications();
        const notificationToDisplay =
            notificationsQuery.data?.notifications.filter(n => n.notificationId === notificationId)[0] || null;

        useMarkLeaseNotificationSeenInLocalStorage(notificationId);

        if (!notificationToDisplay) return null;

        if (Platform.OS === 'web') {
            return (
                <ScrollView
                    testID={'notification-title'}
                    style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                    <LeaseNotificationHTML notification={notificationToDisplay} />
                </ScrollView>
            );
        } else {
            return (
                <View
                    testID={'notification-title'}
                    style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                    <LeaseNotificationHTML notification={notificationToDisplay} />
                </View>
            );
        }
    },
);

type LeaseNotificationHTMLProps = {
    notification: SmarthubNotificationV1;
};
const LeaseNotificationHTML: React.FC<LeaseNotificationHTMLProps> = ({ notification }: LeaseNotificationHTMLProps) => {
    const { windowHeight } = useResponsiveContext();
    const loadIframeHTML = () => {
        if (Platform.OS !== 'web') return;
        const iframe: HTMLIFrameElement | null = document.getElementById('iframe') as HTMLIFrameElement;
        if (iframe.contentDocument) iframe.contentDocument.body.innerHTML = notification.notification;
    };
    useEffect(() => {
        if (Platform.OS === 'web') loadIframeHTML(), [];
    });
    return (
        <>
            {Platform.OS === 'web' && (
                <iframe
                    title='notification_frame'
                    id={'iframe'}
                    height={windowHeight}
                    onLoad={() => {
                        loadIframeHTML();
                    }}
                    width={'100%'}
                    marginHeight={0}
                    marginWidth={0}
                    frameBorder={0}
                    loading={'eager'}
                />
            )}
            {Platform.OS !== 'web' && (
                <WebView
                    scalesPageToFit={true}
                    originWhitelist={['*']}
                    contentMode={'mobile'}
                    source={{ html: notification.notification }}
                    onError={(event: WebViewErrorEvent) => {
                        logError(
                            ERROR_LOGGER,
                            'Error loading lease notification. Close it and try again.',
                            JSON.stringify(event.nativeEvent),
                        );
                    }}
                />
            )}
        </>
    );
};

export default LeaseNotificationDetail;
