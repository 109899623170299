import * as React from 'react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { WebView } from 'react-native-webview';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getRouteParam } from 'utils/routeUtils';
import { MediumBoldText } from 'components/primitives/StyledText';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import {
    ShouldStartLoadRequest,
    WebViewErrorEvent,
    WebViewHttpErrorEvent,
} from 'react-native-webview/lib/WebViewTypes';
import { globalStyles } from 'theme/style/GlobalStyles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ConfirmationType } from 'screens/home/transactions/PaymentConfirmation';
import { navigationRoutes } from 'utils/NavigationUtils';

export type ZegoScreen = 'onetime' | 'autopay' | 'autopay_payment' | 'login' | 'accounts' | 'addcc' | 'addbank';

export const PAYLEASE_BASE_URL = (Constants.expoConfig?.extra && Constants.expoConfig.extra['payleaseBaseUrl']) || '';

const PaymentScreen: React.FC = observer(() => {
    const { userSessionStore, uiStore } = useStore();
    const { sessionActiveResidency, hasSessionActiveResidency } = userSessionStore;
    const theme = useTheme();
    const navigation = useNavigation();
    const { useDrawerNavigation } = useResponsiveContext();
    const route = useRoute();

    //the desired final destination is passed in...
    const navigateTo: ZegoScreen = getRouteParam(route, 'navigateTo') || 'login';

    //state variable 1 - the url to push the iframe/webview to
    const [navigateToUrl, setNavigateToUrl] = useState('');

    const _getNavigateToUrl = (navto: ZegoScreen) => {
        logInfo(INFO_LOGGER, 'Full Zego path requested for ', navto);

        let navigateToPath = '';

        switch (navto) {
            case 'login':
                navigateToPath = sessionActiveResidency.payleaseLoginLink;
                break;
            //the below endpoints we need to login then forward the frame ourselves as zego doesn't provide a GOTO for them
            case 'onetime':
                navigateToPath = sessionActiveResidency.payleaseOneTimeLink;
                break;
            case 'autopay':
                navigateToPath = sessionActiveResidency.payleaseAutoPayLink;
                break;
            case 'autopay_payment':
                navigateToPath = sessionActiveResidency.payleaseAutoPayPaymentLink;
                break;
        }

        /*
            //the below endpoints are not accessible through a GOTO parameter
            //once logged in we could navigate manually to these
            case 'addautopay':
              navigateToPath = '/resident/create_variable_autopay/payment_schedule';
              break;*!/
            case 'accounts':
                navigateToPath = '/resident/accounts';
                break;
            case 'addcc':
                navigateToPath = '/resident/accounts/add_credit_card';
                break;
            case 'addbank':
                navigateToPath = '/resident/accounts/add_bank_account';
                break;
        }*/

        const pathToReturn = PAYLEASE_BASE_URL + navigateToPath + '&vpw=400'; //vpw is critical to trigger correct css breakpoint
        logInfo(INFO_LOGGER, 'Providing zego path to ', pathToReturn);
        setNavigateToUrl(pathToReturn);
    };

    useEffect(() => {
        if (hasSessionActiveResidency) {
            uiStore.showActivityLoader();
            _getNavigateToUrl(navigateTo);
        }
    }, [navigateTo, hasSessionActiveResidency]);

    //may need to do this... https://medium.com/@caphun/reactnative-why-your-webview-disappears-inside-scrollview-c6057c9ac6dd

    //for mobile ONLY
    const listenToRedirectsInWebView = (event: ShouldStartLoadRequest) => {
        const prefix = Linking.makeUrl('/');
        logInfo(INFO_LOGGER, 'Scheme is: ', prefix);
        logInfo(INFO_LOGGER, 'WebView is navigating to: ', event.url);

        const url = event.url.toString();

        //if the url is trying to go back to our website or our app then we hijack and
        if (
            event.url &&
            (url.startsWith(prefix) ||
                url.startsWith('http://exp//') ||
                url.startsWith('http://smarthub//') ||
                url.startsWith('http://smarthub-'))
        ) {
            //TODO make this more sophisticated later... for now really we only want to handle when they
            //      are forwarding the user to our payment confirmation screen
            //Linking.openURL(event.url);
            /* this is what they send back:
            http://exp//127.0.0.1:19001/--/payment_confirmation?
            transaction_id=29471176&transaction_date=2021-02-10+10%3A37%3A45&
            transaction_type=ACH&unit_amount=2.00&total_amount=2.00&fee_amount=0.00&cc_type=&last_four=1103&resident_id=R.FLFS.GAB.CION.99351609222066733&property_id=1073&ipn_custom=&status=SUCCESS&hmac=07a4a381dfc21fd24a0a9bae9b2a710bd0faec00e878c93deedbdd79f259901c
            */
            if (url.indexOf('payment_confirmation') > -1) {
                logInfo(INFO_LOGGER, 'Smarthub will handle...', url);
                const wasAch = url.indexOf('transaction_type=ACH') > -1;
                logInfo(INFO_LOGGER, 'Was it ach? ', wasAch);
                const autoPayConfirm = url.indexOf('confirmationType=AutoPaySetupPending') > -1;
                proceedToConfirmation(wasAch, (autoPayConfirm && 'AutoPaySetupPending') || undefined);
            }
            return false;
        } else {
            logInfo(INFO_LOGGER, 'Webview should handle...');
            return true;
        }
    };

    const proceedToConfirmation = (wasAch: boolean, confirmationType?: ConfirmationType) => {
        logInfo(INFO_LOGGER, 'proceeding to payment confirmationscreen...');
        //TODO pass params that Zego passed back
        const routeParams = {
            confirmationType: confirmationType || (wasAch ? 'PaymentBeingProcessed' : 'PaymentReceived'),
        };
        if (useDrawerNavigation) {
            navigation.navigate(navigationRoutes.homeRoutes.paymentConfirmation, routeParams);
        } else {
            navigation.navigate(navigationRoutes.loggedInRoutes.paymentConfirmationModal, routeParams);
        }
    };

    const zegoIframe: RefObject<HTMLIFrameElement> = useRef<HTMLIFrameElement | null>(null);
    const zegoIframeHeight = 1400;

    // Reset iframe height to bring scroll Y to 0
    const resizeFrame = () => {
        if (zegoIframe && zegoIframe.current) {
            zegoIframe.current.height = '0';

            setTimeout(() => {
                if (zegoIframe && zegoIframe.current) {
                    zegoIframe.current.height = zegoIframeHeight.toString();
                }
            }, 10);
        }
    };

    const amountLocked: boolean =
        (sessionActiveResidency.payleaseLoginLink &&
            sessionActiveResidency.payleaseLoginLink.indexOf('lock_amount=Y') > -1) ||
        false;

    let subheaderBlurb = `Your total balance is ${formatCentsAsDollars(sessionActiveResidency?.currentBalance || 0)}.${
        (amountLocked && ' The payment amount cannot be changed.') || ''
    }${(!amountLocked && ' How much will you be paying?') || ''}`;

    if (navigateTo === 'autopay') {
        subheaderBlurb = '';
    }

    const showHeader = navigateTo === 'onetime';

    const PaymentPresenter: React.FC = useCallback(() => {
        const [showFooter, setShowFooter] = useState(true);
        const handleKeyboardDidShow = () => {
            setShowFooter(false);
        };

        const handleKeyboardDidHide = () => {
            setShowFooter(true);
        };
        return (
            <KeyboardAwareScrollView
                onKeyboardDidShow={() => {
                    handleKeyboardDidShow();
                }}
                onKeyboardDidHide={() => {
                    handleKeyboardDidHide();
                }}
                enableResetScrollToCoords={false}
                /*keyboardDismissMode={Platform.OS==='web'?'none':'on-drag'}*/
                contentContainerStyle={Platform.OS !== 'web' ? [globalStyles.fullheightwidthpct] : {}}>
                {showHeader && (
                    <View style={[styles.totalblurb, globalStyles.doubleSpaceBelow]}>
                        <MediumBoldText>{subheaderBlurb}</MediumBoldText>
                    </View>
                )}

                <>
                    {Platform.OS === 'web' && (
                        <>
                            <iframe
                                title='zego_frame'
                                ref={zegoIframe}
                                src={navigateToUrl}
                                frameBorder={0}
                                loading={'eager'}
                                onLoadStart={() => uiStore.showActivityLoader()}
                                onLoad={() => {
                                    logInfo(INFO_LOGGER, ' in the load end of the iframe');
                                    resizeFrame();
                                    uiStore.hideActivityLoader();
                                }}
                                height={zegoIframeHeight}
                                sandbox='allow-top-navigation allow-scripts allow-forms allow-same-origin allow-modals allow-popups'
                            />
                        </>
                    )}

                    {/*change so the onloadend sets a logged in setting when the page loaded was the login page*/}
                    {Platform.OS !== 'web' && (
                        <>
                            <WebView
                                scalesPageToFit={true}
                                originWhitelist={['*']}
                                contentMode={'mobile'}
                                onLoadEnd={() => {
                                    logInfo(INFO_LOGGER, ' in the load end of the webview');
                                    uiStore.hideActivityLoader();
                                }}
                                source={{ uri: navigateToUrl }}
                                onShouldStartLoadWithRequest={listenToRedirectsInWebView}
                                onHttpError={(event: WebViewHttpErrorEvent) => {
                                    logError(
                                        ERROR_LOGGER,
                                        'Network Error loading payment screen. Close it and try again.',
                                        JSON.stringify(event.nativeEvent),
                                    );
                                }}
                                onError={(event: WebViewErrorEvent) => {
                                    logError(
                                        ERROR_LOGGER,
                                        'Error loading payment screen. Close it and try again.',
                                        JSON.stringify(event.nativeEvent),
                                    );
                                }}
                            />
                        </>
                    )}
                </>
                {showFooter && (
                    <Image source={require('../../../../assets/images/zego.png')} style={[styles.zegoPayleaseLogo]} />
                )}

                {/*NEXT PHASE WHEN WE MAKE OUR OWN UI FOR PAYMENTS
                <View style={globalStyles.doubleSpaceAbove}>
                    <Container borderStyle={'orange'}>
                        <View style={[styles.ccfeewarning]}>
                            <MaterialCommunityIcons
                                name='alert-circle'
                                size={SmarthubTheme.layout.LARGEICONSIZE}
                                color={SmarthubTheme.colors.orange}
                            />
                            <RegularText style={globalStyles.doubleSpaceAbove}>
                                Please note there is a $20.95 processing fee on
                                every payment made with a credit card and a $4.95
                                processing fee with a debit card. To avoid fees,
                                pay with a bank account.
                            </RegularText>
                        </View>
                    </Container>
                </View>*/}

                {/*TODO future...
                <View style={styles.notes}>
                    <RegularBoldText>Payment Notes</RegularBoldText>
                </View>*/}
            </KeyboardAwareScrollView>
        );
    }, [showHeader, navigateToUrl, subheaderBlurb]);

    if (navigateToUrl) {
        if (Platform.OS === 'web') {
            return (
                // <ScrollView style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                //     <PaymentPresenter />
                // </ScrollView>
                <View style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                    <PaymentPresenter />
                </View>
            );
        } else {
            return (
                <View style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                    <PaymentPresenter />
                </View>
            );
        }
    } else return null;
});

export default PaymentScreen;

const styles = StyleSheet.create({
    totalblurb: {},
    zegoPayleaseLogo: {
        height: 50,
        width: 138,
        alignSelf: 'center',
    },
});
