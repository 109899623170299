export const FontConfig = {
    default: {
        regular: {
            fontFamily: 'Roboto_400Regular',
            fontWeight: 'normal',
        },
        medium: {
            fontFamily: 'Roboto_700Bold',
            fontWeight: 'normal',
        },
        light: {
            fontFamily: 'Roboto_300Light',
            fontWeight: 'normal',
        },
        thin: {
            fontFamily: 'Roboto_100Thin',
            fontWeight: 'normal',
        },
    },
};
