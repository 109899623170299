import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { SmallerText } from 'components/primitives/StyledText';
import { StyleProp } from 'react-native';
import { useStore } from 'contexts/StoreContextProvider';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { testProps } from 'components/ComponentTypes';

type LeaseSwitchLinkProps = {
    labelText?: string;
    labelStyle?: StyleProp<any>;
};
const LeaseSwitchLink: React.FC<LeaseSwitchLinkProps> = observer(({ labelText, labelStyle }) => {
    const navigation = useNavigation();
    const { smarthubUser, hasSessionActiveResidency, sessionActiveResidency } = useStore().userSessionStore;
    if (smarthubUser.authorizedResidencies.length < 2) return null;
    const { useDrawerNavigation } = useResponsiveContext();

    return (
        <Button
            {...testProps('switch-property')}
            mode={'text'}
            icon={'sync'}
            uppercase={false}
            compact={true}
            labelStyle={labelStyle}
            onPress={() => {
                if (
                    (hasSessionActiveResidency && sessionActiveResidency.statusCd === 'Leased') ||
                    !useDrawerNavigation
                ) {
                    navigation.navigate('LeaseSelectorModal');
                } else {
                    navigation.navigate('Home', { screen: 'LeaseSelector' });
                }
            }}
        >
            {!labelStyle && <SmallerText style={labelStyle}>{labelText || 'Switch'}</SmallerText>}
            {labelStyle && (labelText || 'Switch')}
        </Button>
    );
});

export default LeaseSwitchLink;
