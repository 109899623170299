import React from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';
import Hyperlink from 'components/primitives/Hyperlink';

interface AnchorProps {
    href: string;
    onPress?: () => void;
    linkColor?: string;
    useBrowser?: boolean;
    newTab?: boolean;
    children: React.ReactNode;
}

const Anchor: React.FC<AnchorProps> = ({ useBrowser = true, ...props }) => {
    function _handlePress() {
        if (useBrowser) {
            if (props.newTab && window) {
                return window.open(props.href, 'blank');
            } else {
                WebBrowser.openBrowserAsync(props.href);
            }
        } else {
            Linking.openURL(props.href);
        }
        props.onPress && props.onPress();
    }

    return (
        <Hyperlink {...props} onClick={_handlePress}>
            {props.children}
        </Hyperlink>
    );
};

/**
 * Usage:
 *
 * <Anchor href="https://google.com">Go to Google</Anchor>
 * <Anchor href="mailto:support@expo.io">Email support</Anchor>
 */
export default Anchor;
