import React, { useEffect } from 'react';
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { Heading2, RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { groupBy } from 'utils/ArrayUtils';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { useNavigation } from '@react-navigation/native';
import { SmarthubResidencyV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';
import { globalStyles } from 'theme/style/GlobalStyles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { navigationRoutes } from 'utils/NavigationUtils';
import { testProps } from 'components/ComponentTypes';

const LeaseSelector: React.FC = observer(() => {
    const { userSessionStore } = useStore();
    const { smarthubUser, hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const navigation = useNavigation();

    useEffect(() => {
        if (!hasSessionActiveResidency) {
            navigation.setOptions({
                headerRight: undefined,
            });
        }
    }, [hasSessionActiveResidency, sessionActiveResidency, smarthubUser]);

    const listData: Array<{ title: string; data: Array<SmarthubResidencyV1> }> = [];

    const groupedResidencies = groupBy(smarthubUser.authorizedResidencies.slice(), 'propertyName');
    listData.length = 0;
    for (const group in groupedResidencies) {
        listData.push({ title: group, data: groupedResidencies[group] });
    }
    if (listData.length === 0) return null;

    return (
        <SafeAreaView style={[globalStyles.justflex1, { backgroundColor: SmarthubTheme.colors.background }]}>
            <KeyboardAwareScrollView>
                <View style={styles.innercontainer}>
                    <Heading2 {...testProps('page-header')} style={globalStyles.singleSpaceBelow}>
                        Select a lease to view below:
                    </Heading2>

                    {listData.map(property => {
                        return (
                            <View
                                {...testProps('property-header')}
                                key={property.title}
                                style={globalStyles.sectionSpaceBelow}
                            >
                                <Heading2 style={[globalStyles.sectionSpaceAround]} key={property.title}>
                                    {property.title}
                                </Heading2>
                                {property.data.map(residency => {
                                    return (
                                        <TouchableOpacity
                                            key={residency.key}
                                            onPress={() => {
                                                logInfo(INFO_LOGGER, 'user selected residency is now', residency.key);
                                                userSessionStore
                                                    .setSessionActiveResidencyByKey(residency.key, true)
                                                    .finally(() => {
                                                        setTimeout(() => {
                                                            navigation.reset({
                                                                routes: [
                                                                    {
                                                                        name: navigationRoutes.loggedInRoutes.loggedIn,
                                                                        params: { fromLeaseSelector: true },
                                                                    },
                                                                ],
                                                            });
                                                        }, 500);
                                                    });
                                            }}
                                        >
                                            <View
                                                {...testProps(
                                                    residency.propertyCd +
                                                        '-' +
                                                        residency.bldgCd +
                                                        '-' +
                                                        residency.unitCd,
                                                )}
                                                key={residency.key}
                                                style={[globalStyles.singleSpaceAround]}
                                            >
                                                <ResidencyItem key={residency.key} residency={residency} />
                                            </View>
                                        </TouchableOpacity>
                                    );
                                })}
                            </View>
                        );
                    })}
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
});

const ResidencyItem = observer(({ residency }: { residency: SmarthubResidencyV1 }) => {
    return (
        <View style={[styles.residencyitem]}>
            <View style={styles.stackedleaseAdjustmentMessages}>
                <RegularBoldText>{`${residency.adminYn ? '(Admin) ' : ''}Apartment ${residency.bldgCd}-${
                    residency.unitCd
                }`}</RegularBoldText>
                {null != residency.leaseAdjustmentMessage && (
                    <RegularText>{residency.leaseAdjustmentMessage}</RegularText>
                )}
                {null != residency.leaseAdjustmentMessage2 && (
                    <RegularText>{residency.leaseAdjustmentMessage2}</RegularText>
                )}
            </View>
        </View>
    );
});

export default LeaseSelector;

const styles = StyleSheet.create({
    container: {
        padding: SmarthubTheme.layout.SURROUNDMARGIN * 2,
    },
    innercontainer: {
        padding: SmarthubTheme.layout.INNERPAD,
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 6,
    },
    residencyitem: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        //marginTop: SmarthubTheme.layout.SURROUNDMARGIN,
        padding: SmarthubTheme.layout.INNERPAD,
        height: 100,
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.lightgrey,
    },
    selectedresidencyitem: {
        backgroundColor: SmarthubTheme.colors.selected,
        borderColor: SmarthubTheme.colors.selectedborder,
        borderStyle: 'solid',
        borderWidth: 1,
    },
    stackedleaseAdjustmentMessages: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
});
