import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { ZegoScreen } from './PaymentScreen';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useTheme } from 'react-native-paper';
import { RegularText } from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';

const PaymentPOC: React.FC = observer(() => {
    const theme = useTheme();
    const [zegoPage, setZegoPage] = useState<ZegoScreen>('login');
    const { hasSessionActiveResidency } = useStore().userSessionStore;

    if (!hasSessionActiveResidency)
        return (
            <KeyboardAwareScrollView
                contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
                <View style={[styles.container, { backgroundColor: theme.colors.background }]}>
                    <RegularText>No lease selected</RegularText>
                </View>
            </KeyboardAwareScrollView>
        );

    return (
        <KeyboardAwareScrollView
            resetScrollToCoords={{ x: 0, y: 0 }}
            contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
            {/*<View style={styles.paymentButtons}>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('onetime')}>
                    <ButtonText>Pay Now</ButtonText>
                </TouchableOpacity>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('addautopay')}>
                    <ButtonText>Add Autopay</ButtonText>
                </TouchableOpacity>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('accounts')}>
                    <ButtonText>Manage Accounts</ButtonText>
                </TouchableOpacity>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('autopays')}>
                    <ButtonText>Manage Autopay</ButtonText>
                </TouchableOpacity>
            </View>*/}
            {/*<View style={styles.paymentButtons}>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('onetime')}>
                    <ButtonText>Pay Now</ButtonText>
                </TouchableOpacity>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('addautopay')}>
                    <ButtonText>Add Autopay</ButtonText>
                </TouchableOpacity>
            </View>
            <View style={styles.paymentButtons}>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('addcc')}>
                    <ButtonText>Add Credit Card</ButtonText>
                </TouchableOpacity>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('addbank')}>
                    <ButtonText>Add Bank</ButtonText>
                </TouchableOpacity>
            </View>
            <View style={styles.paymentButtons}>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('accounts')}>
                    <ButtonText>Manage Accounts</ButtonText>
                </TouchableOpacity>
                <TouchableOpacity style={styles.paymentOptionButton} onPress={() => setZegoPage('autopays')}>
                    <ButtonText>Manage Autopay</ButtonText>
                </TouchableOpacity>
            </View>*/}
            <View style={styles.zegoFrame}>{/*<PaymentScreen navigateTo={zegoPage} />*/}</View>
        </KeyboardAwareScrollView>
    );
});
export default PaymentPOC;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    orangeButton: {
        height: 45,
        width: 100,
        justifyContent: 'center',
        backgroundColor: SmarthubTheme.colors.orange,
    },
    paymentButtons: {
        flexDirection: 'row',
        height: 25,
        width: '100%',
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN,
        justifyContent: 'space-around',
    },
    paymentOptionButton: {
        justifyContent: 'center',
        backgroundColor: SmarthubTheme.colors.orange,
        width: 150,
    },
    zegoFrame: {
        width: '100%',
        height: '100%',
        backgroundColor: SmarthubTheme.colors.white,
    },
});
