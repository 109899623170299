import { useEffect, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import useIsForeground from 'utils/useIsForeground';

const useBackOnDisplay = (onForeground: boolean, onFocus: boolean) => {
    const isFocused = useIsFocused();
    const hasComeToForeground = useIsForeground();
    const [isBackOnDisplay, setIsBackOnDisplay] = useState(true);

    useEffect(() => {
        if (onFocus) {
            setIsBackOnDisplay(isFocused);
        }
    }, [isFocused]);

    useEffect(() => {
        if (onForeground) {
            setIsBackOnDisplay(hasComeToForeground && isFocused);
        }
    }, [hasComeToForeground]);

    return isBackOnDisplay;
};

export default useBackOnDisplay;
