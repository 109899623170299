import { RootStore } from '../RootStore';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { action, IObservableArray, makeAutoObservable, observable } from 'mobx';
import { GetResidentProfileResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentProfileResultV1';
import { createGetResidentProfileCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentProfileCommandV1';
import {
    createSmarthubResidentV1,
    SmarthubResidentV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentV1';
import { SmarthubResidentNotificationPreferenceV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentNotificationPreferenceV1';
import { createGetResidentNotificationPreferencesCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentNotificationPreferencesCommandV1';
import { GetResidentNotificationPreferencesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentNotificationPreferencesResultV1';
import { AlertOption } from 'screens/more/appsettings/AlertsAndNotifications';
import { createUpdateResidentNotificationPreferencesCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/UpdateResidentNotificationPreferencesCommandV1';
import { EmptyVersionedRemoteResult } from 'models/remotecmds/com/ocs/remotecmd/versioned/EmptyVersionedRemoteResult';
import { createViewResidentEmailCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/ViewResidentEmailCommandV1';
import { ViewResidentEmailResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/ViewResidentEmailResultV1';
import * as WebBrowser from 'expo-web-browser';

type LoadedStatus = 'LOADING' | 'LOADED' | 'ERROR';

export class ResidentStore {
    rootStore: RootStore;

    residentInfo: SmarthubResidentV1 = createSmarthubResidentV1();
    guarantors: SmarthubResidentV1[] = [];
    primaryEmail: string | null = null;
    primaryPhone: string | null = null;
    secondaryPhone: string | null = null;
    residentInfoLoadedStatus: LoadedStatus = 'LOADING';

    notificationPreferences: IObservableArray<SmarthubResidentNotificationPreferenceV1> =
        observable<SmarthubResidentNotificationPreferenceV1>([]);
    setNotificationPreferences = action((newPreferences: Array<SmarthubResidentNotificationPreferenceV1>) => {
        this.notificationPreferences.replace(newPreferences);
    });

    turnEmailStatementsOn = action((option: AlertOption, enabled: boolean) => {
        const foundPreference = this.notificationPreferences.filter(p => p.type === 'Statement')[0];
        if (null != foundPreference) {
            foundPreference.email = true;
        }
        //update on server too!
        this.updateAlertOptionOnServer(option, enabled);
    });

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    getSessionActiveResidency() {
        return this.rootStore.userSessionStore.sessionActiveResidency;
    }

    setResidentInfoLoadedStatus = action((status: LoadedStatus) => (this.residentInfoLoadedStatus = status));

    setResidentInfo = action((residentInfo: SmarthubResidentV1) => (this.residentInfo = residentInfo));

    setGuarantors = action((guarantors: SmarthubResidentV1[]) => {
        this.guarantors = guarantors.slice();
    });

    viewEmailExternalLinkAsync = async (emailLogInfo: string): Promise<void> => {
        try {
            this.rootStore.uiStore.showActivityLoader();
            const cmd = createViewResidentEmailCommandV1();
            cmd.propertyCd = this.getSessionActiveResidency().propertyCd;
            cmd.residentId = this.getSessionActiveResidency().residentId;
            cmd.emailLogId = Number(emailLogInfo);

            const result: ViewResidentEmailResultV1 = await dispatchCommandAsync(this.rootStore.uiStore, cmd, false);

            if (result) {
                await WebBrowser.openBrowserAsync(result.secureHttpUrl);
            }
        } catch (e) {
            logError(ERROR_LOGGER, 'Error getting view email link from server:   ', JSON.stringify(e));
        } finally {
            this.rootStore.uiStore.hideActivityLoader();
        }
    };

    getNotificationPreferencesFromServer = action(async (): Promise<void> => {
        try {
            const cmd = createGetResidentNotificationPreferencesCommandV1();
            cmd.propertyCd = this.getSessionActiveResidency().propertyCd;
            cmd.residentId = this.getSessionActiveResidency().residentId;

            const result: GetResidentNotificationPreferencesResultV1 = await dispatchCommandAsync(
                this.rootStore.uiStore,
                cmd,
                false,
            );

            if (result) {
                if (result.preferences) {
                    logInfo(INFO_LOGGER, 'Obtained Notification Preferences from server: ', JSON.stringify(result));
                    this.setNotificationPreferences(result.preferences);
                } else {
                    logError(
                        ERROR_LOGGER,
                        'Could not find matching Notification Preferences on server:   ',
                        JSON.stringify(result),
                    );
                }
            }
        } catch (e) {
            logError(ERROR_LOGGER, 'Error getting Notification Preferences from server:   ', JSON.stringify(e));
        } finally {
            logInfo(INFO_LOGGER, 'Finished getting Notification Preferences from server');
        }
    });

    updateAlertOptionOnServer = action(async (option: AlertOption, enabled: boolean): Promise<void> => {
        option.enabled = enabled;
        try {
            logInfo(
                INFO_LOGGER,
                'Update the preference in mobx and then post to server; sending...',
                JSON.stringify(this.notificationPreferences),
            );

            const foundPreference = this.notificationPreferences.filter(
                preference => preference.type === option.type,
            )[0];
            if (foundPreference) {
                if (option.contactMethod === 'email') {
                    foundPreference.email = enabled;
                } else if (option.contactMethod === 'text') {
                    foundPreference.text = enabled;
                } else if (option.contactMethod === 'push') {
                    foundPreference.push = enabled;
                } else if (option.contactMethod === 'paperless') {
                    foundPreference.paperless = enabled;
                }
                //now update the server
                logInfo(
                    INFO_LOGGER,
                    'Saving user preference that alerts for ' +
                        option.title +
                        ' are now ' +
                        (enabled ? 'enabled' : 'disabled'),
                );
                logInfo(INFO_LOGGER, 'Preference to save is ', JSON.stringify(foundPreference));

                const cmd = createUpdateResidentNotificationPreferencesCommandV1();
                cmd.propertyCd = this.getSessionActiveResidency().propertyCd;
                cmd.residentId = this.getSessionActiveResidency().residentId;
                cmd.preferences = this.notificationPreferences;

                const result: EmptyVersionedRemoteResult = await dispatchCommandAsync(
                    this.rootStore.uiStore,
                    cmd,
                    false,
                );

                if (result) {
                    logInfo(INFO_LOGGER, 'Notification Preferences updated: ', JSON.stringify(result));
                }
            }
        } catch (e) {
            logError(ERROR_LOGGER, 'Error updating notification preferences:   ', JSON.stringify(e));
        } finally {
            logInfo(INFO_LOGGER, 'All done updating notification preference: ', JSON.stringify(option));
        }
    });
}
