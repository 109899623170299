import React from 'react';
import { View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { RegularText } from 'components/primitives/StyledText';
import Section from 'components/primitives/Section';
import { globalStyles } from 'theme/style/GlobalStyles';

type AddressProps = {
    streetLine1: string;
    city: string;
    state: string;
    zip: string;
};

const Address: React.FC<AddressProps> = observer((props: AddressProps) => {
    return (
        <View>
            <RegularText>{props.streetLine1}</RegularText>
            <RegularText>
                {props.city}, {props.state} {props.zip}
            </RegularText>
        </View>
    );
});

export default Address;
