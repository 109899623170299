import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Platform, Text, TextProps, View } from 'react-native';
import Hyperlink from 'components/primitives/Hyperlink';
import { globalStyles } from 'theme/style/GlobalStyles';
import { SmarthubTypography } from 'theme/typography/SmarthubTypography';
import { testProps } from 'components/ComponentTypes';

interface LineLimitedTextProp extends TextProps {
    text: string;
    expandable?: boolean;
}

const LineLimitedText: React.FC<LineLimitedTextProp> = observer((props: LineLimitedTextProp) => {
    const characterPerline = 50;
    const maxCharToShow = characterPerline * (props.numberOfLines || 10000);
    const textLongerThanMax = props.text.length > maxCharToShow;
    const truncatedText = textLongerThanMax ? props.text.slice(0, maxCharToShow) + '...' : props.text;

    const [text, setText] = useState(Platform.OS === 'web' ? truncatedText : props.text);
    const [fullTextShown, setFullTextShown] = useState(false);
    const [showButton, setShowButton] = useState(Platform.OS === 'web' ? textLongerThanMax : false);
    const [numberOfLines, setNumberOfLines] = useState(Platform.OS === 'web' ? undefined : props.numberOfLines);
    const toggleFullText = () => {
        setNumberOfLines(Platform.OS === 'web' ? undefined : fullTextShown ? props.numberOfLines : undefined);
        if (Platform.OS === 'web') {
            if (fullTextShown && textLongerThanMax) {
                setText(truncatedText);
            } else {
                setText(props.text);
            }
        }
        setFullTextShown(!fullTextShown);
    };

    useEffect(() => {
        if (Platform.OS === 'web') {
            if (fullTextShown && textLongerThanMax) {
                setText(truncatedText);
            } else {
                setText(props.text);
            }
        } else {
            setText(props.text);
        }
    }, [props.text]);

    return (
        <View {...testProps('generic-line-limited-text')}>
            <Text
                {...props}
                {...testProps('show-less')}
                style={[SmarthubTypography.regular, props.style]}
                onTextLayout={e => {
                    setShowButton(props.numberOfLines ? e.nativeEvent.lines.length >= props.numberOfLines : false);
                }}
                numberOfLines={numberOfLines}>
                {text}
            </Text>
            {props.expandable && showButton && (
                <Hyperlink
                    {...testProps('show-more')}
                    onClick={toggleFullText}
                    style={[globalStyles.alignSelfFlexEnd, globalStyles.singleSpaceAbove]}>
                    {fullTextShown ? 'Show Less' : 'Show More'}
                </Hyperlink>
            )}
        </View>
    );
});

export default LineLimitedText;
