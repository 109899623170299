import React, { useState } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { RegularBoldText, RegularText } from 'components/primitives/StyledText';
import Tag from 'components/primitives/Tag';
import { TagTypes } from 'components/primitives/TagTypes';
import Button from 'components/primitives/Button';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import InputString from 'components/input/InputString';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useStore } from 'contexts/StoreContextProvider';
import * as WebBrowser from 'expo-web-browser';
import { useCommandMutation } from 'queries/hooks/useCommandQuery';
import {
    createSendSmarthubContractSigningEmailCommandV1,
    SendSmarthubContractSigningEmailCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/contract/SendSmarthubContractSigningEmailCommandV1';
import { EmptyVersionedRemoteResult } from 'models/remotecmds/com/ocs/remotecmd/versioned/EmptyVersionedRemoteResult';
import { SmarthubContractSignerV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/contract/SmarthubContractSignerV1';
import { useGetContractSigningUrlQuery } from 'queries/hooks/contract/useGetContractSigningUrlQuery';
import { useQueryClient } from 'react-query';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { globalStyles } from 'theme/style/GlobalStyles';

const schema = yup.object().shape({
    email: yup.string().email(),
});

interface formData {
    email: string;
}

interface RenewalSigningScreenResidentProps {
    signer: SmarthubContractSignerV1;
    showSignNowButton: boolean;
}

const RenewalSigningScreenResident: React.FC<RenewalSigningScreenResidentProps> = observer(
    ({ signer, showSignNowButton }: RenewalSigningScreenResidentProps) => {
        const { userSessionStore, uiStore } = useStore();
        const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
        const { useDrawerNavigation } = useResponsiveContext();
        const [showResendForm, setShowResendForm] = useState<boolean>(false);
        const [resendEmail, setResendEmail] = useState<boolean>(false);
        const sendEmailMutation = useCommandMutation<
            SendSmarthubContractSigningEmailCommandV1,
            EmptyVersionedRemoteResult
        >(uiStore);

        const { queryKey: getSigningUrlQueryKey, queryResult: getSigningUrlResult } = useGetContractSigningUrlQuery(
            sessionActiveResidency.propertyCd,
            sessionActiveResidency.residencyId,
            signer.viewId,
            uiStore,
            {
                enabled: false,
                refetchOnWindowFocus: false,
                retry: false,
                onSuccess: data => {
                    if (data?.signingUrl) {
                        if (Platform.OS === 'web') {
                            window.open(data?.signingUrl, '_blank');
                        } else {
                            WebBrowser.openBrowserAsync(data?.signingUrl);
                        }
                    }
                },
                onSettled: () => {
                    uiStore.hideActivityLoader();
                },
            },
        );

        const formik = useFormik({
            validateOnMount: false,
            validateOnChange: false,
            validateOnBlur: false,
            initialValues: {
                email: signer.email,
            },
            validationSchema: schema,
            onSubmit: values => {
                submitResendForm(values);
            },
        });

        const submitResendForm = (values: formData) => {
            const mutateCmd = createSendSmarthubContractSigningEmailCommandV1();
            mutateCmd.propertyCd = sessionActiveResidency.propertyCd;
            mutateCmd.residencyId = sessionActiveResidency.residencyId;
            mutateCmd.eSignerViewId = signer.viewId;
            mutateCmd.email = values.email;

            sendEmailMutation.mutate(mutateCmd, {
                onSuccess: () => {
                    uiStore.addToast({ message: `Your contract has been resent!` });
                },
            });

            setResendEmail(true);
            setShowResendForm(false);
        };

        const openSigningURL = (): void => {
            uiStore.showActivityLoader();
            getSigningUrlResult.refetch();
        };

        const renderResendButton = (): React.ReactNode => {
            return (
                <Button
                    buttonType={'secondary'}
                    onClick={() => {
                        setShowResendForm(true);
                    }}>
                    Resend Email
                </Button>
            );
        };

        const renderResentWrapper = (): React.ReactNode => {
            return (
                <View style={styles.resentWrapper}>
                    <Image source={require('../../../../assets/images/Checkmark.png')} style={styles.checkmarkIcon} />
                    <RegularText>Email resent</RegularText>
                </View>
            );
        };

        return (
            <View
                style={[
                    styles.residentContainer,
                    useDrawerNavigation ? globalStyles.flexRow : globalStyles.flexColumn,
                ]}>
                <View style={[styles.signingNameAndStatus]}>
                    <View>
                        <RegularBoldText>{signer.name}</RegularBoldText>
                    </View>

                    {signer.signingStatus !== 'Signed' ? (
                        <View style={styles.signStatus}>
                            <Tag tagType={TagTypes.Blue}>Not Signed</Tag>
                        </View>
                    ) : (
                        <View style={styles.signStatus}>
                            <Tag tagType={TagTypes.DarkGrey}>Signed</Tag>
                        </View>
                    )}
                </View>

                {signer.signingStatus !== 'Signed' &&
                    signer.signingStatus !== 'Declined' &&
                    signer.signingStatus !== 'Unknown' && (
                        <View
                            style={[styles.signControls, useDrawerNavigation ? null : globalStyles.sectionSpaceAbove]}>
                            {showResendForm && (
                                <View style={styles.resendForm}>
                                    <View>
                                        <InputString
                                            initialValue={formik.values.email}
                                            onChange={formik.handleChange('email')}
                                            error={!!formik.errors.email}
                                            errorMessage={formik.errors.email}
                                        />
                                    </View>
                                    <View style={styles.resendFormControls}>
                                        <View style={[styles.controlButton, styles.controlButtonLeft]}>
                                            <Button onClick={formik.handleSubmit} inactive={formik.isSubmitting}>
                                                Send
                                            </Button>
                                        </View>
                                        <View style={[styles.controlButton, styles.controlButtonRight]}>
                                            <Button
                                                buttonType={'secondary'}
                                                onClick={() => {
                                                    setShowResendForm(false);
                                                }}>
                                                Cancel
                                            </Button>
                                        </View>
                                    </View>
                                </View>
                            )}

                            {!showResendForm && showSignNowButton && (
                                <>
                                    <View style={[styles.controlButton, styles.controlButtonLeft]}>
                                        <Button
                                            onClick={() => {
                                                openSigningURL();
                                            }}>
                                            Sign Now
                                        </Button>
                                    </View>
                                    {!resendEmail ? (
                                        <View style={[styles.controlButton, styles.controlButtonRight]}>
                                            {renderResendButton()}
                                        </View>
                                    ) : (
                                        renderResentWrapper()
                                    )}
                                </>
                            )}

                            {!showResendForm && !showSignNowButton && (
                                <View style={styles.controlButton}>
                                    {!resendEmail ? renderResendButton() : renderResentWrapper()}
                                </View>
                            )}
                        </View>
                    )}
            </View>
        );
    },
);

export default RenewalSigningScreenResident;

const styles = StyleSheet.create({
    residentContainer: {
        width: '100%',
        borderTopWidth: 1,
        borderTopColor: SmarthubTheme.colors.border,
        paddingVertical: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    signingNameAndStatus: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    signStatus: {
        minWidth: SmarthubTheme.layout.GRIDINCREMENT * 17,
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    signControls: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        alignContent: 'flex-end',
        flex: 1,
    },
    controlButton: {
        flex: 1,
    },
    controlButtonLeft: {
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN / 2,
    },
    controlButtonRight: {
        paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN / 2,
    },
    resendForm: {
        flexDirection: 'column',
        width: '100%',
    },
    resendFormControls: {
        flexDirection: 'row',
    },
    resentWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    checkmarkIcon: {
        width: SmarthubTheme.layout.ICONWIDTH,
        height: SmarthubTheme.layout.ICONWIDTH,
        resizeMode: 'contain',
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
});
