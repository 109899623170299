import { RootStore } from '../RootStore';
import SmarthubUser from 'models/SmarthubUser';
import { getAuth, User as FirebaseUser } from 'firebase/auth';
import { logError, logInfo } from 'utils/logging/Logger';
import { BOOTSTRAP_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { isEmpty, last } from 'utils/ObjectUtils';
import { BusinessExceptionErrorDetail } from 'services/remotecmd/RemoteCmdMgrUtils';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { formatPhoneNo, stringIsNotEmpty, stripCountryCode } from 'utils/StringUtils';
import {
    residencyInfo,
    SmarthubResidencyV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';
import { GetAuthorizedResidenciesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetAuthorizedResidenciesResultV1';
import { createGetAuthorizedResidenciesCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetAuthorizedResidenciesCommandV1';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import * as SentryHelper from 'utils/logging/SentryHelper';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import * as Device from 'expo-device';
import { cloneTime, parseDateMMDDYYYY } from 'utils/DateUtils';
import { GetSmarthubResidencyResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetSmarthubResidencyResultV1';
import { createGetSmarthubResidencyCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetSmarthubResidencyCommandV1';
import { getFirebaseUserEmail, getFirebaseUserPhoneNumber } from 'utils/FirebaseUtils';
import { getItem, removeItem, setItem } from 'utils/storage';
import { STORAGE_KEY_AUTHORIZED_RESIDENCY_INFO_LIST, STORAGE_KEY_SAVED_RESIDENCY_INFO } from 'utils/storage-keys';
import { getSmarthubWebsiteUrl } from 'utils/AppConfigHelper';
import { loadResidencyRefreshHistory, navigationRoutes, removeResidencyRefreshHistory } from 'utils/NavigationUtils';
import { FeatureToggle } from 'models/remotecmds/com/ocs/nirvana/shared/featuretoggle/FeatureToggle';
import { generateInsertSmarthubLoginCmd, getBrowser, getDeviceType, getPlatformOS } from 'utils/EnvironmentUtils';
import * as Notifications from 'expo-notifications';
import { SmarthubLoginV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubLoginV1';
import Constants from 'expo-constants';
import { User as SentryUser } from '@sentry/types';

export class UserSessionStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    smarthubUser: SmarthubUser = new SmarthubUser();
    sessionActiveResidency: SmarthubResidencyV1 = {} as SmarthubResidencyV1;
    isReloading = false;

    balanceDirty = false;

    autoPaySetupPending = false;

    get hasSessionActiveResidency(): boolean {
        return !isNaN(this.sessionActiveResidency.residencyId);
    }

    confirmActiveResidency = (navigation: NavigationProp<ParamListBase>) => {
        if (
            !this.hasSessionActiveResidency &&
            this.smarthubUser.authorizedResidencies &&
            this.smarthubUser.authorizedResidencies.length > 0 &&
            navigation
        ) {
            navigation.navigate(navigationRoutes.homeRoutes.leaseSelector);
        }
    };

    _isValidResidencyKey = (key: string | undefined) => {
        return (
            key &&
            key.split('-').length === 2 &&
            stringIsNotEmpty(key.split('-')[0]) &&
            stringIsNotEmpty(key.split('-')[1]) &&
            !isNaN(parseInt(key.split('-')[1]))
        );
    };

    setSessionActiveResidency = action((newResidency: SmarthubResidencyV1) => {
        this.sessionActiveResidency = newResidency;

        //TODO introduce an event bus and listen for changes where we care?
        if (newResidency && newResidency.propertyCd) {
            this._insertSmarthubLogin();
        }
    });

    clearSessionActiveResidency = action(() => {
        this.setSessionActiveResidency({} as SmarthubResidencyV1);
    });

    setSessionActiveResidencyByKey = action(
        async (newSessionActiveResidencyKey: string, populateFromServer: boolean) => {
            if (this._isValidResidencyKey(newSessionActiveResidencyKey)) {
                const newSessionActiveResidency: SmarthubResidencyV1 | undefined =
                    this.smarthubUser.authorizedResidencies.find(r => {
                        //1073-23222
                        const propertyNumber = parseInt(newSessionActiveResidencyKey.split('-')[0]);
                        const residencyId = parseInt(newSessionActiveResidencyKey.split('-')[1]);
                        return r.propertyNumber === propertyNumber && r.residencyId === residencyId;
                    });

                logInfo(INFO_LOGGER, 'Found the residency to switch to : ', newSessionActiveResidency?.residencyId);
                if (newSessionActiveResidency) {
                    //clear temp variables that were related to the old residency
                    this.setBalanceDirty(false);
                    this.setAutoPaySetupPending(false);
                    if (populateFromServer) {
                        //fully load that newSessionActiveResidency...
                        const populatedResidency: SmarthubResidencyV1 | null =
                            await this.populateAuthorizedResidencyFromServer(newSessionActiveResidency);
                        this._updateResidencyInArray(populatedResidency);
                        if (this.smarthubUser.uid && populatedResidency) {
                            this._logSessionInfo(populatedResidency);
                        }
                    } else {
                        this.setSessionActiveResidency(newSessionActiveResidency);
                        if (this.smarthubUser.uid) {
                            this._logSessionInfo(newSessionActiveResidency);
                        }
                    }

                    this.saveResidencyInfo(
                        this.sessionActiveResidency.key,
                        this.sessionActiveResidency.propertyCd,
                        this.sessionActiveResidency.residencyId,
                    );
                }
            }
        },
    );

    _updateResidencyInArray = (populatedResidency: SmarthubResidencyV1 | null) => {
        if (null != populatedResidency) {
            //easier to replace the one and replace the list!!
            const clonedArr = [...this.smarthubUser.authorizedResidencies];
            const foundIndex = clonedArr.findIndex(
                res =>
                    res.propertyCd === populatedResidency.propertyCd &&
                    res.residencyId === populatedResidency.residencyId,
            );
            if (foundIndex > -1) {
                clonedArr[foundIndex] = populatedResidency;
                logInfo(
                    INFO_LOGGER,
                    'Found the residency to update : ',
                    populatedResidency.propertyCd + '-' + populatedResidency?.residencyId,
                );
                logInfo(INFO_LOGGER, 'Updated residencies array : ', JSON.stringify(clonedArr));
                runInAction(() => {
                    this.smarthubUser.authorizedResidencies.replace(clonedArr);
                    this.setSessionActiveResidency(populatedResidency);
                });
            }
        }
    };

    setBalanceDirty = action((balanceDirty: boolean) => (this.balanceDirty = balanceDirty));

    setAutoPaySetupPending = action((autoPaySetupPending: boolean) => (this.autoPaySetupPending = autoPaySetupPending));

    /**
     * Setup all the data we need in their session for the firebaseuser.
     *
     * @param firebaseUser User object obtained from firebase
     * @param forceUpdate Update user in session even if it's the same user, etc...
     */
    setUserFromFirebaseUser = action(
        async (firebaseUser: FirebaseUser | null, forceUpdate?: boolean): Promise<void> => {
            logInfo(INFO_LOGGER, 'firebaseUser?.uid', firebaseUser?.uid);
            logInfo(INFO_LOGGER, 'this.smarthubUser.firebaseUser?.uid', this.smarthubUser.uid);

            if (firebaseUser?.uid === this.smarthubUser.uid && !forceUpdate) {
                logInfo(INFO_LOGGER, 'Same firebase user so no update needed!');
                return;
            }
            if (null !== firebaseUser) {
                this.smarthubUser.uid = firebaseUser.uid;
                this.reloadResidencies(true);
            } else {
                logInfo(INFO_LOGGER, 'No firebase user so log out!');
                this.deauthorize();
            }
        },
    );

    _logSessionInfo = (residency: SmarthubResidencyV1) => {
        const auth = getAuth();
        const firebaseUser = auth.currentUser;
        if (!firebaseUser) return;

        const userInfo: SentryUser = {
            id: firebaseUser.uid,
            username: (firebaseUser.emailVerified && firebaseUser.email) || firebaseUser.phoneNumber || undefined,
            name: firebaseUser.displayName || undefined,
            email: (firebaseUser.emailVerified && firebaseUser.email) || undefined,
            propertyCd: residency.propertyCd,
            bldgCd: residency.bldgCd,
            unitCd: residency.unitCd,
            residencyId: residency.residencyId,
            residentId: residency.residentId,
            deviceModelMame: Device.modelName,
            deviceModelId: Device.modelId,
            deviceOsName: Device.osName,
            deviceOsVersion: Device.osVersion,
            deviceName: Device.deviceName,
            deviceManufacturer: Device.manufacturer,
            deviceDesignName: Device.designName,
            deviceProductName: Device.productName,
        };

        logInfo(BOOTSTRAP_LOGGER, 'Sentry updated with...', JSON.stringify(userInfo));

        SentryHelper.setUser(userInfo);
    };

    obtainAuthorizedResidenciesFromServer = action(async (): Promise<void> => {
        // this.rootStore.uiStore.showAlert({ message: 'Getting authorized leases from server...' });
        let result: GetAuthorizedResidenciesResultV1;
        try {
            this.rootStore.uiStore.showActivityLoader();
            const cmd = createGetAuthorizedResidenciesCommandV1();

            cmd.payLeaseRedirUrl = this.getPayleaseRedirUrl();
            logInfo(BOOTSTRAP_LOGGER, 'Payment confirmation url is ', cmd.payLeaseRedirUrl);

            cmd.smarthubWebUrl = getSmarthubWebsiteUrl();
            logInfo(BOOTSTRAP_LOGGER, 'smarthubWebUrl is ', cmd.smarthubWebUrl);

            const resInfo: string | null = await this.getLocalResidencyInfo();
            const residencyInfo: residencyInfo = JSON.parse(resInfo || '{}');

            const refreshResInfo: string | null | undefined = await loadResidencyRefreshHistory();
            const webRefreshResInfo: residencyInfo = JSON.parse(refreshResInfo || '{}');

            // Check for active residency or residency key and fill in the optional residency id to fully load
            // Web refresh shouldn't be loaded in here because it will always be empty unless on refresh, at which point use the active residency
            if (residencyInfo.residencyKey && residencyInfo.residencyKey !== null && !this.hasSessionActiveResidency) {
                cmd.optionalPropertyCd = residencyInfo.propertyCd;
                cmd.optionalResidencyId = residencyInfo.residencyId;
            } else if (this.hasSessionActiveResidency) {
                cmd.optionalResidencyId = this.sessionActiveResidency?.residencyId;
                cmd.optionalPropertyCd = this.sessionActiveResidency?.propertyCd;
            }

            result = await dispatchCommandAsync(this.rootStore.uiStore, cmd, false);

            if (result) {
                if (!isEmpty(result) && !result.authenticated) {
                    logInfo(BOOTSTRAP_LOGGER, 'Not authenticated via server so log out!');
                    this.deauthorize();
                }

                if (result.authenticated) {
                    logInfo(
                        BOOTSTRAP_LOGGER,
                        'AuthorizedResidencies from server are : ',
                        JSON.stringify(result.authorizedResidencies),
                    );
                    //This get's minimally filled residencies
                    const authorizedResidencies = result.authorizedResidencies;

                    if (this.smarthubUser.uid != result.identifier) {
                        //security issue! token doesn't match current user
                        this.deauthorize();
                    } else if (authorizedResidencies.length === 0) {
                        const noLeaseMsg = this._buildNoLeaseMessage();
                        logInfo(BOOTSTRAP_LOGGER, noLeaseMsg);
                        this.rootStore.uiStore.showAlert({
                            message: noLeaseMsg,
                        });
                        this.deauthorize();
                    } else {
                        this.smarthubUser.setAuthorizedResidencies(
                            observable<SmarthubResidencyV1>(authorizedResidencies),
                        );

                        if (!this.sessionActiveResidency || !this.sessionActiveResidency.key) {
                            // First check make sure this is a key in local storage, if so set that as active residency
                            if (
                                residencyInfo.residencyKey &&
                                this.validateLocalResidencyKey(residencyInfo.residencyKey) &&
                                this.validateLocalNonPreviousResidencyKey(residencyInfo.residencyKey)
                            ) {
                                this.setSessionActiveResidencyByKey(residencyInfo.residencyKey, false);
                                // Otherwise set latest
                            } else if (
                                webRefreshResInfo.residencyKey &&
                                this._isValidResidencyKey(webRefreshResInfo.residencyKey)
                            ) {
                                this.setSessionActiveResidencyByKey(webRefreshResInfo.residencyKey, false);
                            } else if (this.latestResidency) {
                                this.setSessionActiveResidencyByKey(
                                    this.latestResidency.propertyNumber + '-' + this.latestResidency.residencyId,
                                    false,
                                );
                                // Clear local info if it isn't valid
                                if (!this.validateLocalResidencyKey(residencyInfo.residencyKey)) {
                                    this.clearLocalResidencyInfo();
                                }
                            }
                        }

                        this.saveAuthorizedResidencyInfoList(authorizedResidencies);
                    }
                }
            }
        } catch (e: any) {
            logError(BOOTSTRAP_LOGGER, 'Error getting authorized residencies:   ', JSON.stringify(e));
            //if the result of the promise is the not auth error then log them out!
            const notAuthorized: boolean =
                e &&
                e.businessErrors &&
                e.businessErrors.find((e: BusinessExceptionErrorDetail) => e.code === 'NOT_AUTHORIZED') !== undefined;
            if (notAuthorized) {
                this.deauthorize();
            }
        } finally {
            this.rootStore.uiStore.hideActivityLoader();
            removeResidencyRefreshHistory();
        }
    });

    populateAuthorizedResidencyFromServer = action(
        async (residencyToPopulate: SmarthubResidencyV1, silent = false): Promise<SmarthubResidencyV1 | null> => {
            let result: GetSmarthubResidencyResultV1;
            try {
                if (!silent) {
                    this.rootStore.uiStore.showActivityLoader();
                } else {
                    logInfo(INFO_LOGGER, 'SILENTLY REFRESHING SINGLE RESIDENCY DATA!!');
                }
                const cmd = createGetSmarthubResidencyCommandV1();
                cmd.propertyCd = residencyToPopulate.propertyCd;
                cmd.residencyId = residencyToPopulate.residencyId;

                cmd.payLeaseRedirUrl = this.getPayleaseRedirUrl();
                logInfo(BOOTSTRAP_LOGGER, 'Payment confirmation url is ', cmd.payLeaseRedirUrl);

                cmd.smarthubWebUrl = getSmarthubWebsiteUrl();
                logInfo(BOOTSTRAP_LOGGER, 'smarthubWebUrl is ', cmd.smarthubWebUrl);

                result = await dispatchCommandAsync(this.rootStore.uiStore, cmd, silent);

                if (result) {
                    if (!isEmpty(result) && !result.authenticated) {
                        logInfo(BOOTSTRAP_LOGGER, 'Not authenticated via server so log out!');
                        this.deauthorize();
                    }

                    if (result.authenticated) {
                        logInfo(
                            BOOTSTRAP_LOGGER,
                            'AuthorizedResidency from server IS : ',
                            JSON.stringify(result.smarthubResidencyV1),
                        );

                        if (this.smarthubUser.uid != result.identifier) {
                            //security issue! token doesn't match current user
                            this.deauthorize();
                        } else if (!result.smarthubResidencyV1) {
                            const noLeaseMsg = this._buildNoLeaseMessage();
                            logInfo(BOOTSTRAP_LOGGER, noLeaseMsg);
                            this.rootStore.uiStore.showAlert({
                                message: noLeaseMsg,
                            });
                            this.deauthorize();
                        } else {
                            return result.smarthubResidencyV1;
                        }
                    }
                }
                return null;
            } catch (e: any) {
                logError(BOOTSTRAP_LOGGER, 'Error getting authorized residency:   ', JSON.stringify(e));
                //if the result of the promise is the not auth error then log them out!
                const notAuthorized: boolean =
                    e &&
                    e.businessErrors &&
                    e.businessErrors.find((e: BusinessExceptionErrorDetail) => e.code === 'NOT_AUTHORIZED') !==
                        undefined;
                if (notAuthorized) {
                    this.deauthorize();
                }
                return null;
            } finally {
                !silent && this.rootStore.uiStore.hideActivityLoader();
            }
        },
    );

    getPayleaseRedirUrl() {
        return Linking.createURL('smarthub/payment_confirmation');
    }

    async saveResidencyInfo(residencyKey: string, propertyCd: string, residencyId: number) {
        const resInfo: residencyInfo = {
            propertyCd: propertyCd,
            residencyKey: residencyKey,
            residencyId: residencyId,
        };
        try {
            await setItem(STORAGE_KEY_SAVED_RESIDENCY_INFO, JSON.stringify(resInfo));
        } catch (e) {
            logInfo(INFO_LOGGER, 'Error saving residency to local storage: ', JSON.stringify(e));
        }
    }

    async saveAuthorizedResidencyInfoList(authorizedResidencies: SmarthubResidencyV1[]) {
        const authorizedResidencyInfoList: residencyInfo[] = authorizedResidencies.map(r => {
            return { propertyCd: r.propertyCd, residencyKey: r.key, residencyId: r.residencyId };
        });
        try {
            await setItem(STORAGE_KEY_AUTHORIZED_RESIDENCY_INFO_LIST, JSON.stringify(authorizedResidencyInfoList));
        } catch (e) {
            logInfo(INFO_LOGGER, 'Error saving authorized residency list to local storage: ', JSON.stringify(e));
        }
    }

    async getLocalResidencyInfo() {
        return getItem(STORAGE_KEY_SAVED_RESIDENCY_INFO);
    }

    async getLocalAuthorizedResidencyInfoList() {
        return getItem(STORAGE_KEY_AUTHORIZED_RESIDENCY_INFO_LIST);
    }

    async clearLocalResidencyInfo() {
        removeItem(STORAGE_KEY_SAVED_RESIDENCY_INFO);
    }

    async clearAuthorizedResidencyInfoList() {
        removeItem(STORAGE_KEY_AUTHORIZED_RESIDENCY_INFO_LIST);
    }

    validateLocalResidencyKey(resKey: string): boolean {
        return this.smarthubUser.authorizedResidencies.filter(res => res.key === resKey).length > 0;
    }

    validateLocalNonPreviousResidencyKey(resKey: string): boolean {
        return (
            this.smarthubUser.authorizedResidencies.filter(res => res.key === resKey && res.statusCd === 'Previous')
                .length <= 0 &&
            this.smarthubUser.authorizedResidencies.filter(res => res.statusCd !== 'Previous').length > 0
        );
    }

    setIsReloading = action((reloadingBool: boolean) => {
        this.isReloading = reloadingBool;
    });

    /**
     * Simple helper method that reloads all residency data from the server and preselects the residency
     * the user was already looking at
     */
    reloadResidencies(silent: boolean, populateFromServer = false) {
        const selectedKey = this.sessionActiveResidency.key;
        this.setIsReloading(true);
        if (!silent) {
            this.rootStore.uiStore.showActivityLoader();
        } else {
            logInfo(INFO_LOGGER, 'SILENTLY REFRESHING RESIDENCY DATA!!');
        }

        logInfo(INFO_LOGGER, 'selectedKey: ', selectedKey);

        this.obtainAuthorizedResidenciesFromServer()
            .then(() => {
                if (selectedKey) {
                    //Default should always be false because current residency will be fully loaded during obtain function
                    this.setSessionActiveResidencyByKey(selectedKey, populateFromServer);
                }
            })
            .finally(() => {
                this.setBalanceDirty(false);
                this.setIsReloading(false);
                if (!silent) {
                    this.rootStore.uiStore.hideActivityLoader();
                }
            });
    }

    async reloadLeaseAdjustmentAndAlert() {
        if (this.hasSessionActiveResidency) {
            this.populateAuthorizedResidencyFromServer(this.sessionActiveResidency, true).then(
                (populatedResidency: SmarthubResidencyV1 | null) => {
                    if (null !== populatedResidency) {
                        runInAction(() => {
                            this.sessionActiveResidency.leaseAdjustment = populatedResidency.leaseAdjustment;
                            this.sessionActiveResidency.leaseAdjustmentMessage =
                                populatedResidency.leaseAdjustmentMessage;
                            this.sessionActiveResidency.leaseAdjustmentMessage2 =
                                populatedResidency.leaseAdjustmentMessage2;
                            this.sessionActiveResidency.leaseAlerts = populatedResidency.leaseAlerts;
                            this.sessionActiveResidency.allowNTV = populatedResidency.allowNTV;
                            this.sessionActiveResidency.allowRenewal = populatedResidency.allowRenewal;
                        });
                    }
                },
            );
        }
    }

    async reloadCurrentResidencyAsync(silent: boolean) {
        const wait = new Promise<void>(resolve => {
            if (this.hasSessionActiveResidency) {
                this.populateAuthorizedResidencyFromServer(this.sessionActiveResidency, silent)
                    .then((populatedResidency: SmarthubResidencyV1 | null) => {
                        if (null !== populatedResidency) {
                            this._updateResidencyInArray(populatedResidency);
                        }
                    })
                    .finally(() => {
                        this.setBalanceDirty(false);
                        resolve();
                    });
            } else {
                this.reloadResidencies(silent);
                resolve();
            }
        });
        return wait;
    }

    reloadCurrentResidencyForAutoPay = () => {
        if (this.autoPaySetupPending) {
            this.reloadCurrentResidencyAsync(true).then(() => {
                this.setAutoPaySetupPending(false);
            });
        }
    };

    _isSupportedLease = (smarthubResidencyV1: SmarthubResidencyV1) => {
        if (
            smarthubResidencyV1.statusCd === 'Cancelled' ||
            smarthubResidencyV1.statusCd === 'TransferIn' ||
            smarthubResidencyV1.statusCd === 'Leased'
        ) {
            return false;
            //e
        } else if (smarthubResidencyV1.statusCd === 'Previous' && null != smarthubResidencyV1.moveoutDt) {
            const yearAgoDt = new Date();
            yearAgoDt.setFullYear(yearAgoDt.getFullYear() - 1);
            const moveoutDt: Date | undefined = parseDateMMDDYYYY(smarthubResidencyV1.moveoutDt);
            if (
                null != moveoutDt &&
                moveoutDt.getTime() < yearAgoDt.getTime() &&
                smarthubResidencyV1.currentBalance === 0
            ) {
                return false;
            } else return true;
        } else return true;
    };

    _buildNoLeaseMessage = (): string => {
        const firebaseUserEmail = getFirebaseUserEmail();
        const firebaseUserPhoneNumber = getFirebaseUserPhoneNumber();
        return (
            'You do not have access to any leases. Please contact your Community Manager to make sure the email or phone number (see below) you used to sign in matches our records.' +
            (firebaseUserEmail ? ' \n\nEmail : ' + firebaseUserEmail : '') +
            (null != firebaseUserPhoneNumber
                ? ' \n\nPhone : ' + formatPhoneNo(stripCountryCode(firebaseUserPhoneNumber))
                : '')
        );
    };

    _insertSmarthubLogin = async () => {
        const deviceType = await getDeviceType();
        let token = '';
        if (Platform.OS !== 'web') {
            try {
                token = (await Notifications.getExpoPushTokenAsync()).data;
            } catch (e) {
                try {
                    // @ts-ignore
                    const projectId = Constants.expoConfig.extra.eas.projectId;
                    token = (await Notifications.getExpoPushTokenAsync({ projectId })).data;
                } catch (e) {
                    //ignore
                }
            }
        }

        const experienceId = `@${Constants.expoConfig?.owner}/${Constants.expoConfig?.slug}` || '';

        const smarthubLogin: SmarthubLoginV1 = {
            experienceId: experienceId,
            deviceTokenId: token,
            smarthubLoginDO: {
                loginId: -1,
                propertyCd: this.sessionActiveResidency.propertyCd,
                ownerId: this.sessionActiveResidency.residentId,
                ownerIdType: 'Resident',
                platformOs: getPlatformOS(),
                deviceType: deviceType,
                browser: getBrowser(),
                osVersion: Device.osVersion || '',
                createdDtt: 0,
                databaseId: '',
            },
        };
        const cmd = generateInsertSmarthubLoginCmd(
            this.sessionActiveResidency.propertyCd,
            this.sessionActiveResidency.residencyId,
            smarthubLogin,
        );
        await dispatchCommandAsync(this.rootStore.uiStore, cmd, false);
    };

    /**
     * signout and clear the session, etc...
     */
    deauthorize = action(() => {
        const auth = getAuth();
        logInfo(INFO_LOGGER, 'Deauthorizing-------------');
        auth.signOut();
        this.smarthubUser.authorizedResidencies.clear();
        this.smarthubUser = new SmarthubUser();
        this.clearSessionActiveResidency();
        this.clearAuthorizedResidencyInfoList();
    });

    get latestCurrentResidency(): SmarthubResidencyV1 | undefined {
        logInfo(INFO_LOGGER, 'COMPUTING LATEST CURRENT RESIDENCY.........');
        const lastRes = last(
            this.smarthubUser.authorizedResidencies &&
                this.smarthubUser.authorizedResidencies.filter(
                    r => r.statusCd === 'Current' || r.statusCd === 'Notice' || r.statusCd === 'TransferOut',
                ),
        );
        logInfo(INFO_LOGGER, 'Latest current residency is ', lastRes?.key);
        return lastRes;
    }

    get latestResidency(): SmarthubResidencyV1 | undefined {
        const containsNonPrevResidency =
            this.smarthubUser.authorizedResidencies.filter(res => res.statusCd !== 'Previous').length > 0;
        const sortedRes = this.smarthubUser.authorizedResidencies.sort((a, b) => {
            const adate = a.statusCd === 'Leased' ? parseDateMMDDYYYY(a.expectMoveinDt) : parseDateMMDDYYYY(a.moveinDt);
            const bdate = b.statusCd === 'Leased' ? parseDateMMDDYYYY(b.expectMoveinDt) : parseDateMMDDYYYY(b.moveinDt);

            if (containsNonPrevResidency) {
                if (a.statusCd === 'Previous' && b.statusCd !== 'Previous') {
                    return -1;
                }
                if (a.statusCd !== 'Previous' && b.statusCd === 'Previous') {
                    return 0;
                }
                if (null != adate && null != bdate) {
                    return adate.getTime() - bdate.getTime();
                }
                return 0;
            } else if (null != adate && null != bdate) {
                return adate.getTime() - bdate.getTime();
            } else return 0;
        });
        const lastRes = sortedRes && last(sortedRes);
        logInfo(INFO_LOGGER, 'Latest residency is ', lastRes?.key + ' unit ' + lastRes?.bldgCd + '-' + lastRes?.unitCd);
        return lastRes;
    }

    get activeUnitString(): string | undefined {
        return (
            (this.hasSessionActiveResidency &&
                'Apt ' + this.sessionActiveResidency.bldgCd + '-' + this.sessionActiveResidency.unitCd + '') ||
            ''
        );
    }

    get activePropertyName(): string | undefined {
        return this.sessionActiveResidency.propertyName;
    }

    isFeatureEnabled(feature: FeatureToggle): boolean {
        return this.sessionActiveResidency?.featureToggles?.slice().filter(toggle => toggle === feature).length > 0;
    }

    get propertySpecificDt(): Date {
        return parseDateMMDDYYYY(this.sessionActiveResidency?.propertySpecificDt) || new Date();
    }

    get propertySpecificTimeStamp(): Date {
        return cloneTime(parseDateMMDDYYYY(this.sessionActiveResidency?.propertySpecificDt) || new Date());
    }

    get friendlyAptString(): string {
        return (
            this.sessionActiveResidency &&
            `Apt ${this.sessionActiveResidency.bldgCd}-${this.sessionActiveResidency.unitCd} (${this.sessionActiveResidency.propertyName})`
        );
    }
}
