import React, { useRef } from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { observer } from 'mobx-react-lite';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useStore } from 'contexts/StoreContextProvider';
import Svg, { Circle, ClipPath, Defs, G, Rect } from 'react-native-svg';
import Hyperlink from 'components/primitives/Hyperlink';

export interface TooltipOverlayConfig {
    testID?: string;
    accessibilityLabel?: string;
    plinthWidth?: number | string;
    plinthAlignment?: 'top' | 'right' | 'bottom' | 'left';
    plinthText?: () => React.ReactNode;
    plinthDismissText?: string;
    spotlightShape?: 'rectangle' | 'circle';
    spotlightWidth: number;
    spotlightHeight: number;
    spotlightX: number;
    spotlightY: number;
    onDismiss?: () => void;
}

interface TooltipOverlayProps {
    testID?: string;
}

const defaultProps: TooltipOverlayProps = {};

const TooltipOverlay: React.FC<TooltipOverlayProps> = observer((props: TooltipOverlayProps) => {
    const [plinthX, setPlinthX] = React.useState(0);
    const [plinthY, setPlinthY] = React.useState(0);
    const [isPlinthSet, setIsPlinthSet] = React.useState(false);

    const { uiStore } = useStore();

    const tooltipPlinth = useRef<View>(null);

    // Get size and alignment of plinth and reposition relative to spotlight
    const _getPlinthCoords = () => {
        if (tooltipPlinth.current && uiStore.tooltip && !isPlinthSet) {
            setIsPlinthSet(true);
            tooltipPlinth.current.measure(_setTooltipPosition);
        }
    };

    const _setTooltipPosition = (
        relX: number,
        relY: number,
        width: number,
        height: number,
        absX: number,
        absY: number,
    ) => {
        if (uiStore.tooltip) {
            let plinthX = uiStore.tooltip.spotlightX - (width - uiStore.tooltip.spotlightWidth) / 2;
            let plinthY = uiStore.tooltip.spotlightY - height - SmarthubTheme.layout.GRIDINCREMENT * 6;

            if (uiStore.tooltip.plinthAlignment === 'right') {
                plinthX =
                    uiStore.tooltip.spotlightX +
                    uiStore.tooltip.spotlightWidth +
                    SmarthubTheme.layout.GRIDINCREMENT * 2;
                plinthY =
                    uiStore.tooltip.spotlightY -
                    (height - uiStore.tooltip.spotlightHeight * 2) -
                    SmarthubTheme.layout.GRIDINCREMENT * 2;
            } else if (uiStore.tooltip.plinthAlignment === 'bottom') {
                plinthY =
                    uiStore.tooltip.spotlightY +
                    uiStore.tooltip.spotlightHeight +
                    SmarthubTheme.layout.GRIDINCREMENT * 4;
            } else if (uiStore.tooltip.plinthAlignment === 'left') {
                plinthX = uiStore.tooltip.spotlightX - width - SmarthubTheme.layout.GRIDINCREMENT * 2;
                plinthY = uiStore.tooltip.spotlightY - (height / 2 - uiStore.tooltip.spotlightHeight);
            }

            plinthX = plinthX < SmarthubTheme.layout.GRIDINCREMENT ? SmarthubTheme.layout.GRIDINCREMENT : plinthX;

            setPlinthX(plinthX);
            setPlinthY(plinthY);
        }
    };

    // Hide tooltip
    const _onDismiss = () => {
        logInfo(INFO_LOGGER, 'Dismiss Tooltip');

        uiStore.hideTooltip();

        if (uiStore.tooltip?.onDismiss) {
            uiStore.tooltip.onDismiss();
        }
    };

    if (uiStore.tooltip != null) {
        // Set spotlight size and coords
        const circleRad = Math.floor(uiStore.tooltip.spotlightWidth / 2 + SmarthubTheme.layout.GRIDINCREMENT);
        const circleX = uiStore.tooltip.spotlightX + uiStore.tooltip.spotlightWidth / 2;
        const circleY = uiStore.tooltip.spotlightY + uiStore.tooltip.spotlightHeight / 2;

        const rectWidth = uiStore.tooltip.spotlightWidth + SmarthubTheme.layout.GRIDINCREMENT * 2;
        const rectHeight = uiStore.tooltip.spotlightHeight + SmarthubTheme.layout.GRIDINCREMENT * 2;
        const rectX = uiStore.tooltip.spotlightX - SmarthubTheme.layout.GRIDINCREMENT;
        const rectY = uiStore.tooltip.spotlightY - SmarthubTheme.layout.GRIDINCREMENT;

        // Set plinth coords
        const plinthStyles: StyleProp<ViewStyle> = [
            styles.tooltipPlinth,
            {
                top: plinthY,
                left: plinthX,
                width: uiStore.tooltip.plinthWidth ? uiStore.tooltip.plinthWidth : 'auto',
            },
        ];

        return (
            <View
                testID={props.testID}
                style={styles.tooltipWrapper}
                onTouchEnd={() => {
                    _onDismiss();
                }}>
                {Platform.OS === 'web' ? (
                    <svg width='100%' height='100%'>
                        <defs>
                            <mask id='hole'>
                                <rect x='0' y='0' width='100vw' height='100vh' fill='white' />
                                {uiStore.tooltip.spotlightShape === 'circle' ? (
                                    <circle cx={`${circleX}`} cy={`${circleY}`} r={`${circleRad}`} />
                                ) : (
                                    <rect
                                        x={`${rectX}`}
                                        y={`${rectY}`}
                                        width={`${rectWidth}`}
                                        height={`${rectHeight}`}
                                    />
                                )}
                            </mask>
                        </defs>

                        <rect width='100%' height='100%' fill='black' opacity='0.6' mask='url(#hole)' />
                    </svg>
                ) : (
                    <Svg height='100%' width='100%'>
                        <Defs>
                            <ClipPath
                                id='clip'
                                // @ts-ignore
                                clipRule={'evenodd'}>
                                <G scale='1'>
                                    <Rect x='0' y='0' width='100%' height='100%' />
                                    {uiStore.tooltip.spotlightShape === 'circle' ? (
                                        <Circle cx={`${circleX}`} cy={`${circleY}`} r={`${circleRad}`} opacity='0' />
                                    ) : (
                                        <Rect
                                            x={`${rectX}`}
                                            y={`${rectY}`}
                                            width={`${rectWidth}`}
                                            height={`${rectHeight}`}
                                        />
                                    )}
                                </G>
                            </ClipPath>
                        </Defs>
                        <Rect x='0' y='0' width='100%' height='100%' fill='black' opacity='0.6' clipPath='url(#clip)' />
                    </Svg>
                )}

                <View ref={tooltipPlinth} style={plinthStyles} onLayout={_getPlinthCoords}>
                    <View>
                        {uiStore.tooltip.plinthText && uiStore.tooltip.plinthText()}
                        {uiStore.tooltip.plinthDismissText !== '' && (
                            <Hyperlink style={styles.dismissText} onClick={_onDismiss}>
                                {uiStore.tooltip.plinthDismissText}
                            </Hyperlink>
                        )}
                    </View>
                </View>
            </View>
        );
    } else {
        return null;
    }
});

TooltipOverlay.defaultProps = defaultProps;

export default TooltipOverlay;

const styles = StyleSheet.create({
    tooltipUnderlay: {
        width: '100%',
        height: '100%',
        backgroundColor: SmarthubTheme.colors.black,
        opacity: 0.5,
    },
    tooltipWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 1,
    },
    tooltipText: {
        display: 'flex',
        textAlign: 'center',
    },
    tooltipPlinth: {
        position: 'absolute',
        maxWidth: '98%',
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingRight: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingLeft: SmarthubTheme.layout.GRIDINCREMENT * 2,
        backgroundColor: SmarthubTheme.colors.white,
    },
    dismissText: {
        width: '100%',
        textAlign: 'center',
        color: SmarthubTheme.colors.lightblue,
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 4,
    },
});
