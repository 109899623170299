import React from 'react';
import { MediumBoldText, RegularText } from 'components/primitives/StyledText';
import { globalStyles } from 'theme/style/GlobalStyles';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useNavigation } from '@react-navigation/native';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { observer } from 'mobx-react-lite';

const SmartMoveContractSigningComplete: React.FC = observer(() => {
    const navigation = useNavigation();
    const _navigate = () => {
        navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveUtilitiesAndRentersInsurance);
    };

    return (
        <SmartmoveScreenContainer
            progressIndicatorProps={{
                currentStep: 3,
            }}
            footerButtonProps={{
                text: 'Continue',
                onClick: _navigate,
            }}>
            <MediumBoldText style={[globalStyles.smartMoveTitleText, globalStyles.centeredText]}>
                You&apos;re ready to continue
            </MediumBoldText>
            <RegularText style={globalStyles.centeredText}>
                All applicants who will live at your apartment have signed the contract. Please continue to the next
                step.
            </RegularText>
        </SmartmoveScreenContainer>
    );
});

export default SmartMoveContractSigningComplete;
