import { LeaseAdjustment } from './LeaseAdjustment';
import { SmarthubLeaseAlert } from './SmarthubLeaseAlert';
import { SmarthubPaymentRestrictionReasons } from './SmarthubPaymentRestrictionReasons';
import { ResidencyStatus } from '../../../../shared/businesslogic/residency/dataobjects/ResidencyStatus';

/* START CUSTOM IMPORTS */
import { Platform } from 'react-native';
import { FeatureToggle } from 'models/remotecmds/com/ocs/nirvana/shared/featuretoggle/FeatureToggle';
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.residency.SmarthubResidencyV1
 */
export interface SmarthubResidencyV1 {
    key: string;
    propertyCd: string;
    bldgCd: string;
    unitCd: string;
    firstName: string;
    lastName: string;
    residencyId: number;
    residentId: number;
    residentEmail: string;
    statusCd: ResidencyStatus;
    leaseAdjustmentMessage: string;
    leaseAdjustmentMessage2: string;
    leaseAdjustment: LeaseAdjustment;
    leaseAlerts: Array<SmarthubLeaseAlert>;
    currentBalance: number;
    payleaseAccount: string;
    onMtm: boolean;
    onAutoPay: boolean;
    autopayConcAmt: number;
    disableAllPaymentOptions: boolean;
    paymentRestrictionReasons: Array<SmarthubPaymentRestrictionReasons>;
    occupantNames: Array<string>;
    leaseTerm: number;
    noticeDays: number;
    propertyName: string;
    propertyPhoneNumber: string;
    propertyEmail: string;
    propertyNumber: number;
    communityMastheadImageURI: string;
    communityLogoImageURI: string;
    leaseStartDt: string;
    leaseEndDt: string;
    hasUnreadNotifications: boolean;
    payleaseLoginLink: string;
    payleaseOneTimeLink: string;
    payleaseAutoPayLink: string;
    payleaseAutoPayPaymentLink: string;
    numPets: number;
    numVehicles: number;
    numRentables: number;
    isEmployee: boolean;
    leaseTypeDesc: string;
    expectMoveoutReason: string;
    expectMoveinDt: string;
    moveinDt: string;
    noticeDt: string;
    expectMoveoutDt: string;
    moveoutDt: string;
    expectXferDt: string;
    lastPaymentDt: string;
    lastPaymentAmt: number;
    contractPdfLink: string;
    inventoryConditionPdfLink: string;
    allowNTV: boolean;
    allowRenewal: boolean;
    featureToggles: Array<FeatureToggle>;
    propertySpecificDt: string;
    propertySpecificTimeStamp: number;
    totalLateFees: number;
    isHillsboroughLateNoticeApplicable: boolean;
    corporateAdminYn: boolean;
    adminYn: boolean;
    lockPaymentAmountYn: boolean;
}

export function createSmarthubResidencyV1(): SmarthubResidencyV1 {
    return {
        key: '',
        propertyCd: '',
        bldgCd: '',
        unitCd: '',
        firstName: '',
        lastName: '',
        residencyId: 0,
        residentId: 0,
        residentEmail: '',
        statusCd: 'Leased',
        leaseAdjustmentMessage: '',
        leaseAdjustmentMessage2: '',
        leaseAdjustment: 'TransferRequestSubmitted',
        leaseAlerts: [],
        currentBalance: 0,
        payleaseAccount: '',
        onMtm: false,
        onAutoPay: false,
        autopayConcAmt: 0,
        disableAllPaymentOptions: false,
        paymentRestrictionReasons: [],
        occupantNames: [],
        leaseTerm: 0,
        noticeDays: 0,
        propertyName: '',
        propertyPhoneNumber: '',
        propertyEmail: '',
        propertyNumber: 0,
        communityMastheadImageURI: '',
        communityLogoImageURI: '',
        leaseStartDt: '01/01/1900',
        leaseEndDt: '01/01/1900',
        hasUnreadNotifications: false,
        payleaseLoginLink: '',
        payleaseOneTimeLink: '',
        payleaseAutoPayLink: '',
        payleaseAutoPayPaymentLink: '',
        numPets: 0,
        numVehicles: 0,
        numRentables: 0,
        isEmployee: false,
        leaseTypeDesc: '',
        expectMoveoutReason: '',
        expectMoveinDt: '01/01/1900',
        moveinDt: '01/01/1900',
        noticeDt: '01/01/1900',
        expectMoveoutDt: '01/01/1900',
        moveoutDt: '01/01/1900',
        expectXferDt: '01/01/1900',
        lastPaymentDt: '01/01/1900',
        lastPaymentAmt: 0,
        contractPdfLink: '',
        inventoryConditionPdfLink: '',
        allowNTV: false,
        allowRenewal: false,
        featureToggles: [],
        propertySpecificDt: '01/01/1900',
        propertySpecificTimeStamp: -1,
        totalLateFees: 0.0,
        isHillsboroughLateNoticeApplicable: false,
        corporateAdminYn: false,
        adminYn: false,
        lockPaymentAmountYn: false,
    };
}

/* START CUSTOM CODE */

export const isInLease = (residency: SmarthubResidencyV1): boolean => {
    return (
        !residency.onMtm &&
        null != residency.leaseEndDt &&
        ['Current', 'Notice', 'TransferOut'].includes(residency.statusCd)
    );
};

export const isOnMtm = (residency: SmarthubResidencyV1): boolean => {
    return residency.onMtm && ['Current', 'Notice', 'TransferOut'].includes(residency.statusCd);
};

export const enableResidentId = (residency: SmarthubResidencyV1): boolean => {
    return ['Current', 'Notice', 'TransferOut'].includes(residency.statusCd) && Platform.OS !== 'web';
};

export interface residencyInfo {
    propertyCd: string;
    residencyId: number;
    residencyKey: string;
}

/* END CUSTOM CODE */
