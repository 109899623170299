export type SmarthubPaymentRestrictionReasons =
    | 'PreviousResidentSentToCollections'
    | 'PreviousResidentUnsubmittedSODA'
    | 'OnlinePaymentStatusNotAllowed'
    | 'PropertyPolicyNoCCandACH'
    | 'AmbiguousBill'
    | 'BalanceRequiresPublishing';

export type NullableSmarthubPaymentRestrictionReasons = SmarthubPaymentRestrictionReasons | null;

export const SmarthubPaymentRestrictionReasonsOptions = [
    { value: 'PreviousResidentSentToCollections', label: 'Previous Resident Sent To Collections' },
    { value: 'PreviousResidentUnsubmittedSODA', label: 'Previous Resident Unsubmitted SODA' },
    { value: 'OnlinePaymentStatusNotAllowed', label: 'Online Payment Status Not Allowed' },
    { value: 'PropertyPolicyNoCCandACH', label: 'Property Policy No CC and ACH' },
    { value: 'AmbiguousBill', label: 'Ambiguous Bill' },
    { value: 'BalanceRequiresPublishing', label: 'Balance Requires Publishing' },
];

export const SmarthubPaymentRestrictionReasonsOptionsWithBlank = [{ value: '', label: '' }].concat(
    SmarthubPaymentRestrictionReasonsOptions,
);

/* START CUSTOM CODE */
/* END CUSTOM CODE */
