import { AbstractRequestAuthenticatedCommand } from '../AbstractRequestAuthenticatedCommand';
import { ReopenRequestResultV1 } from './ReopenRequestResultV1';
import { ReOpenRequestReason } from '../../shared/businesslogic/maintenance/dataobjects/ReOpenRequestReason';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.ReopenRequestCommandV1
 */
export interface ReopenRequestCommandV1 extends AbstractRequestAuthenticatedCommand<ReopenRequestResultV1> {
    reason: ReOpenRequestReason;
    reasonDetail: string;
    residentId: number;
}

export function createReopenRequestCommandV1(): ReopenRequestCommandV1 {
    return {
        reason: 'ProblemNotSolved',
        reasonDetail: '',
        residentId: 0,
        propertyCd: '',
        requestId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.ReopenRequestCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
