import React from 'react';
import { Platform, StyleProp, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'react-native-paper';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { DeemphansizedTextSmall } from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';

interface SmarthubVersionInfoProps {
    style?: StyleProp<any>;
}

const SmarthubVersionInfo: React.FC<SmarthubVersionInfoProps> = observer((props: SmarthubVersionInfoProps) => {
    const theme = useTheme();
    const { appStore } = useStore();
    return (
        <View style={props.style || [styles.container, { backgroundColor: theme.colors.background }]}>
            <DeemphansizedTextSmall style={props.style}>
                {Constants?.expoConfig?.version},{Constants?.expoConfig?.sdkVersion},
                {Platform.OS != 'web' ? Updates.channel + ',' : ''},
                {(Constants?.expoConfig?.extra && Constants.expoConfig.extra['environment']) || '?'}
                {(appStore.getManifestBuildNumber() && ',' + appStore.getManifestBuildNumber()) || ''}
                {(appStore.getNativeBuildNumber() && ',' + appStore.getNativeBuildNumber()) || ''}
            </DeemphansizedTextSmall>
        </View>
    );
});

export default SmarthubVersionInfo;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '50%',
    },
    row: {
        justifyContent: 'space-between',
    },
});
