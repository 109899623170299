export const groupBy = (array: Array<any>, key: string) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
    }, {}); // empty object is the initial value for result object
};

export function groupByToMap<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    const map = new Map();
    list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export const arrayHasNonNullValues = (array: Array<any>): boolean => {
    return (
        null != array &&
        array.some(function (el) {
            return el !== null;
        })
    );
};

export const arrayIsEmpty = (array: Array<any>): boolean => {
    return null == array || array.length === 0 || !array.some(el => el !== null);
};
