import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { FormLabelText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import Section from 'components/primitives/Section';
import InputDropdown from 'components/input/InputDropdown';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalStyles } from 'theme/style/GlobalStyles';
import { action, runInAction } from 'mobx';
import InputRadio from 'components/input/InputRadio';
import { ntvPreferContactMethodOptions } from 'screens/home/manageMyLease/noticeToVacate/NtvHelper';
import { navigationRoutes } from 'utils/NavigationUtils';
import { testProps } from 'components/ComponentTypes';

type NoticeToVacateCancelProps = {
    navigation: StackNavigationProp<any>;
};

const NoticeToVacateCancel: React.FC<NoticeToVacateCancelProps> = observer(
    ({ navigation }: NoticeToVacateCancelProps) => {
        const { userSessionStore, ntvStore, uiStore } = useStore();
        const { smarthubUser, confirmActiveResidency, sessionActiveResidency } = userSessionStore;
        const [contactResidentId, setContactResidentId] = useState(sessionActiveResidency.residentId);
        const [preferContactOption, setPreferContactOption] = useState('');
        const [showPreferContactOption, setShowPreferContactOption] = useState(false);

        const onChangePreferContactPerson = (selected: string) => {
            const selectedResidentId = Number(selected);
            setContactResidentId(selectedResidentId);
            const contactOptions = ntvPreferContactMethodOptions(selectedResidentId, ntvStore.editNtvData.applicants);
            const firstOption = contactOptions[0]?.value || '';
            onSelectedContactMethod(firstOption);
            setShowPreferContactOption(!!firstOption);
        };

        const onSelectedContactMethod = (value: string) => {
            setPreferContactOption(value);
        };

        const onSubmit = async () => {
            uiStore.showActivityLoader();
            let contactMethod = '';
            let contactDetail = '';
            if (preferContactOption) {
                preferContactOption.split('_');
                contactMethod = preferContactOption.split('_')[0] === 'email' ? 'email' : 'phone';
                contactDetail = preferContactOption.split('_')[1];
            }
            const submittingResult = await ntvStore.cancelNoticeToVacateAsync(
                sessionActiveResidency.propertyCd,
                sessionActiveResidency.residencyId,
                sessionActiveResidency.residentId,
                contactResidentId,
                contactMethod,
                contactDetail,
            );
            uiStore.hideActivityLoader();

            if (submittingResult.succeed) {
                navigation.navigate(navigationRoutes.homeRoutes.home, { screen: navigationRoutes.homeRoutes.home });
                uiStore.addToast({
                    message: `We have received your request to cancel notice. We'll get back to you soon with an update.`,
                    autoDismiss: true,
                });
            } else {
                uiStore.showAlert({
                    title: 'Error',
                    message: submittingResult.exceptions,
                });
            }
        };

        useEffect(() => {
            runInAction(() => {
                onChangePreferContactPerson(contactResidentId.toString());
            });
            confirmActiveResidency(navigation);
        }, [smarthubUser.authorizedResidencies]);

        return (
            <SafeAreaView style={globalStyles.container}>
                <KeyboardAwareScrollView
                    keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                    enableResetScrollToCoords={false}>
                    <View style={globalStyles.container}>
                        <FormLabelText>Who should we contact about this notice?</FormLabelText>

                        <Section>
                            <InputRadio
                                options={ntvStore.editNtvData.applicants.map(resident => {
                                    return {
                                        label: `${resident.firstName} ${resident.lastName}`,
                                        value: resident.residentId.toString(),
                                    };
                                })}
                                initialValue={contactResidentId.toString()}
                                onChange={action(selected => {
                                    onChangePreferContactPerson(selected);
                                })}
                                style={'small'}
                            />
                        </Section>

                        {contactResidentId > 0 && showPreferContactOption && (
                            <Section>
                                <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                    Preferred Contact Method
                                </FormLabelText>

                                <InputDropdown
                                    selectedValue={preferContactOption}
                                    options={ntvPreferContactMethodOptions(
                                        contactResidentId,
                                        ntvStore.editNtvData.applicants,
                                    )}
                                    onChange={value => {
                                        onSelectedContactMethod(value as string);
                                    }}
                                />
                            </Section>
                        )}

                        <Section>
                            <View style={[styles.button]}>
                                <Button
                                    inactive={uiStore.activityLoaderVisible || sessionActiveResidency.adminYn}
                                    onClick={onSubmit}
                                    {...testProps('submit-button')}>
                                    Request Cancellation
                                </Button>
                            </View>
                        </Section>
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        );
    },
);

export default NoticeToVacateCancel;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: '100%',
    },
});
