import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetContractSigningUrlResultV1 } from './GetContractSigningUrlResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.contract.GetContractSigningUrlCommandV1
 */
export interface GetContractSigningUrlCommandV1
    extends AbstractResidencyAuthenticatedCommand<GetContractSigningUrlResultV1> {
    viewId: string;
}

export function createGetContractSigningUrlCommandV1(): GetContractSigningUrlCommandV1 {
    return {
        viewId: '',
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.contract.GetContractSigningUrlCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
