import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { SubmitZegoPaymentResultV1 } from './SubmitZegoPaymentResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.CheckZegoPaymentCommandV1
 */
export interface CheckZegoPaymentCommandV1 extends AbstractResidentAuthenticatedCommand<SubmitZegoPaymentResultV1> {
    paymentRequestId: number;
    paymentRefId: string;
}

export function createCheckZegoPaymentCommandV1(): CheckZegoPaymentCommandV1 {
    return {
        paymentRequestId: 0,
        paymentRefId: '',
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.CheckZegoPaymentCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
