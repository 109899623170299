import { Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import {
    DeephansizedRegularText,
    ExtraLargeText,
    RegularBoldText,
    RegularText,
} from 'components/primitives/StyledText';
import React, { useEffect, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStore } from 'contexts/StoreContextProvider';
import { Divider, Surface, useTheme } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import LeaseAdjustmentBanner from 'components/cards/LeaseAdjustmentBanner';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { LeaseAlertsBanner } from 'components/cards/LeaseAlertsBanner';
import { arrayHasNonNullValues } from 'utils/ArrayUtils';
import { formatDateStringMonthLabel } from 'utils/DateUtils';
import { stringIsNotEmpty } from 'utils/StringUtils';
import Hyperlink from 'components/primitives/Hyperlink';
import Button from 'components/primitives/Button';
import Container from 'components/primitives/Container';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { getSmarthubRPCHandler } from 'utils/AppConfigHelper';
import * as WebBrowser from 'expo-web-browser';
import { SmarthubPaymentRestrictionReasonsOptions } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubPaymentRestrictionReasons';
import { openSmartMove } from 'screens/smartmove/SmartMoveApplication';
import { generateGetTransactionsCMD, useGetTransactions } from 'queries/hooks/home/TransactionsHooks';
import { SmarthubTransactionV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionV1';
import { isPaymentTransaction } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionType';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { navigationRoutes } from 'utils/NavigationUtils';
import useBackOnDisplay from 'utils/useBackOnDisplay';

export type MessageBalancePayProps = {
    balancePayOnly?: boolean;
};

export const MessageBalancePay: React.FC<MessageBalancePayProps> = observer(({ balancePayOnly }) => {
    const navigation = useNavigation();
    const route = useRoute();
    const theme = useTheme();
    const { userSessionStore, uiStore } = useStore();
    const { sessionActiveResidency, hasSessionActiveResidency } = userSessionStore;
    const currentBalance = sessionActiveResidency?.currentBalance || 0;
    const lastPaymentDate = sessionActiveResidency?.lastPaymentDt;
    const lastPaymentAmt = sessionActiveResidency?.lastPaymentAmt;
    const { useDrawerNavigation, useFullExperience } = useResponsiveContext();
    const [latestPayment, setLatestPayment] = useState({} as SmarthubTransactionV1 | undefined);

    const transactionsCmd = generateGetTransactionsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
    );
    const { data, isSuccess, isLoading } = useGetTransactions(transactionsCmd);
    const isBackOnDisplay = useBackOnDisplay(true, true);

    useEffect(() => {
        isLoading ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [isLoading]);

    useEffect(() => {
        if (data) {
            for (let i = 0; i < data.pages[0].transactions.length; i++) {
                const result = data.pages[0].transactions[i].data.find(transaction => {
                    return isPaymentTransaction(transaction.type);
                });
                if (result !== undefined) {
                    setLatestPayment(result);
                    break;
                }
            }
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (isBackOnDisplay) {
            userSessionStore.reloadCurrentResidencyForAutoPay();
        }
    }, [isBackOnDisplay]);

    const createLateFeesLink = () => {
        return (
            getSmarthubRPCHandler() +
            `/viewInsecurePDF?Type=HilsboroughLateFeeNotice&property=${sessionActiveResidency.propertyCd}&residency=${sessionActiveResidency.residencyId}`
        );
    };

    if (!hasSessionActiveResidency) {
        return null;
    }

    const _proceedToLastPaymentDetails = () => {
        const paymentDetailOpts = {
            referenceNumber: latestPayment?.referenceNumber,
            transactionType: latestPayment?.type,
        };
        if (route.name === 'Home') navigation.navigate('PaymentDetails', paymentDetailOpts);
        else navigation.navigate('Home', { screen: 'PaymentDetails', params: paymentDetailOpts });
    };

    const _proceedToAutoPayDirectly = (replace?: boolean) => {
        logInfo(INFO_LOGGER, 'proceeding to zego screen...');
        const autopayOpts = {
            navigateTo:
                sessionActiveResidency.onAutoPay || userSessionStore.autoPaySetupPending
                    ? 'autopay'
                    : 'autopay_payment',
            title:
                sessionActiveResidency.onAutoPay || userSessionStore.autoPaySetupPending
                    ? 'Manage AutoPay'
                    : 'Set Up AutoPay',
        };
        userSessionStore.setAutoPaySetupPending(true);
        if (useDrawerNavigation) {
            if (route.name === 'Home') navigation.navigate(navigationRoutes.homeRoutes.paymentScreen, autopayOpts);
            else
                navigation.navigate('Home', { screen: navigationRoutes.homeRoutes.paymentScreen, params: autopayOpts });
        } else {
            navigation.navigate(navigationRoutes.loggedInRoutes.paymentScreenModal, autopayOpts);
        }
    };

    const _openDocument = (file: string) => {
        WebBrowser.openBrowserAsync(file);
    };

    const isApp = sessionActiveResidency && sessionActiveResidency.statusCd === 'Leased';

    return (
        <Surface style={useDrawerNavigation && useFullExperience ? styles.surface : styles.surfacenoelevation}>
            {!balancePayOnly &&
                null != sessionActiveResidency.leaseAdjustment &&
                null != sessionActiveResidency.leaseAdjustmentMessage && (
                    <View
                        style={[
                            styles.card,
                            { width: getMesageBalanceCardWidth(useFullExperience, useDrawerNavigation) },
                        ]}>
                        <LeaseAdjustmentBanner />
                        <Divider />
                    </View>
                )}
            {!balancePayOnly && arrayHasNonNullValues(sessionActiveResidency.leaseAlerts) && (
                <View
                    style={[
                        globalStyles.tripleSpaceAbove,
                        styles.card,
                        useDrawerNavigation ? globalStyles.horizontalPad : {},
                        { width: getMesageBalanceCardWidth(useFullExperience, useDrawerNavigation) },
                    ]}>
                    <LeaseAlertsBanner />
                </View>
            )}
            {isApp && (
                <TouchableOpacity
                    style={[styles.smartmoveLogoWrapper]}
                    onPress={() => openSmartMove(useDrawerNavigation, navigation)}>
                    <Image
                        style={styles.smartmoveLogo}
                        source={require('../../../assets/images/smartmove-logo-transparent.png')}
                    />
                </TouchableOpacity>
            )}
            {sessionActiveResidency.disableAllPaymentOptions &&
                sessionActiveResidency.paymentRestrictionReasons.length > 0 &&
                sessionActiveResidency.paymentRestrictionReasons.filter(
                    val => val !== SmarthubPaymentRestrictionReasonsOptions[1].value,
                ).length > 0 && (
                    <RegularText style={[globalStyles.innerContainer, globalStyles.centeredText]}>
                        Your balance cannot be determined at this time. Please contact the community office for more
                        information.
                    </RegularText>
                )}
            {sessionActiveResidency.disableAllPaymentOptions &&
                sessionActiveResidency.paymentRestrictionReasons.length > 0 &&
                sessionActiveResidency.paymentRestrictionReasons.filter(
                    val => val === SmarthubPaymentRestrictionReasonsOptions[1].value,
                ).length > 0 && (
                    <RegularText style={[globalStyles.innerContainer, globalStyles.centeredText]}>
                        Your final statement is being generated. Once complete, you will receive the statement in an
                        email.
                    </RegularText>
                )}
            {!sessionActiveResidency.disableAllPaymentOptions && (
                <>
                    {undefined !== sessionActiveResidency.isHillsboroughLateNoticeApplicable &&
                        sessionActiveResidency.isHillsboroughLateNoticeApplicable &&
                        sessionActiveResidency.totalLateFees > 0 && (
                            <View>
                                <RegularText style={[globalStyles.doubleSpaceAbove, globalStyles.centeredText]}>
                                    Your balance includes late fees
                                </RegularText>
                                <Hyperlink
                                    style={[globalStyles.doubleSpaceAbove, globalStyles.centeredText]}
                                    onClick={() => {
                                        _openDocument(createLateFeesLink());
                                    }}>
                                    View your Late Fee Notice (PDF)
                                </Hyperlink>
                            </View>
                        )}
                    <View style={[styles.balanceandpaycontainer, globalStyles.sectionSpaceAbove]}>
                        {currentBalance !== 0 && (
                            <View style={[styles.partyTime]}>
                                <DeephansizedRegularText
                                    style={[globalStyles.singleSpaceBelow, globalStyles.centeredText]}>
                                    Current Balance
                                </DeephansizedRegularText>
                                <ExtraLargeText>
                                    {currentBalance && formatCentsAsDollars(currentBalance)}
                                </ExtraLargeText>
                            </View>
                        )}
                        {currentBalance === 0 && (
                            <View style={[styles.partyTime]}>
                                <Image
                                    source={require('../../../assets/images/party-popper.png')}
                                    style={[styles.partyHat]}
                                />
                                <DeephansizedRegularText style={globalStyles.doubleSpaceAround}>
                                    Your account balance is $0.00. Nice!
                                </DeephansizedRegularText>
                            </View>
                        )}
                        {lastPaymentDate && lastPaymentAmt > 0 && (
                            <View style={[styles.partyTime, globalStyles.doubleSpaceAround]}>
                                <RegularText {...testProps('payment-hyperlink')}>
                                    <Hyperlink
                                        {...testProps('last-payment-hyperlink')}
                                        onClick={_proceedToLastPaymentDetails}>
                                        Last Payment: {formatCentsAsDollars(lastPaymentAmt)} on{' '}
                                        {formatDateStringMonthLabel(lastPaymentDate)}
                                    </Hyperlink>
                                </RegularText>
                            </View>
                        )}
                    </View>

                    {!isApp && currentBalance !== 0 && stringIsNotEmpty(sessionActiveResidency.payleaseOneTimeLink) && (
                        <View style={[styles.button, globalStyles.doubleSpaceAround]}>
                            <Button
                                style={{ height: SmarthubTheme.layout.GRIDINCREMENT * 6 }}
                                buttonType={'primary'}
                                {...testProps('pay-now-button')}
                                onClick={() => {
                                    if (useDrawerNavigation) {
                                        if (route.name === 'Home') navigation.navigate('MakeAPayment');
                                        else navigation.navigate('Home', { screen: 'MakeAPayment' });
                                    } else {
                                        navigation.navigate('MakeAPaymentModal');
                                    }
                                }}>
                                Pay Now
                            </Button>
                        </View>
                    )}

                    {!balancePayOnly && !isApp && (
                        <View
                            style={[
                                globalStyles.doubleSpaceBelow,
                                styles.autopayfullwidth,
                                globalStyles.sectionSpaceAbove,
                            ]}>
                            <Container
                                dropShadow={true}
                                borderStyle={'grey'}
                                width={'100%'}
                                height={SmarthubTheme.layout.GRIDINCREMENT * 7}>
                                <View style={[styles.autopaysurface, { backgroundColor: theme.colors.background }]}>
                                    <RegularText>
                                        AutoPay:{' '}
                                        <RegularBoldText
                                            style={
                                                sessionActiveResidency.onAutoPay ? styles.greytext : styles.orangetext
                                            }>
                                            {sessionActiveResidency.onAutoPay ? 'ON' : 'OFF'}
                                        </RegularBoldText>
                                    </RegularText>
                                    {!sessionActiveResidency.adminYn && (
                                        <RegularText>
                                            <Hyperlink onClick={_proceedToAutoPayDirectly}>
                                                {sessionActiveResidency.onAutoPay ? 'Manage AutoPay' : 'Set Up AutoPay'}
                                            </Hyperlink>
                                        </RegularText>
                                    )}
                                </View>
                            </Container>
                        </View>
                    )}
                </>
            )}
        </Surface>
    );
});

export const getMesageBalanceCardWidth = (
    useFullExperience: boolean,
    useDrawerNavigation: boolean,
): number | string => {
    const cardWidth = useFullExperience
        ? SmarthubTheme.layout.SIDEWIDTH - SmarthubTheme.layout.SURROUNDMARGIN
        : useDrawerNavigation
        ? '60%'
        : '100%';
    return cardWidth;
};

const styles = StyleSheet.create({
    card: {
        minHeight: 130,
        maxHeight: 130,
    },
    slideContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    surface: {
        flex: 1,
        alignItems: 'center',
        elevation: Platform.OS === 'web' ? 0 : SmarthubTheme.layout.SURFACEELEVATION,
        paddingHorizontal: SmarthubTheme.layout.INNERPAD,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT,
        borderWidth: 0.1,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.lightgrey,
        justifyContent: 'space-evenly',
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    surfacenoelevation: {
        alignItems: 'center',
        elevation: 0,
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.white,
        justifyContent: 'space-between',
    },
    balanceandpaycontainer: {
        width: '100%',
        alignItems: 'center',
    },
    nobalancecontainer: {
        paddingHorizontal: SmarthubTheme.layout.INNERPAD,
    },
    paynowbutton: {
        justifyContent: 'center',
        backgroundColor: SmarthubTheme.colors.orange,
        width: SmarthubTheme.layout.GRIDINCREMENT * 20,
        height: SmarthubTheme.layout.GRIDINCREMENT * 5,
    },
    autopaysurface: {
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    autopayfullwidth: { width: '100%' },
    orangetext: {
        color: SmarthubTheme.colors.orange,
    },
    greytext: {
        color: SmarthubTheme.colors.darkgrey,
    },
    chevronleft: {
        //left: -SmarthubTheme.layout.GRIDINCREMENT * 3,
        //alignSelf: 'flex-start',
    },
    chevronright: {
        //left: SmarthubTheme.layout.GRIDINCREMENT * 3,
        //alignSelf: 'flex-end',
    },
    partyTime: {
        // flex: 1,
    },
    partyHat: {
        height: 84,
        width: 84,
        alignSelf: 'center',
    },
    button: {
        flex: 1,
        width: '100%',
    },
    smartmoveLogoWrapper: {
        alignItems: 'center',
    },
    smartmoveLogo: {
        width: 277,
        height: 36,
    },
});
