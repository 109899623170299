import React from 'react';
import { Image, SafeAreaView, StyleSheet, View } from 'react-native';
import { RegularBoldText } from 'components/primitives/StyledText';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import LeaseSwitchLink from 'components/cards/LeaseSwitchLink';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps, updateStringForIDName } from 'components/ComponentTypes';
import { isDesktopNotMobile, isDesktopAndMobile } from 'utils/uiUtils';

const WebLayout: React.FC<{
    children: React.ReactNode;
}> = observer(({ children }) => {
    const responsiveContext = useResponsiveContext();
    const theme = useTheme();
    const { userSessionStore, uiStore } = useStore();
    const { smarthubUser } = userSessionStore;
    const fullExperience = responsiveContext.useFullExperience;

    return (
        <SafeAreaView
            style={[
                globalStyles.justflex1,
                {
                    backgroundColor: uiStore.useDarkTheme ? theme.colors.darkgrey : theme.colors.lightestgrey,
                },
            ]}
        >
            <View style={[styles.headerbar, { height: 48 + useSafeAreaInsets().top }]}>
                <View style={styles.venterraheaderlogoview}>
                    <Image
                        source={require('../../../assets/images/venterra-logo-navigation.png')}
                        style={styles.venterraLogoColor}
                    />
                </View>
                <View style={[styles.unitstringview]}>
                    {userSessionStore.hasSessionActiveResidency && userSessionStore.activeUnitString && (
                        <>
                            <View
                                {...testProps(updateStringForIDName(userSessionStore.activeUnitString))}
                                style={styles.activeunitstring}
                            >
                                <RegularBoldText style={[styles.whitetext]}>
                                    {smarthubUser.authorizedResidencies.length > 1 && fullExperience && 'Viewing'}{' '}
                                    {userSessionStore.activeUnitString}
                                </RegularBoldText>
                                <RegularBoldText style={[styles.whitetext, styles.whitetextBreak]}>
                                    ({userSessionStore.activePropertyName})
                                </RegularBoldText>
                            </View>

                            {smarthubUser.authorizedResidencies.length > 1 && (
                                <LeaseSwitchLink
                                    labelStyle={[SmarthubTheme.typography.regularBold, styles.whitetext]}
                                />
                            )}
                        </>
                    )}
                </View>
            </View>

            {children}

            <View style={globalStyles.singleSpaceAbove}></View>

            <View style={[styles.footerbar]}>
                <Image
                    source={require('../../../assets/images/live_it_love_it.png')}
                    style={styles.liveitloveitLogoColor}
                />
            </View>
        </SafeAreaView>
    );
});
export default WebLayout;

const styles = StyleSheet.create({
    headerbar: {
        backgroundColor: SmarthubTheme.colors.primary,
        position: 'absolute',
        width: SmarthubTheme.layout.FULLWIDTH,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    },
    bodycolumns: {
        flexDirection: 'row',
        width: SmarthubTheme.layout.FULLWIDTH,
        maxWidth: 1400,
        height: SmarthubTheme.layout.FULLHEIGHT,
        //marginVertical: SmarthubTheme.layout.HEADERFOOTERPAD,
        justifyContent: 'center',
        alignSelf: 'center',
    },
    rightcontainer: {
        width: SmarthubTheme.layout.SIDEWIDTH,
        marginRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    footerbar: {
        backgroundColor: SmarthubTheme.colors.primary,
        height: 48,
        position: 'absolute',
        bottom: 0,
        width: SmarthubTheme.layout.FULLWIDTH,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: SmarthubTheme.layout.HEADERFOOTERPAD,
    },
    venterraLogoColor: {
        height: 20,
        width: 147,
    },
    liveitloveitLogoColor: {
        height: 20,
        width: 147,
    },
    drawerwide: {
        width: SmarthubTheme.layout.SIDEWIDTH,
        borderRightWidth: 0,
    },
    drawernarrow: {
        width: SmarthubTheme.layout.NARROWDRAWER,
        borderRightWidth: 0,
    },
    addButtonImage: {
        height: 35,
        width: 35,
        resizeMode: 'contain',
        paddingLeft: 67,
    },
    venterraheaderlogoview: {
        width: '66%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    unitstringview: {
        width: 'auto',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: SmarthubTheme.layout.SURROUNDMARGIN,
        color: SmarthubTheme.colors.white,
    },
    activeunitstring: {
        width: 'auto',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    whitetext: {
        color: SmarthubTheme.colors.white,
    },
    whitetextBreak: {
        position: isDesktopNotMobile() || isDesktopAndMobile() ? 'relative' : 'absolute',
        marginTop: isDesktopNotMobile() || isDesktopAndMobile() ? 0 : 20,
        marginLeft: isDesktopNotMobile() || isDesktopAndMobile() ? 4 : 0,
    },
});
