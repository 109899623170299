import React, { useEffect } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { useNavigation } from '@react-navigation/native';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Hyperlink from 'components/primitives/Hyperlink';
import RenewalSigningScreenResident from 'screens/managelease/components/RenewalSigningScreenResident';
import { useGetRenewalContractSigningInfoQuery } from 'queries/hooks/contract/useGetRenewalContractSigningInfoQuery';
import { formatDateStringMonthLabel } from 'utils/DateUtils';
import { navigationRoutes } from 'utils/NavigationUtils';
import { SmarthubContractSignerV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/contract/SmarthubContractSignerV1';
import { globalStyles } from 'theme/style/GlobalStyles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

// Set minimum rent threshold to $200
const minRentThreshold = 20000;

const RenewalSigningScreen: React.FC = observer(() => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const navigation = useNavigation();

    const { queryKey, queryResult } = useGetRenewalContractSigningInfoQuery(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        uiStore,
        {
            enabled: hasSessionActiveResidency,
            onSuccess: data => {
                if (!data.signingInfo) {
                    navigation.navigate(navigationRoutes.homeRoutes.home);
                }
            },
        },
    );

    useEffect(() => {
        if (queryResult.isFetching) {
            uiStore.showActivityLoader();
        } else {
            uiStore.hideActivityLoader();
        }
    }, [queryResult.isFetching]);

    const allResidentsHaveSigned = (): boolean => {
        const signedSigners = queryResult.data?.signingInfo.signers.filter(s => s.signingStatus === 'Signed');

        if (
            signedSigners &&
            queryResult.data?.signingInfo.signers &&
            signedSigners.length === queryResult.data?.signingInfo.signers.length
        ) {
            return true;
        }

        return false;
    };

    const currentResidentContractHasSigned = (): boolean => {
        const currentSigner = queryResult.data?.signingInfo.signers.find(s => isCurrentResident(s));

        if (currentSigner && currentSigner.signingStatus === 'Signed') {
            return true;
        }

        return false;
    };

    const renderSigningForm = (): React.ReactNode => {
        return (
            <>
                {!allResidentsHaveSigned() && (
                    <View>
                        <RegularBoldText style={styles.pageHeading}>
                            Your new contract is ready for signing
                        </RegularBoldText>
                        <RegularText style={styles.pageSubHeading}>
                            Please sign the contract below, or sign the copy we&apos;ve just emailed to you as soon as
                            you can.
                        </RegularText>
                    </View>
                )}

                {queryResult.data?.signingInfo.signers.map(signer => (
                    <RenewalSigningScreenResident
                        signer={signer}
                        showSignNowButton={isCurrentResident(signer)}
                        key={signer.viewId}
                    />
                ))}

                {!currentResidentContractHasSigned() && (
                    <View style={styles.signLater}>
                        <Hyperlink
                            onClick={() => {
                                navigation.navigate('Home');
                            }}>
                            I&apos;ll sign my new lease later
                        </Hyperlink>
                    </View>
                )}
            </>
        );
    };

    const isCurrentResident = (signer: SmarthubContractSignerV1) => {
        return sessionActiveResidency.residentId === signer.residentId;
    };

    if (!hasSessionActiveResidency || queryResult.isLoading || !queryResult.data || !queryResult.data.signingInfo)
        return null;

    if (!queryResult.data.hasPendingRenewalContract) {
        return (
            <SafeAreaView style={globalStyles.container}>
                <RegularBoldText style={styles.pageHeading}>
                    No pending renewal contract available for this residency
                </RegularBoldText>
            </SafeAreaView>
        );
    }

    if (!queryResult.data.signingInfo.eSigningStartedYn) {
        return (
            <SafeAreaView style={globalStyles.container}>
                <RegularBoldText style={styles.pageHeading}>
                    Your lease renewal contract will be available to sign shortly
                </RegularBoldText>
            </SafeAreaView>
        );
    }

    return (
        <SafeAreaView style={globalStyles.container}>
            <KeyboardAwareScrollView
                keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                enableResetScrollToCoords={false}>
                <View style={globalStyles.container}>
                    {allResidentsHaveSigned() && (
                        <View>
                            <RegularBoldText style={styles.pageHeading}>Your contract has been signed</RegularBoldText>
                            {queryResult.data.pendingOwnerRepSignature && (
                                <RegularText style={styles.pageSubHeading}>
                                    Thank you for continuing to call our community home. Once a representative from our
                                    management team signs the contract, we will make it available for you to view and
                                    download.
                                </RegularText>
                            )}
                            {!queryResult.data.pendingOwnerRepSignature && (
                                <RegularText style={styles.pageSubHeading}>
                                    Great news! All parties have signed the new contract and your lease has been
                                    renewed. Thank you for continuing to call our community home.
                                </RegularText>
                            )}
                            <RegularText style={styles.pageSubHeading}>
                                Your new lease will be in effect on{' '}
                                {formatDateStringMonthLabel(queryResult.data.renewalLeaseStartDt)}
                            </RegularText>
                            {renderSigningForm()}
                        </View>
                    )}
                    {!allResidentsHaveSigned() && queryResult.data.signingInfo.rent <= minRentThreshold && (
                        <View>
                            <RegularBoldText>
                                There was a problem creating your contract. Please contact the community at{' '}
                                {sessionActiveResidency.propertyPhoneNumber}
                            </RegularBoldText>
                        </View>
                    )}
                    {!allResidentsHaveSigned() &&
                        queryResult.data.signingInfo.rent > minRentThreshold &&
                        renderSigningForm()}
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
});

export default RenewalSigningScreen;

const styles = StyleSheet.create({
    pageHeading: {
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    pageSubHeading: {
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    signLater: {
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: SmarthubTheme.colors.border,
        paddingVertical: SmarthubTheme.layout.SECTIONMARGIN,
        paddingTop: SmarthubTheme.layout.SURROUNDMARGIN * 3,
    },
});
