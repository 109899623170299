import { SmartmovePetV1 } from '../../businesslogic/smarthub/versioneddataobjects/smartmove/SmartmovePetV1';
import { SmartmoveVehicleV1 } from '../../businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveVehicleV1';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SubmitSmartmoveVehiclesAndPetsResultV1 } from './SubmitSmartmoveVehiclesAndPetsResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.smartmove.SubmitSmartmoveVehiclesAndPetsCommandV1
 */
export interface SubmitSmartmoveVehiclesAndPetsCommandV1
    extends AbstractResidencyAuthenticatedCommand<SubmitSmartmoveVehiclesAndPetsResultV1> {
    smartmoveVehicles: Array<SmartmoveVehicleV1>;
    smartmovePets: Array<SmartmovePetV1>;
    residentId: number;
}

export function createSubmitSmartmoveVehiclesAndPetsCommandV1(): SubmitSmartmoveVehiclesAndPetsCommandV1 {
    return {
        smartmoveVehicles: [],
        smartmovePets: [],
        residentId: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.smartmove.SubmitSmartmoveVehiclesAndPetsCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
