import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Linking, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { FormLabelText, Heading2, RegularText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useStore } from 'contexts/StoreContextProvider';
import InputCheckbox from 'components/input/InputCheckbox';
import Container from 'components/primitives/Container';
import { observer } from 'mobx-react-lite';
import { SmarthubRequestSubmitData } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubRequestSubmitData';
import Section from 'components/primitives/Section';
import InputDropdown from 'components/input/InputDropdown';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import InputString from 'components/input/InputString';
import InputNumber from 'components/input/InputNumber';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SmartHubResidentContactInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/requests/SmartHubResidentContactInfoV1';
import { getContactMethodOptions } from 'screens/home/requests/requestUtils';
import { useFormik } from 'formik';
import useConfirmLeave from 'utils/useConfirmLeave';
import Hyperlink from 'components/primitives/Hyperlink';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import {
    generateSubmitRequestCMD,
    useGetCreateRequestData,
    useSubmitRequest,
} from 'screens/home/requests/RequestsHooks';

type CreateMaintenanceRequestProps = {
    navigation: StackNavigationProp<any>;
};

const schema = yup.object().shape({
    desc: yup.string().max(1024, 'Max length is 1024 characters').required('Please provide more details'),
    contactResidentId: yup.number().required('Please select contact person'),
    contactName: yup.string(),
    contactDetail: yup.string().max(500).required('Please select contact method'),
    contactOtherDetail: yup.string().when('contactDetail', {
        is: 'other',
        then: yup.string().max(500, 'Max length is 500 characters').required('Please provide contact method'),
        otherwise: yup.string(),
    }),
    allowEntry: yup.boolean(),
    alarmCode: yup.string().max(50, 'Max length is 50 characters'),
    specialInstruction: yup.string().max(1024, 'Max length is 1024 characters'),
});

const CreateMaintenanceRequest: React.FC<CreateMaintenanceRequestProps> = observer(
    ({ navigation }: CreateMaintenanceRequestProps) => {
        const { useDrawerNavigation } = useResponsiveContext();
        const { userSessionStore, requestStore, uiStore } = useStore();
        const { confirmActiveResidency, sessionActiveResidency } = userSessionStore;
        const [contactResident, setContactResident] = useState<SmartHubResidentContactInfoV1>();

        const isHomeOnNavStack = IsNavigationRouteOnStack(navigationRoutes.homeRoutes.home);

        const { data, isSuccess, isLoading } = useGetCreateRequestData();

        const onSubmit = (data: SmarthubRequestSubmitData) => {
            if (sessionActiveResidency) {
                uiStore.showActivityLoader();
                submitRequestMutation.mutate(
                    generateSubmitRequestCMD(
                        sessionActiveResidency.propertyCd,
                        sessionActiveResidency.residencyId,
                        sessionActiveResidency.residentId,
                        data,
                        requestStore.hasCovidSym,
                    ),
                );
            }
        };

        const formik = useFormik({
            validateOnMount: false,
            validateOnChange: false,
            validateOnBlur: false,
            initialValues: {
                propertyCd: sessionActiveResidency?.propertyCd,
                residencyId: sessionActiveResidency?.residencyId,
                residentId: sessionActiveResidency?.residentId,
                desc: '',
                contactResidentId: -1,
                contactName: '',
                contactDetail: '',
                contactOtherDetail: '',
                allowEntry: true,
                alarmCode: '',
                specialInstruction: '',
            },
            validationSchema: schema,
            onSubmit: (values: SmarthubRequestSubmitData) => {
                if (!uiStore.activityLoaderVisible) {
                    formik.resetForm({ values: formik.values });
                    formik.setSubmitting(true);
                    onSubmit(values);
                }
            },
        });

        const submitRequestMutation = useSubmitRequest(
            navigation,
            formik,
            useDrawerNavigation,
            isHomeOnNavStack,
            'MaintenanceRequest',
        );

        useConfirmLeave(navigation, formik);

        useEffect(() => {
            isLoading ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
        }, [isLoading]);

        useEffect(() => {
            confirmActiveResidency(navigation);
            if (data) {
                let currentResident = data.residentContactInfoList.find(
                    value => value.residentId === sessionActiveResidency.residentId,
                );
                if (currentResident === undefined) currentResident = data.residentContactInfoList[0];

                setContactResident(currentResident);
                const contactMethods = getContactMethodOptions(currentResident);

                formik.resetForm({
                    values: {
                        ...formik.values,
                        contactResidentId: currentResident.residentId,
                        contactName: currentResident.name,
                        contactDetail: contactMethods[0].value || '',
                        contactOtherDetail: '',
                        alarmCode: data.alarmCode || '',
                        specialInstruction: data.specialInstrs || '',
                    },
                });
            }
        }, [data]);

        if (!data || !isSuccess) {
            return null;
        } else
            return (
                <SafeAreaView style={globalStyles.container}>
                    <KeyboardAwareScrollView
                        keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                        enableResetScrollToCoords={false}
                    >
                        <View style={globalStyles.container}>
                            {!!data && (
                                <Section>
                                    <FormLabelText>
                                        If you have a maintenance emergency (e.g. toilet overflowing, water leaking,
                                        electrical power out), please call us immediately at{' '}
                                        <Hyperlink
                                            {...testProps('emergency-number')}
                                            onClick={() => Linking.openURL(`tel:${data.locationPhone}`)}
                                        >{`${data.locationPhone}`}</Hyperlink>
                                        . For other maintenance requests, please complete the form below.
                                    </FormLabelText>
                                </Section>
                            )}

                            {!data && (
                                <Section>
                                    <FormLabelText>
                                        If you have a maintenance emergency (e.g. toilet overflowing, water leaking,
                                        electrical power out), please call us immediately. For other maintenance
                                        requests, please complete the form below.
                                    </FormLabelText>
                                </Section>
                            )}

                            <Section>
                                <Heading2 style={[globalStyles.sectionSpaceBelow]}>
                                    Create a Maintenance Request
                                </Heading2>
                            </Section>

                            <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                Please describe your request below:
                            </FormLabelText>

                            <InputString
                                autoFocus={Platform.OS === 'web'}
                                onChange={formik.handleChange('desc')}
                                onBlur={formik.handleBlur('desc')}
                                initialValue={formik.values.desc}
                                multiline={true}
                                maxLength={1024}
                                numberOfLines={6}
                                error={!!formik.errors.desc}
                                errorMessage={formik.errors.desc}
                                {...testProps('details-input')}
                            />

                            {/*todo: uncomment for view photo*/}

                            {/*DEV-21397*/}
                            {/*Grey Out "Who whould we contact about this" in Create Maintenance Request*/}
                            {/*<Section>*/}
                            {/*    <FormLabelText style={globalStyles.doubleSpaceBelow}>*/}
                            {/*        Do you have any photos for this request?*/}
                            {/*    </FormLabelText>*/}
                            {/*    <View style={styles.addPhotoSection}>*/}
                            {/*        <Image*/}
                            {/*            source={require('../../../../assets/images/cameraPlus.png')}*/}
                            {/*            style={globalStyles.icon}*/}
                            {/*        />*/}
                            {/*        <View style={styles.addPhotoLabel}>*/}
                            {/*            <Hyperlink testID={'add-photos'}>Add Photos</Hyperlink>*/}
                            {/*        </View>*/}
                            {/*    </View>*/}
                            {/*</Section>*/}

                            {data && (
                                <View style={useDrawerNavigation ? styles.contactSectionDrawer : styles.contactSection}>
                                    {data.residentContactInfoList.length > 1 && (
                                        <Section style={useDrawerNavigation ? styles.contactSectionDrawerSection : ''}>
                                            <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                                Who should we contact about this?
                                            </FormLabelText>
                                            <InputDropdown
                                                selectedValue={formik.values.contactResidentId.toString()}
                                                options={data.residentContactInfoList.map(info => ({
                                                    label: info.name,
                                                    value: info.residentId.toString(),
                                                }))}
                                                onChange={value => {
                                                    if (data && data.residentContactInfoList) {
                                                        const selectedResident = data.residentContactInfoList.find(
                                                            contactInfo => contactInfo.residentId.toString() === value,
                                                        );
                                                        setContactResident(selectedResident);
                                                        if (selectedResident) {
                                                            formik.setFieldValue(
                                                                'contactResidentId',
                                                                selectedResident.residentId,
                                                                false,
                                                            );
                                                            formik.setFieldValue(
                                                                'contactName',
                                                                selectedResident.name,
                                                                false,
                                                            );
                                                            const contactMethods =
                                                                getContactMethodOptions(selectedResident);
                                                            formik.setFieldValue(
                                                                'contactDetail',
                                                                contactMethods[0].value,
                                                                false,
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                        </Section>
                                    )}

                                    {contactResident && (
                                        <Section style={useDrawerNavigation ? styles.contactSectionDrawerSection : ''}>
                                            <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                                Which contact method is preferred?
                                            </FormLabelText>

                                            <InputDropdown
                                                options={getContactMethodOptions(contactResident, false)}
                                                onChange={value => {
                                                    formik.setFieldValue('contactDetail', value);
                                                }}
                                                selectedValue={formik.values.contactDetail}
                                            />

                                            {formik.values.contactDetail === 'other' && (
                                                <>
                                                    <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                                        Other Contact Method:
                                                    </FormLabelText>
                                                    <InputString
                                                        onChange={formik.handleChange('contactOtherDetail')}
                                                        onBlur={formik.handleBlur('contactOtherDetail')}
                                                        initialValue={formik.values.contactOtherDetail}
                                                        maxLength={500}
                                                        error={!!formik.errors.contactOtherDetail}
                                                        errorMessage={formik.errors.contactOtherDetail}
                                                    />
                                                </>
                                            )}
                                            {/*<Hyperlink*/}
                                            {/*    style={globalStyles.alignSelfFlexEnd}*/}
                                            {/*    onClick={() =>*/}
                                            {/*        useDrawerNavigation*/}
                                            {/*            ? navigation.navigate('Profile')*/}
                                            {/*            : navigation.navigate('ProfileModal')*/}
                                            {/*    }>*/}
                                            {/*    Wrong Number? Update it Now*/}
                                            {/*</Hyperlink>*/}
                                        </Section>
                                    )}
                                </View>
                            )}

                            <Section>
                                <InputCheckbox
                                    style={'small'}
                                    initialStatus={formik.values.allowEntry ? 'unchecked' : 'checked'}
                                    label={
                                        'I would like to be contacted prior to Venterra employee(s) entering my home to complete this request.'
                                    }
                                    onChange={checkValue => {
                                        formik.setFieldValue('allowEntry', checkValue === 'unchecked' ? true : false);
                                    }}
                                    {...testProps('employee-enter-checkbox')}
                                />
                            </Section>

                            <Section>
                                <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                    If you have an alarm code, enter it below:
                                </FormLabelText>
                                <View style={styles.numberInput}>
                                    <InputNumber
                                        maxLength={50}
                                        initialValue={formik.values.alarmCode}
                                        onChange={formik.handleChange('alarmCode')}
                                        onBlur={formik.handleBlur('alarmCode')}
                                        error={!!formik.errors.alarmCode}
                                        errorMessage={formik.errors.alarmCode}
                                        {...testProps('alarm-code-input')}
                                    />
                                </View>
                            </Section>

                            <Section>
                                <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                    Special Instructions for entering my apartment:
                                </FormLabelText>

                                <InputString
                                    onChange={formik.handleChange('specialInstruction')}
                                    onBlur={formik.handleBlur('specialInstruction')}
                                    initialValue={formik.values.specialInstruction}
                                    multiline={true}
                                    numberOfLines={6}
                                    maxLength={1024}
                                    error={!!formik.errors.specialInstruction}
                                    errorMessage={formik.errors.specialInstruction}
                                    {...testProps('special-instructions-input')}
                                />
                            </Section>

                            <Section>
                                <Container borderStyle={'orange'}>
                                    <View style={[globalStyles.flexColumnCenter, globalStyles.innerContainer]}>
                                        <MaterialCommunityIcons
                                            name='alert-circle'
                                            size={SmarthubTheme.layout.LARGEICONSIZE}
                                            color={SmarthubTheme.colors.orange}
                                            style={globalStyles.doubleSpaceBelow}
                                        />
                                        <RegularText>
                                            I understand that Venterra employee(s) may enter my home without notice when
                                            there is a risk of extended property damage or injury to others.
                                        </RegularText>
                                    </View>
                                </Container>
                            </Section>

                            <Section>
                                <View style={[styles.button]}>
                                    <Button
                                        inactive={uiStore.activityLoaderVisible || sessionActiveResidency.adminYn}
                                        onClick={formik.handleSubmit}
                                        {...testProps('submit-maintenance-request-button')}
                                    >
                                        Submit Maintenance Request
                                    </Button>
                                </View>
                            </Section>
                        </View>
                    </KeyboardAwareScrollView>
                </SafeAreaView>
            );
    },
);

export default CreateMaintenanceRequest;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    contactSectionDrawer: {
        flexDirection: 'row',
    },
    contactSectionDrawerSection: {
        width: '45%',
        marginRight: 32,
    },
    contactSection: {
        flexDirection: 'column',
    },
    button: {
        width: '100%',
    },
    numberInput: {
        width: '30%',
    },
    // addPhotoSection: {
    //     flexDirection: 'row',
    //     alignItems: 'center',
    // },
    // addPhotoLabel: {
    //     paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
    // },
    closeButton: {
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});
