import { AbstractPropertyAuthenticatedCommand } from '../AbstractPropertyAuthenticatedCommand';
import { GetContactInfoAndOfficeHoursResultV1 } from './GetContactInfoAndOfficeHoursResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.community.GetContactInfoAndOfficeHoursCommandV1
 */
export type GetContactInfoAndOfficeHoursCommandV1 = AbstractPropertyAuthenticatedCommand<GetContactInfoAndOfficeHoursResultV1>;

export function createGetContactInfoAndOfficeHoursCommandV1(): GetContactInfoAndOfficeHoursCommandV1 {
    return {
        propertyCd: '',
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.community.GetContactInfoAndOfficeHoursCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
