import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';

//Handling incoming notifications when the app is in foreground, if we don't call this function, the notification won't be shown when the app is in foreground
export function setNotificationHandler() {
    Notifications.setNotificationHandler({
        handleNotification: async () => ({
            shouldShowAlert: true,
            shouldPlaySound: false,
            shouldSetBadge: false,
        }),
    });
}

export const getDeepLinkSchemaUri = (): string => {
    return Constants?.expoConfig?.extra?.environment === 'dev'
        ? // @ts-ignore
          `exp://${Constants.expoConfig?.hostUri}/--/`
        : `${Constants?.expoConfig?.scheme}://`;
};

export const getDeepLinkUrl = (pathToScreen: string): string => {
    return `${getDeepLinkSchemaUri()}${pathToScreen}`;
};
