import React, { useState } from 'react';
import { FlatList, SectionList, SectionListData, StyleProp, View, ViewStyle } from 'react-native';
import { Heading1, RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { getAuth } from 'firebase/auth';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { Divider } from 'react-native-paper';
import { IS_PRODUCTION } from 'utils/BootstrapHelpers';
import { ListItemProps, renderListItem } from 'components/lists/SmarthubListItem';
import { globalStyles } from 'theme/style/GlobalStyles';
import { observer } from 'mobx-react-lite';

export const HomePageMenuOptions: ListItemProps[] = [
    {
        id: '1',
        title: 'Statements and Payments',
        routeName: 'StatementsAndBalanceUpdates',
        testID: 'home-menu',
        notificationTypes: ['Statement'],
    },
    {
        id: '2',
        title: 'My Requests',
        routeName: 'MyRequests',
        testID: 'home-menu',
        notificationTypes: ['MaintenanceRequestUpdate', 'MaintenanceRequestCorrespondence'],
    },
    {
        id: '3',
        title: 'SMARTPACKAGE',
        routeName: 'SMARTPACKAGE',
        testID: 'home-menu',
        notificationTypes: ['SmartPackage'],
        notificationMenuDesc: 'You have $count packages waiting to be picked up',
        customTitle: (
            <RegularText>
                SMART<RegularBoldText>PACKAGE</RegularBoldText>
            </RegularText>
        ),
    },
    {
        id: '4',
        title: 'Contracts and Documents',
        routeName: 'ContractAndDocuments',
        testID: 'home-menu',
        notificationTypes: ['ElectronicContract'],
    },
    /*{
        id: '4',
        title: 'Contracts And Documents',
        routeName: 'ComingSoon',
    },*/
];

const PersonalOptions: ListItemProps[] = [
    {
        id: '1',
        title: 'Profile',
        routeName: 'Profile',
        testID: 'more-tab',
    },
    {
        id: '2',
        title: 'Away Times',
        routeName: 'AwayTimes',
        testID: 'more-tab',
    },
    /*{
        id: '2',
        title: 'Payment Methods',
        routeName: 'PaymentMethods',
    },
    {
        id: '3',
        title: 'Pets',
        routeName: 'Pets',
    },
    {
        id: '4',
        title: 'Visitors',
        routeName: 'Visitors',
    },
    {
        id: '5',
        title: 'Away Times',
        routeName: 'AwayTimes',
    },
    {
        id: '6',
        title: 'Referrals',
        routeName: 'Referrals',
    },*/
];

const MyCommunityOptions: ListItemProps[] = [
    {
        id: '1',
        title: 'Community Info and Office Hours',
        routeName: 'CommunityInfo',
        testID: 'more-tab',
    },
    /*{
        id: '2',
        title: 'Community Policy Documents',
        routeName: 'CommunityPolicyDocuments',
    },
    {
        id: '3',
        title: 'Frequently Asked Questions',
        routeName: 'FrequentlyAskedQuestions',
    },*/
];

const SettingsOptions: ListItemProps[] = [
    {
        id: '1',
        title: 'Alerts and Notifications',
        routeName: 'AlertsAndNotifications',
        testID: 'more-tab',
    },
    {
        id: '2',
        title: 'Sign Out',
        titleStyle: globalStyles.orangeText,
        hideChevron: true,
        routeName: 'SignOut',
        testID: 'more-tab',
        doAction: () => {
            const auth = getAuth();
            return auth.signOut();
        },
    },
];

const TestOptions: ListItemProps[] = [
    {
        id: '3',
        title: 'Test Screen',
        routeName: 'TestScreen',
    },
    {
        id: '4',
        title: 'Primitives Screen',
        routeName: 'PrimitivesScreen',
    },
    {
        id: '5',
        title: 'Form Screen',
        routeName: 'FormScreen',
    },
];

const MorePageOptionsNoTest = [
    {
        title: 'Personal',
        data: PersonalOptions,
    },
    {
        title: 'Settings',
        data: SettingsOptions,
    },
];

const MorePageOptionsNoTestWithActiveResidency = [
    {
        title: 'Personal',
        data: PersonalOptions,
    },
    {
        title: 'FAQ',
        data: MyCommunityOptions,
    },
    {
        title: 'App Settings',
        data: SettingsOptions,
    },
];

const moreOptionsTest = [
    {
        title: ':::TEST:::',
        data: TestOptions,
    },
];

export const moreOptions = IS_PRODUCTION ? [...MorePageOptionsNoTest] : [...MorePageOptionsNoTest, ...moreOptionsTest];
export const moreOptionsWithActiveResidency = IS_PRODUCTION
    ? [...MorePageOptionsNoTestWithActiveResidency]
    : [...MorePageOptionsNoTestWithActiveResidency, ...moreOptionsTest];

interface ScreenMenuProps {
    options: Array<ListItemProps>;
    style?: StyleProp<any>;
    contentContainerStyle?: StyleProp<ViewStyle>;
    headerComponent?: React.ComponentType<any> | React.ReactElement | null;
    footerComponent?: React.ComponentType<any> | React.ReactElement | null;
    onRefresh?: () => void;
}
export const ScreensMenu: React.FC<ScreenMenuProps> = ({
    options,
    style,
    contentContainerStyle,
    headerComponent,
    footerComponent,
    onRefresh,
}: ScreenMenuProps) => {
    const [refreshing, setRefreshing] = useState(false);
    return (
        <FlatList
            style={style}
            data={options}
            keyExtractor={item => item.id}
            renderItem={renderListItem}
            contentContainerStyle={contentContainerStyle}
            ItemSeparatorComponent={Divider}
            ListHeaderComponent={headerComponent || Divider}
            ListFooterComponent={footerComponent || Divider}
            scrollEnabled={true}
            onRefresh={onRefresh}
            refreshing={refreshing}
        />
    );
};

interface SectionsScreensMenuProps {
    options: Array<SectionListData<ListItemProps>>;
    contentContainerStyle?: StyleProp<ViewStyle>;
    headerComponent?: React.ComponentType<any> | React.ReactElement | null;
    footerComponent?: React.ComponentType<any> | React.ReactElement | null;
    onRefresh?: () => void;
}

export const SectionsScreensMenu: React.FC<SectionsScreensMenuProps> = observer((props: SectionsScreensMenuProps) => {
    logInfo(INFO_LOGGER, 'section options ', props.options);
    logInfo(INFO_LOGGER, 'section contentContainerStyle ', props.contentContainerStyle);
    const [refreshing, setRefreshing] = useState(false);

    return (
        <SectionList
            sections={props.options}
            keyExtractor={item => item.id}
            renderItem={renderListItem}
            renderSectionHeader={({ section: { title } }) => (
                <View style={globalStyles.sectionSpaceAround}>
                    <Heading1>{title}</Heading1>
                </View>
            )}
            contentContainerStyle={props.contentContainerStyle}
            ItemSeparatorComponent={() => <Divider />}
            SectionSeparatorComponent={Divider}
            stickySectionHeadersEnabled={false}
            ListHeaderComponent={props.headerComponent || Divider}
            ListFooterComponent={props.footerComponent || Divider}
            onRefresh={() => {
                if (props.onRefresh) {
                    setRefreshing(true);
                    props.onRefresh();
                    setRefreshing(false);
                }
            }}
            refreshing={refreshing}
        />
    );
});
