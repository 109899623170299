import React, { useEffect, useState } from 'react';
import { StyleSheet, TextStyle, View } from 'react-native';
import { Badge } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import { SmarthubNotificationTypeV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationTypeV1';
import { testProps } from 'components/ComponentTypes';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';
import {
    getUnseenNotifications,
    typesHaveUnseenNotifications,
    useSmarthubNotifications,
} from 'screens/home/requests/NotificationsHooks';

interface DrawerIconWithNotificationsProps {
    iconSize: number;
    notificationTypes: Array<SmarthubNotificationTypeV1> | 'All';
    children: React.ReactNode;
    identifier?: string;
}

const DrawerIconWithNotifications: React.FC<DrawerIconWithNotificationsProps> = observer(
    ({ iconSize, notificationTypes, children, identifier }: DrawerIconWithNotificationsProps) => {
        const bubbleSize = iconSize / 3.5;
        const [showRedDot, setShowRedDot] = useState(false);

        const newestNotifications = useSmarthubNotifications();
        const notifications = newestNotifications.data?.notifications || ([] as SmarthubNotificationV1[]);

        useEffect(() => {
            if (newestNotifications.data) {
                notificationTypes === 'All'
                    ? setShowRedDot(getUnseenNotifications(notifications).length > 0)
                    : setShowRedDot(typesHaveUnseenNotifications(notificationTypes, notifications));
            }
        }, [notifications]);

        const getBadgeStyle = (bubbleSize: number): TextStyle => {
            return { position: 'absolute', top: bubbleSize - 1.8, right: bubbleSize - 3 };
        };

        const badgeId = identifier ? `badge-${identifier}` : 'badge-id-not-specified';

        return (
            <React.Fragment>
                <View style={styles.badgedicon}>
                    {children}
                    {showRedDot && (
                        // @ts-ignore
                        <Badge
                            {...testProps(badgeId)}
                            dataDetectorType={'none'}
                            visible={true}
                            size={bubbleSize}
                            style={getBadgeStyle(bubbleSize)}
                        />
                    )}
                </View>
            </React.Fragment>
        );
    },
);

export default DrawerIconWithNotifications;

const styles = StyleSheet.create({
    badgedicon: {
        flexDirection: 'row',
    },
});
