import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetSmartmoveUtilitiesAndRenterInsurancesResultV1 } from './GetSmartmoveUtilitiesAndRenterInsurancesResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.smartmove.GetSmartmoveUtilitiesAndRenterInsurancesCommandV1
 */
export type GetSmartmoveUtilitiesAndRenterInsurancesCommandV1 = AbstractResidencyAuthenticatedCommand<GetSmartmoveUtilitiesAndRenterInsurancesResultV1>;

export function createGetSmartmoveUtilitiesAndRenterInsurancesCommandV1(): GetSmartmoveUtilitiesAndRenterInsurancesCommandV1 {
    return {
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.smartmove.GetSmartmoveUtilitiesAndRenterInsurancesCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
