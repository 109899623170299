import { SmarthubTransactionType } from '../../businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionType';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetTransactionResultV1 } from './GetTransactionResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.transactions.GetTransactionCommandV1
 */
export interface GetTransactionCommandV1 extends AbstractResidencyAuthenticatedCommand<GetTransactionResultV1> {
    type: SmarthubTransactionType;
    referenceNumber: string;
}

export function createGetTransactionCommandV1(): GetTransactionCommandV1 {
    return {
        type: 'RecentBilling',
        referenceNumber: '',
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.transactions.GetTransactionCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
