import { RootStore } from 'stores/RootStore';
import { createContext, useContext } from 'react';

export const createStore = (): RootStore => {
    return new RootStore();
};

export const StoreContext = createContext<RootStore>({} as RootStore);
export const StoreContextProvider = StoreContext.Provider;

//Custom hook to get the store from any function component
export const useStore = (): RootStore => {
    return useContext(StoreContext);
};
