import { useStore } from 'contexts/StoreContextProvider';
import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import {
    createGetContactInfoAndOfficeHoursCommandV1,
    GetContactInfoAndOfficeHoursCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/community/GetContactInfoAndOfficeHoursCommandV1';
import { UseQueryOptions } from 'react-query';
import { GetContactInfoAndOfficeHoursResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/community/GetContactInfoAndOfficeHoursResultV1';

const generateCommunitInfoCMD = (propertyCd: string) => {
    const cmd = createGetContactInfoAndOfficeHoursCommandV1();
    cmd.propertyCd = propertyCd;
    return cmd;
};

export const useGetCommnityInfo = () => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateCommunitInfoCMD(sessionActiveResidency.propertyCd);

    const queryOptions: UseQueryOptions<unknown, unknown, GetContactInfoAndOfficeHoursResultV1> = {
        enabled: hasSessionActiveResidency,
    };

    return useCommandQuery<GetContactInfoAndOfficeHoursCommandV1, GetContactInfoAndOfficeHoursResultV1>(
        cmd,
        uiStore,
        undefined,
        queryOptions,
    );
};
