import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetSmartmoveVehiclesAndPetsResultV1 } from './GetSmartmoveVehiclesAndPetsResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.smartmove.GetSmartmoveVehiclesAndPetsCommandV1
 */
export type GetSmartmoveVehiclesAndPetsCommandV1 = AbstractResidencyAuthenticatedCommand<GetSmartmoveVehiclesAndPetsResultV1>;

export function createGetSmartmoveVehiclesAndPetsCommandV1(): GetSmartmoveVehiclesAndPetsCommandV1 {
    return {
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.smartmove.GetSmartmoveVehiclesAndPetsCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
