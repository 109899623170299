import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Container from 'components/primitives/Container';
import FlexContainer from 'components/primitives/FlexContainer';
import { MediumBoldText, RegularText } from 'components/primitives/StyledText';
import { globalStyles } from 'theme/style/GlobalStyles';

const ResidentID = observer(() => {
    const { sessionActiveResidency, activeUnitString } = useStore().userSessionStore;
    const theme = useTheme();

    const venterraLogo = theme.dark
        ? require('../../../assets/images/venterra-logo-navigation.png')
        : require('../../../assets/images/venterra-logo-blue.png');

    return (
        <FlexContainer style={[globalStyles.hundowidth, globalStyles.hundoheight, styles.flexColumnCenter]}>
            <Container height={'50%'} width={'80%'} dropShadow={true}>
                <FlexContainer
                    style={[
                        globalStyles.hundowidth,
                        globalStyles.hundoheight,
                        globalStyles.flexColumnCenter,
                        { backgroundColor: theme.colors.background },
                    ]}>
                    <Image source={venterraLogo} style={[styles.venterraLogoColor, globalStyles.sectionSpaceAround]} />
                    <Image
                        source={{
                            uri: sessionActiveResidency.communityLogoImageURI,
                        }}
                        style={[styles.logo, globalStyles.sectionSpaceBelow]}
                    />

                    <MediumBoldText>
                        {sessionActiveResidency.firstName + ' ' + sessionActiveResidency.lastName}
                    </MediumBoldText>
                    <RegularText style={globalStyles.singleSpaceAbove}>Apartment {activeUnitString}</RegularText>
                </FlexContainer>
            </Container>
        </FlexContainer>
    );
});
export default ResidentID;

const styles = StyleSheet.create({
    logo: {
        height: 100,
        width: 150,
        margin: SmarthubTheme.layout.SURROUNDMARGIN,
        resizeMode: 'contain',
    },
    id: {
        padding: SmarthubTheme.layout.INNERPAD,
        justifyContent: 'center',
        alignItems: 'center',
    },
    venterraLogoColor: {
        height: 20,
        width: 148,
    },
    flexColumnCenter: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '33%',
    },
});
