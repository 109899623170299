import { ResidencyAuditEventType } from '../../businesslogic/residency/dataobjects/ResidencyAuditEventType';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { LogResidencyAuditEventResultV1 } from './LogResidencyAuditEventResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.LogResidencyAuditEventCommandV1
 */
export interface LogResidencyAuditEventCommandV1
    extends AbstractResidencyAuthenticatedCommand<LogResidencyAuditEventResultV1> {
    auditLogId: number | null;
    eventType: ResidencyAuditEventType;
    currentValue: string;
    newValue: string;
    sessionId: string;
}

export function createLogResidencyAuditEventCommandV1(): LogResidencyAuditEventCommandV1 {
    return {
        auditLogId: null,
        eventType: 'ExpectMoveInDateChange',
        currentValue: '',
        newValue: '',
        sessionId: '',
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.LogResidencyAuditEventCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
