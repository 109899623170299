import { createSmarthubAddressV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubAddressV1';
import { SmarthubAddressV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubAddressV1';
import { ValidateAddressResultV1 } from './ValidateAddressResultV1';
import { AbstractVersionedRemoteCommand } from '../../../remotecmd/versioned/AbstractVersionedRemoteCommand';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.ValidateAddressCommandV1
 */
export interface ValidateAddressCommandV1 extends AbstractVersionedRemoteCommand<ValidateAddressResultV1> {
    address: SmarthubAddressV1;
}

export function createValidateAddressCommandV1(): ValidateAddressCommandV1 {
    return {
        address: createSmarthubAddressV1(),
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.ValidateAddressCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
