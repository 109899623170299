import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { observer } from 'mobx-react-lite';
import MainStackSurface from './MainStackSurface';
import { getMainStackScreenOptions, NavigatorProps } from './MainStackScreenOptions';
import Notices from 'screens/notices/Notices';
import { useTheme } from 'react-native-paper';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { navigationRoutes } from 'utils/NavigationUtils';

const NoticesStack = createStackNavigator();

const NoticesStackNavigator: React.FC<NavigatorProps> = observer(() => {
    const { useDrawerNavigation } = useResponsiveContext();
    const theme = useTheme();
    return (
        <MainStackSurface>
            <NoticesStack.Navigator
                initialRouteName={navigationRoutes.noticesRoutes.notices}
                screenOptions={getMainStackScreenOptions(theme, useDrawerNavigation)}>
                <NoticesStack.Screen
                    name={navigationRoutes.noticesRoutes.community}
                    component={Notices}
                    options={{ headerLeft: () => null }}
                />
            </NoticesStack.Navigator>
        </MainStackSurface>
    );
});

export default NoticesStackNavigator;
