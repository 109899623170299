import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetNoticeToVacateScreenDataResultV1 } from './GetNoticeToVacateScreenDataResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetNoticeToVacateScreenDataCommandV1
 */
export type GetNoticeToVacateScreenDataCommandV1 = AbstractResidencyAuthenticatedCommand<GetNoticeToVacateScreenDataResultV1>;

export function createGetNoticeToVacateScreenDataCommandV1(): GetNoticeToVacateScreenDataCommandV1 {
    return {
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetNoticeToVacateScreenDataCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
