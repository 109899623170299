import { useEffect, useRef, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

/***
 * return true when the app goes to the background and then back to foreground
 * initial value is false because the app has not went to background
 */
const useIsForeground = () => {
    const appState = useRef(AppState.currentState);
    const [hasComeToForeground, setHasComeToForeground] = useState(false);

    useEffect(() => {
        const sub = AppState.addEventListener('change', _handleAppStateChange);

        return () => {
            sub.remove();
        };
    }, []);

    const _handleAppStateChange = (nextAppState: AppStateStatus) => {
        if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
            setHasComeToForeground(true);
        } else setHasComeToForeground(false);

        appState.current = nextAppState;
    };

    return hasComeToForeground;
};

export default useIsForeground;
