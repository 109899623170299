import * as React from 'react';
import { View, StyleSheet, StyleProp } from 'react-native';

interface FlexContainerProps {
    testID?: string;
    flexDirection?: 'row' | 'column';
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    alignContent?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'space-between' | 'space-around';
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
    alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
    children?: React.ReactNode;
    style?: StyleProp<any>;
}

const defaultProps: FlexContainerProps = {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
};

const FlexContainer: React.FC<FlexContainerProps> = (props: FlexContainerProps) => {
    const styles = StyleSheet.create({
        flexContainer: {
            display: 'flex',
            flexDirection: props.flexDirection,
            justifyContent: props.justifyContent,
            alignContent: props.alignContent,
            flexWrap: props.flexWrap,
            alignItems: props.alignItems,
        },
    });

    const style = [...[styles.flexContainer], props.style];

    return (
        <View style={style} testID={'generic-flex-container'}>
            {props.children}
        </View>
    );
};

FlexContainer.defaultProps = defaultProps;

export default FlexContainer;
