import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { DeemphansizedTextSmall, Heading3, RegularText, SmallText } from 'components/primitives/StyledText';
import React from 'react';
import { Badge, Surface } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { arrayIsEmpty } from 'utils/ArrayUtils';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';
import { formatDateMonthLabelFullNoYear } from 'utils/DateUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { buildNotificationOnPress } from 'screens/notifications/Notifications';
import { useNavigation } from '@react-navigation/native';
import { testProps } from 'components/ComponentTypes';
import { useSmarthubNotifications } from 'screens/home/requests/NotificationsHooks';
import { IsNavigationRouteOnDeepStack, navigationRoutes } from 'utils/NavigationUtils';

export const LatestNotifications: React.FC = observer(() => {
    const useDrawerNavigation = useResponsiveContext().useDrawerNavigation;
    const newestNotifications = useSmarthubNotifications();

    if (!!newestNotifications && (!newestNotifications.isSuccess || !newestNotifications.data)) return null;
    return (
        <Surface
            style={useDrawerNavigation ? [styles.surfacenoelevation, styles.surfaceBorder] : styles.surfacenoelevation}>
            <Heading3 style={globalStyles.singleSpaceBelow}>Latest Notifications</Heading3>
            {arrayIsEmpty(newestNotifications.data.notifications!) && <RegularText>None</RegularText>}
            {newestNotifications.data?.notifications
                .slice(
                    0,
                    newestNotifications.data?.notifications.length < 3
                        ? newestNotifications.data?.notifications.length
                        : 3,
                )
                .map(notification => (
                    <LatestNotification key={JSON.stringify(notification)} notification={notification} />
                ))}
        </Surface>
    );
});

interface LatestNotificationProps {
    notification: SmarthubNotificationV1;
}
const LatestNotification: React.FC<LatestNotificationProps> = observer(({ notification }: LatestNotificationProps) => {
    const navigation = useNavigation();
    const isOnHomeStack = IsNavigationRouteOnDeepStack(navigationRoutes.homeRoutes.home);
    return (
        <TouchableOpacity
            style={[styles.notificationcontainer, globalStyles.singleSpaceBelow]}
            onPress={buildNotificationOnPress(notification, navigation, false, isOnHomeStack)}>
            <View style={styles.badgedtext}>
                <SmallText>{notification.heading}</SmallText>
                {/*// @ts-ignore */}
                <Badge
                    {...testProps('badge-latest-notifications')}
                    dataDetectorType={'none'}
                    visible={!notification.seenYn}
                    size={10}
                    style={globalStyles.notificationBadge}
                />
            </View>
            <DeemphansizedTextSmall>
                {formatDateMonthLabelFullNoYear(new Date(notification.dateCreated))}
            </DeemphansizedTextSmall>
        </TouchableOpacity>
    );
});

const styles = StyleSheet.create({
    surface: {
        flex: 1,
        padding: SmarthubTheme.layout.INNERPAD,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        elevation: SmarthubTheme.layout.SURFACEELEVATION,
        borderWidth: 0.1,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.lightgrey,
    },
    surfacenoelevation: {
        flex: 1,
        padding: SmarthubTheme.layout.INNERPAD,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        elevation: 0,
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.white,
    },
    surfaceBorder: {
        borderWidth: 0.1,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.lightgrey,
    },
    notificationcontainer: {
        width: '100%',
    },
    badgedtext: {
        flexDirection: 'row',
    },
});
