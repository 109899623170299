import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import {
    DeemphansizedTextSmall,
    DeephansizedRegularText,
    ExtraLargeBoldText,
    RegularBoldText,
    RegularText,
} from 'components/primitives/StyledText';
import { formatDateStringMonthLabelFromNumber } from 'utils/DateUtils';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { v4 as uuid } from 'uuid';
import { useTheme } from 'react-native-paper';
import Tag from 'components/primitives/Tag';
import { TagTypes } from 'components/primitives/TagTypes';
import {
    isCreditCardTransaction,
    SmarthubTransactionType,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionType';
import { globalStyles } from 'theme/style/GlobalStyles';
import { RouteProp } from '@react-navigation/native';
import { getRouteParam } from 'utils/routeUtils';
import { testProps } from 'components/ComponentTypes';
import { useGetTransaction } from 'queries/hooks/home/TransactionsHooks';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import { StackNavigationProp } from '@react-navigation/stack';

type PaymentDetailParamList = {
    PaymentDetail: {
        referenceNumber: number;
        title: string;
    };
};
type PaymentDetailScreenRouteProp = RouteProp<PaymentDetailParamList, 'PaymentDetail'>;
type PaymentDetailProps = {
    navigation: StackNavigationProp<any, string>;
    route: PaymentDetailScreenRouteProp;
};

const PaymentDetails: React.FC<PaymentDetailProps> = observer(({ navigation, route }: PaymentDetailProps) => {
    const { userSessionStore } = useStore();
    const { hasSessionActiveResidency } = userSessionStore;

    const theme = useTheme();
    const passedPaymentId: string = getRouteParam(route, 'referenceNumber') || '';
    const passedTransactionType: SmarthubTransactionType =
        getRouteParam(route, 'transactionType') || 'CreditCardPayment';

    const isTransactionsOnStack = IsNavigationRouteOnStack(navigationRoutes.homeRoutes.transactions);

    const query = useGetTransaction(passedPaymentId, passedTransactionType);

    let iscc: boolean;
    let paymentIcon: any;
    let iconLib = 'fontawesome';
    let typeDesc = 'Acct #';
    let tagType: TagTypes = TagTypes.Blue;

    useEffect(() => {
        //ensure transactions and home page are in the stack for back navigation
        if (hasSessionActiveResidency && !isTransactionsOnStack) {
            navigation.reset({
                routes: [
                    { name: navigationRoutes.homeRoutes.home },
                    { name: navigationRoutes.homeRoutes.transactions },
                    {
                        name: navigationRoutes.homeRoutes.paymentDetails,
                        params: { referenceNumber: passedPaymentId, transactionType: passedTransactionType },
                    },
                ],
                index: 2,
            });
        }
    }, [hasSessionActiveResidency]);

    useEffect(() => {
        if (query.data) {
            iscc = isCreditCardTransaction(query.data.transaction.type);
            paymentIcon = isCreditCardTransaction(query.data.transaction.type) ? 'credit-card-alt' : 'bank';

            if (iscc) {
                typeDesc = 'CC #';
                if (query.data.transaction.creditCardType) {
                    switch (query.data.transaction.creditCardType) {
                        case 'VISA':
                            paymentIcon = 'cc-visa';
                            break;
                        case 'AmericanExpress':
                            paymentIcon = 'cc-amex';
                            break;
                        case 'Discover':
                            paymentIcon = 'cc-discover';
                            break;
                        case 'DinersClub':
                            paymentIcon = 'cc-diners-club';
                            break;
                        case 'Mastercard':
                            paymentIcon = 'cc-mastercard';
                            break;
                    }
                }
            } else if (query.data.transaction.type === 'MoneyOrder') {
                typeDesc = 'Money Order #';
                iconLib = 'material-community';
                paymentIcon = 'checkbook';
            } else if (query.data.transaction.type === 'Check') {
                typeDesc = 'Check #';
                iconLib = 'material-community';
                paymentIcon = 'checkbook';
            }

            if (query.data.transaction.status === 'Failed') {
                tagType = TagTypes.Red;
            } else if (query.data.transaction.status === 'Successful') {
                tagType = TagTypes.DarkGrey;
            }
        }
    }, [query.data]);

    if (!query.isSuccess || !query.data || !hasSessionActiveResidency) return null;

    return (
        <ScrollView contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
            {query.data.transaction?.depositDt != null && (
                <RegularBoldText style={globalStyles.singleSpaceBelow}>
                    Receipt from Venterra Realty Management
                </RegularBoldText>
            )}

            <DeephansizedRegularText {...testProps('transaction-number')} style={globalStyles.singleSpaceBelow}>
                Transaction # {query.data.transaction?.referenceNumber}
            </DeephansizedRegularText>

            <RegularBoldText style={globalStyles.sectionSpaceAround}>
                {query.data.transaction?.transactionDtt &&
                    formatDateStringMonthLabelFromNumber(query.data.transaction?.transactionDtt)}
            </RegularBoldText>

            <ExtraLargeBoldText style={globalStyles.doubleSpaceBelow}>
                {query.data.transaction?.amount && formatCentsAsDollars(query.data.transaction?.amount)}
            </ExtraLargeBoldText>

            {!!query.data.transaction?.responsibleName && query.data.transaction?.responsibleName.length > 0 && (
                <RegularBoldText style={globalStyles.doubleSpaceBelow}>
                    paid by {query.data.transaction?.responsibleName}
                </RegularBoldText>
            )}

            <View key={uuid()} style={[globalStyles.doubleSpaceBelow, styles.accountdetails]}>
                {paymentIcon && iconLib === 'fontawesome' && (
                    <FontAwesome
                        key={uuid()}
                        name={paymentIcon}
                        size={SmarthubTheme.layout.GRIDINCREMENT * 4}
                        color={theme.colors.icon}
                    />
                )}

                {paymentIcon && iconLib === 'material-community' && (
                    <MaterialCommunityIcons
                        key={uuid()}
                        name={paymentIcon}
                        size={SmarthubTheme.layout.GRIDINCREMENT * 4}
                        color={theme.colors.icon}
                    />
                )}

                {query.data.transaction?.cardOrCheckNumber && (
                    <RegularText key={uuid()}>
                        {typeDesc} {query.data.transaction?.cardOrCheckNumber}
                    </RegularText>
                )}
            </View>

            <View key={uuid()} style={[globalStyles.sectionSpaceAround]} {...testProps('payment-status')}>
                <Tag tagType={tagType}>
                    PAYMENT{' '}
                    {query.data.transaction?.status === 'Submitted' ? 'In Progress' : query.data.transaction?.status}
                </Tag>
            </View>

            <DeemphansizedTextSmall style={globalStyles.singleSpaceAround} {...testProps('payment-notes')}>
                Payment Notes
            </DeemphansizedTextSmall>
            <RegularText style={globalStyles.singleSpaceAround}>{query.data.transaction?.notes || 'None'}</RegularText>
        </ScrollView>
    );
});

export default PaymentDetails;

const styles = StyleSheet.create({
    /*container: {
        margin: SmarthubTheme.layout.SURROUNDMARGIN * 2,
        marginTop: '25%',
        alignItems: 'center',
        justifyContent: 'center',
        width: '75%',
        height: '75%',
        alignSelf: 'center',
    },*/
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 4,
        padding: SmarthubTheme.layout.SURROUNDMARGIN * 2,
    },
    accountdetails: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
});
