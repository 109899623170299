import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { getPaymentFormInitialValues, paymentFormValidationSchema, PaymentFormValues } from 'utils/PayLeaseZegoUtils';
import { FormikHelpers } from 'formik/dist/types';
import { navigationRoutes } from 'utils/NavigationUtils';
import { Formik } from 'formik';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import PaymentForm from 'components/payments/PaymentForm';
import Section from 'components/primitives/Section';
import Button from 'components/primitives/Button';
import { testProps } from 'components/ComponentTypes';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';

type ZegoPaymentScreenProps = {
    navigation: StackNavigationProp<any>;
};

const ZegoPaymentScreen: React.FC<ZegoPaymentScreenProps> = observer(({ navigation }: ZegoPaymentScreenProps) => {
    const { userSessionStore, uiStore, transactionsStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const useDrawerNavigation = useResponsiveContext().useDrawerNavigation;

    const _continueReview = (values: PaymentFormValues, formikHelpers: FormikHelpers<PaymentFormValues>) => {
        navigation.navigate(
            useDrawerNavigation
                ? navigationRoutes.homeRoutes.zegoPaymentReview
                : navigationRoutes.loggedInRoutes.zegoPaymentReviewModal,
            {
                paymentFormValues: values,
            },
        );
    };

    if (!sessionActiveResidency) {
        return null;
    }

    const initialValues: PaymentFormValues = React.useMemo(() => {
        return getPaymentFormInitialValues(
            transactionsStore.uniquePaymentReferenceId,
            sessionActiveResidency.payleaseAccount,
            sessionActiveResidency.firstName,
            sessionActiveResidency.lastName,
            sessionActiveResidency.currentBalance,
        );
    }, [
        sessionActiveResidency.payleaseAccount,
        sessionActiveResidency.firstName,
        sessionActiveResidency.lastName,
        sessionActiveResidency.currentBalance,
    ]);

    return (
        <Formik
            validateOnMount={false}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={_continueReview}
            validationSchema={paymentFormValidationSchema}>
            {formikHelpers => (
                <SafeAreaView style={styles.container}>
                    <KeyboardAwareScrollView
                        keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                        enableResetScrollToCoords={false}>
                        <View style={styles.contentContainerStyle}>
                            <PaymentForm
                                title1={`Your total balance is ${formatCentsAsDollars(
                                    sessionActiveResidency?.currentBalance || 0,
                                )}. How much will you be paying?`}
                                paymentMethodTitle={'How would you like to pay?'}
                                showPaymentAmountInput={true}
                                disablePaymentAmountInput={sessionActiveResidency.lockPaymentAmountYn}
                                formikHelpers={formikHelpers}
                                applyPaymentRestrictions={false}
                                hideFee={false}
                            />

                            <Section style={styles.button}>
                                <Button
                                    inactive={
                                        uiStore.activityLoaderVisible ||
                                        !sessionActiveResidency ||
                                        !formikHelpers.values.payByMethod ||
                                        !formikHelpers.values.amount
                                    }
                                    onClick={formikHelpers.handleSubmit}
                                    {...testProps('continue-payment-review-button')}>
                                    Continue
                                </Button>
                            </Section>
                        </View>
                    </KeyboardAwareScrollView>
                </SafeAreaView>
            )}
        </Formik>
    );
});

export default ZegoPaymentScreen;

const styles = StyleSheet.create({
    container: { flex: 1 },
    contentContainerStyle: { width: '100%', alignItems: 'center', flexDirection: 'column' },
    button: {
        width: '100%',
        padding: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});
