import * as React from 'react';
import { useEffect, useState } from 'react';
import { Animated, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCollapsibleHeader } from 'react-navigation-collapsible';
import { getMainStackScreenOptions } from 'navigation/stacks/MainStackScreenOptions';
import { DeephansizedRegularText, Heading3, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { observer } from 'mobx-react-lite';
import { Badge, Divider, useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SmarthubTransactionV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionV1';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { _describePeriod, formatDateMonthLabelNoYearFromNumber } from 'utils/DateUtils';

import { spaceWordsOnCapitals } from 'utils/StringUtils';
import { StackNavigationProp } from '@react-navigation/stack';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps, updateStringForIDName } from 'components/ComponentTypes';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useQueryClient } from 'react-query';
import { generateInfiniteQueryKeyFromCmd } from 'queries/hooks/useCommandQuery';
import {
    generateGetTransactionsCMD,
    useAlterTransactionsResultsForSectionList,
    useGetTransactions,
} from 'queries/hooks/home/TransactionsHooks';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { GetTransactionsCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetTransactionsCommandV1';
import { isPaymentTransaction } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionType';
import { GetTransactionsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetTransactionsResultV1';
import { hasUnseenNotificationForTypeAndId, useSmarthubNotifications } from 'screens/home/requests/NotificationsHooks';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';

type ItemProps = {
    transactionV1: SmarthubTransactionV1;
    onPress: any;
    testID?: string;
    accessibilityLabel?: string;
};

const TransactionItem: React.FC<ItemProps> = ({ transactionV1, onPress }: ItemProps) => {
    const isBillingOrStatement =
        transactionV1.type === 'Statement' ||
        transactionV1.type === 'FinalStatement' ||
        transactionV1.type === 'RecentBilling';

    const textStyle = transactionV1.status === 'Failed' ? styles.failed : {};

    const notifications = useSmarthubNotifications().data?.notifications || ([] as SmarthubNotificationV1[]);

    return (
        <TouchableOpacity onPress={onPress} style={styles.item}>
            <View style={styles.column}>
                {isBillingOrStatement && (
                    <View style={styles.datedesc}>
                        <RegularText {...testProps('statement')}>
                            {formatDateMonthLabelNoYearFromNumber(transactionV1.transactionDtt)} -{' '}
                        </RegularText>
                        <RegularText>{spaceWordsOnCapitals(transactionV1.type)}</RegularText>
                    </View>
                )}
                {!isBillingOrStatement && (
                    <View style={styles.datedesc}>
                        <RegularText style={textStyle} {...testProps('payment')}>
                            {formatDateMonthLabelNoYearFromNumber(transactionV1.transactionDtt)} -{' '}
                        </RegularText>
                        <RegularText style={textStyle}>
                            {transactionV1.type === 'ACHPayment'
                                ? 'Check (ACH) Payment'
                                : spaceWordsOnCapitals(transactionV1.type)}
                        </RegularText>
                    </View>
                )}
                {/*// @ts-ignore */}
                <Badge
                    {...testProps('badge-' + updateStringForIDName(transactionV1.type))}
                    dataDetectorType={'none'}
                    visible={
                        hasUnseenNotificationForTypeAndId(
                            ['Statement'],
                            Number(transactionV1.referenceNumber),
                            notifications,
                        ) || false
                    }
                    size={10}
                    style={globalStyles.notificationBadge}
                />
            </View>
            <View>
                <RegularText style={[styles.dollars, textStyle]}>
                    {(isPaymentTransaction(transactionV1.type) ? '-' : '') + formatCentsAsDollars(transactionV1.amount)}
                </RegularText>
            </View>
        </TouchableOpacity>
    );
};

type TransactionsProps = {
    navigation: StackNavigationProp<any>;
};

const Transactions: React.FC<TransactionsProps> = observer(({ navigation }: TransactionsProps) => {
    const { useDrawerNavigation } = useResponsiveContext();
    const theme = useTheme();

    const { onScroll, containerPaddingTop, scrollIndicatorInsetTop } = useCollapsibleHeader({
        navigationOptions: getMainStackScreenOptions(theme, useDrawerNavigation, true),
        config: !useDrawerNavigation ? { collapsedColor: SmarthubTheme.colors.primary } : {},
    });
    const [refreshing, setRefreshing] = useState(false);
    const { userSessionStore, transactionsStore, uiStore } = useStore();
    const { hasSessionActiveResidency, confirmActiveResidency, sessionActiveResidency } = userSessionStore;

    const queryClient = useQueryClient();
    const transactionsCmd = generateGetTransactionsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
    );
    const transactionsQueryKey = generateInfiniteQueryKeyFromCmd<GetTransactionsCommandV1, GetTransactionsResultV1>(
        transactionsCmd,
    );

    // The hooks to get transaction data
    const { data, isSuccess, isLoading, hasNextPage, fetchNextPage } = useGetTransactions(transactionsCmd);

    useAlterTransactionsResultsForSectionList(transactionsQueryKey);

    // We do is loading so that the activity indicator only appears on the initial load and not when fetching more data. This keeps the app looking quicker
    useEffect(() => {
        isLoading ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [isLoading]);

    useEffect(() => {
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        confirmActiveResidency(navigation);
    }, [sessionActiveResidency.key]);

    if (!hasSessionActiveResidency) {
        return (
            <SafeAreaView style={[styles.container, { backgroundColor: theme.colors.background }]}>
                <View style={[styles.container, { backgroundColor: theme.colors.background }]}>
                    <RegularText>No lease selected</RegularText>
                </View>
            </SafeAreaView>
        );
    }

    if (isSuccess && data !== undefined) {
        return (
            <Animated.SectionList
                sections={data.pages[0].transactions}
                keyExtractor={(item: SmarthubTransactionV1) => item.referenceNumber}
                renderItem={({ item }) => (
                    <TransactionItem
                        transactionV1={item}
                        onPress={() => {
                            transactionsStore.setCurrentTransaction(item);
                            if (item.type === 'Statement' || item.type === 'FinalStatement') {
                                //go to statement details screen
                                navigation.navigate(navigationRoutes.homeRoutes.statementDetail, {
                                    title:
                                        (item.type === 'FinalStatement' ? 'Final' : _describePeriod(item.period)) +
                                        ' Statement ',
                                    referenceNumber: item.referenceNumber,
                                    transactionType: item.type,
                                });
                            } else {
                                //just show receipt as modal
                                navigation.navigate(navigationRoutes.homeRoutes.paymentDetails, {
                                    referenceNumber: item.referenceNumber,
                                    transactionType: item.type,
                                });
                            }
                        }}
                    />
                )}
                renderSectionHeader={({ section: { title } }) => (
                    <Heading3 {...testProps(_describePeriod(title))} style={styles.sectionheader}>
                        {_describePeriod(title)} Statement Period
                    </Heading3>
                )}
                renderSectionFooter={({ section: { title } }) => <View style={styles.sectionfooterspace}></View>}
                contentContainerStyle={[
                    {
                        //marginTop: HEIGHTHEADERFOOTERPAD,
                        paddingTop: containerPaddingTop,
                        backgroundColor: theme.colors.background,
                    },
                    styles.container,
                ]}
                ItemSeparatorComponent={Divider}
                SectionSeparatorComponent={Divider}
                ListHeaderComponent={Divider}
                stickySectionHeadersEnabled={false}
                onRefresh={() => {
                    setRefreshing(true);
                    queryClient.invalidateQueries(transactionsQueryKey).then(() => setRefreshing(false));
                }}
                ListEmptyComponent={
                    <View style={[styles.listEmptyContainer, { backgroundColor: theme.colors.background }]}>
                        <View style={styles.centertext}>
                            <DeephansizedRegularText>
                                {"You don't have any statements or payments"}
                            </DeephansizedRegularText>
                            <DeephansizedRegularText style={globalStyles.doubleSpaceAbove}>
                                {'Check back soon!'}
                            </DeephansizedRegularText>
                        </View>
                    </View>
                }
                refreshing={refreshing}
                scrollEnabled={true}
                onScroll={onScroll}
                onEndReachedThreshold={0.5}
                onEndReached={() => {
                    if (hasNextPage && !refreshing) {
                        logInfo(INFO_LOGGER, 'On end reached - Fetching transactions from server');
                        setRefreshing(true);
                        return fetchNextPage().then(() => setRefreshing(false));
                    }
                }}
            />
        );
    } else {
        return null;
    }
});

export default Transactions;

const styles = StyleSheet.create({
    centertext: { alignItems: 'center' },
    container: {
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
        flexGrow: 1,
    },
    item: {
        flexDirection: 'row',
        height: SmarthubTheme.layout.GRIDINCREMENT * 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    datedesc: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    column: {
        width: '66%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    dollars: {
        justifyContent: 'flex-end',
        color: SmarthubTheme.colors.lightblue,
    },
    sectionheader: {
        marginVertical: SmarthubTheme.layout.SECTIONMARGIN,
    },
    sectionfooterspace: {
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    failed: { color: SmarthubTheme.colors.red, textDecorationLine: 'line-through' },
    listEmptyContainer: {
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
        justifyContent: 'center',
    },
});
