import { UIStore } from 'stores/domain/UIStore';
import { MutationOptions, useMutation } from 'react-query';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { SmarthubResidencyV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';

import { SmartHubResidentContactInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/requests/SmartHubResidentContactInfoV1';
import { createUpdateContactInfoCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/UpdateContactInfoCommandV1';
import { UpdateContactInfoResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/UpdateContactInfoResultV1';

export const useUpdateContactInfoMutation = (
    uiStore: UIStore,
    sessionActiveResidency: SmarthubResidencyV1,
    mutationOptions?: MutationOptions<UpdateContactInfoResultV1, any, SmartHubResidentContactInfoV1[]>,
) => {
    return useMutation<UpdateContactInfoResultV1, any, SmartHubResidentContactInfoV1[]>(
        async (contactInfos: SmartHubResidentContactInfoV1[]) => {
            const cmd = createUpdateContactInfoCommandV1();
            cmd.propertyCd = sessionActiveResidency.propertyCd;
            cmd.residencyId = sessionActiveResidency.residencyId;
            cmd.contactInfoList = contactInfos;
            return dispatchCommandAsync(uiStore, cmd, false);
        },
        mutationOptions,
    );
};
