import React, { useEffect } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { HomePageMenuOptions, ScreensMenu } from 'components/lists/ScreensMenu';
import { MessageBalancePay } from 'components/cards/MessageBalancePay';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { Divider, useTheme } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { RegularText } from 'components/primitives/StyledText';
import Hyperlink from 'components/primitives/Hyperlink';
import { enableResidentId } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';
import { globalStyles } from 'theme/style/GlobalStyles';
import { getRouteParam } from 'utils/routeUtils';
import { navigationRoutes } from 'utils/NavigationUtils';
import useIsForeground from 'utils/useIsForeground';
import { openSmartMove } from 'screens/smartmove/SmartMoveApplication';

const Home = observer(() => {
    const { useFullExperience, useDrawerNavigation } = useResponsiveContext();
    const theme = useTheme();
    const { userSessionStore } = useStore();
    const { smarthubUser, confirmActiveResidency, hasSessionActiveResidency, sessionActiveResidency } =
        userSessionStore;
    const navigation = useNavigation();
    const route = useRoute();
    const hasComeToForeground = useIsForeground();
    const fromLeaseSelector: boolean = getRouteParam(route, 'fromLeaseSelector') || false;

    useEffect(() => {
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        confirmActiveResidency(navigation);
    }, [smarthubUser.authorizedResidencies]);

    useEffect(() => {
        if (sessionActiveResidency.statusCd === 'Leased') openSmartMove(useDrawerNavigation, navigation);
    }, [sessionActiveResidency]);

    useEffect(() => {
        if ((userSessionStore.balanceDirty || hasComeToForeground) && !fromLeaseSelector && hasSessionActiveResidency) {
            userSessionStore.reloadCurrentResidencyAsync(true);
        }
    }, [userSessionStore.balanceDirty, hasComeToForeground]);

    if (!hasSessionActiveResidency) {
        return null;
    }

    return (
        <ScreensMenu
            options={HomePageMenuOptions}
            contentContainerStyle={[
                styles.menu,
                // eslint-disable-next-line react-native/no-inline-styles
                {
                    backgroundColor: theme.colors.background,
                    marginTop: useFullExperience ? SmarthubTheme.layout.GRIDINCREMENT * 2 : 0,
                },
            ]}
            headerComponent={() => {
                return (
                    <View style={[globalStyles.hundowidth, { backgroundColor: theme.colors.background }]}>
                        {useDrawerNavigation &&
                            null != sessionActiveResidency.communityMastheadImageURI &&
                            sessionActiveResidency.communityMastheadImageURI.length > 0 && (
                                <Image
                                    source={{
                                        uri: sessionActiveResidency.communityMastheadImageURI,
                                    }}
                                    style={[styles.masthead]}
                                />
                            )}

                        {!useFullExperience && <MessageBalancePay />}

                        <Divider style={globalStyles.sectionSpaceAbove} />
                    </View>
                );
            }}
            footerComponent={() => {
                if (enableResidentId(sessionActiveResidency)) {
                    return (
                        <>
                            <Divider />
                            <View style={[globalStyles.flexColumnCenter, globalStyles.sectionSpaceAround]}>
                                <RegularText>
                                    <Hyperlink
                                        onClick={() =>
                                            navigation.navigate(navigationRoutes.loggedInRoutes.residentIdModal)
                                        }>
                                        Show My Resident ID
                                    </Hyperlink>{' '}
                                </RegularText>
                            </View>
                        </>
                    );
                } else {
                    return null;
                }
            }}
            onRefresh={() => userSessionStore.reloadResidencies(false, true)}
        />
    );
});
export default Home;

const styles = StyleSheet.create({
    menu: {
        zIndex: 1,
        width: '100%',
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    masthead: {
        height: 250,
        width: '100%',
    },
});
