/**
 * https://projects.invisionapp.com/d/main?origin=v7#/console/20651922/436337370/preview?scrollOffset=0
 */
export const SmarthubLayout = {
    GRIDINCREMENT: 8,
    INNERPAD: 16,
    SURROUNDMARGIN: 16,
    SECTIONMARGIN: 32,
    SURFACEELEVATION: 3,
    SIDEWIDTH: 325,
    NARROWDRAWER: 120,
    HEADERFOOTERPAD: 48,
    FULLWIDTH: '100%',
    FULLHEIGHT: '100%',
    BOTTOMTABHEIGHT: 40,
    BOTTOMTABICON: 28,
    PLUSBUTTONBOTTOMTAB: 40,
    BUTTONHEIGHT: 40,
    ICONWIDTH: 24,
    SMALLIMAGEWIDTH: 48,
    SMALLIMAGEHEIGHT: 37,
    SMARTDESKIMAGEWIDTH: 212,
    SMARTDESKIMAGEHEIGHT: 32,
    LINESPACE: 8,
    LARGEICONSIZE: 36,
    EXTRALARGEICONSIZE: 60,
    ICONMASSIVE: 72,
    DEFAULTMESSAGEINTERVAL: 5000,
};
