import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { FormLabelText, RegularText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import Section from 'components/primitives/Section';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalStyles } from 'theme/style/GlobalStyles';
import Hyperlink from 'components/primitives/Hyperlink';
import { getScreenOrModalName, navigationRoutes } from 'utils/NavigationUtils';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import DateField from 'components/primitives/DateField';
import { SubmitNoticeToVacateChangeDateRequestResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitNoticeToVacateChangeDateRequestResultV1';
import { parseDateMMDDYYYY } from 'utils/DateUtils';
import { testProps } from 'components/ComponentTypes';

type NoticeToVacateEditProps = {
    navigation: StackNavigationProp<any>;
};

const NoticeToVacateEdit: React.FC<NoticeToVacateEditProps> = observer(({ navigation }: NoticeToVacateEditProps) => {
    const { useDrawerNavigation } = useResponsiveContext();
    const { userSessionStore, ntvStore, uiStore } = useStore();
    const [expectedMoveOutDt, setExpectedMoveOutDate] = useState('');
    const { hasSessionActiveResidency, smarthubUser, confirmActiveResidency, sessionActiveResidency } =
        userSessionStore;

    const navigateToCancelNotice = () => {
        navigation.navigate(
            getScreenOrModalName(navigationRoutes.homeRoutes.noticeToVacateCancel, useDrawerNavigation),
        );
    };

    const onSubmit = () => {
        if (expectedMoveOutDt !== ntvStore.editNtvData.expectMoveOutDt) {
            uiStore.showActivityLoader();
            ntvStore
                .changeNoticeToVacateMoveOutdtAsync(
                    sessionActiveResidency.propertyCd,
                    sessionActiveResidency.residencyId,
                    sessionActiveResidency.residentId,
                    expectedMoveOutDt,
                )
                .then((result: SubmitNoticeToVacateChangeDateRequestResultV1) => {
                    uiStore.hideActivityLoader();
                    if (result.succeed) {
                        navigation.navigate(navigationRoutes.homeRoutes.home);
                        uiStore.addToast({
                            message: `We have received your request to change your move out date. We'll get back to you soon with an update.`,
                            autoDismiss: true,
                        });
                    } else {
                        uiStore.showAlert({
                            title: 'Error',
                            message: result.exceptions,
                        });
                    }
                })
                .finally(() => uiStore.hideActivityLoader());
        }
    };
    //load data
    useEffect(() => {
        confirmActiveResidency(navigation);
        if (hasSessionActiveResidency) {
            uiStore.showActivityLoader();
            ntvStore
                .getEditNoticeToVacateData()
                .then(() => {
                    setExpectedMoveOutDate(ntvStore.editNtvData.expectMoveOutDt);
                })
                .finally(() => uiStore.hideActivityLoader());
        }
    }, [smarthubUser.authorizedResidencies, sessionActiveResidency]);

    if (!ntvStore.editNtvData) {
        return null;
    }

    return (
        <SafeAreaView style={globalStyles.container}>
            <KeyboardAwareScrollView
                keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                enableResetScrollToCoords={false}>
                <View style={globalStyles.container}>
                    {!!ntvStore.editNtvData.noticeMessage && (
                        <RegularText {...testProps('edit-message')}>{ntvStore.editNtvData.noticeMessage}</RegularText>
                    )}

                    <Section>
                        <FormLabelText>Expected Move Out Date</FormLabelText>
                    </Section>

                    <View style={[globalStyles.singleSpaceAbove, styles.dateField]}>
                        <DateField
                            value={expectedMoveOutDt || ntvStore.editNtvData.expectMoveOutDt}
                            onChange={value => {
                                setExpectedMoveOutDate(value);
                            }}
                            minDate={ntvStore.editNtvData.todayDt}
                            editable={ntvStore.editNtvData.canModify}
                        />
                    </View>
                    <Section>
                        <FormLabelText>Move-Out Reason</FormLabelText>
                        <RegularText style={globalStyles.singleSpaceAbove}>
                            {ntvStore.editNtvData.moveOutReason}
                        </RegularText>
                    </Section>
                    <Section>
                        <FormLabelText>Person of Contact</FormLabelText>
                        <RegularText style={globalStyles.singleSpaceAbove}>
                            {ntvStore.editNtvData.contactPersonName}
                        </RegularText>
                    </Section>

                    {ntvStore.editNtvData.canCancel && (
                        <Section
                            style={[
                                globalStyles.centeredText,
                                globalStyles.flexColumnCenter,
                                globalStyles.tripleSpaceAbove,
                            ]}>
                            <Hyperlink {...testProps('cancel-notice')} onClick={navigateToCancelNotice}>
                                Changed your mind? Cancel your notice
                            </Hyperlink>
                        </Section>
                    )}

                    <Section style={[globalStyles.centeredText, globalStyles.tripleSpaceAbove]}>
                        <View style={[styles.button]}>
                            <Button
                                inactive={
                                    uiStore.activityLoaderVisible ||
                                    sessionActiveResidency.adminYn ||
                                    parseDateMMDDYYYY(expectedMoveOutDt)?.getTime() ===
                                        parseDateMMDDYYYY(ntvStore.editNtvData.expectMoveOutDt)?.getTime()
                                }
                                onClick={onSubmit}
                                {...testProps('submit-button')}>
                                Save Changes
                            </Button>
                        </View>
                    </Section>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
});

export default NoticeToVacateEdit;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: '100%',
    },
    dateField: {
        zIndex: 1000,
    },
});
