import { getApps, initializeApp } from 'firebase/app';
import 'firebase/auth';
import * as SplashScreen from 'expo-splash-screen';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import registerForPushNotificationsAsync from 'services/registerForPushNotificationsAsync';
import { logInfo } from 'utils/logging/Logger';
import { BOOTSTRAP_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
//import { Subscription } from '@unimodules/react-native-adapter';
import { configure } from 'mobx';
import Constants from 'expo-constants';
import * as Sentry from 'sentry-expo';
import * as SentryHelper from 'utils/logging/SentryHelper';
import { enableScreens } from 'react-native-screens';
import { UIStore } from 'stores/domain/UIStore';
import ApiConfig from 'components/auth/constants/ApiConfig';
import { LinkingConfiguration } from 'navigation/linking/LinkingConfiguration';
import { deepLinkHandler, handleLink } from 'navigation/linking/DeepLinks';
import { STORAGE_KEY_DARK_THEME_ENABLED } from 'utils/storage-keys';
import { getItem } from 'utils/storage';
import { UserSessionStore } from 'stores/ui/UserSessionStore';
import * as Updates from 'expo-updates';
import { setNotificationHandler } from 'utils/PushNotificationUtils';

function initializeFirebase() {
    const apps = getApps();
    if (!apps.length && ApiConfig.FirebaseConfig) {
        initializeApp(ApiConfig.FirebaseConfig);
    }
}

async function loadResourcesAndDataAsync() {
    logInfo(BOOTSTRAP_LOGGER, 'Constants.expoConfig: ', JSON.stringify(Constants.expoConfig));
    try {
        await SplashScreen.preventAutoHideAsync();

        //be nice - warm up the browser so the user doesn't need to wait when we start using it later...
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
            await WebBrowser.warmUpAsync();
            //TODO warm up paylease session???
        }
    } catch (e) {
        // We might want to provide this error information to an error reporting service
        //'ERR_SPLASH_SCREEN_CANNOT_PREVENT_AUTOHIDE' exception is thrown but no impact on the UI, change the logging level to info.
        logInfo(INFO_LOGGER, JSON.stringify(e));
    } finally {
        await SplashScreen.hideAsync();
    }
}

async function setDark(uiStore: UIStore) {
    //dark theme
    let makeItDark = false;
    const storageKey = await getItem(STORAGE_KEY_DARK_THEME_ENABLED);
    //app preference
    if (storageKey && storageKey === 'true' && !uiStore.useDarkTheme) {
        makeItDark = true;
        /*//os level
    } else if (
        !storageKey &&
        Platform.OS !== 'web' &&
        Appearance.getColorScheme() === 'dark' &&
        !uiStore.useDarkTheme
    ) {
        makeItDark = true;*/
    }
    if (makeItDark) {
        uiStore.toggleDarkTheme();
    }
}

async function setupPreferences(uiStore: UIStore) {
    setDark(uiStore);
    //addChangeListener(() => setDark(uiStore));
}

function teardown(/*notificationSubscription: Subscription, */ linkingHandler: Linking.URLListener) {
    (Platform.OS === 'ios' || Platform.OS === 'android') && WebBrowser.coolDownAsync();

    //teardown the local notifications subscription
    //Notifications.removeNotificationSubscription(notificationSubscription);
    //Notifications.removeAllNotificationListeners();
    //TODO?? Notifications.removeAllPushTokenListeners();

    //teardown firebase?
}

//CANNOT RELY on releaseChannel because web doens't use it
export const IS_PRODUCTION =
    Constants.expoConfig?.extra &&
    Constants.expoConfig.extra['environment'] &&
    Constants.expoConfig.extra['environment'] === 'production';

export const bootstrapApp = (uiStore: UIStore, userSessionStore: UserSessionStore) => {
    //configure sentry
    Sentry.init({
        dsn: (Constants.expoConfig?.extra && Constants.expoConfig.extra['sentryDSN']) || '',
        enableInExpoDevelopment: false,
        enableOutOfMemoryTracking: false,
        autoInitializeNativeSdk: Platform.OS !== 'web',
        debug: !IS_PRODUCTION,
        environment:
            (Constants.expoConfig?.extra && Constants.expoConfig.extra['environment']) || 'EnvironmentNotConfigured',
    });

    logInfo(BOOTSTRAP_LOGGER, 'Constants.appOwnership ', Constants.appOwnership || 'None');
    logInfo(BOOTSTRAP_LOGGER, 'Release channel is ', Updates.channel);
    logInfo(BOOTSTRAP_LOGGER, 'linkingUri is ', Constants.linkingUri);

    //turn on react native screens
    enableScreens();

    //configure mobx
    configure({
        useProxies: 'ifavailable',
        enforceActions: 'observed',
        computedRequiresReaction: false,
        reactionRequiresObservable: false,
        observableRequiresReaction: false,
        disableErrorBoundaries: false,
    });

    //get firebase going
    initializeFirebase();

    //load anything needed up front
    loadResourcesAndDataAsync();

    //sort out if they want local notifications
    registerForPushNotificationsAsync();

    setNotificationHandler();

    //setup the deep link handlers
    const linkingHandler: Linking.URLListener = ({ url }) => {
        handleLink(url, uiStore, userSessionStore);
    };
    if (Platform.OS !== 'web') {
        //If the app is already open, the app is foregrounded and a Linking event is fired
        Linking.addEventListener('url', linkingHandler);
    }
    deepLinkHandler(linkingHandler);

    logInfo(BOOTSTRAP_LOGGER, 'LinkingConfiguration is: ', LinkingConfiguration);

    //setup saved app preferences
    setupPreferences(uiStore);

    //return teardown method which will cleanup anything when app unmounts
    return () => {
        teardown(linkingHandler);
    };
};
