export const isEmpty = (obj: any): boolean => {
    for (const prop in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
};

export const last = <T>(arr?: Array<T>) => {
    return (arr && arr.length > 0 && arr[arr.length - 1]) || undefined;
};

export const first = <T>(arr?: Array<T>) => {
    return (arr && arr.length > 0 && arr[0]) || undefined;
};
