import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SubmitNoticeToVacateCancellationRequestResultV1 } from './SubmitNoticeToVacateCancellationRequestResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitNoticeToVacateCancellationRequestCommandV1
 */
export interface SubmitNoticeToVacateCancellationRequestCommandV1
    extends AbstractResidencyAuthenticatedCommand<SubmitNoticeToVacateCancellationRequestResultV1> {
    requestResidentId: number;
    contactResidentId: number;
    preferContactOption: string;
    preferContactDetail: string;
}

export function createSubmitNoticeToVacateCancellationRequestCommandV1(): SubmitNoticeToVacateCancellationRequestCommandV1 {
    return {
        requestResidentId: 0,
        contactResidentId: 0,
        preferContactOption: '',
        preferContactDetail: '',
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitNoticeToVacateCancellationRequestCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
