import { PersistentDataObjectBase } from '../../common/PersistentDataObjectBase';
import { OwnerIdType } from '../../notifications/dataobjects/OwnerIdType';
import { NullableSmarthubBrowser } from './SmarthubBrowser';
import { SmarthubDeviceType } from './SmarthubDeviceType';
import { SmarthubPlatformOS } from './SmarthubPlatformOS';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.dataobjects.SmarthubLoginDO
 */
export interface SmarthubLoginDo extends PersistentDataObjectBase {
    loginId: number;
    propertyCd: string;
    ownerId: number;
    ownerIdType: OwnerIdType;
    platformOs: SmarthubPlatformOS;
    deviceType: SmarthubDeviceType;
    browser: NullableSmarthubBrowser;
    osVersion: string;
    createdDtt: number;
}

export function createSmarthubLoginDo(): SmarthubLoginDo {
    return {
        loginId: 0,
        propertyCd: '',
        ownerId: 0,
        ownerIdType: 'Resident',
        platformOs: 'Web',
        deviceType: 'Handset',
        browser: 'Chrome',
        osVersion: '',
        createdDtt: -1,
        databaseId: '',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
