import React, { useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { MaterialIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';

type CollapsibleContentProps = {
    expanded?: boolean;
    header?: React.ReactNode;
    children?: React.ReactNode;
    testId?: string;
};

const defaultProps: CollapsibleContentProps = {
    expanded: false,
};

const CollapsibleContent: React.FC<CollapsibleContentProps> = ({
    expanded,
    header,
    children,
    testId,
}: CollapsibleContentProps) => {
    const { useFullExperience } = useResponsiveContext();

    const [isExpanded, setIsExpanded] = useState(useFullExperience ? true : expanded);

    const toggleExpanded = (): void => {
        if (useFullExperience) {
            return;
        }
        setIsExpanded(!isExpanded);
    };

    return (
        <View testID={testId}>
            <TouchableWithoutFeedback onPress={toggleExpanded}>
                <View style={styles.header}>
                    {!useFullExperience && (
                        <MaterialIcons
                            name={isExpanded ? 'keyboard-arrow-down' : 'keyboard-arrow-right'}
                            size={30}
                            style={styles.arrowIcon}
                        />
                    )}
                    {header}
                </View>
            </TouchableWithoutFeedback>
            <View style={isExpanded ? styles.contentShow : styles.contentHide}>{children}</View>
        </View>
    );
};

CollapsibleContent.defaultProps = defaultProps;

export default CollapsibleContent;

const styles = StyleSheet.create({
    header: {
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    arrowIcon: {
        paddingTop: 36,
        position: 'absolute',
        right: 0,
    },
    contentHide: {
        display: 'none',
    },
    contentShow: {
        display: 'flex',
    },
});
