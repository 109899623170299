import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { QueryKeys } from 'queries/QueryKeys';
import { UIStore } from 'stores/domain/UIStore';
import {
    createGetRenewMyLeaseDataCommandV1,
    GetRenewMyLeaseDataCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/renewal/GetRenewMyLeaseDataCommandV1';
import { GetRenewMyLeaseDataResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/renewal/GetRenewMyLeaseDataResultV1';

export const useRenewMyLeaseDataQuery = (
    propertyCd: string,
    residencyId: number,
    hasSessionActiveResidency: boolean,
    uiStore: UIStore,
) => {
    // Set query key
    const queryKey = [QueryKeys.RenewMyLease, propertyCd, residencyId];

    const cmd = createGetRenewMyLeaseDataCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;

    const queryResult = useCommandQuery<GetRenewMyLeaseDataCommandV1, GetRenewMyLeaseDataResultV1>(
        cmd,
        uiStore,
        queryKey,
        {
            enabled: hasSessionActiveResidency,
        },
    );

    return { renewMyLeaseDataQueryKey: queryKey, renewMyLeaseDataResult: queryResult };
};
