import { PaymentFormValues } from 'utils/PayLeaseZegoUtils';
import { FormLabelText } from 'components/primitives/StyledText';
import { globalStyles } from 'theme/style/GlobalStyles';
import InputDropdown from 'components/input/InputDropdown';
import InputString from 'components/input/InputString';
import { Image, StyleSheet, View } from 'react-native';
import Hyperlink from 'components/primitives/Hyperlink';
import InputCheckbox from 'components/input/InputCheckbox';
import React from 'react';
import { FormikProps } from 'formik';
import { BankAccountTypeOptions } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/integration/paylease/dataobjects/BankAccountType';
import Dialog from 'components/primitives/Dialog';
import InputNumber from 'components/input/InputNumber';
import { testProps } from 'components/ComponentTypes';

type PayByBankFormProps = {
    formikHelpers: FormikProps<PaymentFormValues>;
};
const PayByBankForm: React.FC<PayByBankFormProps> = ({ formikHelpers }: PayByBankFormProps) => {
    const [showRoutingNumberTip, setShowRoutingNumberTip] = React.useState(false);
    const [showAccountNumberTip, setShowAccountNumberTip] = React.useState(false);

    return (
        <>
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                Account Type
            </FormLabelText>
            <InputDropdown
                options={[{ value: '', label: '' }, ...BankAccountTypeOptions]}
                onChange={value => {
                    formikHelpers.setFieldValue('accountType', value);
                }}
                {...testProps('select-account-type')}
                selectedValue={formikHelpers.values.accountType || ''}
                error={!!formikHelpers.errors.accountType}
                errorMessage={formikHelpers.errors.accountType}
            />
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                Name on Account
            </FormLabelText>
            <InputString
                {...testProps('account-name')}
                onChange={formikHelpers.handleChange('accountFullName')}
                error={!!formikHelpers.errors.accountFullName}
                errorMessage={formikHelpers.errors.accountFullName}
                maxLength={100}
            />
            {showRoutingNumberTip && (
                <Dialog
                    showCloseButton={true}
                    visible={showRoutingNumberTip}
                    onDismiss={() => {
                        setShowRoutingNumberTip(false);
                    }}
                >
                    <View style={styles.bankTipImageContainer}>
                        <Image
                            style={styles.bankTipImage}
                            source={require('../../../assets/images/cc-tip-routing-number.png')}
                        />
                    </View>
                </Dialog>
            )}
            <View style={[globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow, globalStyles.flexRow]}>
                <FormLabelText style={[styles.label, globalStyles.justflex1]}>Routing Number (9 digits)</FormLabelText>
                <Hyperlink
                    style={styles.labelTip}
                    onClick={() => {
                        setShowRoutingNumberTip(true);
                    }}
                >
                    What&apos;s this?
                </Hyperlink>
            </View>
            <InputNumber
                {...testProps('routing-number')}
                onChange={formikHelpers.handleChange('routingNumber')}
                error={!!formikHelpers.errors.routingNumber}
                errorMessage={formikHelpers.errors.routingNumber}
                maxLength={9}
            />
            {showAccountNumberTip && (
                <Dialog
                    showCloseButton={true}
                    visible={showAccountNumberTip}
                    onDismiss={() => {
                        setShowAccountNumberTip(false);
                    }}
                >
                    <View style={styles.bankTipImageContainer}>
                        <Image
                            style={styles.bankTipImage}
                            source={require('../../../assets/images/cc-tip-account-number.png')}
                        />
                    </View>
                </Dialog>
            )}
            <View style={[globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow, globalStyles.flexRow]}>
                <FormLabelText style={[styles.label, globalStyles.justflex1]}>Account Number</FormLabelText>
                <Hyperlink
                    style={styles.labelTip}
                    onClick={() => {
                        setShowAccountNumberTip(true);
                    }}
                >
                    What&apos;s this?
                </Hyperlink>
            </View>
            <InputNumber
                {...testProps('account-number')}
                onChange={value => {
                    formikHelpers.setFieldValue('accountNumber', value);
                    formikHelpers.setFieldValue('lastFour', value.substr(-4));
                }}
                error={!!formikHelpers.errors.accountNumber}
                errorMessage={formikHelpers.errors.accountNumber}
                maxLength={20}
            />
            <View style={globalStyles.tripleSpaceAbove}>
                <InputCheckbox
                    label={'Save this payment info for future use'}
                    style={'small'}
                    initialStatus={'checked'}
                    onChange={checked => formikHelpers.setFieldValue('save', checked === 'checked')}
                />
            </View>
        </>
    );
};

export default PayByBankForm;

const styles = StyleSheet.create({
    label: {
        fontSize: 14,
    },
    labelTip: {
        marginTop: 3,
        fontSize: 13,
    },
    bankTipImage: {
        width: '100%',
        height: 243,
    },
    bankTipImageContainer: {
        width: '100%',
    },
});
