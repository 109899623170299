import { AbstractAwayTimeAuthenticatedCommand } from '../AbstractAwayTimeAuthenticatedCommand';
import { DeleteAwayTimeResultV1 } from './DeleteAwayTimeResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.DeleteAwayTimeCommandV1
 */
export type DeleteAwayTimeCommandV1 = AbstractAwayTimeAuthenticatedCommand<DeleteAwayTimeResultV1>;

export function createDeleteAwayTimeCommandV1(): DeleteAwayTimeCommandV1 {
    return {
        propertyCd: '',
        residentAwayTimeId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.DeleteAwayTimeCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
