import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';

export default async function registerForPushNotificationsAsync() {
    // Remote notifications do not work in simulators, only on device
    if (!Constants.isDevice) {
        return;
    }
    //TODO fix web notifications so we can turn this on
    if (Platform.OS === 'web') {
        return;
    }

    if (Platform.OS === 'android') {
        const channels = [
            'Statements',
            'Community Bulletins',
            'Contests | Specials | Offers',
            'Emergency',
            'Events',
            'Mandatory | Legally Required',
        ];
        channels.forEach(c => {
            Notifications.setNotificationChannelAsync(c, {
                name: c,
                importance: Notifications.AndroidImportance.MAX,
                enableLights: true,
                enableVibrate: true,
                showBadge: true,
            });
        });
    }

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
        try {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        } catch (e) {
            //ignore
        }
    }
    if (finalStatus !== 'granted') {
        //alert('Failed to get push token for push notification!');
        return;
    }

    logInfo(INFO_LOGGER, 'Push notification status : ', finalStatus);
}

// _handleNotification = (notification) => {
//   logInfo(INFO_LOGGER, "notification: ", notification)

//   let { origin, data } = notification; //JSON format: {"propertyCd":"TX4CE", "requestId": 12077, "taskNo": 5, "title": "Task Assigned", "body":"12077-5 was assigned to you"}
//   if (origin === 'received' && AppState.currentState === 'active') {
//       this._handleReceivedNotif(notification);
//   } else if (origin === 'selected' && !(null!=notification.actionId && notification.actionId==="dismiss")){
//       this._handleSelectedNotif(data);
//   }
// };
