import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetCreateRequestDataResultV1 } from './GetCreateRequestDataResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.GetCreateRequestDataCommandV1
 */
export interface GetCreateRequestDataCommandV1
    extends AbstractResidencyAuthenticatedCommand<GetCreateRequestDataResultV1> {
    residentId: number;
}

export function createGetCreateRequestDataCommandV1(): GetCreateRequestDataCommandV1 {
    return {
        residentId: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.GetCreateRequestDataCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
