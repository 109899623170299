import { StyleSheet, View } from 'react-native';
import Button from 'components/primitives/Button';
import { AntDesign } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import ActionMenu, { ActionMenuItem } from 'components/primitives/ActionMenu';
import * as React from 'react';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { Menu } from 'react-native-popup-menu';

interface PlusIconProps {
    menuItem?: Array<ActionMenuItem>;
    onClick?: () => void;
    buttonTitle?: string;
    testId?: string;
}

const defaultProps: PlusIconProps = {};

const PlusIcon: React.FC<PlusIconProps> = (props: PlusIconProps) => {
    const _handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };
    const { useDrawerNavigation } = useResponsiveContext();
    const menu = React.createRef<Menu>();

    if (props.menuItem) {
        return (
            <ActionMenu
                ref={menu}
                style={styles.plusIcon}
                menuTrigger={
                    useDrawerNavigation ? (
                        <View style={styles.createButton}>
                            <Button onClick={_handleClick} testID={props.testId}>
                                {props.buttonTitle}
                            </Button>
                        </View>
                    ) : (
                        <AntDesign
                            name='pluscircle'
                            size={SmarthubTheme.layout.ICONWIDTH}
                            color={SmarthubTheme.colors.white}
                            testID={props.testId}
                            accessibilityLabel={props.testId}
                        />
                    )
                }
                menuItem={props.menuItem}
            />
        );
    } else {
        return (
            <AntDesign
                name='pluscircle'
                size={SmarthubTheme.layout.ICONWIDTH}
                color={SmarthubTheme.colors.white}
                testID={props.testId}
                accessibilityLabel={props.testId}
                onPress={() => {
                    _handleClick();
                }}
            />
        );
    }
};

PlusIcon.defaultProps = defaultProps;

export default PlusIcon;

const styles = StyleSheet.create({
    plusIcon: {
        marginTop: 'auto',
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    createButton: {
        marginRight: SmarthubTheme.layout.SURROUNDMARGIN,
        marginBottom: 'auto',
    },
});
