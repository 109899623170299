import {
    MutationOptions,
    QueryClient,
    QueryKey,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { UIStore } from 'stores/domain/UIStore';
import { useEffect } from 'react';
import { AbstractResidencyAuthenticatedCommand } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/AbstractResidencyAuthenticatedCommand';

export const useCommandQuery = <C, R>(
    cmd: C,
    uiStore: UIStore,
    key?: QueryKey,
    queryOptions?: UseQueryOptions<unknown, unknown, R>,
): UseQueryResult<R> => {
    const queryKey = key ?? generateQueryKeyFromCmd(cmd);
    return useQuery<unknown, unknown, R>(queryKey, () => dispatchCommandAsync<C, R>(uiStore, cmd, false), queryOptions);
};

export const prefetchCommandQuery = <C, R>(cmd: C, uiStore: UIStore, queryClient: QueryClient, key?: QueryKey) => {
    const queryKey = key ?? generateQueryKeyFromCmd(cmd);
    return queryClient.prefetchQuery(queryKey, () => dispatchCommandAsync<C, R>(uiStore, cmd, false));
};

export const useCommandMutation = <C, R>(uiStore: UIStore, mutationOptions?: MutationOptions<R, unknown, C>) => {
    return useMutation<R, unknown, C>(cmd => dispatchCommandAsync<C, R>(uiStore, cmd, false), mutationOptions);
};

export const useCommandInfiniteQuery = <C, R extends Record<string, Array<T>>, T>(
    cmd: InfiniteQueryCMD & C & AbstractResidencyAuthenticatedCommand<R>,
    uiStore: UIStore,
    rowLimit: number,
    dataName: string,
    key?: QueryKey,
    options?: UseInfiniteQueryOptions<any, T, { data: Array<T>; nextStartingIndex: number }>,
): UseInfiniteQueryResult<{ data: Array<T>; nextStartingIndex: number }> => {
    const queryKey = key ?? generateInfiniteQueryKeyFromCmd<C, R>(cmd);
    const queryOptions = {
        ...options,
        // This side effect is necessary to add so the query knows if there are more pages that can be fetched
        // Since there nothing coming from the API telling is how much data is left, we must figure it out by checking if the amount of data
        // returned is less than the amount of data we expected. If so then there aren't any pages left to get.
        getNextPageParam: (lastPage: { data: Array<T>; nextStartingIndex: number }) => {
            if (lastPage.data !== undefined && lastPage.data.length === lastPage.nextStartingIndex) {
                return lastPage.nextStartingIndex;
            }
            return undefined;
        },
    };
    return useInfiniteQuery(
        queryKey,
        queryReturn => _getInfiniteQueryCommand<C, R, T>(queryReturn.pageParam, cmd, rowLimit, uiStore, dataName),
        queryOptions,
    );
};

// This takes the useInfiniteQuery hook result and turns it into a format Flatlist can use.
// All this does is merge each page into one page so that flat list has access to all the results in that one page
export const useAlterInfiniteQueryDataForFlatList = (queryKey: QueryKey) => {
    const queryClient = useQueryClient();
    const data:
        | { pages: Array<{ data: Array<unknown>; nextStartingIndex: number }>; pageParams?: Array<number> }
        | undefined = useQueryClient().getQueryData(queryKey);
    useEffect(() => {
        if (data) {
            const newPagesArray = [
                {
                    data: [] as Array<unknown>,
                    nextStartingIndex: 0,
                },
            ];
            // Map through each page and concat the data into the newPagesArray[0].data
            data.pages.map((page: { data: Array<unknown>; nextStartingIndex: number }) => {
                newPagesArray[0].data = [...newPagesArray[0].data.concat(page.data)];
                newPagesArray[0].nextStartingIndex = page.nextStartingIndex;
            });

            queryClient.setQueryData(queryKey, () => ({
                pages: newPagesArray,
            }));
        }
    }, [data]);
};

export function generateQueryKeyFromCmd(cmd: any) {
    return [getCmdClass(cmd), ...Object.values(cmd)];
}

export function getCmdClass<C>(cmd: C) {
    // @ts-ignore
    return cmd['_cmdSvrClass'];
}

export function generateInfiniteQueryKeyFromCmd<C, R>(
    cmd: InfiniteQueryCMD & C & AbstractResidencyAuthenticatedCommand<R>,
    toggle = false,
) {
    return [getCmdClass(cmd), cmd.propertyCd, cmd.residencyId, cmd.rowLimit, toggle];
}

export type InfiniteQueryCMD = {
    startIndex: number;
    rowLimit: number;
};

// This dispatches the command for the query
const _getInfiniteQueryCommand = <C, R extends Record<string, Array<T>>, T>(
    pageParam: number | undefined,
    cmd: InfiniteQueryCMD & C,
    rowLimit: number,
    uiStore: UIStore,
    dataName: string,
): Promise<{ data: Array<T>; nextStartingIndex: number }> => {
    // This is where the command takes the updated pageParam (which is the nextStartingIndex) and uses it to update the start index
    if ('startIndex' in cmd) {
        cmd.startIndex = pageParam ? pageParam : 0;
    }
    return dispatchCommandAsync<C, R>(uiStore, cmd, false).then((dispatchResult: R) => {
        // Our API doesn't use pages, it uses a starting index. So we increment the next starting index here
        const nextStartingIndex = pageParam === undefined || pageParam === 0 ? rowLimit : pageParam + rowLimit;
        // Infinite query needs a next index variable to increment along with the data from the result
        return {
            data: dispatchResult[dataName],
            nextStartingIndex: nextStartingIndex,
        };
    });
};
