import * as React from 'react';
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { RegularText } from 'components/primitives/StyledText';
import { useEffect } from 'react';

interface InputSwitchProps {
    testID?: string;
    accessibilityLabel?: string;
    label?: string;
    initialStatus?: boolean;
    disabled?: boolean;
    onChange?: (status: boolean) => void;
    labelColor?: string;
}

const defaultProps: InputSwitchProps = {
    initialStatus: false,
    disabled: false,
};

const InputSwitch: React.FC<InputSwitchProps> = (props: InputSwitchProps) => {
    const [status, setStatus] = React.useState(props.initialStatus);

    useEffect(() => {
        setStatus(props.initialStatus);
    }, [props.initialStatus]);

    const _handleChange = () => {
        if (!props.disabled) {
            const newStatus = !status;

            setStatus(newStatus);

            logInfo(INFO_LOGGER, newStatus);

            if (props.onChange) {
                props.onChange(newStatus);
            }
        }
    };

    let switchStyle: any = styles.inputSwitch;
    let toggleStyle: any = styles.inputSwitchToggle;
    if (status === true) {
        switchStyle = [...[switchStyle], styles.inputSwitchOn];
        toggleStyle = [...[toggleStyle], styles.inputSwitchToggleOn];
    }
    if (props.disabled) {
        switchStyle = [...[switchStyle], styles.inputSwitchDisabled];
    }

    return (
        <View style={styles.inputSwitchWrapper} testID={props.testID} accessibilityLabel={props.accessibilityLabel}>
            <TouchableWithoutFeedback onPress={_handleChange}>
                <View style={switchStyle}>
                    <View style={toggleStyle} />
                </View>
            </TouchableWithoutFeedback>
            {props.label && (
                <View style={styles.inputSwitchLabel}>
                    <RegularText style={props.labelColor ? { color: props.labelColor } : {}}>{props.label}</RegularText>
                </View>
            )}
        </View>
    );
};

InputSwitch.defaultProps = defaultProps;

export default InputSwitch;

const styles = StyleSheet.create({
    inputSwitchWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 8,
    },
    inputSwitch: {
        position: 'relative',
        width: 51,
        height: 31,
        borderWidth: 1,
        borderRadius: 16,
        borderColor: SmarthubTheme.colors.border,
    },
    inputSwitchOn: {
        borderColor: SmarthubTheme.colors.lightblue,
        backgroundColor: SmarthubTheme.colors.lightblue,
    },
    inputSwitchDisabled: {
        borderColor: SmarthubTheme.colors.lightgrey,
        backgroundColor: SmarthubTheme.colors.lightgrey,
    },
    inputSwitchToggle: {
        left: -1,
        width: 29,
        height: 29,
        borderWidth: 1,
        borderRadius: 16,
        borderColor: SmarthubTheme.colors.border,
        shadowColor: SmarthubTheme.colors.black,
        shadowOffset: {
            width: 0,
            height: 1.5,
        },
        shadowOpacity: 0.1,
        shadowRadius: 1,
        elevation: SmarthubTheme.layout.SURFACEELEVATION,
        backgroundColor: SmarthubTheme.colors.white,
    },
    inputSwitchToggleOn: {
        left: 21,
    },
    inputSwitchLabel: {
        paddingTop: 6,
        paddingLeft: 8,
    },
});
