import { SmarthubRequestType } from '../../businesslogic/smarthub/dataobjects/SmarthubRequestType';
import { AbstractRequestAuthenticatedCommand } from '../AbstractRequestAuthenticatedCommand';
import { CancelRequestResultV1 } from './CancelRequestResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.CancelRequestCommandV1
 */
export interface CancelRequestCommandV1 extends AbstractRequestAuthenticatedCommand<CancelRequestResultV1> {
    requestType: SmarthubRequestType;
    message: string;
}

export function createCancelRequestCommandV1(): CancelRequestCommandV1 {
    return {
        requestType: 'MaintenanceRequest',
        message: '',
        propertyCd: '',
        requestId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.CancelRequestCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
