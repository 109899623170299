import {
    createGetSmarthubManageMyLeaseDataCommandV1,
    GetSmarthubManageMyLeaseDataCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetSmarthubManageMyLeaseDataCommandV1';
import { useStore } from 'contexts/StoreContextProvider';
import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { GetSmarthubManageMyLeaseDataResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetSmarthubManageMyLeaseDataResultV1';

export const generateGetSmarthubManageMyLeaseDataCmd = (propertyCd: string, residencyId: number) => {
    const cmd = createGetSmarthubManageMyLeaseDataCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    return cmd;
};

export const useGetSmarthubManageMyLeaseData = () => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateGetSmarthubManageMyLeaseDataCmd(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
    );

    const queryOptions = {
        enabled: hasSessionActiveResidency,
    };

    return useCommandQuery<GetSmarthubManageMyLeaseDataCommandV1, GetSmarthubManageMyLeaseDataResultV1>(
        cmd,
        uiStore,
        undefined,
        queryOptions,
    );
};
