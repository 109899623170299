import React from 'react';
import { StyleProp, StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';

interface ContainerProps {
    testID?: string;
    borderStyle?: 'none' | 'grey' | 'orange';
    dropShadow?: boolean;
    width?: number | string | 'auto';
    height?: number | string | 'auto';
    style?: StyleProp<any>;
    children?: React.ReactNode;
}

const defaultProps: ContainerProps = {
    borderStyle: 'none',
    dropShadow: true,
    width: 'auto',
    height: 'auto',
};

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
    const _getContainerClass = () => {
        let containerClass: any = props.dropShadow
            ? [styles.containerWithShadow, props.style, { width: props.width, height: props.height }]
            : [props.style, { width: props.width, height: props.height }];
        if (props.borderStyle) {
            switch (props.borderStyle) {
                case 'grey':
                    containerClass = [...containerClass, styles.containerBorderGrey];
                    break;
                case 'orange':
                    containerClass = [...containerClass, styles.containerBorderOrange];
                    break;
            }
        }

        return containerClass;
    };

    const style = _getContainerClass();

    return (
        <View style={style} testID={props.testID}>
            {props.children}
        </View>
    );
};

Container.defaultProps = defaultProps;

export default Container;

const styles = StyleSheet.create({
    containerWithShadow: {
        shadowColor: SmarthubTheme.colors.dropshadow,
        shadowOpacity: 0.1,
        shadowOffset: {
            width: 0,
            height: SmarthubTheme.layout.SURFACEELEVATION,
        },
        shadowRadius: SmarthubTheme.layout.SURFACEELEVATION,
        elevation: SmarthubTheme.layout.SURFACEELEVATION,
        backgroundColor: SmarthubTheme.colors.white,
    },
    containerBorderGrey: {
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.disabled,
    },
    containerBorderOrange: {
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.orange,
    },
});
