export type ReOpenRequestReason = 'ProblemNotSolved' | 'ProblemCameBack' | 'Other';

export type NullableReOpenRequestReason = ReOpenRequestReason | null;

export const ReOpenRequestReasonOptions = [
    { value: 'ProblemNotSolved', label: 'Problem Not Solved' },
    { value: 'ProblemCameBack', label: 'Problem Came Back' },
    { value: 'Other', label: 'Other' },
];

export const ReOpenRequestReasonOptionsWithBlank = [{ value: '', label: '' }].concat(ReOpenRequestReasonOptions);

/* START CUSTOM CODE */
/* END CUSTOM CODE */
