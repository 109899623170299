import { SmartHubResidentContactInfoV1 } from '../../businesslogic/smarthub/versioneddataobjects/requests/SmartHubResidentContactInfoV1';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { RenewMyLeaseResultV1 } from './RenewMyLeaseResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.renewal.RenewMyLeaseCommandV1
 */
export interface RenewMyLeaseCommandV1 extends AbstractResidencyAuthenticatedCommand<RenewMyLeaseResultV1> {
    leaseTerm: number;
    contactInfoList: Array<SmartHubResidentContactInfoV1>;
}

export function createRenewMyLeaseCommandV1(): RenewMyLeaseCommandV1 {
    return {
        leaseTerm: 0,
        contactInfoList: [],
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.renewal.RenewMyLeaseCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
