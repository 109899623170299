/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.smartmove.SmartmoveRenterInsuranceV1
 */
export interface SmartmoveRenterInsuranceV1 {
    policyId: number;
    residencyId: number;
    companyName: string;
    policyNumber: string;
    startDt: string;
    endDt: string;
    liabilityConfirmed: boolean;
    policyResidents: Array<number | null>;
    toDeleteYn: boolean;
    updatedDtt: number;
}

export function createSmartmoveRenterInsuranceV1(): SmartmoveRenterInsuranceV1 {
    return {
        policyId: 0,
        residencyId: 0,
        companyName: '',
        policyNumber: '',
        startDt: '01/01/1900',
        endDt: '01/01/1900',
        liabilityConfirmed: false,
        policyResidents: [],
        toDeleteYn: false,
        updatedDtt: -1,
    };
}

/* START CUSTOM CODE */
export function createDefaultSmartmoveRenterInsuranceV1(residencyId: number): SmartmoveRenterInsuranceV1 {
    const defaultPolicy = createSmartmoveRenterInsuranceV1();
    defaultPolicy.policyId = -1;
    defaultPolicy.residencyId = residencyId;
    defaultPolicy.startDt = '';
    defaultPolicy.endDt = '';

    return defaultPolicy;
}
/* END CUSTOM CODE */
