import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UIStore } from 'stores/domain/UIStore';
import { UseQueryOptions } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import {
    createGetSmartmoveVehiclesAndPetsCommandV1,
    GetSmartmoveVehiclesAndPetsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveVehiclesAndPetsCommandV1';
import { GetSmartmoveVehiclesAndPetsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveVehiclesAndPetsResultV1';

export const useVehiclesAndPetsQuery = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetSmartmoveVehiclesAndPetsResultV1>,
) => {
    //build key
    const queryKey = [QueryKeys.VehiclesAndPets, propertyCd, residencyId];

    //build command
    const cmd = createGetSmartmoveVehiclesAndPetsCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;

    //Setup query
    const queryResult = useCommandQuery<GetSmartmoveVehiclesAndPetsCommandV1, GetSmartmoveVehiclesAndPetsResultV1>(
        cmd,
        uiStore,
        queryKey,
        queryOptions,
    );
    return { queryKey, queryResult };
};
