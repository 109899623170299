import React, { useEffect, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from 'screens/auth/LoginScreen';
import DrawerNavigator from '../navigators/DrawerNavigator';
import BottomTabNavigator from '../navigators/BottomTabNavigator';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { observer } from 'mobx-react-lite';
import { StatusBar } from 'react-native';
import { useTheme } from 'react-native-paper';
import MakeAPayment from 'screens/home/transactions/MakeAPayment';
import CreateMaintenanceRequest from 'screens/home/requests/CreateMaintenanceRequest';
import CreateTicket from 'screens/home/requests/CreateTicket';
import Profile from 'screens/more/personal/Profile';
import PreviousStatements from 'screens/home/transactions/PreviousStatements';
import PaymentScreen from 'screens/home/transactions/PaymentScreen';
import { getModalScreenOptions } from 'navigation/stacks/ModalScreenOptions';
import { useStore } from 'contexts/StoreContextProvider';
import ActivityIndicator from 'components/primitives/ActivityIndicator';
import Toaster from 'components/primitives/Toaster';
import LeaseSelector from 'screens/home/LeaseSelector';
import PaymentConfirmation from 'screens/home/transactions/PaymentConfirmation';
import TooltipOverlay from 'components/primitives/TooltipOverlay';
import Alert from 'components/primitives/Alert';
import Overlay from 'components/primitives/Overlay';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import FeedbackDialog from 'utils/errorhandling/FeedbackDialog';
import { NavigationContainerRef } from '@react-navigation/native';
import ResidentID from 'screens/home/ResidentID';
import OnboardingComponent from 'navigation/stacks/OnboardingComponent';
import CreateMaintenanceRequestCovidQuestion from 'screens/home/requests/CreateMaintenanceRequestCovidQuestion';
import NoticeToVacateRequest from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacateRequest';
import NoticeToVacateConfirmation from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacateConfirmation';
import SodaRecipient from 'screens/home/manageMyLease/noticeToVacate/SodaRecipient';
import AddressPicker from 'screens/home/manageMyLease/noticeToVacate/AddressPicker';
import NoticeToVacatePreferContact from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacatePreferContact';
import NoticeToVacateCancel from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacateCancel';
import AddEditAwayTime from 'screens/more/personal/awayTimes/AddEditAwayTime';
import AppBanner from 'components/appTeasers/AppBanner';
import SplashScreen from 'components/appTeasers/SplashScreen';
import { setDismissBanner, showAppBanner, showSplashScreen } from 'utils/appTeasers';
import { navigationRoutes } from 'utils/NavigationUtils';
import SmartMoveStackNavigator from 'navigation/stacks/SmartMoveStack';
import ZegoPaymentScreen from 'screens/home/transactions/ZegoPaymentScreen';
import ZegoPaymentReview from 'screens/home/transactions/ZegoPaymentReview';
import RenewMyLease from 'screens/home/manageMyLease/renewal/RenewMyLease';
import ManageMyLease from 'screens/home/manageMyLease/ManageMyLease';
import RenewalSigningScreen from 'screens/home/manageMyLease/renewal/RenewalSigningScreen';
import ActivityIndicatorWithMessage from 'components/primitives/ActivityIndicatorWithMessage';

export const navigationRef = React.createRef<NavigationContainerRef>();

//------------------------NOT LOGGED IN---------------------------------------

const NotLoggedInStack = createStackNavigator();

const NotLoggedInStackNavigator = observer(() => {
    const theme = useTheme();
    const insets = useSafeAreaInsets();
    const { uiStore } = useStore();

    return (
        <>
            <StatusBar backgroundColor={theme.colors.transparent} barStyle={'light-content'} translucent={true} />
            <NotLoggedInStack.Navigator
                screenOptions={{
                    safeAreaInsets: insets,
                    headerShown: false,
                    cardStyle: {
                        backgroundColor: theme.colors.background,
                        opacity: 0.99,
                    },
                    title: 'SMARTHUB',
                }}>
                <NotLoggedInStack.Screen name={navigationRoutes.notLoggedInRoutes.login} component={LoginScreen} />
            </NotLoggedInStack.Navigator>

            {uiStore.activityLoaderVisible && !!uiStore.activityLoaderMessage && (
                <ActivityIndicatorWithMessage message={uiStore.activityLoaderMessage} />
            )}
            {uiStore.activityLoaderVisible && !uiStore.activityLoaderMessage && <ActivityIndicator animating={true} />}

            {uiStore.alert != null && <Alert />}

            {uiStore.feedbackDialog != null && <FeedbackDialog />}
        </>
    );
});

export const NotLoggedInNavigator = () => {
    return <NotLoggedInStackNavigator />;
};

//------------------------LOGGED IN---------------------------------------
const LoggedInStack = createStackNavigator();

const LoggedInStackNavigator = observer(() => {
    const [showSplashTeaser, setShowSplashTeaser] = useState(false);
    const [showBannerTeaser, setShowBannerTeaser] = useState(false);
    const useDrawerNavigation = useResponsiveContext().useDrawerNavigation;
    const theme = useTheme();
    const insets = useSafeAreaInsets();
    const { uiStore, userSessionStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    useEffect(() => {
        (async () => {
            const showTeaserBanner = await showAppBanner();
            setShowBannerTeaser(showTeaserBanner);
            const showTeaserSplash = await showSplashScreen();
            setShowSplashTeaser(showTeaserSplash);
        })();
    }, []);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
        <>
            {showSplashTeaser && (
                <SplashScreen
                    closeSplashScreen={() => {
                        setShowSplashTeaser(false);
                    }}
                />
            )}
            {showBannerTeaser && (
                <AppBanner
                    closeAppBanner={() => {
                        setDismissBanner();
                        setShowBannerTeaser(false);
                    }}
                />
            )}

            <StatusBar backgroundColor={theme.colors.primary} barStyle={'light-content'} />

            <LoggedInStack.Navigator
                screenOptions={{
                    safeAreaInsets: insets,
                    headerShown: false,
                    cardStyle: { backgroundColor: theme.colors.background },
                    //animationEnabled: false,
                    title: 'SMARTHUB',
                }}
                mode='modal'>
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.loggedIn}
                    component={
                        hasSessionActiveResidency && sessionActiveResidency.statusCd === 'Leased'
                            ? SmartMoveStackNavigator
                            : useDrawerNavigation
                            ? DrawerNavigator
                            : BottomTabNavigator
                    }
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.manageMyLeaseModal}
                    component={ManageMyLease}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                    initialParams={{ title: 'Manage My Lease' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.noticeToVacateRequestModal}
                    component={NoticeToVacateRequest}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                    initialParams={{ title: 'Notice to Vacate' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.sodaRecipientModal}
                    component={SodaRecipient}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme)}
                    initialParams={{ title: 'Notice to Vacate' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.addressPickerModal}
                    component={AddressPicker}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.noticeToVacatePreferContactModal}
                    component={NoticeToVacatePreferContact}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme)}
                    initialParams={{ title: 'Notice to Vacate' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.noticeToVacateCancelModal}
                    component={NoticeToVacateCancel}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme)}
                    initialParams={{ title: 'Cancel Notice to Vacate' }}
                />
                <LoggedInStack.Screen
                    name='NoticeToVacateConfirmationModal'
                    component={NoticeToVacateConfirmation}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false, false)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.onboardingModal}
                    component={OnboardingComponent}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false, false)}
                    initialParams={{ title: 'Make a Payment' }}
                />

                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.leaseSelectorModal}
                    component={LeaseSelector}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                />
                {/*<LoggedInStack.Screen
                    name='PaymentDetails'
                    component={PaymentDetails}
                    options={{
                        ...breatheInAndOut(),
                        headerShown: false,
                        //cardStyle: { backgroundColor: theme.colors.transparent },
                    }}
                />*/}
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.makeAPaymentModal}
                    component={MakeAPayment}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                    initialParams={{ title: 'Make a Payment' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.previousStatementModal}
                    component={PreviousStatements}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, true, false)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.paymentScreenModal}
                    component={PaymentScreen}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false, true)}
                    initialParams={{ title: 'Make a Payment' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.zegoPaymentScreenModal}
                    component={ZegoPaymentScreen}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false, true)}
                    initialParams={{ title: 'Make a Payment' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.zegoPaymentReviewModal}
                    component={ZegoPaymentReview}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, true, true)}
                    initialParams={{ title: 'Make a Payment' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.paymentConfirmationModal}
                    component={PaymentConfirmation}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false, false)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.createARequestModal}
                    component={CreateMaintenanceRequestCovidQuestion}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.createARequestModal2}
                    component={CreateMaintenanceRequest}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.createATicketModal}
                    component={CreateTicket}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.profileModal}
                    component={Profile}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.residentIdModal}
                    component={ResidentID}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.addEditAwayTimeModal}
                    component={AddEditAwayTime}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, false)}
                    initialParams={{ title: 'Add a new Away Time' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.renewMyLeaseModal}
                    component={RenewMyLease}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, true)}
                    initialParams={{ title: 'Renew My Lease' }}
                />
                <LoggedInStack.Screen
                    name={navigationRoutes.loggedInRoutes.renewalSigningModal}
                    component={RenewalSigningScreen}
                    options={({ navigation }) => getModalScreenOptions(navigation, theme, true)}
                    initialParams={{ title: 'Renewal Contract Signing' }}
                />
            </LoggedInStack.Navigator>

            <Toaster />

            {uiStore.activityLoaderVisible && !!uiStore.activityLoaderMessage && (
                <ActivityIndicatorWithMessage message={uiStore.activityLoaderMessage} />
            )}
            {uiStore.activityLoaderVisible && !uiStore.activityLoaderMessage && <ActivityIndicator animating={true} />}

            {uiStore.overlayVisible && <Overlay testID={'generic-overlay'} />}

            {uiStore.alert != null && <Alert />}

            {uiStore.feedbackDialog != null && <FeedbackDialog />}

            {uiStore.tooltip != null && <TooltipOverlay testID={'simulation-tooltip'} />}
        </>
    );
});

export const LoggedInNavigator = observer(() => {
    return <LoggedInStackNavigator />;
});
