/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.dataobjects.SmarthubTicketSubmitData
 */
export interface SmarthubTicketSubmitData {
    propertyCd: string;
    residencyId: number;
    residentId: number;
    desc: string;
    escalateYn: boolean;
    contactDetail: string;
    categoryTypeDv: string | null;
}

export function createSmarthubTicketSubmitData(): SmarthubTicketSubmitData {
    return {
        propertyCd: '',
        residencyId: 0,
        residentId: 0,
        desc: '',
        escalateYn: false,
        contactDetail: '',
        categoryTypeDv: null,
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
