import React, { useEffect } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';

import { observer } from 'mobx-react-lite';
import {
    DeemphansizedTextSmall,
    DeephansizedRegularText,
    LargeBoldText,
    RegularText,
} from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';
import { Badge, useTheme } from 'react-native-paper';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { daysBetween, formatDateStringMonthLabel, parseDateMMDDYYYY } from 'utils/DateUtils';
import * as WebBrowser from 'expo-web-browser';
import Hyperlink from 'components/primitives/Hyperlink';
import { stringIsNotEmpty } from 'utils/StringUtils';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { StackNavigationProp } from '@react-navigation/stack';
import {
    isInLease,
    isOnMtm,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';
import { SmarthubLeaseContractV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubLeaseContractV1';
import FlexContainer from 'components/primitives/FlexContainer';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps, updateStringForIDName } from 'components/ComponentTypes';
import { QueryKey } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import {
    createGetLeaseContractsCommandV1,
    GetLeaseContractsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetLeaseContractsCommandV1';
import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { GetLeaseContractsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetLeaseContractsResultV1';
import { viewPdfFromUrl } from 'utils/FileUtils';
import {
    generateMarkNotificationAsSeenCMD,
    getMatchingUnseenNotifications,
    getUnseenNotifications,
    hasUnseenNotificationForTypeAndId,
    useMarkNotificationSeenMutation,
    useSmarthubNotifications,
} from 'screens/home/requests/NotificationsHooks';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';
import { getRouteParam } from 'utils/routeUtils';
import { useRoute } from '@react-navigation/native';

type ContractAndDocumentsProps = {
    navigation: StackNavigationProp<any>;
};

const ContractAndDocuments = observer(({ navigation }: ContractAndDocumentsProps) => {
    const { userSessionStore, uiStore } = useStore();
    const { confirmActiveResidency, sessionActiveResidency, hasSessionActiveResidency } = userSessionStore;

    const route = useRoute();
    const notificationRefNumber = getRouteParam(route, 'referenceNumber') || -1;
    const theme = useTheme();

    logInfo(INFO_LOGGER, 'Contract link: ', sessionActiveResidency.contractPdfLink || 'none');

    const today = new Date();
    const leaseEndDt = parseDateMMDDYYYY(sessionActiveResidency.leaseEndDt);

    if (null != leaseEndDt) {
        logInfo(INFO_LOGGER, 'daysBetween(today, leaseEndDt) ', daysBetween(today, leaseEndDt));
    }
    const daysRemaining = !sessionActiveResidency.onMtm && null != leaseEndDt ? daysBetween(today, leaseEndDt) : 0;

    // Set query key
    const queryKey: QueryKey = [
        QueryKeys.Contracts,
        userSessionStore.sessionActiveResidency.propertyCd,
        userSessionStore.sessionActiveResidency.residencyId,
    ];

    const cmd = createGetLeaseContractsCommandV1();
    cmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
    cmd.residencyId = userSessionStore.sessionActiveResidency.residencyId;

    const leaseContractsQuery = useCommandQuery<GetLeaseContractsCommandV1, GetLeaseContractsResultV1>(
        cmd,
        uiStore,
        queryKey,
        {
            enabled: hasSessionActiveResidency,
            staleTime: 6000 * 5,
        },
    );

    const notificationType = 'ElectronicContract';
    const markNotificationSeen = useMarkNotificationSeenMutation(notificationType, notificationRefNumber);

    const notifications: SmarthubNotificationV1[] = useSmarthubNotifications()?.data?.notifications || [];

    const unseenNotifs = getUnseenNotifications(notifications);

    useEffect(() => {
        leaseContractsQuery.isLoading ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [leaseContractsQuery.isLoading]);

    useEffect(() => {
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        confirmActiveResidency(navigation);
    }, [sessionActiveResidency]);

    // Parse documents for required document type and render open link
    const _displayDocumentLink = (
        type: string,
        file: string,
        fileName: string,
        linkText: string,
        portalContractId: number,
    ) => {
        return (
            <View style={styles.inline}>
                <Hyperlink
                    onClick={() => {
                        _openDocument(file, fileName, portalContractId);
                    }}>
                    {linkText}
                </Hyperlink>
            </View>
        );
    };

    const _openDocument = (file: string, fileName: string, notificationRefId: number) => {
        const matchingNotifications: SmarthubNotificationV1[] = getMatchingUnseenNotifications(
            unseenNotifs,
            notificationType,
            notificationRefId,
        );
        markNotificationSeen.mutate(
            generateMarkNotificationAsSeenCMD(
                sessionActiveResidency.propertyCd,
                sessionActiveResidency.residencyId,
                sessionActiveResidency.residentId,
                matchingNotifications,
            ),
        );
        viewPdfFromUrl(file, fileName);
    };

    if (leaseContractsQuery.isLoading || !leaseContractsQuery.data) {
        return null;
    }

    const { contracts } = leaseContractsQuery.data;

    return (
        <ScrollView contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
            <View style={styles.sectionbottom}>
                <DeephansizedRegularText style={styles.headingbottom}>Apartment</DeephansizedRegularText>
                <RegularText {...testProps('apt-and-prop')}>
                    Apt {sessionActiveResidency.bldgCd + '-' + sessionActiveResidency.unitCd} at{' '}
                    {sessionActiveResidency.propertyName}
                </RegularText>
            </View>

            <View style={styles.sectionbottom}>
                <DeephansizedRegularText style={styles.headingbottom} {...testProps('lease-term')}>
                    Lease Term
                </DeephansizedRegularText>
                <RegularText {...testProps('lease-details')}>
                    {formatDateStringMonthLabel(sessionActiveResidency.leaseStartDt)} to{' '}
                    {formatDateStringMonthLabel(sessionActiveResidency.leaseEndDt)}
                    {isInLease(sessionActiveResidency) && ` (${daysRemaining} days left)`}
                    {isOnMtm(sessionActiveResidency) ? ' (Currently on Month to Month)' : ''}
                </RegularText>
            </View>

            {/*<View style={styles.sectionbottom}>*/}
            {/*    <DeephansizedRegularText style={styles.headingbottom} testID={'notice-period'}>*/}
            {/*        Notice Period*/}
            {/*    </DeephansizedRegularText>*/}
            {/*    <RegularText>{sessionActiveResidency.noticeDays} days</RegularText>*/}
            {/*</View>*/}

            <View style={styles.sectionbottom}>
                <DeephansizedRegularText style={styles.headingbottom}>Occupants</DeephansizedRegularText>
                <RegularText {...testProps('occupants')}>
                    {sessionActiveResidency &&
                        sessionActiveResidency.occupantNames &&
                        sessionActiveResidency.occupantNames.join(', ')}
                </RegularText>
            </View>
            {(sessionActiveResidency.numPets > 0 ||
                sessionActiveResidency.numVehicles > 0 ||
                sessionActiveResidency.numRentables > 0) && (
                <View style={styles.sectionbottom}>
                    <View style={[styles.datarow, styles.headingbottom]}>
                        <View style={styles.sharedwidth}>
                            <DeephansizedRegularText>Pets</DeephansizedRegularText>
                        </View>
                        <View style={styles.sharedwidth}>
                            <DeephansizedRegularText>Vehicles</DeephansizedRegularText>
                        </View>
                        <View style={styles.sharedwidth}>
                            <DeephansizedRegularText>Rentables</DeephansizedRegularText>
                        </View>
                    </View>
                    <View style={styles.datarow}>
                        <View style={styles.sharedwidth}>
                            <RegularText {...testProps('pets')}>{sessionActiveResidency.numPets || 0}</RegularText>
                        </View>
                        <View style={styles.sharedwidth}>
                            <RegularText {...testProps('vehicles')}>
                                {sessionActiveResidency.numVehicles || 0}
                            </RegularText>
                        </View>
                        <View style={styles.sharedwidth}>
                            <RegularText {...testProps('rentables')}>
                                {sessionActiveResidency.numRentables || 0}
                            </RegularText>
                        </View>
                    </View>
                </View>
            )}
            {/*{stringIsNotEmpty(sessionActiveResidency.contractPdfLink) && (
            <View style={styles.sectionbottom}>
                <DeephansizedRegularText style={styles.headingbottom}>My Lease Contract</DeephansizedRegularText>
                <Hyperlink onClick={() => WebBrowser.openBrowserAsync(sessionActiveResidency.contractPdfLink)}>
                    View Contract (PDF)
                </Hyperlink>
            </View>
        )}*/}
            {stringIsNotEmpty(sessionActiveResidency.inventoryConditionPdfLink) && (
                <View style={styles.sectionbottom}>
                    <DeephansizedRegularText style={styles.headingbottom}>
                        Inventory and Condition Form
                    </DeephansizedRegularText>

                    <Hyperlink
                        onClick={() => WebBrowser.openBrowserAsync(sessionActiveResidency.inventoryConditionPdfLink)}>
                        View PDF
                    </Hyperlink>
                </View>
            )}

            {contracts.length > 0 && (
                <View style={styles.sectionContracts}>
                    <LargeBoldText style={styles.headingbottom}>Contracts*</LargeBoldText>
                    <View style={styles.sectionbottomBorder}>
                        <DeemphansizedTextSmall>
                            *Only contracts signed electronically are shown here.
                        </DeemphansizedTextSmall>
                    </View>

                    {contracts.map((contract: SmarthubLeaseContractV1, index: number) => {
                        const headingText = contract.modificationYn
                            ? `${contract.contractTypeDv} Amendment`
                            : `${contract.contractTypeDv} Contract`;

                        return (
                            <View key={`contract-summary-${index}`}>
                                <View style={styles.sectionbottom}>
                                    <FlexContainer flexDirection={'row'} justifyContent={'flex-start'}>
                                        <RegularText style={styles.contractHeading}>{headingText}</RegularText>
                                        {/*// @ts-ignore */}
                                        <Badge
                                            {...testProps('badge-' + updateStringForIDName(headingText))}
                                            dataDetectorType={'none'}
                                            visible={hasUnseenNotificationForTypeAndId(
                                                [notificationType],
                                                contract.portalContractId,
                                                notifications || ([] as SmarthubNotificationV1[]),
                                            )}
                                            size={10}
                                            style={[globalStyles.notificationBadge, styles.customBadge]}
                                        />
                                    </FlexContainer>

                                    <DeemphansizedTextSmall>
                                        Effective Date: {formatDateStringMonthLabel(contract.effectiveDt)}
                                    </DeemphansizedTextSmall>
                                </View>
                                <View
                                    style={[styles.datarow, styles.sectionbottomBorder]}
                                    {...testProps('contract-and-signing')}>
                                    {contract.signedContractFile &&
                                        _displayDocumentLink(
                                            'Content',
                                            contract.signedContractFile,
                                            'contract.pdf',
                                            'View Contract',
                                            contract.portalContractId,
                                        )}
                                    {contract.signedSummaryFile &&
                                        _displayDocumentLink(
                                            'Summary',
                                            contract.signedSummaryFile,
                                            'signingSummary.pdf',
                                            'View Signing Summary',
                                            contract.portalContractId,
                                        )}
                                </View>
                            </View>
                        );
                    })}
                </View>
            )}
        </ScrollView>
    );
});
export default ContractAndDocuments;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: SmarthubTheme.layout.SURROUNDMARGIN,
        marginTop: Platform.OS === 'web' ? SmarthubTheme.layout.SURROUNDMARGIN : SmarthubTheme.layout.GRIDINCREMENT * 7,
        maxWidth: 420,
    },
    sectionbottom: {
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    sectionbottomBorder: {
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 3,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 3,
        borderBottomColor: SmarthubTheme.colors.lightestgrey,
        borderBottomWidth: 1,
    },
    sectionContracts: {
        marginTop: SmarthubTheme.layout.SECTIONMARGIN,
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    headingbottom: {
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    datarow: {
        flexDirection: 'row',
    },
    sharedwidth: {
        flex: 1,
    },
    inline: {
        marginRight: SmarthubTheme.layout.GRIDINCREMENT * 4,
    },
    contractHeading: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    customBadge: { marginBottom: 11, marginLeft: 16 },
});
