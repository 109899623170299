import * as React from 'react';
import { StyleProp, StyleSheet, TextInput, View, Platform } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { RegularText } from 'components/primitives/StyledText';
import { InputStyles } from 'theme/style/InputStyles';
import { IconDefinition } from 'theme/IconDefinition';
import { useStore } from 'contexts/StoreContextProvider';
import { testProps } from 'components/ComponentTypes';

interface InputNumberProps {
    testID?: string;
    accessibilityLabel?: string;
    inputStyle?: 'normal' | 'transparent';
    label?: string;
    placeholder?: string;
    initialValue?: string;
    error?: boolean;
    errorMessage?: string;
    onChange?: (value: string) => void;
    onBlur?: (e: any) => void;
    maxLength?: number;
    icon?: IconDefinition;
    wrapperStyle?: StyleProp<any>;
}

const defaultProps: InputNumberProps = {
    error: false,
    inputStyle: 'normal',
};

const InputNumber: React.FC<InputNumberProps> = (props: InputNumberProps) => {
    const { uiStore } = useStore();

    let style: StyleProp<any> = props.error ? [InputStyles.input, InputStyles.inputError] : [InputStyles.input];

    if (props.inputStyle === 'transparent') {
        style = props.error ? [InputStyles.inputTransparent, InputStyles.inputError] : [InputStyles.inputTransparent];
    }

    style = props.icon
        ? props.icon.alignment === 'right'
            ? [style, InputStyles.inputIconRight]
            : [style, InputStyles.inputIcon]
        : style;
    if (uiStore.useDarkTheme) {
        style = [style, { color: SmarthubTheme.colors.white }];
    }

    return (
        <View style={[InputStyles.inputWrapper, props.wrapperStyle]}>
            <TextInput
                defaultValue={props.initialValue}
                testID={props.testID}
                accessibilityLabel={props.accessibilityLabel}
                maxLength={props.maxLength}
                style={style}
                placeholder={props.placeholder}
                placeholderTextColor={SmarthubTheme.colors.lightgrey}
                keyboardType={'number-pad'}
                onChangeText={props.onChange}
                onBlur={props.onBlur}
            />
            {props.icon && (
                <MaterialCommunityIcons
                    {...testProps('generic-number-icon')}
                    style={props.icon.alignment === 'right' ? styles.iconRight : styles.icon}
                    name={props.icon.iconID}
                    size={props.icon.size ? props.icon.size : SmarthubTheme.layout.ICONWIDTH}
                    color={props.icon.color}
                />
            )}
            {!!props.errorMessage && (
                <RegularText {...testProps('generic-number-error')} style={InputStyles.inputErrorMessage}>
                    {props.errorMessage}
                </RegularText>
            )}
        </View>
    );
};

InputNumber.defaultProps = defaultProps;

export default InputNumber;

const styles = StyleSheet.create({
    icon: {
        position: 'absolute',
        top: Platform.OS === 'ios' ? 10 : 16,
        left: 8,
    },
    iconRight: {
        position: 'absolute',
        top: Platform.OS === 'ios' ? 10 : 16,
        right: 8,
    },
});
