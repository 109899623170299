import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetSmarthubNotificationsResultV1 } from './GetSmarthubNotificationsResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.notifications.GetSmarthubNotificationsCommandV1
 */
export interface GetSmarthubNotificationsCommandV1
    extends AbstractResidencyAuthenticatedCommand<GetSmarthubNotificationsResultV1> {
    residentId: number;
    startIndex: number;
    rowLimit: number;
}

export function createGetSmarthubNotificationsCommandV1(): GetSmarthubNotificationsCommandV1 {
    return {
        residentId: 0,
        startIndex: -1,
        rowLimit: -1,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.notifications.GetSmarthubNotificationsCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
