import React, { useEffect } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { Heading2 } from 'components/primitives/StyledText';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'react-native-paper';
import { _describePeriod } from 'utils/DateUtils';
import TransactionDetailsList from 'screens/home/transactions/TransactionDetailsList';
import StatementPDFLink from 'screens/home/transactions/StatementPDFLink';
import { v4 as uuid } from 'uuid';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { globalStyles } from 'theme/style/GlobalStyles';

const PreviousStatements: React.FC = observer(() => {
    const { transactionsStore, userSessionStore, uiStore } = useStore();
    const { previousStatements } = transactionsStore;
    const navigation = useNavigation();
    const theme = useTheme();
    const { hasSessionActiveResidency, confirmActiveResidency, sessionActiveResidency } = userSessionStore;

    //grab the latest statement...

    useEffect(() => {
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        confirmActiveResidency(navigation);
        if (hasSessionActiveResidency) {
            uiStore.showActivityLoader();
            transactionsStore.obtainTransactionsFromServer(false, true).finally(() => uiStore.hideActivityLoader());
        }
    }, [sessionActiveResidency]);

    if (!previousStatements || previousStatements.length === 0) {
        return null;
    }

    return (
        <SafeAreaView style={[globalStyles.justflex1]}>
            <ScrollView style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                <View style={globalStyles.container}>
                    <Heading2 style={globalStyles.singleSpaceBelow}>Here are your previous statements:</Heading2>
                    {previousStatements.map(statement => (
                        <View key={uuid()} style={globalStyles.sectionSpaceAround}>
                            <Heading2 style={globalStyles.doubleSpaceBelow}>
                                {_describePeriod(statement.period)}
                            </Heading2>
                            <TransactionDetailsList currentTransaction={statement} />
                            <StatementPDFLink currentTransaction={statement} />
                        </View>
                    ))}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
});

export default PreviousStatements;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
});
