import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { EmptyVersionedRemoteResult } from '../../../remotecmd/versioned/EmptyVersionedRemoteResult';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.notifications.MarkSmarthubNotificationsSeenCommandV1
 */
export interface MarkSmarthubNotificationsSeenCommandV1
    extends AbstractResidencyAuthenticatedCommand<EmptyVersionedRemoteResult> {
    noticeIds: Array<number | null>;
    residentId: number;
}

export function createMarkSmarthubNotificationsSeenCommandV1(): MarkSmarthubNotificationsSeenCommandV1 {
    return {
        noticeIds: [],
        residentId: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.notifications.MarkSmarthubNotificationsSeenCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
