import React from 'react';
import { MediumBoldText, RegularText } from 'components/primitives/StyledText';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import SmartMoveMoveInChargesBreakdown from 'screens/smartmove/components/SmartMoveMoveInChargesBreakdown';
import {
    daysBetween,
    formatDateMonthLabelFullNoYear,
    formatDateStringMonthLabel,
    parseDateMMDDYYYY,
} from 'utils/DateUtils';
import { SmartmovePaymentInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmovePaymentInfoV1';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useNavigation } from '@react-navigation/native';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import Container from 'components/primitives/Container';
import { View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Section from 'components/primitives/Section';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';

const SmartMovePayBeforeMoveIn: React.FC = observer(() => {
    const navigation = useNavigation();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    ///header
    const applicationHeader = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );
    const isLoading = applicationHeader?.query.isLoading;
    const paymentInfoV1: SmartmovePaymentInfoV1 | undefined =
        applicationHeader.query.data?.smartmoveApplicationHeaderV1?.paymentInfoV1;
    const hasLastPaymentYn =
        !!sessionActiveResidency.lastPaymentAmt &&
        daysBetween(
            parseDateMMDDYYYY(sessionActiveResidency.lastPaymentDt),
            parseDateMMDDYYYY(sessionActiveResidency.propertySpecificDt),
        ) <= 2;

    const _reviewPayment = () => {
        if (paymentInfoV1) {
            if (!hasLastPaymentYn) {
                navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePaymentStart);
            } else {
                uiStore.showAlert({
                    message: `Please note that a payment of ${formatCentsAsDollars(
                        sessionActiveResidency.lastPaymentAmt,
                    )} was made on ${formatDateStringMonthLabel(
                        sessionActiveResidency.lastPaymentDt,
                    )}. Do you still want to continue and make another payment?`,
                    buttons: [
                        {
                            buttonText: 'No, go back',
                            buttonType: 'secondary',
                            onClick: uiStore.hideAlert,
                        },
                        {
                            buttonText: 'Yes, continue',
                            buttonType: 'primary',
                            onClick: () => {
                                uiStore.hideAlert();
                                navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePaymentStart);
                            },
                        },
                    ],
                });
            }
        }
    };

    return isLoading ? null : (
        <SmartmoveScreenContainer
            footerButtonProps={{
                text: 'Pay Now',
                onClick: _reviewPayment,
            }}>
            <MediumBoldText style={globalStyles.smartMoveTitleText}>Pay before move-in</MediumBoldText>
            <RegularText style={globalStyles.smartMoveParagraphText}>
                To prepare for your move-in on{' '}
                {formatDateMonthLabelFullNoYear(parseDateMMDDYYYY(sessionActiveResidency.expectMoveinDt))}, please
                ensure that you pay the amount listed below:
            </RegularText>
            {paymentInfoV1 && <SmartMoveMoveInChargesBreakdown restricted={paymentInfoV1.applyPaymentRestrictions} />}
            {hasLastPaymentYn && (
                <Section>
                    <Container borderStyle={'orange'}>
                        <View style={[globalStyles.flexColumnCenter, globalStyles.innerContainer]}>
                            <MaterialCommunityIcons
                                name='alert-circle'
                                size={SmarthubTheme.layout.LARGEICONSIZE}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <RegularText>
                                {`Please note that a payment of ${formatCentsAsDollars(
                                    sessionActiveResidency.lastPaymentAmt,
                                )} was made on ${formatDateStringMonthLabel(sessionActiveResidency.lastPaymentDt)}`}
                            </RegularText>
                        </View>
                    </Container>
                </Section>
            )}
        </SmartmoveScreenContainer>
    );
});

export default SmartMovePayBeforeMoveIn;
