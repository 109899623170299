import { createZegoGatewayPaymentMethodDo } from '../../businesslogic/integration/paylease/dataobjects/ZegoGatewayPaymentMethodDo';
import { ZegoGatewayPaymentMethodDo } from '../../businesslogic/integration/paylease/dataobjects/ZegoGatewayPaymentMethodDo';
import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { SubmitZegoPaymentResultV1 } from './SubmitZegoPaymentResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitZegoPaymentCommandV2
 */
export interface SubmitZegoPaymentCommandV2 extends AbstractResidentAuthenticatedCommand<SubmitZegoPaymentResultV1> {
    paymentMethod: ZegoGatewayPaymentMethodDo;
    incurFee: boolean;
    amount: number;
}

export function createSubmitZegoPaymentCommandV2(): SubmitZegoPaymentCommandV2 {
    return {
        paymentMethod: createZegoGatewayPaymentMethodDo(),
        incurFee: false,
        amount: 0,
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitZegoPaymentCommandV2',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
