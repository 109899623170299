import { SmarthubResidentV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentV1';
import { AbstractVersionedRemoteResult } from '../../../remotecmd/versioned/AbstractVersionedRemoteResult';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetEditNoticeToVacateResultV1
 */
export interface GetEditNoticeToVacateResultV1 extends AbstractVersionedRemoteResult {
    noticeMessage: string;
    todayDt: string;
    expectMoveOutDt: string;
    moveOutReason: string;
    contactPersonName: string;
    canCancel: boolean;
    canModify: boolean;
    applicants: Array<SmarthubResidentV1>;
}

export function createGetEditNoticeToVacateResultV1(): GetEditNoticeToVacateResultV1 {
    return {
        noticeMessage: '',
        todayDt: '01/01/1900',
        expectMoveOutDt: '01/01/1900',
        moveOutReason: '',
        contactPersonName: '',
        canCancel: false,
        canModify: false,
        applicants: [],
        authenticated: false,
        identifier: '',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
