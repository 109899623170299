import React from 'react';
import { useNavigation } from '@react-navigation/native';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { SmartmovePaymentInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmovePaymentInfoV1';
import { SmartmoveApplicationHeaderV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveApplicationHeaderV1';
import { StyleSheet } from 'react-native';
import { navigationRoutes } from 'utils/NavigationUtils';
import { getPaymentFormInitialValues, paymentFormValidationSchema, PaymentFormValues } from 'utils/PayLeaseZegoUtils';
import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import PaymentForm from 'components/payments/PaymentForm';

const SmartMovePaymentStart: React.FC = observer(() => {
    const navigation = useNavigation();
    const { userSessionStore, uiStore, transactionsStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    ///header
    const applicationHeader = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );

    const header: SmartmoveApplicationHeaderV1 | undefined = applicationHeader.query.data?.smartmoveApplicationHeaderV1;
    const paymentInfoV1: SmartmovePaymentInfoV1 | undefined =
        applicationHeader.query.data?.smartmoveApplicationHeaderV1?.paymentInfoV1;
    const isReservationPayment = header?.smartmoveStep === 'ReservationFeePayment';

    const _continueReview = (values: PaymentFormValues, formikHelpers: FormikHelpers<PaymentFormValues>) => {
        // console.log('payment form values', values);
        navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePaymentReview, {
            paymentFormValues: values,
        });
    };

    const initialValues: PaymentFormValues = React.useMemo(() => {
        return getPaymentFormInitialValues(
            transactionsStore.uniquePaymentReferenceId,
            sessionActiveResidency.payleaseAccount,
            sessionActiveResidency.firstName,
            sessionActiveResidency.lastName,
            paymentInfoV1?.balance || 0,
        );
    }, [
        sessionActiveResidency.payleaseAccount,
        sessionActiveResidency.firstName,
        sessionActiveResidency.lastName,
        paymentInfoV1?.balance,
    ]);

    if (applicationHeader.query.isLoading || !paymentInfoV1) return null;

    return (
        <Formik
            validateOnMount={false}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            onSubmit={_continueReview}
            validationSchema={paymentFormValidationSchema}>
            {formikHelpers => (
                <SmartmoveScreenContainer
                    contentContainerStyle={styles.contentContainerStyle}
                    showBack={true}
                    footerButtonProps={{
                        text: 'Continue',
                        onClick: () => {
                            formikHelpers.handleSubmit();
                        },
                        disabled: !formikHelpers.values.payByMethod,
                    }}>
                    <PaymentForm
                        title1={isReservationPayment ? 'Reserve your apartment now' : 'How would you like to pay?'}
                        title2={
                            isReservationPayment
                                ? `Your reservation fee is ${formatCentsAsDollars(paymentInfoV1?.balance)}`
                                : undefined
                        }
                        showPaymentAmountInput={false}
                        formikHelpers={formikHelpers}
                        applyPaymentRestrictions={paymentInfoV1?.applyPaymentRestrictions}
                        hideFee={isReservationPayment}
                    />
                </SmartmoveScreenContainer>
            )}
        </Formik>
    );
});

export default SmartMovePaymentStart;

const styles = StyleSheet.create({
    contentContainerStyle: { maxWidth: 700, width: '100%', alignItems: 'center', flexDirection: 'column' },
});
