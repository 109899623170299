import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { GetSmartPackagesForResidentResultV1 } from './GetSmartPackagesForResidentResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.smartpackage.GetSmartPackagesForResidentCommandV1
 */
export interface GetSmartPackagesForResidentCommandV1
    extends AbstractResidentAuthenticatedCommand<GetSmartPackagesForResidentResultV1> {
    pendingOnly: boolean;
    maxDaysOld: number;
}

export function createGetSmartPackagesForResidentCommandV1(): GetSmartPackagesForResidentCommandV1 {
    return {
        pendingOnly: false,
        maxDaysOld: 0,
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.smartpackage.GetSmartPackagesForResidentCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
