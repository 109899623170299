import * as React from 'react';
import { MutableRefObject } from 'react';
import {
    NativeSyntheticEvent,
    Platform,
    StyleProp,
    StyleSheet,
    TextInput,
    TextInputSubmitEditingEventData,
    View,
} from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { RegularText } from 'components/primitives/StyledText';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { InputStyles } from 'theme/style/InputStyles';
import { IconDefinition } from 'theme/IconDefinition';
import { useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { testProps } from 'components/ComponentTypes';

export type keyboardType = 'default' | 'number-pad' | 'decimal-pad' | 'numeric' | 'email-address' | 'phone-pad';

interface InputStringProps {
    testID?: string;
    accessibilityLabel?: string;
    inputStyle?: 'normal' | 'transparent';
    label?: string;
    placeholder?: string;
    initialValue?: string;
    maxLength?: number;
    error?: boolean;
    errorMessage?: string;
    keyboardType?: keyboardType;
    autoCapitalize?: 'sentences' | 'characters' | 'words' | 'none';
    autoFocus?: boolean;
    onChange?: (value: string) => void;
    onBlur?: (e: any) => void;
    onSubmitEditing?: (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
    multiline?: boolean;
    numberOfLines?: number;
    inputRef?: MutableRefObject<TextInput>;
    icon?: IconDefinition;
}

const defaultProps: InputStringProps = {
    inputStyle: 'normal',
    error: false,
    keyboardType: 'default',
    autoCapitalize: 'sentences',
    autoFocus: false,
};

const InputString: React.FC<InputStringProps> = (props: InputStringProps) => {
    const theme = useTheme();
    const { uiStore } = useStore();

    let style: StyleProp<any> = props.error ? [InputStyles.input, InputStyles.inputError] : [InputStyles.input];

    if (props.inputStyle === 'transparent') {
        style = props.error ? [InputStyles.inputTransparent, InputStyles.inputError] : [InputStyles.inputTransparent];
    }

    style = props.icon
        ? props.icon.alignment === 'right'
            ? [style, InputStyles.inputIconRight]
            : [style, InputStyles.inputIcon]
        : style;
    if (props.multiline && props.numberOfLines) {
        style = [style, { minHeight: props.numberOfLines * 20, textAlignVertical: 'top' }];
    }
    if (uiStore.useDarkTheme) {
        style = [style, { color: SmarthubTheme.colors.white }];
    }

    return (
        <View style={InputStyles.inputWrapper}>
            <TextInput
                defaultValue={props.initialValue}
                testID={props.testID}
                accessibilityLabel={props.accessibilityLabel}
                style={style}
                placeholder={props.placeholder}
                placeholderTextColor={theme.colors.lightgrey}
                numberOfLines={props.numberOfLines}
                multiline={props.multiline}
                maxLength={props.maxLength}
                keyboardType={props.keyboardType}
                autoCapitalize={props.autoCapitalize}
                autoFocus={props.autoFocus}
                onChangeText={props.onChange}
                onBlur={props.onBlur}
                onSubmitEditing={props.onSubmitEditing}
                ref={props.inputRef}
            />
            {props.icon && (
                <MaterialCommunityIcons
                    {...testProps('generic-string-icon')}
                    style={props.icon.alignment === 'right' ? styles.iconRight : styles.icon}
                    name={props.icon.iconID}
                    size={props.icon.size ? props.icon.size : SmarthubTheme.layout.ICONWIDTH}
                    color={props.icon.color}
                />
            )}
            {!!props.errorMessage && (
                <RegularText style={InputStyles.inputErrorMessage}>{props.errorMessage}</RegularText>
            )}
        </View>
    );
};

InputString.defaultProps = defaultProps;

export default InputString;

const styles = StyleSheet.create({
    icon: {
        position: 'absolute',
        top: Platform.OS === 'android' ? 16 : 10,
        left: 0,
    },
    iconRight: {
        position: 'absolute',
        top: Platform.OS === 'android' ? 16 : 10,
        right: 0,
    },
});
