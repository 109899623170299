import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SubmitNoticeToVacateChangeDateRequestResultV1 } from './SubmitNoticeToVacateChangeDateRequestResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitNoticeToVacateChangeDateRequestCommandV1
 */
export interface SubmitNoticeToVacateChangeDateRequestCommandV1
    extends AbstractResidencyAuthenticatedCommand<SubmitNoticeToVacateChangeDateRequestResultV1> {
    requestResidentId: number;
    expectMoveOutDate: string;
}

export function createSubmitNoticeToVacateChangeDateRequestCommandV1(): SubmitNoticeToVacateChangeDateRequestCommandV1 {
    return {
        requestResidentId: 0,
        expectMoveOutDate: '01/01/1900',
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitNoticeToVacateChangeDateRequestCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
