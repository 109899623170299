import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UIStore } from 'stores/domain/UIStore';
import {
    createGetSmartmoveUtilitiesAndRenterInsurancesCommandV1,
    GetSmartmoveUtilitiesAndRenterInsurancesCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveUtilitiesAndRenterInsurancesCommandV1';
import { GetSmartmoveUtilitiesAndRenterInsurancesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveUtilitiesAndRenterInsurancesResultV1';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { GetSmartmoveApplicationHeaderResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveApplicationHeaderResultV1';
import { SmartmoveStep } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveStep';
import { WorkflowStatus } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/contract/WorkflowStatus';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { isEmptyArray } from 'formik';
import { createDefaultSmartmoveRenterInsuranceV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveRenterInsuranceV1';

export const useUtilitiesAndInsurance = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    hasSessionActiveResidency: boolean,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetSmartmoveUtilitiesAndRenterInsurancesResultV1>,
): {
    utilsInsQuery: UseQueryResult<GetSmartmoveUtilitiesAndRenterInsurancesResultV1>;
    utilsInsQueryKey: Array<string>;
    headerQuery: UseQueryResult<GetSmartmoveApplicationHeaderResultV1>;
    headerQueryKey: Array<any>;
    routeUser: (
        smartmoveStep: SmartmoveStep,
        workflowStatus: WorkflowStatus,
        navigation: NavigationProp<ParamListBase>,
    ) => void;
} => {
    ///get header
    const { query: headerQuery, routeUser, queryKey: headerQueryKey } = useApplicationHeader(
        propertyCd,
        residencyId,
        residentId,
        hasSessionActiveResidency,
        uiStore,
    );

    //build key
    const utilsInsQueryKey: Array<string> = [QueryKeys.UtilitiesAndInsurances, propertyCd, residencyId + ''];

    //build command
    const getUtilsAndInsCommand = createGetSmartmoveUtilitiesAndRenterInsurancesCommandV1();
    getUtilsAndInsCommand.propertyCd = propertyCd;
    getUtilsAndInsCommand.residencyId = residencyId;

    //Setup query
    const utilsInsQuery = useCommandQuery<
        GetSmartmoveUtilitiesAndRenterInsurancesCommandV1,
        GetSmartmoveUtilitiesAndRenterInsurancesResultV1
    >(
        getUtilsAndInsCommand,
        uiStore,
        utilsInsQueryKey,
        queryOptions || {
            enabled: hasSessionActiveResidency,
        },
    );

    //start with at least one policy
    if (!utilsInsQuery.isLoading && utilsInsQuery.data && isEmptyArray(utilsInsQuery.data.insurances)) {
        const newIns = createDefaultSmartmoveRenterInsuranceV1(residencyId);
        utilsInsQuery.data.insurances.push(newIns);
    }

    return { utilsInsQuery, utilsInsQueryKey, headerQuery, headerQueryKey, routeUser };
};
