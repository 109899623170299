import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { RegularText } from 'components/primitives/StyledText';
import { ZegoGatewayPaymentMethodDo } from 'models/remotecmds/com/ocs/nirvana/businesslogic/integration/paylease/dataobjects/ZegoGatewayPaymentMethodDo';
import { globalStyles } from 'theme/style/GlobalStyles';
import { SmarthubTheme } from 'theme/SmarthubTheme';

type props = {
    paymentMethod: ZegoGatewayPaymentMethodDo;
};

const PaymentMethodDescription: React.FC<props> = ({ paymentMethod }: props) => {
    const _renderCardIcon = (bankOrCardName: string) => {
        switch (bankOrCardName) {
            case 'Visa':
                return (
                    <Image style={styles.paymentMethodCard} source={require('../../../assets/images/card-visa.png')} />
                );
            case 'MasterCard':
                return (
                    <Image
                        style={styles.paymentMethodCard}
                        source={require('../../../assets/images/card-mastercard.png')}
                    />
                );
            case 'Discover':
                return (
                    <Image
                        style={styles.paymentMethodCard}
                        source={require('../../../assets/images/card-discover.png')}
                    />
                );
            case 'Amex':
                return (
                    <Image style={styles.paymentMethodCard} source={require('../../../assets/images/card-amex.png')} />
                );
            case 'Debit':
                return <RegularText style={styles.paymentMethodText}>Debit Card</RegularText>;
            case 'Bank':
                return <RegularText style={styles.paymentMethodText}>Bank Account</RegularText>;
        }
    };

    return (
        <View style={globalStyles.flexRow}>
            {_renderCardIcon(paymentMethod.bankOrCardName)}
            <RegularText>&bull;&bull;&bull;&bull; {paymentMethod.lastFour}</RegularText>
        </View>
    );
};

export default PaymentMethodDescription;

const styles = StyleSheet.create({
    paymentMethodCard: {
        width: 32,
        height: 21,
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
    paymentMethodText: {
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
});
