import React, { useEffect } from 'react';
import { Image, ImageBackground, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import EmailPasswordLoginForm from '../../components/auth/EmailPasswordLoginForm';
import PhoneLoginForm from '../../components/auth/PhoneLoginForm';
import PrivacyTermsLinks from '../../components/auth/PrivacyTermsLinks';
import { useAuthentication } from 'contexts/AuthContextProvider';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import GoogleAuthSessionLoginButton from 'components/auth/GoogleAuthSessionLoginButton';
import Button from 'components/primitives/Button';
import { globalStyles } from 'theme/style/GlobalStyles';
import AppleLoginButton from 'components/auth/AppleLoginButton';
import { IS_PRODUCTION } from 'utils/BootstrapHelpers';
import Constants from 'expo-constants';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import { testProps } from 'components/ComponentTypes';
import { useRoute } from '@react-navigation/native';
import { getRouteParam } from 'utils/routeUtils';
import SmarthubVersionInfo from 'components/cards/SmarthubVersionInfo';

const LoginScreen: React.FC = observer(() => {
    const { loginOption, setLoginOption, loginEmail, setLoginEmail } = useAuthentication();
    const { uiStore, userSessionStore } = useStore();
    const route = useRoute();

    useEffect(() => {
        if (route.params) {
            const params = getRouteParam(route, 'params');

            if (params) {
                const decodedParams = Buffer.from(params, 'base64').toString('ascii');
                const paramMap = new Map();
                decodedParams.split('&').forEach(value => {
                    const keyValue = value.split('=');
                    paramMap.set(keyValue[0], keyValue[1] || '');
                });

                if (paramMap.get('adminYn') === '1') {
                    //have to sign out so Admin can log in
                    userSessionStore.deauthorize();
                    userSessionStore.saveResidencyInfo(
                        paramMap.get('residencyKey'),
                        paramMap.get('propertyCd'),
                        Number(paramMap.get('residencyId')),
                    );
                    setLoginOption('email');
                    if (paramMap.get('adminEmail')) {
                        setLoginEmail(paramMap.get('adminEmail'));
                    }
                }
            }
        }
    }, []);

    if (!uiStore.authenticationReady) return null;

    const okToShowAppleLogin = !IS_PRODUCTION || (Constants.platform?.ios?.buildNumber || '0') >= '1.0.6';
    return (
        <ImageBackground
            source={
                Platform.OS === 'web'
                    ? require('../../../assets/images/login-backdrop-web.png')
                    : require('../../../assets/images/login-backdrop-mobile.png')
            }
            style={globalStyles.fullheightwidthpct}>
            <SafeAreaView style={globalStyles.container}>
                <KeyboardAwareScrollView enableResetScrollToCoords={false}>
                    <View style={[globalStyles.container, styles.innercontainer]}>
                        <View style={[globalStyles.sectionSpaceAbove, styles.glassesContainer]}>
                            <Image
                                source={require('../../../assets/images/smarthub-glasses.png')}
                                style={styles.glasses}
                                {...testProps('glassesLogo')}
                            />
                        </View>
                        <View style={[styles.loginFormContainer]}>
                            <View style={styles.buttons}>
                                {okToShowAppleLogin && <AppleLoginButton />}

                                {(Platform.OS !== 'ios' || okToShowAppleLogin) && (
                                    <View
                                        style={[
                                            loginOption === 'google'
                                                ? globalStyles.loginOptionButtonSelected
                                                : globalStyles.loginOptionButton,
                                            globalStyles.doubleSpaceAbove,
                                        ]}>
                                        <GoogleAuthSessionLoginButton />
                                    </View>
                                )}
                                <View
                                    style={[
                                        loginOption === 'phone'
                                            ? globalStyles.loginOptionButtonSelected
                                            : globalStyles.loginOptionButton,
                                        globalStyles.doubleSpaceAbove,
                                    ]}>
                                    <Button
                                        {...testProps('phone-sign-in')}
                                        style={styles.loginPhoneButton}
                                        onClick={() => setLoginOption('phone')}
                                        icon={{
                                            iconID: 'phone',
                                            color: SmarthubTheme.colors.white,
                                        }}>
                                        Sign in with Phone
                                    </Button>
                                </View>
                                <View
                                    style={[
                                        loginOption === 'email'
                                            ? globalStyles.loginOptionButtonSelected
                                            : globalStyles.loginOptionButton,
                                        globalStyles.doubleSpaceAbove,
                                    ]}>
                                    <Button
                                        style={styles.loginEmailButton}
                                        onClick={() => setLoginOption('email')}
                                        icon={{
                                            iconID: 'email',
                                            color: SmarthubTheme.colors.white,
                                        }}>
                                        Sign in with Email
                                    </Button>
                                </View>
                            </View>
                            <View style={styles.form}>
                                <View style={globalStyles.sectionSpaceAbove}>
                                    {loginOption === 'email' && (
                                        <EmailPasswordLoginForm
                                            initialEmail={loginEmail}
                                            setLoginOption={setLoginOption}
                                        />
                                    )}
                                    {loginOption === 'phone' && <PhoneLoginForm />}
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={[globalStyles.sectionSpaceAbove, styles.footerLogoLinksContainer]}>
                        <Image
                            source={require('../../../assets/images/venterra-logo-navigation.png')}
                            style={[styles.venterraLogo, globalStyles.doubleSpaceBelow]}
                        />
                        <PrivacyTermsLinks />
                        <SmarthubVersionInfo
                            style={[globalStyles.singleSpaceAbove, { color: SmarthubTheme.colors.white }]}
                        />
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        </ImageBackground>
    );
});
export default LoginScreen;

const styles = StyleSheet.create({
    innercontainer: {
        alignItems: 'center',
    },
    glassesContainer: {
        width: 281,
        height: 133,
        paddingTop: 32,
    },
    glasses: {
        flex: 1,
        width: 281,
        height: 133,
    },
    loginFormContainer: {
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerLogoLinksContainer: {
        bottom: 24,
        width: '100%',
        alignItems: 'center',
    },
    venterraLogo: {
        height: SmarthubTheme.layout.GRIDINCREMENT * 4,
        width: SmarthubTheme.layout.GRIDINCREMENT * 29,
    },
    loginPhoneButton: {
        backgroundColor: SmarthubTheme.colors.green,
        borderColor: SmarthubTheme.colors.green,
    },
    loginEmailButton: {
        backgroundColor: SmarthubTheme.colors.lightblue,
        borderColor: SmarthubTheme.colors.lightblue,
    },
    buttons: {
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        justifyContent: 'flex-start',
    },
    form: {},
});
