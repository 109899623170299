import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetCommunityNoticesResultV1 } from './GetCommunityNoticesResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.notifications.GetCommunityNoticesCommandV1
 */
export interface GetCommunityNoticesCommandV1
    extends AbstractResidencyAuthenticatedCommand<GetCommunityNoticesResultV1> {
    id: number;
    startIndex: number;
    numRowsToRetrieve: number;
    residentId: number;
}

export function createGetCommunityNoticesCommandV1(): GetCommunityNoticesCommandV1 {
    return {
        id: 0,
        startIndex: 0,
        numRowsToRetrieve: 0,
        residentId: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.notifications.GetCommunityNoticesCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
