import React from 'react';
import { Platform, View } from 'react-native';
import { FormLabelText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import { useStore } from 'contexts/StoreContextProvider';
import Button from 'components/primitives/Button';
import { observer } from 'mobx-react-lite';
import InputString from 'components/input/InputString';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Section from 'components/primitives/Section';
import useConfirmLeave from 'utils/useConfirmLeave';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { getRouteParams } from 'utils/routeUtils';
import { useRoute } from '@react-navigation/native';
import { generateCancelRequestCMD, useCancelRequest } from 'screens/home/requests/RequestsHooks';

type CancelRequestProps = {
    navigation: StackNavigationProp<any>;
};

type FormData = {
    desc: string;
};

const schema = yup.object().shape({
    desc: yup.string().max(460, 'Please limit your message to 460 characters'),
});

const CancelRequest: React.FC<CancelRequestProps> = observer(({ navigation }: CancelRequestProps) => {
    const { uiStore, userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;

    const route = useRoute();
    const { requestId, requestType } = getRouteParams(route) || null;

    const onSubmitMessage = async (data: FormData) => {
        if (requestId) {
            uiStore.showActivityLoader();
            cancelRequestMutation.mutate(
                generateCancelRequestCMD(sessionActiveResidency.propertyCd, requestId, requestType, data.desc),
            );
        }
    };

    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            desc: '',
        },
        validationSchema: schema,
        onSubmit: values => {
            if (!uiStore.activityLoaderVisible) {
                formik.resetForm({ values: formik.values });
                onSubmitMessage(values);
            }
        },
    });

    const cancelRequestMutation = useCancelRequest(navigation, formik, requestType);

    useConfirmLeave(navigation, formik);

    return (
        <KeyboardAwareScrollView
            keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
            enableResetScrollToCoords={false}
            contentContainerStyle={globalStyles.keyboardAwareScrollView}
        >
            <View style={globalStyles.container}>
                <FormLabelText style={[globalStyles.doubleSpaceBelow]}>{'Reason for cancellation:'}</FormLabelText>

                <InputString
                    autoFocus={Platform.OS === 'web'}
                    onChange={formik.handleChange('desc')}
                    onBlur={formik.handleBlur('desc')}
                    initialValue={formik.values.desc}
                    multiline={true}
                    maxLength={460} //the server adds 40 characters to beginning!
                    numberOfLines={6}
                    error={!!formik.errors.desc}
                    errorMessage={formik.errors.desc}
                    {...testProps('message-input')}
                />

                <View style={globalStyles.bottomButton}>
                    <Section>
                        <Button
                            inactive={uiStore.activityLoaderVisible || sessionActiveResidency.adminYn}
                            onClick={formik.handleSubmit}
                            {...testProps('cancel-request-button')}
                        >{`Cancel ${requestType}`}</Button>
                    </Section>
                </View>
            </View>
        </KeyboardAwareScrollView>
    );
});

export default CancelRequest;
