import { createSmarthubLoginDo, SmarthubLoginDo } from '../../dataobjects/SmarthubLoginDo';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.residency.SmarthubLoginV1
 */
export interface SmarthubLoginV1 {
    smarthubLoginDO: SmarthubLoginDo;
    experienceId: string;
    deviceTokenId: string | null;
}

export function createSmarthubLoginV1(): SmarthubLoginV1 {
    return { smarthubLoginDO: createSmarthubLoginDo(), experienceId: '', deviceTokenId: '' };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
