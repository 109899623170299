import { StackNavigationOptions } from '@react-navigation/stack';

import { SmarthubTheme } from 'theme/SmarthubTheme';
import * as React from 'react';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { testProps } from 'components/ComponentTypes';

export const MODAL_HEADER_HEIGHT = SmarthubTheme.layout.GRIDINCREMENT * 10;

export const getModalScreenOptions = (
    navigation: { canGoBack: () => any; goBack: () => void; navigate: (arg0: string) => void },
    theme: ReactNativePaper.Theme,
    showBack = true,
    showClose = true,
    closeRoute = 'LoggedIn',
): StackNavigationOptions => {
    return {
        headerStyle: {
            height: MODAL_HEADER_HEIGHT,
            borderBottomWidth: 0,
            shadowColor: 'transparent',
            shadowRadius: 0,
            shadowOpacity: 0,
            elevation: 0,
        },
        headerShown: showBack || showClose,
        headerTintColor: theme.colors.text,
        headerLeft: () => {
            if (navigation.canGoBack() && showBack) {
                return (
                    <TouchableOpacity style={styles.arrow} onPress={() => navigation.goBack()}>
                        <MaterialCommunityIcons
                            {...testProps('back-arrow')}
                            name='keyboard-backspace'
                            size={SmarthubTheme.layout.GRIDINCREMENT * 4}
                            color={theme.colors.icon}
                        />
                    </TouchableOpacity>
                );
            }
        },
        headerRight: () => {
            if (showClose) {
                return (
                    <TouchableOpacity style={styles.closeButton} onPress={() => navigation.navigate(closeRoute)}>
                        <MaterialIcons
                            {...testProps('close')}
                            name='close'
                            size={SmarthubTheme.layout.GRIDINCREMENT * 4}
                            color={theme.dark ? SmarthubTheme.colors.white : SmarthubTheme.colors.black}
                        />
                    </TouchableOpacity>
                );
            }
        },
        headerBackTitle: '',
        headerTitle: '',
        animationEnabled: false,
        cardStyle: {
            backgroundColor: theme.colors.background,
        },
    };
};

const styles = StyleSheet.create({
    arrow: {
        flex: 1,
        paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
        justifyContent: 'center',
    },
    closeButton: {
        flex: 1,
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
        justifyContent: 'center',
    },
});
