import React from 'react';
import { StyleSheet, View } from 'react-native';
import { globalStyles } from 'theme/style/GlobalStyles';
import { MediumBoldText, RegularText } from 'components/primitives/StyledText';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStore } from 'contexts/StoreContextProvider';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { SmartmoveApplicationHeaderV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveApplicationHeaderV1';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import OrangeCheckmark from 'components/animations/OrangeCheckmark';
import { observer } from 'mobx-react-lite';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import Hyperlink from 'components/primitives/Hyperlink';
import { getRouteParam } from 'utils/routeUtils';

type SmartMovePaymentConfirmationProps = {
    titleText?: string;
    bodyText?: string;
    buttonText?: string;
    icon?: 'check' | 'alert-circle';
    isFail?: boolean;
};

const defaultProps: SmartMovePaymentConfirmationProps = {
    buttonText: 'Continue',
};

const SmartMovePaymentConfirmation: React.FC<SmartMovePaymentConfirmationProps> = observer(
    (props: SmartMovePaymentConfirmationProps) => {
        const navigation = useNavigation();
        const route = useRoute();
        const paymentFailMessage = getRouteParam(route, 'paymentFailMessage');
        const { userSessionStore, uiStore } = useStore();
        const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

        ///header
        const { query, routeUser } = useApplicationHeader(
            sessionActiveResidency.propertyCd,
            sessionActiveResidency.residencyId,
            sessionActiveResidency.residentId,
            hasSessionActiveResidency,
            uiStore,
        );
        const { data, isFetching, isLoading, isSuccess } = query;

        const { icon, buttonText, titleText, bodyText, isFail } = props;

        const header: SmartmoveApplicationHeaderV1 | undefined = data?.smartmoveApplicationHeaderV1;

        const smartmoveStep = data?.smartmoveApplicationHeaderV1?.smartmoveStep;
        const workflowStatus = data?.smartmoveApplicationHeaderV1?.workflowStatus;

        const _navigate = () => {
            if (header && smartmoveStep && workflowStatus) {
                //TODO adjust this?? handle both fail and success cases...
                routeUser(smartmoveStep, workflowStatus, navigation);
            }
        };

        const _renderIcon = (): React.ReactNode => {
            if (icon && icon === 'check') {
                return (
                    <View style={[globalStyles.smartMoveIcon, globalStyles.sectionSpaceAround]}>
                        <OrangeCheckmark lottieViewStyle={styles.animation} />
                    </View>
                );
            } else if (icon) {
                return (
                    <View style={[globalStyles.smartMoveIcon, globalStyles.sectionSpaceAround]}>
                        <MaterialCommunityIcons name={icon} size={48} color={SmarthubTheme.colors.orange} />
                    </View>
                );
            }
        };

        return (
            <SmartmoveScreenContainer
                /*no progress indicator*/
                footerButtonProps={
                    (buttonText && {
                        text: buttonText,
                        onClick: _navigate,
                    }) ||
                    undefined
                }
            >
                {icon && _renderIcon()}
                {titleText && (
                    <MediumBoldText style={[globalStyles.smartMoveTitleText, globalStyles.centeredText]}>
                        {titleText}
                    </MediumBoldText>
                )}
                {bodyText && (!isFail || !paymentFailMessage) && (
                    <RegularText style={globalStyles.centeredText}>{bodyText}</RegularText>
                )}
                {isFail && !!paymentFailMessage && (
                    <RegularText style={globalStyles.centeredText}>{paymentFailMessage}</RegularText>
                )}
                {isFail && (
                    <Hyperlink
                        style={[globalStyles.centeredText, styles.needHelpLink]}
                        onClick={() => {
                            WebBrowser.openBrowserAsync('https://gozego.force.com/residents/s/');
                        }}
                    >
                        Need help? Get Support here
                    </Hyperlink>
                )}
            </SmartmoveScreenContainer>
        );
    },
);

SmartMovePaymentConfirmation.defaultProps = defaultProps;

export default SmartMovePaymentConfirmation;

const styles = StyleSheet.create({
    animation: {
        width: 200,
        height: 200,
    },
    needHelpLink: {
        marginTop: SmarthubTheme.layout.SECTIONMARGIN * 2,
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
});
