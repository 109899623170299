import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UIStore } from 'stores/domain/UIStore';
import { UseQueryOptions } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import { GetSmartmoveContractSigningInfoResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/contract/GetSmartmoveContractSigningInfoResultV1';
import {
    createGetSmartmoveContractSigningInfoCommandV1,
    GetSmartmoveContractSigningInfoCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/contract/GetSmartmoveContractSigningInfoCommandV1';

export const useSmartmoveContractSigningInfoQuery = (
    propertyCd: string,
    residencyId: number,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetSmartmoveContractSigningInfoResultV1>,
) => {
    //build key
    const queryKey = [QueryKeys.SmartmoveContractSigning, propertyCd, residencyId];

    //build command
    const cmd = createGetSmartmoveContractSigningInfoCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;

    //Setup query
    const queryResult = useCommandQuery<
        GetSmartmoveContractSigningInfoCommandV1,
        GetSmartmoveContractSigningInfoResultV1
    >(cmd, uiStore, queryKey, queryOptions);
    return { queryKey, queryResult };
};
