import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { getItem } from 'utils/storage';

export const useGetFromLocalStorageQuery = <T>(
    storageKey: string,
    queryKey: string[],
    dataParser?: (value: string | null) => T,
    queryOptions?: UseQueryOptions<unknown, unknown, T>,
): UseQueryResult<T> => {
    return useQuery<unknown, unknown, T>(
        queryKey,
        () =>
            getItem(storageKey).then(value => {
                if (dataParser) {
                    return dataParser(value);
                } else {
                    return value;
                }
            }),
        queryOptions,
    );
};
