import React, { useRef, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import {
    getAuth,
    PhoneAuthProvider,
    RecaptchaVerifier,
    ApplicationVerifier,
    signInWithCredential,
} from 'firebase/auth';
import { useAuthentication } from 'contexts/AuthContextProvider';
import { FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha';
import { useStore } from 'contexts/StoreContextProvider';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { SmallerText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Button from 'components/primitives/Button';
import ApiConfig from 'components/auth/constants/ApiConfig';
import InputPhone from 'components/input/InputPhone';
import FlexContainer from 'components/primitives/FlexContainer';
import { IS_PRODUCTION } from 'utils/BootstrapHelpers';
import InputSwitch from 'components/input/InputSwitch';
import InputNumber from 'components/input/InputNumber';
import { globalStyles } from 'theme/style/GlobalStyles';
import Hyperlink from 'components/primitives/Hyperlink';
import { testProps } from 'components/ComponentTypes';

/*
MAIN: https://docs.expo.io/versions/latest/sdk/firebase-recaptcha/
https://expo.canny.io/feature-requests/p/phone-number-auth-with-firebase
https://firebase.google.com/docs/auth/web/phone-auth
https://firebase.google.com/docs/auth/android/phone-auth
https://firebase.google.com/docs/reference/js/firebase.auth.RecaptchaVerifier
https://firebase.google.com/docs/reference/js/firebase.auth.PhoneAuthProvider
*/
const PhoneLoginForm: React.FC = () => {
    const auth = getAuth();
    const { setLoginOption } = useAuthentication();
    const recaptchaVerifierRef = useRef<FirebaseRecaptchaVerifierModal | null>(null);

    const { uiStore } = useStore();

    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [verificationId, setVerificationId] = useState<string>();
    const [verificationCode, setVerificationCode] = useState<string>();
    const [codeSent, setCodeSent] = useState(false);
    const [allowBypass, setAllowBypass] = useState(false);

    const sendVerification = async () => {
        uiStore.showActivityLoader();

        logInfo(INFO_LOGGER, 'phone number is ', phoneNumber);
        if (!phoneNumber || phoneNumber.length !== 10) {
            uiStore.showAlert({
                title: 'Invalid Phone Number ',
                message: 'Phone number must be 10 digits',
            });
            uiStore.hideActivityLoader();
            return;
        }

        const phoneProvider = new PhoneAuthProvider(auth);
        const prefixedNumber: string = phoneNumber ? '+1' + phoneNumber.replace(/\D/g, '') : '';

        if (Platform.OS === 'web') {
            auth.useDeviceLanguage();
            auth.settings.appVerificationDisabledForTesting = allowBypass;

            uiStore.hideActivityLoader();
            const applicationVerifier = new RecaptchaVerifier(
                'recaptcha-container',
                {
                    size: 'normal',
                    callback: (verifier: ApplicationVerifier) => {
                        logInfo(INFO_LOGGER, 'verifier:', verifier);
                    },
                },
                auth,
            );

            try {
                phoneProvider.verifyPhoneNumber(prefixedNumber, applicationVerifier).then(verificationId => {
                    setVerificationId(verificationId);
                    uiStore.showAlert({
                        message: 'Verification code has been sent to your phone.',
                    });
                    logInfo(INFO_LOGGER, 'Verification code sent. Verification id :', verificationId);
                    setCodeSent(true);
                });
            } catch (err) {
                logInfo(INFO_LOGGER, 'Error sending verification code', JSON.stringify(err));
            } finally {
                uiStore.hideActivityLoader();
            }
        } else {
            logInfo(INFO_LOGGER, 'recaptchaVerifier ', recaptchaVerifierRef.current);

            // The FirebaseRecaptchaVerifierModal ref implements the
            // FirebaseAuthApplicationVerifier interface and can be
            // passed directly to `verifyPhoneNumber`.
            try {
                if (recaptchaVerifierRef.current) {
                    logInfo(INFO_LOGGER, 'Sending verification code to ' + prefixedNumber);
                    const verificationId = await phoneProvider.verifyPhoneNumber(
                        prefixedNumber,
                        recaptchaVerifierRef.current,
                    );

                    uiStore.showAlert({
                        message: 'Verification code has been sent to your phone.',
                    });

                    setVerificationId(verificationId);

                    logInfo(INFO_LOGGER, 'Verification code sent. Verification id :', verificationId);

                    setCodeSent(true);

                    uiStore.hideActivityLoader();
                } else {
                    uiStore.showAlert({
                        message: 'Recaptcha Error. Please reload the routeName.',
                    });

                    uiStore.hideActivityLoader();
                }
            } catch (err: any) {
                logInfo(INFO_LOGGER, 'Error sending verification code', JSON.stringify(err));
                uiStore.showAlert({
                    title: 'Error Sending Verification',
                    message: err.message,
                });

                uiStore.hideActivityLoader();
            }
        }
    };

    const resetVerification = async () => {
        setLoginOption('phone');
        setPhoneNumber('');
        setVerificationId('');
        setVerificationCode('');
        setCodeSent(false);
    };

    const confirmVerificationCode = async () => {
        uiStore.showActivityLoader();

        try {
            const credential = PhoneAuthProvider.credential(verificationId || '', verificationCode || '');

            logInfo(INFO_LOGGER, 'phone credential: ', JSON.stringify(credential));

            // Can't reset verification after sign in, page is already unmounted so updating states causes a memory leak
            await resetVerification();

            await signInWithCredential(auth, credential);
        } catch (err: any) {
            const badVerificationCodeMessage =
                "The code you've entered is incorrect. Please make sure you've entered it correctly, or resend a new verification code and try again.";
            uiStore.showAlert({
                message: err.code === 'auth/invalid-verification-code' ? badVerificationCodeMessage : err.message,
            });
        } finally {
            uiStore.hideActivityLoader();
        }
    };

    return (
        <View style={styles.loginForm}>
            {Platform.OS !== 'web' && (
                <FirebaseRecaptchaVerifierModal
                    title='SMARTHUB'
                    cancelLabel={'Cancel'}
                    firebaseConfig={ApiConfig.FirebaseConfig}
                    ref={recaptchaVerifierRef}
                    attemptInvisibleVerification={true}
                    appVerificationDisabledForTesting={allowBypass}
                />
            )}

            {!codeSent && (
                <View style={[globalStyles.hundowidth, globalStyles.doubleSpaceBelow]}>
                    <InputPhone
                        inputStyle={'transparent'}
                        placeholder='Phone (eg. 9999999999)'
                        {...testProps('phone-input')}
                        initialValue={phoneNumber}
                        autoFocus={Platform.OS === 'web'}
                        onChange={phoneNumber => setPhoneNumber(phoneNumber)}
                        icon={{
                            iconID: 'check',
                            alignment: 'right',
                            color:
                                phoneNumber && phoneNumber.length >= 10
                                    ? SmarthubTheme.colors.orange
                                    : SmarthubTheme.colors.transparent,
                        }}
                    />
                    <SmallerText style={[styles.legalText, globalStyles.centeredText]}>
                        By tapping Verify, an SMS may be sent. Message &amp; data rates may apply.
                    </SmallerText>
                </View>
            )}

            {codeSent && (
                <FlexContainer style={styles.fieldContainer}>
                    <InputNumber
                        inputStyle={'transparent'}
                        {...testProps('verification-code-input')}
                        placeholder={'Verification code (eg. 123456)'}
                        onChange={setVerificationCode}
                    />
                </FlexContainer>
            )}

            {!codeSent && (
                <>
                    <View style={[globalStyles.singleSpaceAbove, globalStyles.loginOptionButton]}>
                        <Button
                            style={globalStyles.hundowidth}
                            {...testProps('send-verification-button')}
                            onClick={sendVerification}
                            inactive={!phoneNumber || phoneNumber.length < 10 || uiStore.activityLoaderVisible}>
                            Send Verification Code
                        </Button>
                    </View>
                    {!IS_PRODUCTION && (
                        <View style={[styles.bypasscont, globalStyles.singleSpaceAbove]}>
                            <InputSwitch
                                initialStatus={allowBypass}
                                onChange={() => setAllowBypass(!allowBypass)}
                                label={'Bypass Verification'}
                                {...testProps('bypass-verification-switch')}
                                labelColor={SmarthubTheme.colors.white}
                            />
                        </View>
                    )}
                    {Platform.OS === 'web' && (
                        <View
                            style={[styles.recaptcha, globalStyles.sectionSpaceAround]}
                            nativeID={'recaptcha-container'}
                        />
                    )}
                </>
            )}
            {codeSent && (
                <>
                    <View style={[globalStyles.singleSpaceAbove, globalStyles.loginOptionButton]}>
                        <Button
                            style={globalStyles.hundowidth}
                            {...testProps('sign-in-button')}
                            onClick={confirmVerificationCode}
                            inactive={!verificationId || !verificationCode}>
                            Sign in to SMARTHUB
                        </Button>
                    </View>
                    <View style={[globalStyles.sectionSpaceAbove]}>
                        <Hyperlink
                            {...testProps('resend-verification-button')}
                            style={styles.resendLink}
                            onClick={resetVerification}>
                            Didn&lsquo;t receive a code? Resend code now
                        </Hyperlink>
                    </View>
                </>
            )}
        </View>
    );
};

export default PhoneLoginForm;

const styles = StyleSheet.create({
    loginForm: { width: '100%', alignSelf: 'center', alignItems: 'center' },
    fieldContainer: {
        minWidth: 300,
        maxWidth: 300,
    },
    legalText: {
        color: SmarthubTheme.colors.white,
        alignSelf: 'center',
        flexWrap: 'wrap',
        minWidth: 300,
        maxWidth: 300,
    },
    recaptcha: {
        alignItems: 'center',
        alignSelf: 'center',
        height: 120,
        width: 300,
        zIndex: 999,
    },
    bypasscont: { alignSelf: 'center' },
    resendLink: {
        color: SmarthubTheme.colors.white,
        textAlign: 'center',
    },
});
