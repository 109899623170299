import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Home from 'screens/home/Home';
import ComingSoon from 'screens/ComingSoon';
import { observer } from 'mobx-react-lite';

import MainStackSurface from './MainStackSurface';
import { getMainStackScreenOptions, NavigatorProps } from './MainStackScreenOptions';
import PaymentPOC from 'screens/home/transactions/PaymentPOC';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import Requests from 'screens/home/requests/Requests';
import { useTheme } from 'react-native-paper';
import RequestDetail from 'screens/home/requests/RequestDetail';
import Transactions from 'screens/home/transactions/Transactions';
import StatementDetail from 'screens/home/transactions/StatementDetail';
import RequestMessage from 'screens/home/requests/RequestMessage';
import ConfirmScreen from 'screens/home/requests/ConfirmScreen';
import MakeAPayment from 'screens/home/transactions/MakeAPayment';
import CreateMaintenanceRequest from 'screens/home/requests/CreateMaintenanceRequest';

import PreviousStatements from 'screens/home/transactions/PreviousStatements';
import PaymentScreen from 'screens/home/transactions/PaymentScreen';
import CreateTicket from 'screens/home/requests/CreateTicket';
import ContractAndDocuments from 'screens/home/ContractAndDocuments';
import SmartPackage from 'screens/home/smartpackage/SmartPackage';
import SmartPackageHistory from 'screens/home/smartpackage/SmartPackageHistory';
import CancelRequest from 'screens/home/requests/CancelRequest';
import ReopenRequest from 'screens/home/requests/ReopenRequest';
import PaymentConfirmation from 'screens/home/transactions/PaymentConfirmation';
import Profile from 'screens/more/personal/Profile';
import PaymentDetails from 'screens/home/transactions/PaymentDetails';
import AutoPay from 'screens/home/transactions/AutoPay';
import LeaseSelector from 'screens/home/LeaseSelector';
import CreateMaintenanceRequestCovidQuestion from 'screens/home/requests/CreateMaintenanceRequestCovidQuestion';
import NoticeToVacateRequest from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacateRequest';
import SodaRecipient from 'screens/home/manageMyLease/noticeToVacate/SodaRecipient';
import AddressPicker from 'screens/home/manageMyLease/noticeToVacate/AddressPicker';
import NoticeToVacatePreferContact from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacatePreferContact';
import NoticeToVacateEdit from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacateEdit';
import NoticeToVacateCancel from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacateCancel';
import NoticeToVacateConfirmation from 'screens/home/manageMyLease/noticeToVacate/NoticeToVacateConfirmation';
import RenewalSigningScreen from 'screens/home/manageMyLease/renewal/RenewalSigningScreen';
import { Platform } from 'react-native';
import { navigationRoutes } from 'utils/NavigationUtils';
import ZegoPaymentScreen from 'screens/home/transactions/ZegoPaymentScreen';
import ZegoPaymentReview from 'screens/home/transactions/ZegoPaymentReview';
import ManageMyLease from 'screens/home/manageMyLease/ManageMyLease';
import RenewMyLease from 'screens/home/manageMyLease/renewal/RenewMyLease';

const HomeStack = createStackNavigator();

const HomeStackNavigator: React.FC<NavigatorProps> = observer(() => {
    const { useDrawerNavigation } = useResponsiveContext();
    const theme = useTheme();

    return (
        <MainStackSurface>
            <HomeStack.Navigator
                initialRouteName={navigationRoutes.homeRoutes.home}
                headerMode={'screen'}
                screenOptions={getMainStackScreenOptions(theme, useDrawerNavigation)}>
                {/*Misc*/}
                <HomeStack.Screen name={navigationRoutes.homeRoutes.home} component={Home} />
                <HomeStack.Screen name={navigationRoutes.homeRoutes.comingSoon} component={ComingSoon} />

                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.leaseSelector}
                    component={LeaseSelector}
                    options={{
                        headerShown: false,
                    }}
                />

                {/*Statements and Payments*/}
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.transactions}
                    component={Transactions}
                    initialParams={{ title: 'Statements and Payments' }}
                />
                <HomeStack.Screen name={navigationRoutes.homeRoutes.statementDetail} component={StatementDetail} />
                <HomeStack.Screen name={navigationRoutes.homeRoutes.paymentDetails} component={PaymentDetails} />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.paymentConfirmation}
                    component={PaymentConfirmation}
                    options={{
                        headerShown: false,
                    }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.makeAPayment}
                    component={MakeAPayment}
                    options={getMainStackScreenOptions(theme, useDrawerNavigation, false, false)}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.autoPay}
                    component={AutoPay}
                    options={getMainStackScreenOptions(theme, useDrawerNavigation, false, true)}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.paymentScreen}
                    component={PaymentScreen}
                    initialParams={{ title: 'Make A Payment' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.zegoPaymentScreen}
                    component={ZegoPaymentScreen}
                    options={getMainStackScreenOptions(theme, useDrawerNavigation, false, true, false, true)}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.zegoPaymentReview}
                    component={ZegoPaymentReview}
                    options={getMainStackScreenOptions(theme, useDrawerNavigation, false, true, false, true)}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.previousStatements}
                    component={PreviousStatements}
                    initialParams={{ title: 'Make A Payment' }}
                />
                <HomeStack.Screen name={navigationRoutes.homeRoutes.paymentPOC} component={PaymentPOC} />
                <HomeStack.Screen name={navigationRoutes.homeRoutes.addDebitCreditCard} component={ComingSoon} />

                {/*My Requests*/}
                <HomeStack.Screen name={navigationRoutes.homeRoutes.myRequests} component={Requests} />
                <HomeStack.Screen name={navigationRoutes.homeRoutes.requestDetail} component={RequestDetail} />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.createARequest}
                    component={CreateMaintenanceRequestCovidQuestion}
                    options={{ headerShown: false }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.createARequest2}
                    component={CreateMaintenanceRequest}
                    options={{ headerShown: false }}
                />
                <HomeStack.Screen name={navigationRoutes.homeRoutes.profile} component={Profile} />

                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.createATicket}
                    component={CreateTicket}
                    options={{ headerShown: false }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.requestMessage}
                    component={RequestMessage}
                    initialParams={{ title: 'Send Us a Message' }}
                />
                <HomeStack.Screen name={navigationRoutes.homeRoutes.cancelRequest} component={CancelRequest} />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.reopenRequest}
                    component={ReopenRequest}
                    initialParams={{ title: 'Re-Open Maintenance Req.' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.confirmScreen}
                    component={ConfirmScreen}
                    options={{ headerLeft: () => null, headerShown: false }}
                />

                {/*Packages*/}
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.smartPackage}
                    component={SmartPackage}
                    initialParams={{ title: 'SMARTPACKAGE' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.smartPackageHistory}
                    component={SmartPackageHistory}
                    initialParams={{ title: 'Package Delivery History' }}
                />

                {/*Manage Lease*/}
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.noticeToVacateRequest}
                    component={NoticeToVacateRequest}
                    initialParams={{ title: 'Notice to Vacate' }}
                    options={getMainStackScreenOptions(theme, useDrawerNavigation, false, false)}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.sodaRecipient}
                    component={SodaRecipient}
                    initialParams={{ title: 'Notice to Vacate' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.addressPicker}
                    component={AddressPicker}
                    initialParams={{ title: 'Notice to Vacate' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.noticeToVacatePreferContact}
                    component={NoticeToVacatePreferContact}
                    initialParams={{ title: 'Notice to Vacate' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.noticeToVacateEdit}
                    component={NoticeToVacateEdit}
                    initialParams={{ title: 'Notice to Vacate' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.noticeToVacateCancel}
                    component={NoticeToVacateCancel}
                    initialParams={{ title: 'Cancel Notice to Vacate' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.manageMyLease}
                    component={ManageMyLease}
                    initialParams={{ title: 'Manage My Lease' }}
                    options={{
                        headerShown: false,
                    }}
                />
                <HomeStack.Screen
                    name='NoticeToVacateConfirmation'
                    component={NoticeToVacateConfirmation}
                    options={getMainStackScreenOptions(theme, useDrawerNavigation, false, Platform.OS !== 'web')}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.contractAndDocuments}
                    component={ContractAndDocuments}
                    initialParams={{ title: 'Contracts and Documents' }}
                />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.renewalSigning}
                    component={RenewalSigningScreen}
                    options={getMainStackScreenOptions(theme, useDrawerNavigation, false, true, false, true)}
                />

                <HomeStack.Screen name={navigationRoutes.homeRoutes.addAVisitor} component={ComingSoon} />
                <HomeStack.Screen
                    name={navigationRoutes.homeRoutes.renewMyLease}
                    component={RenewMyLease}
                    initialParams={{ title: 'Renew My Lease' }}
                    options={getMainStackScreenOptions(theme, useDrawerNavigation, false, true)}
                />
            </HomeStack.Navigator>
        </MainStackSurface>
    );
});

export default HomeStackNavigator;
