import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextInput, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { InputStyles } from 'theme/style/InputStyles';
import { RegularText } from 'components/primitives/StyledText';

interface InputCurrencyProps {
    testID?: string;
    placeholder?: string;
    value?: number;
    disabled?: boolean;
    max?: number;
    error?: boolean;
    errorMessage?: string;
    onChange?: (value: number | undefined) => void;
    onBlur?: (value: number | undefined) => void;
}

const defaultProps: InputCurrencyProps = {
    error: false,
    placeholder: '$1234.56',
    max: 999999.99,
};

const InputCurrency: React.FC<InputCurrencyProps> = (props: InputCurrencyProps) => {
    const [inputValue, setInputValue] = useState('');
    const [focused, setFocused] = useState(false);

    const style = props.error
        ? [InputStyles.input, InputStyles.inputError]
        : props.disabled
        ? [InputStyles.input, InputStyles.inputDisabled]
        : [InputStyles.input];

    const _updateValue = (stringValue: string, numberValue: number | undefined) => {
        if (numberValue || numberValue === 0) {
            if (props.max && numberValue > props.max) return;

            setInputValue(stringValue);
            if (props.onChange) {
                props.onChange(numberValue);
            }
        }
    };

    const _formatValue = (numValue: number | undefined) => {
        if (numValue || numValue === 0) {
            setInputValue(`$${numValue.toFixed(2)}`);
        }
    };
    const _handleChange = (stringValue: string) => {
        if (stringValue) {
            const dot_pos = stringValue.indexOf('.');
            let numValue;
            if (dot_pos >= 0) {
                const left = stringValue.substring(0, dot_pos);
                let right = stringValue.substring(dot_pos + 1);

                if (right.length <= 2) {
                    right = right + '00';
                    right = right.substr(0, 2);
                    numValue = Number(`${left}.${right}`);
                    _updateValue(stringValue, numValue);
                }
            } else {
                if (stringValue === '-') {
                    setInputValue('');
                    if (props.onChange) {
                        props.onChange(undefined);
                    }
                } else {
                    numValue = Number(stringValue);
                    _updateValue(stringValue, numValue);
                }
            }
        } else {
            setInputValue('');
            if (props.onChange) {
                props.onChange(undefined);
            }
        }
    };

    const _handleBlur = (e: any) => {
        const numValue = _getStateNumberValue();
        if (props.onBlur) {
            props.onBlur(numValue);
        }

        _formatValue(numValue);
        setFocused(false);
    };

    const _handleFocus = (e: any) => {
        setFocused(true);
    };

    const _getStateNumberValue = () => {
        if (!inputValue) return undefined;
        let stateNumberValue;
        const dot_pos = inputValue.indexOf('.');
        if (dot_pos >= 0) {
            //this is to handle the case where inputValue = 12.
            //we add the '00' at the end to make it 12.00
            stateNumberValue = Number(inputValue + '00');
        } else {
            stateNumberValue = Number(inputValue);
        }

        return stateNumberValue;
    };

    useEffect(() => {
        const stateValue = _getStateNumberValue();
        if (stateValue !== props.value) {
            setInputValue(props.value?.toString() || '');
        }
    }, [props.value]);

    useEffect(() => {
        if (!focused) {
            _formatValue(_getStateNumberValue());
        } else {
            if (inputValue.indexOf('$') >= 0 && !props.disabled) {
                setInputValue(inputValue.replace(/\$/g, ''));
            }
        }
    }, [focused, inputValue]);

    return (
        <View style={InputStyles.inputWrapper}>
            <TextInput
                testID={props.testID}
                style={style}
                placeholder={props.placeholder?.toString()}
                placeholderTextColor={SmarthubTheme.colors.darkgrey}
                value={inputValue}
                keyboardType={'decimal-pad'}
                onChangeText={_handleChange}
                onBlur={_handleBlur}
                onFocus={_handleFocus}
                editable={!props.disabled}
            />

            {!!props.errorMessage && (
                <RegularText style={InputStyles.inputErrorMessage}>{props.errorMessage}</RegularText>
            )}
        </View>
    );
};

InputCurrency.defaultProps = defaultProps;

export default InputCurrency;
