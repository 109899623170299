/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.residency.SmarthubAwayTimeResidentV1
 */
export interface SmarthubAwayTimeResidentV1 {
    residentId: number;
    residentFirstName: string;
    residentLastName: string;
    checked: boolean;
}

export function createSmarthubAwayTimeResidentV1(): SmarthubAwayTimeResidentV1 {
    return { residentId: 0, residentFirstName: '', residentLastName: '', checked: false };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
