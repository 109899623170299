import { getZegoCreditCardTypeFromDetectedCardType, PaymentFormValues } from 'utils/PayLeaseZegoUtils';
import { FormLabelText, RegularText } from 'components/primitives/StyledText';
import { globalStyles } from 'theme/style/GlobalStyles';
import InputDropdown from 'components/input/InputDropdown';
import InputString from 'components/input/InputString';
import { StyleSheet, View } from 'react-native';
import React from 'react';
import InputNumber from 'components/input/InputNumber';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { FormikProps } from 'formik';
import { SelectOption } from 'models/remotecmds/com/ocs/nirvana/businesslogic/common/dataobjects/SelectOption';
import creditCardType from 'credit-card-type';
import { testProps } from 'components/ComponentTypes';

type PayByCardFormProps = {
    cardType: 'Credit' | 'Debit';
    stateOptions: Array<SelectOption>;
    formikHelpers: FormikProps<PaymentFormValues>;
};
const PayByCardForm: React.FC<PayByCardFormProps> = ({ cardType, stateOptions, formikHelpers }: PayByCardFormProps) => {
    const _getMonthOptions = (): SelectOption[] => [
        { value: '', label: '' },
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
    ];

    const _getYearOptions = (): SelectOption[] => {
        const result = [] as SelectOption[];
        result.push({ value: '', label: '' });

        const today = new Date();
        const currentYear = today.getFullYear();
        for (let i = 0; i <= 20; i++) {
            const next = (currentYear + i).toString().substr(-2);
            result.push({ value: next, label: next });
        }

        return result;
    };

    return (
        <>
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                {cardType} Card Number
            </FormLabelText>
            <InputNumber
                {...testProps(cardType + '-card-number')}
                onChange={value => {
                    if (value) {
                        const detectedCardTypes = creditCardType(value);
                        if (detectedCardTypes && detectedCardTypes[0]) {
                            const zegoCardType = getZegoCreditCardTypeFromDetectedCardType(detectedCardTypes[0].type);
                            formikHelpers.setFieldValue('creditCardType', zegoCardType);
                            formikHelpers.setFieldValue(
                                'bankOrCardName',
                                cardType !== 'Debit' ? zegoCardType : 'Debit',
                            );
                        } else {
                            formikHelpers.setFieldValue('creditCardType', null);
                            formikHelpers.setFieldValue('bankOrCardName', null);
                        }
                    }
                    formikHelpers.setFieldValue('creditCardNumber', value);
                    formikHelpers.setFieldValue('lastFour', value.substr(-4));
                }}
                maxLength={16}
                error={!!formikHelpers.errors.creditCardNumber || !!formikHelpers.errors.creditCardType}
                errorMessage={formikHelpers.errors.creditCardNumber || formikHelpers.errors.creditCardType}
            />

            <View style={styles.expirationAndCVVContainer}>
                <View style={styles.expirationAndCVVField}>
                    <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                        Expiry Month
                    </FormLabelText>
                    <View style={styles.dropdownWrapper}>
                        <InputDropdown
                            {...testProps('expiry-month')}
                            options={_getMonthOptions()}
                            onChange={value => {
                                formikHelpers.setFieldValue('creditCardExpMonth', value);
                            }}
                            selectedValue={formikHelpers.values.creditCardExpMonth}
                            error={!!formikHelpers.errors.creditCardExpMonth}
                            errorMessage={formikHelpers.errors.creditCardExpMonth}
                        />
                    </View>
                </View>
                <View style={styles.expirationAndCVVField}>
                    <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                        Expiry Year
                    </FormLabelText>
                    <View style={styles.dropdownWrapper}>
                        <InputDropdown
                            {...testProps('expiry-year')}
                            options={_getYearOptions()}
                            onChange={value => {
                                formikHelpers.setFieldValue('creditCardExpYear', value);
                            }}
                            selectedValue={formikHelpers.values.creditCardExpYear}
                            error={!!formikHelpers.errors.creditCardExpYear}
                            errorMessage={formikHelpers.errors.creditCardExpYear}
                        />
                    </View>
                </View>
                <View style={styles.expirationAndCVVField}>
                    <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                        CVV2
                    </FormLabelText>
                    <InputNumber
                        {...testProps('cvv2')}
                        onChange={value => {
                            formikHelpers.setFieldValue('creditCardCvv2', value);
                        }}
                        maxLength={4}
                        error={!!formikHelpers.errors.creditCardCvv2}
                        errorMessage={formikHelpers.errors.creditCardCvv2}
                    />
                </View>
            </View>
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                First Name on Card
            </FormLabelText>
            <InputString
                {...testProps('first-name')}
                onChange={formikHelpers.handleChange('billingFirstName')}
                maxLength={100}
                error={!!formikHelpers.errors.billingFirstName}
                errorMessage={formikHelpers.errors.billingFirstName}
            />
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                Last Name on Card
            </FormLabelText>
            <InputString
                {...testProps('last-name')}
                onChange={formikHelpers.handleChange('billingLastName')}
                maxLength={100}
                error={!!formikHelpers.errors.billingLastName}
                errorMessage={formikHelpers.errors.billingLastName}
            />
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                Street Address
            </FormLabelText>
            <InputString
                {...testProps('street-address')}
                onChange={formikHelpers.handleChange('billingStreetAddress')}
                maxLength={200}
                error={!!formikHelpers.errors.billingStreetAddress}
                errorMessage={formikHelpers.errors.billingStreetAddress}
            />
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                Country
            </FormLabelText>
            <RegularText>United States of America</RegularText>
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                State
            </FormLabelText>
            <InputDropdown
                {...testProps('state-name')}
                options={stateOptions}
                onChange={value => formikHelpers.setFieldValue('billingState', value)}
                selectedValue={formikHelpers.values.billingState}
                error={!!formikHelpers.errors.billingState}
                errorMessage={formikHelpers.errors.billingState}
            />
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                City
            </FormLabelText>
            <InputString
                {...testProps('city')}
                onChange={formikHelpers.handleChange('billingCity')}
                maxLength={100}
                error={!!formikHelpers.errors.billingCity}
                errorMessage={formikHelpers.errors.billingCity}
            />
            <FormLabelText style={[styles.label, globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                ZIP
            </FormLabelText>
            <InputNumber
                {...testProps('zip-code')}
                onChange={formikHelpers.handleChange('billingZip')}
                maxLength={20}
                error={!!formikHelpers.errors.billingZip}
                errorMessage={formikHelpers.errors.billingZip}
            />
        </>
    );
};

export default PayByCardForm;

const styles = StyleSheet.create({
    label: {
        fontSize: 14,
    },
    expirationAndCVVContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    expirationAndCVVField: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginRight: SmarthubTheme.layout.INNERPAD,
    },
    dropdownWrapper: {
        flex: 1,
        width: '100%',
    },
});
