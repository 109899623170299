import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UIStore } from 'stores/domain/UIStore';
import { UseQueryOptions } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import {
    createGetContractSigningUrlCommandV1,
    GetContractSigningUrlCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/contract/GetContractSigningUrlCommandV1';
import { GetContractSigningUrlResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/contract/GetContractSigningUrlResultV1';

export const useGetContractSigningUrlQuery = (
    propertyCd: string,
    residencyId: number,
    viewId: string,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetContractSigningUrlResultV1>,
) => {
    //build key
    const queryKey = [QueryKeys.ContractSigningUrl, propertyCd, viewId];

    const cmd = createGetContractSigningUrlCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.viewId = viewId;

    //Setup query
    const queryResult = useCommandQuery<GetContractSigningUrlCommandV1, GetContractSigningUrlResultV1>(
        cmd,
        uiStore,
        queryKey,
        queryOptions,
    );
    return { queryKey, queryResult };
};
