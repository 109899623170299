import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import Button from 'components/primitives/Button';
import { StackNavigationProp } from '@react-navigation/stack';
import { globalStyles } from 'theme/style/GlobalStyles';
import Section from 'components/primitives/Section';
import { FormLabelText, Heading2, RegularText } from 'components/primitives/StyledText';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useStore } from 'contexts/StoreContextProvider';
import InputCheckbox from 'components/input/InputCheckbox';
import Container from 'components/primitives/Container';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFormik } from 'formik';
import InputDate from 'components/input/InputDate';
import { daysBetween, formatDate, minDateTodayOrDateProvided, parseDateMMDDYYYY } from 'utils/DateUtils';
import {
    createSmarthubAwayTimeV1,
    SmarthubAwayTimeV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAwayTimeV1';
import { SmarthubAwayTimeResidentV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAwayTimeResidentV1';
import { useNavigationState, useRoute } from '@react-navigation/native';
import { getRouteParams } from 'utils/routeUtils';
import { InputStyles } from 'theme/style/InputStyles';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import { useTheme } from 'react-native-paper';
import useConfirmLeave from 'utils/useConfirmLeave';
import { testProps } from 'components/ComponentTypes';
import {
    generateSubmitAddEditAwayTimeCmd,
    useGetAddEditAwayTimeData,
    useSubmitAddEditAwayTime,
} from 'queries/hooks/more/AwayTimesHooks';

type AddEditAwayTimeProps = {
    navigation: StackNavigationProp<any>;
};

const AddEditAwayTime: React.FC<AddEditAwayTimeProps> = observer(({ navigation }: AddEditAwayTimeProps) => {
    const theme = useTheme();
    const { userSessionStore, uiStore } = useStore();
    const route = useRoute();

    // Was still getting undefined for awayTimeId. This turns undefined into a 0.
    let { awayTimeId } = getRouteParams(route) || 0;
    awayTimeId = awayTimeId !== undefined ? awayTimeId : 0;

    const { sessionActiveResidency } = userSessionStore;

    const navigationStateRoutes = useNavigationState(state => state).routes;
    const isEditAwayTimeOnNavigationStack = IsNavigationRouteOnStack(navigationRoutes.moreRoutes.editAwayTime);

    const [bottomCalendarOpen, setBottomCalendarOpen] = useState(false);

    const schema = yup.object().shape({
        returningDate: yup
            .string()
            .typeError('Please enter a valid date')
            .required('Please enter a valid Date')
            .test(
                'returningDateIsValid',
                'Returning date is not valid. Please enter a date that is today or later',
                value => compareDatesForFormikValidation(value, '') <= 1,
            ),
        leavingDate: yup
            .string()
            .typeError('Please enter a valid date')
            .required('Please enter a valid Date')
            .test(
                'leavingDateIsValid',
                'Leaving date is not valid. Please enter a date that is today or later',
                value => compareDatesForFormikValidation(value, '') <= 1,
            ),
        residents: yup
            .array()
            .of(
                yup.object().shape({
                    residentId: yup
                        .number()
                        .required('No resident ID added')
                        .min(1, 'Invalid resident ID. ID must be greater than 1'),
                    residentFirstName: yup.string().required('Resident first name required'),
                    residentLastName: yup.string().required('Resident last name required'),
                }),
            )
            .test(
                'hasResidents',
                'Please choose at least one resident.',
                residents => residents !== undefined && residents.filter(resident => resident.checked).length > 0,
            ),
        propertyCd: yup.string().required('propertyCd not properly added'),
        residencyId: yup.number().required('residencyId not property added').min(1, 'Invalid residencyId'),
        residentAwayTimeId: yup.number().required('residentAwayTimeId not properly added'),
    });

    const compareDatesForFormikValidation = (date1: string | undefined, date2: string | undefined) => {
        const dt1 = date1 === undefined ? '' : date1;
        const dt2 = date2 === undefined ? '' : date2;

        if (awayTimeId <= 0) {
            if (dt2.length === 0) return daysBetween(parseDateMMDDYYYY(dt1), userSessionStore.propertySpecificDt);
            else return daysBetween(parseDateMMDDYYYY(dt1), parseDateMMDDYYYY(dt2));
        } else {
            return -1;
        }
    };

    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            propertyCd: sessionActiveResidency.propertyCd,
            residencyId: sessionActiveResidency.residencyId,
            residentAwayTimeId: 0,
            leavingDate: '',
            returningDate: '',
            residents: [] as Array<SmarthubAwayTimeResidentV1>,
        },
        validationSchema: schema,
        onSubmit: (values: SmarthubAwayTimeV1) => {
            if (!uiStore.activityLoaderVisible) {
                onSubmit(values);
                formik.setSubmitting(true);
            }
        },
    });

    const addEditAwayTimeQuery = useGetAddEditAwayTimeData(awayTimeId);
    const [awayTime, setAwayTime] = useState(createSmarthubAwayTimeV1());
    const submitAwayTimeMutation = useSubmitAddEditAwayTime(awayTime, navigation, formik);

    const onSubmit = (data: SmarthubAwayTimeV1) => {
        if (sessionActiveResidency) {
            uiStore.showActivityLoader();

            if (!awayTimeId) {
                data.residentAwayTimeId = 0;
            }

            data.residents = data.residents.filter(res => res.checked);

            formik.resetForm({ values: { ...formik.values } });
            setAwayTime(data);

            submitAwayTimeMutation.mutate(
                generateSubmitAddEditAwayTimeCmd(
                    sessionActiveResidency.propertyCd,
                    sessionActiveResidency.residencyId,
                    data,
                ),
            );
        }
    };

    useConfirmLeave(navigation, formik);

    // When you go to edit away time, click add away time in quick action menu, then when in add away time refresh the page, the info from edit away time appears
    // This is because edit away time is still on the stack and is still obtaining data from the server on refresh. By removing it from the stack on refresh we eliminate this problem.
    useEffect(() => {
        if (userSessionStore.isReloading && navigationStateRoutes.length === 4 && isEditAwayTimeOnNavigationStack) {
            navigation.reset({
                index: 2,
                routes: [
                    { name: navigationRoutes.moreRoutes.more },
                    { name: navigationRoutes.moreRoutes.awayTimes },
                    { name: navigationRoutes.moreRoutes.addAwayTime, params: { awayTimeId: 0 } },
                ],
            });
        }
    }, [userSessionStore.isReloading]);

    useEffect(() => {
        if (addEditAwayTimeQuery.isSuccess) {
            formik.resetForm({
                values: {
                    propertyCd: sessionActiveResidency.propertyCd,
                    residencyId: sessionActiveResidency.residencyId,
                    residentAwayTimeId: addEditAwayTimeQuery.data.awayTime.residentAwayTimeId,
                    leavingDate: addEditAwayTimeQuery.data.awayTime.leavingDate,
                    returningDate: addEditAwayTimeQuery.data.awayTime.returningDate,
                    residents: addResidents(
                        addEditAwayTimeQuery.data.awayTime.residents,
                        addEditAwayTimeQuery.data.activeResidentList,
                    ),
                },
            });
        }
    }, [addEditAwayTimeQuery.status, addEditAwayTimeQuery.data?.awayTime]);

    useEffect(() => {
        if (addEditAwayTimeQuery.isFetching) {
            uiStore.showActivityLoader();
        } else {
            uiStore.hideActivityLoader();
        }
    }, [addEditAwayTimeQuery.isFetching]);

    // Populates residents with the active residents list then uses residents array to add checked or unchecked
    const addResidents = (
        residents: SmarthubAwayTimeResidentV1[],
        residentsList: SmarthubAwayTimeResidentV1[],
    ): SmarthubAwayTimeResidentV1[] => {
        const result: SmarthubAwayTimeResidentV1[] = [];
        residentsList.forEach(resident => {
            if (residents && residents.filter(res => res.residentId === resident.residentId).length > 0) {
                resident.checked = true;
                result.push(resident);
            } else {
                resident.checked = false;
                result.push(resident);
            }
        });
        return result;
    };

    const handleDateChange = (date: string, formikId: string) => {
        formik.setFieldValue(formikId, date);
    };

    const handleResidentChange = (checked: string, resident: SmarthubAwayTimeResidentV1) => {
        formik.values.residents.forEach(res => {
            if (res.residentId === resident.residentId) {
                res.checked = checked === 'checked';
            }
        });
    };

    if (
        addEditAwayTimeQuery.isSuccess &&
        !addEditAwayTimeQuery.isFetching &&
        userSessionStore.hasSessionActiveResidency
    ) {
        return (
            <SafeAreaView style={styles.flexContainer}>
                <KeyboardAwareScrollView
                    scrollEnabled={true}
                    keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                    contentContainerStyle={{ backgroundColor: theme.colors.background }}
                    enableResetScrollToCoords={false}>
                    <View style={globalStyles.container}>
                        {Number(awayTimeId) === 0 && <Heading2>Add a New Away Time</Heading2>}
                        <Section>
                            <RegularText style={globalStyles.singleSpaceBelow}>
                                While you are gone, we will keep your packages safe and secure for you.
                            </RegularText>
                        </Section>

                        <Section>
                            <FormLabelText style={globalStyles.doubleSpaceBelow}>When are you leaving?</FormLabelText>
                        </Section>
                        <View style={bottomCalendarOpen ? styles.underlay : styles.overlay}>
                            <InputDate
                                {...testProps('date-picker-leaving-date')}
                                minDate={formatDate(userSessionStore.propertySpecificDt)}
                                maxDate={formik?.values?.returningDate}
                                value={formik.values?.leavingDate}
                                onChange={(date: string) => {
                                    handleDateChange(date, 'leavingDate');
                                }}
                            />
                            {formik.errors.leavingDate && formik.touched.leavingDate && (
                                <RegularText style={InputStyles.inputErrorMessage}>
                                    {formik.errors.leavingDate}
                                </RegularText>
                            )}
                        </View>

                        <Section>
                            <FormLabelText style={globalStyles.doubleSpaceBelow}>When will you be back?</FormLabelText>
                        </Section>
                        <View style={bottomCalendarOpen ? styles.overlay : styles.underlay}>
                            <InputDate
                                {...testProps('date-picker-returning-date')}
                                value={formik.values.returningDate}
                                minDate={minDateTodayOrDateProvided(
                                    formik.values.leavingDate,
                                    userSessionStore.propertySpecificDt,
                                )}
                                onChange={(date: string) => {
                                    handleDateChange(date, 'returningDate');
                                }}
                                onOpen={() => {
                                    setBottomCalendarOpen(true);
                                }}
                                onClose={() => {
                                    setBottomCalendarOpen(false);
                                }}
                            />
                            {formik.errors.returningDate && formik.touched.returningDate && (
                                <RegularText style={InputStyles.inputErrorMessage}>
                                    {formik.errors.returningDate}
                                </RegularText>
                            )}
                        </View>

                        <Section>
                            <FormLabelText style={globalStyles.doubleSpaceBelow}>Who will be away?</FormLabelText>
                            {formik.values?.residentAwayTimeId ===
                                addEditAwayTimeQuery.data.awayTime.residentAwayTimeId &&
                                addEditAwayTimeQuery.data.activeResidentList?.map((resident: any) => {
                                    return (
                                        <InputCheckbox
                                            key={resident.residentId}
                                            label={`${resident.residentFirstName} ${resident.residentLastName}`}
                                            initialStatus={
                                                formik.values.residents?.filter(
                                                    res => res.residentId === resident.residentId && res.checked,
                                                ).length > 0
                                                    ? 'checked'
                                                    : 'unchecked'
                                            }
                                            onChange={checked => {
                                                handleResidentChange(checked, resident);
                                            }}
                                        />
                                    );
                                })}
                            {formik.errors.residents && (
                                <RegularText style={InputStyles.inputErrorMessage}>
                                    {formik.errors.residents}
                                </RegularText>
                            )}
                        </Section>

                        {formik.values?.returningDate !== '' &&
                            formik.values?.leavingDate !== '' &&
                            addEditAwayTimeQuery.data.maxAwayDaysAllowed >= 0 &&
                            daysBetween(
                                parseDateMMDDYYYY(formik.values?.leavingDate),
                                parseDateMMDDYYYY(formik.values?.returningDate),
                            ) > addEditAwayTimeQuery.data.maxAwayDaysAllowed && (
                                <Section>
                                    <Container borderStyle={'orange'}>
                                        <View style={[globalStyles.flexColumnCenter, globalStyles.innerContainer]}>
                                            <MaterialCommunityIcons
                                                name='alert-circle'
                                                size={SmarthubTheme.layout.LARGEICONSIZE}
                                                color={SmarthubTheme.colors.orange}
                                                style={globalStyles.doubleSpaceBelow}
                                            />
                                            <RegularText style={styles.noticeContainer}>
                                                Please note that Package Storage Fees will only be waived for{' '}
                                                {addEditAwayTimeQuery.data.maxAwayDaysAllowed} days after which normal
                                                storage fees will apply.
                                            </RegularText>
                                        </View>
                                    </Container>
                                </Section>
                            )}

                        <Section style={[styles.submitButton]}>
                            <Button
                                style={globalStyles.sectionSpaceBelow}
                                inactive={formik.isSubmitting || sessionActiveResidency.adminYn}
                                onClick={formik.handleSubmit}
                                testID={'submit-button-add-edit-away-time'}>
                                Save Away Time
                            </Button>
                        </Section>
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        );
    } else {
        return null;
    }
});

export default AddEditAwayTime;

const styles = StyleSheet.create({
    submitButton: {
        width: '100%',
    },
    flexContainer: {
        flex: 1,
    },
    noticeContainer: {
        textAlign: 'center',
    },
    overlay: {
        zIndex: 1001,
    },
    underlay: {
        zIndex: 1000,
    },
});
