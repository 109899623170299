import React, { useEffect } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { FormLabelText, Heading2, MediumBoldText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useStore } from 'contexts/StoreContextProvider';
import InputCheckbox from 'components/input/InputCheckbox';
import { observer } from 'mobx-react-lite';
import Section from 'components/primitives/Section';
import InputDropdown from 'components/input/InputDropdown';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import InputString from 'components/input/InputString';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SmarthubTicketSubmitData } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubTicketSubmitData';
import { getContactMethodOptions } from 'screens/home/requests/requestUtils';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useConfirmLeave from 'utils/useConfirmLeave';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import { generateSubmitTicketCMD, useGetCreateTicketData, useSubmitTicket } from 'screens/home/requests/RequestsHooks';
import { FormikHelpers } from 'formik/dist/types';

type CreateTicketProps = {
    navigation: StackNavigationProp<any>;
};

const schema = yup.object().shape({
    desc: yup
        .string()
        .required('Please provide more details')
        .max(1024, 'Please limit your message to 1024 characters'),
    escalateYn: yup.boolean(),
    contactDetail: yup.string().required('Please select an option'),
});

const CreateTicket: React.FC<CreateTicketProps> = observer(({ navigation }: CreateTicketProps) => {
    const { useDrawerNavigation } = useResponsiveContext();
    const { userSessionStore, uiStore } = useStore();
    const { smarthubUser, confirmActiveResidency, sessionActiveResidency } = userSessionStore;

    const query = useGetCreateTicketData();

    const isHomeOnNavStack = IsNavigationRouteOnStack(navigationRoutes.homeRoutes.home);
    const submitTicketMutation = useSubmitTicket(navigation, isHomeOnNavStack);

    const onSubmit = async (data: SmarthubTicketSubmitData) => {
        if (sessionActiveResidency) {
            uiStore.showActivityLoader();
            submitTicketMutation.mutate(
                generateSubmitTicketCMD(
                    sessionActiveResidency.propertyCd,
                    sessionActiveResidency.residencyId,
                    sessionActiveResidency.residentId,
                    data,
                ),
            );
        }
    };

    const formikSubmit = (data: SmarthubTicketSubmitData, formikHelpers: FormikHelpers<SmarthubTicketSubmitData>) => {
        formikHelpers.resetForm({ values: data });
        formikHelpers.setSubmitting(true);
        onSubmit(data);
    };

    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            propertyCd: sessionActiveResidency?.propertyCd,
            residencyId: sessionActiveResidency?.residencyId,
            residentId: sessionActiveResidency?.residentId,
            desc: '',
            escalateYn: false,
            contactDetail: '',
            categoryTypeDv: null,
        },
        validationSchema: schema,
        onSubmit: (data: SmarthubTicketSubmitData, formikHelpers) => {
            if (!uiStore.activityLoaderVisible) {
                if (data.escalateYn) {
                    uiStore.showAlert({
                        message: `This message will be sent to a regional contact, which may delay the resolution of your request. This also indicates that a resolution could not be reached with community management.`,
                        buttons: [
                            {
                                buttonText: 'No, disregard',
                                buttonType: 'primary',
                                onClick: uiStore.hideAlert,
                            },
                            {
                                buttonText: 'Yes, proceed',
                                buttonType: 'secondary',
                                onClick: () => {
                                    uiStore.hideAlert();
                                    formikSubmit(data, formikHelpers);
                                },
                            },
                        ],
                    });
                } else {
                    formikSubmit(data, formikHelpers);
                }
            }
        },
    });

    useEffect(() => {
        query.isLoading ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [query.isLoading]);

    useEffect(() => {
        if (query.data) {
            const contactMethods = getContactMethodOptions(query.data.residentContactInfo);
            formik.setFieldValue('contactDetail', contactMethods[0].value, false);
            formik.resetForm({
                values: { ...formik.values, contactDetail: contactMethods[0].value || '' },
            });
        }
    }, [query.isSuccess]);

    useEffect(() => {
        confirmActiveResidency(navigation);
    }, [smarthubUser.authorizedResidencies, sessionActiveResidency.key]);

    useConfirmLeave(navigation, formik);

    if (query.isSuccess && query.data) {
        return (
            <SafeAreaView style={globalStyles.container}>
                <KeyboardAwareScrollView
                    keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                    enableResetScrollToCoords={false}>
                    <View style={globalStyles.container}>
                        {useDrawerNavigation && (
                            <View style={styles.drawerHeader}>
                                <Heading2 style={[globalStyles.sectionSpaceBelow]}>Send Us a Message</Heading2>
                            </View>
                        )}

                        <MediumBoldText style={globalStyles.sectionSpaceBelow}>
                            {`We're always happy to hear from you! What's on your mind?`}
                        </MediumBoldText>

                        <InputString
                            autoFocus={Platform.OS === 'web'}
                            onChange={formik.handleChange('desc')}
                            onBlur={formik.handleBlur('desc')}
                            initialValue={formik.values.desc}
                            multiline={true}
                            numberOfLines={6}
                            maxLength={1024}
                            error={!!formik.errors.desc}
                            errorMessage={formik.errors.desc}
                            {...testProps('message-details-input')}
                        />

                        {/*todo: uncomment for view photo*/}

                        {/*<Section>*/}
                        {/*    <View style={styles.addPhotoSection}>*/}
                        {/*        <Image*/}
                        {/*            source={require('../../../../assets/images/cameraPlus.png')}*/}
                        {/*            style={globalStyles.icon}*/}
                        {/*        />*/}
                        {/*        <View style={styles.addPhotoLabel}>*/}
                        {/*            <Hyperlink>Add Photos</Hyperlink>*/}
                        {/*        </View>*/}
                        {/*    </View>*/}
                        {/*</Section>*/}

                        <Section>
                            <InputCheckbox
                                style={'small'}
                                initialStatus={formik.values.escalateYn ? 'checked' : 'unchecked'}
                                label={'Do not send to community management. This message is confidential.'}
                                {...testProps('escalate-checkbox')}
                                onChange={checkValue => {
                                    formik.setFieldValue('escalateYn', checkValue === 'checked' ? true : false);
                                }}
                            />
                        </Section>

                        <Section>
                            <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                How should we get in touch with you?
                            </FormLabelText>

                            <InputDropdown
                                selectedValue={formik.values.contactDetail}
                                // options={
                                //     createTicketData ? getContactMethodOptions(createTicketData.residentContactInfo) : []
                                // }
                                options={query.data ? getContactMethodOptions(query.data.residentContactInfo) : []}
                                onChange={value => {
                                    formik.setFieldValue('contactDetail', value);
                                }}
                            />
                            {/*<Hyperlink*/}
                            {/*    style={globalStyles.alignSelfFlexEnd}*/}
                            {/*    onClick={() =>*/}
                            {/*        useDrawerNavigation*/}
                            {/*            ? navigation.navigate('Profile')*/}
                            {/*            : navigation.navigate('ProfileModal')*/}
                            {/*    }>*/}
                            {/*    Wrong Number? Update it Now*/}
                            {/*</Hyperlink>*/}
                        </Section>

                        <Section>
                            <View style={[styles.button]}>
                                <Button
                                    inactive={uiStore.activityLoaderVisible || sessionActiveResidency.adminYn}
                                    onClick={formik.handleSubmit}
                                    {...testProps('submit-button')}>
                                    Submit
                                </Button>
                            </View>
                        </Section>
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        );
    } else {
        return null;
    }
});

export default CreateTicket;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: '100%',
    },
    // addPhotoSection: {
    //     flexDirection: 'row',
    //     alignItems: 'center',
    // },
    // addPhotoLabel: {
    //     paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
    // },
});
