import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetSmarthubManageMyLeaseDataResultV1 } from './GetSmarthubManageMyLeaseDataResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetSmarthubManageMyLeaseDataCommandV1
 */
export type GetSmarthubManageMyLeaseDataCommandV1 =
    AbstractResidencyAuthenticatedCommand<GetSmarthubManageMyLeaseDataResultV1>;

export function createGetSmarthubManageMyLeaseDataCommandV1(): GetSmarthubManageMyLeaseDataCommandV1 {
    return {
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetSmarthubManageMyLeaseDataCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
