import { SmarthubSodaRecipientV1 } from '../versioneddataobjects/residency/SmarthubSodaRecipientV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.dataobjects.SmarthubNoticeToVacateRequestSubmitData
 */
export interface SmarthubNoticeToVacateRequestSubmitData {
    propertyCd: string;
    residencyId: number;
    residentId: number;
    expectedMoveOutDt: string;
    moveOutReasonCd: string;
    preferContactOption: string;
    preferContactDetail: string;
    contactResidentId: number;
    sodaRecipientList: Array<SmarthubSodaRecipientV1>;
}

export function createSmarthubNoticeToVacateRequestSubmitData(): SmarthubNoticeToVacateRequestSubmitData {
    return {
        propertyCd: '',
        residencyId: 0,
        residentId: 0,
        expectedMoveOutDt: '01/01/1900',
        moveOutReasonCd: '',
        preferContactOption: '',
        preferContactDetail: '',
        contactResidentId: 0,
        sodaRecipientList: [],
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
