import { createSmarthubAwayTimeResidentV1, SmarthubAwayTimeResidentV1 } from './SmarthubAwayTimeResidentV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.residency.SmarthubAwayTimeV1
 */
export interface SmarthubAwayTimeV1 {
    propertyCd: string;
    residencyId: number;
    residentAwayTimeId: number;
    leavingDate: string;
    returningDate: string;
    residents: Array<SmarthubAwayTimeResidentV1>;
}

export function createSmarthubAwayTimeV1(): SmarthubAwayTimeV1 {
    return {
        propertyCd: '',
        residencyId: 0,
        residentAwayTimeId: 0,
        leavingDate: '',
        returningDate: '',
        residents: [createSmarthubAwayTimeResidentV1()],
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
