import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { GetPaymentPageDataResultV1 } from './GetPaymentPageDataResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetPaymentPageDataCommandV1
 */
export interface GetPaymentPageDataCommandV1 extends AbstractResidentAuthenticatedCommand<GetPaymentPageDataResultV1> {
    isReservationYn: boolean;
    applyMoveInPaymentRestrictions: boolean;
}

export function createGetPaymentPageDataCommandV1(): GetPaymentPageDataCommandV1 {
    return {
        isReservationYn: false,
        applyMoveInPaymentRestrictions: false,
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetPaymentPageDataCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
