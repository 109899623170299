import React from 'react';
import { StyleSheet, View } from 'react-native';
import { DeemphansizedTextSmall, MediumBoldText } from 'components/primitives/StyledText';
import Hyperlink from 'components/primitives/Hyperlink';
import InputString from 'components/input/InputString';
import InputDropdown from 'components/input/InputDropdown';
import { globalStyles } from 'theme/style/GlobalStyles';
import { SelectOption } from 'models/remotecmds/com/ocs/nirvana/businesslogic/common/dataobjects/SelectOption';
import { FormikErrors, FormikProps } from 'formik';
import { SubmitSmartmoveVehiclesAndPetsCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/SubmitSmartmoveVehiclesAndPetsCommandV1';
import { SmartmoveVehicleV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveVehicleV1';

type SmartMoveVehicleProps = {
    index: number;
    onRemove?: () => void;
    vehicle: SmartmoveVehicleV1;
    ownerOptions: Array<SelectOption> | undefined;
    stateOptions: Array<SelectOption> | undefined;
    formikHelper: FormikProps<SubmitSmartmoveVehiclesAndPetsCommandV1>;
};

const SmartMoveVehicle: React.FC<SmartMoveVehicleProps> = ({
    index,
    onRemove,
    vehicle,
    ownerOptions,
    stateOptions,
    formikHelper,
}: SmartMoveVehicleProps) => {
    const _handleRemove = () => {
        if (onRemove) {
            onRemove();
        }
    };

    let errors: FormikErrors<SmartmoveVehicleV1> | undefined = undefined;
    if (formikHelper.errors.smartmoveVehicles && formikHelper.errors.smartmoveVehicles[index]) {
        errors = formikHelper.errors.smartmoveVehicles[index] as FormikErrors<SmartmoveVehicleV1>;
    }

    const fieldPrefix = `smartmoveVehicles.${index}`;

    return (
        <>
            <View style={styles.heading}>
                <MediumBoldText style={globalStyles.flexContainer}>Vehicle {index + 1}</MediumBoldText>
                <Hyperlink
                    style={styles.removeLink}
                    onClick={() => {
                        _handleRemove();
                    }}>
                    Remove Vehicle
                </Hyperlink>
            </View>
            <View style={globalStyles.sectionSpaceBelow}>
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>Vehicle Make</DeemphansizedTextSmall>
                <InputString
                    initialValue={vehicle.vehicleMake}
                    maxLength={20}
                    error={!!errors?.vehicleMake}
                    errorMessage={errors?.vehicleMake}
                    onChange={formikHelper.handleChange(`${fieldPrefix}.vehicleMake`)}
                    onBlur={formikHelper.handleBlur(`${fieldPrefix}.vehicleMake`)}
                />
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>
                    License Plate Number
                </DeemphansizedTextSmall>
                <InputString
                    initialValue={vehicle.vehicleLicensePlate}
                    maxLength={15}
                    error={!!errors?.vehicleLicensePlate}
                    errorMessage={errors?.vehicleLicensePlate}
                    onChange={formikHelper.handleChange(`${fieldPrefix}.vehicleLicensePlate`)}
                    onBlur={formikHelper.handleBlur(`${fieldPrefix}.vehicleLicensePlate`)}
                />
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>
                    License Plate State
                </DeemphansizedTextSmall>
                <InputDropdown
                    options={stateOptions}
                    selectedValue={vehicle.vehicleLicensePlateState}
                    error={!!errors?.vehicleLicensePlateState}
                    errorMessage={errors?.vehicleLicensePlateState}
                    onChange={value => {
                        formikHelper.setFieldValue(`${fieldPrefix}.vehicleLicensePlateState`, value);
                    }}
                />
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>Vehicle Owner</DeemphansizedTextSmall>
                <InputDropdown
                    options={ownerOptions}
                    selectedValue={String(vehicle.vehicleOwnerResidentId)}
                    error={!!errors?.vehicleOwnerResidentId}
                    errorMessage={errors?.vehicleOwnerResidentId}
                    onChange={value => {
                        formikHelper.setFieldValue(`${fieldPrefix}.vehicleOwnerResidentId`, Number(value));
                    }}
                />
            </View>
        </>
    );
};

export default SmartMoveVehicle;

const styles = StyleSheet.create({
    heading: {
        flexDirection: 'row',
    },
    removeLink: {
        flex: 1,
        paddingTop: 4,
        textAlign: 'right',
    },
});
