import { RootStore } from '../RootStore';
import { action, makeAutoObservable } from 'mobx';

export class RequestStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {});
    }

    getSessionActiveResidency() {
        return this.rootStore.userSessionStore.sessionActiveResidency;
    }

    gridScreenIncludeCompleted = false;

    toggleGridScreenIncludeCompleted = action((value: string) => {
        this.gridScreenIncludeCompleted = value === 'checked' ? true : false;
    });

    hasCovidSym = false;
}
