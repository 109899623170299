import { GetAuthorizedResidenciesResultV1 } from './GetAuthorizedResidenciesResultV1';
import { AbstractVersionedRemoteCommand } from '../../../remotecmd/versioned/AbstractVersionedRemoteCommand';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetAuthorizedResidenciesCommandV1
 */
export interface GetAuthorizedResidenciesCommandV1
    extends AbstractVersionedRemoteCommand<GetAuthorizedResidenciesResultV1> {
    payLeaseRedirUrl: string;
    smarthubWebUrl: string;
    optionalResidencyId: number;
    optionalPropertyCd: string;
}

export function createGetAuthorizedResidenciesCommandV1(): GetAuthorizedResidenciesCommandV1 {
    return {
        payLeaseRedirUrl: '',
        smarthubWebUrl: '',
        optionalResidencyId: 0,
        optionalPropertyCd: '',
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetAuthorizedResidenciesCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
