import { spaceWordsOnCapitals } from 'utils/StringUtils';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { navigationRoutes } from 'utils/NavigationUtils';

export const getHeaderTitle = (route: any) => {
    const focusedRoute = getFocusedRouteNameFromRoute(route);
    const routeName =
        (route.params && route.params.title) ??
        (focusedRoute && spaceWordsOnCapitals(focusedRoute)) ??
        (route.name && spaceWordsOnCapitals(route.name)) ??
        spaceWordsOnCapitals(navigationRoutes.homeRoutes.home);
    return routeName;
};
