import React from 'react';
import { StyleSheet } from 'react-native';
import { useAuthentication } from 'contexts/AuthContextProvider';
import { getAuth, GoogleAuthProvider, OAuthCredential, signInWithCredential, AuthCredential } from 'firebase/auth';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import * as Google from 'expo-auth-session/providers/google';
import ApiConfig from 'components/auth/constants/ApiConfig';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Button from 'components/primitives/Button';

/**
 * See https://docs.expo.io/guides/authentication/#google
 *
 * @param props
 * @constructor
 */
const GoogleAuthSessionLoginButton: React.FC = () => {
    const { setLoginOption } = useAuthentication();

    //this will work in web/simulator but not standalone but currently we're using for just web
    const [request, response, promptAsync] = Google.useIdTokenAuthRequest(
        {
            expoClientId: ApiConfig.GoogleConfig.expoClientId,
            iosClientId: ApiConfig.GoogleConfig.iosClientId,
            androidClientId: ApiConfig.GoogleConfig.androidClientId,
            webClientId: ApiConfig.GoogleConfig.webClientId,
            clientId: ApiConfig.GoogleConfig.webClientId,
        },
        { path: ApiConfig.GoogleConfig.redirPath, projectNameForProxy: 'SMARTHUB' },
    );

    const signInToGoogle = () => {
        promptAsync({
            projectNameForProxy: 'SMARTHUB',
        }).catch(err => {
            logError(ERROR_LOGGER, 'Google auth session Login ERROR...', err);
        });
    };

    const loginToFirebaseWithIdToken = (idToken: string) => {
        const auth = getAuth();
        logInfo(INFO_LOGGER, 'google id token is ', idToken);
        // Build Firebase credential with the Google id token
        const credential: OAuthCredential = GoogleAuthProvider.credential(idToken);
        logInfo(INFO_LOGGER, 'google credential ', JSON.stringify(credential));
        // Sign in with credential from the Google user
        signInWithCredential(auth, credential)
            .then(uc => {
                logInfo(
                    INFO_LOGGER,
                    'user was signed into firebase using their google signin. Firebase credential is : ',
                    JSON.stringify(uc),
                );
            })
            .catch(error => {
                // Handle Errors here
                const errorTuple: [string, string, string, AuthCredential] = error;
                const [code, message, email, credential] = errorTuple;
                logError(ERROR_LOGGER, 'Error signing user into firebase ', JSON.stringify(error));
            });
    };

    React.useEffect(() => {
        if (response?.type === 'success') {
            const { id_token } = response.params;
            loginToFirebaseWithIdToken(id_token);
        }
    }, [response]);

    return (
        <Button
            style={styles.loginOptionButton}
            onClick={() => {
                setLoginOption('google');
                signInToGoogle();
            }}
            icon={{
                iconID: 'google',
                color: SmarthubTheme.colors.white,
            }}>
            Sign in with Google
        </Button>
    );
};

export default GoogleAuthSessionLoginButton;

const styles = StyleSheet.create({
    loginOptionButton: {
        backgroundColor: SmarthubTheme.colors.red,
        borderColor: SmarthubTheme.colors.red,
    },
});
