import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UIStore } from 'stores/domain/UIStore';
import { UseQueryOptions } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import { GetSmartPackagesForResidentResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartpackage/GetSmartPackagesForResidentResultV1';
import {
    createGetSmartPackagesForResidentCommandV1,
    GetSmartPackagesForResidentCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartpackage/GetSmartPackagesForResidentCommandV1';

export const useGetSmartPackagesForResidentQuery = (
    propertyCd: string,
    residentId: number,
    pendingOnly: boolean,
    maxDaysOld: number,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetSmartPackagesForResidentResultV1>,
) => {
    //build key
    const queryKey = [QueryKeys.SmartPackages, propertyCd, residentId, pendingOnly, maxDaysOld];

    const cmd = createGetSmartPackagesForResidentCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residentId = residentId;
    cmd.pendingOnly = pendingOnly;
    cmd.maxDaysOld = maxDaysOld;

    //Setup query
    const queryResult = useCommandQuery<GetSmartPackagesForResidentCommandV1, GetSmartPackagesForResidentResultV1>(
        cmd,
        uiStore,
        queryKey,
        queryOptions,
    );
    return { queryKey, queryResult };
};
