export type RemoteActionHandler = {
    requestHandler: (rootStore: any, onComplete: (successful: boolean) => void) => Promise<any>;
    responseHandler: (rootStore: any, remoteCmd: any, remoteCmdResult: AbstractVersionedRemoteResult) => Promise<any>;
};

export type QueueProcessingResult = 'DONE' | 'MORE' | 'ERROR';

export interface AbstractVersionedRemoteCommand {
    _cmdSvrClass: string;
    expoPushToken: string;
}

export interface AbstractVersionedRemoteResult {
    authenticated: boolean; //TODO on client if this is false then show an error and log out the user
}

export interface SimpleVersionedRemoteResult<T> extends AbstractVersionedRemoteResult {
    data: T;
    errorMsg: string;
    authenticated: boolean;
}

export const EMPTY_RESULT: any = {};

export type BusinessExceptionErrorDetail = {
    code: string;
    desc: string;
};

export type RemoteCmdCommunicationError = {
    errType: 'COMMUNICATION_ERR';
    httpStatus: number;
};

export type RemoteCmdBusinessError = {
    errType: 'BUSINESS_ERR';
    businessErrors: Array<BusinessExceptionErrorDetail>;
};

export type RemoteCmdServerError = {
    errType: 'SERVER_ERR';
    serverExceptionDetails: string;
};

export type RemoteCmdError = RemoteCmdBusinessError | RemoteCmdServerError | RemoteCmdCommunicationError;
