import React, { useEffect } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { globalStyles } from 'theme/style/GlobalStyles';
import Hyperlink from 'components/primitives/Hyperlink';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import SmartMoveMoveInChargesBreakdown from 'screens/smartmove/components/SmartMoveMoveInChargesBreakdown';
import { routeToCorrectStep, useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { SmartmovePaymentInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmovePaymentInfoV1';
import { addDaysToDate, formatDateMonthLabelFullNoYear, parseDateMMDDYYYY } from 'utils/DateUtils';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import SmartMoveCountdown from 'screens/smartmove/components/SmartMoveCountdown';
import { observer } from 'mobx-react-lite';
import PhoneLink from 'components/primitives/PhoneLink';

const SmartMoveMoveInCountdown: React.FC = observer(() => {
    const theme = useTheme();
    const isFocused = useIsFocused();
    const navigation = useNavigation();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    ///header
    const applicationHeader = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        null != sessionActiveResidency.propertyCd,
        uiStore,
        {
            enabled: hasSessionActiveResidency && sessionActiveResidency.statusCd === 'Leased',
            onSuccess: data => {
                if (isFocused) {
                    routeToCorrectStep(data, navigation, 'MoveInCountdown');
                }
            },
        },
    );
    const paymentInfoV1: SmartmovePaymentInfoV1 | undefined =
        applicationHeader.query.data?.smartmoveApplicationHeaderV1?.paymentInfoV1;

    const _editinfo = () => {
        navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveEditInformation);
    };

    const payRestrict = paymentInfoV1?.applyPaymentRestrictions;
    const isCurrentStep =
        applicationHeader.query.data?.smartmoveApplicationHeaderV1?.smartmoveStep === 'MoveInCountdown';
    const willPayLater = !payRestrict && isCurrentStep;
    const payThanks = paymentInfoV1?.moveInChargePaid;

    let canPayDate = '';
    if (willPayLater) {
        const dtCanPayDate = parseDateMMDDYYYY(sessionActiveResidency.expectMoveinDt);
        addDaysToDate(dtCanPayDate, -5);
        canPayDate = formatDateMonthLabelFullNoYear(dtCanPayDate);
    }

    useEffect(() => {
        if (applicationHeader.query.data?.smartmoveApplicationHeaderV1.workflowStatus === 'AppDone') {
            userSessionStore.reloadCurrentResidencyAsync(false).finally(() => {
                navigation.navigate(navigationRoutes.loggedInRoutes.loggedIn);
            });
        }
    }, [hasSessionActiveResidency, applicationHeader.query.data?.smartmoveApplicationHeaderV1.workflowStatus]);

    useEffect(() => {
        console.log('residency changed!!!' + hasSessionActiveResidency, sessionActiveResidency.residentId);
    }, [hasSessionActiveResidency]);

    if (
        (!hasSessionActiveResidency && paymentInfoV1 && !isCurrentStep && applicationHeader.query.isFetching) ||
        !applicationHeader.query.data ||
        !applicationHeader.query.isSuccess
    )
        return null;
    return (
        <SmartmoveScreenContainer
            progressIndicatorProps={{
                currentStep: 4,
                currentStepInProgress: true,
            }}
            alignBodyContentTop={true}
        >
            <View style={styles.peopleImageWrapper}>
                <Image style={styles.peopleImage} source={require('../../../assets/images/smartmove-people.png')} />
            </View>
            <SmartMoveCountdown
                fontStyle={'normal'}
                asOfDate={userSessionStore.propertySpecificTimeStamp}
                targetDate={sessionActiveResidency.expectMoveinDt}
                timezoneCd={applicationHeader.query?.data?.smartmoveApplicationHeaderV1.timezoneCd}
            />
            <View style={styles.callToAction}>
                {willPayLater && canPayDate && !payThanks && (
                    <RegularText>
                        Starting <RegularBoldText>{canPayDate}</RegularBoldText> you will be able to make an online
                        payment for the amount listed below:
                    </RegularText>
                )}
                {payThanks && <RegularText>Thank you for your payment!</RegularText>}
                {!payThanks && payRestrict && hasSessionActiveResidency && (
                    <>
                        <RegularText>
                            Please call us at <PhoneLink phoneNumber={sessionActiveResidency.propertyPhoneNumber} />{' '}
                            during business hours on or before{' '}
                            <RegularBoldText>
                                {formatDateMonthLabelFullNoYear(
                                    parseDateMMDDYYYY(sessionActiveResidency.expectMoveinDt),
                                )}
                            </RegularBoldText>{' '}
                            to make a payment arrangement for the amount listed below.
                        </RegularText>
                    </>
                )}
            </View>

            {!payThanks && isCurrentStep && <SmartMoveMoveInChargesBreakdown restricted={payRestrict} />}

            <View style={globalStyles.smartMoveBottomContent}>
                <Hyperlink style={globalStyles.centeredText} onClick={_editinfo}>
                    Want to make a change? Edit your information
                </Hyperlink>
            </View>
        </SmartmoveScreenContainer>
    );
});

export default SmartMoveMoveInCountdown;

const styles = StyleSheet.create({
    peopleImageWrapper: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 3,
        alignItems: 'center',
    },
    peopleImage: {
        width: 187,
        height: 111,
    },
    paragraphText: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    callToAction: {
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN * 2,
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN * 2,
        alignItems: 'center',
    },
});
