//fallback for use on the web

import AsyncStorage from '@react-native-async-storage/async-storage';
import { logInfo } from './logging/Logger';
import { INFO_LOGGER } from './logging/Loggers';
import { Callback } from '@react-native-async-storage/async-storage/lib/typescript/types';

export const getItem = (key: string): Promise<string | null> => {
    logInfo(INFO_LOGGER, `Getting value from key called ${key} in AsyncStorage for WEB...`);
    return AsyncStorage.getItem(key);
};

export const setItem = (key: string, value: string, callback?: Callback) => {
    logInfo(INFO_LOGGER, `Setting the value ${value} into key called ${key} in AsyncStorage for WEB...`, value);
    return AsyncStorage.setItem(key, value, callback);
};

export const removeItem = (key: string, callback?: Callback): Promise<void> => {
    return AsyncStorage.removeItem(key, callback);
};
