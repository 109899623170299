import * as React from 'react';
import { View, StyleSheet, Image, TouchableWithoutFeedback } from 'react-native';
import { RadioButton as RNPRadioButton } from 'react-native-paper';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { testProps } from 'components/ComponentTypes';
import { InputStyles } from 'theme/style/InputStyles';

export interface InputRadioOption {
    label: string;
    labelOverride?: any;
    value: string;
    testID?: string;
    accessibilityLabel?: string;
}

interface InputRadioProps {
    testID?: string;
    accessibilityLabel?: string;
    style?: 'block' | 'small';
    itemDisplay?: 'block' | 'inline';
    options?: Array<InputRadioOption>;
    initialValue?: string;
    onChange?: (status: string) => void;
    errorMessage?: string;
}

const defaultProps: InputRadioProps = {
    style: 'block',
    itemDisplay: 'block',
};

const InputRadio: React.FC<InputRadioProps> = (props: InputRadioProps) => {
    const _handleChange = (newValue: string) => {
        logInfo(INFO_LOGGER, newValue);

        if (props.onChange) {
            props.onChange(newValue);
        }
    };

    const groupStyle =
        props.itemDisplay === 'inline'
            ? [styles.inputRadioGroupCol, styles.inputRadioGroupColRow]
            : [styles.inputRadioGroupCol];

    return (
        <RNPRadioButton.Group
            onValueChange={newValue => {
                _handleChange(newValue);
            }}
            value={props.initialValue || ''}>
            <View style={groupStyle} testID={props.testID} accessibilityLabel={props.accessibilityLabel}>
                {props.options?.map((option, index) => {
                    let containerStyle = {};

                    if (props.style === 'block') {
                        containerStyle =
                            props.initialValue === option.value
                                ? [styles.inputRadioContainer, styles.inputRadioContainerSelected]
                                : [styles.inputRadioContainer];
                    } else {
                        containerStyle = [styles.inputRadioContainerSmall];
                    }

                    const inlineStyle = props.itemDisplay === 'inline' ? [styles.inputRadioContainerInline] : [];

                    return (
                        <View style={[containerStyle, inlineStyle]} key={`inputRadio${index}`}>
                            <View style={styles.inputRadioWrapper}>
                                <TouchableWithoutFeedback
                                    testID={option.testID}
                                    accessibilityLabel={option.accessibilityLabel}
                                    onPress={() => {
                                        _handleChange(option.value);
                                    }}>
                                    <View style={styles.inputRadio}>
                                        {props.initialValue === option.value ? (
                                            <Image
                                                {...testProps('generic-radio-selected-icon')}
                                                style={styles.imageRadio}
                                                source={require('../../../assets/images/radio-selected.png')}
                                            />
                                        ) : (
                                            <Image
                                                {...testProps('generic-radio-unselected-icon')}
                                                style={styles.imageRadio}
                                                source={require('../../../assets/images/radio.png')}
                                            />
                                        )}
                                        <View style={styles.inputRadioButton}>
                                            <RNPRadioButton value={option.value} />
                                        </View>
                                    </View>
                                </TouchableWithoutFeedback>
                                {!option.labelOverride && (
                                    <RegularText style={styles.inputRadioLabel}>{option.label}</RegularText>
                                )}
                                {!!option.labelOverride && option.labelOverride}
                            </View>
                        </View>
                    );
                })}
                {!!props.errorMessage && (
                    <RegularText style={InputStyles.inputErrorMessage}>{props.errorMessage}</RegularText>
                )}
            </View>
        </RNPRadioButton.Group>
    );
};

InputRadio.defaultProps = defaultProps;

export default InputRadio;

const styles = StyleSheet.create({
    inputRadioContainer: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: SmarthubTheme.layout.GRIDINCREMENT * 8,
        marginBottom: 8,
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.lightestgrey,
    },
    inputRadioContainerSmall: {
        minHeight: 0,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT * -3,
        borderWidth: 0,
        borderColor: SmarthubTheme.colors.transparent,
    },
    inputRadioContainerInline: {
        flex: 1,
    },
    inputRadioContainerSelected: {
        borderColor: SmarthubTheme.colors.selectedborder,
        backgroundColor: SmarthubTheme.colors.selected,
    },
    inputRadioGroupCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputRadioGroupColRow: {
        flexDirection: 'row',
    },
    inputRadioWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    inputRadio: {
        justifyContent: 'center',
        marginRight: SmarthubTheme.layout.GRIDINCREMENT * 2,
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
    inputRadioButton: {
        opacity: 0,
        width: 0,
        height: 0,
    },
    imageRadio: {
        width: 16,
        height: 16,
    },
    inputRadioLabel: {
        flex: 1,
    },
});
