import React, { useEffect, useRef } from 'react';
import { Platform, StyleSheet, View, ViewStyle } from 'react-native';
import { observer } from 'mobx-react-lite';
import LottieView from './LottieView';
import { globalStyles } from 'theme/style/GlobalStyles';

type OrangeCheckmarkProps = {
    lottieViewStyle?: ViewStyle;
};

const OrangeCheckmark: React.FC<OrangeCheckmarkProps> = observer((props: OrangeCheckmarkProps) => {
    const animation = useRef<LottieView>(null);

    const runAnimation = () => {
        if (null != animation.current) {
            //animation.current.play();
            // Or set a specific startFrame and endFrame with:
            animation.current.play(0, 32);
        }
    };

    useEffect(() => {
        runAnimation();
    }, []);

    return (
        <View style={[styles.animationContainer, globalStyles.doubleSpaceBelow]}>
            <LottieView
                loop={false}
                ref={animation}
                style={props.lottieViewStyle || {}}
                source={
                    Platform.OS === 'android'
                        ? require('../../../assets/lottiejson/orange_checkmark_animated_nocircle_android.json')
                        : require('../../../assets/lottiejson/orange_checkmark_animated_nocircle.json')
                }
            />
        </View>
    );
});

export default OrangeCheckmark;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    animationContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});
