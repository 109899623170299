import React, { useState } from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { MediumBoldText, RegularText, SmallBoldText, SmallText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { globalStyles } from 'theme/style/GlobalStyles';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import SmartMoveSignedCard from 'screens/smartmove/components/SmartMoveSignedCard';
import { TagTypes } from 'components/primitives/TagTypes';
import { useStore } from 'contexts/StoreContextProvider';
import Hyperlink from 'components/primitives/Hyperlink';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { useTheme } from 'react-native-paper';
import Dialog from 'components/primitives/Dialog';
import { testProps } from 'components/ComponentTypes';
import Button from 'components/primitives/Button';
import { useSmartmoveContractSigningInfoQuery } from 'queries/hooks/smartmove/useSmartmoveContractSigningInfoQuery';
import { useCommandMutation } from 'queries/hooks/useCommandQuery';
import { EmptyVersionedRemoteResult } from 'models/remotecmds/com/ocs/remotecmd/versioned/EmptyVersionedRemoteResult';
import { observer } from 'mobx-react-lite';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { routeToCorrectStep, useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import PhoneLink from 'components/primitives/PhoneLink';
import {
    createSendSmarthubContractSigningEmailCommandV1,
    SendSmarthubContractSigningEmailCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/contract/SendSmarthubContractSigningEmailCommandV1';

const SmartMoveContractReady: React.FC = observer(() => {
    const theme = useTheme();
    const isFocused = useIsFocused();

    const [sendEmailInfo, setSendEmailInfo] = useState({ email: '', viewId: '' });
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const navigation = useNavigation();

    ///header
    const { query: headerQuery, queryKey: headerQueryKey } = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
        {
            enabled: hasSessionActiveResidency,
            onSuccess: data => {
                if (isFocused) {
                    routeToCorrectStep(data, navigation, 'Contract', 'AppAwaitingSignatures');
                }
            },
        },
    );

    const { queryKey, queryResult } = useSmartmoveContractSigningInfoQuery(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        uiStore,
        {
            enabled: hasSessionActiveResidency,
        },
    );

    const sendEmailMutation = useCommandMutation<SendSmarthubContractSigningEmailCommandV1, EmptyVersionedRemoteResult>(
        uiStore,
    );

    if (!hasSessionActiveResidency || queryResult.isLoading || !queryResult.data) return null;

    return (
        <SmartmoveScreenContainer
            progressIndicatorProps={{
                currentStep: 2,
                currentStepInProgress: true,
            }}
            /*NO footer button*/
        >
            {!!sendEmailInfo.email && (
                <Dialog visible={!!sendEmailInfo.email}>
                    <View style={globalStyles.flexColumnCenter}>
                        <SmallText style={globalStyles.singleSpaceBelow}>
                            Your contract will be resent to the following address:
                        </SmallText>
                        <SmallBoldText style={globalStyles.singleSpaceBelow}>{sendEmailInfo.email}</SmallBoldText>
                        <SmallText style={globalStyles.sectionSpaceBelow}>
                            All applicants are required to digitally sign the contract to move forward. If this email
                            address is incorrect, please contact us at{' '}
                            <PhoneLink smallText={true} phoneNumber={sessionActiveResidency.propertyPhoneNumber} />{' '}
                        </SmallText>

                        <View style={styles.alertButtons}>
                            <View>
                                <Button
                                    buttonType={'secondary'}
                                    {...testProps('cancel-button')}
                                    onClick={() => {
                                        setSendEmailInfo({ email: '', viewId: '' });
                                    }}>
                                    No, go back
                                </Button>
                            </View>
                            <View>
                                <Button
                                    buttonType={'primary'}
                                    {...testProps('resend-button')}
                                    onClick={() => {
                                        setSendEmailInfo({ email: '', viewId: '' });
                                        const mutateCmd = createSendSmarthubContractSigningEmailCommandV1();
                                        mutateCmd.propertyCd = sessionActiveResidency.propertyCd;
                                        mutateCmd.residencyId = sessionActiveResidency.residencyId;
                                        mutateCmd.eSignerViewId = sendEmailInfo.viewId;

                                        sendEmailMutation.mutate(mutateCmd, {
                                            onSuccess: () => {
                                                uiStore.addToast({ message: `Your contract has been resent!` });
                                            },
                                        });
                                    }}>
                                    Yes, resend
                                </Button>
                            </View>
                        </View>
                    </View>
                </Dialog>
            )}

            <View style={[globalStyles.smartMoveIcon, globalStyles.sectionSpaceAround]}>
                <MaterialCommunityIcons
                    name={'check'}
                    size={theme.layout.EXTRALARGEICONSIZE}
                    color={SmarthubTheme.colors.orange}
                />
            </View>
            <MediumBoldText style={[globalStyles.smartMoveTitleText, globalStyles.centeredText]}>
                Your contract has been sent
            </MediumBoldText>
            <RegularText style={[globalStyles.centeredText, globalStyles.smartMoveParagraphText]}>
                Please advise all applicants to check their email for a link to their contract. You and all other
                applicants will need to digitally sign the contract to move forward.
            </RegularText>
            <RegularText style={globalStyles.centeredText}>
                If you did not receive an email, use the link below to send it again or contact the property at{' '}
                <PhoneLink phoneNumber={sessionActiveResidency.propertyPhoneNumber} />.
            </RegularText>

            <View style={[styles.signedCards, globalStyles.sectionSpaceAbove]}>
                {queryResult.data.signingInfo.signers.map(signer => (
                    <SmartMoveSignedCard
                        key={signer.viewId}
                        titleText={signer.name}
                        tagText={signer.signingStatus === 'Signed' ? 'SIGNED' : 'NOT SIGNED YET'}
                        tagType={signer.signingStatus === 'Signed' ? TagTypes.DarkGrey : TagTypes.Blue}
                        linkText={signer.signingStatus !== 'Signed' && signer.email ? 'Resend Email' : undefined}
                        onLinkClick={() => setSendEmailInfo({ email: signer.email, viewId: signer.viewId })}
                    />
                ))}
            </View>
        </SmartmoveScreenContainer>
    );
});

export default SmartMoveContractReady;

const styles = StyleSheet.create({
    signedCards: {
        borderTopWidth: 1,
        borderTopColor: SmarthubTheme.colors.lightestgrey,
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    alertButtons: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
});
