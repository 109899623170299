import { AbstractAwayTimeAuthenticatedCommand } from '../AbstractAwayTimeAuthenticatedCommand';
import { GetAddEditAwayTimeDataResultV1 } from './GetAddEditAwayTimeDataResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetAddEditAwayTimeDataCommandV1
 */
export interface GetAddEditAwayTimeDataCommandV1
    extends AbstractAwayTimeAuthenticatedCommand<GetAddEditAwayTimeDataResultV1> {
    residencyId: number;
}

export function createGetAddEditAwayTimeDataCommandV1(): GetAddEditAwayTimeDataCommandV1 {
    return {
        residencyId: 0,
        propertyCd: '',
        residentAwayTimeId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetAddEditAwayTimeDataCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
