import { useEffect, useState } from 'react';
import { showOptInOutFeatureHighlight } from 'utils/appTeasers';
import { useStore } from 'contexts/StoreContextProvider';

const useShowOptInOutFeatureHighlight = () => {
    const { userSessionStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const [showOptInOptOutFeatureHighlight, setShowOptInOptOutFeatureHighlight] = useState(false);

    useEffect(() => {
        (async () => {
            if (
                userSessionStore.isFeatureEnabled('OptInOut') &&
                (sessionActiveResidency.statusCd === 'Current' ||
                    sessionActiveResidency.statusCd === 'Notice' ||
                    sessionActiveResidency.statusCd === 'TransferOut') &&
                hasSessionActiveResidency
            ) {
                const showOIOOFeatureHighlight = await showOptInOutFeatureHighlight(
                    sessionActiveResidency.residentId.toString(),
                );
                setShowOptInOptOutFeatureHighlight(showOIOOFeatureHighlight);
            }
        })();
    }, [sessionActiveResidency.residencyId]);

    return {
        showOptInOptOutFeatureHighlight: showOptInOptOutFeatureHighlight,
        setShowOptInOptOutFeatureHighlight: setShowOptInOptOutFeatureHighlight,
    };
};

export default useShowOptInOutFeatureHighlight;
