import { RootStore } from '../RootStore';
import { action, makeAutoObservable } from 'mobx';
import { BackHandler, NativeEventSubscription } from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import NetInfo, { NetInfoState } from '@react-native-community/netinfo';
import type { ToastInterface } from 'components/primitives/Toaster';
import type { TooltipOverlayConfig } from 'components/primitives/TooltipOverlay';
import type { AlertConfig } from 'components/primitives/Alert';
import { FeedbackDialogProps } from 'utils/errorhandling/FeedbackDialog';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { setItem } from 'utils/storage';
import { STORAGE_KEY_DARK_THEME_ENABLED } from 'utils/storage-keys';

export interface ToastWithID extends ToastInterface {
    toastId: string;
}

export class UIStore {
    rootStore: RootStore;

    authenticationReady = false;

    onboardingComplete = false;

    useDarkTheme = false;

    activityLoaderVisible = false;
    activityLoaderMessage: string | null = null;

    toastQueue: Array<ToastWithID> = [];
    currentToast: ToastWithID | null = null;
    currentInterval: ReturnType<typeof setTimeout> | null = null;

    tooltip: TooltipOverlayConfig | null = null;

    alert: AlertConfig | null = null;

    state: NetInfoState | undefined;

    overlayVisible = false;

    showLoginEmailMissing = false;

    feedbackDialog: FeedbackDialogProps | null = null;

    hardwareBackButtonEventSubs: NativeEventSubscription | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        NetInfo.addEventListener(state => {
            this.state = state;
        });
        makeAutoObservable(this);
    }

    get isInternetReachable() {
        return this.state && this.state.isInternetReachable;
    }

    setAuthenticationReady = action((authReady: boolean) => {
        this.authenticationReady = authReady;
    });

    setOnboardingComplete = action((onboarded: boolean) => {
        this.onboardingComplete = onboarded;
    });

    toggleDarkTheme = action(() => {
        logInfo(INFO_LOGGER, 'Dark theme was ' + (this.useDarkTheme ? 'On' : 'Off'));
        this.useDarkTheme = !this.useDarkTheme;
        setItem(STORAGE_KEY_DARK_THEME_ENABLED, this.useDarkTheme ? 'true' : 'false');
        logInfo(INFO_LOGGER, 'Dark theme is now ' + (this.useDarkTheme ? 'On' : 'Off'));
    });

    preventHardwareBackButtonClick = () => {
        //do nothing, return true to stop bubble up events
        return true;
    };

    showActivityLoader = action((message?: string) => {
        this.hardwareBackButtonEventSubs = BackHandler.addEventListener(
            'hardwareBackPress',
            this.preventHardwareBackButtonClick,
        );
        this.activityLoaderVisible = true;
        this.activityLoaderMessage = message || null;
    });

    hideActivityLoader = action(() => {
        if (this.hardwareBackButtonEventSubs) {
            this.hardwareBackButtonEventSubs.remove();
        }
        this.activityLoaderVisible = false;
    });

    addToast = action((toast: ToastInterface) => {
        const toastId = uuidv4();
        const newToast: ToastWithID = {
            toastId: toastId,
            ...toast,
        };
        this.toastQueue.push(newToast);

        if (this.toastQueue.length === 1) {
            this.nextToast();
        }
    });

    nextToast = action(() => {
        if (this.currentInterval != null) {
            clearInterval(this.currentInterval);
            this.currentInterval = null;

            this.removeToast();
        } else {
            this.currentToast = this.toastQueue[0];

            if (this.currentToast.autoDismiss) {
                this.currentInterval = setTimeout(
                    () => {
                        this.removeToast();
                    },
                    this.currentToast.autoDismissInterval
                        ? this.currentToast.autoDismissInterval
                        : SmarthubTheme.layout.DEFAULTMESSAGEINTERVAL,
                );
            }
        }
    });

    dismissToast = action(() => {
        if (this.currentInterval) {
            clearInterval(this.currentInterval);
        }
        this.removeToast();
    });

    removeToast = action(() => {
        this.toastQueue = this.toastQueue.filter(toast => toast.toastId !== this.currentToast?.toastId);

        if (this.toastQueue.length > 0) {
            this.nextToast();
        } else {
            this.currentToast = null;
            this.currentInterval = null;
        }
    });

    showTooltip = action((props: TooltipOverlayConfig) => {
        this.tooltip = props;
    });

    hideTooltip = action(() => {
        this.tooltip = null;
    });

    showAlert = action((props: AlertConfig) => {
        this.alert = props;
    });

    hideAlert = action(() => {
        this.alert = null;
    });

    showFeedbackDialog = action((props: FeedbackDialogProps) => {
        this.feedbackDialog = props;
    });

    hideFeedbackDialog = action(() => {
        this.feedbackDialog = null;
    });

    showOverlay = action(() => {
        this.overlayVisible = true;
    });

    hideOverlay = action(() => {
        this.overlayVisible = false;
    });

    setShowLoginEmailMissing = action((value: boolean) => {
        this.showLoginEmailMissing = value;
    });

    /*checkOnboardingComplete = async () => {
        if (Platform.OS !== 'web') {
            const storageKey = await getItem(STORAGE_KEY_ONBOARDING_COMPLETE);
            if (!storageKey) {
                logInfo(INFO_LOGGER, 'Onboarding required');
                this.setOnboardingComplete(false);
            } else {
                logInfo(INFO_LOGGER, 'Onboarding already done');
                this.setOnboardingComplete(true);
            }
        } else {
            logInfo(INFO_LOGGER, 'Onboarding already done');
            this.setOnboardingComplete(true);
        }
    };*/
}
