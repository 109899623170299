import React, { useEffect } from 'react';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import { routeUser, useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { navigationRoutes } from 'utils/NavigationUtils';
import { SmartmoveApplicationHeaderV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveApplicationHeaderV1';
import PaymentReview from 'components/payments/PaymentReview';
import { View } from 'react-native';
import { useZegoPaymentMutation } from 'queries/hooks/useZegoPaymentMutation';
import { useQueryClient } from 'react-query';
import { logError } from 'utils/logging/Logger';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import {
    buildZegoPaymentInfoFromFormValues,
    errorContainsIneligibleDebitCardErrorMessage,
    getProcessingFee,
    getProcessingFeePercentage,
} from 'utils/PayLeaseZegoUtils';
import { SubmitZegoPaymentResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitZegoPaymentResultV1';
import { getRouteParam } from 'utils/routeUtils';
import { generatePaymentMethodsQueryKey } from 'queries/hooks/usePaymentMethods';
import { useCheckPaymentStatus } from 'queries/hooks/useCheckPaymentStatus';

const SmartMovePaymentReview: React.FC = observer(() => {
    const navigation = useNavigation();
    const route = useRoute();
    const isFocused = useIsFocused();
    const { userSessionStore, uiStore, transactionsStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const [submittingPayment, setSubmittingPayment] = React.useState<boolean>(false);
    const [submitPaymentResult, setSubmitPaymentResult] = React.useState<SubmitZegoPaymentResultV1 | undefined>(
        undefined,
    );
    const enableCheckPaymentStatus =
        !!submitPaymentResult &&
        !!submitPaymentResult.paymentResult &&
        submitPaymentResult.paymentResult.status === 'Pending';
    const { queryKey: checkPaymentStatusQueryKey, query: checkPaymentStatusQuery } = useCheckPaymentStatus(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residentId,
        submitPaymentResult?.paymentResult.paymentRefId || '',
        submitPaymentResult?.paymentResult.paymentRequestId || 0,
        enableCheckPaymentStatus,
        uiStore,
        {
            enabled: enableCheckPaymentStatus,
            refetchInterval: 5000,
            retry: 24,
            onSuccess: (data: SubmitZegoPaymentResultV1) => {
                if (data.paymentResult.status === 'Success' || data.paymentResult.status === 'Fail') {
                    _processNonPendingPayment(data);
                    setSubmitPaymentResult(undefined);
                }
            },
            onError: error => {
                setSubmittingPayment(false);
                logError(ERROR_LOGGER, error);
            },
        },
    );

    const queryClient = useQueryClient();
    const paymentFormValues = getRouteParam(route, 'paymentFormValues');
    const paymentMethodQueryKey = generatePaymentMethodsQueryKey(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residentId,
    );

    ///header
    const applicationHeader = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
        {
            onSuccess: data => {
                if (!paymentFormValues || !paymentFormValues.payByMethod) {
                    if (isFocused) {
                        routeUser(
                            data.smartmoveApplicationHeaderV1.smartmoveStep,
                            data.smartmoveApplicationHeaderV1.workflowStatus,
                            navigation,
                        );
                    }
                }
            },
        },
    );
    const isLoadingApplication = applicationHeader?.query.isLoading;
    const header: SmartmoveApplicationHeaderV1 | undefined = applicationHeader.query.data?.smartmoveApplicationHeaderV1;

    const zegoPaymentMutation = useZegoPaymentMutation(uiStore, sessionActiveResidency);

    const zegoPaymentInfo = buildZegoPaymentInfoFromFormValues(
        paymentFormValues,
        transactionsStore.uniquePaymentReferenceId,
        sessionActiveResidency.payleaseAccount,
        header?.smartmoveStep === 'MoveInPayment' ? false : true,
    );

    const _processNonPendingPayment = (result: SubmitZegoPaymentResultV1) => {
        if (result.paymentResult.status === 'Pending') return;
        queryClient.invalidateQueries(applicationHeader.queryKey);
        if (result.paymentResult.status === 'Success') {
            navigation.navigate(
                header?.smartmoveStep === 'MoveInPayment'
                    ? navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationPaymentReceivedMoveInPayment
                    : navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationPaymentReceivedApartmentReserved,
            );
        } else if (result.paymentResult.status === 'Fail') {
            navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationUnsuccessful, {
                paymentFailMessage: result.paymentResult.failMessage,
            });
        }
        queryClient.invalidateQueries(paymentMethodQueryKey);
        transactionsStore.setUniquePaymentReferenceId();
        setSubmittingPayment(false);
    };

    const _submitPayment = () => {
        if (zegoPaymentInfo && zegoPaymentInfo.payByMethod) {
            const _onSuccess = (result: SubmitZegoPaymentResultV1) => {
                if (result.paymentResult.status === 'Pending') {
                    setSubmitPaymentResult(result);
                } else {
                    _processNonPendingPayment(result);
                }
            };

            const _onError = (error: string) => {
                setSubmittingPayment(false);
                if (errorContainsIneligibleDebitCardErrorMessage(error)) {
                    navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationUnsuccessful, {
                        paymentFailMessage:
                            'The payment information you have entered is for a credit card, not a debit card, please review your payment information and try again.',
                    });
                } else {
                    logError(ERROR_LOGGER, error);
                    navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationUnsuccessful);
                }
            };

            setSubmittingPayment(true);

            zegoPaymentMutation.mutate(zegoPaymentInfo, {
                onSuccess: _onSuccess,
                onError: error => _onError('Encounter error when making payment - ' + JSON.stringify(error)),
            });
        }
    };

    useEffect(() => {
        submittingPayment ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [submittingPayment, uiStore.activityLoaderVisible]);

    return isLoadingApplication || !zegoPaymentInfo || !zegoPaymentInfo.paymentMethod ? null : (
        <SmartmoveScreenContainer
            showBack={true}
            footerButtonProps={{
                text: 'Submit Payment',
                onClick: _submitPayment,
                disabled: submittingPayment,
            }}>
            <View>
                <PaymentReview
                    amount={zegoPaymentInfo.amount || 0}
                    paymentMethod={zegoPaymentInfo.paymentMethod}
                    fee={getProcessingFee(zegoPaymentInfo)}
                    feePercentage={getProcessingFeePercentage(zegoPaymentInfo)}
                />
            </View>
        </SmartmoveScreenContainer>
    );
});

export default SmartMovePaymentReview;
