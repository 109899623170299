export type SmarthubTransactionType =
    | 'RecentBilling'
    | 'Statement'
    | 'FinalStatement'
    | 'CreditCardPayment'
    | 'ACHPayment'
    | 'Check'
    | 'MoneyOrder'
    | 'BalanceUpdate';

export type NullableSmarthubTransactionType = SmarthubTransactionType | null;

export const SmarthubTransactionTypeOptions = [
    { value: 'RecentBilling', label: 'Recent Billing' },
    { value: 'Statement', label: 'Statement' },
    { value: 'FinalStatement', label: 'Final Statement' },
    { value: 'CreditCardPayment', label: 'Credit Card Payment' },
    { value: 'ACHPayment', label: 'A C H Payment' },
    { value: 'Check', label: 'Check' },
    { value: 'MoneyOrder', label: 'Money Order' },
    { value: 'BalanceUpdate', label: 'Balance Update' },
];

export const SmarthubTransactionTypeOptionsWithBlank = [{ value: '', label: '' }].concat(
    SmarthubTransactionTypeOptions,
);

/* START CUSTOM CODE */
export const isPaymentTransaction = (type?: SmarthubTransactionType): boolean => {
    return (
        null != type &&
        (type === 'ACHPayment' || type === 'CreditCardPayment' || type === 'Check' || type === 'MoneyOrder')
    );
};
export const isStatementTransaction = (type?: SmarthubTransactionType): boolean => {
    return null != type && (type === 'Statement' || type === 'FinalStatement');
};
export const isCreditCardTransaction = (type?: SmarthubTransactionType): boolean => {
    return null != type && type === 'CreditCardPayment';
};
export const isCheckOrMoneyOrder = (type?: SmarthubTransactionType): boolean => {
    return null != type && (type === 'Check' || type === 'MoneyOrder');
};
/* END CUSTOM CODE */
