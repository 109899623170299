import React, { useEffect } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { DeephansizedRegularText, RegularText } from 'components/primitives/StyledText';
import { useTheme } from 'react-native-paper';
import Button from 'components/primitives/Button';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { StackNavigationProp } from '@react-navigation/stack';
import { AutoPayDetails } from 'screens/home/transactions/AutoPayDetails';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useGetAutopays } from 'queries/hooks/home/TransactionsHooks';

type AutoPayProps = {
    navigation: StackNavigationProp<any>;
};

const AutoPay: React.FC<AutoPayProps> = observer(({ navigation }: AutoPayProps) => {
    const theme = useTheme();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, confirmActiveResidency, sessionActiveResidency } = userSessionStore;
    const { useDrawerNavigation } = useResponsiveContext();

    const autopaysQuery = useGetAutopays();

    useEffect(() => {
        confirmActiveResidency(navigation);
        if (hasSessionActiveResidency && autopaysQuery.data) {
            const numautopays = autopaysQuery.data.autoPays.length;
            if (numautopays > 0) {
                navigation.setParams({ title: 'AutoPay (' + numautopays + ' active)' });
            }
        }
    }, [autopaysQuery.data, sessionActiveResidency.key]);

    useEffect(() => {
        if (autopaysQuery.isFetching || autopaysQuery.isIdle) {
            uiStore.showActivityLoader();
        } else {
            uiStore.hideActivityLoader();
        }
    }, [autopaysQuery.isFetching, autopaysQuery.isIdle]);

    const proceed = (replace?: boolean) => {
        logInfo(INFO_LOGGER, 'proceeding to zego screen...');
        const autopayOpts = {
            navigateTo:
                sessionActiveResidency.onAutoPay || userSessionStore.autoPaySetupPending
                    ? 'autopay'
                    : 'autopay_payment',
            title:
                sessionActiveResidency.onAutoPay || userSessionStore.autoPaySetupPending
                    ? 'Manage AutoPay'
                    : 'Set Up AutoPay',
        };
        userSessionStore.setAutoPaySetupPending(true);
        if (useDrawerNavigation) {
            navigation.navigate(navigationRoutes.homeRoutes.paymentScreen, autopayOpts);
        } else {
            navigation.navigate(navigationRoutes.loggedInRoutes.paymentScreenModal, autopayOpts);
        }
    };

    if (!autopaysQuery.data || !autopaysQuery.isSuccess || !hasSessionActiveResidency) return null;

    return (
        <SafeAreaView style={[globalStyles.justflex1, { backgroundColor: theme.colors.background }]}>
            <KeyboardAwareScrollView>
                <View style={styles.innercontainer}>
                    {!sessionActiveResidency.onAutoPay && (
                        <>
                            <RegularText style={globalStyles.sectionSpaceAround}>
                                {`You don't have AutoPay set up.`}{' '}
                            </RegularText>
                            {/*<RegularText>{`Set up AutoPay and save 3% ($43.50) on your monthly bill!`} PIGGY BANK ICON!</RegularText>*/}
                        </>
                    )}
                    {sessionActiveResidency.onAutoPay &&
                        autopaysQuery.data.autoPays.length > 0 &&
                        autopaysQuery.data.autoPays.map(autopay => {
                            return (
                                <View key={autopay.transactionId + autopay.payleaseAccount}>
                                    <AutoPayDetails
                                        navigation={navigation}
                                        autopay={autopay}
                                        setupLinkHandler={proceed}
                                    />
                                </View>
                            );
                        })}
                    <View style={[globalStyles.sectionSpaceAround, styles.horizontalmargin]}>
                        <DeephansizedRegularText>
                            To turn AutoPay off, you must remove all of your current AutoPays
                        </DeephansizedRegularText>
                    </View>
                    <View style={[globalStyles.sectionSpaceAround, styles.horizontalmargin]}>
                        <Button onClick={proceed} {...testProps('setup-autopay-proceed')}>
                            {sessionActiveResidency.onAutoPay ? 'Manage AutoPay' : 'Set Up AutoPay'}
                        </Button>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
});

export default AutoPay;

const styles = StyleSheet.create({
    innercontainer: {
        padding: SmarthubTheme.layout.SURROUNDMARGIN,
        flexDirection: 'column',
        width: '100%',
    },
    surface: {
        padding: SmarthubTheme.layout.INNERPAD,
        width: '100%',
        elevation: SmarthubTheme.layout.SURFACEELEVATION,
    },
    button: {
        alignSelf: 'center',
        width: '92%',
    },
    closeButton: {
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    datarow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    nopayallowed: {
        padding: SmarthubTheme.layout.INNERPAD,
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    contactDetailsWrapper: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contactDetailsItem: {
        //flex: 1,
        //marginTop: 0,
    },
    contactDetailsItemIcon: {
        maxWidth: 30,
        //marginTop: -3,
    },
    horizontalmargin: {
        marginHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});
