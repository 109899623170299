/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.smartmove.SmartmoveVehicleV1
 */
export interface SmartmoveVehicleV1 {
    vehicleId: number;
    vehicleMake: string;
    vehicleLicensePlate: string;
    vehicleLicensePlateState: string;
    vehicleOwnerResidentId: number;
    updatedDtt: number;
}

export function createSmartmoveVehicleV1(): SmartmoveVehicleV1 {
    return {
        vehicleId: 0,
        vehicleMake: '',
        vehicleLicensePlate: '',
        vehicleLicensePlateState: '',
        vehicleOwnerResidentId: 0,
        updatedDtt: -1,
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
