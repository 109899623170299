import * as React from 'react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { DeephansizedRegularText, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import { SafeAreaView } from 'react-native-safe-area-context';
import { WebView, WebViewMessageEvent, WebViewNavigation } from 'react-native-webview';
import { useAuthentication } from 'contexts/AuthContextProvider';
import { GetCommunityNoticesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/notifications/GetCommunityNoticesResultV1';
import { NavigationProp } from '@react-navigation/core/lib/typescript/src/types';
import { ParamListBase } from '@react-navigation/routers';
import useBackOnDisplay from 'utils/useBackOnDisplay';
import { WebViewErrorEvent, WebViewHttpErrorEvent } from 'react-native-webview/lib/WebViewTypes';
import { globalStyles } from 'theme/style/GlobalStyles';
import * as SentryHelper from 'utils/logging/SentryHelper';
import * as WebBrowser from 'expo-web-browser';
import { useIsFocused } from '@react-navigation/native';
import { getEncasaServer, getSmarthubRPCHandler } from 'utils/AppConfigHelper';
import {
    generateMarkNotificationsAsSeenByTypeCMD,
    typeHasUnseenNotifications,
    useMarkNotificationTypeAsSeenMutation,
    useSmarthubNotifications,
} from 'screens/home/requests/NotificationsHooks';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';
import { SmarthubNotificationTypeV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationTypeV1';

type IFrameWindow = {
    getNotices: (payload: string) => void;
} & Window;

type WebViewRef = {
    injectJavaScript: (functionCall: string) => void;
    reload: () => void;
} & MutableRefObject<null>;

const Notices: React.FC<NavigationProp<ParamListBase>> = observer((navigation: NavigationProp<ParamListBase>) => {
    const theme = useTheme();
    const { userSessionStore, communityNoticesStore, uiStore } = useStore();
    const { hasSessionActiveResidency, confirmActiveResidency, sessionActiveResidency } = userSessionStore;
    const isBackOnDisplay = useBackOnDisplay(true, true);

    const { user } = useAuthentication();
    const [hasError, setHasError] = useState(false);
    const [hasHttpError, setHasHttpError] = useState(false);
    const [iframeCounter, setIframeCounter] = useState(0);
    const [webViewPageTitle, setWebViewPageTitle] = useState('');
    const [initialLoad, setInitialLoad] = useState(true);
    const webRef: MutableRefObject<null> = useRef(null);

    const isFocused = useIsFocused();

    const ENCASA_URL = getEncasaServer();
    const RPC_URL = getSmarthubRPCHandler();

    const notificationType: SmarthubNotificationTypeV1 = 'CommunityNotice';
    const notifications: SmarthubNotificationV1[] = useSmarthubNotifications()?.data?.notifications || [];
    const hasUnseenNotices = typeHasUnseenNotifications(notificationType, notifications);
    const markNotificationTypeAsSeen = useMarkNotificationTypeAsSeenMutation(notificationType);

    useEffect(() => {
        setHasHttpError(false);
        if (Platform.OS === 'web') {
            return () => {
                communityNoticesStore.startIndex = 0;
                communityNoticesStore.communityNotices = [];
                window.removeEventListener('message', loadMoreRowsIFrame);
            };
        }
    }, []);

    useEffect(() => {
        //when we focus back on the screen, just reset everything back to original state
        if (!communityNoticesStore.hasNotices) {
            communityNoticesStore.startIndex = 0;
            communityNoticesStore.communityNotices = [];
            communityNoticesStore.setHasNotices(true);
            if (Platform.OS !== 'web' && !initialLoad) {
                appendDataToWebView();
                (webRef.current as WebViewRef | null)?.reload();
            } else {
                setIframeCounter(prevState => prevState + 1);
            }
        }
    }, [isFocused]);

    useEffect(() => {
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        confirmActiveResidency(navigation);
        if (hasSessionActiveResidency && user && Platform.OS !== 'web') {
            setHasError(false);
            //tell the webview to reload once we leave the app and come back
            if (isBackOnDisplay && communityNoticesStore.hasNotices && !initialLoad) {
                communityNoticesStore.startIndex = 0;
                communityNoticesStore.communityNotices = [];
                appendDataToWebView();

                (webRef.current as WebViewRef | null)?.reload();
            }
        }
    }, [sessionActiveResidency, isBackOnDisplay]);

    const appendDataToWebView = () => {
        if (hasSessionActiveResidency && user) {
            uiStore.showActivityLoader();
            communityNoticesStore
                .getCommunityNotices(user)
                .then((result: GetCommunityNoticesResultV1 | void) => {
                    if (result && result.communityNotices && result.communityNotices.length > 0) {
                        (webRef.current as WebViewRef | null)?.injectJavaScript(
                            `getNotices(${JSON.stringify(result.communityNotices)})`,
                        );
                    }
                })
                .finally(() => {
                    markNotificationsSeenInNotices();
                    setHasError(false);
                });
        }
    };

    const loadMoreRowsIFrame = (e: MessageEvent | null) => {
        if (user && hasSessionActiveResidency) {
            if (e && e.data !== 'loadRows') {
                //don't open new browser if it is not a valid url
                try {
                    new URL(e.data);
                    WebBrowser.openBrowserAsync(e.data, { windowFeatures: { height: 1024, width: 1280 } });
                } catch (ex) {
                    return;
                }
            } else {
                uiStore.showActivityLoader();
                if (communityNoticesStore.communityNotices.length > 0) communityNoticesStore.addMoreRows();
                communityNoticesStore
                    .getCommunityNotices(user)
                    .then((result: GetCommunityNoticesResultV1 | void) => {
                        if (result && result.communityNotices && result.communityNotices.length > 0) {
                            appendDataToIframe(JSON.stringify(result.communityNotices));
                        }
                    })
                    .finally(() => {
                        markNotificationsSeenInNotices();
                    });
            }
        }
    };

    const markNotificationsSeenInNotices = () => {
        if (hasUnseenNotices) {
            markNotificationTypeAsSeen.mutate(
                generateMarkNotificationsAsSeenByTypeCMD(
                    sessionActiveResidency.propertyCd,
                    sessionActiveResidency.residencyId,
                    sessionActiveResidency.residentId,
                    notificationType,
                ),
            );
        }
        uiStore.hideActivityLoader();
    };

    const captureWebViewMessage = (event: WebViewMessageEvent) => {
        const messageData = event.nativeEvent.data;
        if (messageData === 'loadRows') {
            if (
                communityNoticesStore.communityNotices.length > 0 &&
                (webViewPageTitle === '' || webViewPageTitle === 'Encasa')
            ) {
                logInfo(INFO_LOGGER, 'MESSAGE');
                communityNoticesStore.addMoreRows();
                appendDataToWebView();
            }
        } else {
            // we open a PDF for attachments, we pass the url of the pdf in the message!
            try {
                const url = new URL(messageData);
                WebBrowser.openBrowserAsync(url.toString()).catch(reason => {
                    logError(ERROR_LOGGER, 'Can not open url ' + messageData, JSON.stringify(reason));
                });
            } catch (e) {
                logError(ERROR_LOGGER, 'Can not convert ' + messageData + ' to url', JSON.stringify(e));
            }
        }
    };

    if (!hasSessionActiveResidency) {
        return (
            <SafeAreaView style={[styles.container, { backgroundColor: theme.colors.background }]}>
                <RegularText>No lease selected</RegularText>
            </SafeAreaView>
        );
    }
    if (!communityNoticesStore.hasNotices && !uiStore.activityLoaderVisible) {
        return (
            <SafeAreaView style={[styles.noNotices, { backgroundColor: theme.colors.background }]}>
                <View style={styles.centertext}>
                    <DeephansizedRegularText>{'There are no community notices at the moment'}</DeephansizedRegularText>
                    <DeephansizedRegularText style={globalStyles.doubleSpaceAbove}>
                        {'Check back soon!'}
                    </DeephansizedRegularText>
                </View>
            </SafeAreaView>
        );
    }

    const appendDataToIframe = (commNoticeStr: string) => {
        const iframe: HTMLIFrameElement | null = document.getElementById('iframe') as HTMLIFrameElement;
        const contentWindow: Window | null = iframe?.contentWindow;
        try {
            (contentWindow as IFrameWindow)?.postMessage(`getNotices?payload=${commNoticeStr}`, '*');
        } catch (error) {
            if (error) {
                console.log(error, 'ERROR');
                SentryHelper.captureException(error);
                logInfo(ERROR_LOGGER, 'An error has occured appending data to a iframe', error);
            }
            setHasHttpError(true);
        }
    };

    if (hasHttpError) {
        logInfo(ERROR_LOGGER, 'An HTTP error has occured while attempting to load community notices');
        throw 'An HTTP error has occured while attempting to load community notices';
    }

    return Platform.OS === 'web' ? (
        <>
            <iframe
                title='community_notices'
                id={'iframe'}
                key={iframeCounter}
                src={`${ENCASA_URL}SmarthubNotices.html?propertyCd=${userSessionStore.sessionActiveResidency.propertyCd}`}
                height={'100%'}
                onLoad={() => {
                    loadMoreRowsIFrame(null);
                    window.addEventListener('message', loadMoreRowsIFrame);
                }}
                width={'100%'}
                marginHeight={0}
                marginWidth={0}
                frameBorder={0}
                loading={'eager'}
            />
        </>
    ) : (
        <>
            {!hasError && !hasHttpError && (
                <WebView
                    source={{
                        uri: `${ENCASA_URL}SmarthubNotices.html?propertyCd=${userSessionStore.sessionActiveResidency.propertyCd}`,
                    }}
                    ref={webRef}
                    cacheEnabled={false}
                    onMessage={(event: WebViewMessageEvent) => {
                        captureWebViewMessage(event);
                    }}
                    onNavigationStateChange={(event: WebViewNavigation) => {
                        setWebViewPageTitle(event.title);
                    }}
                    javaScriptEnabled={true}
                    mixedContentMode={'compatibility'}
                    onLoadEnd={() => {
                        if (initialLoad) {
                            communityNoticesStore.startIndex = 0;
                            communityNoticesStore.communityNotices = [];
                            appendDataToWebView();
                            (webRef.current as WebViewRef | null)?.reload();
                            setInitialLoad(false);
                        }
                    }}
                    onError={(error: WebViewErrorEvent) => {
                        logInfo(INFO_LOGGER, 'ERRORS');
                        communityNoticesStore.startIndex = 0;
                        console.log('ERROR!', error);
                        setHasError(true);
                        uiStore.hideActivityLoader();
                    }}
                    renderError={(errorDomain: string | undefined, errorCode: number, errorDesc: string) => {
                        logInfo(INFO_LOGGER, 'ERROR ' + errorCode + errorDomain + errorDesc);
                        return <View></View>;
                    }}
                    onHttpError={(event: WebViewHttpErrorEvent) => {
                        if (event) {
                            logInfo(ERROR_LOGGER, event);
                        }
                        setHasHttpError(true);
                    }}
                />
            )}
        </>
    );
});

export default Notices;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
        margin: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    noNotices: {
        flex: 1,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    centertext: { alignItems: 'center', justifyContent: 'center' },
});
