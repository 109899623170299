import React from 'react';
import { observer } from 'mobx-react-lite';
import { Image, StyleSheet, View } from 'react-native';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { NavigationProp, ParamListBase, useNavigation } from '@react-navigation/native';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { globalStyles } from 'theme/style/GlobalStyles';
import { RegularBoldText, RegularText } from 'components/primitives/StyledText';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { SmartmoveApplicationHeaderV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveApplicationHeaderV1';

export const openSmartMove = (useDrawerNavigation: boolean, navigation: NavigationProp<ParamListBase>) => {
    useDrawerNavigation
        ? navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveApplication)
        : navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveApplication);
};

const SmartMoveApplication: React.FC = observer(() => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const navigation = useNavigation();

    const { query, routeUser } = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );
    const { data, isLoading } = query;

    const header: SmartmoveApplicationHeaderV1 | undefined = data?.smartmoveApplicationHeaderV1;

    if (!isLoading && header) {
        const { smartmoveStep, workflowStatus } = header;

        if (smartmoveStep && workflowStatus && navigation) {
            const firstVisit = smartmoveStep === 'ReservationFeePayment';

            return (
                <SmartmoveScreenContainer
                    /*No progress indicator*/
                    footerButtonProps={{
                        text: (firstVisit ? 'Start' : 'Continue') + ' SMARTMOVE',
                        onClick: () => routeUser(smartmoveStep, workflowStatus, navigation),
                    }}>
                    <View style={[styles.smartmoveLogoWrapper]}>
                        <Image
                            style={styles.smartmoveLogo}
                            source={require('../../../assets/images/smartmove-logo-transparent.png')}
                        />
                    </View>
                    {firstVisit && (
                        <>
                            <RegularText style={[globalStyles.centeredText, globalStyles.smartMoveParagraphText]}>
                                Welcome to SMART<RegularBoldText>MOVE</RegularBoldText>, the quickest and easiest way to
                                move in to your new place!
                            </RegularText>
                            <RegularText style={[globalStyles.centeredText, globalStyles.smartMoveParagraphText]}>
                                In the next {header.numberOfSteps} steps, we will gather the necessary information to
                                create your lease contract. Once completed, we will collect your signature and provide
                                everything else you need to move-in to your new apartment home.
                            </RegularText>
                            <RegularText style={globalStyles.centeredText}>
                                If you need to stop, you can always come back and continue from where you left off.
                            </RegularText>
                        </>
                    )}
                    {!firstVisit && (
                        <>
                            <RegularText style={[globalStyles.centeredText, globalStyles.smartMoveParagraphText]}>
                                Welcome back to SMART<RegularBoldText>MOVE</RegularBoldText>!
                            </RegularText>
                            <RegularText style={[globalStyles.centeredText, globalStyles.smartMoveParagraphText]}>
                                We’ve saved all the information entered so far. Please continue from where you left off.
                            </RegularText>
                        </>
                    )}

                    {/*<View style={globalStyles.sectionSpaceAround}>
                        <Hyperlink
                            onClick={() =>
                                navigation.navigate(
                                    navigationRoutes.loggedInRoutes.smartmoveRoutes
                                        .smartMovePaymentConfirmationProcessing,
                                )
                            }>
                            Payment Processing
                        </Hyperlink>

                        <Hyperlink
                            onClick={() =>
                                navigation.navigate(
                                    navigationRoutes.loggedInRoutes.smartmoveRoutes
                                        .smartMovePaymentConfirmationPaymentReceivedApartmentReserved,
                                )
                            }>
                            Payment Received / Apt Reserved
                        </Hyperlink>
                        <Hyperlink
                            onClick={() =>
                                navigation.navigate(
                                    navigationRoutes.loggedInRoutes.smartmoveRoutes
                                        .smartMovePaymentConfirmationUnsuccessful,
                                )
                            }>
                            Payment Failed
                        </Hyperlink>
                    </View>*/}
                </SmartmoveScreenContainer>
            );
        }
    }
    return null;
});

export default SmartMoveApplication;

const styles = StyleSheet.create({
    smartmoveLogoWrapper: {
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN * 6,
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN * 4,
        alignItems: 'center',
    },
    smartmoveLogo: {
        width: 277,
        height: 36,
    },
});
