import React from 'react';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { Animated, Easing, StyleProp, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ButtonText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Ripple from 'react-native-material-ripple';
import { IconDefinition } from 'theme/IconDefinition';
import { testProps } from 'components/ComponentTypes';

interface ButtonProps {
    testID?: string;
    accessibilityLabel?: string;
    buttonType?: 'primary' | 'secondary';
    animationType?: 'bounce' | 'ripple' | 'none';
    inactive?: boolean;
    children?: React.ReactNode;
    onClick?: () => void;
    style?: StyleProp<any>;
    wrapperStyle?: StyleProp<any>;
    icon?: IconDefinition;
}

const defaultProps: ButtonProps = {
    buttonType: 'primary',
    animationType: 'ripple',
    inactive: false,
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const scaleValue = new Animated.Value(1);

    const _handleClick = () => {
        if (props.animationType === 'bounce') {
            scaleValue.setValue(1.05);

            Animated.timing(scaleValue, {
                toValue: 1,
                duration: 1000,
                easing: Easing.elastic(4),
                useNativeDriver: true,
            }).start();
        }

        if (!props.inactive) {
            logInfo(INFO_LOGGER, 'Button Clicked');

            if (props.onClick) {
                props.onClick();
            }
        }
    };

    const _renderIcon = () => {
        if (props.icon) {
            return (
                <MaterialCommunityIcons
                    style={props.icon.alignment === 'right' ? styles.iconRight : styles.icon}
                    name={props.icon.iconID}
                    size={props.icon.size ? props.icon.size : SmarthubTheme.layout.ICONWIDTH}
                    color={props.icon.color}
                />
            );
        }
    };

    const style = [
        ...[styles.button],
        props.style,
        props.icon?.alignment === 'right' ? { flexDirection: 'row-reverse' } : null,
    ];

    const inactiveStyle = [...[styles.buttonInactive], props.style];

    const buttonBackgroundStyle =
        props.buttonType === 'secondary'
            ? [...[styles.buttonBackground], styles.buttonBackgroundSecondary, props.style]
            : [...[styles.buttonBackground], props.style];

    const animButtonBackground = [
        buttonBackgroundStyle,
        {
            transform: [{ scale: scaleValue }],
        },
    ];

    const buttonTextStyle = props.buttonType === 'secondary' ? styles.buttonTextSecondary : styles.buttonText;

    return (
        <View
            style={[styles.buttonWrapper, props.wrapperStyle]}
            testID={props.testID}
            accessibilityLabel={props.accessibilityLabel}>
            {props.inactive ? (
                <View style={inactiveStyle}>
                    <ButtonText style={buttonTextStyle} testID={props.testID + '-alt'}>
                        {props.children}
                    </ButtonText>
                    {props.icon && (
                        <MaterialCommunityIcons
                            style={props.icon.alignment === 'right' ? styles.iconRight : styles.icon}
                            name={props.icon.iconID}
                            size={props.icon.size ? props.icon.size : SmarthubTheme.layout.ICONWIDTH}
                            color={props.icon.color}
                        />
                    )}
                </View>
            ) : (
                <View>
                    <Animated.View style={animButtonBackground}>
                        {props.animationType === 'ripple' ? (
                            <Ripple
                                {...testProps('button-clickable')}
                                style={style}
                                onPress={() => {
                                    _handleClick();
                                }}
                                rippleCentered={true}>
                                {_renderIcon()}
                                <ButtonText style={buttonTextStyle} testID={props.testID + '-alt'}>
                                    {props.children}
                                </ButtonText>
                            </Ripple>
                        ) : (
                            <TouchableOpacity
                                {...testProps('button-clickable')}
                                style={style}
                                onPress={() => {
                                    _handleClick();
                                }}>
                                {_renderIcon()}
                                <ButtonText style={buttonTextStyle} testID={props.testID + '-alt'}>
                                    {props.children}
                                </ButtonText>
                            </TouchableOpacity>
                        )}
                    </Animated.View>
                </View>
            )}
        </View>
    );
};

Button.defaultProps = defaultProps;

export default Button;

const styles = StyleSheet.create({
    buttonWrapper: {
        display: 'flex',
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'center',
        height: SmarthubTheme.layout.BUTTONHEIGHT,
        paddingLeft: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingRight: SmarthubTheme.layout.GRIDINCREMENT * 2,
        backgroundColor: SmarthubTheme.colors.transparent,
    },
    buttonBackground: {
        height: SmarthubTheme.layout.BUTTONHEIGHT,
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.orange,
        backgroundColor: SmarthubTheme.colors.orange,
    },
    buttonBackgroundSecondary: {
        borderColor: SmarthubTheme.colors.orange,
        backgroundColor: SmarthubTheme.colors.white,
    },
    buttonInactive: {
        flexDirection: 'row',
        justifyContent: 'center',
        height: SmarthubTheme.layout.BUTTONHEIGHT,
        paddingLeft: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingRight: SmarthubTheme.layout.GRIDINCREMENT * 2,
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.orangeinactive,
        backgroundColor: SmarthubTheme.colors.orangeinactive,
    },
    buttonText: {
        alignSelf: 'center',
        color: SmarthubTheme.colors.white,
    },
    buttonTextSecondary: {
        color: SmarthubTheme.colors.orange,
    },
    icon: {
        alignSelf: 'center',
        marginRight: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
    iconRight: {
        alignSelf: 'center',
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
});
