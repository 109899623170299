import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { QueryKeys } from 'queries/QueryKeys';
import { UIStore } from 'stores/domain/UIStore';
import {
    createGetSmartmoveMoveInChargesCommandV1,
    GetSmartmoveMoveInChargesCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveMoveInChargesCommandV1';
import { GetSmartmoveMoveInChargesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveMoveInChargesResultV1';
import { UseQueryOptions, UseQueryResult } from 'react-query';

export const useMoveInCharges = (
    propertyCd: string,
    residencyId: number,
    hasSessionActiveResidency: boolean,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetSmartmoveMoveInChargesResultV1>,
): UseQueryResult<GetSmartmoveMoveInChargesResultV1> => {
    //make the  query key
    const queryKey = [QueryKeys.MoveInCharges, propertyCd, residencyId];

    //make the command
    const theCommand = createGetSmartmoveMoveInChargesCommandV1();
    theCommand.propertyCd = propertyCd;
    theCommand.residencyId = residencyId;

    //call the command
    return useCommandQuery<GetSmartmoveMoveInChargesCommandV1, GetSmartmoveMoveInChargesResultV1>(
        theCommand,
        uiStore,
        queryKey,
        queryOptions || {
            enabled: hasSessionActiveResidency,
        },
    );
};
