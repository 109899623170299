import { useEffect } from 'react';
import { NavigationProp } from '@react-navigation/native';
import { useStore } from 'contexts/StoreContextProvider';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';

const useConfirmLeave = (navigation: NavigationProp<any>, formik: any) => {
    const { uiStore } = useStore();

    useEffect(() => {
        return navigation.addListener('beforeRemove', e => {
            if (!formik.dirty) {
                formik.resetForm();
                return;
            }

            e.preventDefault();

            uiStore.showAlert({
                message: 'You will lose any unsaved changes. Are you sure you wish to continue?',
                buttons: [
                    {
                        buttonText: 'OK',
                        onClick: () => {
                            uiStore.hideAlert();
                            formik.resetForm();
                            navigation.dispatch(e.data.action);
                        },
                    },
                    {
                        buttonText: 'Cancel',
                        buttonType: 'secondary',
                        onClick: () => {
                            uiStore.hideAlert();
                        },
                    },
                ],
            });
        });
    }, [navigation, formik.dirty]);
};

export default useConfirmLeave;
