import React, { useEffect } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { Platform, StyleSheet, View } from 'react-native';
import RightBar from '../RightBar';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { observer } from 'mobx-react-lite';
import CustomDrawer from './CustomDrawer';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import { SmarthubTheme } from 'theme/SmarthubTheme';

import MoreStackNavigator from 'navigation/stacks/MoreStack';
import NoticesStackNavigator from 'navigation/stacks/NoticesStack';
import NotificationsStackNavigator from 'navigation/stacks/NotificationsStack';
import HomeStackNavigator from 'navigation/stacks/HomeStack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { navigationRoutes } from 'utils/NavigationUtils';
import WebLayout from 'navigation/navigators/WebLayout';
import SmartMoveStackNavigator from 'navigation/stacks/SmartMoveStack';
import OptInOutFeatureHighlightScreen from 'components/appTeasers/OptInOutFeatureHighlightScreen';
import useShowOptInOutFeatureHighlight from 'utils/useShowOptInOutFeatureHighlight';
import { setOptInOutFeatureHighlightToday } from 'utils/appTeasers';

const Drawer = createDrawerNavigator(); //https://reactnavigation.org/docs/drawer-navigator

const DrawerNavigator: React.FC<{
    navigation: NavigationProp<ParamListBase>;
    route: any;
}> = observer(({ navigation, route }) => {
    const responsiveContext = useResponsiveContext();
    const insets = useSafeAreaInsets();
    const { userSessionStore, uiStore } = useStore();
    const { smarthubUser, confirmActiveResidency, hasSessionActiveResidency, sessionActiveResidency } =
        userSessionStore;
    const theme = useTheme();
    const { useFullExperience } = responsiveContext;

    const { showOptInOptOutFeatureHighlight, setShowOptInOptOutFeatureHighlight } = useShowOptInOutFeatureHighlight();

    useEffect(() => {
        //if (!smarthubUser.authorizedResidencies || smarthubUser.authorizedResidencies.length === 0) return;
        confirmActiveResidency(navigation);
    }, [smarthubUser.authorizedResidencies]);

    useEffect(() => {
        logInfo(INFO_LOGGER, 'RESPONSIVE====== Adjusting to new width: ', responsiveContext.windowWidth);
    }, [responsiveContext.windowWidth]);

    if (sessionActiveResidency.statusCd === 'Leased') {
        return null;
    } else
        return (
            <>
                {showOptInOptOutFeatureHighlight && (
                    <OptInOutFeatureHighlightScreen
                        closeOptInOutFeatureHighlightScreen={() => {
                            setShowOptInOptOutFeatureHighlight(false);
                            setOptInOutFeatureHighlightToday(sessionActiveResidency.residentId.toString());
                        }}
                        navigateToAlerts={() => {
                            setOptInOutFeatureHighlightToday(sessionActiveResidency.residentId.toString());
                            navigation.navigate(navigationRoutes.moreRoutes.more, {
                                screen: navigationRoutes.moreRoutes.alertsAndNotifications,
                            });
                        }}
                    />
                )}
                <WebLayout>
                    <View style={styles.bodycolumns}>
                        <Drawer.Navigator
                            screenOptions={{
                                headerShown: false,
                                drawerType: 'permanent',
                                drawerContentContainerStyle: {},
                                drawerItemStyle: {},
                                overlayColor: theme.colors.transparent,
                                drawerStyle: [
                                    useFullExperience ? styles.drawerwide : styles.drawernarrow,
                                    {
                                        backgroundColor: uiStore.useDarkTheme
                                            ? theme.colors.darkgrey
                                            : theme.colors.lightestgrey,
                                    },
                                    // eslint-disable-next-line react-native/no-inline-styles
                                    showOptInOptOutFeatureHighlight ? { display: 'none' } : {},
                                ],
                                sceneContainerStyle: {
                                    backgroundColor: uiStore.useDarkTheme
                                        ? theme.colors.darkgrey
                                        : theme.colors.lightestgrey,
                                },
                            }}
                            defaultStatus={'open'}
                            backBehavior={'history'}
                            drawerContent={CustomDrawer}
                            //initialRouteName={Navigation.HOME_INITIAL_ROUTE_NAME}
                            initialRouteName={navigationRoutes.homeRoutes.home}>
                            {/*Top level screens*/}
                            <Drawer.Screen name={navigationRoutes.homeRoutes.home} component={HomeStackNavigator} />
                            <Drawer.Screen
                                name={navigationRoutes.noticesRoutes.notices}
                                component={NoticesStackNavigator}
                            />
                            <Drawer.Screen
                                name={navigationRoutes.notificationRoutes.notifications}
                                component={NotificationsStackNavigator}
                            />
                            <Drawer.Screen name={navigationRoutes.moreRoutes.more} component={MoreStackNavigator} />
                            <Drawer.Screen
                                name={navigationRoutes.smartmoveRoutes.smartMoveApplication}
                                component={SmartMoveStackNavigator}
                            />
                        </Drawer.Navigator>
                        {useFullExperience && hasSessionActiveResidency && (
                            <View
                                style={[
                                    styles.rightcontainer,
                                    // eslint-disable-next-line react-native/no-inline-styles
                                    { display: showOptInOptOutFeatureHighlight ? 'none' : undefined },
                                ]}>
                                <RightBar />
                            </View>
                        )}
                    </View>
                </WebLayout>
            </>
        );
});
export default DrawerNavigator;

const styles = StyleSheet.create({
    bodycolumns: {
        flexDirection: 'row',
        width: SmarthubTheme.layout.FULLWIDTH,
        maxWidth: 1400,
        height: SmarthubTheme.layout.FULLHEIGHT,
        paddingVertical: SmarthubTheme.layout.HEADERFOOTERPAD,
        justifyContent: 'center',
        alignSelf: 'center',
    },
    rightcontainer: {
        width: SmarthubTheme.layout.SIDEWIDTH,
        marginRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    drawerwide: {
        width: SmarthubTheme.layout.SIDEWIDTH,
        borderRightWidth: 0,
    },
    drawernarrow: {
        width: SmarthubTheme.layout.NARROWDRAWER,
        borderRightWidth: 0,
    },
});
