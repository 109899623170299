import { StackNavigationOptions } from '@react-navigation/stack';
import { NavigationProp, ParamListBase, useNavigation, useRoute } from '@react-navigation/native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { getHeaderTitle } from 'navigation/stacks/PageTitles';
import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Heading1, RegularText } from 'components/primitives/StyledText';
import LeaseSwitchLink from 'components/cards/LeaseSwitchLink';
import { useTheme } from 'react-native-paper';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Tooltip from 'components/primitives/Tooltip';
import { STORAGE_KEY_REQUEST_PLUS_BUTTON_HIGHLIGHT_DISMISSED } from 'utils/storage-keys';
import { globalStyles } from 'theme/style/GlobalStyles';
import { Menu } from 'react-native-popup-menu';
import PlusIcon from 'components/primitives/PlusIcon';
import { getRouteParam } from 'utils/routeUtils';
import { testProps, updateStringForIDName } from 'components/ComponentTypes';
import {
    GetNavigationState,
    IsNavigationRouteOnStack,
    navigationRoutes,
    resetNavigationState,
    saveWebHistoryOnRefresh,
    saveWebResidencyOnRefresh,
} from 'utils/NavigationUtils';
import { residencyInfo } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';

// Calculate Y pos offset value
export const TopSafeZone = (): number => {
    // Measure top offset (screen has back arrow only)
    const safeZone = Math.round(useSafeAreaInsets().top / 2);

    if (Platform.OS === 'ios') {
        return safeZone;
    } else {
        return 0;
    }
};

const IsSingleLine = (showBack: boolean): boolean => {
    const route = useRoute();
    const title = getHeaderTitle(route);
    const topTab = ['Home', 'Community', 'Notices', 'Notifications', 'More'].indexOf(title) !== -1;

    if (showBack && !topTab) {
        return false;
    } else {
        return true;
    }
};

const GetHeaderHeight = (atHome: boolean, showBack: boolean): number => {
    if (Platform.OS === 'web' && atHome) {
        return SmarthubTheme.layout.GRIDINCREMENT * 8;
    } else if (IsSingleLine(showBack)) {
        return SmarthubTheme.layout.GRIDINCREMENT * 11;
    } else {
        return SmarthubTheme.layout.GRIDINCREMENT * 15;
    }
};

//the orchid is 2368x1401 (wxh)
const orchidRatio = 2368 / 1401;

export const getMainStackScreenOptions = (
    theme: ReactNativePaper.Theme,
    useDrawerNavigation: boolean,
    collapsible?: boolean,
    showBack = true,
    isAtHome = false,
    hideTitle = false,
):
    | StackNavigationOptions
    | {
          [key: string]: any;
      } => {
    return {
        headerStyle: {
            backgroundColor: useDrawerNavigation ? theme.colors.background : theme.colors.primary,
            height: GetHeaderHeight(isAtHome, showBack) + TopSafeZone(),
            borderBottomWidth: 0,
            shadowColor: 'transparent',
            shadowRadius: 0,
            shadowOpacity: 0,
            elevation: 0,
        },
        headerBackground: useDrawerNavigation ? null : collapsible ? (
            <HeaderBackgroundFC
                theme={theme}
                useDrawerNavigation={useDrawerNavigation}
                isAtHome={isAtHome}
                showBack={showBack}
            />
        ) : (
            () => (
                <HeaderBackgroundFC
                    theme={theme}
                    useDrawerNavigation={useDrawerNavigation}
                    isAtHome={isAtHome}
                    showBack={showBack}
                />
            )
        ),
        headerTitleContainerStyle: {
            height: Platform.OS === 'web' ? 'auto' : GetHeaderHeight(isAtHome, showBack),
        },
        headerTitleStyle: theme.typography.heading3,
        headerTintColor: useDrawerNavigation && !theme.dark ? theme.colors.black : theme.colors.white,
        headerShown: true,
        headerLeft: () => null,
        headerTitle: <HeaderTitleFC showBack={showBack} hideTitle={hideTitle} />,
        headerTitleAlign: 'left',
        animationEnabled: false,
        cardShadowEnabled: false,
        title: 'SMARTHUB',
    };
};

export interface NavigatorProps {
    navigation: NavigationProp<ParamListBase>;
    route: any;
}

export interface HeaderTitleFCProps {
    navigationProp?: any;
    showBack?: boolean;
    hideTitle?: boolean;
}

/**
 * THis is used by mainstackscreenoptions. NOTE: It returns effectively NOTHING for the header for web / drawer navigation.
 */
export const HeaderTitleFC: React.FC<HeaderTitleFCProps> = observer(
    ({ navigationProp, showBack = true, hideTitle = false }) => {
        const navigation = navigationProp || useNavigation();
        const route = useRoute();
        const { useDrawerNavigation } = useResponsiveContext();
        const theme = useTheme();
        const { userSessionStore } = useStore();
        const { smarthubUser, isReloading, hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

        const menu = React.createRef<Menu>();

        const title = getHeaderTitle(route);

        const goBackRoute: string = getRouteParam(route, 'goBackRoute');

        const isAtHome = route.name === navigationRoutes.homeRoutes.home;
        const isProfilePage = route.name === navigationRoutes.homeRoutes.profile;
        const isMyRequestsPage = route.name === navigationRoutes.homeRoutes.myRequests;
        const isAwayTimesPage = route.name === navigationRoutes.moreRoutes.awayTimes;
        const isAlertsAndNotificationsOptPage = route.name === navigationRoutes.moreRoutes.alertsAndNotificationsOpt;

        const isMoreOnStack = IsNavigationRouteOnStack(navigationRoutes.moreRoutes.more);

        const displayBackArrow = !IsSingleLine(showBack);

        let containerWidth: number | string = 'auto';
        if (!useDrawerNavigation) {
            containerWidth = useResponsiveContext().windowWidth - 32;
        }

        const headerHeight = GetHeaderHeight(isAtHome, showBack);

        const arrowMarginTop = Platform.OS === 'web' ? 0 : SmarthubTheme.layout.GRIDINCREMENT * 4 - TopSafeZone() / 2;

        const navState = GetNavigationState();
        saveWebHistoryOnRefresh(navState);

        const resInfo: residencyInfo = {
            residencyKey: sessionActiveResidency.key,
            residencyId: sessionActiveResidency.residencyId,
            propertyCd: sessionActiveResidency.propertyCd,
        };
        saveWebResidencyOnRefresh(resInfo);

        const topTab = ['Home', 'Community', 'Notices', 'Notifications', 'More'].indexOf(title) !== -1;
        const isPayConfirm = route.name.indexOf('PaymentConfirmation') > -1;

        useEffect(() => {
            if (Platform.OS === 'web' && !topTab && isReloading && !isPayConfirm) {
                resetNavigationState(navigation, navState);
            }
            // navigation.reset() causes a reload for hasSessionActiveResidency and I don't know why.
            // isReloading is a workaround that ensures this only occurs once on reload
        }, [isReloading]);

        const renderPlugIconTooltipText = () => {
            return (
                <RegularText style={globalStyles.centeredText}>
                    <RegularText style={[globalStyles.centeredText]}>
                        Tap the + icon in the right-hand corner to{' '}
                        <RegularText style={[globalStyles.orangeText, globalStyles.boldText]}>
                            create or add
                        </RegularText>{' '}
                        something on that screen. Try it out!
                    </RegularText>
                </RegularText>
            );
        };

        return (
            // eslint-disable-next-line react-native/no-inline-styles
            <View style={{ height: headerHeight, paddingBottom: TopSafeZone() / 2, width: '100%' }}>
                <View style={[styles.container, { width: containerWidth, height: headerHeight }]}>
                    <View style={[styles.headerSpread, { height: headerHeight }]}>
                        {displayBackArrow && (
                            <TouchableOpacity
                                style={[styles.arrow, { marginTop: arrowMarginTop }]}
                                onPress={() => {
                                    if (goBackRoute) {
                                        navigation.navigate(goBackRoute);
                                    } else if (!goBackRoute && isAlertsAndNotificationsOptPage && !isMoreOnStack) {
                                        navigation.navigate(navigationRoutes.moreRoutes.more);
                                    } else {
                                        navigation.goBack();
                                    }
                                }}>
                                <MaterialCommunityIcons
                                    {...testProps('back-arrow')}
                                    name='keyboard-backspace'
                                    size={36}
                                    color={!useDrawerNavigation ? SmarthubTheme.colors.white : theme.colors.icon}
                                />
                            </TouchableOpacity>
                        )}

                        <View style={styles.headerContent}>
                            {hasSessionActiveResidency && !hideTitle && (
                                <View
                                    {...testProps(updateStringForIDName(`${userSessionStore.activeUnitString}`))}
                                    style={styles.headerHeading}>
                                    <Heading1
                                        style={{
                                            color: !useDrawerNavigation
                                                ? SmarthubTheme.colors.white
                                                : theme.colors.text,
                                        }}>
                                        {title}
                                        {hasSessionActiveResidency &&
                                            isAtHome &&
                                            !useDrawerNavigation &&
                                            ` (${userSessionStore.activeUnitString})`}
                                    </Heading1>
                                </View>
                            )}

                            {hasSessionActiveResidency &&
                                isAtHome &&
                                !useDrawerNavigation &&
                                smarthubUser.authorizedResidencies.length > 1 && (
                                    <View style={styles.headerSwitch}>
                                        <LeaseSwitchLink
                                            labelStyle={[
                                                SmarthubTheme.typography.mediumBold,
                                                {
                                                    color:
                                                        useDrawerNavigation && !theme.dark
                                                            ? theme.colors.black
                                                            : theme.colors.white,
                                                },
                                            ]}
                                        />
                                    </View>
                                )}

                            {hasSessionActiveResidency && isProfilePage && !useDrawerNavigation && (
                                <View style={styles.headerCardpad}>
                                    <TouchableOpacity
                                        {...testProps('id-card')}
                                        onPress={() =>
                                            navigation.navigate(navigationRoutes.loggedInRoutes.residentIdModal)
                                        }
                                        style={styles.cardpad}>
                                        <AntDesign name='idcard' size={38} color={SmarthubTheme.colors.white} />
                                    </TouchableOpacity>
                                </View>
                            )}

                            {hasSessionActiveResidency &&
                                (isMyRequestsPage || isAwayTimesPage) &&
                                userSessionStore.sessionActiveResidency.statusCd !== 'Cancelled' &&
                                userSessionStore.sessionActiveResidency.statusCd !== 'Previous' &&
                                !useDrawerNavigation && (
                                    <View style={styles.plusIconWrapper}>
                                        <Tooltip
                                            plinthText={renderPlugIconTooltipText}
                                            plinthAlignment={'left'}
                                            spotlightShape={'circle'}
                                            plinthWidth={300}
                                            storageKey={STORAGE_KEY_REQUEST_PLUS_BUTTON_HIGHLIGHT_DISMISSED}>
                                            {isMyRequestsPage && (
                                                <PlusIcon
                                                    testId={'create-request-button'}
                                                    onClick={() => {
                                                        if (menu.current) {
                                                            return menu.current.isOpen()
                                                                ? menu.current.close()
                                                                : menu.current.open();
                                                        }
                                                    }}
                                                    menuItem={[
                                                        {
                                                            id: '3',
                                                            icon: 'build',
                                                            iconLibrary: 'material',
                                                            title: 'Create a Maintenance Request',
                                                            onSelect: () => {
                                                                useDrawerNavigation
                                                                    ? navigation.navigate(
                                                                          navigationRoutes.homeRoutes.createARequest,
                                                                      )
                                                                    : navigation.navigate(
                                                                          navigationRoutes.loggedInRoutes
                                                                              .createARequestModal,
                                                                      );
                                                            },
                                                        },
                                                        {
                                                            id: '4',
                                                            icon: 'chat',
                                                            iconLibrary: 'material',
                                                            title: 'Send Us a Message',
                                                            onSelect: () => {
                                                                useDrawerNavigation
                                                                    ? navigation.navigate(
                                                                          navigationRoutes.homeRoutes.createATicket,
                                                                      )
                                                                    : navigation.navigate(
                                                                          navigationRoutes.loggedInRoutes
                                                                              .createATicketModal,
                                                                      );
                                                            },
                                                        },
                                                    ]}
                                                    buttonTitle={'Create a Request or Ticket'}
                                                    {...testProps('create-request-button')}
                                                />
                                            )}
                                            {isAwayTimesPage && (
                                                <PlusIcon
                                                    testId={'add-away-time-button'}
                                                    onClick={() => {
                                                        useDrawerNavigation
                                                            ? navigation.navigate(
                                                                  navigationRoutes.moreRoutes.addEditAwayTime,
                                                                  {
                                                                      title: 'Add a New Away Time',
                                                                      awayTimeId: 0,
                                                                  },
                                                              )
                                                            : navigation.navigate(
                                                                  navigationRoutes.loggedInRoutes.addEditAwayTimeModal,
                                                                  {
                                                                      title: 'Add a New AwayTime',
                                                                      awayTimeId: 0,
                                                                  },
                                                              );
                                                    }}
                                                    {...testProps('add-new-away-time-mobile')}
                                                />
                                            )}
                                        </Tooltip>
                                    </View>
                                )}
                        </View>
                    </View>
                </View>
            </View>
        );
    },
);

export interface HeaderBackgroundFCProps {
    theme: ReactNativePaper.Theme;
    useDrawerNavigation: boolean;
    isAtHome: boolean;
    showBack: boolean;
}

export const HeaderBackgroundFC: React.FC<HeaderBackgroundFCProps> = (props: HeaderBackgroundFCProps) => {
    let topOffset = TopSafeZone();

    if (Platform.OS === 'ios' && topOffset < 44) {
        topOffset = 10;
    }

    const orchidHeight = GetHeaderHeight(props.isAtHome, props.showBack) - topOffset;
    const orchidWidth = orchidHeight * orchidRatio;

    return (
        <View
            style={{
                backgroundColor: props.useDrawerNavigation ? props.theme.colors.background : props.theme.colors.primary,
                height: GetHeaderHeight(props.isAtHome, props.showBack) + TopSafeZone(),
            }}>
            <Image
                source={require('../../../assets/images/orchid_header.png')}
                style={[styles.orchid, { height: orchidHeight, width: orchidWidth }]}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderWidth: 0,
        borderColor: SmarthubTheme.colors.black,
        borderStyle: 'solid',
        paddingTop: Platform.OS === 'web' ? 22 : 0,
    },
    arrow: {
        flex: 1,
        width: SmarthubTheme.layout.LARGEICONSIZE,
    },
    headerSpread: {
        display: 'flex',
        justifyContent: Platform.OS === 'web' ? 'flex-start' : 'flex-end',
        flex: 1,
        flexDirection: 'column',
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerHeading: {
        alignSelf: 'flex-start',
    },
    headerSwitch: {
        position: 'absolute',
        right: 0,
        marginTop: -3,
    },
    orchid: {
        position: 'absolute',
        alignSelf: 'center',
        bottom: 0,
        backgroundColor: SmarthubTheme.colors.transparent,
        opacity: 0.25,
    },
    headerCardpad: {
        position: 'absolute',
        right: 0,
        marginTop: -3,
    },
    cardpad: {
        marginTop: 'auto',
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT,
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    plusIconWrapper: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
});
