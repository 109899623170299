import React, { useEffect } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Heading3, MediumBoldText, RegularText, SmallText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Hyperlink from 'components/primitives/Hyperlink';
import { globalStyles } from 'theme/style/GlobalStyles';
import PaymentMethods from 'components/payments/PaymentMethods';
import InputRadioSimple from 'components/input/InputRadioSimple';
import PayByBankForm from 'components/payments/PayByBankForm';
import PayByCardForm from 'components/payments/PayByCardForm';
import Dialog from 'components/primitives/Dialog';
import { FormikProps } from 'formik';
import {
    CREDIT_CARD_PROCESSING_FEE_PERCENTAGE,
    DEBIT_CARD_PROCESSING_FEE,
    PaymentFormValues,
} from 'utils/PayLeaseZegoUtils';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { useStore } from 'contexts/StoreContextProvider';
import { usePaymentMethods } from 'queries/hooks/usePaymentMethods';
import { usePaymentData } from 'queries/hooks/usePaymentData';
import { useRemoveZegPaymentMethodMutation } from 'queries/hooks/useRemoveZegoPaymentMethodMutation';
import { useQueryClient } from 'react-query';
import InputCurrency from 'components/input/InputCurrency';
import { centsFromNumber, numberFromCents } from 'utils/CurrencyUtils';
import { useIsFocused } from '@react-navigation/native';

type PaymentFormProps = {
    formikHelpers: FormikProps<PaymentFormValues>;
    showPaymentAmountInput?: boolean;
    disablePaymentAmountInput?: boolean;
    hideFee: boolean;
    applyPaymentRestrictions: boolean;
    title1?: string;
    title2?: string;
    paymentMethodTitle?: string;
};

const PaymentForm: React.FC<PaymentFormProps> = ({
    formikHelpers,
    showPaymentAmountInput,
    disablePaymentAmountInput,
    hideFee,
    applyPaymentRestrictions,
    title1,
    title2,
    paymentMethodTitle,
}: PaymentFormProps) => {
    const { isDesktop } = useResponsiveContext();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState('');
    const [showCreditCardReadMore, setShowCreditCardReadMore] = React.useState(false);
    const { uiStore, userSessionStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();
    const isFocus = useIsFocused();

    const { queryKey: paymentMethodQueryKey, query: paymentMethodsQuery } = usePaymentMethods(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residentId,
        hideFee,
        applyPaymentRestrictions,
        hasSessionActiveResidency,
        uiStore,
        {
            refetchOnWindowFocus: false,
            enabled: hasSessionActiveResidency,
            onSettled: () => {
                uiStore.hideActivityLoader();
            },
        },
    );

    const { queryKey: paymentDataQuertKey, query: paymentDataQuery } = usePaymentData(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residentId,
        hideFee,
        applyPaymentRestrictions,
        hasSessionActiveResidency,
        uiStore,
        {
            refetchOnWindowFocus: false,
            enabled: hasSessionActiveResidency,
            onSettled: () => {
                uiStore.hideActivityLoader();
            },
        },
    );

    const paymentData = paymentDataQuery.data;

    const removePaymentMethodMutation = useRemoveZegPaymentMethodMutation(uiStore, sessionActiveResidency, {
        onSuccess: () => queryClient.invalidateQueries(paymentMethodQueryKey),
    });

    const titleContainerStyle = [isDesktop ? styles.weblayout : styles.mobilelayout, styles.otherContainerStyle];
    const paymentContainerStyle = [isDesktop ? styles.weblayout : undefined, styles.otherContainerStyle];

    useEffect(() => {
        if (isFocus) {
            if (paymentMethodsQuery.isFetching || paymentDataQuery.isFetching) {
                uiStore.showActivityLoader();
            } else {
                uiStore.hideActivityLoader();
            }
        }
    }, [paymentMethodsQuery.isFetching, paymentDataQuery.isFetching]);

    return (
        <>
            <View style={titleContainerStyle}>
                {!!title1 && <MediumBoldText style={globalStyles.smartMoveTitleText}>{title1}</MediumBoldText>}

                {!!title2 && <RegularText style={globalStyles.smartMoveTitleText}>{title2}</RegularText>}

                {showPaymentAmountInput && (
                    <View style={globalStyles.sectionSpaceBelow}>
                        <InputCurrency
                            value={
                                formikHelpers.values.amount
                                    ? numberFromCents(formikHelpers.values.amount)
                                    : formikHelpers.values.amount
                            }
                            disabled={disablePaymentAmountInput}
                            onChange={value => {
                                formikHelpers.setFieldValue(
                                    'amount',
                                    value || value === 0 ? centsFromNumber(value) : undefined,
                                );
                            }}
                            error={!!formikHelpers.errors.amount}
                            errorMessage={formikHelpers.errors.amount}
                        />
                    </View>
                )}

                {!!paymentMethodTitle && (
                    <MediumBoldText style={globalStyles.smartMoveTitleText}>{paymentMethodTitle}</MediumBoldText>
                )}
            </View>

            {!paymentMethodsQuery.isFetching && paymentData && (
                <View style={[styles.paymentWrapper, isDesktop ? globalStyles.flexColumnCenter : undefined]}>
                    <View style={paymentContainerStyle}>
                        <Image style={styles.secureSSL} source={require('../../../assets/images/secure-ssl.png')} />

                        <PaymentMethods
                            selectedPaymentMethod={selectedPaymentMethod}
                            paymentMethods={paymentMethodsQuery.data?.methods}
                            onPaymentMethodSelected={paymentMethod => {
                                formikHelpers.resetForm({
                                    values: { ...formikHelpers.initialValues, amount: formikHelpers.values.amount },
                                });
                                setSelectedPaymentMethod(paymentMethod.paymentMethodId.toString());
                                formikHelpers.setFieldValue('payByMethod', 'savedPaymentMethod');
                                formikHelpers.setFieldValue('paymentMethodId', paymentMethod.paymentMethodId);
                                formikHelpers.setFieldValue('gatewayPayerId', paymentMethod.gatewayPayerId);
                                formikHelpers.setFieldValue('paymentType', paymentMethod.paymentType);
                                formikHelpers.setFieldValue('lastFour', paymentMethod.lastFour);
                                formikHelpers.setFieldValue('bankOrCardName', paymentMethod.bankOrCardName);
                                formikHelpers.setFieldValue('save', false);
                            }}
                            onClickDeletePaymentMethod={paymentMethod => {
                                uiStore.showAlert({
                                    message: `Are you sure you want to delete this saved payment method ending in ${paymentMethod.lastFour}?`,
                                    buttons: [
                                        {
                                            buttonText: 'No, go back',
                                            buttonType: 'secondary',
                                            onClick: () => {
                                                uiStore.hideAlert();
                                            },
                                        },
                                        {
                                            buttonText: 'Yes, delete',
                                            buttonType: 'primary',
                                            onClick: () => {
                                                uiStore.hideAlert();
                                                uiStore.showActivityLoader();
                                                removePaymentMethodMutation.mutate(paymentMethod.gatewayPayerId!, {
                                                    onSettled: () => uiStore.hideActivityLoader(),
                                                });
                                            },
                                        },
                                    ],
                                });
                            }}
                        />

                        <View style={globalStyles.sectionSpaceBelow}>
                            <Heading3>Enter New Payment Method</Heading3>
                        </View>
                        {!paymentData?.disableAchYn && (
                            <View style={styles.paymentMethod}>
                                <InputRadioSimple
                                    testID={'bankaccount'}
                                    value={'bankaccount'}
                                    active={selectedPaymentMethod === 'bankaccount'}
                                    onClick={value => {
                                        formikHelpers.resetForm({
                                            values: {
                                                ...formikHelpers.initialValues,
                                                amount: formikHelpers.values.amount,
                                            },
                                        });
                                        setSelectedPaymentMethod(value);
                                        formikHelpers.setFieldValue('payByMethod', 'newBank');
                                        formikHelpers.setFieldValue('save', true);
                                        formikHelpers.setFieldValue('bankOrCardName', 'Bank');
                                        formikHelpers.setFieldValue('paymentType', 'Bank');
                                    }}
                                    style={styles.paymentMethodRadio}
                                />
                                <RegularText>Bank account</RegularText>
                            </View>
                        )}

                        {!paymentData?.disableCCYn && (
                            <>
                                <View style={styles.paymentMethod}>
                                    <InputRadioSimple
                                        testID={'creditcard'}
                                        value={'creditcard'}
                                        active={selectedPaymentMethod === 'creditcard'}
                                        onClick={value => {
                                            formikHelpers.resetForm({
                                                values: {
                                                    ...formikHelpers.initialValues,
                                                    amount: formikHelpers.values.amount,
                                                },
                                            });
                                            setSelectedPaymentMethod(value);
                                            formikHelpers.setFieldValue('payByMethod', 'newCard');
                                            formikHelpers.setFieldValue('save', true);
                                            formikHelpers.setFieldValue('isDebitCard', 'No');
                                            formikHelpers.setFieldValue('paymentType', 'Card');
                                        }}
                                        style={styles.paymentMethodRadio}
                                    />
                                    <RegularText style={styles.paymentMethodType}>Credit card</RegularText>
                                    <Image
                                        style={styles.paymentMethodCard}
                                        source={require('../../../assets/images/card-visa.png')}
                                    />
                                    <Image
                                        style={styles.paymentMethodCard}
                                        source={require('../../../assets/images/card-mastercard.png')}
                                    />
                                    <Image
                                        style={styles.paymentMethodCard}
                                        source={require('../../../assets/images/card-discover.png')}
                                    />
                                    <Image
                                        style={styles.paymentMethodCard}
                                        source={require('../../../assets/images/card-amex.png')}
                                    />
                                </View>

                                <View style={styles.paymentMethod}>
                                    <InputRadioSimple
                                        testID={'debitcard'}
                                        value={'debitcard'}
                                        active={selectedPaymentMethod === 'debitcard'}
                                        onClick={value => {
                                            formikHelpers.resetForm({
                                                values: {
                                                    ...formikHelpers.initialValues,
                                                    amount: formikHelpers.values.amount,
                                                },
                                            });
                                            setSelectedPaymentMethod(value);
                                            formikHelpers.setFieldValue('payByMethod', 'newCard');
                                            formikHelpers.setFieldValue('save', true);
                                            formikHelpers.setFieldValue('isDebitCard', 'Yes');
                                            formikHelpers.setFieldValue('bankOrCardName', 'Debit');
                                            formikHelpers.setFieldValue('paymentType', 'Card');
                                        }}
                                        style={styles.paymentMethodRadio}
                                    />
                                    <RegularText style={styles.paymentMethodType}>Debit card</RegularText>
                                    <Image
                                        style={styles.paymentMethodCard}
                                        source={require('../../../assets/images/card-visa.png')}
                                    />
                                    <Image
                                        style={styles.paymentMethodCard}
                                        source={require('../../../assets/images/card-mastercard.png')}
                                    />
                                    <Image
                                        style={styles.paymentMethodCard}
                                        source={require('../../../assets/images/card-discover.png')}
                                    />
                                </View>
                            </>
                        )}

                        {!hideFee && (
                            <SmallText style={[globalStyles.doubleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                                {`Bank account payments are FREE. Debit cards will incur a $${DEBIT_CARD_PROCESSING_FEE} processing fee. Credit cards will incur a ${CREDIT_CARD_PROCESSING_FEE_PERCENTAGE}% processing fee.`}
                            </SmallText>
                        )}

                        {selectedPaymentMethod === 'bankaccount' && <PayByBankForm formikHelpers={formikHelpers} />}
                        {selectedPaymentMethod === 'creditcard' && (
                            <PayByCardForm
                                cardType={'Credit'}
                                formikHelpers={formikHelpers}
                                stateOptions={paymentData.stateOptions}
                            />
                        )}
                        {selectedPaymentMethod === 'debitcard' && (
                            <PayByCardForm
                                cardType={'Debit'}
                                formikHelpers={formikHelpers}
                                stateOptions={paymentData.stateOptions}
                            />
                        )}
                    </View>
                </View>
            )}

            {(selectedPaymentMethod === 'creditcard' || selectedPaymentMethod === 'debitcard') && (
                <View style={[styles.zegoLogoContainer, titleContainerStyle]}>
                    {showCreditCardReadMore && (
                        <Dialog
                            showCloseButton={true}
                            visible={showCreditCardReadMore}
                            onDismiss={() => {
                                setShowCreditCardReadMore(false);
                            }}>
                            <RegularText style={[globalStyles.singleSpaceAbove, globalStyles.doubleSpaceBelow]}>
                                By adding this payment account, you grant Zego permission to store your card
                                credentials. The stored card credentials will only be used when you initiate a payment
                                or have a recurring payment scheduled. You may delete this payment account at any time
                                by clicking the &apos;Delete&apos; buton when making a payment. Any changes to these
                                terms will be reflected in Zego&apos;s general terms and conditions.
                            </RegularText>
                        </Dialog>
                    )}

                    <SmallText style={styles.termLabel}>
                        By clicking Continue, you grant Zego permission to store your credit card credentials.{' '}
                        <Hyperlink
                            style={styles.termLabel}
                            onClick={() => {
                                setShowCreditCardReadMore(true);
                            }}>
                            Read more
                        </Hyperlink>
                    </SmallText>
                </View>
            )}
            <Image
                style={[styles.zegoLogo, globalStyles.sectionSpaceAbove]}
                source={require('../../../assets/images/zego.png')}
            />
        </>
    );
};

export default PaymentForm;

const styles = StyleSheet.create({
    paymentWrapper: {
        minWidth: '100%',
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 4,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
        backgroundColor: SmarthubTheme.colors.paymentbackground,
    },
    secureSSL: {
        width: 105,
        height: 35,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 4,
    },
    paymentMethod: {
        flexDirection: 'row',
    },
    paymentMethodRadio: {
        marginTop: 4,
        marginRight: SmarthubTheme.layout.GRIDINCREMENT * 2,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    paymentMethodCard: {
        width: 32,
        height: 21,
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
    paymentMethodType: {
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
    weblayout: { maxWidth: 700, width: '80%' },
    mobilelayout: {
        width: '100%',
    },
    otherContainerStyle: { paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN },
    zegoLogo: {
        width: 138,
        height: 50,
    },
    zegoLogoContainer: {
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 6,
        alignItems: 'center',
    },
    termLabel: {
        fontSize: 14,
    },
});
