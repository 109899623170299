import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useStore } from 'contexts/StoreContextProvider';
import { RegularText, SmallText } from 'components/primitives/StyledText';
import Button from 'components/primitives/Button';
import Dialog from 'components/primitives/Dialog';
import InputString from 'components/input/InputString';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Constants from 'expo-constants';
import { useAuthentication } from 'contexts/AuthContextProvider';
import { testProps } from 'components/ComponentTypes';

export interface FeedbackDialogProps {
    eventId: string;
}

const RequestMessageSchema = yup.object().shape({
    message: yup.string().max(1024, 'Max length is 1024 characters').required('Please enter your message'),
});

type FormData = {
    message: string;
};

const FeedbackDialog: React.FC = () => {
    const { uiStore, userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const { user } = useAuthentication();

    const onSubmitMessage = async (data: FormData) => {
        uiStore.showActivityLoader();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const params = {
            event_id: uiStore.feedbackDialog?.eventId,
            name: `${sessionActiveResidency.firstName} ${sessionActiveResidency.lastName}`,
            email: user?.email,
            comments: data.message,
        };

        const organizationSlug =
            (Constants?.expoConfig?.hooks &&
                Constants.expoConfig.hooks.postPublish &&
                Constants.expoConfig.hooks.postPublish[0] &&
                Constants.expoConfig.hooks.postPublish[0].config &&
                Constants.expoConfig.hooks.postPublish[0].config.organization) ||
            '';
        const projectSlug =
            (Constants?.expoConfig?.hooks &&
                Constants.expoConfig.hooks.postPublish &&
                Constants.expoConfig.hooks.postPublish[0] &&
                Constants.expoConfig.hooks.postPublish[0].config &&
                Constants.expoConfig.hooks.postPublish[0].config.project) ||
            '';
        const dsn = (Constants.expoConfig?.extra && Constants.expoConfig.extra['sentryDSN']) || '';
        const endpoint = `https://sentry.io/api/0/projects/${organizationSlug}/${projectSlug}/user-feedback/`;

        await axios({
            headers: { Authorization: `DSN ${dsn}`, 'Content-Type': 'application/json' },
            method: 'post',
            cancelToken: source.token,
            url: endpoint,
            timeout: 60000, //Set the timeout to a minute
            data: JSON.stringify(params),
        });

        uiStore.hideFeedbackDialog();
        uiStore.hideActivityLoader();
    };

    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            message: '',
        },
        validationSchema: RequestMessageSchema,
        onSubmit: (values, actions) => {
            if (!uiStore.activityLoaderVisible) {
                formik.resetForm({ values: values });
                formik.setSubmitting(true);
                onSubmitMessage(values);
            }
        },
    });

    if (uiStore.feedbackDialog) {
        return (
            <Dialog {...testProps('feedback-dialog')} visible={true} onDismiss={uiStore.hideFeedbackDialog}>
                <View style={styles.alert}>
                    <RegularText
                        style={styles.alertTitle}>{`It looks like we're having some internal issues`}</RegularText>

                    <SmallText
                        style={
                            styles.alertMessage
                        }>{`Our team has been notified. If you'd like to help, tell us what happened below.`}</SmallText>

                    <InputString
                        autoFocus={Platform.OS === 'web'}
                        onChange={formik.handleChange('message')}
                        onBlur={formik.handleBlur('message')}
                        initialValue={formik.values.message}
                        multiline={true}
                        maxLength={1024}
                        numberOfLines={6}
                        error={!!formik.errors.message}
                        errorMessage={formik.errors.message}
                    />

                    <View style={styles.alertButtons}>
                        <View style={styles.alertButton}>
                            <Button
                                buttonType={'primary'}
                                inactive={uiStore.activityLoaderVisible}
                                onClick={formik.handleSubmit}>
                                Submit Crash Report
                            </Button>
                        </View>
                        <View style={styles.alertButton}>
                            <Button
                                buttonType={'secondary'}
                                inactive={uiStore.activityLoaderVisible}
                                onClick={uiStore.hideFeedbackDialog}>
                                Close
                            </Button>
                        </View>
                    </View>
                </View>
            </Dialog>
        );
    } else {
        return null;
    }
};

export default FeedbackDialog;

const styles = StyleSheet.create({
    alert: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    alertTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    alertMessage: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    alertButtons: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: SmarthubTheme.layout.GRIDINCREMENT,
    },
    alertButton: {
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
});
