import { RootStore } from '../RootStore';
import { logError } from 'utils/logging/Logger';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { action, makeAutoObservable, runInAction } from 'mobx';
import {
    createGetNoticeToVacateScreenDataResultV1,
    GetNoticeToVacateScreenDataResultV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetNoticeToVacateScreenDataResultV1';
import {
    createSmarthubNoticeToVacateRequestSubmitData,
    SmarthubNoticeToVacateRequestSubmitData,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubNoticeToVacateRequestSubmitData';
import {
    createGetNoticeToVacateScreenDataCommandV1,
    GetNoticeToVacateScreenDataCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetNoticeToVacateScreenDataCommandV1';
import {
    createValidateAddressCommandV1,
    ValidateAddressCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/ValidateAddressCommandV1';
import { ValidateAddressResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/ValidateAddressResultV1';
import { SmarthubAddressV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAddressV1';
import {
    createSubmitNoticeToVacateRequestCommandV1,
    SubmitNoticeToVacateRequestCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitNoticeToVacateRequestCommandV1';
import { SubmitNoticeToVacateRequestResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitNoticeToVacateRequestResultV1';
import { SubmitNoticeToVacateChangeDateRequestResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitNoticeToVacateChangeDateRequestResultV1';
import {
    createSubmitNoticeToVacateChangeDateRequestCommandV1,
    SubmitNoticeToVacateChangeDateRequestCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitNoticeToVacateChangeDateRequestCommandV1';
import { SubmitNoticeToVacateCancellationRequestResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitNoticeToVacateCancellationRequestResultV1';
import {
    createSubmitNoticeToVacateCancellationRequestCommandV1,
    SubmitNoticeToVacateCancellationRequestCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitNoticeToVacateCancellationRequestCommandV1';
import {
    createGetEditNoticeToVacateCommandV1,
    GetEditNoticeToVacateCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetEditNoticeToVacateCommandV1';
import {
    createGetEditNoticeToVacateResultV1,
    GetEditNoticeToVacateResultV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetEditNoticeToVacateResultV1';

export class NTVStore {
    rootStore: RootStore;

    ntvScreenData: GetNoticeToVacateScreenDataResultV1 = createGetNoticeToVacateScreenDataResultV1();

    editNtvData: GetEditNoticeToVacateResultV1 = createGetEditNoticeToVacateResultV1();

    ntvRequestSubmitData: SmarthubNoticeToVacateRequestSubmitData = createSmarthubNoticeToVacateRequestSubmitData();

    ntvRecipientIdSet: Set<number> = new Set();

    get ntvRecipientIdAry(): Array<number> {
        return Array.from(this.ntvRecipientIdSet).sort();
    }

    ntvCurrentRecipientIndx = 0;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    getNoticeToVacateScreenData = action(async () => {
        try {
            const cmd: GetNoticeToVacateScreenDataCommandV1 = createGetNoticeToVacateScreenDataCommandV1();
            cmd.propertyCd = this.rootStore.residentStore.getSessionActiveResidency().propertyCd;
            cmd.residencyId = this.rootStore.residentStore.getSessionActiveResidency().residencyId;
            const result: GetNoticeToVacateScreenDataResultV1 = await dispatchCommandAsync(
                this.rootStore.uiStore,
                cmd,
                false,
            );
            if (result) {
                runInAction(() => {
                    this.ntvScreenData = result;
                });
            }
        } catch (e) {
            logError(ERROR_LOGGER, 'Error getting create notice to vacate data:   ', JSON.stringify(e));
            this.ntvScreenData = createGetNoticeToVacateScreenDataResultV1();
        } finally {
            runInAction(() => {
                this.ntvRequestSubmitData = createSmarthubNoticeToVacateRequestSubmitData();
                this.ntvRequestSubmitData.propertyCd = this.rootStore.residentStore.getSessionActiveResidency().propertyCd;
                this.ntvRequestSubmitData.residencyId = this.rootStore.residentStore.getSessionActiveResidency().residencyId;
                this.ntvRequestSubmitData.residentId = this.rootStore.residentStore.getSessionActiveResidency().residentId;
                this.ntvRequestSubmitData.contactResidentId = this.rootStore.residentStore.getSessionActiveResidency().residentId;
                this.ntvRecipientIdSet = new Set();
            });
        }
    });

    validateAddressAsync = async (address: SmarthubAddressV1): Promise<ValidateAddressResultV1> => {
        try {
            const cmd: ValidateAddressCommandV1 = createValidateAddressCommandV1();
            cmd.address = address;
            return await dispatchCommandAsync(this.rootStore.uiStore, cmd, false);
        } catch (e) {
            logError(ERROR_LOGGER, 'Error validating address:   ', JSON.stringify(e));
            throw e;
        } finally {
            //nada
        }
    };

    submitNoticeToVacateRequestAsync = async (): Promise<SubmitNoticeToVacateRequestResultV1> => {
        try {
            const cmd: SubmitNoticeToVacateRequestCommandV1 = createSubmitNoticeToVacateRequestCommandV1();
            cmd.data = this.ntvRequestSubmitData;
            return await dispatchCommandAsync(this.rootStore.uiStore, cmd, false);
        } catch (e) {
            logError(ERROR_LOGGER, 'Error submitting notice to vacate request to server:   ', JSON.stringify(e));
            throw e;
        } finally {
            //nada
        }
    };

    getEditNoticeToVacateData = action(async () => {
        try {
            const cmd: GetEditNoticeToVacateCommandV1 = createGetEditNoticeToVacateCommandV1();
            cmd.propertyCd = this.rootStore.residentStore.getSessionActiveResidency().propertyCd;
            cmd.residencyId = this.rootStore.residentStore.getSessionActiveResidency().residencyId;
            const result: GetEditNoticeToVacateResultV1 = await dispatchCommandAsync(
                this.rootStore.uiStore,
                cmd,
                false,
            );
            if (result) {
                runInAction(() => {
                    this.editNtvData = result;
                });
            }
        } catch (e) {
            logError(ERROR_LOGGER, 'Error getting edit notice to vacate data: ', JSON.stringify(e));
            this.editNtvData = createGetEditNoticeToVacateResultV1();
        }
    });

    changeNoticeToVacateMoveOutdtAsync = async (
        propertyCd: string,
        residencyId: number,
        requestResidentId: number,
        expectMoveOutDate: string,
    ): Promise<SubmitNoticeToVacateChangeDateRequestResultV1> => {
        try {
            const cmd: SubmitNoticeToVacateChangeDateRequestCommandV1 = createSubmitNoticeToVacateChangeDateRequestCommandV1();
            cmd.propertyCd = propertyCd;
            cmd.residencyId = residencyId;
            cmd.requestResidentId = requestResidentId;
            cmd.expectMoveOutDate = expectMoveOutDate;

            const result: SubmitNoticeToVacateChangeDateRequestResultV1 = await dispatchCommandAsync(
                this.rootStore.uiStore,
                cmd,
                false,
            );
            //reload residency to get the correct status and lease adjustment
            this.rootStore.userSessionStore.reloadResidencies(true);
            return result;
        } catch (e) {
            logError(
                ERROR_LOGGER,
                'Error submitting notice to vacate move out date change request to server:   ',
                JSON.stringify(e),
            );
            throw e;
        }
    };

    cancelNoticeToVacateAsync = async (
        propertyCd: string,
        residencyId: number,
        requestResidentId: number,
        contactResidentId: number,
        preferContactOption: string,
        preferContactDetail: string,
    ): Promise<SubmitNoticeToVacateCancellationRequestResultV1> => {
        try {
            const cmd: SubmitNoticeToVacateCancellationRequestCommandV1 = createSubmitNoticeToVacateCancellationRequestCommandV1();
            cmd.propertyCd = propertyCd;
            cmd.residencyId = residencyId;
            cmd.requestResidentId = requestResidentId;
            cmd.contactResidentId = contactResidentId;
            cmd.preferContactOption = preferContactOption;
            cmd.preferContactDetail = preferContactDetail;

            const result: SubmitNoticeToVacateCancellationRequestResultV1 = await dispatchCommandAsync(
                this.rootStore.uiStore,
                cmd,
                false,
            );
            //reload residency to get the correct status and lease adjustment
            this.rootStore.userSessionStore.reloadResidencies(true);
            return result;
        } catch (e) {
            logError(
                ERROR_LOGGER,
                'Error submitting notice to vacate cancellation request to server:   ',
                JSON.stringify(e),
            );
            throw e;
        }
    };
}
