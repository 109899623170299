import * as React from 'react';
import { View, StyleSheet, StyleProp } from 'react-native';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';

interface FlexItemProps {
    testID?: string;
    flex?: number;
    flexGrow?: number;
    flexBasis?: number | string;
    alignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
    children?: React.ReactNode;
    style?: StyleProp<any>;
}

const defaultProps: FlexItemProps = {
    flex: 1,
    flexGrow: 1,
    flexBasis: 'auto',
    alignSelf: 'auto',
};

const FlexItem: React.FC<FlexItemProps> = (props: FlexItemProps) => {
    const styles = StyleSheet.create({
        flexItem: {
            flex: props.flex,
            flexGrow: props.flexGrow,
            flexBasis: props.flexBasis,
            alignSelf: props.alignSelf,
            alignItems: props.alignItems,
        },
    });

    const style = [...[styles.flexItem], props.style];

    return (
        <View style={style} testID={'generic-flex-item'}>
            {props.children}
        </View>
    );
};

FlexItem.defaultProps = defaultProps;

export default FlexItem;
