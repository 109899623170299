import * as React from 'react';
import { useEffect, useState } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { observer } from 'mobx-react-lite';
import { FlatList, StyleSheet, View } from 'react-native';
import ActionMenu from 'components/primitives/ActionMenu';
import Button from 'components/primitives/Button';
import { AntDesign } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { Menu } from 'react-native-popup-menu';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { Divider, useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { SafeAreaView } from 'react-native-safe-area-context';
import { globalStyles } from 'theme/style/GlobalStyles';
import { DeemphansizedTextSmall, RegularText, SmallText } from 'components/primitives/StyledText';
import Hyperlink from 'components/primitives/Hyperlink';
import { SmarthubAwayTimeV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAwayTimeV1';
import Dialog from 'components/primitives/Dialog';
import { daysBetween, formatDateStringMonthLabel, parseDateMMDDYYYY } from 'utils/DateUtils';
import { testProps } from 'components/ComponentTypes';
import { navigationRoutes } from 'utils/NavigationUtils';
import { SmarthubAwayTimeResidentV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAwayTimeResidentV1';
import { generateDeleteAwayTimeCmd, useDeleteAwayTime, useGetAwayTimesList } from 'queries/hooks/more/AwayTimesHooks';

type ScreenProps = {
    navigation: StackNavigationProp<any>;
};

const AwayTimes: React.FC<ScreenProps> = observer(({ navigation }: ScreenProps) => {
    const theme = useTheme();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [awayTimeIdToDelete, setAwayTimeIdToDelete] = useState(0);
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, confirmActiveResidency, sessionActiveResidency } = userSessionStore;

    const menu = React.createRef<Menu>();
    const { useDrawerNavigation } = useResponsiveContext();

    const awayTimesQuery = useGetAwayTimesList();
    const deleteAwayTimeHook = useDeleteAwayTime();

    const onClickAddAwayTimeButton = (awayTimeId: number) => {
        if (awayTimeId === 0) {
            useDrawerNavigation
                ? navigation.navigate(navigationRoutes.moreRoutes.addAwayTime, {
                      awayTimeId: awayTimeId,
                      title: 'Add a New Away Time',
                  })
                : navigation.navigate(navigationRoutes.loggedInRoutes.addEditAwayTimeModal, {
                      title: 'Add a New AwayTime',
                      awayTimeId: awayTimeId,
                  });
        } else {
            navigation.navigate(navigationRoutes.moreRoutes.editAwayTime, {
                title: 'Edit an Away Time',
                awayTimeId: awayTimeId,
            });
        }
    };

    const PlusIcon = observer(() => {
        return (
            <ActionMenu
                ref={menu}
                style={styles.plusIcon}
                menuTrigger={
                    useDrawerNavigation ? (
                        <View style={styles.createAwayTimeButton}>
                            <Button
                                onClick={() => {
                                    onClickAddAwayTimeButton(0);
                                }}
                                {...testProps('add-away-time-button')}
                            >
                                Add an Away Time
                            </Button>
                        </View>
                    ) : (
                        <AntDesign
                            name='pluscircle'
                            size={SmarthubTheme.layout.ICONWIDTH}
                            color={SmarthubTheme.colors.white}
                        />
                    )
                }
                menuItem={[
                    {
                        id: '3',
                        icon: 'build',
                        iconLibrary: 'material',
                        title: 'Add an Away Time',
                        onSelect: () => {
                            navigation.navigate(navigationRoutes.moreRoutes.more, {
                                screen: navigationRoutes.moreRoutes.addAwayTime,
                                awayTimeId: 0,
                                title: 'Add a New Away Time',
                            });
                        },
                    },
                ]}
            />
        );
    });

    useEffect(() => {
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        confirmActiveResidency(navigation);
        if (hasSessionActiveResidency) {
            if (
                sessionActiveResidency.statusCd !== 'Cancelled' &&
                sessionActiveResidency.statusCd !== 'Previous' &&
                useDrawerNavigation
            ) {
                navigation.setOptions({
                    // eslint-disable-next-line react/display-name
                    headerRight: () => <PlusIcon />,
                });
            }
        }
    }, [sessionActiveResidency.key]);

    useEffect(() => {
        if (awayTimesQuery.isFetching) {
            uiStore.showActivityLoader();
        } else {
            uiStore.hideActivityLoader();
        }
    }, [awayTimesQuery.isFetching]);

    const _toggleDialog = (awayTime: SmarthubAwayTimeV1) => {
        setDialogVisible(!dialogVisible);
        setAwayTimeIdToDelete(awayTime.residentAwayTimeId);
    };

    const deleteAwayTime = () => {
        if (sessionActiveResidency && awayTimeIdToDelete !== 0) {
            const awayTimeId = awayTimeIdToDelete !== 0 ? awayTimeIdToDelete : -1;
            deleteAwayTimeHook.mutate(generateDeleteAwayTimeCmd(sessionActiveResidency.propertyCd, awayTimeId));
            if (dialogVisible) {
                setDialogVisible(!dialogVisible);
            }
        }
    };

    const residentsAwayTimeList = (residents: Array<SmarthubAwayTimeResidentV1>): string => {
        let result = 'Away Residents: ';

        residents.forEach((resident, index) => {
            result = result + resident.residentFirstName;
            if (index !== residents.length - 1) {
                result = result + ', ';
            }
        });

        return result;
    };

    if (!hasSessionActiveResidency) {
        return null;
    }

    const renderDialog = () => {
        return (
            <Dialog visible={dialogVisible} onDismiss={() => setDialogVisible(false)}>
                <View style={{ backgroundColor: theme.colors.background }}>
                    <View style={styles.dialogBody}>
                        {useDrawerNavigation && (
                            <RegularText>Are you sure you want to delete this away time?</RegularText>
                        )}
                        {!useDrawerNavigation && <SmallText>Are you sure you want to delete this away time?</SmallText>}
                    </View>
                    <View style={globalStyles.flexRow}>
                        <Button
                            {...testProps('no-nevermind')}
                            style={styles.dialogButtons}
                            buttonType={'secondary'}
                            onClick={() => setDialogVisible(false)}
                        >
                            No, go back
                        </Button>
                        <Button
                            {...testProps('yes-delete')}
                            style={styles.dialogButtons}
                            buttonType={'primary'}
                            onClick={() => deleteAwayTime()}
                        >
                            Yes, continue
                        </Button>
                    </View>
                </View>
            </Dialog>
        );
    };

    const renderAwayTime = (awayTime: SmarthubAwayTimeV1) => {
        return (
            <View
                testID={'away-line'}
                accessibilityLabel={'away-line'}
                style={[
                    styles.awayTimeFlexWrapper,
                    globalStyles.tripleSpaceBelow,
                    globalStyles.tripleSpaceAbove,
                    { backgroundColor: theme.colors.background },
                ]}
            >
                <View style={useDrawerNavigation ? styles.webItemGroup1 : styles.mobileAwayTimeItemGroup1}>
                    {useDrawerNavigation && (
                        <RegularText style={[globalStyles.flexRowStart, styles.dateWidth]}>
                            {`${formatDateStringMonthLabel(awayTime.leavingDate)} - ${formatDateStringMonthLabel(
                                awayTime.returningDate,
                            )}`}
                        </RegularText>
                    )}
                    {!useDrawerNavigation && (
                        <SmallText
                            style={[
                                globalStyles.flexColumnCenter,
                                globalStyles.singleSpaceBelow,
                                globalStyles.hundowidth,
                            ]}
                        >
                            {`${formatDateStringMonthLabel(awayTime.leavingDate)} - ${formatDateStringMonthLabel(
                                awayTime.returningDate,
                            )}`}
                        </SmallText>
                    )}
                    <View
                        style={
                            useDrawerNavigation
                                ? [globalStyles.flexRowStart, styles.residentsWidth]
                                : [globalStyles.flexRowStart, globalStyles.hundowidth]
                        }
                    >
                        <View style={styles.awayTimeResidentsList}>
                            <DeemphansizedTextSmall style={styles.awayTimeResidentsListResidents}>
                                {residentsAwayTimeList(awayTime.residents)}
                            </DeemphansizedTextSmall>
                        </View>
                    </View>
                </View>
                <View
                    style={
                        useDrawerNavigation
                            ? styles.awayTimeItem3
                            : [styles.mobileAwayTimeItem3, globalStyles.singleSpaceAbove]
                    }
                >
                    {daysBetween(
                        new Date(
                            Date.UTC(
                                userSessionStore.propertySpecificDt.getUTCFullYear(),
                                userSessionStore.propertySpecificDt.getUTCMonth(),
                                userSessionStore.propertySpecificDt.getUTCDate(),
                            ),
                        ),
                        parseDateMMDDYYYY(awayTime.returningDate),
                    ) > 0 && (
                        <View style={globalStyles.flexRow}>
                            <Hyperlink
                                testID={'edit'}
                                accessibilityLabel={'edit'}
                                smallText={!useDrawerNavigation}
                                style={styles.editMargin}
                                onClick={() => onClickAddAwayTimeButton(awayTime.residentAwayTimeId)}
                            >
                                Edit
                            </Hyperlink>
                            {!sessionActiveResidency.adminYn && (
                                <Hyperlink
                                    testID={'delete'}
                                    accessibilityLabel={'delete'}
                                    smallText={!useDrawerNavigation}
                                    onClick={() => _toggleDialog(awayTime)}
                                >
                                    Delete
                                </Hyperlink>
                            )}
                        </View>
                    )}
                </View>
            </View>
        );
    };

    return (
        <SafeAreaView style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
            <RegularText {...testProps('message')} style={globalStyles.tripleSpaceBelow}>
                Let us know if you will be away from your apartment for an extended period of time so that we can make
                the necessary arrangements to hold your packages for you.
            </RegularText>
            <Divider style={styles.headerDivider}></Divider>
            {awayTimesQuery.isLoading ||
            awayTimesQuery.isError ||
            awayTimesQuery.isFetching ||
            awayTimesQuery.isIdle ? null : (
                <FlatList
                    data={awayTimesQuery.data.awayTimes}
                    renderItem={item => renderAwayTime(item.item)}
                    ItemSeparatorComponent={Divider}
                    ListFooterComponent={awayTimesQuery.data.awayTimes.length > 0 ? Divider : null}
                    scrollEnabled={true}
                    keyExtractor={item => item.residentAwayTimeId.toString()}
                />
            )}
            {dialogVisible && renderDialog()}
        </SafeAreaView>
    );
});

const styles = StyleSheet.create({
    createAwayTimeButton: {
        marginTop: -100,
    },
    webItemGroup1: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        width: '80%',
    },
    plusIcon: {
        marginTop: 'auto',
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    editMargin: {
        marginRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    awayTimeFlexWrapper: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
    },
    mobileAwayTimeItemGroup1: {
        width: '75%',
        justifyContent: 'flex-start',
    },
    mobileAwayTimeItem3: {
        width: '25%',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    awayTimeItem3: {
        width: '20%',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    dialogBody: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 4,
        alignItems: 'center',
    },
    dialogButtons: {
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT,
    },
    awayTimeResidentsList: {
        flex: 1,
        flexDirection: 'row',
    },
    awayTimeResidentsListResidents: {
        flexWrap: 'wrap',
        flex: 1,
    },
    residentsWidth: {
        width: '55%',
    },
    dateWidth: {
        width: '45%',
    },
    headerDivider: {
        height: 2,
    },
});

export default AwayTimes;
