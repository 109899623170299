import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { ReopenTicketResultV1 } from './ReopenTicketResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.ReopenTicketCommandV1
 */
export interface ReopenTicketCommandV1 extends AbstractResidentAuthenticatedCommand<ReopenTicketResultV1> {
    ticketId: number;
    message: string;
}

export function createReopenTicketCommandV1(): ReopenTicketCommandV1 {
    return {
        ticketId: 0,
        message: '',
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.ReopenTicketCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
