import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ZegoGatewayPaymentMethodDo } from 'models/remotecmds/com/ocs/nirvana/businesslogic/integration/paylease/dataobjects/ZegoGatewayPaymentMethodDo';
import { globalStyles } from 'theme/style/GlobalStyles';
import InputRadioSimple from 'components/input/InputRadioSimple';
import PaymentMethodDescription from 'components/payments/PaymentMethodDescription';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Hyperlink from 'components/primitives/Hyperlink';
import { Heading3 } from 'components/primitives/StyledText';

type PaymentMethodsProps = {
    selectedPaymentMethod: string | undefined;
    paymentMethods: Array<ZegoGatewayPaymentMethodDo> | undefined;
    onPaymentMethodSelected: (paymentMethod: ZegoGatewayPaymentMethodDo) => void;
    onClickDeletePaymentMethod?: (paymentMethod: ZegoGatewayPaymentMethodDo) => void;
};

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
    selectedPaymentMethod,
    paymentMethods,
    onPaymentMethodSelected,
    onClickDeletePaymentMethod,
}: PaymentMethodsProps) => {
    if (paymentMethods && paymentMethods.length > 0) {
        return (
            <View style={globalStyles.sectionSpaceBelow}>
                {paymentMethods && paymentMethods.length > 0 && (
                    <View style={globalStyles.sectionSpaceBelow}>
                        <Heading3>Saved Payment Methods</Heading3>
                    </View>
                )}
                {paymentMethods.map(pm => (
                    <View style={styles.paymentMethod} key={pm.paymentMethodId}>
                        <View style={globalStyles.flexRow}>
                            <InputRadioSimple
                                value={pm.paymentMethodId.toString()}
                                active={selectedPaymentMethod === pm.paymentMethodId.toString()}
                                onClick={value => {
                                    onPaymentMethodSelected(pm);
                                }}
                                style={styles.paymentMethodRadio}
                            />
                            <PaymentMethodDescription paymentMethod={pm} />
                        </View>
                        {onClickDeletePaymentMethod && (
                            <View>
                                <Hyperlink onClick={() => onClickDeletePaymentMethod(pm)}>Delete</Hyperlink>
                            </View>
                        )}
                    </View>
                ))}
            </View>
        );
    } else {
        return null;
    }
};

export default PaymentMethods;

const styles = StyleSheet.create({
    paymentMethod: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    paymentMethodRadio: {
        marginTop: 2,
        marginRight: SmarthubTheme.layout.GRIDINCREMENT * 2,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
});
