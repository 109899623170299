import * as React from 'react';
import { View, TextInput, StyleProp, StyleSheet, Platform } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { RegularText } from 'components/primitives/StyledText';
import { MutableRefObject, useEffect } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { formatPhoneNo } from 'utils/StringUtils';
import { InputStyles } from 'theme/style/InputStyles';
import { IconDefinition } from 'theme/IconDefinition';
import { useStore } from 'contexts/StoreContextProvider';
import { testProps } from 'components/ComponentTypes';

interface InputPhoneProps {
    testID?: string;
    accessibilityLabel?: string;
    inputStyle?: 'normal' | 'transparent';
    label?: string;
    placeholder?: string;
    initialValue?: string;
    autoFormat?: boolean;
    autoFocus?: boolean;
    error?: boolean;
    errorMessage?: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    multiline?: boolean;
    numberOfLines?: number;
    inputRef?: MutableRefObject<TextInput>;
    icon?: IconDefinition;
}

const defaultProps: InputPhoneProps = {
    inputStyle: 'normal',
    autoFormat: true,
    autoFocus: false,
    error: false,
};

const InputPhone: React.FC<InputPhoneProps> = (props: InputPhoneProps) => {
    const [text, setText] = React.useState('');

    const { uiStore } = useStore();

    useEffect(() => {
        if (props.initialValue) {
            setText(formatPhoneNo(props.initialValue));
        }
    }, [props.initialValue]);

    const _handleChange = (newText: string) => {
        if (props.autoFormat) {
            const cleanInput = ('' + newText).replace(/\D/g, '');

            const formattedNumber = `${cleanInput.substring(0, 3)}${
                cleanInput.length > 3 ? '-' : ''
            }${cleanInput.substring(3, 6)}${cleanInput.length > 6 ? '-' : ''}${cleanInput.substring(6, 11)}`;

            setText(formatPhoneNo(formattedNumber));
        } else {
            setText(newText.replace(/\D/g, ''));
        }

        if (props.onChange) {
            props.onChange(newText.replace(/\D/g, ''));
        }
    };

    const _onBlur = () => {
        if (props.onBlur) {
            props.onBlur(text);
        }
    };

    let style: StyleProp<any> = props.error ? [InputStyles.input, InputStyles.inputError] : [InputStyles.input];

    if (props.inputStyle === 'transparent') {
        style = props.error ? [InputStyles.inputTransparent, InputStyles.inputError] : [InputStyles.inputTransparent];
    }

    style = props.icon
        ? props.icon.alignment === 'right'
            ? [style, InputStyles.inputIconRight]
            : [style, InputStyles.inputIcon]
        : style;
    if (props.multiline && props.numberOfLines) {
        style = [style, { minHeight: props.numberOfLines * 20, textAlignVertical: 'top' }];
    }
    if (uiStore.useDarkTheme) {
        style = [style, { color: SmarthubTheme.colors.white }];
    }

    return (
        <View style={InputStyles.inputWrapper}>
            <TextInput
                testID={props.testID}
                accessibilityLabel={props.accessibilityLabel}
                style={style}
                placeholder={props.placeholder}
                placeholderTextColor={SmarthubTheme.colors.lightgrey}
                numberOfLines={props.numberOfLines}
                value={text}
                maxLength={16}
                autoFocus={props.autoFocus}
                keyboardType={'phone-pad'}
                onChangeText={text => _handleChange(text)}
                onBlur={_onBlur}
                ref={props.inputRef}
            />
            {props.icon && (
                <MaterialCommunityIcons
                    {...testProps('generic-phone-icon')}
                    style={props.icon.alignment === 'right' ? styles.iconRight : styles.icon}
                    name={props.icon.iconID}
                    size={props.icon.size ? props.icon.size : SmarthubTheme.layout.ICONWIDTH}
                    color={props.icon.color}
                />
            )}
            {!!props.errorMessage && (
                <RegularText {...testProps('generic-phone-error')} style={InputStyles.inputErrorMessage}>
                    {props.errorMessage}
                </RegularText>
            )}
        </View>
    );
};

InputPhone.defaultProps = defaultProps;

export default InputPhone;

const styles = StyleSheet.create({
    icon: {
        position: 'absolute',
        top: Platform.OS === 'android' ? 16 : 10,
        left: 0,
    },
    iconRight: {
        position: 'absolute',
        top: Platform.OS === 'android' ? 16 : 10,
        right: 0,
    },
});
