export function testProps(id: string) {
    return {
        testID: id,
        accessibilityLabel: id,
    };
}

export function updateStringForIDName(id: string) {
    return id.split(' ').join('-').toLowerCase();
}
