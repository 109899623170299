import { UIStore } from 'stores/domain/UIStore';
import * as Linking from 'expo-linking';
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import { getItem, removeItem, setItem } from 'utils/storage';
import { Platform } from 'react-native';
import { STORAGE_KEY_UNVERIFIED_EMAIL } from 'utils/storage-keys';
import { getSmarthubRPCHandler } from 'utils/AppConfigHelper';
import { UserSessionStore } from 'stores/ui/UserSessionStore';
import { residencyInfo } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';

export const deepLinkRedirector = getSmarthubRPCHandler() + 'smarthubProxyRedirect'; //this one is in encasa external

interface DeepLink {
    link: DeepLinks;
    handler: (url: string, uiStore: UIStore) => Promise<any>;
}

enum DeepLinks {
    Login = 'login',
}
export default DeepLinks;

//Functions that handle deep links...

export const deepLinkHandler = async (linkingHandler: Linking.URLListener) => {
    //If the app is not already open, it is opened and the url is passed in as the initialURL
    const url: string | null = await Linking.getInitialURL();
    if (url) {
        logInfo(INFO_LOGGER, 'Initial URL is ', url);
        linkingHandler({ url: url });
    }
};

export const handleLink = (url: string, uiStore: UIStore, userSessionStore: UserSessionStore) => {
    if (url) {
        _checkForPreselectedResidency(url, userSessionStore);
        const foundHandler = DeepLinkHandlers.find(deepLink => url.indexOf(deepLink.link) > -1);
        if (foundHandler) {
            foundHandler.handler(url, uiStore);
        } else {
            //TODO no special handler so just grab the path and redirect to the correct route
            // OR have the authentication hydration
            logInfo(INFO_LOGGER, 'Initial url to handle is ...', url);
        }
    }
};

export const _checkForPreselectedResidency = (url: string, userSessionStore: UserSessionStore) => {
    if (Platform.OS === 'web') {
        const urlObject = new URL(url);
        const residencyString = urlObject.searchParams.get('residency');

        if (residencyString) {
            const [propertyCd, companyId, residencyId] = residencyString.split('-');
            if (propertyCd && companyId && residencyId) {
                userSessionStore.getLocalAuthorizedResidencyInfoList().then(data => {
                    const authorizedResidencies: residencyInfo[] = JSON.parse(data || '[]');

                    if (authorizedResidencies && authorizedResidencies.length > 0) {
                        if (
                            !authorizedResidencies.find(
                                r => r.propertyCd === propertyCd && r.residencyId === Number(residencyId),
                            )
                        ) {
                            userSessionStore.deauthorize();
                        }
                    }
                });

                const residencyKey = companyId + '-' + residencyId;
                userSessionStore.saveResidencyInfo(residencyKey, propertyCd, Number(residencyId));
            }
        }
    }
};

let magicLinkLoginActive = false;

const isAdminInitiatedLogin = (url: string): boolean => {
    return new URL(url).searchParams.get('params') !== null;
};

const _handleMagicLinkLogin = async (url: string, uiStore: UIStore) => {
    const auth = getAuth();
    //handles double login / browser issues on some devices
    if (magicLinkLoginActive) {
        logInfo(INFO_LOGGER, 'Handling magic link ALREADY ACTIVE. Returning without processing link...', url);
        return;
    }

    //if the link isn't valid get outta here
    if (!isSignInWithEmailLink(auth, url)) {
        logInfo(
            INFO_LOGGER,
            'Magic link was not so magic (not a sign in link according to isSignInWithEmailLink()). Returning without processing link...',
            url,
        );
        return;
    }

    const adminInitiatedLogin: boolean = isAdminInitiatedLogin(url);

    if (adminInitiatedLogin) {
        return;
    }

    logInfo(INFO_LOGGER, 'Handling magic link login; url:', url);

    magicLinkLoginActive = true;

    const email: string | null = await getItem(STORAGE_KEY_UNVERIFIED_EMAIL);

    try {
        if (email) {
            logInfo(INFO_LOGGER, 'unverifiedEmail is ', email);
            if (auth.currentUser) {
                uiStore.showAlert({
                    message:
                        'You are already signed in. Go to More / Sign Out if you want to change who you are signed in as.',
                });
                return;
            }
            await removeItem(STORAGE_KEY_UNVERIFIED_EMAIL);
            const result = await signInWithEmailLink(auth, email, url);
            logInfo(INFO_LOGGER, 'signInWithEmailLink SUCCESSFUL');
            if (!result.user) {
                uiStore.showAlert({
                    title: 'Sign In Failed',
                    message:
                        'We were unable to sign you in using the link. You can try sending it again or use an alternative method to login.',
                });
            }
        } else {
            uiStore.setShowLoginEmailMissing(true);
        }
    } catch (error: any) {
        logInfo(INFO_LOGGER, JSON.stringify(error));

        if (error.code === 'auth/invalid-action-code') {
            uiStore.showAlert({
                title: 'Sign In Link Used',
                message: 'The sign in link has already been used.',
            });
        } else if (error.code === 'auth/email-already-in-use') {
            uiStore.showAlert({
                title: 'Sign In Link Used',
                message: 'The email is already in use.',
            });
        } else {
            uiStore.showAlert({
                title: 'Sign In Failed',
                message:
                    'We were unable to sign you in using the link. You can try sending it again or use an alternative method to login.',
            });
            logError(ERROR_LOGGER, 'Error logging in with email ' + (email || 'noemail') + ' ' + error.message);
        }
    }
    magicLinkLoginActive = false;
};

const DeepLinkHandlers: DeepLink[] = [
    {
        link: DeepLinks.Login,
        handler: _handleMagicLinkLogin,
    },
];

//Functions that create deep links...
export const sendMagicLoginLink = (email: string, uiStore: UIStore): Promise<void> => {
    const auth = getAuth();
    const expoLink: string = Linking.createURL('smarthub/' + DeepLinks.Login);

    const proxyUrl =
        Platform.OS === 'web' ? expoLink : `${deepLinkRedirector}?redirectUrl=${encodeURIComponent(expoLink)}`;
    logInfo(INFO_LOGGER, 'expoLink is : ', expoLink);
    logInfo(INFO_LOGGER, 'proxy is : ', proxyUrl);

    setItem(STORAGE_KEY_UNVERIFIED_EMAIL, email);

    return sendSignInLinkToEmail(auth, email, {
        handleCodeInApp: true,
        url: proxyUrl,
    });
};
