import * as Sentry from 'sentry-expo';
import { User } from '@sentry/types';

export const setUser = (sentryUser: User) => {
    Sentry.Browser.setUser(sentryUser);
};

export const captureMessage = (message: string, captureContext?: any): string => {
    return Sentry.Browser.captureMessage(message, captureContext);
};

export const captureException = (exception: any): string => {
    return Sentry.Browser.captureException(exception);
};
