import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useStore } from 'contexts/StoreContextProvider';
import { RegularText, SmallText } from 'components/primitives/StyledText';
import Button from 'components/primitives/Button';
import Dialog from 'components/primitives/Dialog';
import { testProps } from 'components/ComponentTypes';

export interface AlertButton {
    buttonType?: 'primary' | 'secondary';
    buttonText: string;
    inactive?: boolean;
    onClick?: () => void;
    testID?: string;
    accessibilityLabel?: string;
}

export interface AlertConfig {
    title?: string;
    message: string;
    buttons?: Array<AlertButton>;
    dismissByButtonOnly?: boolean;
    notDismissible?: boolean;
}

const Alert: React.FC = () => {
    const { uiStore } = useStore();

    if (uiStore.alert) {
        return (
            <Dialog
                {...testProps('generic-alert')}
                visible={true}
                onDismiss={
                    uiStore.alert.dismissByButtonOnly || uiStore.alert.notDismissible ? undefined : uiStore.hideAlert
                }>
                <View style={styles.alert}>
                    {uiStore.alert.title && <RegularText style={styles.alertTitle}>{uiStore.alert.title}</RegularText>}

                    <SmallText {...testProps('alert-message')} style={styles.alertMessage}>
                        {uiStore.alert.message}
                    </SmallText>

                    {uiStore.alert.buttons && (
                        <View style={styles.alertButtons}>
                            {uiStore.alert.buttons.map((button, index) => {
                                return (
                                    <View key={`alert-button-${index}`} style={styles.alertButton}>
                                        <Button
                                            buttonType={button.buttonType}
                                            inactive={button.inactive}
                                            testID={button.buttonText}
                                            accessibilityLabel={button.buttonText}
                                            onClick={button.onClick}>
                                            {button.buttonText}
                                        </Button>
                                    </View>
                                );
                            })}
                        </View>
                    )}

                    {!uiStore.alert.buttons && !uiStore.alert.notDismissible && (
                        <Button
                            {...testProps('dismiss-button')}
                            onClick={() => {
                                uiStore.hideAlert();
                            }}>
                            Dismiss
                        </Button>
                    )}
                </View>
            </Dialog>
        );
    } else {
        return null;
    }
};

export default Alert;

const styles = StyleSheet.create({
    alert: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    alertTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    alertMessage: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    alertButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    alertButton: {
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
});
