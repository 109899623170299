import React, { useEffect, useState } from 'react';
import { MediumBoldText } from 'components/primitives/StyledText';
import InputRadio from 'components/input/InputRadio';
import { useNavigation } from '@react-navigation/native';
import { globalStyles } from 'theme/style/GlobalStyles';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { useSecurityOptionsQuery } from 'queries/hooks/smartmove/useSecurityOptionsQuery';
import { useStore } from 'contexts/StoreContextProvider';
import { useQueryClient } from 'react-query';
import { observer } from 'mobx-react-lite';
import { useCommandMutation } from 'queries/hooks/useCommandQuery';
import {
    createSaveSmartmoveSecuritySelectionCommandV1,
    SaveSmartmoveSecuritySelectionCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/SaveSmartmoveSecuritySelectionCommandV1';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormikHelpers } from 'formik/dist/types';
import { SaveSmartmoveSecuritySelectionResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/SaveSmartmoveSecuritySelectionResultV1';
import { GetSmartmoveApplicationHeaderResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveApplicationHeaderResultV1';

const SmartMoveDeposit: React.FC = observer(() => {
    const navigation = useNavigation();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    const applicationHeader = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );

    const { securityQueryKey, securityQueryResult } = useSecurityOptionsQuery(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );

    const initialValues = createSaveSmartmoveSecuritySelectionCommandV1();
    initialValues.propertyCd = sessionActiveResidency.propertyCd;
    initialValues.residencyId = sessionActiveResidency.residencyId;
    initialValues.securityChoice =
        securityQueryResult.data?.currentSelection && securityQueryResult.data.currentSelection != 'NoChoice'
            ? securityQueryResult.data.currentSelection
            : 'SecurityFee';

    const securityChoiceMutation = useCommandMutation<
        SaveSmartmoveSecuritySelectionCommandV1,
        SaveSmartmoveSecuritySelectionResultV1
    >(uiStore, {
        onSuccess: result => {
            queryClient.setQueriesData(applicationHeader.queryKey, old => {
                return {
                    ...(old as GetSmartmoveApplicationHeaderResultV1),
                    smartmoveApplicationHeaderV1: result.smartmoveApplicationHeaderV1,
                };
            });

            applicationHeader.routeUser(
                result.smartmoveApplicationHeaderV1.smartmoveStep,
                result.smartmoveApplicationHeaderV1.workflowStatus,
                navigation,
            );
            queryClient.invalidateQueries(securityQueryKey);
            queryClient.invalidateQueries(applicationHeader.queryKey);
        },
    });

    const _onSubmit = (
        values: SaveSmartmoveSecuritySelectionCommandV1,
        formikHelpers: FormikHelpers<SaveSmartmoveSecuritySelectionCommandV1>,
    ) => {
        securityChoiceMutation.mutate(values);
    };

    const _validationSchema = yup.object().shape({
        securityChoice: yup.string().oneOf(['SecurityFee', 'SecurityDeposit'], 'Please select an option.'),
    });

    if (!securityQueryResult.data || securityQueryResult.isLoading) return null;

    return (
        <Formik
            enableReinitialize={true}
            validateOnMount={false}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            onSubmit={_onSubmit}
            validationSchema={_validationSchema}>
            {formikHelper => (
                <SmartmoveScreenContainer
                    progressIndicatorProps={{
                        currentStep: 0,
                        currentStepInProgress: true,
                    }}
                    footerButtonProps={{
                        text: 'Continue',
                        onClick: formikHelper.handleSubmit,
                        disabled: securityChoiceMutation.isLoading,
                    }}>
                    <MediumBoldText style={globalStyles.smartMoveTitleText}>
                        Confirm your preference below
                    </MediumBoldText>
                    <InputRadio
                        style={'small'}
                        options={securityQueryResult.data?.securityOptions}
                        initialValue={formikHelper.values.securityChoice}
                        onChange={formikHelper.handleChange('securityChoice')}
                        errorMessage={formikHelper.errors.securityChoice}
                    />
                </SmartmoveScreenContainer>
            )}
        </Formik>
    );
});

export default SmartMoveDeposit;
