import { QueryKeys } from 'queries/QueryKeys';
import { UIStore } from 'stores/domain/UIStore';
import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UseQueryOptions } from 'react-query';
import { GetPaymentMethodsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetPaymentMethodsResultV1';
import {
    createGetPaymentMethodsCommandV1,
    GetPaymentMethodsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetPaymentMethodsCommandV1';

export function generatePaymentMethodsQueryKey(propertyCd: string, residentId: number): (string | number)[] {
    return [QueryKeys.PaymentMethods, propertyCd, residentId];
}

export const usePaymentMethods = (
    propertyCd: string,
    residentId: number,
    isReservationYn: boolean,
    applyMoveInPaymentRestrictions: boolean,
    enabled: boolean,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetPaymentMethodsResultV1>,
) => {
    //make the  query key
    const queryKey = generatePaymentMethodsQueryKey(propertyCd, residentId);

    //make the command
    const theCommand = createGetPaymentMethodsCommandV1();
    theCommand.propertyCd = propertyCd;
    theCommand.residentId = residentId;
    theCommand.applyMoveInPaymentRestrictions = applyMoveInPaymentRestrictions;
    theCommand.isReservationYn = isReservationYn;

    //call the command
    const query = useCommandQuery<GetPaymentMethodsCommandV1, GetPaymentMethodsResultV1>(
        theCommand,
        uiStore,
        queryKey,
        queryOptions || {
            enabled: enabled,
        },
    );

    return {
        queryKey,
        query,
    };
};
