export type BankAccountType = 'Checking' | 'Savings';

export type NullableBankAccountType = BankAccountType | null;

export const BankAccountTypeOptions = [
    { value: 'Checking', label: 'Checking' },
    { value: 'Savings', label: 'Savings' },
];

export const BankAccountTypeOptionsWithBlank = [{ value: '', label: '' }].concat(BankAccountTypeOptions);

/* START CUSTOM CODE */
/* END CUSTOM CODE */
