import React from 'react';
import { StyleProp, TouchableOpacity } from 'react-native';

export interface LinkProps {
    testID?: string;
    target?: string;
    children?: React.ReactNode;
    onClick?: () => void;
    style?: StyleProp<any>;
}

const defaultProps: LinkProps = {};

const Link: React.FC<LinkProps> = (props: LinkProps) => {
    const _handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <TouchableOpacity
            testID={props.testID}
            onPress={() => {
                _handleClick();
            }}
        >
            {props.children}
        </TouchableOpacity>
    );
};

Link.defaultProps = defaultProps;

export default Link;
