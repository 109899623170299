import axios from 'axios';
import { ERROR_LOGGER, INFO_LOGGER, RPC_COMMAND_LOGGER, RPC_RESULT_LOGGER } from 'utils/logging/Loggers';
import { logError, logInfo, shouldLog } from 'utils/logging/Logger';
import {
    BusinessExceptionErrorDetail,
    EMPTY_RESULT,
    RemoteCmdBusinessError,
    RemoteCmdServerError,
} from './RemoteCmdMgrUtils';
import { getAuth, User } from 'firebase/auth';
import * as SentryHelper from 'utils/logging/SentryHelper';
import { Linking, PixelRatio, Platform } from 'react-native';
import { UIStore } from 'stores/domain/UIStore';
import * as Updates from 'expo-updates';
import { AlertButton } from 'components/primitives/Alert';
import Constants from 'expo-constants';
import { getSmarthubRPCHandler } from 'utils/AppConfigHelper';

export interface VersionedRemoteResultPayload<R> {
    result: R;
    businessErrors: Array<BusinessExceptionErrorDetail>;
    serverExceptionDetails: string;
}

export async function dispatchCommandAsync<C, R>(
    uiStore: UIStore | null,
    cmd: C,
    ignoreNetworkErrors: boolean,
    currentUser?: User | null,
    skipAuthorization = false,
): Promise<R> {
    const auth = getAuth();
    if (null == currentUser) {
        currentUser = auth.currentUser;
    }
    //here we must make sure they are logged in and we have a valid token
    if (null != currentUser || skipAuthorization) {
        const targetServer = getSmarthubRPCHandler() + 'externalVersionedRemoteCommandDispatch';
        let authorizationToken = '';
        if (!skipAuthorization && null != currentUser) {
            authorizationToken = await currentUser.getIdToken();
        }
        //TODO pass the expo push token
        //cmd.expoPushToken = await userModel.expoPushToken;

        if (shouldLog(RPC_COMMAND_LOGGER)) {
            // console.group("REMOTE COMMAND DISPATCH")
            logInfo(RPC_COMMAND_LOGGER, 'RPC Command::::::', cmd);
        }

        return new Promise((resolve, reject) =>
            _makeRpcCall(
                uiStore,
                targetServer,
                cmd,
                resolve,
                reject,
                ignoreNetworkErrors,
                authorizationToken,
                currentUser || null,
                skipAuthorization,
            ),
        );
    } else {
        logError(ERROR_LOGGER, 'No current user');
        SentryHelper.captureMessage("No current user'");
        return new Promise((resolve, reject) => reject());
    }
}

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

async function _makeRpcCall<C, R>(
    uiStore: UIStore | null,
    targetServer: string,
    cmd: C,
    resolve: (arg0: R) => void,
    reject: {
        (reason?: any): void;
        (arg0: {
            errType: 'BUSINESS_ERR' | 'SERVER_ERR';
            businessErrors?: BusinessExceptionErrorDetail[];
            serverExceptionDetails?: string;
        }): void;
    },
    ignoreNetworkErrors: boolean,
    authorizationToken: string,
    currentUser: User | null,
    skipAuthorization = false,
) {
    let response;
    const auth = getAuth();
    try {
        logInfo(INFO_LOGGER, 'Font Scale is ', PixelRatio.getFontScale());
        logInfo(RPC_COMMAND_LOGGER, 'We are ' + (!ignoreNetworkErrors ? 'not ' : '') + 'ignoring network errors');

        response = await axios({
            headers: { Authorization: authorizationToken },
            cancelToken: source.token,
            method: 'post',
            url: targetServer,
            timeout: 60000, //Set the timeout to a minute
            data: cmd,
        });

        //some sort of problem
        if (response.status !== 200) {
            if (ignoreNetworkErrors) {
                //ignoring our problems
                resolve(EMPTY_RESULT);
            } else {
                logError(ERROR_LOGGER, 'COMMUNICATION_ERR: Status Code: ' + response.status);

                logInfo(INFO_LOGGER, 'ANDROID FUN 1' + JSON.stringify(response));

                SentryHelper.captureException(response);

                _showErrorAlert(
                    uiStore,
                    targetServer,
                    cmd,
                    resolve,
                    reject,
                    ignoreNetworkErrors,
                    authorizationToken,
                    [], //responseJson.businessErrors,
                    '', //responseJson.serverExceptionDetails,
                    currentUser,
                    skipAuthorization,
                );
                reject('COMMUNICATION_ERR: Status Code: ' + response.status);
                return;
            }
        } else {
            //no major problems so continue...

            // Examine the text in the response
            const responseJson: VersionedRemoteResultPayload<R> = response.data;

            if (responseJson.businessErrors && responseJson.businessErrors.length !== 0) {
                logError(ERROR_LOGGER, 'BUSINESS_ERR: ' + JSON.stringify(responseJson.businessErrors));

                const busError: RemoteCmdBusinessError = {
                    errType: 'BUSINESS_ERR',
                    businessErrors: responseJson.businessErrors,
                };

                const notAuthError: BusinessExceptionErrorDetail | undefined = busError.businessErrors.find(
                    (e: BusinessExceptionErrorDetail) => e.code === 'NOT_AUTHORIZED',
                );

                const notAuthorized: boolean = notAuthError !== undefined;

                if (notAuthorized) {
                    SentryHelper.captureException(notAuthError);
                    reject(busError);
                    uiStore?.rootStore.userSessionStore.deauthorize();
                    uiStore?.hideActivityLoader();
                    setTimeout(() => {
                        uiStore?.showAlert({
                            title: 'You are not authorized. Please try logging in again.',
                            message: notAuthError ? notAuthError?.desc : '',
                        });
                    }, 1000);
                    return;
                } else {
                    SentryHelper.captureException(busError);

                    _showErrorAlert(
                        uiStore,
                        targetServer,
                        cmd,
                        resolve,
                        reject,
                        ignoreNetworkErrors,
                        authorizationToken,
                        responseJson.businessErrors,
                        responseJson.serverExceptionDetails,
                        currentUser,
                        skipAuthorization,
                    );

                    reject(busError);
                    return;
                }
            } else if (responseJson.serverExceptionDetails && responseJson.serverExceptionDetails !== '') {
                logError(ERROR_LOGGER, 'SERVER_ERR: ' + responseJson.serverExceptionDetails);
                SentryHelper.captureException(responseJson.serverExceptionDetails);
                if (responseJson.serverExceptionDetails.includes('Cannot find handler for command')) {
                    _showUpgradeRequiredAlert(uiStore);
                    return;
                }
                const servError: RemoteCmdServerError = {
                    errType: 'SERVER_ERR',
                    serverExceptionDetails: responseJson.serverExceptionDetails,
                };
                _showErrorAlert(
                    uiStore,
                    targetServer,
                    cmd,
                    resolve,
                    reject,
                    ignoreNetworkErrors,
                    authorizationToken,
                    responseJson.businessErrors,
                    responseJson.serverExceptionDetails,
                    currentUser,
                    skipAuthorization,
                );

                reject(servError);
                return;
            } else if (responseJson.result == null) {
                //Non JSON response, could be the firewall error message, treat as COMMUNICATION_ERR
                if (ignoreNetworkErrors) {
                    resolve(EMPTY_RESULT); //Return success
                } else {
                    const errstring = 'COMMUNICATION_ERR: Status Code: NO JSON RESPONSE';
                    logError(ERROR_LOGGER, errstring);
                    SentryHelper.captureMessage(errstring);
                    _showErrorAlert(
                        uiStore,
                        targetServer,
                        cmd,
                        resolve,
                        reject,
                        ignoreNetworkErrors,
                        authorizationToken,
                        responseJson.businessErrors,
                        responseJson.serverExceptionDetails,
                        currentUser,
                        skipAuthorization,
                    );

                    reject(errstring);
                }
                return;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment

            if (
                !skipAuthorization &&
                auth.currentUser &&
                auth.currentUser?.uid &&
                // @ts-ignore // how to type this??
                (!responseJson.result.identifier || auth.currentUser?.uid !== responseJson.result.identifier)
            ) {
                const identifierDontMatchError: BusinessExceptionErrorDetail = {
                    code: 'NOT_AUTHORIZED',
                    desc: 'The user that requested information from smarthub does not match the user that has been granted access',
                };

                uiStore?.showAlert({
                    title: 'Not Authorized',
                    message: identifierDontMatchError ? identifierDontMatchError.desc : '',
                });

                reject(identifierDontMatchError);
                return;
            }

            if (shouldLog(RPC_RESULT_LOGGER)) {
                //console.group(`REMOTE RESULT: OK`);
                logInfo(RPC_RESULT_LOGGER, 'RPC Result::::::', responseJson.result);
                //console.groupEnd();
            }

            if (shouldLog(RPC_COMMAND_LOGGER)) {
                //console.groupEnd()
            }

            logInfo(RPC_COMMAND_LOGGER, 'Resolving result of RPC call...');
            resolve(responseJson.result);
        }
    } catch (err) {
        SentryHelper.captureException(err);
        if (ignoreNetworkErrors) {
            resolve(EMPTY_RESULT); //Return success
        } else {
            logInfo(RPC_RESULT_LOGGER, 'COMMUNICATION_ERR2: ', JSON.stringify(err));

            let responseJson: VersionedRemoteResultPayload<R> | undefined = undefined;
            if (response && response.data) {
                responseJson = response.data;
            }

            _showErrorAlert(
                uiStore,
                targetServer,
                cmd,
                resolve,
                reject,
                ignoreNetworkErrors,
                authorizationToken,
                (responseJson && responseJson.businessErrors) || [],
                (responseJson && responseJson.serverExceptionDetails) || '',
                currentUser,
                skipAuthorization,
            );

            reject('COMMUNICATION_ERR2: ' + JSON.stringify(err));
        }
    }
}

function _showErrorAlert<C, R>(
    uiStore: UIStore | null,
    targetServer: string,
    cmd: C,
    resolve: (arg0: R) => void,
    reject: any,
    ignoreNetworkErrors: boolean,
    authorizationToken: string,
    businessErrors: Array<BusinessExceptionErrorDetail>,
    serverExceptionDetails: string,
    currentUser: User | null,
    skipAuthorization = false,
) {
    const isInternetReachable: boolean | null | undefined = uiStore?.isInternetReachable;

    logInfo(
        INFO_LOGGER,
        'is it reachable or what??? ' + isInternetReachable || 'isInternetReachable is null or whatever',
    );

    const errors =
        (businessErrors &&
            businessErrors.reduce((acc, word) => {
                if (acc.length > 0) {
                    acc += '\n';
                }
                acc += word.desc;
                return acc;
            }, '')) ||
        [];

    const errorTitle = 'Woops!';
    const theError =
        'We are having problems getting information from SMARTHUB. ' +
        (!isInternetReachable
            ? 'It looks like your internet connection may be down. Check your connection and click Try Again. '
            : '') +
        'If the problem persists you can call the community office.' +
        (errors.length > 0 ? '\n\nMore Info: ' : '') +
        errors;

    const buttons: Array<AlertButton> = [
        {
            buttonText: 'Try Again',
            onClick: () => {
                uiStore?.hideAlert();
                _makeRpcCall(
                    uiStore,
                    targetServer,
                    cmd,
                    resolve,
                    reject,
                    ignoreNetworkErrors,
                    authorizationToken,
                    currentUser,
                    skipAuthorization,
                );
            },
        },
    ];
    if (Platform.OS !== 'web' && uiStore?.rootStore.userSessionStore.sessionActiveResidency?.propertyPhoneNumber) {
        buttons.push({
            buttonText: 'Call Us',
            buttonType: 'secondary',
            onClick: () =>
                _sendSupportRequest(
                    businessErrors,
                    serverExceptionDetails,
                    currentUser,
                    uiStore.rootStore.userSessionStore.sessionActiveResidency.propertyPhoneNumber,
                ),
        });
    }
    buttons.push({
        buttonText: 'Refresh App',
        buttonType: 'secondary',
        onClick: () => {
            if (Platform.OS === 'web') {
                const weburl = (Constants?.expoConfig?.extra && Constants.expoConfig.extra['webUrl']) || '';
                if (weburl) {
                    window.location.replace(weburl);
                } else {
                    window.location.reload();
                }
            } else {
                Updates.reloadAsync();
            }
        },
    });

    uiStore?.showAlert({
        title: errorTitle,
        message: theError,
        buttons: buttons,
        dismissByButtonOnly: true,
    });
    //}
}

function _sendSupportRequest(
    businessErrors: Array<BusinessExceptionErrorDetail>,
    serverExceptionDetails: string,
    currentUser: User | null,
    phoneNumber: string,
) {
    //TODO send support request! // expo install expo-mail-composer
    let dialUrl = '';

    if (Platform.OS === 'android' || Platform.OS === 'web') {
        dialUrl = 'tel:${' + phoneNumber + '}';
    } else {
        dialUrl = 'telprompt:${' + phoneNumber + '}';
    }

    Linking.openURL(dialUrl);
}

function _showUpgradeRequiredAlert(uiStore: UIStore | null) {
    /*Alert.alert(
        'Application Upgrade Required',
        'Smarthub needs to be upgraded in order for you to continue using it.',
        [
            {
                text: 'Open Installation Instructions',
                onPress: () => WebBrowser.openBrowserAsync('TO BE IMPLEMENTED').catch(e => {}),
            },
        ],
        { cancelable: false },
    );*/
    uiStore?.showAlert({
        title: 'Application Upgrade Required',
        message: 'Smarthub needs to be upgraded in order for you to continue using it.',
    });
}
