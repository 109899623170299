import React, { useEffect, useState } from 'react';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import { Platform, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
    DeephansizedRegularText,
    FormLabelText,
    Heading1,
    RegularText,
    SmallBoldText,
    SmallText,
} from 'components/primitives/StyledText';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { regexPhone } from 'utils/FormUtils';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Button from 'components/primitives/Button';
import InputDropdown from 'components/input/InputDropdown';
import { PhoneNoTypeOptions } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/residency/dataobjects/PhoneNoType';
import { PreferredCommunicationMethodOptions } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/residency/dataobjects/PreferredCommunicationMethod';
import InputString from 'components/input/InputString';
import InputPhone from 'components/input/InputPhone';
import { useNavigation } from '@react-navigation/native';
import Dialog from 'components/primitives/Dialog';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import CloseButton from 'components/primitives/CloseButton';
import FlexContainer from 'components/primitives/FlexContainer';
import FlexItem from 'components/primitives/FlexItem';
import { formatPhoneNo } from 'utils/StringUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { useCommandMutation, useCommandQuery } from 'queries/hooks/useCommandQuery';
import { SmarthubResidentV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentV1';
import {
    createGetResidentProfileCommandV1,
    GetResidentProfileCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentProfileCommandV1';
import {
    createUpdateResidentProfileCommandV1,
    UpdateResidentProfileCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/UpdateResidentProfileCommandV1';
import { QueryKey, useQueryClient } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import { GetResidentProfileResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentProfileResultV1';
import { UpdateResidentProfileResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/UpdateResidentProfileResultV1';
import Section from 'components/primitives/Section';
import InputCheckbox from 'components/input/InputCheckbox';
import { arrayIsEmpty } from 'utils/ArrayUtils';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import useConfirmLeave from 'utils/useConfirmLeave';

const schema = yup.object().shape({
    email: yup.string().email(),
    phone1No: yup.string().matches(regexPhone, 'Please ensure you have entered a valid phone number'),
    phone1TypeCd: yup.string(),
    phone1Notes: yup.string(),
    phone2No: yup.string().matches(regexPhone, 'Please ensure you have entered a valid phone number'),
    phone2TypeCd: yup.string(),
    phone2Notes: yup.string(),
    preferredCommunicationMethodCd: yup.string(),
    guarantorAccessEnabledList: yup.array().of(yup.number()),
});

const Profile: React.FC = () => {
    const [showContactMethodError, setShowContactMethodError] = useState(false);
    const [contactMethodErrorText, setContactMethodErrorText] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [secondaryPhone, setSecondaryPhone] = useState('');
    const [primaryEmail, setPrimaryEmail] = useState('');
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showEmailPhoneDialog, setShowEmailPhoneDialog] = useState(false);
    const [showEmailDialog, setShowEmailDialog] = useState(false);
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const [showPhoneDialog, setShowPhoneDialog] = useState(false);
    const [phoneConfirmed, setPhoneConfirmed] = useState(false);
    const [showSecPhoneDialog, setSecShowPhoneDialog] = useState(false);
    const [phoneSecConfirmed, setSecPhoneConfirmed] = useState(false);

    const theme = useTheme();

    const { userSessionStore, residentStore, uiStore } = useStore();

    const { confirmActiveResidency, hasSessionActiveResidency } = userSessionStore;

    const navigation = useNavigation();

    const { windowWidth } = useResponsiveContext();

    const isMoreOnStack = IsNavigationRouteOnStack(navigationRoutes.moreRoutes.more);

    const hidePreferredCommunicationMethod = userSessionStore.isFeatureEnabled('OptInOut');

    // Instantiate query
    const queryClient = useQueryClient();

    // Set query key
    const queryKey: QueryKey = [QueryKeys.ResidentProfile, userSessionStore.sessionActiveResidency.residentId];

    const testCmd = createGetResidentProfileCommandV1();
    testCmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
    testCmd.residentId = userSessionStore.sessionActiveResidency.residentId;

    const residentProfileQuery = useCommandQuery<GetResidentProfileCommandV1, GetResidentProfileResultV1>(
        testCmd,
        uiStore,
        queryKey,
        {
            enabled: hasSessionActiveResidency,
        },
    );

    const residentMutation = useCommandMutation<UpdateResidentProfileCommandV1, UpdateResidentProfileResultV1>(
        uiStore,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey);
                setEmailConfirmed(false); // Reset flags
                setPhoneConfirmed(false);
                setSecPhoneConfirmed(false);
                setShowConfirmDialog(true); // Show confirmation dialog
            },
        },
    );

    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: createUpdateResidentProfileCommandV1(),
        validationSchema: schema,
        onSubmit: (values: UpdateResidentProfileCommandV1) => {
            _onSubmit(values);
        },
    });

    useConfirmLeave(navigation, formik);

    useEffect(() => {
        if (residentProfileQuery.isFetching) {
            uiStore.showActivityLoader();
        } else if (residentProfileQuery.isFetched) {
            uiStore.hideActivityLoader();

            const queryData = residentProfileQuery.data;

            if (queryData) {
                const updateCmd = createUpdateResidentProfileCommandV1();
                updateCmd.propertyCd = queryData.resident.propertyCd;
                updateCmd.residentId = queryData.resident.residentId;
                updateCmd.resident = queryData.resident;
                updateCmd.guarantorAccessEnabledList = queryData.guarantors
                    .filter(g => g.smarthubAccessEnabledYn)
                    .map(g => g.residentId);
                formik.resetForm({ values: updateCmd });
                setPrimaryPhone(queryData.resident.phone1No);
                setSecondaryPhone(queryData.resident.phone2No);
                setPrimaryEmail(queryData.resident.email);

                navigation.setParams({ title: `${queryData.resident.firstName}'s Profile` });
            }
        }
    }, [residentProfileQuery.isFetching, residentMutation.isLoading]);

    useEffect(() => {
        if (hasSessionActiveResidency) {
            logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
            confirmActiveResidency(navigation);

            if (!isMoreOnStack) {
                navigation.reset({
                    routes: [{ name: navigationRoutes.moreRoutes.more }, { name: navigationRoutes.moreRoutes.profile }],
                    index: 1,
                });
            }
        }
    }, [hasSessionActiveResidency]);

    if (!hasSessionActiveResidency) {
        return null;
    }

    const _handleFieldChange = (field: string, value: string | number | undefined) => {
        formik.setFieldValue(field, value);
    };

    const _onSubmit = (data: UpdateResidentProfileCommandV1, bypassValidation = false) => {
        logInfo(INFO_LOGGER, data);

        // Confirm preferred contact method field values
        if (!_contactMethodConfirm() && !bypassValidation) {
            setShowContactMethodError(true);
            return;
        }

        // If user has changed their primary email and primary phone number
        if (_isPrimaryEmailChanged(data.resident) && _isPrimaryPhoneChanged(data.resident) && !bypassValidation) {
            setShowEmailPhoneDialog(true);
            return;
        }

        // If user has changed their primary email
        if (_isPrimaryEmailChanged(data.resident) && !bypassValidation) {
            setShowEmailDialog(true);
            return;
        }

        // If user has changed their primary phone number
        if (_isPrimaryPhoneChanged(data.resident) && !bypassValidation) {
            setShowPhoneDialog(true);
            return;
        }

        // If user has changed their secondary phone number
        if (
            data.resident.phone2No !== secondaryPhone &&
            data.resident.phone2No.trim() !== '' &&
            !phoneSecConfirmed &&
            !bypassValidation
        ) {
            setSecShowPhoneDialog(true);
            return;
        }

        if (hidePreferredCommunicationMethod) {
            data.resident.preferredCommunicationMethodCd = 'None';
        }

        // Update user details
        residentMutation.mutate(data);
    };

    const _isPrimaryEmailChanged = (data: SmarthubResidentV1): boolean => {
        return data.email !== primaryEmail && data.email.trim() !== '' && !emailConfirmed;
    };

    const _isPrimaryPhoneChanged = (data: SmarthubResidentV1): boolean => {
        return data.phone1No !== primaryPhone && data.phone1No.trim() !== '' && !phoneConfirmed;
    };

    const _contactMethodConfirm = (): boolean => {
        const prefContactMethod = formik.values.resident.preferredCommunicationMethodCd;
        logInfo(INFO_LOGGER, 'prefContactMethod');
        logInfo(INFO_LOGGER, prefContactMethod);

        if (hidePreferredCommunicationMethod) return true;

        if (prefContactMethod !== 'None' && formik.values.resident.email.trim() === '') {
            setContactMethodErrorText('Please enter a valid email address');
            return false;
        }

        if (prefContactMethod === 'EmailAndText' && formik.values.resident.phone1No.trim() === '') {
            setContactMethodErrorText('Please enter a valid primary phone number.');
            return false;
        }

        return true;
    };

    const _emailAndPhoneConfirm = (approved: boolean): void => {
        setShowEmailPhoneDialog(false);
        setEmailConfirmed(approved);
        setPhoneConfirmed(approved);
        if (approved) {
            setTimeout(() => {
                setPrimaryEmail(formik.values.resident.email);
                setPrimaryPhone(formik.values.resident.phone1No);
                _onSubmit(formik.values, true);
            }, 500);
        } else {
            formik.setSubmitting(false);
        }
    };

    const _emailConfirm = (approved: boolean): void => {
        setShowEmailDialog(false);
        setEmailConfirmed(approved);
        if (approved) {
            setTimeout(() => {
                setPrimaryEmail(formik.values.resident.email);
                _onSubmit(formik.values, true);
            }, 500);
        } else {
            formik.setSubmitting(false);
        }
    };

    const _phoneConfirm = (approved: boolean): void => {
        setShowPhoneDialog(false);
        setPhoneConfirmed(approved);
        if (approved) {
            setTimeout(() => {
                setPrimaryPhone(formik.values.resident.phone1No);
                _onSubmit(formik.values, true);
            }, 500);
        } else {
            formik.setSubmitting(false);
        }
    };

    const _phoneSecConfirm = (approved: boolean): void => {
        setSecShowPhoneDialog(false);
        setSecPhoneConfirmed(approved);
        if (approved) {
            setTimeout(() => {
                setSecondaryPhone(formik.values.resident.phone2No);
                _onSubmit(formik.values, true);
            }, 500);
        } else {
            formik.setSubmitting(false);
        }
    };

    const { index, routes } = navigation.dangerouslyGetState();

    if (residentProfileQuery.isFetching) {
        return null;
    }

    return (
        <>
            {routes[index].name === 'ProfileModal' && (
                <CloseButton onClick={() => navigation.goBack()} style={styles.closeButton} />
            )}
            <KeyboardAwareScrollView
                contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
                <View style={styles.contentWrapper}>
                    {showContactMethodError && (
                        <Dialog
                            visible={showContactMethodError}
                            onDismiss={() => {
                                setShowContactMethodError(false);
                            }}>
                            <MaterialCommunityIcons
                                name='head'
                                size={SmarthubTheme.layout.ICONWIDTH}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <View style={styles.dialogBody}>
                                <SmallText>{contactMethodErrorText}</SmallText>
                            </View>
                            <Button
                                {...testProps('dismiss-button')}
                                onClick={() => {
                                    setShowContactMethodError(false);
                                }}>
                                Dismiss
                            </Button>
                        </Dialog>
                    )}

                    {showConfirmDialog && (
                        <Dialog
                            visible={showConfirmDialog}
                            onDismiss={() => {
                                setShowConfirmDialog(false);
                                navigation.navigate('More');
                            }}>
                            <MaterialCommunityIcons
                                name='head'
                                size={SmarthubTheme.layout.ICONWIDTH}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <View style={styles.dialogBody}>
                                <SmallText>Your profile has been updated.</SmallText>
                            </View>
                            <Button
                                {...testProps('dismiss-button')}
                                onClick={() => {
                                    setShowConfirmDialog(false);
                                    formik.setSubmitting(false);
                                    navigation.navigate('More');
                                }}>
                                Dismiss
                            </Button>
                        </Dialog>
                    )}

                    {showEmailPhoneDialog && (
                        <Dialog visible={showEmailPhoneDialog}>
                            <MaterialCommunityIcons
                                name='email'
                                size={SmarthubTheme.layout.ICONWIDTH}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <View style={styles.dialogBody}>
                                <SmallText>You have elected to change your primary email to:</SmallText>
                                <SmallBoldText style={[globalStyles.singleSpaceAbove, globalStyles.singleSpaceBelow]}>
                                    {formik.values.resident.email}
                                </SmallBoldText>
                                <SmallText>And your primary phone number to:</SmallText>
                                <SmallBoldText style={[globalStyles.singleSpaceAbove, globalStyles.singleSpaceBelow]}>
                                    {formatPhoneNo(formik.values.resident.phone1No)}
                                </SmallBoldText>
                                <SmallText>
                                    Please carefully check both the new email address and phone number and confirm that
                                    they belong to you.
                                </SmallText>
                            </View>

                            <FlexContainer style={styles.fieldRow}>
                                <FlexItem>
                                    <Button
                                        {...testProps('cancel-ph-and-e-button')}
                                        buttonType={'secondary'}
                                        style={styles.dialogLeftButton}
                                        onClick={() => {
                                            _emailAndPhoneConfirm(false);
                                        }}>
                                        Cancel
                                    </Button>
                                </FlexItem>
                                <FlexItem>
                                    <Button
                                        {...testProps('confirm-ph-and-e-button')}
                                        style={styles.dialogRightButton}
                                        onClick={() => {
                                            _emailAndPhoneConfirm(true);
                                        }}>
                                        Yes, confirm
                                    </Button>
                                </FlexItem>
                            </FlexContainer>
                        </Dialog>
                    )}

                    {showEmailDialog && (
                        <Dialog visible={showEmailDialog}>
                            <MaterialCommunityIcons
                                name='email'
                                size={SmarthubTheme.layout.ICONWIDTH}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <View style={styles.dialogBody}>
                                <SmallText>You have elected to change your primary email to:</SmallText>
                                <SmallBoldText style={[globalStyles.singleSpaceAbove, globalStyles.singleSpaceBelow]}>
                                    {formik.values.resident.email}
                                </SmallBoldText>
                                <SmallText>
                                    Please carefully check this email address and confirm that it belongs to you. You
                                    may be asked to log back in.
                                </SmallText>
                            </View>

                            <FlexContainer style={styles.fieldRow}>
                                <FlexItem>
                                    <Button
                                        buttonType={'secondary'}
                                        style={styles.dialogLeftButton}
                                        {...testProps('cancel-button')}
                                        onClick={() => {
                                            _emailConfirm(false);
                                        }}>
                                        Cancel
                                    </Button>
                                </FlexItem>
                                <FlexItem>
                                    <Button
                                        style={styles.dialogRightButton}
                                        {...testProps('confirm-email-button')}
                                        onClick={() => {
                                            _emailConfirm(true);
                                        }}>
                                        Yes, confirm
                                    </Button>
                                </FlexItem>
                            </FlexContainer>
                        </Dialog>
                    )}

                    {showPhoneDialog && (
                        <Dialog visible={showPhoneDialog}>
                            <MaterialCommunityIcons
                                name='phone'
                                size={SmarthubTheme.layout.ICONWIDTH}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <View style={styles.dialogBody}>
                                <SmallText>You have elected to change your primary phone number to:</SmallText>
                                <SmallBoldText style={[globalStyles.singleSpaceAbove, globalStyles.singleSpaceBelow]}>
                                    {formatPhoneNo(formik.values.resident.phone1No)}
                                </SmallBoldText>
                                <SmallText>
                                    Please carefully check this phone number and confirm that it belongs to you. You may
                                    be asked to log back in.
                                </SmallText>
                            </View>
                            <FlexContainer style={styles.fieldRow}>
                                <FlexItem>
                                    <Button
                                        {...testProps('CancelButton')}
                                        style={styles.dialogLeftButton}
                                        buttonType={'secondary'}
                                        onClick={() => {
                                            _phoneConfirm(false);
                                        }}>
                                        Cancel
                                    </Button>
                                </FlexItem>
                                <FlexItem>
                                    <Button
                                        {...testProps('YesConfirmButton')}
                                        style={styles.dialogRightButton}
                                        onClick={() => {
                                            _phoneConfirm(true);
                                        }}>
                                        Yes, confirm
                                    </Button>
                                </FlexItem>
                            </FlexContainer>
                        </Dialog>
                    )}

                    {showSecPhoneDialog && (
                        <Dialog visible={showSecPhoneDialog}>
                            <MaterialCommunityIcons
                                name='phone'
                                size={SmarthubTheme.layout.ICONWIDTH}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <View style={styles.dialogBody}>
                                <SmallText>You have elected to change your secondary phone number to:</SmallText>
                                <SmallBoldText style={[globalStyles.singleSpaceAbove, globalStyles.singleSpaceBelow]}>
                                    {formatPhoneNo(formik.values.resident.phone2No)}.
                                </SmallBoldText>
                                <SmallText>
                                    Please carefully check this phone number and confirm that it belongs to you. You may
                                    be asked to log back in.
                                </SmallText>
                            </View>
                            <FlexContainer style={styles.fieldRow}>
                                <FlexItem>
                                    <Button
                                        buttonType={'secondary'}
                                        style={styles.dialogLeftButton}
                                        onClick={() => {
                                            _phoneSecConfirm(false);
                                        }}>
                                        Cancel
                                    </Button>
                                </FlexItem>
                                <FlexItem>
                                    <Button
                                        style={styles.dialogRightButton}
                                        onClick={() => {
                                            _phoneSecConfirm(true);
                                        }}>
                                        Yes, confirm
                                    </Button>
                                </FlexItem>
                            </FlexContainer>
                        </Dialog>
                    )}

                    <View
                        style={[
                            styles.container,
                            { backgroundColor: theme.colors.background, maxWidth: windowWidth - 12 },
                        ]}>
                        <FlexContainer style={[styles.paddingTopBottom]}>
                            <FlexItem>
                                <FormLabelText style={styles.paddingSmallBottom}>Property</FormLabelText>
                                <RegularText>{residentStore.getSessionActiveResidency().propertyName}</RegularText>
                            </FlexItem>
                            <FlexItem>
                                <FormLabelText style={styles.paddingSmallBottom}>Apartment</FormLabelText>
                                <RegularText>
                                    {residentStore.getSessionActiveResidency().bldgCd} -{' '}
                                    {residentStore.getSessionActiveResidency().unitCd}
                                </RegularText>
                            </FlexItem>
                        </FlexContainer>
                        <FlexContainer style={styles.paddingSmallBottom}>
                            <FlexItem>
                                <FormLabelText style={styles.paddingSmallBottom}>Status</FormLabelText>
                                <RegularText>{residentStore.getSessionActiveResidency().statusCd}</RegularText>
                            </FlexItem>
                        </FlexContainer>

                        <View style={styles.paddingTopBottom}>
                            <FormLabelText style={styles.formLabel}>Email</FormLabelText>

                            <InputString
                                {...testProps('ProfileEmailAddressField')}
                                autoFocus={Platform.OS === 'web'}
                                keyboardType={'email-address'}
                                maxLength={50}
                                initialValue={formik.values.resident.email}
                                onChange={formik.handleChange('resident.email')}
                                error={!!formik.errors.resident?.email}
                                errorMessage={formik.errors.resident?.email}
                            />

                            <FlexContainer style={[styles.fieldRow]}>
                                <FlexItem style={[styles.fieldColumn, styles.fieldColumnFull]}>
                                    <View>
                                        <FormLabelText style={styles.formLabel}>Primary Phone Number</FormLabelText>
                                        <InputPhone
                                            {...testProps('PrimaryPhoneNoField')}
                                            initialValue={formik.values.resident.phone1No}
                                            onChange={formik.handleChange('resident.phone1No')}
                                            error={!!formik.errors.resident?.phone1No}
                                            errorMessage={formik.errors.resident?.phone1No}
                                        />
                                    </View>
                                </FlexItem>

                                <FlexItem style={[styles.fieldColumnFull]}>
                                    <View>
                                        <FormLabelText style={styles.formLabel}>Phone Type</FormLabelText>
                                        <InputDropdown
                                            {...testProps('PrimaryPhoneTypeField')}
                                            placeholder={'Select a phone type'}
                                            options={PhoneNoTypeOptions}
                                            selectedValue={formik.values.resident.phone1TypeCd}
                                            onChange={value => {
                                                _handleFieldChange('resident.phone1TypeCd', value);
                                            }}
                                            error={!!formik.errors.resident?.phone1TypeCd}
                                            errorMessage={formik.errors.resident?.phone1TypeCd}
                                        />
                                    </View>
                                </FlexItem>
                            </FlexContainer>

                            <View>
                                <FormLabelText style={styles.formLabel}>Primary Phone Number Notes</FormLabelText>

                                <InputString
                                    {...testProps('PrimaryPhoneNotesField')}
                                    maxLength={20}
                                    initialValue={formik.values.resident.phone1Notes}
                                    placeholder={'E.g. Please only call me after 5PM'}
                                    onChange={formik.handleChange('resident.phone1Notes')}
                                    error={!!formik.errors.resident?.phone1Notes}
                                    errorMessage={formik.errors.resident?.phone1Notes}
                                />
                            </View>

                            <FlexContainer style={[styles.fieldRow]}>
                                <FlexItem style={[styles.fieldColumn, styles.fieldColumnFull]}>
                                    <View>
                                        <FormLabelText style={styles.formLabel}>Secondary Phone Number</FormLabelText>
                                        <InputPhone
                                            {...testProps('SecondaryPhoneNoField')}
                                            initialValue={formik.values.resident.phone2No}
                                            onChange={formik.handleChange('resident.phone2No')}
                                            error={!!formik.errors.resident?.phone2No}
                                            errorMessage={formik.errors.resident?.phone2No}
                                        />
                                    </View>
                                </FlexItem>

                                <FlexItem style={[styles.fieldColumnFull]}>
                                    <View>
                                        <FormLabelText style={styles.formLabel}>Phone Type</FormLabelText>
                                        <InputDropdown
                                            {...testProps('SecondaryPhoneTypeField')}
                                            placeholder={'Select a phone type'}
                                            options={PhoneNoTypeOptions}
                                            selectedValue={formik.values.resident.phone2TypeCd}
                                            onChange={value => {
                                                _handleFieldChange('resident.phone2TypeCd', value);
                                            }}
                                            error={!!formik.errors.resident?.phone2TypeCd}
                                            errorMessage={formik.errors.resident?.phone2TypeCd}
                                        />
                                    </View>
                                </FlexItem>
                            </FlexContainer>

                            <View>
                                <FormLabelText style={styles.formLabel}>Secondary Phone Number Notes</FormLabelText>
                                <InputString
                                    {...testProps('SecondaryPhoneNotesField')}
                                    maxLength={20}
                                    initialValue={formik.values.resident.phone2Notes}
                                    placeholder={'E.g. Please only call me after 5PM'}
                                    onChange={formik.handleChange('resident.phone2Notes')}
                                    error={!!formik.errors.resident?.phone2Notes}
                                    errorMessage={formik.errors.resident?.phone2Notes}
                                />
                            </View>

                            {!hidePreferredCommunicationMethod && (
                                <View style={[styles.fieldColumnNoPadding, styles.fieldSet, styles.fieldPaddingTop]}>
                                    <FormLabelText style={styles.formLabel}>Preferred Contact Method</FormLabelText>
                                    <InputDropdown
                                        {...testProps('PreferredContactMethodField')}
                                        placeholder={'Select a preferred contact method'}
                                        options={PreferredCommunicationMethodOptions}
                                        selectedValue={formik.values.resident.preferredCommunicationMethodCd}
                                        onChange={value => {
                                            _handleFieldChange('resident.preferredCommunicationMethodCd', value);
                                        }}
                                        error={!!formik.errors.resident?.preferredCommunicationMethodCd}
                                        errorMessage={formik.errors.resident?.preferredCommunicationMethodCd}
                                    />
                                </View>
                            )}

                            {formik.values.resident.typeCd === 'Applicant' &&
                                residentProfileQuery.data &&
                                !arrayIsEmpty(residentProfileQuery.data.guarantors) && (
                                    <View style={globalStyles.sectionSpaceBelow}>
                                        <Section>
                                            <Heading1>Guarantors</Heading1>
                                        </Section>

                                        {residentProfileQuery.data.guarantors.map(g => (
                                            <View key={g.residentId}>
                                                <Section style={globalStyles.flexColumnStart}>
                                                    <RegularText>
                                                        {g.firstName} {g.lastName}
                                                    </RegularText>
                                                    {!!g.phone1No && (
                                                        <DeephansizedRegularText style={globalStyles.singleSpaceAbove}>
                                                            {formatPhoneNo(g.phone1No)}
                                                        </DeephansizedRegularText>
                                                    )}
                                                    {!!g.email && (
                                                        <DeephansizedRegularText style={globalStyles.singleSpaceAbove}>
                                                            {g.email}
                                                        </DeephansizedRegularText>
                                                    )}
                                                </Section>
                                                <Section>
                                                    <InputCheckbox
                                                        style='small'
                                                        initialStatus={
                                                            formik.values.guarantorAccessEnabledList.find(
                                                                enabledResidentId => enabledResidentId === g.residentId,
                                                            )
                                                                ? 'checked'
                                                                : 'unchecked'
                                                        }
                                                        label={`Allow ${g.firstName} to access SMARTHUB`}
                                                        onChange={checkValue => {
                                                            if (checkValue === 'checked') {
                                                                if (
                                                                    !formik.values.guarantorAccessEnabledList.includes(
                                                                        g.residentId,
                                                                    )
                                                                ) {
                                                                    formik.setFieldValue('guarantorAccessEnabledList', [
                                                                        ...formik.values.guarantorAccessEnabledList,
                                                                        g.residentId,
                                                                    ]);
                                                                }
                                                            } else {
                                                                formik.setFieldValue(
                                                                    'guarantorAccessEnabledList',
                                                                    formik.values.guarantorAccessEnabledList.filter(
                                                                        v => v !== g.residentId,
                                                                    ),
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </Section>
                                            </View>
                                        ))}
                                    </View>
                                )}
                        </View>
                    </View>
                </View>
                <FlexContainer style={styles.buttonContainer}>
                    <FlexItem>
                        <Button
                            onClick={formik.handleSubmit}
                            animationType={'ripple'}
                            inactive={formik.isSubmitting}
                            {...testProps('save-button')}>
                            Save Changes
                        </Button>
                    </FlexItem>
                </FlexContainer>
            </KeyboardAwareScrollView>
        </>
    );
};

export default observer(Profile);

const styles = StyleSheet.create({
    container: {
        height: 'auto',
        paddingRight: 12,
        paddingBottom: 12,
        paddingLeft: Platform.OS === 'web' ? SmarthubTheme.layout.GRIDINCREMENT : 12,
        justifyContent: 'space-between',
    },
    contentWrapper: {
        width: Platform.OS === 'web' ? 400 : '100%',
    },
    paddingTopBottom: {
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
    paddingSmallBottom: {
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    formLabel: {
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
        fontSize: Platform.OS === 'ios' || Platform.OS === 'web' ? 13 : 15,
    },
    fieldSet: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 6,
    },
    fieldPaddingTop: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 6,
    },
    fieldRow: {
        flexWrap: 'wrap',
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    fieldColumn: {
        maxWidth: '50%',
        minWidth: Platform.OS === 'ios' ? '100%' : 'auto',
        paddingRight:
            Platform.OS === 'web' ? SmarthubTheme.layout.GRIDINCREMENT * 2 : SmarthubTheme.layout.GRIDINCREMENT,
    },
    fieldColumnFull: {
        maxWidth: '50%',
        minWidth: '50%',
        //marginTop: Platform.OS === 'ios' ? SmarthubTheme.layout.GRIDINCREMENT * 2 : 0,
    },
    fieldColumnNoPadding: {
        paddingRight: 0,
    },
    buttonContainer: {
        paddingRight: 12,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 6,
        paddingLeft: 12,
    },
    dialogBody: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 4,
    },
    dialogLeftButton: {
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
    dialogRightButton: {
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT,
    },
    closeButton: {
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});
