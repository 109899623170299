import { QueryKeys } from 'queries/QueryKeys';
import { UIStore } from 'stores/domain/UIStore';
import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UseQueryOptions } from 'react-query';
import {
    createGetPaymentPageDataCommandV1,
    GetPaymentPageDataCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetPaymentPageDataCommandV1';
import { GetPaymentPageDataResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetPaymentPageDataResultV1';

export const usePaymentData = (
    propertyCd: string,
    residentId: number,
    isReservationYn: boolean,
    applyMoveInPaymentRestrictions: boolean,
    enabled: boolean,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetPaymentPageDataResultV1>,
) => {
    //make the  query key
    const queryKey = [QueryKeys.PaymentData, propertyCd, residentId];

    //make the command
    const theCommand = createGetPaymentPageDataCommandV1();
    theCommand.propertyCd = propertyCd;
    theCommand.residentId = residentId;
    theCommand.applyMoveInPaymentRestrictions = applyMoveInPaymentRestrictions;
    theCommand.isReservationYn = isReservationYn;

    //call the command
    const query = useCommandQuery<GetPaymentPageDataCommandV1, GetPaymentPageDataResultV1>(
        theCommand,
        uiStore,
        queryKey,
        queryOptions || {
            enabled: enabled,
        },
    );

    return {
        queryKey,
        query,
    };
};
