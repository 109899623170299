import { SmarthubPlatformOS } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubPlatformOS';
import { Platform } from 'react-native';
import { SmarthubDeviceType } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubDeviceType';
import * as Device from 'expo-device';
import { DeviceType } from 'expo-device';
import { NullableSmarthubBrowser } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubBrowser';
import { browserName } from 'react-device-detect';
import { SmarthubLoginV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubLoginV1';
import { createInsertSmarthubLoginCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/InsertSmarthubLoginCommandV1';

export const generateInsertSmarthubLoginCmd = (
    propertyCd: string,
    residencyId: number,
    smarthubLogin: SmarthubLoginV1,
) => {
    const cmd = createInsertSmarthubLoginCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.data = smarthubLogin;
    return cmd;
};

export const getPlatformOS = (): SmarthubPlatformOS => {
    const platformOS = Platform.OS;

    switch (platformOS) {
        case 'android':
            return 'Android';
        case 'ios':
            return 'IOS';
        case 'web':
            return 'Web';
        case 'macos':
            return 'Web';
        case 'windows':
            return 'Web';
        default:
            return 'Web';
    }
};

export const getDeviceType = async (): Promise<SmarthubDeviceType> => {
    const deviceType = await Device.getDeviceTypeAsync();

    switch (deviceType) {
        case DeviceType.UNKNOWN:
            return 'Unknown';
        case DeviceType.DESKTOP:
            return 'Desktop';
        case DeviceType.PHONE:
            return 'Handset';
        case DeviceType.TABLET:
            return 'Tablet';
        case DeviceType.TV:
            return 'Unknown';
        default:
            return 'Unknown';
    }
};

export const getBrowser = (): NullableSmarthubBrowser => {
    if (getPlatformOS() === 'Web') {
        switch (browserName) {
            case 'Chrome':
                return 'Chrome';
            case 'Safari':
                return 'Safari';
            case 'Edge':
                return 'Edge';
            case 'Firefox':
                return 'Firefox';
            case 'InternetExplorer':
                return 'InternetExplorer';
            case 'Chromium':
                return 'Chromium';
            case 'Ie':
                return 'Ie';
            case 'MobileSafari':
                return 'MobileSafari';
            case 'EdgeChromium':
                return 'EdgeChromium';
            case 'MIUI':
                return 'MIUI';
            case 'Yandex':
                return 'Yandex';
            case 'SamsungBrowser':
                return 'SamsungBrowser';
            default:
                return 'Other';
        }
    } else {
        return null;
    }
};
