import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ComingSoon from 'screens/ComingSoon';
import More from 'screens/more/More';
import TestScreen from 'screens/TestScreen';
import Profile from 'screens/more/personal/Profile';
import PrimitivesScreen from 'screens/PrimitivesScreen';
import { observer } from 'mobx-react-lite';
import MainStackSurface from './MainStackSurface';
import { getMainStackScreenOptions, NavigatorProps } from './MainStackScreenOptions';
import AlertsAndNotifications from 'screens/more/appsettings/AlertsAndNotifications';
import { useTheme } from 'react-native-paper';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import PaymentPOC from 'screens/home/transactions/PaymentPOC';
import CommunityInfo from 'screens/more/faq/CommunityInfo';
import AwayTimes from 'screens/more/personal/awayTimes/AwayTimes';
import AddEditAwayTime from 'screens/more/personal/awayTimes/AddEditAwayTime';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useStore } from 'contexts/StoreContextProvider';
import AlertsAndNotificationsOpt from 'screens/more/appsettings/AlertsAndNotificationsOpt';

const MoreStack = createStackNavigator();

const MoreStackNavigator: React.FC<NavigatorProps> = observer(() => {
    const { useDrawerNavigation } = useResponsiveContext();
    const theme = useTheme();
    const { userSessionStore } = useStore();

    return (
        <MainStackSurface>
            <MoreStack.Navigator
                initialRouteName={navigationRoutes.moreRoutes.more}
                /*headerMode={'none'}*/
                screenOptions={getMainStackScreenOptions(theme, useDrawerNavigation)}>
                <MoreStack.Screen
                    name={navigationRoutes.moreRoutes.more}
                    component={More}
                    options={{ headerLeft: () => null }}
                />

                {/*Personal section*/}
                <MoreStack.Screen name={navigationRoutes.moreRoutes.profile} component={Profile} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.paymentMethods} component={ComingSoon} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.pets} component={ComingSoon} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.visitors} component={ComingSoon} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.awayTimes} component={AwayTimes} />
                <MoreStack.Screen
                    name={navigationRoutes.moreRoutes.addAwayTime}
                    component={AddEditAwayTime}
                    options={{ headerShown: false }}
                />
                <MoreStack.Screen
                    name={navigationRoutes.moreRoutes.editAwayTime}
                    component={AddEditAwayTime}
                    initialParams={{ title: 'Edit an Away Time' }}
                />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.addEditAwayTime} component={AddEditAwayTime} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.referrals} component={ComingSoon} />
                {/*FAQ Section*/}
                <MoreStack.Screen name={navigationRoutes.moreRoutes.communityInfo} component={CommunityInfo} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.communityPolicyDocuments} component={ComingSoon} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.faq} component={ComingSoon} />
                {/*App Settings Section*/}
                <MoreStack.Screen
                    name={navigationRoutes.moreRoutes.alertsAndNotifications}
                    component={
                        userSessionStore.isFeatureEnabled('OptInOut')
                            ? AlertsAndNotificationsOpt
                            : AlertsAndNotifications
                    }
                    initialParams={{ title: 'Alerts and Notifications' }}
                />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.touchID} component={ComingSoon} />
                {/*No Real 'page' for signout yet*/}
                <MoreStack.Screen name={navigationRoutes.moreRoutes.signOut} component={ComingSoon} />

                {/*TEST Section*/}
                <MoreStack.Screen name={navigationRoutes.moreRoutes.testScreen} component={TestScreen} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.primitivesScreen} component={PrimitivesScreen} />
                <MoreStack.Screen name={navigationRoutes.moreRoutes.paymentPOC} component={PaymentPOC} />
            </MoreStack.Navigator>
        </MainStackSurface>
    );
});

export default MoreStackNavigator;
