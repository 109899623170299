import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
    DeemphansizedTextSmall,
    DeephansizedRegularText,
    MediumBoldText,
    RegularText,
} from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Hyperlink from 'components/primitives/Hyperlink';
import InputString from 'components/input/InputString';
import InputDate from 'components/input/InputDate';
import InputCheckbox from 'components/input/InputCheckbox';
import { globalStyles } from 'theme/style/GlobalStyles';
import { SmartmoveRenterInsuranceV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveRenterInsuranceV1';
import { InputStyles } from 'theme/style/InputStyles';
import { SmarthubAwayTimeResidentV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAwayTimeResidentV1';
import { FieldArray } from 'formik';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { isDesktopAndMobile } from 'utils/uiUtils';

type SmartMovePolicyProps = {
    policy: SmartmoveRenterInsuranceV1;
    index: number;
    insuranceLimit: string;
    activeResidents: Array<SmarthubAwayTimeResidentV1>;
    errors: any;
    canRemoveFirstPolicy: boolean;
    onRemove?: () => void;
    handleChange: {
        /** Classic React change handler, keyed by input name */
        (e: React.ChangeEvent<any>): void;
        /** Preact-like linkState. Will return a handleChange function.  */
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: {
        /** Classic React blur handler, keyed by input name */
        (e: React.FocusEvent<any>): void;
        /** Preact-like linkState. Will return a handleBlur function. */
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
};

const SmartMovePolicy: React.FC<SmartMovePolicyProps> = (props: SmartMovePolicyProps) => {
    const [policyDateCalOpen, setPolicyDateCalOpen] = useState('');

    const _handleRemove = () => {
        if (props.onRemove) {
            props.onRemove();
        }
    };

    const policy = props.policy;
    const handleChange = props.handleChange;
    const handleBlur = props.handleBlur;
    const index = props.index;
    const errors = props.errors;
    const activeResidents = props.activeResidents;

    const companyNameMissing = errors === 'Company name is missing';

    return (
        <>
            <View style={styles.heading}>
                <MediumBoldText style={globalStyles.flexContainer}>Insurance Policy {props.index + 1}</MediumBoldText>
                {index >= 0 && props.canRemoveFirstPolicy && (
                    <Hyperlink
                        style={styles.removeLink}
                        onClick={() => {
                            _handleRemove();
                        }}
                    >
                        Remove Policy
                    </Hyperlink>
                )}
            </View>
            <View style={styles.formWrapper}>
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>
                    Insurance Company
                </DeemphansizedTextSmall>
                <InputString
                    initialValue={policy.companyName}
                    onBlur={handleBlur(`insurances.${index}.companyName`)}
                    onChange={handleChange(`insurances.${index}.companyName`)}
                    error={!!errors?.companyName || companyNameMissing}
                    errorMessage={errors?.companyName || (companyNameMissing && 'Company name is missing')}
                />
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>Policy Number</DeemphansizedTextSmall>
                <InputString
                    initialValue={policy.policyNumber}
                    onBlur={handleBlur(`insurances.${index}.policyNumber`)}
                    onChange={handleChange(`insurances.${index}.policyNumber`)}
                    error={!!errors?.policyNumber}
                    errorMessage={errors?.policyNumber}
                />
                <View style={[styles.inlineFieldWrapper, styles.overlay]}>
                    <View
                        style={[
                            styles.inlineField,
                            policyDateCalOpen === 'startDate' ? styles.overlay : styles.underlay,
                        ]}
                    >
                        <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>
                            Policy Start Date
                        </DeemphansizedTextSmall>
                        <InputDate
                            value={policy.startDt}
                            onOpen={() => {
                                setPolicyDateCalOpen('startDate');
                            }}
                            onClose={() => {
                                setPolicyDateCalOpen('');
                            }}
                            onChange={handleChange(`insurances.${index}.startDt`)}
                            error={!!errors?.startDt}
                            errorMessage={errors?.startDt}
                        />
                    </View>
                    <View
                        style={[
                            styles.inlineField,
                            styles.inlineFieldRight,
                            policyDateCalOpen === 'endDate' ? styles.overlay : styles.underlay,
                        ]}
                    >
                        <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>
                            Policy End Date
                        </DeemphansizedTextSmall>
                        <InputDate
                            value={policy.endDt}
                            onOpen={() => {
                                setPolicyDateCalOpen('endDate');
                            }}
                            onClose={() => {
                                setPolicyDateCalOpen('');
                            }}
                            onChange={handleChange(`insurances.${index}.endDt`)}
                            error={!!errors?.endDt}
                            errorMessage={errors?.endDt}
                        />
                    </View>
                </View>
                <View style={styles.underlay}>
                    <InputCheckbox
                        label={
                            'I confirm that the liability coverage on my policy is a MINIMUM of $' +
                            props.insuranceLimit
                        }
                        style={'small'}
                        initialStatus={policy.liabilityConfirmed ? 'checked' : 'unchecked'}
                        onChange={checked => {
                            policy.liabilityConfirmed = checked === 'checked';
                        }}
                    />
                    {!!errors?.liabilityConfirmed && (
                        <RegularText style={InputStyles.inputErrorMessage}>{errors?.liabilityConfirmed}</RegularText>
                    )}
                    <DeephansizedRegularText style={[globalStyles.smartMoveFormLabel, styles.formLabelPadding]}>
                        Select the resident(s) that are on this policy:
                    </DeephansizedRegularText>
                </View>

                <FieldArray
                    name='activeResidents'
                    render={arrayHelpers => (
                        <View>
                            {activeResidents.map(activeResident => (
                                <InputCheckbox
                                    key={activeResident.residentId}
                                    label={activeResident.residentFirstName + ' ' + activeResident.residentLastName}
                                    style={'small'}
                                    initialStatus={
                                        policy.policyResidents?.some(res => res === activeResident.residentId)
                                            ? 'checked'
                                            : 'unchecked'
                                    }
                                    onChange={checked => {
                                        //compatibleAlert('All values' + JSON.stringify(values));

                                        logInfo(
                                            INFO_LOGGER,
                                            'Policy residents before change: ' + JSON.stringify(policy.policyResidents),
                                        );

                                        if (checked === 'checked') {
                                            logInfo(INFO_LOGGER, 'Adding resident: ' + activeResident.residentId);
                                            policy.policyResidents.push(activeResident.residentId);
                                        } else {
                                            logInfo(INFO_LOGGER, 'Removing resident: ' + activeResident.residentId);
                                            const idx = policy.policyResidents.indexOf(activeResident.residentId);
                                            if (idx !== -1) {
                                                arrayHelpers.remove(idx);
                                            }
                                            policy.policyResidents = policy.policyResidents.filter(
                                                p => p !== activeResident.residentId,
                                            );
                                        }

                                        logInfo(
                                            INFO_LOGGER,
                                            'Policy residents after change: ' + JSON.stringify(policy.policyResidents),
                                        );
                                    }}
                                />
                            ))}
                        </View>
                    )}
                />

                {!!errors?.policyResidents && (
                    <RegularText style={InputStyles.inputErrorMessage}>{errors?.policyResidents}</RegularText>
                )}
            </View>
        </>
    );
};

export default SmartMovePolicy;

const styles = StyleSheet.create({
    heading: {
        flexDirection: 'row',
    },
    removeLink: {
        flex: 1,
        paddingTop: 4,
        textAlign: 'right',
    },
    formWrapper: {},
    formLabelPadding: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
    inlineFieldWrapper: {
        display: 'flex',
        flexDirection: isDesktopAndMobile() ? 'column' : 'row',
        justifyContent: 'space-between',
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 5,
    },
    inlineField: {
        flex: 1,
        marginRight: isDesktopAndMobile() ? 0 : SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    inlineFieldRight: {
        marginRight: 0,
    },
    overlay: {
        zIndex: 1001,
    },
    underlay: {
        zIndex: 1000,
    },
});
