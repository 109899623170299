import * as React from 'react';
import { View, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { SmarthubTypography } from 'theme/typography/SmarthubTypography';

export function SmallerText(props: any) {
    return <Text {...props} style={[SmarthubTypography.smaller, props.style]} />;
}

export function SmallText(props: any) {
    return <Text {...props} style={[SmarthubTypography.small, props.style]} />;
}

export function SmallerBoldText(props: any) {
    return <Text {...props} style={[SmarthubTypography.smallerBold, props.style]} />;
}

export function SmallBoldText(props: any) {
    return <Text {...props} style={[SmarthubTypography.smallBold, props.style]} />;
}

export function RegularText(props: any) {
    return <Text {...props} style={[SmarthubTypography.regular, props.style]} />;
}

export function RegularBoldText(props: any) {
    return <Text {...props} style={[SmarthubTypography.regularBold, props.style]} />;
}

export function MediumText(props: any) {
    return <Text {...props} style={[SmarthubTypography.medium, props.style]} />;
}

export function MediumBoldText(props: any) {
    return <Text {...props} style={[SmarthubTypography.mediumBold, props.style]} />;
}

export function LargeText(props: any) {
    return <Text {...props} style={[SmarthubTypography.large, props.style]} />;
}

export function LargeBoldText(props: any) {
    return <Text {...props} style={[SmarthubTypography.largeBold, props.style] as ViewStyle} />;
}

export function ExtraLargeText(props: any) {
    return <Text {...props} style={[SmarthubTypography.extraLarge, props.style]} />;
}

export function ExtraLargeBoldText(props: any) {
    return <Text {...props} style={[SmarthubTypography.extraLargeBold, props.style]} />;
}

export function DeemphansizedTextSmall(props: any) {
    return <Text {...props} style={[SmarthubTypography.deephansizedSmall, props.style]} />;
}

export function DeephansizedRegularText(props: any) {
    return <Text {...props} style={[SmarthubTypography.deephansizedRegular, props.style]} />;
}

export function FormLabelText(props: any) {
    return <Text {...props} style={[SmarthubTypography.formLabel, props.style]} />;
}

export function LabelText(props: any) {
    return <Text {...props} style={[SmarthubTypography.boldLabel, props.style]} />;
}

export function ButtonText(props: any) {
    return (
        <>
            <Text {...props} style={[SmarthubTypography.buttonText, props.style]} />
        </>
    );
}

export function ButtonTextLarge(props: any) {
    return <Text {...props} style={[SmarthubTypography.buttonTextLarge, props.style]} />;
}

export function Heading1(props: any) {
    return <Text {...props} style={[SmarthubTypography.heading1, props.style]} />;
}

export function Heading2(props: any) {
    return <Text {...props} style={[SmarthubTypography.heading2, props.style]} />;
}

export function Heading3(props: any) {
    return <Text {...props} style={[SmarthubTypography.heading3, props.style]} />;
}

export function Heading4(props: any) {
    return <Text {...props} style={[SmarthubTypography.heading4, props.style]} />;
}

type BulletedListProps = {
    listItems: Array<string>;
    style?: any;
};

export function BulletedList(props: BulletedListProps) {
    return (
        <View style={SmarthubTypography.bulletList}>
            {props.listItems.map((item, index) => {
                return (
                    <Text style={props.style} key={index} dataDetectorType={'none'}>
                        <RegularText>• </RegularText>
                        {item}
                    </Text>
                );
            })}
        </View>
    );
}
