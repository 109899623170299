import { RootStore } from 'stores/RootStore';
import { action, makeAutoObservable } from 'mobx';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import { GetCommunityNoticesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/notifications/GetCommunityNoticesResultV1';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { createGetCommunityNoticesCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/notifications/GetCommunityNoticesCommandV1';
import { SmarthubCommunityNoticesV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notices/SmarthubCommunityNoticesV1';
import { User } from 'firebase/auth';

export class CommunityNoticesStore {
    rootStore: RootStore;

    communityNotices: Array<SmarthubCommunityNoticesV1> = [];
    startIndex = 0;
    numOfRows = 3;
    hasNotices = true;
    currentProperty = '';
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    setHasNotices = action((hasNotices: boolean) => {
        this.hasNotices = hasNotices;
    });

    getSessionActiveResidency() {
        return this.rootStore.userSessionStore.sessionActiveResidency;
    }

    addMoreRows() {
        this.startIndex += this.numOfRows;
    }

    getCommunityNotices = action(async (currentUser: User): Promise<GetCommunityNoticesResultV1 | void> => {
        try {
            const cmd = createGetCommunityNoticesCommandV1();
            const residency = this.getSessionActiveResidency();
            cmd.propertyCd = residency.propertyCd;
            if (this.currentProperty !== cmd.propertyCd) {
                this.currentProperty = cmd.propertyCd;
                this.startIndex = 0;
            }
            logInfo(INFO_LOGGER, this.startIndex, 'START INDEX');
            cmd.startIndex = this.startIndex;
            cmd.numRowsToRetrieve = this.numOfRows;
            cmd.residencyId = residency.residencyId;
            cmd.residentId = residency.residentId;
            const result: GetCommunityNoticesResultV1 = await dispatchCommandAsync(
                this.rootStore.uiStore,
                cmd,
                false,
                currentUser,
            );

            if (result) {
                this.communityNotices = result.communityNotices;

                if (result.communityNotices.length === 0 && this.startIndex === 0) {
                    this.setHasNotices(false);
                }
            }
            return result;
        } catch (e) {
            logError(ERROR_LOGGER, 'Error getting requests:   ', JSON.stringify(e));
        }
    });
}
