import { SmartHubResidentContactInfoV1 } from '../../businesslogic/smarthub/versioneddataobjects/requests/SmartHubResidentContactInfoV1';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { UpdateContactInfoResultV1 } from './UpdateContactInfoResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.UpdateContactInfoCommandV1
 */
export interface UpdateContactInfoCommandV1 extends AbstractResidencyAuthenticatedCommand<UpdateContactInfoResultV1> {
    contactInfoList: Array<SmartHubResidentContactInfoV1>;
}

export function createUpdateContactInfoCommandV1(): UpdateContactInfoCommandV1 {
    return {
        contactInfoList: [],
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.UpdateContactInfoCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
