import * as React from 'react';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../theme/custom/react-datepicker-custom.css';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { formatDate, formatDateStringMonthLabel, parseDateMMDDYYYY } from 'utils/DateUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { RegularText } from 'components/primitives/StyledText';
import Hyperlink from 'components/primitives/Hyperlink';
import { testProps } from 'components/ComponentTypes';

interface DateFieldProps {
    testID?: string;
    value: string;
    minDate?: string;
    maxDate?: string;
    editable?: boolean;
    onChange?: (value: string) => void;
}

interface CalendarContainerProps {
    className?: string;
    children?: React.ReactNode;
}

/***
 *@value the date string in mm/dd/yyyy format
 *@disabled  disable the date picker
 *@onChange  (value: string)=>void
 */
const DateField: React.FC<DateFieldProps> = (props: DateFieldProps) => {
    const [changing, setChanging] = useState(false);
    const [internalValue, setInternalValue] = useState(props.value);

    const _handleChange = (newDate: Date) => {
        setInternalValue(formatDate(newDate));
    };

    const MyContainer = ({ className, children }: CalendarContainerProps) => {
        return (
            <View style={styles.calendarWrapper}>
                <CalendarContainer className={className}>
                    <div>{children}</div>
                </CalendarContainer>
            </View>
        );
    };

    if (!changing) {
        return (
            <View style={globalStyles.flexRow}>
                <RegularText>{formatDateStringMonthLabel(props.value || null)}</RegularText>
                {props.editable && (
                    <Hyperlink
                        {...testProps('change-date')}
                        style={globalStyles.horizontalPad}
                        onClick={() => {
                            setInternalValue(props.value);
                            setChanging(true);
                        }}>
                        Change Date
                    </Hyperlink>
                )}
            </View>
        );
    } else {
        return (
            <View style={[globalStyles.flexRowCenter, styles.inputWrapper]}>
                <View style={styles.inputWrapper}>
                    <DatePicker
                        selected={parseDateMMDDYYYY(internalValue || null)}
                        minDate={parseDateMMDDYYYY(props.minDate || null)}
                        maxDate={parseDateMMDDYYYY(props.maxDate || null)}
                        disabled={!props.editable}
                        onChange={(newDate: Date) => _handleChange(newDate)}
                        calendarContainer={MyContainer}
                        placeholderText='MM/DD/YYYY'
                    />
                    <MaterialCommunityIcons
                        name={'calendar'}
                        style={styles.inputIcon}
                        size={SmarthubTheme.layout.ICONWIDTH}
                        color={SmarthubTheme.colors.darkgrey}
                    />
                </View>
                <Hyperlink
                    {...testProps('save')}
                    style={globalStyles.innerMarginLeft}
                    onClick={() => {
                        if (props.onChange) {
                            props.onChange(internalValue);
                            setChanging(false);
                        }
                    }}>
                    Save
                </Hyperlink>
                <Hyperlink
                    {...testProps('cancel')}
                    style={globalStyles.innerMarginLeft}
                    onClick={() => {
                        if (props.onChange) {
                            setInternalValue(props.value);
                            setChanging(false);
                        }
                    }}>
                    Cancel
                </Hyperlink>
            </View>
        );
    }
};

export default DateField;

const styles = StyleSheet.create({
    inputWrapper: {
        zIndex: 1000,
    },
    inputIcon: {
        position: 'absolute',
        top: 10,
        left: SmarthubTheme.layout.GRIDINCREMENT,
    },
    calendarWrapper: {
        zIndex: 1000,
    },
});
