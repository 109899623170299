import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { StackNavigationProp } from '@react-navigation/stack';
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useStore } from 'contexts/StoreContextProvider';
import { Heading1, Heading3, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useTheme } from 'react-native-paper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalStyles } from 'theme/style/GlobalStyles';
import { daysBetweenIgnoreTime, parseDateMMDDYYYY } from 'utils/DateUtils';
import { useGetSmarthubManageMyLeaseData } from 'queries/hooks/manageMyLease/ManageMyLeaseHooks';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { useLogResidencyAuditEventMutation } from 'queries/hooks/residency/useLogResidencyAuditEventMutation';

type ScreenProps = {
    navigation: StackNavigationProp<any>;
};
const ManageMyLease: React.FC<ScreenProps> = observer(({ navigation }: ScreenProps) => {
    const { userSessionStore, uiStore } = useStore();
    const { useDrawerNavigation } = useResponsiveContext();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const theme = useTheme();

    let renewLeaseMessage2 = 'Renew my lease';
    const manageMyLeaseDataQuery = useGetSmarthubManageMyLeaseData();
    const logResidencyAuditEventMutation = useLogResidencyAuditEventMutation(uiStore, sessionActiveResidency);

    useEffect(() => {
        if (manageMyLeaseDataQuery.isFetching) {
            uiStore.showActivityLoader();
        } else {
            uiStore.hideActivityLoader();
        }
    }, [manageMyLeaseDataQuery.isFetching]);

    if (manageMyLeaseDataQuery.isSuccess) {
        !!manageMyLeaseDataQuery.data?.earlyBirdExpDt &&
        daysBetweenIgnoreTime(
            parseDateMMDDYYYY(manageMyLeaseDataQuery.data?.earlyBirdExpDt),
            userSessionStore.propertySpecificDt,
        ) <= 0
            ? (renewLeaseMessage2 = renewLeaseMessage2 + ' and get a special early bird rate')
            : null;
    }

    if (!hasSessionActiveResidency && (!manageMyLeaseDataQuery.isSuccess || !manageMyLeaseDataQuery.data)) return null;
    return (
        <SafeAreaView style={[globalStyles.justflex1, { backgroundColor: theme.colors.background }]}>
            <KeyboardAwareScrollView>
                <View style={styles.innerContainer}>
                    <Heading1 style={styles.mainHeading}>Manage My Lease</Heading1>
                    <Heading3 style={styles.subheading}>What do you want to do with your lease?</Heading3>
                    {sessionActiveResidency.allowRenewal && (
                        <TouchableOpacity
                            onPress={() => {
                                logResidencyAuditEventMutation.mutate({
                                    auditLogId: null,
                                    eventType: 'SmarthubRenewMyLease',
                                    currentValue: '',
                                    newValue: '',
                                });

                                useDrawerNavigation
                                    ? navigation.navigate(navigationRoutes.homeRoutes.renewMyLease)
                                    : navigation.navigate(navigationRoutes.loggedInRoutes.renewMyLeaseModal);
                            }}>
                            <View style={styles.leaseOptions}>
                                <RegularText>{renewLeaseMessage2}</RegularText>
                            </View>
                        </TouchableOpacity>
                    )}

                    {sessionActiveResidency.allowNTV && (
                        <TouchableOpacity
                            onPress={() => {
                                useDrawerNavigation
                                    ? navigation.navigate(navigationRoutes.homeRoutes.noticeToVacateRequest)
                                    : navigation.navigate(navigationRoutes.loggedInRoutes.noticeToVacateRequestModal);
                            }}>
                            <View style={styles.leaseOptions}>
                                <RegularText>Submit my notice to vacate</RegularText>
                            </View>
                        </TouchableOpacity>
                    )}
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
});

const styles = StyleSheet.create({
    innerContainer: {
        marginHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    mainHeading: {
        marginTop: SmarthubTheme.layout.SECTIONMARGIN,
    },
    subheading: {
        marginTop: SmarthubTheme.layout.SECTIONMARGIN,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 6,
    },
    leaseOptions: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.lightgrey,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: SmarthubTheme.layout.INNERPAD * 2,
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});

export default ManageMyLease;
