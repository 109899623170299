import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';

interface OverlayProps {
    testID?: string;
}

const defaultProps: OverlayProps = {};

const Overlay: React.FC<OverlayProps> = (props: OverlayProps) => {
    return <View testID={props.testID} style={styles.overlay} />;
};

Overlay.defaultProps = defaultProps;

export default Overlay;

const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        height: '90%',
        backgroundColor: SmarthubTheme.colors.black,
        opacity: 0.5,
    },
});
