import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { RegularText } from 'components/primitives/StyledText';
import React, { LegacyRef, useEffect, useRef } from 'react';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import Swiper from 'react-native-web-swiper';
import { MaterialIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { usePrevious } from 'utils/hooks/usePrevious';
import { v4 as uuid } from 'uuid';
import Container from 'components/primitives/Container';
import { globalStyles } from 'theme/style/GlobalStyles';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { useNavigation } from '@react-navigation/native';
import { SmarthubLeaseAlert } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubLeaseAlert';
import { useLogResidencyAuditEventMutation } from 'queries/hooks/residency/useLogResidencyAuditEventMutation';

export const LeaseAlertsBanner: React.FC = observer(() => {
    const { userSessionStore, uiStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const theme = useTheme();
    const swiperRef: LegacyRef<Swiper> = useRef(null);
    const logResidencyAuditEventMutation = useLogResidencyAuditEventMutation(uiStore, sessionActiveResidency);

    const previousResidencyKey = usePrevious(
        sessionActiveResidency.propertyNumber + '-' + sessionActiveResidency.residencyId,
    );

    const currentResidencyKey = sessionActiveResidency.propertyNumber + '-' + sessionActiveResidency.residencyId;

    useEffect(() => {
        if (currentResidencyKey !== previousResidencyKey) {
            if (null != swiperRef && null != swiperRef.current) {
                const activeIndex = swiperRef.current.getActiveIndex();
                if (activeIndex !== 0) {
                    swiperRef.current.goTo(0);
                }
            }
        }
    }, [currentResidencyKey]);

    type SwiperControlProps = {
        onPress: () => void;
    };

    class LeftChevron extends React.Component {
        typedProps: SwiperControlProps = this.props as SwiperControlProps;
        render() {
            return (
                <MaterialIcons
                    name={'chevron-left'}
                    size={50}
                    color={theme.colors.text}
                    onPress={this.typedProps.onPress}
                />
            );
        }
    }
    class RightChevron extends React.Component {
        typedProps: SwiperControlProps = this.props as SwiperControlProps;
        render() {
            return (
                <MaterialIcons
                    name={'chevron-right'}
                    size={50}
                    color={theme.colors.text}
                    onPress={this.typedProps.onPress}
                />
            );
        }
    }

    const BlankDot: React.FC<{ index: number; isActive: boolean; onPress: any }> = ({
        index,
        isActive,
        onPress: any,
    }) => {
        return null;
    };

    const OneAlert: React.FC<{ leaseAlert: SmarthubLeaseAlert }> = observer(({ leaseAlert }) => {
        const { useDrawerNavigation, useFullExperience } = useResponsiveContext();
        const { sessionActiveResidency } = userSessionStore;
        const navigation = useNavigation();
        const { leaseAdjustmentType, alert } = leaseAlert;
        return (
            <Container borderStyle={'orange'} dropShadow={true} key={uuid()}>
                <View style={[globalStyles.innerContainer, styles.alertcont]}>
                    <RegularText style={styles.alertcont}>{alert}</RegularText>

                    {leaseAdjustmentType === 'ExpiringSoon' && sessionActiveResidency.allowRenewal && (
                        <View>
                            <TouchableOpacity
                                onPress={() => {
                                    logResidencyAuditEventMutation.mutate({
                                        auditLogId: null,
                                        eventType: 'SmarthubManageMyLease',
                                        currentValue: '',
                                        newValue: '',
                                    });

                                    useDrawerNavigation
                                        ? useFullExperience
                                            ? navigation.navigate(navigationRoutes.homeRoutes.home, {
                                                  screen: navigationRoutes.homeRoutes.manageMyLease,
                                              })
                                            : navigation.navigate(navigationRoutes.homeRoutes.manageMyLease)
                                        : navigation.navigate(navigationRoutes.loggedInRoutes.manageMyLeaseModal);
                                }}>
                                <RegularText style={styles.manageMyLeaseLink}>Manage My Lease</RegularText>
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
            </Container>
        );
    });

    if (sessionActiveResidency.leaseAlerts && sessionActiveResidency.leaseAlerts.length > 1) {
        return (
            <Swiper
                from={0}
                ref={swiperRef}
                controlsProps={{
                    DotComponent: BlankDot,
                    prevPos: 'left',
                    nextPos: 'right',
                    PrevComponent: LeftChevron,
                    NextComponent: RightChevron,
                }}>
                {sessionActiveResidency.leaseAlerts.map(alert => (
                    <OneAlert leaseAlert={alert} key={uuid()} />
                ))}
            </Swiper>
        );
    } else if (sessionActiveResidency.leaseAlerts && sessionActiveResidency.leaseAlerts.length === 1) {
        return <OneAlert leaseAlert={sessionActiveResidency.leaseAlerts[0]} key={uuid()} />;
    } else {
        return null;
    }
});

export const getMesageBalanceCardWidth = (
    useFullExperience: boolean,
    useDrawerNavigation: boolean,
): number | string => {
    const cardWidth = useFullExperience
        ? SmarthubTheme.layout.SIDEWIDTH - SmarthubTheme.layout.SURROUNDMARGIN
        : useDrawerNavigation
        ? '60%'
        : '100%';
    return cardWidth;
};

const styles = StyleSheet.create({
    alertcont: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    manageMyLeaseLink: {
        color: SmarthubTheme.colors.lightblue,
        textAlign: 'center',
        alignItems: 'center',
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});
