import { DeephansizedRegularText, RegularText } from 'components/primitives/StyledText';
import React from 'react';
import { observer } from 'mobx-react-lite';
import Container from 'components/primitives/Container';
import FlexContainer from 'components/primitives/FlexContainer';
import { StackNavigationProp } from '@react-navigation/stack';
import { SmarthubAutopayV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubAutopayV1';
import Hyperlink from 'components/primitives/Hyperlink';
import { formatDateStringMonthLabelFromNumber, nth } from 'utils/DateUtils';
import { FontAwesome } from '@expo/vector-icons';
import { v4 as uuid } from 'uuid';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useTheme } from 'react-native-paper';

type AutoPayDetailsProps = {
    navigation: StackNavigationProp<any>;
    autopay: SmarthubAutopayV1;
    setupLinkHandler: () => void;
};

export const AutoPayDetails: React.FC<AutoPayDetailsProps> = observer(
    ({ autopay, setupLinkHandler }: AutoPayDetailsProps) => {
        const theme = useTheme();

        const getFrequencyString = (code: string) => {
            switch (code) {
                case 'M':
                    return 'Monthly';
                case 'A':
                    return 'Annually';
                case 'Q':
                    return 'Quarterly';
                case 'B':
                    return 'Biannually';
            }
        };

        const creditCard = autopay.paymentType === 'CC';
        let paymentIcon: any = creditCard ? 'credit-card-alt' : 'bank';
        if (autopay.creditCardType) {
            switch (autopay.creditCardType) {
                case 'VISA':
                    paymentIcon = 'cc-visa';
                    break;
                case 'AmericanExpress':
                    paymentIcon = 'cc-amex';
                    break;
                case 'Discover':
                    paymentIcon = 'cc-discover';
                    break;
                case 'DinersClub':
                    paymentIcon = 'cc-diners-club';
                    break;
                case 'Mastercard':
                    paymentIcon = 'cc-mastercard';
                    break;
            }
        }

        return (
            <FlexContainer
                style={[
                    globalStyles.container,
                    globalStyles.sectionSpaceBelow,
                    { backgroundColor: theme.colors.background },
                ]}>
                <Container borderStyle={'grey'} dropShadow={true} width={'100%'}>
                    <FlexContainer
                        style={globalStyles.innerContainer}
                        flexDirection={'row'}
                        justifyContent={'space-between'}>
                        <FlexContainer flexDirection={'column'}>
                            <DeephansizedRegularText>Payment Amount</DeephansizedRegularText>
                            <RegularText style={globalStyles.singleSpaceAbove}>
                                {autopay.amount !== 0
                                    ? 'Variable (Max Amount: ' + formatCentsAsDollars(autopay.amount) + ')'
                                    : 'Variable'}
                            </RegularText>
                        </FlexContainer>
                        <FlexContainer flexDirection={'column'}>
                            <RegularText>
                                <Hyperlink onClick={setupLinkHandler}>Remove</Hyperlink>
                            </RegularText>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer
                        style={globalStyles.innerContainer}
                        flexDirection={'row'}
                        justifyContent={'space-between'}>
                        <FlexContainer flexDirection={'column'}>
                            <DeephansizedRegularText>Start Date</DeephansizedRegularText>
                            <RegularText style={globalStyles.singleSpaceAbove}>
                                {formatDateStringMonthLabelFromNumber(autopay.startDt)}
                            </RegularText>
                        </FlexContainer>
                        <FlexContainer flexDirection={'column'}>
                            <DeephansizedRegularText>End Date</DeephansizedRegularText>
                            <RegularText style={globalStyles.singleSpaceAbove}>
                                {formatDateStringMonthLabelFromNumber(autopay.endDt)}
                            </RegularText>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer
                        style={globalStyles.innerContainer}
                        flexDirection={'row'}
                        justifyContent={'space-between'}>
                        <FlexContainer flexDirection={'column'}>
                            <DeephansizedRegularText>Day of Withdrawal</DeephansizedRegularText>
                            <RegularText style={globalStyles.singleSpaceAbove}>
                                {autopay.dayOfMonth + nth(autopay.dayOfMonth)}, {getFrequencyString(autopay.frequency)}
                            </RegularText>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer
                        style={globalStyles.innerContainer}
                        flexDirection={'row'}
                        justifyContent={'flex-start'}>
                        <FlexContainer>
                            <RegularText>
                                {paymentIcon && (
                                    <FontAwesome
                                        key={uuid()}
                                        name={paymentIcon}
                                        size={SmarthubTheme.layout.GRIDINCREMENT * 4}
                                        color={theme.colors.icon}
                                    />
                                )}
                            </RegularText>
                        </FlexContainer>
                        <FlexContainer>
                            <RegularText>{'       '}******</RegularText>
                        </FlexContainer>
                    </FlexContainer>
                </Container>
            </FlexContainer>
        );
    },
);
