// TODO: Web-specific date input can be removed once Expo includes react-native-web 0.14+ (likely Expo 43). This will include the onOpen and onClose additions.
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../theme/custom/react-datepicker-custom.css';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { formatDate, parseDateMMDDYYYY } from 'utils/DateUtils';
import { RegularText } from 'components/primitives/StyledText';
import { InputStyles } from 'theme/style/InputStyles';

interface InputDateProps {
    testID?: string;
    value?: string;
    minDate?: string;
    maxDate?: string;
    error?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    onOpen?: () => void;
    onClose?: () => void;
}

interface CalendarContainerProps {
    className?: string;
    children?: React.ReactNode;
}

const defaultProps: InputDateProps = {
    error: false,
    disabled: false,
};

/***
 *@value the date string in mm/dd/yyyy format
 *@disabled  disable the date picker
 *@onChange  (value: string)=>void
 */
const InputDate: React.FC<InputDateProps> = (props: InputDateProps) => {
    const _handleChange = (newDate: Date) => {
        if (props.onChange) {
            props.onChange(formatDate(newDate));
        }
    };

    const MyContainer = ({ className, children }: CalendarContainerProps) => {
        return (
            <View style={styles.calendarWrapper}>
                <CalendarContainer className={className}>
                    <div>{children}</div>
                </CalendarContainer>
            </View>
        );
    };

    return (
        <View style={styles.inputWrapper}>
            <DatePicker
                selected={parseDateMMDDYYYY(props.value || null)}
                minDate={parseDateMMDDYYYY(props.minDate || null)}
                maxDate={parseDateMMDDYYYY(props.maxDate || null)}
                disabled={props.disabled}
                onChange={(newDate: Date) => _handleChange(newDate)}
                calendarContainer={MyContainer}
                placeholderText='MM/DD/YYYY'
                onCalendarOpen={props.onOpen}
                onCalendarClose={props.onClose}
            />
            <MaterialCommunityIcons
                name={'calendar'}
                style={styles.inputIcon}
                size={SmarthubTheme.layout.ICONWIDTH}
                color={SmarthubTheme.colors.darkgrey}
            />
            {!!props.errorMessage && (
                <RegularText style={InputStyles.inputErrorMessage}>{props.errorMessage}</RegularText>
            )}
        </View>
    );
};

InputDate.defaultProps = defaultProps;

export default InputDate;

const styles = StyleSheet.create({
    inputWrapper: {
        zIndex: 1000,
    },
    inputIcon: {
        position: 'absolute',
        top: 10,
        left: SmarthubTheme.layout.GRIDINCREMENT,
    },
    calendarWrapper: {
        zIndex: 1000,
    },
});
