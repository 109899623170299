import React, { useState } from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { MediumBoldText, SmallText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import SmartMoveVehicle from 'screens/smartmove/components/SmartMoveVehicle';
import Hyperlink from 'components/primitives/Hyperlink';
import SmartMovePet from 'screens/smartmove/components/SmartMovePet';
import { navigationRoutes } from 'utils/NavigationUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { useQueryClient } from 'react-query';
import { useStore } from 'contexts/StoreContextProvider';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { useVehiclesAndPetsQuery } from 'queries/hooks/smartmove/useVehiclesAndPetsQuery';
import { useCommandMutation } from 'queries/hooks/useCommandQuery';
import {
    createSubmitSmartmoveVehiclesAndPetsCommandV1,
    SubmitSmartmoveVehiclesAndPetsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/SubmitSmartmoveVehiclesAndPetsCommandV1';
import { SubmitSmartmoveVehiclesAndPetsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/SubmitSmartmoveVehiclesAndPetsResultV1';
import { SmartmoveApplicationHeaderV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveApplicationHeaderV1';
import * as yup from 'yup';
import { FieldArray, FieldArrayRenderProps, Formik } from 'formik';
import {
    createSmartmoveVehicleV1,
    SmartmoveVehicleV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveVehicleV1';
import { SmartmovePetV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmovePetV1';
import { FormikHelpers } from 'formik/dist/types';
import { testProps } from 'components/ComponentTypes';
import Dialog from 'components/primitives/Dialog';
import Button from 'components/primitives/Button';
import { GetSmartmoveApplicationHeaderResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveApplicationHeaderResultV1';
import { observer } from 'mobx-react-lite';
import PhoneLink from 'components/primitives/PhoneLink';

const SmartMoveVehiclesAndPets: React.FC = observer(() => {
    const theme = useTheme();
    const navigation = useNavigation();

    const [showPetAlert, setShowPetAlert] = useState(false);
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    const applicationHeader = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );

    const header: SmartmoveApplicationHeaderV1 | undefined = applicationHeader.query.data?.smartmoveApplicationHeaderV1;

    const { queryKey, queryResult } = useVehiclesAndPetsQuery(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,

        uiStore,
        {
            enabled: hasSessionActiveResidency,
        },
    );

    const vehicleAndPetMutation = useCommandMutation<
        SubmitSmartmoveVehiclesAndPetsCommandV1,
        SubmitSmartmoveVehiclesAndPetsResultV1
    >(uiStore, {
        onSuccess: result => {
            queryClient.setQueriesData(applicationHeader.queryKey, old => {
                return {
                    ...(old as GetSmartmoveApplicationHeaderResultV1),
                    smartmoveApplicationHeaderV1: result.smartmoveApplicationHeaderV1,
                };
            });
            applicationHeader.routeUser(
                result.smartmoveApplicationHeaderV1.smartmoveStep,
                result.smartmoveApplicationHeaderV1.workflowStatus,
                navigation,
            );
            queryClient.invalidateQueries(queryKey);
        },
    });

    const _onSubmit = (
        values: SubmitSmartmoveVehiclesAndPetsCommandV1,
        formikHelper: FormikHelpers<SubmitSmartmoveVehiclesAndPetsCommandV1>,
    ) => {
        uiStore.showAlert({
            message:
                "The information you've entered will be used to create your lease contract. Once this information is submitted, you will not be able to go back to make changes." +
                '\n\nPlease ensure that all information is correct and without spelling mistakes.' +
                '\n\nAre you sure you would like to submit the information at this time to create the lease contract?',
            buttons: [
                {
                    buttonText: 'No,go back',
                    buttonType: 'secondary',
                    onClick: () => {
                        uiStore.hideAlert();
                    },
                },
                {
                    buttonText: 'Yes, submit',
                    buttonType: 'primary',
                    onClick: () => {
                        vehicleAndPetMutation.mutate(values);
                        uiStore.hideAlert();
                    },
                },
            ],
        });
    };

    const _navigateBack = () => {
        navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveDeposit);
    };

    const _removeVehicle = (vehicleArrayHelper: FieldArrayRenderProps, index: number) => {
        uiStore.showAlert({
            message: `Are you sure you want to remove this vehicle?`,
            buttons: [
                {
                    buttonText: 'No,go back',
                    buttonType: 'secondary',
                    onClick: () => {
                        uiStore.hideAlert();
                    },
                },
                {
                    buttonText: 'Yes, remove',
                    buttonType: 'primary',
                    onClick: () => {
                        vehicleArrayHelper.remove(index);
                        uiStore.hideAlert();
                    },
                },
            ],
        });
    };

    const _addVehicle = (vehicleArrayHelper: FieldArrayRenderProps) => {
        const newVehicle = createSmartmoveVehicleV1();
        newVehicle.vehicleId = -1;
        vehicleArrayHelper.push(newVehicle);
    };

    const _removePet = (petArrayHelper: FieldArrayRenderProps, index: number) => {
        setShowPetAlert(true);
    };

    const _addPet = (petArrayHelper: FieldArrayRenderProps) => {
        setShowPetAlert(true);
    };

    const _validationSchema = yup.object({
        smartmoveVehicles: yup.array().of(
            yup.object({
                vehicleMake: yup.string().max(20, 'Max length is 20 characters').required('Vehicle make is required'),
                vehicleLicensePlate: yup
                    .string()
                    .max(15, 'Max length is 15 characters')
                    .required('Vehicle plate is required'),
                vehicleLicensePlateState: yup.string().required('Vehicle plate state is required'),
                vehicleOwnerResidentId: yup.number().min(1, 'Vehicle owner is required'),
            }),
        ),

        smartmovePets: yup.array().of(
            yup.object({
                ownerResidentId: yup.number().min(1, 'Owner is required'),
                breed: yup.string().required('Breed is required'),
                colour: yup.string().max(40, 'Max length is 40 characters').required('Color is required'),
                name: yup.string().max(100, 'Max length is 100 characters').required('Name is required'),
            }),
        ),
    });

    const initialValues = createSubmitSmartmoveVehiclesAndPetsCommandV1();
    initialValues.propertyCd = sessionActiveResidency.propertyCd;
    initialValues.residencyId = sessionActiveResidency.residencyId;
    initialValues.residentId = sessionActiveResidency.residentId;
    initialValues.smartmoveVehicles = queryResult.data?.smartmoveVehicles || ([] as SmartmoveVehicleV1[]);
    initialValues.smartmovePets = queryResult.data?.smartmovePets || ([] as SmartmovePetV1[]);

    if (queryResult.isLoading) return null;

    return (
        <Formik
            enableReinitialize={true}
            validateOnMount={false}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            onSubmit={_onSubmit}
            validationSchema={_validationSchema}
        >
            {formikHelper => (
                <SmartmoveScreenContainer
                    progressIndicatorProps={{
                        currentStep: 1,
                        currentStepInProgress: true,
                    }}
                    footerButtonProps={{
                        text: 'Submit',
                        onClick: formikHelper.handleSubmit,
                        disabled: vehicleAndPetMutation.isLoading,
                    }}
                >
                    {showPetAlert && (
                        <Dialog visible={showPetAlert}>
                            <View>
                                <SmallText style={globalStyles.sectionSpaceBelow}>
                                    Please call us at{' '}
                                    <PhoneLink
                                        smallText={true}
                                        phoneNumber={sessionActiveResidency.propertyPhoneNumber}
                                    />{' '}
                                    to update your pet information.
                                </SmallText>

                                <Button
                                    buttonType={'primary'}
                                    {...testProps('cancel-button')}
                                    onClick={() => {
                                        setShowPetAlert(false);
                                    }}
                                >
                                    Dismiss
                                </Button>
                            </View>
                        </Dialog>
                    )}

                    <MaterialCommunityIcons
                        name={'arrow-left'}
                        size={30}
                        color={SmarthubTheme.colors.darkgrey}
                        style={globalStyles.sectionSpaceBelow}
                        onPress={_navigateBack}
                    />
                    <MediumBoldText style={globalStyles.smartMoveTitleText}>
                        Tell us about your vehicle and pets
                    </MediumBoldText>
                    <SmallText>
                        Before we can create your lease contract, we need information about the vehicle(s) that you will
                        park at the community and the pet(s) that will live with you. All fields are mandatory.
                    </SmallText>

                    <View style={styles.formWrapper}>
                        <FieldArray
                            name={'smartmoveVehicles'}
                            render={vehicleArrayHelper => (
                                <>
                                    {formikHelper.values.smartmoveVehicles &&
                                        formikHelper.values.smartmoveVehicles.map((vehicle, index) => (
                                            <SmartMoveVehicle
                                                key={`vehicle ${index}`}
                                                index={index}
                                                vehicle={vehicle}
                                                ownerOptions={queryResult.data?.applicantOptions}
                                                stateOptions={queryResult.data?.stateOptions}
                                                formikHelper={formikHelper}
                                                onRemove={() => _removeVehicle(vehicleArrayHelper, index)}
                                            />
                                        ))}

                                    <View style={styles.addButtonWrapper}>
                                        {formikHelper.values.smartmoveVehicles &&
                                            header &&
                                            formikHelper.values.smartmoveVehicles.length <
                                                header?.vehicleAndPetInfoV1?.numVehiclesInOnlineApp && (
                                                <Hyperlink onClick={() => _addVehicle(vehicleArrayHelper)}>
                                                    + Add A Vehicle
                                                </Hyperlink>
                                            )}
                                    </View>
                                </>
                            )}
                        />

                        <FieldArray
                            name={'smartmovePets'}
                            render={petArrayHelper => (
                                <>
                                    {formikHelper.values.smartmovePets &&
                                        formikHelper.values.smartmovePets.map((pet, index) => (
                                            <SmartMovePet
                                                key={`pet ${index}`}
                                                index={index}
                                                pet={pet}
                                                onRemove={() => _removePet(petArrayHelper, index)}
                                                ownerOptions={queryResult.data?.applicantOptions}
                                                formikHelper={formikHelper}
                                            />
                                        ))}

                                    <View style={styles.addButtonWrapper}>
                                        <Hyperlink onClick={() => _addPet(petArrayHelper)}>+ Add A Pet</Hyperlink>
                                    </View>
                                </>
                            )}
                        />
                    </View>
                </SmartmoveScreenContainer>
            )}
        </Formik>
    );
});

export default SmartMoveVehiclesAndPets;

const styles = StyleSheet.create({
    formWrapper: {
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN * 4,
    },
    addButtonWrapper: {
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN,
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN * 2,
        paddingBottom: SmarthubTheme.layout.SURROUNDMARGIN * 2,
        borderBottomColor: SmarthubTheme.colors.lightestgrey,
        borderBottomWidth: 1,
    },
});
