import { Fontisto } from '@expo/vector-icons';
import { v4 as uuid } from 'uuid';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { StyleProp, StyleSheet, View } from 'react-native';
import React from 'react';
import { observer } from 'mobx-react-lite';

type Props = {
    onClick: (() => void) | undefined;
    style?: StyleProp<any>;
};

const CloseButton: React.FC<Props> = observer((props: Props) => {
    return (
        <View style={props.style} testID={'close-button'}>
            <Fontisto
                testID={'close-button-clickable'}
                key={uuid()}
                name='close-a'
                size={SmarthubTheme.layout.GRIDINCREMENT * 4}
                color={SmarthubTheme.colors.black}
                onPress={props.onClick}
            />
        </View>
    );
});

export default CloseButton;
