import React from 'react';
import { DeemphansizedTextSmall } from 'components/primitives/StyledText';
import InputString from 'components/input/InputString';
import { SmartmoveUtilityV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveUtilityV1';
import { globalStyles } from 'theme/style/GlobalStyles';
import { View } from 'react-native';

type SmartMoveUtilityProps = {
    util: SmartmoveUtilityV1;
    index: number;
    handleChange: {
        /** Classic React change handler, keyed by input name */
        (e: React.ChangeEvent<any>): void;
        /** Preact-like linkState. Will return a handleChange function.  */
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: {
        /** Classic React blur handler, keyed by input name */
        (e: React.FocusEvent<any>): void;
        /** Preact-like linkState. Will return a handleBlur function. */
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    hasError?: boolean;
    errorMessage?: string;
};

const SmartMoveUtility: React.FC<SmartMoveUtilityProps> = ({
    util,
    handleBlur,
    handleChange,
    index,
    hasError,
    errorMessage,
}: SmartMoveUtilityProps) => {
    const _describeUtilType = (type: string) => {
        if (type === 'EA') return 'Electricity';
        if (type === 'GA') return 'Gas';
        if (type === 'WA') return 'Water';
    };

    return (
        <View key={index}>
            <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>
                {_describeUtilType(util.utilityType)} Account Number
            </DeemphansizedTextSmall>
            <InputString
                initialValue={util.accountNumber}
                onBlur={handleBlur(`utilities.${index}.accountNumber`)}
                onChange={handleChange(`utilities.${index}.accountNumber`)}
                error={hasError && hasError}
                errorMessage={errorMessage && errorMessage}
            />
        </View>
    );
};

export default SmartMoveUtility;
