import { UserSessionStore } from './ui/UserSessionStore';
import { TransactionsStore } from 'stores/domain/TransactionsStore';
import { RequestStore } from 'stores/domain/RequestStore';
import { NTVStore } from 'stores/domain/NTVStore';
import { AppStore } from 'stores/domain/AppStore';
import { ResidentStore } from 'stores/domain/ResidentStore';
import { CommunityNoticesStore } from 'stores/domain/CommunityNoticesStore';
import { UIStore } from 'stores/domain/UIStore';
import { AbstractVersionedRemoteCommand } from 'services/remotecmd/RemoteCmdMgrUtils';

import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { User } from 'firebase/auth';

export class RootStore {
    userSessionStore: UserSessionStore;
    transactionsStore: TransactionsStore;
    requestStore: RequestStore;
    residentStore: ResidentStore;
    communityNoticesStore: CommunityNoticesStore;
    uiStore: UIStore;
    ntvStore: NTVStore;
    appStore: AppStore;

    constructor() {
        this.userSessionStore = new UserSessionStore(this);
        this.transactionsStore = new TransactionsStore(this);
        this.requestStore = new RequestStore(this);
        this.residentStore = new ResidentStore(this);
        this.communityNoticesStore = new CommunityNoticesStore(this);
        this.uiStore = new UIStore(this);
        this.ntvStore = new NTVStore(this);
        this.appStore = new AppStore(this);
    }

    dispatchCommandAsync = (
        uiStore: UIStore,
        cmd: AbstractVersionedRemoteCommand,
        ignoreNetworkErrors: boolean,
        currentUser?: User | null,
    ): Promise<any> => {
        return dispatchCommandAsync(uiStore, cmd, ignoreNetworkErrors, currentUser);
    };
}
