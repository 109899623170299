import { InputDropdownOption } from 'components/input/InputDropdown';
import { formatPhoneNo } from 'utils/StringUtils';
import { SmarthubResidentV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentV1';

export const ntvPreferContactMethodOptions = (
    contactResidentId: number,
    applicants: Array<SmarthubResidentV1>,
): Array<InputDropdownOption> => {
    const options: Array<InputDropdownOption> = [];

    const preferredContactResident = applicants.find(resident => resident.residentId === contactResidentId);

    if (preferredContactResident) {
        if (preferredContactResident.phone1No) {
            options.push({
                label: `${
                    preferredContactResident.phone1TypeCd === 'NotSpecified'
                        ? ''
                        : preferredContactResident.phone1TypeCd + ': '
                }${formatPhoneNo(preferredContactResident.phone1No)}`,
                value: `phone1_${formatPhoneNo(preferredContactResident.phone1No)}_${
                    preferredContactResident.phone1TypeCd
                }`,
            });
        }

        if (preferredContactResident.phone2No) {
            options.push({
                label: `${
                    preferredContactResident.phone2TypeCd === 'NotSpecified'
                        ? ''
                        : preferredContactResident.phone2TypeCd + ': '
                }${formatPhoneNo(preferredContactResident.phone2No)}`,
                value: `phone2_${formatPhoneNo(preferredContactResident.phone2No)}_${
                    preferredContactResident.phone2TypeCd
                }`,
            });
        }

        if (preferredContactResident.email) {
            options.push({
                label: `Email: ${preferredContactResident.email}`,
                value: `email_${preferredContactResident.email}_email`,
            });
        }
    }

    return options;
};
