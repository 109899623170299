import { AddressValidationStatus } from '../../../../shared/businesslogic/addressvalidation/dataobjects/AddressValidationStatus';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.residency.SmarthubAddressV1
 */
export interface SmarthubAddressV1 {
    addressId: number;
    to: string;
    streetLine1: string;
    streetLine2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    validationOverrideUcd: string;
    validationStatusDv: AddressValidationStatus;
}

export function createSmarthubAddressV1(): SmarthubAddressV1 {
    return {
        addressId: 0,
        to: '',
        streetLine1: '',
        streetLine2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        validationOverrideUcd: '',
        validationStatusDv: 'Unverified',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
