import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetCreateTicketDataResultV1 } from './GetCreateTicketDataResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.GetCreateTicketDataCommandV1
 */
export interface GetCreateTicketDataCommandV1
    extends AbstractResidencyAuthenticatedCommand<GetCreateTicketDataResultV1> {
    residentId: number;
}

export function createGetCreateTicketDataCommandV1(): GetCreateTicketDataCommandV1 {
    return {
        residentId: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.GetCreateTicketDataCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
