import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableWithoutFeedback, View, Linking } from 'react-native';
import { useTheme } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import { RegularText, SmallText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Button from 'components/primitives/Button';
import Hyperlink from 'components/primitives/Hyperlink';
import InputCheckbox from 'components/input/InputCheckbox';
import { getAppDownloadURL, showDismiss, setDismiss, isIOS, isAndroid } from 'utils/appTeasers';
import { globalStyles } from 'theme/style/GlobalStyles';
import Constants from 'expo-constants';
import { testProps } from 'components/ComponentTypes';

type SplashScreenProps = {
    closeSplashScreen?: () => void;
};

const appleAppStoreUrl = (Constants?.expoConfig?.extra && Constants.expoConfig.extra['appleAppStoreUrl']) || undefined;
const googleAppStoreUrl =
    (Constants?.expoConfig?.extra && Constants.expoConfig.extra['googleAppStoreUrl']) || undefined;

const SplashScreen: React.FC<SplashScreenProps> = observer((props: SplashScreenProps) => {
    const [showCheckbox, setShowCheckbox] = useState(false);

    const theme = useTheme();

    const venterraLogo = theme.dark
        ? require('../../../assets/images/venterra-logo-navigation.png')
        : require('../../../assets/images/venterra-logo-blue.png');

    useEffect(() => {
        (async () => {
            const showDismissCheckbox = await showDismiss();
            setShowCheckbox(showDismissCheckbox);
        })();
    }, []);

    const _downloadApp = () => {
        const url = getAppDownloadURL();
        if (url) {
            Linking.openURL(url);
        }
    };

    const _goToDownloadPage = (url: string) => {
        Linking.openURL(url);
    };

    const _closeSplashScreen = () => {
        if (props.closeSplashScreen) {
            props.closeSplashScreen();
        }
    };

    const _setDismiss = async () => {
        await setDismiss();
        _closeSplashScreen();
    };

    return (
        <TouchableWithoutFeedback>
            <View style={styles.splashContainer}>
                <View style={styles.splashInner}>
                    <View style={styles.splashTop}>
                        <Image source={venterraLogo} style={styles.venterraLogo} />
                    </View>

                    <View style={styles.splashMiddle} {...testProps('teaser-page-glasses')}>
                        <Image
                            source={require('../../../assets/images/smarthub_logo_stacked.png')}
                            style={styles.smarthubLogo}
                        />
                        <RegularText style={styles.descText}>
                            Explore our newly designed SMART<RegularText style={globalStyles.boldText}>HUB</RegularText>{' '}
                            app
                        </RegularText>
                        {!isIOS() && !isAndroid() ? (
                            <>
                                <View style={styles.downloadBadges}>
                                    <TouchableWithoutFeedback
                                        onPress={() => {
                                            _goToDownloadPage(appleAppStoreUrl);
                                        }}>
                                        <Image
                                            source={require('../../../assets/images/app-store-badge.png')}
                                            style={[styles.downloadBadge, styles.downloadBadgeLeft]}
                                        />
                                    </TouchableWithoutFeedback>
                                    <TouchableWithoutFeedback
                                        onPress={() => {
                                            _goToDownloadPage(googleAppStoreUrl);
                                        }}>
                                        <Image
                                            source={require('../../../assets/images/google-play-badge.png')}
                                            style={[styles.downloadBadge, styles.downloadBadgeRight]}
                                        />
                                    </TouchableWithoutFeedback>
                                </View>
                            </>
                        ) : (
                            <Button
                                onClick={_downloadApp}
                                style={styles.downloadButton}
                                {...testProps('download-app-button')}>
                                Download the SMARTHUB app
                            </Button>
                        )}
                    </View>

                    <View style={styles.splashBottom}>
                        <View style={styles.dismissText}>
                            <Hyperlink onClick={_closeSplashScreen} {...testProps('go-to-login')}>
                                No thanks, take me to the website
                            </Hyperlink>
                        </View>
                        {showCheckbox && (
                            <View style={styles.remindCheckbox}>
                                <InputCheckbox style={'small'} onChange={_setDismiss} />
                                <SmallText style={styles.remindLabel}>Don&apos;t ask me again</SmallText>
                            </View>
                        )}
                    </View>
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
});

export default SplashScreen;

const styles = StyleSheet.create({
    splashContainer: {
        position: 'absolute',
        zIndex: 100,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SmarthubTheme.colors.white,
    },
    splashInner: {
        display: 'flex',
        width: '100%',
        maxWidth: 360,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    splashTop: {
        flex: 1,
        alignItems: 'center',
    },
    venterraLogo: {
        width: 148,
        height: 20,
        marginTop: 60,
    },
    splashMiddle: {
        flex: 1,
        alignItems: 'center',
    },
    smarthubLogo: {
        width: 165,
        height: 67,
        marginBottom: 55,
    },
    descText: {
        marginBottom: 35,
    },
    downloadButton: {
        width: 360,
        maxWidth: '100%',
        marginBottom: 16,
    },
    downloadBadges: {
        display: 'flex',
        flexDirection: 'row',
    },
    downloadBadge: {
        flex: 1,
        width: 128,
        height: 38,
    },
    downloadBadgeLeft: {
        marginRight: 18,
    },
    downloadBadgeRight: {
        marginLeft: 18,
    },
    splashBottom: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    dismissText: {
        marginBottom: 15,
    },
    remindCheckbox: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 40,
    },
    remindLabel: {
        marginTop: 4,
        marginLeft: -10,
    },
});
