import { SmarthubNotificationTypeV1 } from '../../businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationTypeV1';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { EmptyVersionedRemoteResult } from '../../../remotecmd/versioned/EmptyVersionedRemoteResult';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.notifications.MarkSmarthubNotificationsSeenByTypeCommandV1
 */
export interface MarkSmarthubNotificationsSeenByTypeCommandV1
    extends AbstractResidencyAuthenticatedCommand<EmptyVersionedRemoteResult> {
    residentId: number;
    smarthubNotificationType: SmarthubNotificationTypeV1;
}

export function createMarkSmarthubNotificationsSeenByTypeCommandV1(): MarkSmarthubNotificationsSeenByTypeCommandV1 {
    return {
        residentId: 0,
        smarthubNotificationType: 'MaintenanceRequestCorrespondence',
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.notifications.MarkSmarthubNotificationsSeenByTypeCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
