import { StackNavigationOptions } from '@react-navigation/stack';
import { getAuth } from 'firebase/auth';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import * as React from 'react';
import { Image, Text, StyleSheet, View } from 'react-native';
import { RegularText, SmallerText } from 'components/primitives/StyledText';
import LeaseSwitchLink from 'components/cards/LeaseSwitchLink';
import { globalStyles } from 'theme/style/GlobalStyles';
import Link from 'components/primitives/Link';
import { isDesktopNotMobile } from 'utils/uiUtils';

type SimpleNavProp = { canGoBack: () => never; goBack: () => void; navigate: (arg0: string) => void };

export const getSmartMoveScreenOptions = (
    navigation: SimpleNavProp,
    friendlyAptString: string,
    theme: ReactNativePaper.Theme,
    showAddress = true,
    showSwitchButton = true,
    showLogoutButton = true,
): StackNavigationOptions => {
    const showheaderitems = showAddress || showSwitchButton;
    const isDesktop = isDesktopNotMobile();
    const auth = getAuth();
    return {
        title: isDesktop ? 'SMARTMOVE' : '',
        headerShown: showheaderitems,
        headerTintColor: theme.colors.text,
        headerStyle: {
            backgroundColor: isDesktop ? SmarthubTheme.colors.primary : SmarthubTheme.colors.white,
            borderBottomWidth: 0,
            shadowColor: 'transparent',
            shadowRadius: 0,
            shadowOpacity: 0,
            elevation: 0,
        },
        headerLeft: () => {
            if (showAddress && !isDesktop) {
                return (
                    <View style={styles.headerWidgetContainer}>
                        <SmallerText
                            style={{
                                color: theme.colors.black,
                                paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
                            }}>
                            {friendlyAptString || 'No Apartment Selected'}
                        </SmallerText>
                    </View>
                );
            } else {
                return <View style={styles.headerWidgetContainer} />;
            }
        },
        headerRight: () => {
            if (showAddress || showSwitchButton) {
                return (
                    <View style={[globalStyles.flexRow, globalStyles.horizontalPad]}>
                        {showAddress && isDesktop && (
                            <View style={[styles.headerWidgetContainer]}>
                                {!!friendlyAptString && (
                                    <View style={[styles.aptDescWeb]}>
                                        <RegularText
                                            style={{
                                                color: theme.colors.white,
                                            }}>
                                            {friendlyAptString.substring(0, friendlyAptString.indexOf('('))}
                                        </RegularText>
                                        <RegularText
                                            style={{
                                                color: theme.colors.white,
                                            }}>
                                            {friendlyAptString.substring(friendlyAptString.indexOf('('))}
                                        </RegularText>
                                    </View>
                                )}
                                {!friendlyAptString && (
                                    <RegularText
                                        style={{
                                            color: theme.colors.white,
                                        }}>
                                        {'No Apartment Selected'}
                                    </RegularText>
                                )}
                            </View>
                        )}

                        {showSwitchButton && (
                            <View style={styles.switchLease}>
                                <LeaseSwitchLink
                                    labelStyle={
                                        isDesktop
                                            ? [
                                                  theme.typography.regular,
                                                  {
                                                      color: theme.colors.white,
                                                  },
                                              ]
                                            : [
                                                  theme.typography.small,
                                                  {
                                                      color: theme.colors.black,
                                                  },
                                              ]
                                    }
                                />
                            </View>
                        )}
                        {showLogoutButton && (
                            <View style={showSwitchButton ? styles.logoutButtonWithSwitch : styles.logoutButton}>
                                <Link
                                    onClick={() => {
                                        auth.signOut();
                                    }}>
                                    <Text style={styles.logoutText}>Log Out</Text>
                                </Link>
                            </View>
                        )}
                    </View>
                );
            } else {
                return <View style={styles.headerWidgetContainer} />;
            }
        },
        headerRightContainerStyle: isDesktop
            ? {
                  maxWidth: '40%',
              }
            : undefined,
        headerBackTitle: '',
        headerTitle: isDesktop ? SmartMoveHeaderTitle : undefined,
        animationEnabled: false,
        cardStyle: {
            backgroundColor: theme.colors.background,
        },
    };
};

export const SmartMoveHeaderTitle = () => {
    return (
        <View style={styles.venterraheaderlogoview}>
            <Image
                source={require('../../../assets/images/venterra-logo-navigation.png')}
                style={styles.venterraLogo}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    headerWidgetContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    aptDescWeb: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginRight: isDesktopNotMobile()
            ? SmarthubTheme.layout.GRIDINCREMENT * 4
            : SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    switchLease: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginRight: isDesktopNotMobile()
            ? SmarthubTheme.layout.GRIDINCREMENT * 4
            : SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    logoutButton: {
        marginTop: isDesktopNotMobile() ? 2 : 1,
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
    logoutButtonWithSwitch: {
        marginTop: isDesktopNotMobile() ? 10 : 9,
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
    logoutText: {
        fontSize: isDesktopNotMobile() ? 15 : 11,
        color: isDesktopNotMobile() ? SmarthubTheme.colors.white : SmarthubTheme.colors.black,
    },
    venterraLogo: {
        height: 20,
        width: 147,
    },
    venterraheaderlogoview: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
