import * as React from 'react';
import { Platform, StyleProp, StyleSheet, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useStore } from 'contexts/StoreContextProvider';
import { RegularText } from 'components/primitives/StyledText';
import { InputStyles } from 'theme/style/InputStyles';
import { useEffect, useRef } from 'react';
import { isDesktopAndMobile } from 'utils/uiUtils';

export interface InputDropdownOption {
    label: string;
    value: string;
    icon?: () => JSX.Element;
    hidden?: boolean;
    disabled?: boolean;
    selected?: boolean;
}

interface InputDropdownProps {
    testID?: string;
    accessibilityLabel?: string;
    placeholder?: string;
    options?: Array<InputDropdownOption>;
    selectedValue?: string | number | undefined;
    error?: boolean;
    errorMessage?: string;
    selectedLabelPosition?: 'bottom' | 'right';
    onChange?: (value: string | number | undefined) => void;
    disabled?: boolean;
}

const defaultProps: InputDropdownProps = {
    error: false,
    selectedLabelPosition: 'bottom',
};

const InputDropdown: React.FC<InputDropdownProps> = (props: InputDropdownProps) => {
    const { uiStore } = useStore();

    const [selectedValue, setSelectedValue] = React.useState('');

    useEffect(() => {
        const selectedOption = props.options?.find(option => {
            return option.value === props.selectedValue;
        });

        if (selectedOption) {
            setSelectedValue(selectedOption.value);
        } else if (props.options && props.options.length > 0) {
            setSelectedValue(props.options[0].value);
        } else {
            setSelectedValue('');
        }
    }, [props.options, props.selectedValue]);

    const dropdownControlColor = uiStore.useDarkTheme ? SmarthubTheme.colors.white : SmarthubTheme.colors.text;
    let dropdownControlStyle: StyleProp<any> = [styles.dropdownControl, { color: dropdownControlColor }];

    if (isDesktopAndMobile()) {
        dropdownControlStyle = [
            styles.dropdownControl,
            styles.dropdownControliOSSafari,
            { color: dropdownControlColor },
        ];
    }

    const style: StyleProp<ViewStyle> = props.error
        ? [styles.dropdownInput, InputStyles.inputError]
        : props.disabled
        ? [styles.dropdownInput, InputStyles.inputDisabled]
        : [styles.dropdownInput];

    return (
        <>
            <View style={style} testID={props.testID} accessibilityLabel={props.accessibilityLabel}>
                <Picker
                    style={dropdownControlStyle}
                    selectedValue={props.selectedValue}
                    mode={'dropdown'}
                    enabled={!props.disabled}
                    dropdownIconColor={uiStore.useDarkTheme ? SmarthubTheme.colors.white : SmarthubTheme.colors.text}
                    onValueChange={props.onChange}>
                    {props.options?.map((option, index) => (
                        <Picker.Item label={option.label} value={option.value} key={`picker-item-${index}`} />
                    ))}
                </Picker>
                {isDesktopAndMobile() && (
                    <MaterialCommunityIcons
                        style={styles.dropdownIcon}
                        name={'chevron-down'}
                        size={24}
                        color={uiStore.useDarkTheme ? SmarthubTheme.colors.white : SmarthubTheme.colors.text}
                    />
                )}
            </View>
            {!!props.errorMessage && (
                <RegularText style={InputStyles.inputErrorMessage}>{props.errorMessage}</RegularText>
            )}
        </>
    );
};

InputDropdown.defaultProps = defaultProps;

export default InputDropdown;

const styles = StyleSheet.create({
    dropdownInput: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.border,
        backgroundColor: SmarthubTheme.colors.white,
    },
    dropdownControl: {
        borderWidth: 0,
        height: Platform.OS === 'android' ? 54 : 41,
        paddingLeft: Platform.OS === 'web' ? 4 : 0,
        backgroundColor: SmarthubTheme.colors.white,
    },
    dropdownIcon: {
        position: 'absolute',
        right: SmarthubTheme.layout.GRIDINCREMENT,
        top: Platform.OS === 'web' ? '25%' : '50%',
    },
    dropdownControliOSSafari: {
        WebkitAppearance: 'none',
        fontSize: 14,
        backgroundColor: SmarthubTheme.colors.white,
    },
});
