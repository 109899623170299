import React from 'react';
import { observer } from 'mobx-react-lite';
import { ActivityIndicator as RNPActivityIndicator, useTheme } from 'react-native-paper';
import { View } from 'react-native';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useStore } from 'contexts/StoreContextProvider';
import { testProps } from 'components/ComponentTypes';

type ActivityIndicatorProps = {
    animating?: boolean;
};

const ActivityIndicator: React.FC<ActivityIndicatorProps> = observer(({ animating = true }: ActivityIndicatorProps) => {
    const theme = useTheme();
    const { uiStore } = useStore();

    return (
        <View {...testProps('loading')} style={animating ? globalStyles.indicatorWrapper : {}}>
            <RNPActivityIndicator
                animating={animating}
                color={uiStore.useDarkTheme ? theme.colors.white : theme.colors.navy}
                size={'large'}
                style={globalStyles.indicator}
            />
        </View>
    );
});

export default ActivityIndicator;
