import { FirebaseOptions } from 'expo-firebase-core';
import Constants from 'expo-constants';

class SmartHubFirebaseConfig implements FirebaseOptions {
    appId: string;
    apiKey: string;
    databaseURL: string;
    trackingId: string;
    messagingSenderId: string;
    storageBucket: string;
    projectId: string;
    authDomain: string;
    measurementId: string;

    constructor(
        appId: string,
        apiKey: string,
        databaseURL: string,
        trackingId: string,
        messagingSenderId: string,
        storageBucket: string,
        projectId: string,
        authDomain: string,
        measurementId: string,
    ) {
        this.appId = appId;
        this.apiKey = apiKey;
        this.databaseURL = databaseURL;
        this.trackingId = trackingId;
        this.messagingSenderId = messagingSenderId;
        this.storageBucket = storageBucket;
        this.projectId = projectId;
        this.authDomain = authDomain;
        this.measurementId = measurementId;
    }
}

const firebaseConfigExtra = (Constants?.expoConfig?.extra && Constants.expoConfig.extra['firebaseConfig']) || undefined;
const smartHubFirebaseConfig: SmartHubFirebaseConfig | undefined =
    (firebaseConfigExtra &&
        new SmartHubFirebaseConfig(
            firebaseConfigExtra.appId,
            firebaseConfigExtra.apiKey,
            firebaseConfigExtra.databaseURL,
            firebaseConfigExtra.trackingId,
            firebaseConfigExtra.messagingSenderId,
            firebaseConfigExtra.storageBucket,
            firebaseConfigExtra.projectId,
            firebaseConfigExtra.authDomain,
            firebaseConfigExtra.measurementId,
        )) ||
    undefined;

const googleConfigExtra = (Constants?.expoConfig?.extra && Constants.expoConfig.extra['googleConfig']) || undefined;
const googleConfig = {
    expoClientId: (googleConfigExtra && googleConfigExtra.expoClientId) || '',
    iosClientId: (googleConfigExtra && googleConfigExtra.iosClientId) || '',
    androidClientId: (googleConfigExtra && googleConfigExtra.androidClientId) || '',
    webClientId: (googleConfigExtra && googleConfigExtra.webClientId) || '',
    redirPath: (googleConfigExtra && googleConfigExtra.redirPath) || '',
};

export default {
    FirebaseConfig: smartHubFirebaseConfig,
    GoogleConfig: googleConfig,
};
