import React from 'react';
import { Image, SafeAreaView, StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { DeephansizedRegularText, Heading3 } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useRoute } from '@react-navigation/native';
import { getRouteParams } from 'utils/routeUtils';
import OrangeCheckmark from 'components/animations/OrangeCheckmark';
import { globalStyles } from 'theme/style/GlobalStyles';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useQueryClient } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';

interface ConfirmScreenProps {
    navigation: StackNavigationProp<any>;
}

const ConfirmScreen: React.FC<ConfirmScreenProps> = (props: ConfirmScreenProps) => {
    const { navigation } = props;
    const route = useRoute();
    const { header, desc1, desc2, buttonText, smartDeskConfirm, testID, toHome } = getRouteParams(route);

    const queryClient = useQueryClient();
    queryClient.invalidateQueries(QueryKeys.Requests);

    return (
        <SafeAreaView style={[globalStyles.container, styles.container]}>
            <OrangeCheckmark lottieViewStyle={styles.animation} />

            {smartDeskConfirm && (
                <Image
                    source={
                        smartDeskConfirm
                            ? require('../../../../assets/images/SmartDeskFullLogo.png')
                            : require('../../../../assets/images/Checkmark.png')
                    }
                    style={[smartDeskConfirm ? styles.smartDeskImage : styles.image, globalStyles.sectionSpaceBelow]}
                />
            )}
            <Heading3 style={[globalStyles.sectionSpaceBelow, styles.descSection]}>{header}</Heading3>
            {(desc1 || desc2) && (
                <View style={[globalStyles.sectionSpaceBelow, styles.descSection]}>
                    {desc1 && <DeephansizedRegularText>{desc1}</DeephansizedRegularText>}
                    {desc2 && <DeephansizedRegularText>{desc2}</DeephansizedRegularText>}
                </View>
            )}
            <View style={styles.button}>
                <Button
                    testID={testID}
                    onClick={() => {
                        navigation.navigate(navigationRoutes.homeRoutes.home);
                        if (!toHome) navigation.navigate(navigationRoutes.homeRoutes.myRequests);
                    }}>
                    {buttonText}
                </Button>
            </View>
        </SafeAreaView>
    );
};

export default ConfirmScreen;

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    descSection: {
        alignItems: 'center',
        textAlign: 'center',
        paddingHorizontal: SmarthubTheme.layout.INNERPAD,
    },
    image: {
        width: SmarthubTheme.layout.SMALLIMAGEWIDTH,
        height: SmarthubTheme.layout.SMALLIMAGEHEIGHT,
    },
    smartDeskImage: {
        width: SmarthubTheme.layout.SMARTDESKIMAGEWIDTH,
        height: SmarthubTheme.layout.SMARTDESKIMAGEHEIGHT,
        resizeMode: 'contain',
    },
    button: {
        width: '88%',
    },
    animation: {
        width: 200,
        height: 200,
    },
});
