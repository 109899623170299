import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { MediumBoldText, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Hyperlink from 'components/primitives/Hyperlink';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useNavigation } from '@react-navigation/native';
import { useStore } from 'contexts/StoreContextProvider';
import SmartMoveMoveInChargesBreakdown from 'screens/smartmove/components/SmartMoveMoveInChargesBreakdown';
import { SmartmovePaymentInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmovePaymentInfoV1';
import { observer } from 'mobx-react-lite';
import { formatDateMonthLabelFullNoYear, parseDateMMDDYYYY } from 'utils/DateUtils';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { SmartmoveApplicationHeaderV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveApplicationHeaderV1';
import { useQueryClient } from 'react-query';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { SmartmoveUtilityV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveUtilityV1';
import {
    createDefaultSmartmoveRenterInsuranceV1,
    SmartmoveRenterInsuranceV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveRenterInsuranceV1';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import {
    createSubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1,
    SubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/SubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1';
import Anchor from 'components/primitives/Anchor';
import SmartMovePolicy from 'screens/smartmove/components/SmartMovePolicy';
import { ArrayHelpers } from 'formik/dist/FieldArray';
import SmartMoveUtility from 'screens/smartmove/components/SmartMoveUtility';
import { useCommandMutation } from 'queries/hooks/useCommandQuery';
import { useUtilitiesAndInsurance } from 'queries/hooks/smartmove/useUtilitiesAndInsurance';
import { FormikHelpers } from 'formik/dist/types';
import { navigationRoutes } from 'utils/NavigationUtils';
import { GetSmartmoveApplicationHeaderResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveApplicationHeaderResultV1';
import { SubmitSmartmoveUtilitiesAndRenterInsurancesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/SubmitSmartmoveUtilitiesAndRenterInsurancesResultV1';
import { generateRandomNumber } from 'utils/NumberUtils';
import PhoneLink from 'components/primitives/PhoneLink';

export type SmartMoveUtilitiesAndRentersInsuranceFormValues = {
    utilities: Array<SmartmoveUtilityV1>;
    insurances: Array<SmartmoveRenterInsuranceV1>;
};

const SmartMoveUtilitiesAndRentersInsurance: React.FC = observer(() => {
    const navigation = useNavigation();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    //get utils / ins / header
    const { utilsInsQuery, utilsInsQueryKey, headerQuery, headerQueryKey, routeUser } = useUtilitiesAndInsurance(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );
    const { data: utilsInsData, isLoading: isUtilsInsLoading, isFetching: isUtilsInsFetching } = utilsInsQuery;
    const { data: headerData, isLoading: isHeaderLoading } = headerQuery;

    const header: SmartmoveApplicationHeaderV1 | undefined = headerData?.smartmoveApplicationHeaderV1;
    const paymentInfoV1: SmartmovePaymentInfoV1 | undefined = header?.paymentInfoV1;
    const showProgressIndicator =
        headerQuery.data?.smartmoveApplicationHeaderV1?.smartmoveStep === 'UtilitiesAndInsurance';

    //mutate utils/ins
    const utilitiesAndInsuranceMutation = useCommandMutation<
        SubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1,
        SubmitSmartmoveUtilitiesAndRenterInsurancesResultV1
    >(uiStore);

    const insuranceEnabled = undefined !== utilsInsData && utilsInsData?.insuranceLiabilityLimit !== '0';

    useEffect(() => {
        isUtilsInsLoading || isHeaderLoading ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [isUtilsInsLoading, isHeaderLoading]);

    const _onSubmit = (
        values: SmartMoveUtilitiesAndRentersInsuranceFormValues,
        formikHelpers: FormikHelpers<SmartMoveUtilitiesAndRentersInsuranceFormValues>,
    ) => {
        logInfo(INFO_LOGGER, 'Utilities and insurance polices to save...', values);

        formikHelpers.setSubmitting(true);
        // Update user details
        const updateCmd = createSubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1();
        updateCmd.propertyCd = sessionActiveResidency.propertyCd;
        updateCmd.residencyId = sessionActiveResidency.residencyId;
        updateCmd.utilities = values.utilities;
        updateCmd.insurances = values.insurances.filter(insurance => insurance.companyName && insurance.policyNumber);

        //console.log('sending ', JSON.stringify(updateCmd));

        const _onSuccess = async (result: SubmitSmartmoveUtilitiesAndRenterInsurancesResultV1) => {
            queryClient.setQueriesData(headerQueryKey, old => {
                return {
                    ...(old as GetSmartmoveApplicationHeaderResultV1),
                    smartmoveApplicationHeaderV1: result.smartmoveApplicationHeaderV1,
                };
            });
            routeUser(
                result.smartmoveApplicationHeaderV1.smartmoveStep,
                result.smartmoveApplicationHeaderV1.workflowStatus,
                navigation,
            );
            queryClient.invalidateQueries(utilsInsQueryKey);
        };

        const _onSettled = () => {
            formikHelpers.setSubmitting(false);
        };
        utilitiesAndInsuranceMutation.mutate(updateCmd, {
            onSuccess: _onSuccess,
            onSettled: _onSettled,
        });
    };

    const _addPolicy = (arrayHelpers: ArrayHelpers, insurances: Array<SmartmoveRenterInsuranceV1>) => {
        const newPolicy = createDefaultSmartmoveRenterInsuranceV1(sessionActiveResidency.residencyId);
        // See DEV-25369 for this
        newPolicy.policyId = generateRandomNumber(100000) * -1;
        arrayHelpers.insert(insurances.length, newPolicy);
    };

    const _removePolicy = (
        arrayHelpers: ArrayHelpers,
        index: number,
        insurances: Array<SmartmoveRenterInsuranceV1>,
    ) => {
        //if this exists on server then clone and set it to be deleted and put it at the end of the array
        const copy = { ...insurances[index] };
        if (copy.policyId > 0) {
            copy.toDeleteYn = true;
            arrayHelpers.insert(insurances.length, copy);
        }
        //remove the original policy
        arrayHelpers.remove(index);
    };

    const _validationSchema =
        sessionActiveResidency.expectMoveinDt &&
        yup.object({
            utilities: yup.array().of(
                yup.object({
                    accountNumber: yup
                        .string()
                        .required('Account Number is required')
                        .max(100, 'The Account Number cannot exceed 100 characters'),
                }),
            ),
            insurances: yup.array().of(
                yup.object({
                    companyName: yup
                        .string()
                        .required('Company name is required')
                        .max(100, 'The Company Name cannot exceed 100 characters'),
                    policyNumber: yup
                        .string()
                        .required('Policy Number is required')
                        .max(100, 'The Policy Number Name cannot exceed 100 characters'),
                    startDt: yup
                        .string()
                        .required('Start Date is required')
                        .test('maxDate', 'Policy must start on or before the expected move in date', function (value) {
                            if (!value || !sessionActiveResidency.expectMoveinDt) return false;
                            if (parseDateMMDDYYYY(value) && parseDateMMDDYYYY(sessionActiveResidency.expectMoveinDt)) {
                                return (
                                    parseDateMMDDYYYY(value)! <=
                                    parseDateMMDDYYYY(sessionActiveResidency.expectMoveinDt)!
                                );
                            } else {
                                return false;
                            }
                        }),
                    endDt: yup
                        .string()
                        .required('End Date is required')
                        .test('minDate', 'Policy must end on or after the expected move in date', function (value) {
                            if (!value || !sessionActiveResidency.expectMoveinDt) return false;
                            if (parseDateMMDDYYYY(value) && parseDateMMDDYYYY(sessionActiveResidency.expectMoveinDt)) {
                                return (
                                    parseDateMMDDYYYY(value)! >=
                                    parseDateMMDDYYYY(sessionActiveResidency.expectMoveinDt)!
                                );
                            } else {
                                return false;
                            }
                        }),
                    liabilityConfirmed: yup.boolean().oneOf([true], 'You must confirm the liability coverage'),
                    policyResidents: yup.array().of(yup.number()).min(1, 'At least one resident must be selected'),
                }),
            ),
        });

    const initialValues: SmartMoveUtilitiesAndRentersInsuranceFormValues = {
        utilities: [...(utilsInsData?.utilities || [])],
        insurances: [...(utilsInsData?.insurances || [])],
    };

    if (isUtilsInsLoading) {
        return null;
    } else {
        return (
            <Formik
                enableReinitialize
                validateOnMount={false}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={_onSubmit}
                validationSchema={_validationSchema}>
                {formikHelpers => {
                    const _hasUtilitiesYn = formikHelpers.values.utilities && formikHelpers.values.utilities.length > 0;
                    return (
                        <SmartmoveScreenContainer
                            showBack={!showProgressIndicator}
                            showClose={!showProgressIndicator && Platform.OS !== 'web'}
                            closeRoute={navigationRoutes.smartmoveRoutes.smartMoveMoveInCountdown}
                            progressIndicatorProps={
                                (showProgressIndicator && {
                                    currentStep: 3,
                                    currentStepInProgress: true,
                                }) ||
                                undefined
                            }
                            footerButtonProps={{
                                text: 'Submit',
                                onClick: formikHelpers.handleSubmit,
                                disabled: utilitiesAndInsuranceMutation.isLoading,
                            }}>
                            <View style={Platform.OS === 'web' ? globalStyles.innerContainer : {}}>
                                {paymentInfoV1?.applyPaymentRestrictions && (
                                    <>
                                        <RegularText style={globalStyles.smartMoveParagraphText}>
                                            Please call us at{' '}
                                            <PhoneLink phoneNumber={sessionActiveResidency.propertyPhoneNumber} />{' '}
                                            during business hours on or before{' '}
                                            {formatDateMonthLabelFullNoYear(
                                                parseDateMMDDYYYY(sessionActiveResidency.expectMoveinDt),
                                            )}{' '}
                                            to make a payment arrangement for the amount listed below.
                                        </RegularText>
                                        <SmartMoveMoveInChargesBreakdown
                                            restricted={paymentInfoV1.applyPaymentRestrictions}
                                        />
                                    </>
                                )}
                                <MediumBoldText
                                    style={[globalStyles.smartMoveTitleText, globalStyles.doubleSpaceAbove]}>
                                    {`Set up your${
                                        _hasUtilitiesYn ? ` utilities ${insuranceEnabled ? `and` : ''}` : ''
                                    } ${insuranceEnabled ? `renters' insurance` : ''}`}
                                </MediumBoldText>

                                {_hasUtilitiesYn && (
                                    <>
                                        <RegularText>
                                            Please contact the utility provider to set up your account. Once you have
                                            done this, please enter the details below.
                                        </RegularText>

                                        <View style={styles.formWrapper}>
                                            <MediumBoldText>Utilities</MediumBoldText>
                                        </View>

                                        <FieldArray
                                            name='utilities'
                                            render={arrayHelpers => (
                                                <View>
                                                    {formikHelpers.values.utilities &&
                                                        formikHelpers.values.utilities.length > 0 &&
                                                        formikHelpers.values.utilities.map((util, index) => {
                                                            const errorMessage = //error message for this account number?
                                                                formikHelpers.errors.utilities &&
                                                                formikHelpers.errors.utilities[index]
                                                                    ? (
                                                                          formikHelpers.errors.utilities[index] as {
                                                                              accountNumber: string;
                                                                          }
                                                                      ).accountNumber
                                                                    : '';
                                                            return (
                                                                <SmartMoveUtility
                                                                    index={index}
                                                                    key={index}
                                                                    util={util}
                                                                    handleChange={formikHelpers.handleChange}
                                                                    handleBlur={formikHelpers.handleBlur}
                                                                    hasError={
                                                                        (!!formikHelpers.errors &&
                                                                            !!formikHelpers.errors.utilities &&
                                                                            !!formikHelpers.errors.utilities[index]) ||
                                                                        false
                                                                    }
                                                                    errorMessage={errorMessage}
                                                                />
                                                            );
                                                        })}
                                                </View>
                                            )}
                                        />
                                    </>
                                )}

                                {insuranceEnabled && (
                                    <View
                                        style={[
                                            styles.insuranceWrapper,
                                            globalStyles.singleSpaceAbove,
                                            globalStyles.sectionSpaceBelow,
                                        ]}>
                                        <MediumBoldText style={globalStyles.smartMoveTitleText}>
                                            Renters&apos; Insurance
                                        </MediumBoldText>
                                        <RegularText>
                                            Renters Insurance is required prior to moving in. If you do not have a
                                            preferred insurance provider, we have partnered with Assurant as our
                                            preferred renter&apos;s insurance provider for guaranteed coverage; visit
                                            <Hyperlink>
                                                {' '}
                                                <Anchor
                                                    href={'http://www.assurantrenters.com/venterrarealty'}
                                                    newTab={Platform.OS === 'web'}>
                                                    www.assurantrenters.com/venterra{' '}
                                                </Anchor>
                                            </Hyperlink>
                                            to purchase a policy.
                                        </RegularText>
                                    </View>
                                )}

                                {insuranceEnabled && (
                                    <FieldArray
                                        name='insurances'
                                        render={arrayHelpers => (
                                            <>
                                                <View>
                                                    {formikHelpers.values.insurances &&
                                                        insuranceEnabled &&
                                                        formikHelpers.values.insurances.length > 0 &&
                                                        formikHelpers.values.insurances
                                                            .filter(insurance => !insurance.toDeleteYn)
                                                            .map((insurance, index) => {
                                                                return (
                                                                    <View
                                                                        style={globalStyles.sectionSpaceBelow}
                                                                        key={`${index}-${insurance.policyId}`}>
                                                                        <SmartMovePolicy
                                                                            index={index}
                                                                            canRemoveFirstPolicy={
                                                                                formikHelpers.values.insurances.length >
                                                                                1
                                                                            }
                                                                            onRemove={() => {
                                                                                uiStore.showAlert({
                                                                                    message:
                                                                                        'Are you sure you want to remove this insurance policy?',
                                                                                    buttons: [
                                                                                        {
                                                                                            buttonText: 'No, go back',
                                                                                            buttonType: 'secondary',
                                                                                            onClick: uiStore.hideAlert,
                                                                                        },
                                                                                        {
                                                                                            buttonText: 'Yes, continue',
                                                                                            buttonType: 'primary',
                                                                                            onClick: () => {
                                                                                                uiStore.hideAlert();
                                                                                                _removePolicy(
                                                                                                    arrayHelpers,
                                                                                                    index,
                                                                                                    formikHelpers.values
                                                                                                        .insurances,
                                                                                                );
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                });
                                                                            }}
                                                                            key={index}
                                                                            policy={insurance}
                                                                            insuranceLimit={
                                                                                utilsInsData?.insuranceLiabilityLimit ||
                                                                                ''
                                                                            }
                                                                            handleChange={formikHelpers.handleChange}
                                                                            handleBlur={formikHelpers.handleBlur}
                                                                            errors={
                                                                                formikHelpers.errors.insurances &&
                                                                                formikHelpers.errors?.insurances[index]
                                                                            }
                                                                            activeResidents={
                                                                                utilsInsData?.activeResidents || []
                                                                            }
                                                                        />
                                                                    </View>
                                                                );
                                                            })}
                                                </View>
                                                <View style={[styles.addButtonWrapper]}>
                                                    <Hyperlink
                                                        onClick={() =>
                                                            _addPolicy(arrayHelpers, formikHelpers.values.insurances)
                                                        }>
                                                        + Add Another Renters&apos; Insurance Policy
                                                    </Hyperlink>
                                                </View>
                                            </>
                                        )}
                                    />
                                )}
                            </View>
                        </SmartmoveScreenContainer>
                    );
                }}
            </Formik>
        );
    }
});

export default SmartMoveUtilitiesAndRentersInsurance;

const styles = StyleSheet.create({
    formWrapper: {
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN * 4,
    },
    insuranceWrapper: {
        borderTopColor: SmarthubTheme.colors.lightestgrey,
        borderTopWidth: 1,
        paddingTop: SmarthubTheme.layout.SURROUNDMARGIN * 2,
    },
    addButtonWrapper: {
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN * 2,
        paddingBottom: SmarthubTheme.layout.SURROUNDMARGIN * 4,
    },
});
