import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetMyRequestResultV1 } from './GetMyRequestResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.GetMyRequestCommandV1
 */
export interface GetMyRequestCommandV1 extends AbstractResidencyAuthenticatedCommand<GetMyRequestResultV1> {
    includeCompletedYn: boolean;
    startIndex: number;
    rowLimit: number;
}

export function createGetMyRequestCommandV1(): GetMyRequestCommandV1 {
    return {
        includeCompletedYn: false,
        startIndex: 0,
        rowLimit: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.GetMyRequestCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
