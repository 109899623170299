import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { RemoveZegoPaymentMethodResultV1 } from './RemoveZegoPaymentMethodResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.RemoveZegoPaymentMethodCommandV1
 */
export interface RemoveZegoPaymentMethodCommandV1
    extends AbstractResidentAuthenticatedCommand<RemoveZegoPaymentMethodResultV1> {
    gatewayPayerId: number;
}

export function createRemoveZegoPaymentMethodCommandV1(): RemoveZegoPaymentMethodCommandV1 {
    return {
        gatewayPayerId: 0,
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.RemoveZegoPaymentMethodCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
