import { UIStore } from 'stores/domain/UIStore';
import { MutationOptions, useMutation } from 'react-query';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { SmarthubResidencyV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';
import { RenewMyLeaseResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/renewal/RenewMyLeaseResultV1';
import { RenewMyLeaseSubmitData } from 'screens/home/manageMyLease/renewal/RenewMyLease';
import { createRenewMyLeaseCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/renewal/RenewMyLeaseCommandV1';

export const useRenewMyLeaseMutation = (
    uiStore: UIStore,
    sessionActiveResidency: SmarthubResidencyV1,
    mutationOptions?: MutationOptions<RenewMyLeaseResultV1, any, RenewMyLeaseSubmitData>,
) => {
    return useMutation<RenewMyLeaseResultV1, any, RenewMyLeaseSubmitData>(async (data: RenewMyLeaseSubmitData) => {
        const cmd = createRenewMyLeaseCommandV1();
        cmd.propertyCd = sessionActiveResidency.propertyCd;
        cmd.residencyId = sessionActiveResidency.residencyId;
        cmd.leaseTerm = data.leaseTerm;
        cmd.contactInfoList = data.contactInfos;
        return dispatchCommandAsync(uiStore, cmd, false);
    }, mutationOptions);
};
