import * as React from 'react';
import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { DeephansizedRegularText, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { observer } from 'mobx-react-lite';
import { Divider, useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { SafeAreaView } from 'react-native-safe-area-context';
import { LeaseAlertsBanner } from 'components/cards/LeaseAlertsBanner';
import { getMesageBalanceCardWidth } from 'components/cards/MessageBalancePay';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import LeaseAdjustmentBanner from 'components/cards/LeaseAdjustmentBanner';
import { arrayHasNonNullValues } from 'utils/ArrayUtils';
import { StackNavigationProp } from '@react-navigation/stack';
import { globalStyles } from 'theme/style/GlobalStyles';
import {
    notificationKey,
    SmarthubNotificationV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';
import { formatDateMonthLabelFullNoYear } from 'utils/DateUtils';
import { ListItemProps } from 'components/lists/SmarthubListItem';
import SmarthubGrid from 'components/lists/SmarthubGrid';
import { SmarthubTypography } from 'theme/typography/SmarthubTypography';
import { SmarthubRequestType } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubRequestType';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { navigationRoutes } from 'utils/NavigationUtils';
import { getRequestTitle } from 'screens/home/requests/requestUtils';
import { useSmarthubNotificationsPaginated } from 'screens/home/requests/NotificationsHooks';
import { useQueryClient } from 'react-query';

const buildNotificationListItem = (
    listItem: SmarthubNotificationV1,
    navigation: StackNavigationProp<any>,
    notifications: SmarthubNotificationV1[],
): ListItemProps => {
    return {
        //config
        descNumberOfLines: 4,
        descExpandable: false,
        descStyle: SmarthubTypography.deephansizedRegular,
        //data
        testID: 'notification',
        accessibilityLabel: 'notification',
        showNotificationBubble: !listItem.seenYn,
        id: notificationKey(listItem),
        title: listItem.heading,
        subtitle: formatDateMonthLabelFullNoYear(new Date(listItem.dateCreated)),
        desc: listItem.body,
        notifications: notifications,
        //action
        doAction: buildNotificationOnPress(listItem, navigation, true),
    };
};

export const buildNotificationOnPress = (
    notification: SmarthubNotificationV1,
    navigation: NavigationProp<ParamListBase>,
    isInNotifications: boolean,
    isOnHomeStack = false,
): (() => void) => {
    return () => {
        if (
            notification.noticeType === 'MaintenanceRequestUpdate' ||
            notification.noticeType === 'MaintenanceRequestCorrespondence'
        ) {
            const mainReqType: SmarthubRequestType = 'MaintenanceRequest';
            isOnHomeStack
                ? navigation.navigate(navigationRoutes.homeRoutes.requestDetail, {
                      requestId: notification.noticeRefId,
                      requestType: 'MaintenanceRequest',
                      title: getRequestTitle(mainReqType, notification.noticeRefId),
                      goBackRoute: navigationRoutes.notificationRoutes.notifications,
                  })
                : navigation.navigate(navigationRoutes.homeRoutes.home, {
                      screen: navigationRoutes.homeRoutes.requestDetail,
                      params: {
                          requestId: notification.noticeRefId,
                          requestType: 'MaintenanceRequest',
                          title: getRequestTitle(mainReqType, notification.noticeRefId),
                          goBackRoute: navigationRoutes.notificationRoutes.notifications,
                      },
                  });
        } else if (notification.noticeType === 'Statement') {
            isOnHomeStack
                ? navigation.navigate(navigationRoutes.homeRoutes.statementDetail, {
                      referenceNumber: notification.noticeRefId.toString(),
                      title: notification.heading.replace('Available', ''),
                      transactionType: 'Statement',
                      goBackRoute: navigationRoutes.notificationRoutes.notifications,
                  })
                : navigation.navigate(navigationRoutes.homeRoutes.home, {
                      screen: navigationRoutes.homeRoutes.statementDetail,
                      params: {
                          referenceNumber: notification.noticeRefId.toString(),
                          title: notification.heading.replace('Available', ''),
                          transactionType: 'Statement',
                          goBackRoute: navigationRoutes.notificationRoutes.notifications,
                      },
                  });
        } else if (notification.noticeType === 'CommunityNotice') {
            navigation.navigate(navigationRoutes.noticesRoutes.notices);
        } else if (notification.noticeType === 'ElectronicContract') {
            navigation.navigate(navigationRoutes.homeRoutes.home, {
                screen: navigationRoutes.homeRoutes.contractAndDocuments,
                params: {
                    referenceNumber: notification.noticeRefId,
                    goBackRoute: navigationRoutes.notificationRoutes.notifications,
                },
            });
        } else if (notification.noticeType === 'LeaseNotification') {
            isInNotifications
                ? navigation.navigate(navigationRoutes.notificationRoutes.leaseNotificationDetail, {
                      title: notification.body,
                      notificationId: notification.notificationId,
                      goBackRoute: navigationRoutes.notificationRoutes.notifications,
                  })
                : navigation.navigate(navigationRoutes.notificationRoutes.notifications, {
                      screen: navigationRoutes.notificationRoutes.leaseNotificationDetail,
                      params: {
                          title: notification.body,
                          notificationId: notification.notificationId,
                          goBackRoute: navigationRoutes.notificationRoutes.notifications,
                      },
                  });
        } else if (notification.noticeType === 'SmartPackage') {
            navigation.navigate(navigationRoutes.homeRoutes.home, {
                screen: navigationRoutes.homeRoutes.smartPackage,
                params: {
                    referenceNumber: notification.noticeRefId,
                    goBackRoute: navigationRoutes.notificationRoutes.notifications,
                },
            });
        }
        //TODO add service desk type!
    };
};

type NotificationsProps = {
    navigation: StackNavigationProp<any>;
};

const Notifications: React.FC<NotificationsProps> = observer(({ navigation }: NotificationsProps) => {
    const theme = useTheme();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, smarthubUser, confirmActiveResidency, sessionActiveResidency } =
        userSessionStore;
    const { useDrawerNavigation, useFullExperience } = useResponsiveContext();

    const useNotificationsQuery = useSmarthubNotificationsPaginated();
    const notifications =
        useNotificationsQuery.notificationsQuery.data?.pages[0].data || ([] as SmarthubNotificationV1[]);
    const notificationsQueryKey = useNotificationsQuery.notificationsQueryKey;
    const queryClient = useQueryClient();

    useEffect(() => {
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        confirmActiveResidency(navigation);
    }, [smarthubUser.authorizedResidencies]);
    useEffect(() => {
        useNotificationsQuery.notificationsQuery.isLoading
            ? uiStore.showActivityLoader()
            : uiStore.hideActivityLoader();
    }, [useNotificationsQuery.notificationsQuery.isLoading]);

    if (
        useNotificationsQuery.notificationsQuery.isLoading ||
        useNotificationsQuery.notificationsQuery.isIdle ||
        useNotificationsQuery.notificationsQuery.isError ||
        !useNotificationsQuery.notificationsQuery.data
    )
        return null;

    if (!hasSessionActiveResidency) {
        return (
            <SafeAreaView style={[styles.container, { backgroundColor: theme.colors.background }]}>
                <RegularText>No lease selected</RegularText>
            </SafeAreaView>
        );
    }

    return (
        <SmarthubGrid
            data={notifications.map((listItem): ListItemProps => {
                return buildNotificationListItem(listItem, navigation, notifications);
            })}
            keyExtractor={item => item.id}
            listEmptyComponent={
                uiStore.activityLoaderVisible ? null : (
                    <View style={[styles.listEmptyContainer, { backgroundColor: theme.colors.background }]}>
                        <View style={styles.centeredColumn}>
                            <DeephansizedRegularText>{"You don't have any notifications"}</DeephansizedRegularText>
                            <DeephansizedRegularText style={globalStyles.doubleSpaceAbove}>
                                {'Check back soon!'}
                            </DeephansizedRegularText>
                        </View>
                    </View>
                )
            }
            listHeaderComponent={() => {
                if (!useFullExperience) {
                    return (
                        <>
                            <Divider />
                            <View style={styles.headerContainer}>
                                {null != sessionActiveResidency.leaseAdjustment &&
                                    null != sessionActiveResidency.leaseAdjustmentMessage && (
                                        <View
                                            style={[
                                                styles.card,
                                                {
                                                    width: getMesageBalanceCardWidth(
                                                        useFullExperience,
                                                        useDrawerNavigation,
                                                    ),
                                                },
                                            ]}>
                                            <LeaseAdjustmentBanner />
                                        </View>
                                    )}
                            </View>
                            {arrayHasNonNullValues(sessionActiveResidency.leaseAlerts) && (
                                <View
                                    style={[
                                        styles.card,
                                        {
                                            width: getMesageBalanceCardWidth(useFullExperience, useDrawerNavigation),
                                        },
                                    ]}>
                                    <LeaseAlertsBanner />
                                </View>
                            )}
                        </>
                    );
                } else return <Divider />;
            }}
            listFooterComponent={Divider}
            onRefresh={() => {
                logInfo(INFO_LOGGER, 'Refreshing notifications from server');
                uiStore.showActivityLoader();
                queryClient.invalidateQueries(notificationsQueryKey);
            }}
            onEndReachedThreshold={0.5}
            onEndReached={() => {
                logInfo(INFO_LOGGER, 'On end reached - Fetching notifications from server');
                return useNotificationsQuery.notificationsQuery.hasNextPage
                    ? useNotificationsQuery.notificationsQuery.fetchNextPage()
                    : null;
            }}
        />
    );
});

export default Notifications;

const styles = StyleSheet.create({
    centeredColumn: { flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
    listEmptyContainer: {
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        flexGrow: 1,
        alignSelf: 'center',
        justifyContent: 'center',
    },
    headerContainer: {
        marginHorizontal: SmarthubTheme.layout.SURROUNDMARGIN / 2,
    },
    card: {
        flex: 1,
        minHeight: 100,
        maxHeight: 100,
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        alignSelf: 'center',
    },
});
