import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { ViewResidentEmailResultV1 } from './ViewResidentEmailResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.ViewResidentEmailCommandV1
 */
export interface ViewResidentEmailCommandV1 extends AbstractResidentAuthenticatedCommand<ViewResidentEmailResultV1> {
    emailLogId: number;
}

export function createViewResidentEmailCommandV1(): ViewResidentEmailCommandV1 {
    return {
        emailLogId: 0,
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.ViewResidentEmailCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
