import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { Heading3, RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { useTheme } from 'react-native-paper';
import { _describePeriod, compareDateIgnoreTime, parsePeriod } from 'utils/DateUtils';
import TransactionDetailsList from 'screens/home/transactions/TransactionDetailsList';
import Button from 'components/primitives/Button';
import Hyperlink from 'components/primitives/Hyperlink';
import StatementPDFLink from 'screens/home/transactions/StatementPDFLink';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { v4 as uuid } from 'uuid';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Container from 'components/primitives/Container';
import { StackNavigationProp } from '@react-navigation/stack';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { navigationRoutes } from 'utils/NavigationUtils';
import PhoneLink from 'components/primitives/PhoneLink';

type MakeAPaymentProps = {
    navigation: StackNavigationProp<any>;
};

const MakeAPayment: React.FC<MakeAPaymentProps> = observer(({ navigation }: MakeAPaymentProps) => {
    const { transactionsStore, userSessionStore, uiStore } = useStore();
    const { latestStatement, statementsSortedDescending } = transactionsStore;
    const useDrawerNavigation = useResponsiveContext().useDrawerNavigation;
    const theme = useTheme();
    const { sessionActiveResidency, hasSessionActiveResidency, propertySpecificDt } = userSessionStore;

    const [isLoading, setLoading] = useState(true);

    //grab the latest statement...
    const proceed = (replace?: boolean) => {
        logInfo(INFO_LOGGER, 'proceeding to payment screen...');
        if (userSessionStore.isFeatureEnabled('ZegoGatewayPayment')) {
            //use new screen
            if (useDrawerNavigation) {
                if (replace) {
                    navigation.replace(navigationRoutes.homeRoutes.zegoPaymentScreen);
                } else {
                    navigation.navigate(navigationRoutes.homeRoutes.zegoPaymentScreen);
                }
            } else {
                if (replace) {
                    navigation.replace(navigationRoutes.loggedInRoutes.zegoPaymentScreenModal);
                } else {
                    navigation.navigate(navigationRoutes.loggedInRoutes.zegoPaymentScreenModal);
                }
            }
        } else {
            //use iframe screen
            if (useDrawerNavigation) {
                if (replace) {
                    navigation.replace(navigationRoutes.homeRoutes.paymentScreen, {
                        navigateTo: 'onetime',
                        title: 'Make a Payment',
                    });
                } else {
                    navigation.navigate(navigationRoutes.homeRoutes.paymentScreen, {
                        navigateTo: 'onetime',
                        title: 'Make a Payment',
                    });
                }
            } else {
                if (replace) {
                    navigation.replace(navigationRoutes.loggedInRoutes.paymentScreenModal, {
                        navigateTo: 'onetime',
                        title: 'Make a Payment',
                    });
                } else {
                    navigation.navigate(navigationRoutes.loggedInRoutes.paymentScreenModal, {
                        navigateTo: 'onetime',
                        title: 'Make a Payment',
                    });
                }
            }
        }
    };

    useEffect(() => {
        uiStore.showActivityLoader();
        setLoading(true);
        if (hasSessionActiveResidency) {
            userSessionStore.reloadCurrentResidencyAsync(true).then(() => {
                transactionsStore
                    .obtainTransactionsFromServer(true, true)
                    .then(() => {
                        uiStore.hideActivityLoader();
                        const skipForPrevious =
                            sessionActiveResidency.statusCd === 'Previous' &&
                            !sessionActiveResidency.disableAllPaymentOptions &&
                            transactionsStore.latestStatement &&
                            transactionsStore.latestStatement.type !== 'FinalStatement';

                        const skipForCurrent =
                            sessionActiveResidency.statusCd !== 'Previous' &&
                            !sessionActiveResidency.disableAllPaymentOptions &&
                            (!transactionsStore.latestStatement ||
                                compareDateIgnoreTime(
                                    parsePeriod(transactionsStore.latestStatement.period)!,
                                    propertySpecificDt,
                                ) <= 0);

                        if (!sessionActiveResidency.adminYn && (skipForPrevious || skipForCurrent)) {
                            proceed(true);
                        } else {
                            setLoading(false);
                        }
                    })
                    .finally(() => {
                        uiStore.hideActivityLoader();
                    });
            });
        }
    }, [sessionActiveResidency.key]);

    const allowPayments = !sessionActiveResidency.disableAllPaymentOptions;

    if (isLoading) return null;

    return (
        <SafeAreaView style={[globalStyles.justflex1, { backgroundColor: theme.colors.background }]}>
            <KeyboardAwareScrollView>
                <View style={styles.innercontainer}>
                    {allowPayments && (
                        <>
                            <Container borderStyle={'grey'} dropShadow={true} width={'100%'}>
                                <View style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                                    {!!latestStatement &&
                                        !(
                                            sessionActiveResidency.statusCd === 'Previous' &&
                                            latestStatement &&
                                            latestStatement.type !== 'FinalStatement'
                                        ) && (
                                            <>
                                                <Heading3 style={globalStyles.doubleSpaceBelow}>
                                                    {(latestStatement.type === 'FinalStatement' && 'Final Statement') ||
                                                        _describePeriod(latestStatement.period)}
                                                </Heading3>
                                                <TransactionDetailsList currentTransaction={latestStatement} />
                                                {/*TODO - add in transactions between latest statement and now!*/}
                                            </>
                                        )}
                                    <View key={uuid()} style={[styles.datarow, globalStyles.sectionSpaceBelow]}>
                                        <RegularBoldText>Current Balance</RegularBoldText>
                                        <RegularBoldText>
                                            {formatCentsAsDollars(sessionActiveResidency.currentBalance)}
                                        </RegularBoldText>
                                    </View>
                                    {!!latestStatement && <StatementPDFLink currentTransaction={latestStatement} />}
                                </View>
                            </Container>

                            {statementsSortedDescending.length > 0 && (
                                <View style={[globalStyles.sectionSpaceAbove]}>
                                    <Hyperlink
                                        {...testProps('previous-statements')}
                                        onClick={() => {
                                            if (useDrawerNavigation) {
                                                navigation.navigate(navigationRoutes.homeRoutes.previousStatements);
                                            } else {
                                                navigation.navigate(
                                                    navigationRoutes.loggedInRoutes.previousStatementModal,
                                                );
                                            }
                                        }}
                                    >
                                        Previous Statements
                                    </Hyperlink>
                                </View>
                            )}

                            <View style={[styles.button, globalStyles.sectionSpaceAround]}>
                                <Button
                                    buttonType={'primary'}
                                    {...testProps('make-a-payment-button')}
                                    onClick={proceed}
                                    inactive={sessionActiveResidency.adminYn}
                                >
                                    Begin Payment
                                </Button>
                            </View>
                        </>
                    )}

                    {!allowPayments && (
                        <View style={globalStyles.sectionSpaceAbove}>
                            <Container borderStyle={'orange'}>
                                <View style={[styles.nopayallowed]}>
                                    <MaterialCommunityIcons
                                        name='alert-circle'
                                        size={SmarthubTheme.layout.LARGEICONSIZE}
                                        color={SmarthubTheme.colors.orange}
                                    />
                                    <RegularText style={globalStyles.doubleSpaceAbove}>
                                        You are not allowed to make online payments. Please contact the community office
                                        for more information
                                    </RegularText>

                                    <View style={[styles.contactDetailsWrapper, globalStyles.doubleSpaceAbove]}>
                                        <View style={[styles.contactDetailsItem, styles.contactDetailsItemIcon]}>
                                            <MaterialCommunityIcons
                                                name='phone'
                                                size={SmarthubTheme.layout.ICONWIDTH}
                                                color={SmarthubTheme.colors.navy}
                                            />
                                        </View>
                                        <View style={[styles.contactDetailsItem]}>
                                            <PhoneLink
                                                {...testProps('office-telephone-link')}
                                                phoneNumber={sessionActiveResidency.propertyPhoneNumber}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </Container>
                        </View>
                    )}
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
});

export default MakeAPayment;

const styles = StyleSheet.create({
    innercontainer: {
        padding: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 6,
        flexDirection: 'column',
        alignItems: 'center',
    },
    surface: {
        padding: SmarthubTheme.layout.INNERPAD,
        width: '100%',
        elevation: SmarthubTheme.layout.SURFACEELEVATION,
    },
    button: {
        alignSelf: 'center',
        width: '92%',
    },
    closeButton: {
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    datarow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    nopayallowed: {
        padding: SmarthubTheme.layout.INNERPAD,
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    contactDetailsWrapper: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contactDetailsItem: {
        //flex: 1,
        //marginTop: 0,
    },
    contactDetailsItemIcon: {
        maxWidth: 30,
        //marginTop: -3,
    },
});
