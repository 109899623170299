import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { observer } from 'mobx-react-lite';
import { globalStyles } from 'theme/style/GlobalStyles';

type SectionProps = {
    style?: StyleProp<any>;
    children: any;
};

const Section: React.FC<SectionProps> = observer((props: SectionProps) => {
    return (
        <View style={[globalStyles.sectionSpaceAbove, props.style]} testID={'generic-section'}>
            {props.children}
        </View>
    );
});

export default Section;
