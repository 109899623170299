import { DrawerContentComponentProps, DrawerItem } from '@react-navigation/drawer';
import { observer } from 'mobx-react-lite';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { Heading3, RegularText } from 'components/primitives/StyledText';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import QuickActionPlusButton from '../QuickActionMenu';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { STORAGE_KEY_PLUS_BUTTON_HIGHLIGHT_DISMISSED } from 'utils/storage-keys';
import Tooltip from 'components/primitives/Tooltip';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useLogResidencyAuditEventMutation } from 'queries/hooks/residency/useLogResidencyAuditEventMutation';
import { SmarthubLeaseAlert } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubLeaseAlert';

const QuickActionDrawerItem = observer(
    ({
        navigation,
        title,
        screen,
        stack,
        onPress,
        testID,
        accessibilityLabel,
        extraParams,
        hidden,
    }: DrawerContentComponentProps & {
        title: string;
        screen?: string;
        stack?: string;
        onPress?: () => void;
        testID?: string;
        accessibilityLabel?: string;
        extraParams?: any;
        hidden?: boolean;
    }) => {
        const theme = useTheme();
        if (!hidden) {
            return (
                <View testID={testID} accessibilityLabel={accessibilityLabel}>
                    <DrawerItem
                        label={() => (
                            <RegularText style={styles.lightBlueText} testID={testID + '-alt'}>
                                {title}
                            </RegularText>
                        )}
                        activeTintColor={theme.colors.lightblue}
                        onPress={
                            (onPress && onPress) ||
                            (() => screen && stack && navigation.navigate(stack, { screen: screen, ...extraParams }))
                        }
                    />
                </View>
            );
        } else {
            return null;
        }
    },
);

const QuickActionDrawerItems: (props: DrawerContentComponentProps) => null | JSX.Element = observer(
    (props: DrawerContentComponentProps) => {
        const responsiveContext = useResponsiveContext();
        const fullExperience = responsiveContext.useFullExperience;
        const { userSessionStore, uiStore } = useStore();
        const { sessionActiveResidency } = userSessionStore;
        const logResidencyAuditEventMutation = useLogResidencyAuditEventMutation(uiStore, sessionActiveResidency);

        const renderQuickActionTooltipText = () => {
            return (
                <RegularText style={globalStyles.centeredText}>
                    <RegularText style={[globalStyles.centeredText, globalStyles.orangeText, globalStyles.boldText]}>
                        The + button{' '}
                    </RegularText>
                    <RegularText>
                        is the quickest way to do many things in SMART
                        <RegularText style={globalStyles.boldText}>HUB</RegularText>, like make a payment or create a
                        maintenance request. Check it out!
                    </RegularText>
                </RegularText>
            );
        };

        if (fullExperience) {
            return (
                <View style={styles.container}>
                    <View
                        style={[
                            globalStyles.sectionSpaceAbove,
                            globalStyles.doubleSpaceBelow,
                            { paddingLeft: SmarthubTheme.layout.INNERPAD },
                        ]}>
                        {/*<CustomDrawerItem {...props} label={'Quick Actions'} icon={undefined} route={''} />*/}
                        <Heading3 style={[globalStyles.sectionSpaceAbove, globalStyles.singleSpaceBelow]}>
                            Quick Actions
                        </Heading3>
                    </View>
                    {(sessionActiveResidency.allowNTV || sessionActiveResidency.allowRenewal) &&
                        sessionActiveResidency.leaseAlerts != null &&
                        sessionActiveResidency.leaseAlerts.find(
                            (la: SmarthubLeaseAlert) =>
                                la.leaseAdjustmentType === 'Expired' || la.leaseAdjustmentType === 'ExpiringSoon',
                        ) && (
                            <QuickActionDrawerItem
                                {...props}
                                title={'Manage My Lease'}
                                screen={navigationRoutes.homeRoutes.manageMyLease}
                                {...testProps('quick-notice-manage-my-lease')}
                                stack={navigationRoutes.homeRoutes.home}
                                onPress={() => {
                                    logResidencyAuditEventMutation.mutate({
                                        auditLogId: null,
                                        eventType: 'SmarthubManageMyLease',
                                        currentValue: '',
                                        newValue: '',
                                    });

                                    props.navigation.navigate(navigationRoutes.homeRoutes.home, {
                                        screen: navigationRoutes.homeRoutes.manageMyLease,
                                    });
                                }}
                            />
                        )}
                    <QuickActionDrawerItem
                        {...props}
                        title={'Make a Payment'}
                        screen={navigationRoutes.homeRoutes.makeAPayment}
                        stack={navigationRoutes.homeRoutes.home}
                        {...testProps('quick-make-a-payment')}
                    />
                    {sessionActiveResidency.statusCd !== 'Cancelled' && sessionActiveResidency.statusCd !== 'Previous' && (
                        <>
                            <QuickActionDrawerItem
                                {...props}
                                title={'Create a Maintenance Request'}
                                screen={navigationRoutes.homeRoutes.createARequest}
                                {...testProps('quick-maintenance-request')}
                                stack={navigationRoutes.homeRoutes.home}
                            />
                            <QuickActionDrawerItem
                                {...props}
                                title={'Send Us a Message'}
                                screen={navigationRoutes.homeRoutes.createATicket}
                                {...testProps('quick-send-a-message')}
                                stack={navigationRoutes.homeRoutes.home}
                            />
                            <QuickActionDrawerItem
                                {...props}
                                title={'Add an Away Time'}
                                extraParams={{
                                    params: { awayTimeId: 0 },
                                }}
                                screen={navigationRoutes.moreRoutes.addAwayTime}
                                {...testProps('quick-away-time')}
                                stack={navigationRoutes.moreRoutes.more}
                                hidden={!useStore().userSessionStore.isFeatureEnabled('ParcelPending')}
                            />
                        </>
                    )}
                </View>
            );
        } else {
            return (
                <View style={globalStyles.doubleSpaceAbove}>
                    <Tooltip
                        plinthText={renderQuickActionTooltipText}
                        plinthAlignment={'right'}
                        plinthWidth={400}
                        spotlightShape={'circle'}
                        storageKey={STORAGE_KEY_PLUS_BUTTON_HIGHLIGHT_DISMISSED}>
                        <QuickActionPlusButton />
                    </Tooltip>
                </View>
            );
        }
    },
);

export default QuickActionDrawerItems;

const styles = StyleSheet.create({
    container: {
        paddingLeft: SmarthubTheme.layout.INNERPAD,
    },
    lightBlueText: {
        color: SmarthubTheme.colors.lightblue,
        lineHeight: 2,
    },
});
