import { Animated, RefreshControl, SectionList, StyleSheet } from 'react-native';
import { Divider, useTheme } from 'react-native-paper';
import * as React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { Collapsible, useCollapsibleHeader } from 'react-navigation-collapsible';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { getMainStackScreenOptions } from 'navigation/stacks/MainStackScreenOptions';
import { ListItemProps, renderListItem } from 'components/lists/SmarthubListItem';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useIsFocused } from '@react-navigation/native';

type SmarthubGridProps = {
    data: ListItemProps[];
    collapsible?: Collapsible;
    listEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
    listHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
    listFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
    keyExtractor?: (item: ListItemProps, index: number) => string;
    onRefresh?: () => void;
    onEndReached?: (() => void) | null | undefined;
    onEndReachedThreshold?:
        | number
        | Animated.Value
        | Animated.AnimatedInterpolation<string | number>
        | null
        | undefined;
};

const SmarthubGrid: React.FC<SmarthubGridProps> = observer(
    ({
        data,
        collapsible,
        listEmptyComponent,
        listHeaderComponent,
        listFooterComponent,
        keyExtractor,
        onRefresh,
        onEndReached,
        onEndReachedThreshold,
    }: SmarthubGridProps) => {
        const { useDrawerNavigation } = useResponsiveContext();
        const isFocused = useIsFocused();
        const theme = useTheme();
        const [refreshing, setRefreshing] = useState(false);
        if (!collapsible) {
            collapsible = useCollapsibleHeader({
                navigationOptions: getMainStackScreenOptions(theme, useDrawerNavigation, true),
                config: !useDrawerNavigation ? { collapsedColor: SmarthubTheme.colors.primary } : {},
            });
        }
        const { onScroll, containerPaddingTop, scrollIndicatorInsetTop } = collapsible;
        return (
            <Animated.FlatList
                style={[{ backgroundColor: theme.colors.background }]}
                data={data}
                onScroll={onScroll}
                ListEmptyComponent={listEmptyComponent}
                ItemSeparatorComponent={Divider}
                ListHeaderComponent={listHeaderComponent}
                ListFooterComponent={listFooterComponent}
                contentContainerStyle={[styles.container, { paddingTop: containerPaddingTop }]}
                renderItem={renderListItem}
                keyExtractor={keyExtractor}
                onRefresh={() => {
                    if (onRefresh) {
                        setRefreshing(true);
                        onRefresh();
                        setRefreshing(false);
                    }
                }}
                refreshing={refreshing}
                onEndReached={() => {
                    if (onEndReached && !refreshing && isFocused) {
                        setRefreshing(true);
                        onEndReached();
                        setRefreshing(false);
                    }
                }}
                onEndReachedThreshold={onEndReachedThreshold}
            />
        );
    },
);

export default SmarthubGrid;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
        flexGrow: 1,
    },
});
