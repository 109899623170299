import React from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { Heading3, RegularText } from 'components/primitives/StyledText';
import Button from 'components/primitives/Button';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import { logError } from 'utils/logging/Logger';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Section from 'components/primitives/Section';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useStore } from 'contexts/StoreContextProvider';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import PhoneLink from 'components/primitives/PhoneLink';
import EmailLink from 'components/primitives/EmailLink';

interface ErrorProps {
    error: Error;
    resetError: () => void;
}

export const errorHandler = (error: Error, stackTrace: string) => {
    logError(ERROR_LOGGER, 'Smarthub crashed! ' + error.message, stackTrace);
};

const ErrorFallBackComponent: React.FC<ErrorProps> = observer((props: ErrorProps) => {
    const { userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;

    return (
        <SafeAreaView style={[globalStyles.container]}>
            <View style={[globalStyles.container, globalStyles.flexColumnCenter]}>
                <Section>
                    <MaterialCommunityIcons
                        name='alert-circle'
                        size={SmarthubTheme.layout.EXTRALARGEICONSIZE}
                        color={SmarthubTheme.colors.orange}
                        style={globalStyles.doubleSpaceBelow}
                    />
                </Section>
                <Section>
                    <Heading3>Woops! Something went wrong.</Heading3>
                </Section>
                <Section>
                    <RegularText>Please contact the community office if this continues to happen.</RegularText>
                </Section>

                <Section style={globalStyles.flexColumnCenter}>
                    {!!sessionActiveResidency.propertyEmail && (
                        <View style={[globalStyles.flexRow, globalStyles.doubleSpaceAbove]}>
                            <View style={[styles.contactDetailsItemIcon]}>
                                <MaterialCommunityIcons
                                    name='email-outline'
                                    size={SmarthubTheme.layout.ICONWIDTH}
                                    color={SmarthubTheme.colors.navy}
                                />
                            </View>
                            <View>
                                <EmailLink
                                    {...testProps('office-email-link')}
                                    email={sessionActiveResidency.propertyEmail}
                                />
                            </View>
                        </View>
                    )}

                    {!!sessionActiveResidency.propertyPhoneNumber && (
                        <View style={[globalStyles.flexRow, globalStyles.doubleSpaceAbove]}>
                            <View style={[styles.contactDetailsItemIcon]}>
                                <MaterialCommunityIcons
                                    name='phone'
                                    size={SmarthubTheme.layout.ICONWIDTH}
                                    color={SmarthubTheme.colors.navy}
                                />
                            </View>
                            <View>
                                <PhoneLink
                                    {...testProps('office-telephone-link')}
                                    phoneNumber={sessionActiveResidency.propertyPhoneNumber}
                                />
                            </View>
                        </View>
                    )}
                </Section>

                <View style={globalStyles.fullScreenButton}>
                    <Section>
                        <Button onClick={props.resetError}>Continue</Button>
                    </Section>
                </View>
            </View>
        </SafeAreaView>
    );
});

export default ErrorFallBackComponent;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    contactDetailsWrapper: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contactDetailsItemIcon: {
        maxWidth: 30,
        //marginTop: -3,
    },
});
