import * as SentryHelper from 'utils/logging/SentryHelper';

export const LOG_LEVEL_NONE = -1;
export const LOG_LEVEL_ERROR = 0;
export const LOG_LEVEL_INFO = 1;

let _globalLogLevel = -1;

export class Logger {
    _level = -1;

    logError(msg: any, msg2?: any) {
        if (this._level === 0 || (this._level === -1 && _globalLogLevel === 0)) {
            if (msg2) {
                SentryHelper.captureException(Error(msg + msg2));
                console.log(msg, msg2);
            } else {
                SentryHelper.captureException(Error(msg));
                console.log(msg);
            }
        }
    }

    log(msg: any, msg2?: any) {
        if (this._level > 0 || (this._level === -1 && _globalLogLevel > 0)) {
            if (msg2) console.log(msg, msg2);
            else console.log(msg);
        }
    }

    setLogLevel(level: number) {
        this._level = level;
    }

    shouldLog(): boolean {
        return this._level > 0 || (this._level === -1 && _globalLogLevel > 0);
    }
}

const _loggerMap: Map<number, Logger> = new Map();

export const setGlobalLogLevel = (level: number) => {
    _globalLogLevel = level;
};

export const setLogLevel = (loggerId: number, logLevel: number) => {
    const l: Logger | undefined = _loggerMap.get(loggerId);

    if (l != null) {
        l.setLogLevel(logLevel);
    } else {
        throw Error('Invalid logger ' + loggerId);
    }
};

export const registerLogger = (loggerId: number) => {
    _loggerMap.set(loggerId, new Logger());
};

export const logInfo = (loggerId: number, msg: any, msg2?: any) => {
    const l: Logger | undefined = _loggerMap.get(loggerId);

    if (l != null) {
        l.log(msg, msg2);
    } else {
        throw Error('Invalid logger ' + loggerId);
    }
};

export const logError = (loggerId: number, msg: any, msg2?: any) => {
    const l: Logger | undefined = _loggerMap.get(loggerId);
    if (l != null) {
        l.logError(msg, msg2);
    } else {
        throw Error('Invalid logger ' + loggerId);
    }
};

export const shouldLog = (loggerId: number): boolean => {
    const l: Logger | undefined = _loggerMap.get(loggerId);

    if (l != null) {
        return l.shouldLog();
    } else {
        throw Error('Invalid logger ' + loggerId);
    }
};
