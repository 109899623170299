import {
    createGetSmartmoveApplicationHeaderCommandV1,
    GetSmartmoveApplicationHeaderCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveApplicationHeaderCommandV1';
import { UIStore } from 'stores/domain/UIStore';
import { SmartmoveStep } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveStep';
import { WorkflowStatus } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/contract/WorkflowStatus';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { navigationRoutes } from 'utils/NavigationUtils';
import { GetSmartmoveApplicationHeaderResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveApplicationHeaderResultV1';
import { generateQueryKeyFromCmd, useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UseQueryOptions } from 'react-query';

export const useApplicationHeader = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    hasSessionActiveResidency: boolean,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetSmartmoveApplicationHeaderResultV1>,
) => {
    //make the command
    const theCommand = createGetSmartmoveApplicationHeaderCommandV1();
    theCommand.propertyCd = propertyCd;
    theCommand.residencyId = residencyId;
    theCommand.residentId = residentId;

    //make the  query key
    const queryKey = generateQueryKeyFromCmd(theCommand);

    //call the command
    const query = useCommandQuery<GetSmartmoveApplicationHeaderCommandV1, GetSmartmoveApplicationHeaderResultV1>(
        theCommand,
        uiStore,
        queryKey,
        queryOptions || {
            enabled: hasSessionActiveResidency,
        },
    );

    return {
        query,
        queryKey,
        routeUser,
    };
};

export const routeUser = (
    smartmoveStep: SmartmoveStep,
    workflowStatus: WorkflowStatus,
    navigation: NavigationProp<ParamListBase>,
) => {
    if (smartmoveStep) {
        if (smartmoveStep === 'ReservationFeePayment')
            navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveReserve);
        else if (smartmoveStep === 'SecurityChoice')
            navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveDeposit);
        else if (smartmoveStep === 'VehiclesAndPets')
            navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveVehiclesAndPets);
        else if (smartmoveStep === 'Contract') {
            if (workflowStatus) {
                if (workflowStatus === 'AppAwaitingSignatures')
                    navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveContractReady);
                else if (workflowStatus === 'AppDone')
                    navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveContractSigningComplete);
                else navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveContractPending);
            }
        } else if (smartmoveStep === 'MoveInCountdown')
            navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveMoveInCountdown);
        else if (smartmoveStep === 'UtilitiesAndInsurance')
            navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveContractSigningComplete);
        else if (smartmoveStep === 'MoveInPayment')
            navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePayBeforeMoveIn);
    }
};

export const routeToCorrectStep = (
    data: GetSmartmoveApplicationHeaderResultV1,
    navigation: NavigationProp<ParamListBase>,
    currentStep: SmartmoveStep,
    currentWorkFlowStatus?: WorkflowStatus,
) => {
    const { smartmoveStep, workflowStatus } = data.smartmoveApplicationHeaderV1;
    if (smartmoveStep !== currentStep || (currentWorkFlowStatus && workflowStatus !== currentWorkFlowStatus)) {
        routeUser(smartmoveStep, workflowStatus, navigation);
    }
};
