import Constants from 'expo-constants';
import { getItem, setItem } from 'utils/storage';
import {
    STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT,
    STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT_HISTORY,
    STORAGE_KEY_TEASER_HISTORY,
} from 'utils/storage-keys';
import { daysBetween } from 'utils/DateUtils';
import { Platform } from 'react-native';

interface TeaserHistory {
    numberOfViews: number;
    dateLastViewed: Date;
    dismissSet: boolean;
    dismissBannerSet: boolean;
}

interface FeatureHighlightHistory {
    dateLastViewed: Date;
    numberOfViews: number;
    residentId: string;
}

export function getAppDownloadURL(): string {
    if (isIOS()) {
        return (Constants.expoConfig?.extra && Constants.expoConfig.extra['appleAppStoreUrl']) || undefined;
    } else {
        return (Constants.expoConfig?.extra && Constants.expoConfig.extra['googleAppStoreUrl']) || undefined;
    }
}

// Do we show the splash screen to the user?
export async function showSplashScreen(): Promise<boolean> {
    if (Platform.OS === 'web') {
        const teaserHistory = await getTeaserHistory();
        const today: Date = new Date();

        // If user hasn't already been shown splash screen, update history and show splash screen
        if (teaserHistory === null) {
            const newHistory: TeaserHistory = {
                numberOfViews: 1,
                dateLastViewed: today,
                dismissSet: false,
                dismissBannerSet: false,
            };
            await updateTeaserHistory(newHistory);

            return true;
            // If user has clicked 'Don't ask me again' checkbox, don't how splash screen
        } else if (teaserHistory.dismissSet) {
            return false;
            // If user has already seen it - but not in the last 45 days, update history and show again
        } else if (daysBetween(new Date(teaserHistory.dateLastViewed), today) >= 45) {
            const newHistory: TeaserHistory = {
                numberOfViews: teaserHistory.numberOfViews + 1,
                dateLastViewed: today,
                dismissSet: false,
                dismissBannerSet: false,
            };
            await updateTeaserHistory(newHistory);

            return true;
        }
    }

    return false;
}

// Do we show the app banner/top toast to the user?
export async function showAppBanner(): Promise<boolean> {
    if (Platform.OS === 'web' && (isIOS() || isAndroid())) {
        const teaserHistory = await getTeaserHistory();
        return teaserHistory?.dismissBannerSet !== true;
    }

    return false;
}

// Get teaser history from local storage
async function getTeaserHistory(): Promise<TeaserHistory | null> {
    const strTeaserHistory = await getItem(STORAGE_KEY_TEASER_HISTORY);
    let teaserHistory = null;

    if (strTeaserHistory) {
        teaserHistory = JSON.parse(strTeaserHistory);
    }

    return teaserHistory;
}

async function updateTeaserHistory(newHistory: TeaserHistory | null): Promise<boolean> {
    await setItem(STORAGE_KEY_TEASER_HISTORY, JSON.stringify(newHistory));

    return true;
}

// Display 'Don't ask me again' control on splash screen
// if user has been shown splash screen 3+ times
export async function showDismiss(): Promise<boolean> {
    const teaserHistory = await getTeaserHistory();

    if (teaserHistory) {
        return teaserHistory && teaserHistory.numberOfViews > 2;
    }

    return false;
}

// User clicks 'Don't ask me again' on splash screen
export async function setDismiss() {
    const teaserHistory = await getTeaserHistory();

    if (teaserHistory) {
        teaserHistory.dismissSet = true;
        await setItem(STORAGE_KEY_TEASER_HISTORY, JSON.stringify(teaserHistory));
    }
}

// User dismisses app banner
export async function setDismissBanner() {
    const teaserHistory = await getTeaserHistory();

    if (teaserHistory) {
        teaserHistory.dismissBannerSet = true;
        await setItem(STORAGE_KEY_TEASER_HISTORY, JSON.stringify(teaserHistory));
    }
}

// Get show opt in out feature highlight history from local storage
async function getShowOptInOutFeatureHighlightHistory(): Promise<FeatureHighlightHistory[]> {
    const strFeatureHistory = await getItem(STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT_HISTORY);
    let featureHistory = [];

    if (strFeatureHistory) {
        featureHistory = JSON.parse(strFeatureHistory);
    }

    return featureHistory;
}

// Ensure that the feature highlight only gets shown once per day per resident id
export async function hasShownOptInOutFeatureHighlightToday(residentId: string) {
    const optInOutSavedHistoriesArray = await getShowOptInOutFeatureHighlightHistory();

    return optInOutSavedHistoriesArray.some(
        (optInOutHistory: FeatureHighlightHistory) =>
            optInOutHistory.residentId === residentId &&
            daysBetween(new Date(optInOutHistory.dateLastViewed), new Date()) === 0,
    );
}

export async function hasBeenShownOptInOutFeatureHighlight(residentId: string) {
    const optInOutSavedHistoriesArray = await getShowOptInOutFeatureHighlightHistory();
    return optInOutSavedHistoriesArray.some(
        (optInOutHistory: FeatureHighlightHistory) => optInOutHistory.residentId === residentId,
    );
}

export async function setOptInOutFeatureHighlightToday(residentId: string) {
    const hasBeenShownHighlight = await hasBeenShownOptInOutFeatureHighlight(residentId);
    const optInOutSavedHistoriesArray = await getShowOptInOutFeatureHighlightHistory();

    if (!hasBeenShownHighlight) {
        const newFeatureHighlightHistory: FeatureHighlightHistory = {
            dateLastViewed: new Date(),
            numberOfViews: 1,
            residentId: residentId,
        };
        const featureHighlightHistories: FeatureHighlightHistory[] = [
            ...optInOutSavedHistoriesArray,
            newFeatureHighlightHistory,
        ];
        await setItem(STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT_HISTORY, JSON.stringify(featureHighlightHistories));
    } else {
        optInOutSavedHistoriesArray.map((history: FeatureHighlightHistory) => {
            if (history.residentId === residentId) {
                history.numberOfViews += 1;
                history.dateLastViewed = new Date();
            }
        });
        await setItem(
            STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT_HISTORY,
            JSON.stringify(optInOutSavedHistoriesArray),
        );
    }
}

export async function showOptInOutFeatureHighlight(residentId: string) {
    const savedResidentIds = await getItem(STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT);
    const savedResidentIdsArray = savedResidentIds?.split('|') || ([] as string[]);
    const hasFeatureHighlightBeenShownTodayForResident = await hasShownOptInOutFeatureHighlightToday(residentId);
    return !savedResidentIdsArray.some(id => id === residentId) && !hasFeatureHighlightBeenShownTodayForResident;
}

export async function setOptInOutFeatureHighlight(residentId: string) {
    const isNotStored = await showOptInOutFeatureHighlight(residentId);
    const optInOutSavedResidentIds = await getItem(STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT);
    if (isNotStored) {
        optInOutSavedResidentIds
            ? await setItem(STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT, optInOutSavedResidentIds + '|' + residentId)
            : await setItem(STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT, residentId);
    }
}

export function isIOS(): boolean {
    return !!navigator.userAgent.match(/(iPad|iPhone|iPod)/i);
}

export function isAndroid(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('android') > -1;
}
