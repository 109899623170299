import React, { useCallback, useEffect, useState } from 'react';
import * as Crypto from 'expo-crypto';
import * as AppleAuthentication from 'expo-apple-authentication';
import { logError } from 'utils/logging/Logger';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Button from 'components/primitives/Button';
import { Platform, StyleSheet, View } from 'react-native';
import { useAuthentication } from 'contexts/AuthContextProvider';
import { useStore } from 'contexts/StoreContextProvider';
import { globalStyles } from 'theme/style/GlobalStyles';
import { STORAGE_KEY_APPLE_SIGN_IN_CONSENT } from 'utils/storage-keys';
import { getItem, setItem } from 'utils/storage';
import { getAuth, signInWithCredential, OAuthProvider } from 'firebase/auth';

const AppleLoginButton: React.FC = () => {
    const [appleSignInYn, setAppleSignInYn] = useState(false);
    const auth = getAuth();
    useEffect(() => {
        const getAvailable = async () => {
            const isAvailable = await AppleAuthentication.isAvailableAsync();
            if (isAvailable) setAppleSignInYn(true);
        };
        if (Platform.OS === 'ios') getAvailable();
    }, []);

    const { loginOption, setLoginOption } = useAuthentication();
    const { uiStore } = useStore();

    const signInWithApple = useCallback(() => {
        const nonce = Math.random().toString(36).substring(2, 10);

        Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce).then(hashedNonce => {
            AppleAuthentication.signInAsync({
                requestedScopes: [
                    AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                    AppleAuthentication.AppleAuthenticationScope.EMAIL,
                ],
                nonce: hashedNonce,
            })
                .then(appleCredential => {
                    const { identityToken } = appleCredential;
                    const provider = new OAuthProvider('apple.com');
                    const credential = provider.credential({
                        idToken: identityToken!,
                        rawNonce: nonce,
                    });
                    setItem(STORAGE_KEY_APPLE_SIGN_IN_CONSENT, 'true');

                    return signInWithCredential(auth, credential);
                })
                .catch(e => {
                    if (e.code === 'ERR_CANCELED') {
                        // handle that the user canceled the sign-in flow
                        setLoginOption('none');
                    } else {
                        logError(ERROR_LOGGER, 'Problem Signing In  with Apple', e.message);
                        uiStore.showAlert({
                            title: 'Problem Signing In with Apple',
                            message: e.message,
                        });
                    }
                });
        });
    }, []);

    if (appleSignInYn) {
        return (
            <View
                style={[
                    loginOption === 'apple' ? globalStyles.loginOptionButtonSelected : globalStyles.loginOptionButton,
                    globalStyles.doubleSpaceAbove,
                ]}>
                <Button
                    style={styles.loginAppleButton}
                    onClick={async () => {
                        setLoginOption('apple');
                        const appleSignInConcent = await getItem(STORAGE_KEY_APPLE_SIGN_IN_CONSENT);

                        if (!appleSignInConcent) {
                            uiStore.showAlert({
                                title: 'Sign in with Apple',
                                message:
                                    "We use the email associated with your Apple ID to locate your lease(s), please select Share My Email when asked. If you don't want to share your email, please use other options to sign in.",
                                buttons: [
                                    {
                                        buttonType: 'primary',
                                        buttonText: 'Continue',
                                        onClick: () => {
                                            uiStore.hideAlert();
                                            signInWithApple();
                                        },
                                    },
                                    {
                                        buttonText: 'Cancel',
                                        buttonType: 'secondary',
                                        onClick: () => {
                                            uiStore.hideAlert();
                                        },
                                    },
                                ],
                            });
                        } else {
                            signInWithApple();
                        }
                    }}
                    icon={{
                        iconID: 'apple',
                        color: SmarthubTheme.colors.white,
                    }}>
                    Sign in with Apple
                </Button>
            </View>
        );
    } else {
        return null;
    }
};

export default AppleLoginButton;

const styles = StyleSheet.create({
    loginAppleButton: {
        backgroundColor: SmarthubTheme.colors.black,
        borderColor: SmarthubTheme.colors.black,
    },
});
