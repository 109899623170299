import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { UIStore } from 'stores/domain/UIStore';
import { UseQueryOptions } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import {
    createGetRenewalContractSigningInfoCommandV1,
    GetRenewalContractSigningInfoCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/contract/GetRenewalContractSigningInfoCommandV1';
import { GetRenewalContractSigningInfoResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/contract/GetRenewalContractSigningInfoResultV1';

export const useGetRenewalContractSigningInfoQuery = (
    propertyCd: string,
    residencyId: number,
    uiStore: UIStore,
    queryOptions?: UseQueryOptions<unknown, unknown, GetRenewalContractSigningInfoResultV1>,
) => {
    //build key
    const queryKey = [QueryKeys.LeaseRenewalContractSigning, propertyCd, residencyId];

    const cmd = createGetRenewalContractSigningInfoCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;

    //Setup query
    const queryResult = useCommandQuery<GetRenewalContractSigningInfoCommandV1, GetRenewalContractSigningInfoResultV1>(
        cmd,
        uiStore,
        queryKey,
        queryOptions,
    );
    return { queryKey, queryResult };
};
