import React from 'react';
import { Platform, View } from 'react-native';
import { FormLabelText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import { useStore } from 'contexts/StoreContextProvider';
import Button from 'components/primitives/Button';
import { observer } from 'mobx-react-lite';
import InputString from 'components/input/InputString';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Section from 'components/primitives/Section';
import useConfirmLeave from 'utils/useConfirmLeave';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { generateSendRequestMessageCMD, useSendRequestMessage } from 'screens/home/requests/RequestsHooks';
import { getRouteParams } from 'utils/routeUtils';
import { useRoute } from '@react-navigation/native';

type RequestMessageProps = {
    navigation: StackNavigationProp<any>;
};

const RequestMessageSchema = yup.object().shape({
    message: yup.string().max(1024, 'Max length is 1024 characters').required('Please enter your message'),
});

type FormData = {
    message: string;
};

const RequestMessage: React.FC<RequestMessageProps> = observer(({ navigation }: RequestMessageProps) => {
    const { uiStore, userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;

    const route = useRoute();
    const { requestId, requestType } = getRouteParams(route) || null;

    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            message: '',
        },
        validationSchema: RequestMessageSchema,
        onSubmit: (values, actions) => {
            if (!uiStore.activityLoaderVisible) {
                formik.resetForm({ values: values });
                formik.setSubmitting(true);
                onSubmitMessage(values);
            }
        },
    });

    const submitMessageMutation = useSendRequestMessage(navigation, formik, requestId, requestType);

    const onSubmitMessage = async (data: FormData) => {
        if (requestId) {
            uiStore.showActivityLoader();
            submitMessageMutation.mutate(
                generateSendRequestMessageCMD(sessionActiveResidency.propertyCd, requestId, requestType, data.message),
            );
        }
    };

    useConfirmLeave(navigation, formik);

    return (
        <KeyboardAwareScrollView
            keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
            enableResetScrollToCoords={false}
            contentContainerStyle={globalStyles.keyboardAwareScrollView}
        >
            <View style={globalStyles.container}>
                <FormLabelText style={[globalStyles.doubleSpaceBelow]}>
                    {`Send us a message about this ${requestType}:`}
                </FormLabelText>

                <InputString
                    autoFocus={Platform.OS === 'web'}
                    onChange={formik.handleChange('message')}
                    onBlur={formik.handleBlur('message')}
                    initialValue={formik.values.message}
                    multiline={true}
                    maxLength={1024}
                    numberOfLines={6}
                    error={!!formik.errors.message}
                    errorMessage={formik.errors.message}
                    {...testProps('message-input')}
                />

                <View style={globalStyles.bottomButton}>
                    <Section>
                        <Button
                            {...testProps('send-button')}
                            inactive={uiStore.activityLoaderVisible || sessionActiveResidency.adminYn}
                            onClick={formik.handleSubmit}
                        >
                            {'Send'}
                        </Button>
                    </Section>
                </View>
            </View>
        </KeyboardAwareScrollView>
    );
});

export default RequestMessage;
