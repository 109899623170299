import React, { useState } from 'react';
import { User as FirebaseUser, getAuth } from 'firebase/auth';
import { getApps } from 'firebase/app';
import { createCtx } from 'contexts/createCtx';
import { LoginOptions } from 'components/auth/LoginOptions';

const defaultAuthContext = (): AuthContextType => {
    const auth = getAuth();
    const apps = getApps();
    return {
        user: !apps.length ? null : auth.currentUser,
        setUser: () => {
            //ignore
        },
        loginOption: 'none',
        setLoginOption: () => {
            //ignore
        },
        loginEmail: '',
        setLoginEmail: () => {
            //ignore
        },
    };
};

type AuthContextType = {
    user: FirebaseUser | null;
    setUser: (value: FirebaseUser | null) => void;
    loginOption: LoginOptions;
    setLoginOption: (value: LoginOptions) => void;
    loginEmail: string;
    setLoginEmail: (value: string) => void;
};

export const [useAuthentication, AuthProvider] = createCtx<AuthContextType>();

//REF: https://www.carlrippon.com/react-context-with-typescript-p2/
type Props = {
    children: React.ReactNode;
};
const AuthContextProvider = ({ children }: Props) => {
    const [loginOption, setLoginOption] = useState(defaultAuthContext().loginOption);
    const [loginEmail, setLoginEmail] = useState(defaultAuthContext().loginEmail);
    const [user, setUser] = useState(defaultAuthContext().user);
    return (
        <AuthProvider value={{ user, setUser, loginOption, setLoginOption, loginEmail, setLoginEmail }}>
            {children}
        </AuthProvider>
    );
};
export default AuthContextProvider;
