import React from 'react';
import { observer } from 'mobx-react-lite';
import { Image, StyleSheet, View } from 'react-native';
import { testProps } from 'components/ComponentTypes';
import { RegularText } from 'components/primitives/StyledText';
import Button from 'components/primitives/Button';
import Hyperlink from 'components/primitives/Hyperlink';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';

type OptInOutFeatureHighlightProps = {
    closeOptInOutFeatureHighlightScreen?: () => void;
    navigateToAlerts?: () => void;
};
const OptInOutFeatureHighlightScreen: React.FC<OptInOutFeatureHighlightProps> = observer(
    (props: OptInOutFeatureHighlightProps) => {
        const { uiStore } = useStore();
        const theme = useTheme();
        const venterraLogo = theme.dark
            ? require('../../../assets/images/venterra-logo-navigation.png')
            : require('../../../assets/images/venterra-logo-blue.png');

        const _navigateToAlertsAndNotifications = () => {
            if (props.navigateToAlerts) {
                props.navigateToAlerts();
            }
            _closeOptInOutFeatureHighlightScreen();
        };

        const _closeOptInOutFeatureHighlightScreen = () => {
            if (props.closeOptInOutFeatureHighlightScreen) {
                props.closeOptInOutFeatureHighlightScreen();
            }
        };

        if (uiStore.activityLoaderVisible) {
            return null;
        }
        return (
            <View style={styles.screenContainer}>
                <View style={styles.screenInner}>
                    <View style={styles.screenTop}>
                        <Image source={venterraLogo} style={styles.venterraLogo} />
                    </View>

                    <View style={styles.screenMiddle}>
                        <Image
                            source={require('../../../assets/images/smarthub_logo_stacked.png')}
                            style={styles.smarthubLogo}
                            {...testProps('opt-in-opt-out-feature-highlight-glasses')}
                        />
                        <RegularText style={styles.descText}>
                            We want to ensure you only receive the communications you want in your preferred mode of
                            communication.
                        </RegularText>
                        <RegularText style={styles.descText}>
                            Please take a moment to update your communication preferences.
                        </RegularText>
                        <Button
                            onClick={_navigateToAlertsAndNotifications}
                            style={styles.navigationButton}
                            {...testProps('navigate-to-alerts-and-notifications-button')}>
                            Yes, take me there!
                        </Button>
                    </View>

                    <View style={styles.screenBottom}>
                        <View style={styles.dismissText}>
                            <Hyperlink
                                onClick={_closeOptInOutFeatureHighlightScreen}
                                {...testProps('close-opt-in-opt-out-feature-highlight-screen')}>
                                Remind me later
                            </Hyperlink>
                        </View>
                    </View>
                </View>
            </View>
        );
    },
);

export default OptInOutFeatureHighlightScreen;

const styles = StyleSheet.create({
    screenContainer: {
        position: 'absolute',
        zIndex: 100,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SmarthubTheme.colors.white,
    },
    screenInner: {
        display: 'flex',
        width: '100%',
        maxWidth: 360,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    screenTop: {
        flex: 1,
        alignItems: 'center',
        flexGrow: 1,
    },
    venterraLogo: {
        width: 148,
        height: 20,
        marginTop: 60,
    },
    screenMiddle: {
        flex: 1,
        alignItems: 'center',
        flexGrow: 1.5,
    },
    smarthubLogo: {
        width: 165,
        height: 67,
        marginBottom: 55,
    },
    descText: {
        marginBottom: 35,
        textAlign: 'center',
    },
    navigationButton: {
        width: 360,
        maxWidth: '100%',
        marginBottom: 16,
    },
    // eslint-disable-next-line react-native/no-color-literals
    screenBottom: {
        marginTop: 28,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexGrow: 0.5,
    },
    dismissText: {
        marginBottom: 15,
    },
});
