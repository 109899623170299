import { createSmarthubAddressV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubAddressV1';
import { SelectOption } from '../../businesslogic/common/dataobjects/SelectOption';
import { SmarthubAddressV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubAddressV1';
import { SmarthubResidentV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentV1';
import { AbstractVersionedRemoteResult } from '../../../remotecmd/versioned/AbstractVersionedRemoteResult';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetNoticeToVacateScreenDataResultV1
 */
export interface GetNoticeToVacateScreenDataResultV1 extends AbstractVersionedRemoteResult {
    todayDt: string;
    leaseEndDt: string;
    onMTM: boolean;
    noticePeriod: number;
    defaultExpectMoveOutDt: string;
    applicants: Array<SmarthubResidentV1>;
    moveOutReasons: Array<SelectOption>;
    propertyAddress: SmarthubAddressV1;
    provinceStateOptions: Array<SelectOption>;
    countries: Array<SelectOption>;
}

export function createGetNoticeToVacateScreenDataResultV1(): GetNoticeToVacateScreenDataResultV1 {
    return {
        todayDt: '01/01/1900',
        leaseEndDt: '01/01/1900',
        onMTM: false,
        noticePeriod: 0,
        defaultExpectMoveOutDt: '01/01/1900',
        applicants: [],
        moveOutReasons: [],
        propertyAddress: createSmarthubAddressV1(),
        provinceStateOptions: [],
        countries: [],
        authenticated: false,
        identifier: '',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
