import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import { DeemphansizedTextSmall, RegularText } from 'components/primitives/StyledText';
import { globalStyles } from 'theme/style/GlobalStyles';
import InputSwitch from 'components/input/InputSwitch';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import InputCheckbox from 'components/input/InputCheckbox';

type OptionSwitchComponentProps = {
    type: 'checkbox' | 'switch';
    label: string;
    description?: string;
    switchOn: boolean;
    disabled?: boolean;
    toggleOffTextColor?: string;
    testID?: string;
    accessibilityLabel?: string;
    onChangesMade: (toggleOn: boolean) => void;
};

const OptionSwitchComponent: React.FC<OptionSwitchComponentProps> = observer(
    ({
        type,
        label,
        description,
        switchOn,
        disabled,
        toggleOffTextColor,
        testID,
        accessibilityLabel,
        onChangesMade,
    }) => {
        const theme = useTheme();

        const onToggleCheckbox = (checked: string) => {
            onChangesMade(checked === 'checked' ? true : false);
        };

        const onToggleSwitch = (switchOn: boolean) => {
            onChangesMade(switchOn);
        };

        return (
            <View style={[styles.oneAlertOption]}>
                {type === 'checkbox' && (
                    <View style={styles.optioncheckboxview}>
                        <InputCheckbox
                            initialStatus={switchOn ? 'checked' : 'unchecked'}
                            disabled={disabled}
                            onChange={onToggleCheckbox}
                            testID={testID}
                            style={'small'}
                            accessibilityLabel={accessibilityLabel}
                        />
                    </View>
                )}
                {type === 'switch' && (
                    <View style={styles.optiontoggleview}>
                        <InputSwitch
                            initialStatus={switchOn}
                            disabled={disabled}
                            onChange={onToggleSwitch}
                            testID={testID}
                            accessibilityLabel={accessibilityLabel}
                        />
                    </View>
                )}
                <View style={styles.stackedtitledesc}>
                    <View style={styles.optiontextview}>
                        <RegularText
                            style={[
                                styles.optiontitle,
                                !switchOn && { color: toggleOffTextColor || theme.colors.text },
                            ]}>
                            {label}
                        </RegularText>
                    </View>
                    {description && (
                        <View style={[styles.optiontextview]}>
                            <DeemphansizedTextSmall style={styles.optiondescription}>
                                {description}
                            </DeemphansizedTextSmall>
                        </View>
                    )}
                </View>
            </View>
        );
    },
);

export default OptionSwitchComponent;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingVertical: SmarthubTheme.layout.SURROUNDMARGIN,
        width: '100%',
    },
    innerContainer: {
        maxWidth: '100%',
    },
    sectiontitle: {},
    sectiondescription: {},
    oneAlertOption: {
        flexDirection: 'row',
        height: SmarthubTheme.layout.GRIDINCREMENT * 8,
        alignItems: 'center',
    },
    stackedtitledesc: {
        flexDirection: 'column',
        paddingBottom: 6,
        width: '85%',
    },
    optiontextview: {
        flexDirection: 'row',
    },
    optiontitle: {
        flex: 1,
        flexWrap: 'wrap',
    },
    optiondescription: {
        paddingRight: SmarthubTheme.layout.INNERPAD,
    },
    optiontoggleview: {
        width: 70,
    },
    optioncheckboxview: {
        width: 40,
    },
    contactinfowarning: { padding: SmarthubTheme.layout.INNERPAD },
    contactinfotitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    updatelink: {
        width: '20%',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
    },
    infotextcont: {
        width: '80%',
    },
});
