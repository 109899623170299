import { SmarthubResidentNotificationPreferenceV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentNotificationPreferenceV1';
import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { EmptyVersionedRemoteResult } from '../../../remotecmd/versioned/EmptyVersionedRemoteResult';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.UpdateResidentNotificationPreferencesCommandV1
 */
export interface UpdateResidentNotificationPreferencesCommandV1
    extends AbstractResidentAuthenticatedCommand<EmptyVersionedRemoteResult> {
    preferences: Array<SmarthubResidentNotificationPreferenceV1>;
}

export function createUpdateResidentNotificationPreferencesCommandV1(): UpdateResidentNotificationPreferencesCommandV1 {
    return {
        preferences: [],
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.residency.UpdateResidentNotificationPreferencesCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
