import React, { useEffect } from 'react';
import Button from 'components/primitives/Button';
import ProgressIndicator from 'components/primitives/ProgressIndicator';
import { globalStyles } from 'theme/style/GlobalStyles';
import {
    BackHandler,
    Platform,
    SafeAreaView,
    StyleProp,
    StyleSheet,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import FlexContainer from 'components/primitives/FlexContainer';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useNavigation } from '@react-navigation/native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { testProps } from 'components/ComponentTypes';
import Section from 'components/primitives/Section';
import { isDesktopNotMobile, isDesktopAndMobile } from 'utils/uiUtils';

type ProgressIndicatorProps = {
    currentStep: number;
    currentStepInProgress?: boolean;
};

type FooterButtonProps = {
    text: string;
    onClick: () => void;
    disabled?: boolean;
};

type SmartmoveScreenContainerProps = {
    progressIndicatorProps?: ProgressIndicatorProps | undefined;
    children?: React.ReactNode;
    alignBodyContentTop?: boolean;
    footerButtonProps?: FooterButtonProps;
    showBack?: boolean;
    showClose?: boolean;
    closeRoute?: string;
    contentContainerStyle?: StyleProp<any>;
};
type SmartmoveScreenProps = SmartmoveScreenContainerProps & {
    numberOfStepsInHeader: number | undefined;
};

const SmartmoveScreen: React.FC<SmartmoveScreenProps> = (props: SmartmoveScreenProps) => {
    const theme = useTheme();
    const numberOfStepsInHeader = props.numberOfStepsInHeader;
    const navigation = useNavigation();
    const isDesktopOrMobileWeb = isDesktopNotMobile() || isDesktopAndMobile();

    const wrapperHeight = useWindowDimensions().height - 100;
    const adjustHeight = { minHeight: Platform.OS === 'android' ? wrapperHeight : '100%' };
    const defaultContentContainerStyle = [
        isDesktopOrMobileWeb ? styles.weblayout : styles.mobilelayout,
        styles.otherContainerStyle,
    ];

    const contentContainerStyle = props.contentContainerStyle || defaultContentContainerStyle;
    const progressIndicatorStyle = props.alignBodyContentTop
        ? [defaultContentContainerStyle, styles.progressIndicatorTop]
        : defaultContentContainerStyle;

    return (
        <FlexContainer
            flexDirection={'column'}
            style={[globalStyles.smartMoveContentWrapper, adjustHeight, { backgroundColor: theme.colors.background }]}>
            {(props.progressIndicatorProps || props.showBack || (props.showClose && props.closeRoute)) && (
                <View style={progressIndicatorStyle}>
                    {props.progressIndicatorProps && (
                        <ProgressIndicator
                            numberOfSteps={numberOfStepsInHeader || 0}
                            currentStep={
                                numberOfStepsInHeader === 4
                                    ? props.progressIndicatorProps.currentStep
                                    : --props.progressIndicatorProps.currentStep
                            }
                            currentStepInProgress={props.progressIndicatorProps.currentStepInProgress || false}
                        />
                    )}

                    {(props.showBack || (props.showClose && props.closeRoute)) && (
                        <View style={[globalStyles.flexRow, globalStyles.doubleSpaceBelow]}>
                            {props.showBack && (
                                <TouchableOpacity
                                    style={[globalStyles.justflex1]}
                                    onPress={() => {
                                        navigation.goBack();
                                    }}>
                                    <MaterialCommunityIcons
                                        {...testProps('back-arrow')}
                                        name='keyboard-backspace'
                                        size={36}
                                        color={theme.colors.icon}
                                    />
                                </TouchableOpacity>
                            )}

                            {props.showClose && props.closeRoute && (
                                <TouchableOpacity
                                    style={styles.closeButton}
                                    onPress={() => navigation.navigate(props.closeRoute!)}>
                                    <MaterialIcons
                                        {...testProps('close')}
                                        name='close'
                                        size={theme.layout.GRIDINCREMENT * 4}
                                        color={theme.dark ? theme.colors.white : theme.colors.black}
                                    />
                                </TouchableOpacity>
                            )}
                        </View>
                    )}
                </View>
            )}
            <View style={contentContainerStyle}>{props.children}</View>

            {props.footerButtonProps && (
                <View style={defaultContentContainerStyle}>
                    <View style={[globalStyles.bottomButton]}>
                        <Section>
                            <Button
                                {...testProps(props.footerButtonProps.text)}
                                onClick={props.footerButtonProps.onClick}
                                inactive={props.footerButtonProps.disabled}>
                                {props.footerButtonProps.text}
                            </Button>
                        </Section>
                    </View>
                </View>
            )}
        </FlexContainer>
    );
};

const SmartmoveScreenContainer: React.FC<SmartmoveScreenContainerProps> = (props: SmartmoveScreenContainerProps) => {
    const theme = useTheme();

    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const isDesktop = isDesktopNotMobile();

    ///header
    const { query } = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );
    const isLoading = query.isLoading;
    const numberOfStepsInHeader = query.data?.smartmoveApplicationHeaderV1?.numberOfSteps;

    const preventHardwareBackButtonClick = () => {
        //do nothing, return true to stop bubble up events
        return true;
    };

    useEffect(() => {
        const eventSubs = BackHandler.addEventListener('hardwareBackPress', preventHardwareBackButtonClick);

        return () => {
            eventSubs.remove();
        };
    }, []);

    if (isLoading) return null;

    if (isDesktop) {
        return (
            <KeyboardAwareScrollView
                style={[
                    styles.webscrollview,
                    {
                        backgroundColor: uiStore.useDarkTheme ? theme.colors.darkgrey : theme.colors.lightestgrey,
                    },
                ]}
                contentContainerStyle={styles.contentContainer}
                scrollEnabled={true}
                enableAutomaticScroll={true}
                enableResetScrollToCoords={false}>
                <View style={styles.innerscreen}>
                    <SmartmoveScreen {...props} numberOfStepsInHeader={numberOfStepsInHeader} />
                </View>
            </KeyboardAwareScrollView>
        );
    } else
        return (
            <SafeAreaView style={[globalStyles.justflex1, { backgroundColor: theme.colors.background }]}>
                <KeyboardAwareScrollView
                    scrollEnabled={true}
                    enableAutomaticScroll={true}
                    keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                    enableResetScrollToCoords={false}>
                    <SmartmoveScreen {...props} numberOfStepsInHeader={numberOfStepsInHeader} />
                </KeyboardAwareScrollView>
            </SafeAreaView>
        );
};

export default SmartmoveScreenContainer;

const styles = StyleSheet.create({
    weblayout: { maxWidth: 700, width: '80%' },
    mobilelayout: {
        flex: 1,
        width: '100%',
    },
    otherContainerStyle: { paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN },
    webscrollview: {
        flex: 1,
    },
    contentContainer: { alignItems: 'center', flex: 1 },
    innerscreen: {
        marginVertical: SmarthubTheme.layout.SURROUNDMARGIN,
        flex: 1,
        minWidth: 700,
        maxWidth: 1400,
    },
    progressIndicatorTop: {
        maxHeight: 50,
    },
    closeButton: {
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
        alignSelf: 'flex-end',
    },
});
