import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetLeaseContractsResultV1 } from './GetLeaseContractsResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetLeaseContractsCommandV1
 */
export type GetLeaseContractsCommandV1 = AbstractResidencyAuthenticatedCommand<GetLeaseContractsResultV1>;

export function createGetLeaseContractsCommandV1(): GetLeaseContractsCommandV1 {
    return {
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetLeaseContractsCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
