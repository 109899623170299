import React, { useEffect } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { DeemphansizedTextSmall, DeephansizedRegularText, RegularText } from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { StackNavigationProp } from '@react-navigation/stack';
import { formatDateStringMonthLabelAtTimeFromNumber } from 'utils/DateUtils';
import { useGetSmartPackagesForResidentQuery } from 'queries/hooks/smartpackage/useGetSmartPackagesForResidentQuery';
import { SmartPackageV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartpackage/SmartPackageV1';
import Tag from 'components/primitives/Tag';
import { TagTypes } from 'components/primitives/TagTypes';

type SmartPackageProps = {
    navigation: StackNavigationProp<any>;
};

const SmartPackageHistory = observer(({ navigation }: SmartPackageProps) => {
    const { userSessionStore, uiStore } = useStore();
    const { sessionActiveResidency, hasSessionActiveResidency } = userSessionStore;

    const theme = useTheme();

    const { queryKey, queryResult } = useGetSmartPackagesForResidentQuery(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residentId,
        false,
        90,
        uiStore,
        {
            enabled: hasSessionActiveResidency,
        },
    );

    useEffect(() => {
        queryResult.isLoading || queryResult.isFetching ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [queryResult.isLoading, queryResult.isFetching]);

    if (!hasSessionActiveResidency || queryResult.isLoading || queryResult.isFetching || !queryResult.data) {
        return null;
    }

    return (
        <ScrollView contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
            <View>
                <View style={styles.title}>
                    <DeephansizedRegularText>Your package history for the last 90 days.</DeephansizedRegularText>
                </View>

                {queryResult.data.packages.length === 0 ? (
                    <View style={styles.notice}>
                        <DeephansizedRegularText>Your package history will display here</DeephansizedRegularText>
                    </View>
                ) : (
                    <View style={styles.packages}>
                        {queryResult.data.packages.map((parcel: SmartPackageV1, index) => (
                            <View key={`parcel-pending-${index}`} style={styles.packageWrapper}>
                                <View style={styles.package}>
                                    <View style={styles.packageLeft}>
                                        <RegularText style={styles.lineItem}>
                                            Package #{parcel.parcelPendingParcelId}
                                        </RegularText>
                                        <DeemphansizedTextSmall style={styles.lineItem}>
                                            Arrived {formatDateStringMonthLabelAtTimeFromNumber(parcel.deliveryTime)}
                                        </DeemphansizedTextSmall>
                                        {parcel.abandonAlertSentDtt == null && (
                                            <DeemphansizedTextSmall>
                                                Picked up{' '}
                                                {formatDateStringMonthLabelAtTimeFromNumber(
                                                    parcel.lastStatusChangeTime,
                                                )}
                                            </DeemphansizedTextSmall>
                                        )}
                                        {parcel.abandonAlertSentDtt != null && (
                                            <DeemphansizedTextSmall style={styles.lineItemTall}>
                                                Mailed back{' '}
                                                {formatDateStringMonthLabelAtTimeFromNumber(
                                                    parcel.lastStatusChangeTime,
                                                )}
                                            </DeemphansizedTextSmall>
                                        )}
                                    </View>
                                    <View style={styles.packageRight}>
                                        {parcel.abandonAlertSentDtt == null && (
                                            <Tag tagType={TagTypes.DarkGrey}>Picked Up</Tag>
                                        )}
                                        {parcel.abandonAlertSentDtt != null && (
                                            <Tag tagType={TagTypes.Red}>Mailed Back</Tag>
                                        )}
                                    </View>
                                </View>

                                {parcel.abandonAlertSentDtt != null && (
                                    <DeemphansizedTextSmall>
                                        This package was not picked up within the {parcel.packageMaxDaysForStorageFee}{' '}
                                        day time limit. We&apos;ve sent this package back to the sender.
                                    </DeemphansizedTextSmall>
                                )}
                            </View>
                        ))}
                    </View>
                )}
            </View>
        </ScrollView>
    );
});
export default SmartPackageHistory;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: SmarthubTheme.layout.SURROUNDMARGIN,
        marginTop: Platform.OS === 'web' ? SmarthubTheme.layout.SURROUNDMARGIN : SmarthubTheme.layout.GRIDINCREMENT * 7,
        paddingBottom: SmarthubTheme.layout.SECTIONMARGIN * 4,
    },
    title: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    notice: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        borderTopColor: SmarthubTheme.colors.border,
        borderTopWidth: 1,
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    packages: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        borderTopColor: SmarthubTheme.colors.border,
        borderTopWidth: 1,
    },
    packageWrapper: {
        borderBottomColor: SmarthubTheme.colors.border,
        borderBottomWidth: 1,
        paddingVertical: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
    package: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    packageLeft: {
        flex: 1,
    },
    packageRight: {
        flex: 1,
        maxWidth: 120,
    },
    lineItem: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    lineItemTall: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
});
