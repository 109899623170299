import React from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { DeemphansizedTextSmall, RegularText } from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { Button, useTheme } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { v4 as uuid } from 'uuid';
import { testProps } from 'components/ComponentTypes';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';

const LeaseAdjustmentBanner: React.FC = observer(() => {
    const { sessionActiveResidency } = useStore().userSessionStore;
    const theme = useTheme();
    const navigation = useNavigation();
    const route = useRoute();
    const { useFullExperience } = useResponsiveContext();

    const {
        leaseEndDt,
        expectMoveoutDt,
        noticeDt,
        expectXferDt,
        leaseAdjustment,
        leaseAdjustmentMessage,
        leaseAdjustmentMessage2,
    } = sessionActiveResidency;

    //if expiring soon show the alert.... if transfer / renewal / notice then show basic banner
    const highlightedFlavor = false; //sessionActiveResidency.leaseAdjustment === 'ExpiringSoon';

    let pendingAdjustmentText = 'View'; //LeaseRenewalRequestSubmitted and ExpiringSoon
    //TODO future when we add support for ntv/renew/transfer...
    if (leaseAdjustment === 'NoticeToVacateSubmitted' || leaseAdjustment === 'OnNotice') {
        pendingAdjustmentText = 'Edit';
    }
    /*if (leaseAdjustment === 'PendingTransfer' || leaseAdjustment === 'OnNotice') {
        pendingAdjustmentText = 'Edit';
    } else if (
        leaseAdjustment === 'LeaseRenewalInProgress' ||
        leaseAdjustment === 'TransferRequestSubmitted' ||
        leaseAdjustment === 'NoticeToVacateSubmitted'
    ) {
        pendingAdjustmentText = 'Cancel';
    }*/

    const adjustmentText = highlightedFlavor ? 'Dismiss' : pendingAdjustmentText;

    const stackedMessagesStyle = useFullExperience
        ? leaseAdjustmentBannerStyles.stackedleaseAdjustmentMessages
        : [
              leaseAdjustmentBannerStyles.stackedleaseAdjustmentMessages,
              leaseAdjustmentBannerStyles.stackedleaseAdjustmentMessagesMobile,
          ];

    const leaseAdjustmentMessageStyle = useFullExperience
        ? leaseAdjustmentBannerStyles.leaseAdjustmentMessage
        : [
              leaseAdjustmentBannerStyles.leaseAdjustmentMessage,
              leaseAdjustmentBannerStyles.leaseAdjustmentMessageMobile,
          ];

    return (
        <View style={[leaseAdjustmentBannerStyles.containerrow]}>
            <View
                style={
                    highlightedFlavor
                        ? leaseAdjustmentBannerStyles.leaseAdjustmentMessageHighlight
                        : [leaseAdjustmentMessageStyle, { backgroundColor: theme.colors.background }]
                }>
                {leaseAdjustmentMessage && (
                    <View {...testProps(leaseAdjustment)} style={stackedMessagesStyle}>
                        <RegularText style={leaseAdjustmentBannerStyles.centeredtext}>
                            {leaseAdjustmentMessage}
                        </RegularText>
                        {leaseAdjustmentMessage2 &&
                            leaseAdjustmentMessage2.split('\n').map(m => (
                                <DeemphansizedTextSmall key={uuid()} style={leaseAdjustmentBannerStyles.centeredtext}>
                                    {m}
                                </DeemphansizedTextSmall>
                            ))}
                    </View>
                )}

                <View style={leaseAdjustmentBannerStyles.leaseAdjustmentMessageOptionContainer}>
                    {leaseAdjustment !== 'PendingTransfer' &&
                        leaseAdjustment !== 'OnNoticeNoEdit' &&
                        leaseAdjustment !== 'LeaseRenewalRequestSubmitted' &&
                        leaseAdjustment !== 'LeaseRenewalInProgress' &&
                        leaseAdjustment !== 'LeaseRenewalPendingExecution' &&
                        adjustmentText && (
                            <Button
                                {...testProps('lease-adjustment-banner-hyperlink')}
                                mode={'text'}
                                onPress={() => {
                                    if (
                                        leaseAdjustment === 'NoticeToVacateSubmitted' ||
                                        leaseAdjustment === 'OnNotice'
                                    ) {
                                        if (route.name === 'Home') navigation.navigate('NoticeToVacateEdit');
                                        else navigation.navigate('Home', { screen: 'NoticeToVacateEdit' });
                                    } else if (leaseAdjustment === 'LeaseRenewalPendingSignature') {
                                        if (route.name === 'Home')
                                            navigation.navigate(navigationRoutes.homeRoutes.renewalSigning);
                                        else
                                            navigation.navigate('Home', {
                                                screen: navigationRoutes.homeRoutes.renewalSigning,
                                            });
                                    } else {
                                        if (route.name === 'Home') navigation.navigate('ContractAndDocuments');
                                        else navigation.navigate('Home', { screen: 'ContractAndDocuments' });
                                    }
                                }}
                                color={theme.colors.lightblue}
                                uppercase={false}
                                compact={true}>
                                <RegularText style={leaseAdjustmentBannerStyles.leaseAdjustmentMessageOption}>
                                    {adjustmentText}
                                </RegularText>
                            </Button>
                        )}

                    {/*{leaseAdjustment === 'PendingTransfer' && <LeaseSwitchLink />}*/}
                </View>
            </View>
        </View>
    );
});

export default LeaseAdjustmentBanner;

export const leaseAdjustmentBannerStyles = StyleSheet.create({
    containerrow: {
        flex: 1,
        flexDirection: 'row',
    },
    leaseAdjustmentMessageHighlight: {
        flex: 1,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.orange,
        borderWidth: 1,
        padding: SmarthubTheme.layout.INNERPAD,
        paddingHorizontal: SmarthubTheme.layout.INNERPAD * 2,
        justifyContent: 'space-between',
    },
    leaseAdjustmentMessage: {
        flex: 1,
        padding: SmarthubTheme.layout.INNERPAD,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leaseAdjustmentMessageMobile: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    leaseAdjustmentMessageOptionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    leaseAdjustmentMessageOption: {
        color: SmarthubTheme.colors.lightblue,
    },
    stackedleaseAdjustmentMessages: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '85%',
    },
    stackedleaseAdjustmentMessagesMobile: {
        alignItems: 'center',
        width: '100%',
    },
    centeredtext: {},
});
