import React from 'react';
import { Image, Linking, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SmallText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { getAppDownloadURL } from 'utils/appTeasers';
import { testProps } from 'components/ComponentTypes';

type AppBannerProps = {
    closeAppBanner?: () => void;
};

const AppBanner: React.FC<AppBannerProps> = observer((props: AppBannerProps) => {
    const _downloadApp = () => {
        const url = getAppDownloadURL();
        if (url) {
            Linking.openURL(url);
        }
    };

    const _dismissBanner = () => {
        if (props.closeAppBanner) {
            props.closeAppBanner();
        }
    };

    const topMargin = useSafeAreaInsets().top > 12 ? useSafeAreaInsets().top : 12;

    const wrapperStyles = [styles.bannerWrapper, { paddingTop: topMargin }];

    return (
        <TouchableWithoutFeedback>
            <View style={wrapperStyles}>
                <View style={styles.bannerLeft} onTouchEnd={_downloadApp}>
                    <Image style={styles.logoIcon} source={require('../../../assets/images/new-app-icon.png')} />
                </View>
                <View style={styles.bannerMiddle} onTouchEnd={_downloadApp}>
                    <SmallText style={styles.instructionalText}>
                        Experience the full SMARTHUB. Download the app
                    </SmallText>
                </View>
                <View style={styles.bannerRight} onTouchEnd={_dismissBanner} {...testProps('dismiss-banner')}>
                    <MaterialCommunityIcons
                        name='close'
                        size={14}
                        color={SmarthubTheme.colors.white}
                        {...testProps('dismiss-banner-icon')}
                    />
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
});

export default AppBanner;

const styles = StyleSheet.create({
    bannerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 12,
        paddingBottom: 10,
        paddingLeft: 12,
        backgroundColor: SmarthubTheme.colors.lightblue,
    },
    bannerLeft: {
        flex: 1,
        maxWidth: 50,
    },
    logoIcon: {
        width: 32,
        height: 32,
        marginRight: 18,
        borderRadius: 5,
    },
    bannerMiddle: {
        flex: 1,
    },
    instructionalText: {
        color: SmarthubTheme.colors.white,
    },
    bannerRight: {
        flex: 1,
        maxWidth: 14,
        marginTop: 3,
    },
});
