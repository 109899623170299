import { SmarthubAddressV1 } from './SmarthubAddressV1';
import { PhoneNoType } from '../../../../shared/businesslogic/residency/dataobjects/PhoneNoType';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.residency.SmarthubSodaRecipientV1
 */
export interface SmarthubSodaRecipientV1 {
    residencyId: number;
    residentId: number;
    mailToFirstName: string;
    mailToLastName: string;
    mailToAddrId: number;
    mailToAddress: SmarthubAddressV1 | null;
    phone1No: string;
    phone1TypeCd: PhoneNoType;
    phone2No: string;
    phone2TypeCd: PhoneNoType;
    email: string;
}

export function createSmarthubSodaRecipientV1(): SmarthubSodaRecipientV1 {
    return {
        residencyId: 0,
        residentId: 0,
        mailToFirstName: '',
        mailToLastName: '',
        mailToAddrId: 0,
        mailToAddress: null,
        phone1No: '',
        phone1TypeCd: 'NotSpecified',
        phone2No: '',
        phone2TypeCd: 'NotSpecified',
        email: '',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
