import React from 'react';
import { observer } from 'mobx-react-lite';
import { ActivityIndicator as RNPActivityIndicator, useTheme } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useStore } from 'contexts/StoreContextProvider';
import { testProps } from 'components/ComponentTypes';
import { RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';

type ActivityIndicatorProps = {
    message: string;
};

const ActivityIndicatorWithMessage: React.FC<ActivityIndicatorProps> = observer(
    ({ message }: ActivityIndicatorProps) => {
        const theme = useTheme();
        const { uiStore } = useStore();

        return (
            <View {...testProps('loading')} style={styles.indicatorWrapper}>
                <View style={[styles.container, uiStore.useDarkTheme ? styles.darkBackground : styles.whiteBackground]}>
                    <RNPActivityIndicator
                        animating={true}
                        color={uiStore.useDarkTheme ? theme.colors.white : theme.colors.navy}
                        size={'large'}
                    />
                    <View style={[styles.message, globalStyles.flexColumnCenter]}>
                        <RegularText style={[globalStyles.sectionSpaceAbove, styles.message]}>{message}</RegularText>
                    </View>
                    {/*<Button onClick={()=>uiStore.hideActivityLoader()}>Close</Button>*/}
                </View>
                <View style={styles.greyout} />
            </View>
        );
    },
);

export default ActivityIndicatorWithMessage;

const styles = StyleSheet.create({
    indicatorWrapper: {
        zIndex: 96,
        position: 'absolute',
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
    },
    greyout: {
        zIndex: 97,
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: SmarthubTheme.colors.black,
        opacity: 0.5,
    },
    container: {
        zIndex: 98,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    whiteBackground: {
        backgroundColor: SmarthubTheme.colors.white,
    },
    darkBackground: {
        backgroundColor: SmarthubTheme.colors.darkgrey,
    },
    message: {
        maxWidth: 300,
        textAlign: 'center',
    },
});
