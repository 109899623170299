import { fixLoopback } from 'utils/UrlUtils';
import Constants from 'expo-constants';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import * as Updates from 'expo-updates';

let resolvedRPCServer: string | null = null;
export const getSmarthubRPCHandler = (): string => {
    if (resolvedRPCServer != null) return resolvedRPCServer;
    const targetServer = fixLoopback((Constants.expoConfig?.extra && Constants.expoConfig.extra['rpcUrl']) || '');
    if (targetServer === '') throw new Error('Missing rpcUrl in app.config.js');
    resolvedRPCServer = targetServer;
    logInfo(INFO_LOGGER, 'The smarthub rpc server is at ', targetServer);
    return targetServer;
};

let resolvedEncasaServer: string | null = null;
export const getEncasaServer = (): string => {
    if (resolvedEncasaServer != null) return resolvedEncasaServer;
    const targetServer = fixLoopback((Constants.expoConfig?.extra && Constants.expoConfig.extra['encasaUrl']) || '');
    if (targetServer === '') throw new Error('Missing encasaUrl in app.config.js');
    resolvedEncasaServer = targetServer;
    logInfo(INFO_LOGGER, 'The encasa server is at ', targetServer);
    return targetServer;
};

let resolvedWebUrl: string | null = null;
export const getSmarthubWebsiteUrl = (): string => {
    if (resolvedWebUrl != null) return resolvedWebUrl;
    const targetServer = fixLoopback(Constants.expoConfig?.extra && Constants.expoConfig.extra['webUrl']) || '';
    if (targetServer === '') throw new Error('Missing webUrl in app.config.js');
    resolvedWebUrl = targetServer;
    logInfo(INFO_LOGGER, 'The smarthub web site is at ', targetServer);
    return targetServer;
};

export const getReleaseChannel = (): string => {
    let channel = 'default';
    logInfo(INFO_LOGGER, 'Updates.channel set to ', Updates.channel);
    if (Updates.channel && Updates.channel !== '') {
        channel = Updates.channel;
    }
    logInfo(INFO_LOGGER, 'The release channel has been set to ', channel);
    return channel;
};
