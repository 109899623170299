import 'react-native-gesture-handler';
import React, { useEffect, useRef, useState } from 'react';
import { AppState, AppStateStatus, Platform } from 'react-native';
import 'react-native-url-polyfill/auto';
import { focusManager, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AuthContextProvider from './src/contexts/AuthContextProvider';
import AuthGuard from './src/screens/auth/AuthGuard';
import * as WebBrowser from 'expo-web-browser';
import { navigationRef } from 'navigation/stacks/RootNavigation';

import {
    Roboto_100Thin,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_700Bold,
    Roboto_900Black,
    useFonts
} from '@expo-google-fonts/roboto';
import { bootstrapApp } from 'utils/BootstrapHelpers';
import { createStore, StoreContextProvider } from 'contexts/StoreContextProvider';
import ResponsiveContextProvider from './src/contexts/ResponsiveContextProvider';
import { Portal, Provider as PaperProvider } from 'react-native-paper';
import { MenuProvider } from 'react-native-popup-menu';
import { SmarthubDarkTheme, SmarthubTheme } from 'theme/SmarthubTheme';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NavigationContainer } from '@react-navigation/native';
import { LinkingConfiguration } from 'navigation/linking/LinkingConfiguration';
import ErrorBoundary from 'react-native-error-boundary';
import ErrorFallBackComponent, { errorHandler } from 'screens/ErrorFallBackComponent';
import { RegularText } from 'components/primitives/StyledText';
import { NAVIGATION_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { observer } from 'mobx-react-lite';
import Constants from 'expo-constants';
import { polyfillWebCrypto } from "expo-standard-web-crypto";
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

polyfillWebCrypto();

//import * as rqnDevTools from 'react-query-native-devtools';

interface AppProps {
    skipLoadingScreen: boolean;
    exp: any;
}

if (Platform.OS === 'web') {
    WebBrowser.maybeCompleteAuthSession();
}

const site247key = (Constants.expoConfig?.extra && Constants.expoConfig.extra['site24x7ApiKey']) || '';

const queryClient = new QueryClient();

if (Platform.OS !== 'web' && Constants?.expoConfig?.extra && Constants.expoConfig.extra['environment'] === 'dev') {
    //rqnDevTools.addPlugin({ queryClient });
}

//site24x7 monitoring
{
    Platform.OS === 'web' &&
        Constants.expoConfig?.extra &&
        Constants.expoConfig.extra['environment'] !== 'dev' &&
        (function (w: Window, d, s, r, k) {
            if (w.performance && w.performance.timing && w.performance.navigation) {
                let m: any;
                //@ts-ignore
                w[r] =
                    //@ts-ignore
                    w[r] ||
                    function () {
                        //@ts-ignore
                        // eslint-disable-next-line prefer-rest-params
                        (w[r].q = w[r].q || []).push(arguments);
                    };
                const h = d.createElement('script');
                h.async = true;
                h.setAttribute('src', s + k);
                d.getElementsByTagName('head')[0].appendChild(h);
                (m = window.onerror),
                    (window.onerror = function (b, c, d, f, g) {
                        //@ts-ignore
                        m && m(b, c, d, f, g),
                            //@ts-ignore
                            g || (g = new Error(b)),
                            //@ts-ignore
                            (w[r].q = w[r].q || []).push(['captureException', g]);
                    });
            }
        })(window, document, '//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=', 's247r', site247key);
}

const rootStore = createStore();

export const App: React.FC<AppProps> = observer(({ skipLoadingScreen, exp }) => {
    const [isLoadingComplete, setLoadingComplete] = useState(false);

    const [fontsLoaded] = useFonts({
        Roboto_100Thin,
        Roboto_400Regular,
        Roboto_700Bold,
        Roboto_300Light,
        Roboto_900Black,
    });

    const { uiStore } = rootStore;

    //useEffect that will run on mount and unmount (passed empty array)
    useEffect(() => {
        const teardown = bootstrapApp(rootStore.uiStore, rootStore.userSessionStore);
        setLoadingComplete(true);
        return teardown;
    }, []);

    useEffect(() => {
        focusManager.setEventListener(handleFocus => {
            const onAppFocused = (state: AppStateStatus) => {
                handleFocus(state === 'active');
            };
            const onFocusedEventSub = AppState.addEventListener('change', onAppFocused);
            return ()=>{
                onFocusedEventSub.remove()
            }
        });
    }, []);

    const bootstrapped: boolean = (isLoadingComplete && fontsLoaded) || skipLoadingScreen;
    const routeNameRef = useRef<string | undefined>();
    const themeToUse = uiStore.useDarkTheme ? SmarthubDarkTheme : SmarthubTheme;

    if (bootstrapped) {
        return (
            <QueryClientProvider client={queryClient}>
                <StoreContextProvider value={rootStore}>
                    <AuthContextProvider>
                        <SafeAreaProvider>
                            <PaperProvider theme={themeToUse}>
                                <ResponsiveContextProvider>
                                    <MenuProvider>
                                        <Portal.Host>
                                            <ErrorBoundary
                                                FallbackComponent={ErrorFallBackComponent}
                                                onError={errorHandler}>
                                                <NavigationContainer
                                                    ref={navigationRef}
                                                    onReady={() => {
                                                        if (null != navigationRef.current) {
                                                            routeNameRef.current = navigationRef.current.getCurrentRoute()?.name;
                                                        }
                                                    }}
                                                    onStateChange={async state => {
                                                        const previousRouteName = routeNameRef.current;
                                                        let currentRouteName;
                                                        if (null != navigationRef.current) {
                                                            currentRouteName = navigationRef.current.getCurrentRoute()
                                                                ?.name;
                                                        }
                                                        logInfo(
                                                            NAVIGATION_LOGGER,
                                                            '============CHANGING NAVIGATION STATE============' +
                                                                ' Previous Route is ' +
                                                                previousRouteName +
                                                                ' Current route is ' +
                                                                currentRouteName,
                                                            JSON.stringify(state),
                                                        );

                                                        if (previousRouteName !== currentRouteName) {
                                                            //can track screen changes here if so desired...
                                                        }

                                                        // Save the current route name for later comparison
                                                        routeNameRef.current = currentRouteName;
                                                    }}
                                                    theme={themeToUse}
                                                    linking={LinkingConfiguration}
                                                    fallback={<RegularText>Loading...</RegularText>}>
                                                    <AuthGuard />
                                                </NavigationContainer>
                                            </ErrorBoundary>
                                        </Portal.Host>
                                    </MenuProvider>
                                </ResponsiveContextProvider>
                            </PaperProvider>
                        </SafeAreaProvider>
                    </AuthContextProvider>
                </StoreContextProvider>
                {Platform.OS === 'web' && <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
        );
    } else {
        return null;
    }
});

export default App;
