import React, { useCallback, useEffect, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { FormLabelText, Heading1 } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import Section from 'components/primitives/Section';
import InputDropdown, { InputDropdownOption } from 'components/input/InputDropdown';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalStyles } from 'theme/style/GlobalStyles';
import { action, runInAction } from 'mobx';
import InputRadio from 'components/input/InputRadio';
import { ntvPreferContactMethodOptions } from 'screens/home/manageMyLease/noticeToVacate/NtvHelper';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { navigationRoutes } from 'utils/NavigationUtils';
import { testProps } from 'components/ComponentTypes';

type NoticeToVacatePreferContactProps = {
    navigation: StackNavigationProp<any>;
};

const NoticeToVacatePreferContact: React.FC<NoticeToVacatePreferContactProps> = observer(
    ({ navigation }: NoticeToVacatePreferContactProps) => {
        const { useDrawerNavigation } = useResponsiveContext();
        const { userSessionStore, ntvStore, uiStore } = useStore();
        const { smarthubUser, confirmActiveResidency, sessionActiveResidency } = userSessionStore;
        const [preferContactOption, setPreferContactOption] = useState('');
        const [showPreferContactOption, setShowPreferContactOption] = useState(false);

        const ntvScreenData = ntvStore.ntvScreenData;

        const onSelectedContactMethod = action((value: string | undefined) => {
            if (value) {
                setPreferContactOption(value);
                ntvStore.ntvRequestSubmitData.preferContactOption = value.split('_')[0] === 'email' ? 'email' : 'phone';
                ntvStore.ntvRequestSubmitData.preferContactDetail = value.split('_')[1];
            } else {
                ntvStore.ntvRequestSubmitData.preferContactOption = '';
                ntvStore.ntvRequestSubmitData.preferContactDetail = '';
            }
        });

        const getNtvPreferContactMethodOptions = useCallback((): Array<InputDropdownOption> => {
            return ntvPreferContactMethodOptions(
                ntvStore.ntvRequestSubmitData.contactResidentId,
                ntvStore.ntvScreenData.applicants,
            );
        }, []);

        const onChangePreferContactPerson = action((selected: string) => {
            ntvStore.ntvRequestSubmitData.contactResidentId = Number(selected);
            const contactOptions = getNtvPreferContactMethodOptions();
            const firstOption = contactOptions[0]?.value;
            onSelectedContactMethod(firstOption);
            setShowPreferContactOption(!!firstOption);
        });

        const onSubmit = async () => {
            uiStore.showActivityLoader();
            try {
                const submittingResult = await ntvStore.submitNoticeToVacateRequestAsync();
                uiStore.hideActivityLoader();

                if (submittingResult.succeed) {
                    const routeParams = {
                        expectedMoveOutDt: ntvStore.ntvRequestSubmitData.expectedMoveOutDt,
                    };
                    if (useDrawerNavigation) {
                        navigation.navigate('NoticeToVacateConfirmation', routeParams);
                    } else {
                        navigation.navigate('NoticeToVacateConfirmationModal', routeParams);
                    }
                } else {
                    uiStore.showAlert({
                        title: 'Error',
                        message: submittingResult.exceptions,
                    });
                }
            } finally {
                if (uiStore.activityLoaderVisible) uiStore.hideActivityLoader();
            }
        };

        //handle hardware or browser back button
        useEffect(() => {
            if (!sessionActiveResidency.allowNTV) {
                navigation.navigate(navigationRoutes.homeRoutes.home);
            }
        });

        useEffect(() => {
            confirmActiveResidency(navigation);
            runInAction(() => {
                onChangePreferContactPerson(ntvStore.ntvRequestSubmitData.contactResidentId.toString());
            });
        }, [smarthubUser.authorizedResidencies]);

        return (
            <SafeAreaView style={globalStyles.container}>
                <KeyboardAwareScrollView
                    keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                    enableResetScrollToCoords={false}>
                    <View style={globalStyles.container}>
                        {!useDrawerNavigation && (
                            <Heading1 {...testProps('header-3')} style={globalStyles.sectionSpaceBelow}>
                                Notice to Vacate
                            </Heading1>
                        )}

                        <FormLabelText {...testProps('message-3')} style={globalStyles.doubleSpaceBelow}>
                            Who should we contact about this notice?
                        </FormLabelText>

                        <InputRadio
                            options={ntvScreenData.applicants.map(resident => {
                                return {
                                    testID: `${resident.firstName} ${resident.lastName}`,
                                    accessibilityLabel: `${resident.firstName} ${resident.lastName}`,
                                    label: `${resident.firstName} ${resident.lastName}`,
                                    value: resident.residentId.toString(),
                                };
                            })}
                            initialValue={ntvStore.ntvRequestSubmitData.contactResidentId.toString()}
                            onChange={action(selected => {
                                onChangePreferContactPerson(selected);
                            })}
                            style={'small'}
                        />

                        {ntvStore.ntvRequestSubmitData.contactResidentId > 0 && showPreferContactOption && (
                            <Section>
                                <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                    Preferred Contact Method
                                </FormLabelText>

                                <InputDropdown
                                    selectedValue={preferContactOption}
                                    options={getNtvPreferContactMethodOptions()}
                                    onChange={value => {
                                        onSelectedContactMethod(value as string);
                                    }}
                                />
                            </Section>
                        )}

                        <Section>
                            <View style={[styles.button]}>
                                <Button
                                    inactive={uiStore.activityLoaderVisible || sessionActiveResidency.adminYn}
                                    onClick={onSubmit}
                                    {...testProps('submit-button')}>
                                    Submit Notice
                                </Button>
                            </View>
                        </Section>
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        );
    },
);

export default NoticeToVacatePreferContact;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: '100%',
    },
});
