import { QueryKey, useInfiniteQuery, useQueryClient } from 'react-query';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTransactionV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionV1';
import {
    createGetTransactionsCommandV1,
    GetTransactionsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetTransactionsCommandV1';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { UIStore } from 'stores/domain/UIStore';
import { groupBy } from 'utils/ArrayUtils';
import { useEffect } from 'react';
import { generateInfiniteQueryKeyFromCmd, useCommandQuery } from 'queries/hooks/useCommandQuery';
import {
    isPaymentTransaction,
    isStatementTransaction,
    SmarthubTransactionType,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionType';
import { GetTransactionsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetTransactionsResultV1';
import {
    createGetTransactionCommandV1,
    GetTransactionCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetTransactionCommandV1';
import { GetTransactionResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetTransactionResultV1';
import {
    createGetAutopaysCommandV1,
    GetAutopaysCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetAutopaysCommandV1';
import { GetAutopaysResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/transactions/GetAutopaysResultV1';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { useIsFocused } from '@react-navigation/native';
import { AppState } from 'react-native';

export const transactionsRowLimit = 5;

export const generateGetTransactionsCMD = (
    propertyCd: string,
    residencyId: number,
    rowLimit = transactionsRowLimit,
    startIndex = 0,
) => {
    const cmd = createGetTransactionsCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.startIndex = startIndex;
    cmd.rowLimit = rowLimit;
    return cmd;
};

export const generateGetTransactionCMD = (
    propertyCd: string,
    residencyId: number,
    referenceNo: string,
    type: SmarthubTransactionType,
) => {
    const cmd = createGetTransactionCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.referenceNumber = referenceNo;
    cmd.type = type;
    return cmd;
};

export const generateGetAutpoaysCMD = (propertyCd: string, residencyId: number) => {
    const cmd = createGetAutopaysCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    return cmd;
};

export const useGetTransaction = (transactionId: string, transactionType: SmarthubTransactionType) => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateGetTransactionCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        transactionId,
        transactionType,
    );

    return useCommandQuery<GetTransactionCommandV1, GetTransactionResultV1>(cmd, uiStore, undefined, {
        enabled: hasSessionActiveResidency && !!transactionId && !!transactionType,
    });
};

export const useGetAutopays = () => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateGetAutpoaysCMD(sessionActiveResidency.propertyCd, sessionActiveResidency.residencyId);

    return useCommandQuery<GetAutopaysCommandV1, GetAutopaysResultV1>(cmd, uiStore, undefined, {
        enabled: hasSessionActiveResidency,
        onSuccess: result => {
            logInfo(INFO_LOGGER, 'Obtained autopays from server: ', JSON.stringify(result.autoPays));
        },
    });
};

// The general query to get transaction data
export const useGetTransactions = (cmd: GetTransactionsCommandV1) => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency } = userSessionStore;
    const isFocused = useIsFocused();

    const rowLimit = cmd.rowLimit ? cmd.rowLimit : transactionsRowLimit;

    return useInfiniteQuery(
        generateInfiniteQueryKeyFromCmd<GetTransactionsCommandV1, GetTransactionsResultV1>(cmd),
        queryReturn => _getTransactions(queryReturn.pageParam, cmd, rowLimit, uiStore),
        {
            enabled: hasSessionActiveResidency && isFocused && !AppState.currentState.match(/inactive|background/),
            // This side effect is necessary to add so the query knows if there are more pages that can be fetched
            getNextPageParam: lastPage => {
                if (
                    lastPage.transactions !== undefined &&
                    _getIsFinalPage(lastPage.transactions, lastPage.nextStartingIndex)
                ) {
                    return lastPage.nextStartingIndex;
                } else return undefined;
            },
        },
    );
};

// This hook is what transforms the pages of data into something the SectionList can use.
// Because of the _formatAndSortTransactions function, SectionList can use each page of data individually but not all the pages together.
// This hook takes all the pages and merges them into one page so the SectionList only has to look at one page.
// useInfiniteQuery returns data which holds an array of pages which hold our query results. We will be taking all the pages and merging them into data.pages[0]
export const useAlterTransactionsResultsForSectionList = (queryKey: QueryKey) => {
    const queryClient = useQueryClient();
    const data:
        | {
              pages: Array<{
                  transactions: Array<{ title: string; data: Array<SmarthubTransactionV1> }>;
                  nextStartingIndex: number;
              }>;
              pageParams?: Array<number>;
          }
        | undefined = useQueryClient().getQueryData(queryKey);
    useEffect(() => {
        if (data) {
            // This is new data.pages object. We will only be using newPagesArray[0]
            const newPagesArray = [
                {
                    transactions: [] as Array<{ title: string; data: Array<SmarthubTransactionV1> }>,
                    nextStartingIndex: 0,
                },
            ];

            // All the pages must be mapped over and all their transaction objects must be added to newPagesArray[0].transactions
            data?.pages.map(
                (page: {
                    transactions: Array<{ title: string; data: Array<SmarthubTransactionV1> }>;
                    nextStartingIndex: number;
                }) => {
                    // We take the transactions object from the new page and concat it to the end of the old transactions object. We must also update the next starting index.
                    newPagesArray[0].transactions = [...newPagesArray[0].transactions.concat(page.transactions)];
                    newPagesArray[0].nextStartingIndex = page.nextStartingIndex;
                },
            );

            // This fixes the following problem:
            // If the query ends in the middle of the section (there is more data to retrieve for that section) then 2 section titles will appear for the same section
            // It does so by iterating over the combined array, putting all titles in a hashmap along with their index in the array, then checking the hashmap for duplicates.
            // If there's a duplicate title it copies the duplicates data into the original one (who's index is held in the hashmap), sorts it, then removes the duplicate.
            const hashMap = new Map();

            // Iterating in reverse so splice doesn't mess up the indexing
            for (let i = 0; i < newPagesArray[0].transactions.length; i++) {
                if (hashMap.get(newPagesArray[0].transactions[i].title) !== undefined) {
                    // newPagesArray[0].transactions[i] contains the duplicate index
                    const originalIndex = hashMap.get(newPagesArray[0].transactions[i].title);
                    newPagesArray[0].transactions[originalIndex].data = [
                        ...newPagesArray[0].transactions[originalIndex].data.concat(
                            newPagesArray[0].transactions[i].data,
                        ),
                    ];
                    newPagesArray[0].transactions[originalIndex].data.sort(
                        (a: SmarthubTransactionV1, b: SmarthubTransactionV1) => b.transactionDtt - a.transactionDtt,
                    );
                    newPagesArray[0].transactions.splice(i, 1);
                    i--;
                } else {
                    hashMap.set(newPagesArray[0].transactions[i].title, i);
                }
            }

            // This replaces the query data so sectionList can use it in the page
            queryClient.setQueryData(queryKey, () => ({
                pages: newPagesArray,
            }));
        }
    }, [data]);
};

// This the function that dispatches the command for the query
const _getTransactions = (
    pageParam: number | undefined,
    CMD: GetTransactionsCommandV1,
    rowLimit: number,
    uiStore: UIStore,
) => {
    // This is where the command takes the updated pageParam (which is the nextStartingIndex) and uses it to update the start index
    CMD.startIndex = pageParam ? pageParam : 0;
    return dispatchCommandAsync<GetTransactionsCommandV1, GetTransactionsResultV1>(uiStore, CMD, false).then(
        (result: GetTransactionsResultV1) => {
            const nextStartingIndex = pageParam === undefined || pageParam === 0 ? rowLimit : pageParam + rowLimit;

            // The data gets modified once it arrives to fit the infinite query and SectionList data structure. Array<{title: string, data: Array<SmarthubTransactionsV1>}>
            // This is done here because the data structure needs to be consistent at all times.
            // Changing the data structure in the queryClient will mess up the getNextPageParam side effect in the useInfiniteQuery hook.
            // Next starting index is instantiated and incremented here so the query knows what starting index to fetch next.
            return _formatAndSortTransactions(result.transactions, nextStartingIndex);
        },
    );
};

// This function checks to see if there is any more data that can be fetched from the API
// Since the API doesn't tell us if there is more data available we have to determine it ourselves
// by checking to see if the number of statements and payments returned match the row limit. If so there may be more data still.
const _getIsFinalPage = (
    list: Array<{ title: string; data: Array<SmarthubTransactionV1> }>,
    nextStartingIndex: number,
): boolean => {
    let statementCount = 0;
    let paymentCount = 0;
    // console.log(list);
    list.forEach(transactionGroup => {
        transactionGroup.data.forEach(transaction => {
            if (isPaymentTransaction(transaction.type)) ++paymentCount;
            if (isStatementTransaction(transaction.type)) ++statementCount;
        });
    });
    // console.log(statementCount + ' ' + paymentCount);
    return statementCount === nextStartingIndex || paymentCount === nextStartingIndex;
};

// This takes the result data, formats it to the correct data structure we will be using and sorts it.
// SectionList needs a data structure as follows: Array<{title: string, data: Array<SmarthubTransactionsV1>}>
const _formatAndSortTransactions = (transactions: Array<SmarthubTransactionV1>, nextStartingIndex: number) => {
    const groupedTransactions = groupBy(transactions.slice(), 'period');
    const listData: Array<{ title: string; data: Array<SmarthubTransactionV1> }> = [];
    for (const period in groupedTransactions) {
        listData.push({ title: period, data: groupedTransactions[period] });
    }
    //sort by period descending...
    listData.sort((a, b) => (Number(a.title) < Number(b.title) ? 1 : -1));

    return {
        transactions: listData,
        nextStartingIndex: nextStartingIndex,
    };
};
