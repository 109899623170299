import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetSmartmoveMoveInChargesResultV1 } from './GetSmartmoveMoveInChargesResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.smartmove.GetSmartmoveMoveInChargesCommandV1
 */
export interface GetSmartmoveMoveInChargesCommandV1
    extends AbstractResidencyAuthenticatedCommand<GetSmartmoveMoveInChargesResultV1> {
    asOfDate: number;
}

export function createGetSmartmoveMoveInChargesCommandV1(): GetSmartmoveMoveInChargesCommandV1 {
    return {
        asOfDate: -1,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.smartmove.GetSmartmoveMoveInChargesCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
