import React from 'react';
import * as yup from 'yup';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { Heading2, RegularText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import Section from 'components/primitives/Section';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useFormik } from 'formik';
import { globalStyles } from 'theme/style/GlobalStyles';
import InputRadio, { InputRadioOption } from 'components/input/InputRadio';
import { testProps } from 'components/ComponentTypes';
import { navigationRoutes } from 'utils/NavigationUtils';

type CreateMaintenanceRequestCovidQuestionProps = {
    navigation: StackNavigationProp<any>;
};

const schema = yup.object().shape({
    hasCovidSym: yup.string(),
});

const radioOptions: Array<InputRadioOption> = [
    {
        label: 'One or more of these conditions apply to me',
        value: 'true',
        testID: 'true-covid-19-input',
        accessibilityLabel: 'true-covid-19-input',
    },
    {
        label: 'None of these conditions apply to me',
        value: 'false',
        testID: 'false-covid-19-input',
        accessibilityLabel: 'false-covid-19-input',
    },
];

const CreateMaintenanceRequestCovidQuestion: React.FC<CreateMaintenanceRequestCovidQuestionProps> = observer(
    ({ navigation }: CreateMaintenanceRequestCovidQuestionProps) => {
        const { requestStore, uiStore } = useStore();
        const { useDrawerNavigation } = useResponsiveContext();

        const formik = useFormik({
            validateOnMount: false,
            validateOnChange: false,
            validateOnBlur: false,
            initialValues: {
                hasCovidSym: 'false',
            },
            validationSchema: schema,
            onSubmit: (values: { hasCovidSym: string }) => {
                requestStore.hasCovidSym = values.hasCovidSym === 'true';

                useDrawerNavigation
                    ? navigation.navigate(navigationRoutes.homeRoutes.createARequest2)
                    : navigation.navigate(navigationRoutes.loggedInRoutes.createARequestModal2);
            },
        });

        return (
            <SafeAreaView style={globalStyles.container}>
                <KeyboardAwareScrollView
                    keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                    enableResetScrollToCoords={false}>
                    <View style={globalStyles.container} {...testProps('covid-19-message')}>
                        <Section>
                            <Heading2>Please indicate if you or anyone in the apartment:</Heading2>
                        </Section>

                        <Section>
                            <RegularText style={globalStyles.singleSpaceBelow}>
                                1. Have any respiratory symptoms including fever, sore throat, cough, shortness of
                                breath.
                            </RegularText>
                            <RegularText style={globalStyles.singleSpaceBelow}>
                                2. Traveled internationally within the last 14 days where COVID-19 cases have been
                                confirmed.
                            </RegularText>
                            <RegularText style={globalStyles.singleSpaceBelow}>
                                3. Worked in a healthcare setting that has confirmed COVID-19 cases.
                            </RegularText>
                            <RegularText style={globalStyles.singleSpaceBelow}>
                                4. Had close contact with a person known to have COVID-19.
                            </RegularText>
                        </Section>

                        <Section>
                            <InputRadio
                                options={radioOptions}
                                initialValue={formik.values.hasCovidSym}
                                onChange={formik.handleChange('hasCovidSym')}
                                style={'small'}
                            />
                        </Section>

                        <Section>
                            <View style={[styles.button]}>
                                <Button onClick={formik.handleSubmit} {...testProps('submit-button')}>
                                    Continue
                                </Button>
                            </View>
                        </Section>
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        );
    },
);

export default CreateMaintenanceRequestCovidQuestion;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    contactSectionDrawer: {
        flexDirection: 'row',
    },
    contactSection: {
        flexDirection: 'column',
    },
    contactMethodSection: {},
    contactMethodSectionDrawer: {
        marginLeft: SmarthubTheme.layout.SECTIONMARGIN,
    },
    button: {
        width: '100%',
    },
    numberInput: {
        width: '30%',
    },
    // addPhotoSection: {
    //     flexDirection: 'row',
    //     alignItems: 'center',
    // },
    // addPhotoLabel: {
    //     paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
    // },
    closeButton: {
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});
