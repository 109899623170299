export type PreferredCommunicationMethod = 'None' | 'Email' | 'EmailAndText';

export type NullablePreferredCommunicationMethod = PreferredCommunicationMethod | null;

export const PreferredCommunicationMethodOptions = [
    { value: 'None', label: 'None' },
    { value: 'Email', label: 'Email' },
    { value: 'EmailAndText', label: 'Email And Text' },
];

export const PreferredCommunicationMethodOptionsWithBlank = [{ value: '', label: '' }].concat(
    PreferredCommunicationMethodOptions,
);

/* START CUSTOM CODE */
/* END CUSTOM CODE */
