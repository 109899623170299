import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import { Image, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { change24HourTo12Hour, getDayOfWeekByCode, getTimePeriodFromHour } from 'utils/DateUtils';
import { OfficeHoursDo } from 'models/remotecmds/com/ocs/nirvana/businesslogic/chorus/dataobjects/OfficeHoursDo';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { testProps } from 'components/ComponentTypes';
import { useGetCommnityInfo } from 'queries/hooks/more/useGetCommunityInfo';
import PhoneLink from 'components/primitives/PhoneLink';

const CommunityInfo = () => {
    const theme = useTheme();
    const { userSessionStore, uiStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;

    const { data, isFetching, isSuccess } = useGetCommnityInfo();

    useEffect(() => {
        isFetching ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [isFetching]);

    const _writeOfficeHours = (startHour: number, endHour: number): string => {
        if (startHour === 0 && endHour === 0) {
            return 'Closed';
        } else {
            let officeHours: string = change24HourTo12Hour(startHour).toString();
            officeHours += getTimePeriodFromHour(startHour);
            officeHours += ' - ';
            officeHours += change24HourTo12Hour(endHour).toString();
            officeHours += getTimePeriodFromHour(endHour);

            return officeHours;
        }
    };

    if (!isSuccess || !data) {
        return null;
    }

    return (
        <KeyboardAwareScrollView
            contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}
        >
            <View style={[styles.container, { backgroundColor: theme.colors.background }]}>
                {null != sessionActiveResidency.communityLogoImageURI &&
                    sessionActiveResidency.communityLogoImageURI.length > 0 && (
                        <Image
                            source={{
                                uri: sessionActiveResidency.communityLogoImageURI,
                            }}
                            style={[styles.logo]}
                        />
                    )}

                <View style={styles.separator}>
                    <View style={styles.addressTop}>
                        <RegularText>{data.location.name}</RegularText>
                    </View>
                    <RegularText>
                        {data.location.streetAddress}, {data.location.city} {data.location.stateProvinceCd},{' '}
                        {data.location.zipPostalCode}
                    </RegularText>
                </View>

                <View style={styles.separator}>
                    <View style={styles.contactDetailsWrapper}>
                        <View style={[styles.contactDetailsItem, styles.contactDetailsItemIcon]}>
                            <MaterialCommunityIcons
                                name='phone'
                                size={SmarthubTheme.layout.BOTTOMTABICON}
                                color={SmarthubTheme.colors.navy}
                            />
                        </View>
                        <View style={styles.contactDetailsItem}>
                            <PhoneLink {...testProps('telephone-link')} phoneNumber={data.location.locationPhone} />
                        </View>
                    </View>
                </View>

                <View style={[styles.separator]}>
                    <View style={styles.officeHoursHeading}>
                        <RegularText {...testProps('office-hours')}>Office Hours</RegularText>
                    </View>

                    {data.officeHours?.map((officeHours: OfficeHoursDo, index) => {
                        return (
                            <View style={styles.officeHoursDayWrapper} key={`officeHours${index}`}>
                                <View style={styles.officeHoursDay}>
                                    <RegularBoldText>{getDayOfWeekByCode(officeHours.dayCd)}</RegularBoldText>
                                </View>
                                <View style={styles.officeHoursTime}>
                                    <RegularText>
                                        {_writeOfficeHours(officeHours.startHour, officeHours.endHour)}
                                    </RegularText>
                                </View>
                            </View>
                        );
                    })}
                </View>

                {data.awards?.length > 0 && (
                    <View style={styles.awardImages}>
                        {data.awards?.map((award, index) => {
                            return (
                                <Image
                                    key={`award-image-${index}`}
                                    style={styles.awardImage}
                                    source={{
                                        uri: award.colour2ImageFilePath,
                                    }}
                                />
                            );
                        })}
                    </View>
                )}
            </View>
        </KeyboardAwareScrollView>
    );
};

export default observer(CommunityInfo);

const styles = StyleSheet.create({
    container: {
        height: 'auto',
        width: '100%',
        paddingRight: 12,
        paddingBottom: 12,
        paddingLeft: 12,
        justifyContent: 'space-between',
    },
    separator: {
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    addressTop: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    contactDetailsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    contactDetailsItem: {
        flex: 1,
        marginTop: 0,
    },
    contactDetailsItemIcon: {
        maxWidth: 30,
        marginTop: -3,
    },
    officeHoursHeading: {
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    officeHoursDayWrapper: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 230,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    officeHoursDay: {
        flex: 1,
        alignItems: 'flex-start',
    },
    officeHoursTime: {
        flex: 1,
        alignItems: 'flex-end',
    },
    awardImages: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT,
    },
    awardImage: {
        minWidth: 45,
        height: 45,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
        resizeMode: 'contain',
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
    logo: {
        height: 100,
        width: 150,
        margin: SmarthubTheme.layout.SURROUNDMARGIN,
        resizeMode: 'contain',
    },
});
