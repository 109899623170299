import { DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { Image, Platform, StyleSheet, Text, View, ViewStyle } from 'react-native';

import CustomDrawerItem from './CustomDrawerItem';
import QuickActionDrawerItems from './QuickActionDrawerItems';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { Surface, useTheme } from 'react-native-paper';
import { observer } from 'mobx-react-lite';

import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useStore } from 'contexts/StoreContextProvider';
import { globalStyles } from 'theme/style/GlobalStyles';
import DrawerIconWithNotifications from 'components/notifications/DrawerIconWithNotifications';
import { navigationRoutes } from 'utils/NavigationUtils';
import { testProps } from 'components/ComponentTypes';

type DrawerLabel = {
    color: string;
    focused: boolean;
};
type DrawerIcon = DrawerLabel & {
    size: number;
};

const CustomDrawerFC: React.FC<DrawerContentComponentProps> = observer((props: DrawerContentComponentProps) => {
    const { userSessionStore } = useStore();
    const { hasSessionActiveResidency } = userSessionStore;

    const theme = useTheme();
    const fullExperience = useResponsiveContext().useFullExperience;
    const drawerIconSize = fullExperience ? 24 : 40;

    const focusedColor = theme.colors.lightblue;
    const unfocusedColor = theme.colors.lightgrey;

    const smarthubLogoIcon = () => (
        <Image
            source={
                fullExperience
                    ? require('../../../assets/images/smarthub_logo.png')
                    : require('../../../assets/images/smarthub_logo_stacked.png')
            }
            resizeMode={'contain'}
            style={fullExperience ? styles.smartHubLogo : styles.smartHubLogoStacked}
        />
    );

    const homeIcon = ({ focused }: DrawerIcon) => (
        //https://icons.expo.fyi/MaterialIcons/home
        <DrawerIconWithNotifications
            iconSize={drawerIconSize}
            identifier={'home'}
            notificationTypes={[
                'MaintenanceRequestUpdate',
                'MaintenanceRequestCorrespondence',
                'Statement',
                'ElectronicContract',
                'SmartPackage',
            ]}>
            <MaterialIcons
                name='home'
                size={drawerIconSize}
                color={focused ? focusedColor : unfocusedColor}
                {...testProps('home-icon')}
            />
        </DrawerIconWithNotifications>
    );

    const homeLabel = fullExperience ? navigationRoutes.homeRoutes.home : '';

    const noticesIcon = ({ focused }: DrawerIcon) => (
        //https://icons.expo.fyi/MaterialCommunityIcons/clipboard-text
        <DrawerIconWithNotifications
            iconSize={drawerIconSize}
            notificationTypes={['CommunityNotice']}
            identifier={'notices'}>
            <MaterialCommunityIcons
                name='clipboard-text'
                size={drawerIconSize}
                color={focused ? focusedColor : unfocusedColor}
                {...testProps('notices-icon')}
            />
        </DrawerIconWithNotifications>
    );

    const noticesLabel = fullExperience ? navigationRoutes.noticesRoutes.community : '';

    const notificationsIcon = ({ focused }: DrawerIcon) => (
        <DrawerIconWithNotifications iconSize={drawerIconSize} notificationTypes={'All'} identifier={'notification'}>
            <MaterialIcons
                name='notifications'
                size={drawerIconSize}
                color={focused ? focusedColor : unfocusedColor}
                {...testProps('notifications-icon')}
            />
        </DrawerIconWithNotifications>
    );

    const notificationsLabel = fullExperience
        ? ({ color }: DrawerLabel) => (
              <View style={styles.badgedtext}>
                  <Text
                      style={
                          [
                              SmarthubTheme.typography.heading3,
                              {
                                  color: color,
                              },
                          ] as ViewStyle
                      }>
                      Notifications
                  </Text>
              </View>
          )
        : '';

    const moreIcon = ({ focused }: DrawerIcon) => (
        //https://icons.expo.fyi/MaterialIcons/more-horiz
        <MaterialIcons
            name='more-horiz'
            size={drawerIconSize}
            color={focused ? focusedColor : unfocusedColor}
            {...testProps('more-icon')}
        />
    );

    const moreLabel = fullExperience ? navigationRoutes.moreRoutes.more : '';

    if (!hasSessionActiveResidency) return null;

    return (
        <Surface style={[styles.surface, { backgroundColor: theme.colors.background }]}>
            <DrawerContentScrollView
                scrollEnabled={false}
                {...props}
                contentContainerStyle={fullExperience ? styles.containerFull : styles.container}>
                <View style={[globalStyles.sectionSpaceAbove, globalStyles.doubleSpaceBelow]}>
                    <CustomDrawerItem
                        {...props}
                        label={''}
                        icon={smarthubLogoIcon}
                        route={navigationRoutes.homeRoutes.home}
                    />
                </View>
                <CustomDrawerItem
                    {...props}
                    label={homeLabel}
                    icon={homeIcon}
                    route={navigationRoutes.homeRoutes.home}
                />
                <CustomDrawerItem
                    {...props}
                    label={noticesLabel}
                    icon={noticesIcon}
                    route={navigationRoutes.noticesRoutes.notices}
                />
                <CustomDrawerItem
                    {...props}
                    label={notificationsLabel}
                    icon={notificationsIcon}
                    route={navigationRoutes.notificationRoutes.notifications}
                />
                <CustomDrawerItem
                    {...props}
                    label={moreLabel}
                    icon={moreIcon}
                    route={navigationRoutes.moreRoutes.more}
                />
                {hasSessionActiveResidency && (
                    <>
                        <QuickActionDrawerItems {...props} />
                    </>
                )}
            </DrawerContentScrollView>
        </Surface>
    );
});
const CustomDrawer = (props: DrawerContentComponentProps) => {
    return <CustomDrawerFC {...props} />;
};

export default CustomDrawer;

const styles = StyleSheet.create({
    surface: {
        flex: Platform.OS === 'web' ? undefined : 1,
        elevation: Platform.OS === 'web' ? 0 : SmarthubTheme.layout.SURFACEELEVATION,
        marginVertical: SmarthubTheme.layout.SURROUNDMARGIN,
        marginLeft: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 4,
        borderWidth: 0.1,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.lightgrey,
    },
    container: {
        flex: 1,
        alignItems: 'center',
    },
    containerFull: {
        flex: 1,
        alignItems: 'flex-start',
    },
    smartHubLogo: {
        height: 20,
        width: 210,
    },
    smartHubLogoStacked: {
        height: 50,
        width: 80,
        paddingBottom: 32,
    },
    badgedtext: {
        flexDirection: 'row',
    },
});
