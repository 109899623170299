import React, { useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import { navigationRoutes } from 'utils/NavigationUtils';
import PaymentReview from 'components/payments/PaymentReview';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { useZegoPaymentMutation } from 'queries/hooks/useZegoPaymentMutation';
import { useQueryClient } from 'react-query';
import { logError } from 'utils/logging/Logger';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import {
    buildZegoPaymentInfoFromFormValues,
    errorContainsIneligibleDebitCardErrorMessage,
    getProcessingFee,
    getProcessingFeePercentage,
    INELIGIBLE_DEBIT_CARD_ERROR_CODE,
} from 'utils/PayLeaseZegoUtils';
import { SubmitZegoPaymentResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitZegoPaymentResultV1';
import { getRouteParam } from 'utils/routeUtils';
import { generatePaymentMethodsQueryKey } from 'queries/hooks/usePaymentMethods';
import { globalStyles } from 'theme/style/GlobalStyles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Button from 'components/primitives/Button';
import Section from 'components/primitives/Section';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { ConfirmationType } from 'screens/home/transactions/PaymentConfirmation';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useCheckPaymentStatus } from 'queries/hooks/useCheckPaymentStatus';

const ZegoPaymentReview: React.FC = observer(() => {
    const navigation = useNavigation();
    const route = useRoute();
    const { userSessionStore, uiStore, transactionsStore } = useStore();
    const useDrawerNavigation = useResponsiveContext().useDrawerNavigation;
    const { sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();
    const paymentFormValues = getRouteParam(route, 'paymentFormValues');
    const paymentMethodQueryKey = generatePaymentMethodsQueryKey(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residentId,
    );
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [submitPaymentResult, setSubmitPaymentResult] = React.useState<SubmitZegoPaymentResultV1 | undefined>(
        undefined,
    );
    const enableCheckPaymentStatus =
        !!submitPaymentResult &&
        !!submitPaymentResult.paymentResult &&
        submitPaymentResult.paymentResult.status === 'Pending';
    const { queryKey: checkPaymentStatusQueryKey, query: checkPaymentStatusQuery } = useCheckPaymentStatus(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residentId,
        submitPaymentResult?.paymentResult.paymentRefId || '',
        submitPaymentResult?.paymentResult.paymentRequestId || 0,
        enableCheckPaymentStatus,
        uiStore,
        {
            enabled: enableCheckPaymentStatus,
            refetchInterval: 5000,
            retry: 24,
            onSuccess: (data: SubmitZegoPaymentResultV1) => {
                if (data.paymentResult.status === 'Success' || data.paymentResult.status === 'Fail') {
                    _processNonPendingPayment(data);
                    setSubmitPaymentResult(undefined);
                }
            },
            onError: error => {
                setIsLoading(false);
                logError(ERROR_LOGGER, error);
            },
        },
    );
    const zegoPaymentMutation = useZegoPaymentMutation(uiStore, sessionActiveResidency);

    const zegoPaymentInfo = buildZegoPaymentInfoFromFormValues(
        paymentFormValues,
        transactionsStore.uniquePaymentReferenceId,
        sessionActiveResidency.payleaseAccount,
        false,
    );

    const _processNonPendingPayment = (result: SubmitZegoPaymentResultV1) => {
        if (result.paymentResult.status === 'Pending') return;
        const confirmationType: ConfirmationType =
            result.paymentResult.status === 'Success' ? 'PaymentReceived' : 'PaymentFailed';
        const paymentFailedMessage = confirmationType === 'PaymentFailed' ? result.paymentResult.failMessage : '';

        if (useDrawerNavigation) {
            navigation.navigate(navigationRoutes.homeRoutes.paymentConfirmation, {
                confirmationType: confirmationType,
                paymentFailedMessage: paymentFailedMessage,
            });
        } else {
            navigation.navigate(navigationRoutes.loggedInRoutes.paymentConfirmationModal, {
                confirmationType: confirmationType,
                paymentFailedMessage: paymentFailedMessage,
            });
        }
        queryClient.invalidateQueries(paymentMethodQueryKey);
        transactionsStore.setUniquePaymentReferenceId();
        setIsLoading(false);
    };

    const _submitPayment = () => {
        if (zegoPaymentInfo && zegoPaymentInfo.payByMethod) {
            const _onSuccess = (result: SubmitZegoPaymentResultV1) => {
                if (result.paymentResult.status === 'Pending') {
                    setSubmitPaymentResult(result);
                } else {
                    _processNonPendingPayment(result);
                }
            };

            const _onError = (error: string) => {
                setIsLoading(false);
                let errorCode = undefined;
                if (errorContainsIneligibleDebitCardErrorMessage(error)) {
                    errorCode = INELIGIBLE_DEBIT_CARD_ERROR_CODE;
                } else {
                    logError(ERROR_LOGGER, error);
                }

                if (useDrawerNavigation) {
                    navigation.navigate(navigationRoutes.homeRoutes.paymentConfirmation, {
                        confirmationType: 'PaymentFailed',
                        errorCode: errorCode,
                    });
                } else {
                    navigation.navigate(navigationRoutes.loggedInRoutes.paymentConfirmationModal, {
                        confirmationType: 'PaymentFailed',
                        errorCode: errorCode,
                    });
                }
            };

            setIsLoading(true);

            zegoPaymentMutation.mutate(zegoPaymentInfo, {
                onSuccess: _onSuccess,
                onError: error => _onError('Encounter error when making payment - ' + JSON.stringify(error)),
            });
        }
    };

    useEffect(() => {
        isLoading ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [isLoading, uiStore.activityLoaderVisible]);

    return !zegoPaymentInfo || !zegoPaymentInfo.paymentMethod ? null : (
        <SafeAreaView style={globalStyles.container}>
            <KeyboardAwareScrollView
                keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                enableResetScrollToCoords={false}>
                <View style={styles.contentContainerStyle}>
                    <PaymentReview
                        amount={zegoPaymentInfo.amount || 0}
                        paymentMethod={zegoPaymentInfo.paymentMethod}
                        fee={getProcessingFee(zegoPaymentInfo)}
                        feePercentage={getProcessingFeePercentage(zegoPaymentInfo)}
                    />
                    <Section style={[styles.button]}>
                        <Button
                            inactive={uiStore.activityLoaderVisible || !sessionActiveResidency}
                            onClick={_submitPayment}>
                            Submit Payment
                        </Button>
                    </Section>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
});

export default ZegoPaymentReview;

const styles = StyleSheet.create({
    contentContainerStyle: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    button: {
        width: '100%',
    },
});
