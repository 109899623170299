import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { GetResidentCommSubscriptionsResultV1 } from './GetResidentCommSubscriptionsResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.TogglePaperlessStatementCommandV1
 */
export interface TogglePaperlessStatementCommandV1
    extends AbstractResidentAuthenticatedCommand<GetResidentCommSubscriptionsResultV1> {
    residencyId: number;
    toggleOnYn: boolean;
}

export function createTogglePaperlessStatementCommandV1(): TogglePaperlessStatementCommandV1 {
    return {
        residencyId: 0,
        toggleOnYn: false,
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.TogglePaperlessStatementCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
