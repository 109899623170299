import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import {
    DeephansizedRegularText,
    FormLabelText,
    Heading2,
    RegularBoldText,
    RegularText,
} from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as yup from 'yup';
import { Formik, FormikErrors } from 'formik';
import { globalStyles } from 'theme/style/GlobalStyles';
import { action } from 'mobx';
import InputRadio from 'components/input/InputRadio';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import { testProps } from 'components/ComponentTypes';
import { SmartHubResidentContactInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/requests/SmartHubResidentContactInfoV1';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { useRenewMyLeaseDataQuery } from 'queries/hooks/manageMyLease/useRenewMyLeaseDataQuery';
import { formatDateStringMonthLabel } from 'utils/DateUtils';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { Divider } from 'react-native-paper';
import { RateOption } from 'models/remotecmds/com/ocs/nirvana/businesslogic/contract/dataobjects/RateOption';
import { TagTypes } from 'components/primitives/TagTypes';
import Tag from 'components/primitives/Tag';
import Section from 'components/primitives/Section';
import { v4 as uuid } from 'uuid';
import Container from 'components/primitives/Container';
import InputString from 'components/input/InputString';
import Button from 'components/primitives/Button';
import { generateSubmitTicketCMD, useSubmitTicket } from 'screens/home/requests/RequestsHooks';
import {
    createSmarthubTicketSubmitData,
    SmarthubTicketSubmitData,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubTicketSubmitData';
import { useRenewMyLeaseMutation } from 'queries/hooks/manageMyLease/useRenewMyLeaseMutation';
import { useUpdateContactInfoMutation } from 'queries/hooks/residency/useUpdateContactInfoMutation';
import { useLogResidencyAuditEventMutation } from 'queries/hooks/residency/useLogResidencyAuditEventMutation';

type RenewMyLeaseProps = {
    navigation: StackNavigationProp<any>;
};

export type RenewMyLeaseSubmitData = {
    leaseTerm: number;
    termRate: number;
    updateInformationYn: boolean;
    contactInfos: SmartHubResidentContactInfoV1[];
};

const RenewMyLease: React.FC<RenewMyLeaseProps> = observer(({ navigation }: RenewMyLeaseProps) => {
    const { useDrawerNavigation } = useResponsiveContext();
    const { userSessionStore, uiStore } = useStore();
    const { smarthubUser, confirmActiveResidency, sessionActiveResidency, hasSessionActiveResidency } =
        userSessionStore;
    const [contactInfo, setContactInfo] = useState([] as SmartHubResidentContactInfoV1[]);
    const [leaseTermAuditLogId, setLeaseTermAuditLogId] = useState(null as number | null);

    yup.addMethod(yup.mixed, 'uniqueEmail', function (array: SmartHubResidentContactInfoV1[] = [], message) {
        return this.test('uniqueEmail', message, function (value) {
            return array.filter(item => item.email === value).length < 2;
        });
    });

    const schema = yup.object().shape({
        leaseTerm: yup.number(),
        updateInformationYn: yup.boolean(),
        contactInfos: yup.array().of(
            yup.object({
                residentId: yup.number(),
                email: yup
                    .string()
                    .email('Please provide a valid email address.')
                    .required('Please provide an email address.')
                    // @ts-ignore
                    .uniqueEmail(
                        contactInfo,
                        'Email address must be unique. This email is already in use, please provide another email address.',
                    ),
            }),
        ),
    });

    const _getConfirmScreenOption = (refNumber: string) => {
        return {
            smartDeskConfirm: true,
            header: "Thanks, we've received your renewal request!",
            desc1: `One of our Better Living Consultants will contact you soon to walk you through the process!`,
            desc2: `Your reference number is #SD${refNumber}`,
            buttonText: 'Continue',
            testID: 'continue-button',
            toHome: true,
        };
    };
    const isHomeOnNavStack = IsNavigationRouteOnStack(navigationRoutes.homeRoutes.home);
    const submitTicketMutation = useSubmitTicket(navigation, isHomeOnNavStack, _getConfirmScreenOption, () => {
        userSessionStore.reloadLeaseAdjustmentAndAlert();
    });

    const { renewMyLeaseDataQueryKey, renewMyLeaseDataResult } = useRenewMyLeaseDataQuery(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        hasSessionActiveResidency,
        uiStore,
    );
    const { data } = renewMyLeaseDataResult;

    const isMTP = data?.letterTemplateType === 'MTP';
    const isVoucher = data?.leaseType === 'VO';
    const isSingleTerm = !isMTP && data?.fixedRentMaxTerm === data?.fixedRentMinTerm;

    const renewMyLeaseMutation = useRenewMyLeaseMutation(uiStore, sessionActiveResidency);
    const updateContactInfoMutation = useUpdateContactInfoMutation(uiStore, sessionActiveResidency);
    const logResidencyAuditEventMutation = useLogResidencyAuditEventMutation(uiStore, sessionActiveResidency);

    const _getInitialFormikValues = () => {
        if (isVoucher) {
            return {
                leaseTerm: 12,
                termRate: data?.fixedRent || 0,
                updateInformationYn: false,
                contactInfos: data?.residentContactInfos || [],
            };
        } else if (isMTP) {
            return {
                leaseTerm: data?.termRates.find(r => !!r.isBestRate)?.term || 1,
                termRate: data?.termRates.find(r => !!r.isBestRate)?.monthRate || data?.termRates[0].monthRate || 0,
                updateInformationYn: false,
                contactInfos: data?.residentContactInfos || [],
            };
        } else {
            return {
                leaseTerm: data?.fixedRentMaxTerm || 1,
                termRate: data?.fixedRent || 0,
                updateInformationYn: false,
                contactInfos: data?.residentContactInfos || [],
            };
        }
    };

    const _onSubmit = action((data: RenewMyLeaseSubmitData) => {
        if (data.updateInformationYn) {
            _OnUpdateInfo(data);
        } else {
            uiStore.showActivityLoader(
                'Please wait while we generate your contract. This may take a few minutes. We appreciate your patience.',
            );
            renewMyLeaseMutation.mutate(data, {
                onSuccess: () => {
                    userSessionStore.reloadCurrentResidencyAsync(true).then(() => {
                        uiStore.hideActivityLoader();
                        useDrawerNavigation
                            ? navigation.navigate(navigationRoutes.homeRoutes.renewalSigning)
                            : navigation.navigate(navigationRoutes.loggedInRoutes.renewalSigningModal);
                    });
                },
                onError: () => {
                    uiStore.hideActivityLoader();
                },
            });
        }
    });

    const _OnUpdateInfo = (data: RenewMyLeaseSubmitData) => {
        updateContactInfoMutation.mutate(data.contactInfos);

        const ticketData: SmarthubTicketSubmitData = createSmarthubTicketSubmitData();
        ticketData.categoryTypeDv = 'RWR';
        ticketData.desc = `I am interested in renewing my lease for a ${data.leaseTerm} term, but would like to make changes to the lease information.`;
        ticketData.escalateYn = false;
        ticketData.propertyCd = sessionActiveResidency.propertyCd;
        ticketData.residencyId = sessionActiveResidency.residencyId;
        ticketData.residentId = sessionActiveResidency.residentId;

        uiStore.showActivityLoader();
        submitTicketMutation.mutate(
            generateSubmitTicketCMD(
                sessionActiveResidency.propertyCd,
                sessionActiveResidency.residencyId,
                sessionActiveResidency.residentId,
                ticketData,
            ),
        );
    };

    const _getTermRateLabel = (tr: RateOption) => {
        return `${tr.term} month${tr.term > 1 ? 's' : ''} (${formatCentsAsDollars(tr.monthRate)}/month)`;
    };

    const _getBestTermRateLabel = (tr: RateOption) => (
        <View style={[globalStyles.flexRowStart, globalStyles.flexContainer]}>
            <RegularText style={styles.inputRadioLabel}>{`${tr.term} month${
                tr.term > 1 ? 's' : ''
            } (${formatCentsAsDollars(tr.monthRate)}/month)`}</RegularText>
            <Tag tagType={TagTypes.Blue}>BEST VALUE</Tag>
        </View>
    );

    const _getMtpLeaseTermRadioOptions = () => {
        return data?.termRates.map(tr => {
            return {
                label: _getTermRateLabel(tr),
                labelOverride: tr.isBestRate ? _getBestTermRateLabel(tr) : undefined,
                value: `${tr.term}`,
                testID: 'term-rate-input',
                accessibilityLabel: 'term-rate-input',
            };
        });
    };

    const _getSTPLeaseTermRadioOptions = () => {
        const result = [];
        if (data?.fixedRentMinTerm && data.fixedRentMaxTerm) {
            for (let i = data?.fixedRentMinTerm; i <= data?.fixedRentMaxTerm; i++) {
                result.push({
                    label: `${i} month${i > 1 ? 's' : ''}`,
                    value: `${i}`,
                    testID: 'term-rate-input',
                    accessibilityLabel: 'term-rate-input',
                });
            }
        }
        return result;
    };

    const isInfoCorrectOptions = [
        {
            label: 'Yes, the information is correct.',
            value: 'yes',
            testID: 'info-correct',
            accessibilityLabel: 'Yes, the information is correct.',
        },
        {
            label: 'No, I would like to change something.',
            value: 'no',
            testID: 'info-correct',
            accessibilityLabel: 'No, I would like to change something.',
        },
    ];

    //handle hardware or browser back button
    useEffect(() => {
        if (!sessionActiveResidency.allowRenewal) {
            navigation.navigate(navigationRoutes.homeRoutes.home);
        }
    }, []);

    useEffect(() => {
        confirmActiveResidency(navigation);
    }, [smarthubUser.authorizedResidencies]);

    useEffect(() => {
        if (data?.residentContactInfos) {
            setContactInfo(data.residentContactInfos);
        }
    }, [data]);

    if (!data) return null;

    return (
        <Formik
            validateOnMount={false}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={_getInitialFormikValues()}
            enableReinitialize={true}
            onSubmit={_onSubmit}
            validationSchema={schema}>
            {formikHelpers => (
                <SafeAreaView style={globalStyles.container}>
                    <KeyboardAwareScrollView
                        keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                        enableResetScrollToCoords={false}>
                        <View style={globalStyles.container}>
                            <Section>
                                {!isSingleTerm && (
                                    <Heading2 {...testProps('message-1')}>
                                        {`Your new lease will start on ${formatDateStringMonthLabel(data?.renewalDt)}${
                                            !isVoucher
                                                ? '. What is your desired lease term?'
                                                : ' and your lease type requires a 12 month term.'
                                        }`}
                                    </Heading2>
                                )}

                                {isSingleTerm && (
                                    <Heading2 {...testProps('message-1')}>
                                        {`Your new ${
                                            data?.fixedRentMaxTerm
                                        } month lease will start on ${formatDateStringMonthLabel(data?.renewalDt)}`}
                                    </Heading2>
                                )}
                            </Section>

                            {data?.showEarlyBirdRateYn && (
                                <Section>
                                    <RegularBoldText
                                        style={styles.orangeText}>{`Renew on or before ${formatDateStringMonthLabel(
                                        data?.earlyBirdExpiryDt,
                                    )} to get a special early bird rate! Otherwise, you will be billed our standard rates.`}</RegularBoldText>
                                </Section>
                            )}

                            {isMTP && !isVoucher && (
                                <Section style={globalStyles.sectionSpaceBelow}>
                                    <InputRadio
                                        options={_getMtpLeaseTermRadioOptions()}
                                        initialValue={formikHelpers.values.leaseTerm.toString()}
                                        onChange={selected => {
                                            logResidencyAuditEventMutation.mutate(
                                                {
                                                    auditLogId: leaseTermAuditLogId,
                                                    eventType: 'SmarthubRenewSelectTerm',
                                                    currentValue: '',
                                                    newValue: selected,
                                                },
                                                {
                                                    onSuccess: data => {
                                                        if (data.auditLogId) {
                                                            setLeaseTermAuditLogId(data.auditLogId);
                                                        }
                                                    },
                                                },
                                            );
                                            formikHelpers.setFieldValue('leaseTerm', Number(selected));
                                            formikHelpers.setFieldValue(
                                                'termRate',
                                                data?.termRates.find(tr => tr.term === Number(selected))?.monthRate,
                                            );
                                        }}
                                        style={'small'}
                                    />
                                </Section>
                            )}

                            {(!isMTP || isVoucher) && !!data?.fixedRent && (
                                <>
                                    <Section>
                                        <Container borderStyle={'grey'}>
                                            <View style={[globalStyles.flexColumnCenter, globalStyles.innerContainer]}>
                                                <RegularBoldText
                                                    style={
                                                        styles.orangeText
                                                    }>{`Your renewal rate: ${formatCentsAsDollars(
                                                    data?.fixedRent,
                                                )} `}</RegularBoldText>
                                            </View>
                                        </Container>
                                    </Section>

                                    {!isVoucher && !isSingleTerm && (
                                        <Section style={globalStyles.sectionSpaceBelow}>
                                            <InputRadio
                                                options={_getSTPLeaseTermRadioOptions()}
                                                initialValue={formikHelpers.values.leaseTerm.toString()}
                                                onChange={selected => {
                                                    logResidencyAuditEventMutation.mutate(
                                                        {
                                                            auditLogId: leaseTermAuditLogId,
                                                            eventType: 'SmarthubRenewSelectTerm',
                                                            currentValue: '',
                                                            newValue: selected,
                                                        },
                                                        {
                                                            onSuccess: data => {
                                                                if (data.auditLogId) {
                                                                    setLeaseTermAuditLogId(data.auditLogId);
                                                                }
                                                            },
                                                        },
                                                    );

                                                    formikHelpers.setFieldValue('leaseTerm', Number(selected));
                                                    formikHelpers.setFieldValue('termRate', data?.fixedRent);
                                                }}
                                                style={'small'}
                                            />
                                        </Section>
                                    )}
                                </>
                            )}

                            {data?.monthlyCharges && data?.monthlyCharges.length > 0 && (
                                <>
                                    <Divider style={globalStyles.sectionSpaceAbove} />

                                    <Section>
                                        <Heading2 {...testProps('message-2')} style={globalStyles.doubleSpaceBelow}>
                                            Additional Monthly Fees:
                                        </Heading2>
                                    </Section>

                                    <Section>
                                        {data?.monthlyCharges.map(monthlyCharge => (
                                            <View key={uuid()} style={globalStyles.singleSpaceBelow}>
                                                <View
                                                    style={[
                                                        globalStyles.flexRowSpaceBetween,
                                                        styles.monthlyChargeContainer,
                                                    ]}>
                                                    <RegularText>{monthlyCharge.label}</RegularText>
                                                    {!!monthlyCharge.amount && (
                                                        <RegularText>
                                                            {formatCentsAsDollars(monthlyCharge.amount || 0)}
                                                        </RegularText>
                                                    )}
                                                </View>
                                                {monthlyCharge.breakDowns &&
                                                    monthlyCharge.breakDowns.length > 0 &&
                                                    monthlyCharge.breakDowns.map(bd => (
                                                        <RegularText key={bd} style={globalStyles.innerMarginLeft}>
                                                            {bd}
                                                        </RegularText>
                                                    ))}
                                            </View>
                                        ))}
                                        {data?.monthlyCharges && data.monthlyCharges.length > 0 && (
                                            <View style={globalStyles.singleSpaceBelow}>
                                                <View
                                                    style={[
                                                        globalStyles.flexRowSpaceBetween,
                                                        styles.monthlyChargeContainer,
                                                    ]}>
                                                    <RegularBoldText>{`${
                                                        data.hasCRSCharges ? '*' : ''
                                                    }Total Monthly Charges`}</RegularBoldText>
                                                    <RegularBoldText>
                                                        {formatCentsAsDollars(
                                                            data?.monthlyCharges.reduce((prev, curr) => {
                                                                return prev + (curr.amount || 0);
                                                            }, 0) + formikHelpers.values.termRate,
                                                        )}
                                                    </RegularBoldText>
                                                </View>
                                            </View>
                                        )}
                                    </Section>

                                    {data.hasCRSCharges && (
                                        <Section>
                                            <DeephansizedRegularText>
                                                *Does not include utility fees
                                            </DeephansizedRegularText>
                                        </Section>
                                    )}
                                </>
                            )}

                            {!sessionActiveResidency.onAutoPay && !!data?.autoPayDiscount && (
                                <Section>
                                    <Container borderStyle={'grey'}>
                                        <View style={[globalStyles.flexColumnCenter, globalStyles.innerContainer]}>
                                            <RegularBoldText style={styles.orangeText}>{`*Save ${formatCentsAsDollars(
                                                data?.autoPayDiscount,
                                            )} a month when you use Recurring AutoPay to pay your rent!`}</RegularBoldText>
                                        </View>
                                    </Container>
                                </Section>
                            )}

                            <Divider style={globalStyles.sectionSpaceAbove} />

                            <Section>
                                <Heading2 {...testProps('message-3')}>
                                    The following information will be on your new lease:
                                </Heading2>
                            </Section>

                            {data?.leaseInfoList.map(li => (
                                <Section key={uuid()}>
                                    <DeephansizedRegularText style={globalStyles.singleSpaceBelow}>
                                        {li.label}
                                    </DeephansizedRegularText>
                                    {li.descList.map((desc, index) => (
                                        <RegularText key={index}>{desc}</RegularText>
                                    ))}
                                </Section>
                            ))}

                            <Section>
                                <Heading2 {...testProps('message-4')}>Is the information above correct?</Heading2>
                            </Section>

                            <Section style={globalStyles.sectionSpaceBelow}>
                                <InputRadio
                                    options={isInfoCorrectOptions}
                                    initialValue={formikHelpers.values.updateInformationYn ? 'no' : 'yes'}
                                    onChange={selected => {
                                        formikHelpers.setFieldValue(
                                            'updateInformationYn',
                                            selected === 'yes' ? false : true,
                                        );
                                    }}
                                    style={'small'}
                                />
                            </Section>

                            {/*//todo: validate unique email address*/}
                            {formikHelpers.values.contactInfos.map((value, index) => (
                                <Section key={index}>
                                    <FormLabelText
                                        style={
                                            globalStyles.singleSpaceBelow
                                        }>{`Confirm Email Address for ${value.name}`}</FormLabelText>
                                    <InputString
                                        keyboardType={'email-address'}
                                        maxLength={50}
                                        initialValue={formikHelpers.values.contactInfos[index].email}
                                        onChange={value => {
                                            formikHelpers.setFieldValue(`contactInfos.${index}.email`, value);
                                            setContactInfo(prevState => {
                                                prevState[index].email = value;
                                                return [...prevState];
                                            });
                                        }}
                                        error={
                                            !!(
                                                formikHelpers.errors.contactInfos &&
                                                (
                                                    formikHelpers.errors.contactInfos[
                                                        index
                                                    ] as FormikErrors<SmartHubResidentContactInfoV1>
                                                )?.email
                                            )
                                        }
                                        errorMessage={
                                            formikHelpers.errors.contactInfos
                                                ? (
                                                      formikHelpers.errors.contactInfos[
                                                          index
                                                      ] as FormikErrors<SmartHubResidentContactInfoV1>
                                                  )?.email
                                                : undefined
                                        }
                                    />
                                </Section>
                            ))}

                            <Section>
                                <Button
                                    inactive={uiStore.activityLoaderVisible || sessionActiveResidency.adminYn}
                                    onClick={formikHelpers.handleSubmit}
                                    {...testProps('submit-renewal-button')}>
                                    {formikHelpers.values.updateInformationYn ? 'Submit Renewal Request' : 'Continue'}
                                </Button>
                            </Section>
                        </View>
                    </KeyboardAwareScrollView>
                </SafeAreaView>
            )}
        </Formik>
    );
});

export default RenewMyLease;

const styles = StyleSheet.create({
    orangeText: {
        color: SmarthubTheme.colors.orange,
    },
    inputRadioLabel: {
        flex: 1,
        maxWidth: SmarthubTheme.layout.GRIDINCREMENT * 30,
        marginRight: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    monthlyChargeContainer: {
        maxWidth: SmarthubTheme.layout.GRIDINCREMENT * 60,
    },
});
