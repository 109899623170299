import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';

/***
 * return true when client platform is web *and* desktop
 */
export const isDesktopNotMobile = () => {
    return Platform.OS === 'web' && !isMobile;
};

/***
 * return true when client platform is web *and* mobile
 */
export const isDesktopAndMobile = () => {
    return Platform.OS === 'web' && isMobile;
};
