import React, { useEffect, useState } from 'react';
import { SectionListData, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { moreOptions, moreOptionsWithActiveResidency, SectionsScreensMenu } from 'components/lists/ScreensMenu';
import { useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import SmarthubVersionInfo from 'components/cards/SmarthubVersionInfo';
import { useNavigation } from '@react-navigation/native';
import { globalStyles } from 'theme/style/GlobalStyles';
import { ListItemProps } from 'components/lists/SmarthubListItem';
import { navigationRoutes } from 'utils/NavigationUtils';

const More = observer(() => {
    const theme = useTheme();
    const { userSessionStore } = useStore();
    const { hasSessionActiveResidency } = userSessionStore;
    const navigation = useNavigation();
    const isAwayTimesAllowed = userSessionStore.isFeatureEnabled('ParcelPending');

    const [options, setOptions] = useState<Array<SectionListData<ListItemProps>>>([]);

    useEffect(() => {
        let tempOptions: Array<SectionListData<ListItemProps>>;
        hasSessionActiveResidency ? (tempOptions = moreOptionsWithActiveResidency) : (tempOptions = moreOptions);
        if (tempOptions && tempOptions[0]?.data[1]?.title === 'Away Times') {
            tempOptions[0].data[1].hidden = !isAwayTimesAllowed;
        }
        setOptions([...tempOptions]);
    }, [hasSessionActiveResidency]);

    if (!hasSessionActiveResidency) {
        return null;
    }

    return (
        <SectionsScreensMenu
            options={options}
            contentContainerStyle={[
                {
                    backgroundColor: theme.colors.background,
                    marginHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
                },
            ]}
            footerComponent={() => (
                <View style={globalStyles.sectionSpaceAround}>
                    <SmarthubVersionInfo />
                </View>
            )}
            onRefresh={() => navigation.navigate(navigationRoutes.moreRoutes.more)}
        />
    );
});
export default More;
