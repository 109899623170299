import React, { useEffect } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { FormLabelText, Heading1 } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import Section from 'components/primitives/Section';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useRoute } from '@react-navigation/native';
import { getRouteParams } from 'utils/routeUtils';
import AddressContainer from 'components/noticeToVacate/AddressContainer';
import {
    createSmarthubAddressV1,
    SmarthubAddressV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAddressV1';
import { AddressValidationStatus } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/addressvalidation/dataobjects/AddressValidationStatus';
import { runInAction } from 'mobx';

type AddressPickerProps = {
    navigation: StackNavigationProp<any>;
};

const AddressPicker: React.FC<AddressPickerProps> = observer(({ navigation }: AddressPickerProps) => {
    const route = useRoute();
    const { title, addressEntered, addressValidated, residentId, nextScreenName } = getRouteParams(route);
    const { userSessionStore, ntvStore } = useStore();
    const { smarthubUser, confirmActiveResidency } = userSessionStore;
    const { useDrawerNavigation } = useResponsiveContext();

    const onAddressSelected = (address: SmarthubAddressV1, addressValidationStatusDv: AddressValidationStatus) => {
        const recipient = ntvStore.ntvRequestSubmitData.sodaRecipientList.find(r => r.residentId === residentId);
        if (recipient) {
            runInAction(() => {
                recipient.mailToAddress = createSmarthubAddressV1();
                recipient.mailToAddress.streetLine1 = address.streetLine1;
                recipient.mailToAddress.city = address.city;
                recipient.mailToAddress.state = address.state;
                recipient.mailToAddress.zip = address.zip;
                recipient.mailToAddress.country = address.country;
                recipient.mailToAddress.validationStatusDv = addressValidationStatusDv;
                recipient.mailToAddress.validationOverrideUcd = 'Smarthub';
                navigation.replace(nextScreenName);
            });
        }
    };
    useEffect(() => {
        confirmActiveResidency(navigation);
        if (title) {
            navigation.setParams({ title: title });
        }
    }, [smarthubUser.authorizedResidencies]);

    return (
        <SafeAreaView style={globalStyles.container}>
            <KeyboardAwareScrollView
                keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                enableResetScrollToCoords={false}>
                <View style={globalStyles.container}>
                    {!useDrawerNavigation && !!title && (
                        <Heading1 style={globalStyles.sectionSpaceBelow}>{title}</Heading1>
                    )}

                    <FormLabelText>{`Which address did you mean?`}</FormLabelText>

                    <Section>
                        <AddressContainer
                            label={'Address You Entered:'}
                            address={addressEntered}
                            onClicked={() => onAddressSelected(addressEntered, 'Forced')}
                        />
                    </Section>

                    <Section>
                        <AddressContainer
                            label={'Address Found:'}
                            address={addressValidated}
                            onClicked={() => onAddressSelected(addressValidated, 'Validated')}
                        />
                    </Section>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
});

export default AddressPicker;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});
