import { useEffect } from 'react';
import { NavigationProp } from '@react-navigation/native';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';

const useBeforeLeave = (navigation: NavigationProp<any>, action: () => void) => {
    useEffect(() => {
        return navigation.addListener('beforeRemove', e => {
            e.preventDefault();
            action();
            navigation.dispatch(e.data.action);
        });
    }, []);
};

export default useBeforeLeave;
