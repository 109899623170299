import React, { useState } from 'react';
import { useTheme } from 'react-native-paper';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
    ButtonText,
    ButtonTextLarge,
    DeemphansizedTextSmall,
    DeephansizedRegularText,
    ExtraLargeBoldText,
    ExtraLargeText,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    LabelText,
    LargeBoldText,
    LargeText,
    MediumBoldText,
    MediumText,
    RegularBoldText,
    RegularText,
    SmallBoldText,
    SmallerBoldText,
    SmallerText,
    SmallText,
} from 'components/primitives/StyledText';
import Container from 'components/primitives/Container';
import Button from 'components/primitives/Button';
import Hyperlink from 'components/primitives/Hyperlink';
import Tag from 'components/primitives/Tag';
import { TagTypes } from 'components/primitives/TagTypes';
import InputCheckbox from 'components/input/InputCheckbox';
import Dialog from 'components/primitives/Dialog';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useNavigation } from '@react-navigation/native';
import InputDate from 'components/input/InputDate';
import Anchor from 'components/primitives/Anchor';
import { testProps } from 'components/ComponentTypes';
import { navigationRoutes } from 'utils/NavigationUtils';
import InputCurrency from 'components/input/InputCurrency';

const PrimitivesScreen = () => {
    const navigation = useNavigation();
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [currencyValue, setCurrencyValue] = useState<number | undefined>(99.99);

    const _toggleDialog = () => {
        setDialogVisible(!dialogVisible);
    };

    const theme = useTheme();

    return (
        <SafeAreaView style={globalStyles.container}>
            <KeyboardAwareScrollView>
                <View style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                    <Heading2>Primitives</Heading2>

                    <Heading3>Dialog</Heading3>

                    <Hyperlink
                        {...testProps('open-dialog')}
                        onClick={() => {
                            _toggleDialog();
                        }}
                    >
                        Toggle Dialog
                    </Hyperlink>

                    {dialogVisible && (
                        <Dialog
                            visible={dialogVisible}
                            onDismiss={() => {
                                setDialogVisible(false);
                            }}
                        >
                            <MaterialCommunityIcons
                                name='alert-circle'
                                size={SmarthubTheme.layout.ICONWIDTH}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <View style={styles.dialogBody}>
                                <SmallText>
                                    If there is an emergency and you need our immediate assistance, please contact us
                                    now!
                                </SmallText>
                            </View>
                            <Button>This is a button</Button>
                        </Dialog>
                    )}

                    <Hyperlink
                        {...testProps('open-confirm-screen')}
                        onClick={() => {
                            navigation.navigate(navigationRoutes.homeRoutes.confirmScreen, {
                                header: `Thanks, we've received your request!`,
                                desc1: `Your reference number is 123456`,
                                desc2: `We'll get back to you soon with an update.`,
                                buttonText: 'Continue',
                                testID: 'continue-button',
                                accessibilityLabel: 'continue-button',
                                smartDeskConfirm: true,
                            });
                        }}
                    >
                        Toggle Confirm Screen
                    </Hyperlink>

                    <Heading3>Input Currency</Heading3>
                    <View style={globalStyles.singleSpaceAround}>
                        <InputCurrency
                            value={currencyValue}
                            onChange={value => {
                                setCurrencyValue(value);
                            }}
                        />
                    </View>

                    <Heading3>Containers</Heading3>

                    <Container {...testProps('container')} width={250} height={250}>
                        <RegularText>Container</RegularText>
                    </Container>

                    <Container {...testProps('container-border-grey')} width={250} height={250} borderStyle={'grey'}>
                        <RegularText>Container with grey border</RegularText>
                    </Container>

                    <Container
                        {...testProps('container-border-orange')}
                        width={250}
                        height={250}
                        borderStyle={'orange'}
                    >
                        <RegularText>Container with orange border</RegularText>
                    </Container>

                    <Heading3>Buttons</Heading3>
                    <View style={globalStyles.singleSpaceAround}>
                        <Button {...testProps('button-primary')}>Primary Button</Button>
                    </View>
                    <View style={globalStyles.singleSpaceAround}>
                        <Button {...testProps('button-secondary')} buttonType={'secondary'}>
                            Secondary Button
                        </Button>
                    </View>
                    <View style={globalStyles.singleSpaceAround}>
                        <Button {...testProps('button-inactive')} inactive={true}>
                            Inactive Button
                        </Button>
                    </View>
                    <View style={globalStyles.singleSpaceAround}>
                        <Button {...testProps('button-bounce')} animationType={'bounce'}>
                            Bounce Button
                        </Button>
                    </View>
                    <View style={globalStyles.singleSpaceAround}>
                        <Button {...testProps('button-no-animation')} animationType={'none'}>
                            No animation Button
                        </Button>
                    </View>
                    <View style={globalStyles.singleSpaceAround}>
                        <Button
                            {...testProps('button-left-icon')}
                            icon={{
                                iconID: 'email',
                                color: SmarthubTheme.colors.white,
                            }}
                        >
                            Button with left icon
                        </Button>
                    </View>
                    <View style={globalStyles.singleSpaceAround}>
                        <Button
                            {...testProps('button-right-icon')}
                            icon={{
                                iconID: 'email',
                                color: SmarthubTheme.colors.white,
                                alignment: 'right',
                            }}
                        >
                            Button with right icon
                        </Button>
                    </View>
                    <Heading3>Date Picker</Heading3>
                    <RegularText>No min or max</RegularText>
                    <View style={globalStyles.inputDateContainerTemp}>
                        <InputDate {...testProps('date-picker-component')} value={'12/07/2021'} />
                    </View>
                    <RegularText>With min</RegularText>
                    <View style={globalStyles.inputDateContainerTemp}>
                        <InputDate
                            {...testProps('date-picker-component-min')}
                            value={'12/07/2021'}
                            minDate={'12/05/2021'}
                        />
                    </View>
                    <RegularText>With max</RegularText>
                    <View style={globalStyles.inputDateContainerTemp}>
                        <InputDate
                            {...testProps('date-picker-component-max')}
                            value={'12/07/2021'}
                            maxDate={'12/15/2021'}
                        />
                    </View>
                    <RegularText>With min and max</RegularText>
                    <View style={globalStyles.inputDateContainerTemp}>
                        <InputDate
                            {...testProps('date-picker-component-minmax')}
                            value={'12/07/2021'}
                            minDate={'12/05/2021'}
                            maxDate={'12/15/2021'}
                        />
                    </View>
                    <Heading3>Checkboxes</Heading3>

                    <InputCheckbox initialStatus={'unchecked'} />
                    <InputCheckbox initialStatus={'checked'} />
                    <InputCheckbox label={'Checkbox with label unchecked'} initialStatus={'unchecked'} />
                    <InputCheckbox label={'Checkbox with label checked'} initialStatus={'checked'} />

                    <Heading3>Tags</Heading3>

                    <Tag {...testProps('tag-red')}>Red Tag</Tag>

                    <Tag {...testProps('tag-yellow')} tagType={TagTypes.Yellow}>
                        Yellow Tag
                    </Tag>

                    <Tag {...testProps('tag-green')} tagType={TagTypes.Green}>
                        Green Tag
                    </Tag>

                    <Tag {...testProps('tag-blue')} tagType={TagTypes.Blue}>
                        Blue Tag
                    </Tag>

                    <Tag {...testProps('tag-dark-grey')} tagType={TagTypes.DarkGrey}>
                        Dark Grey Tag
                    </Tag>

                    <Heading3>Link</Heading3>

                    <Hyperlink {...testProps('hyperlink-default')}>This is a Hyperlink</Hyperlink>
                    <Anchor href={'https://google.com'}>This is an Anchor</Anchor>

                    <Heading3>Style Text</Heading3>

                    <SmallerText>SmallerText</SmallerText>

                    <SmallText>SmallText</SmallText>

                    <SmallerBoldText>SmallerBoldText</SmallerBoldText>

                    <SmallBoldText>SmallBoldText</SmallBoldText>

                    <RegularText>RegularText</RegularText>

                    <RegularBoldText>RegularBoldText</RegularBoldText>

                    <MediumText>MediumText</MediumText>

                    <MediumBoldText>MediumBoldText</MediumBoldText>

                    <LargeText>LargeText</LargeText>

                    <LargeBoldText>LargeBoldText</LargeBoldText>

                    <ExtraLargeText>ExtraLargeText</ExtraLargeText>

                    <ExtraLargeBoldText>ExtraLargeBoldText</ExtraLargeBoldText>

                    <DeemphansizedTextSmall>DeemphansizedTextSmall</DeemphansizedTextSmall>

                    <DeephansizedRegularText>DeephansizedRegularText</DeephansizedRegularText>

                    <LabelText>LabelText</LabelText>

                    <ButtonText>ButtonText</ButtonText>

                    <ButtonTextLarge>ButtonTextLarge</ButtonTextLarge>

                    <Heading1>Heading 1</Heading1>
                    <Heading2>Heading 2</Heading2>
                    <Heading3>Heading 3</Heading3>
                    <Heading4>Heading 4</Heading4>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
};

export default PrimitivesScreen;

const styles = StyleSheet.create({
    dialogBody: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 4,
    },
});
