import { createSmarthubNoticeToVacateRequestSubmitData } from '../../businesslogic/smarthub/dataobjects/SmarthubNoticeToVacateRequestSubmitData';
import { SmarthubNoticeToVacateRequestSubmitData } from '../../businesslogic/smarthub/dataobjects/SmarthubNoticeToVacateRequestSubmitData';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SubmitNoticeToVacateRequestResultV1 } from './SubmitNoticeToVacateRequestResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitNoticeToVacateRequestCommandV1
 */
export interface SubmitNoticeToVacateRequestCommandV1
    extends AbstractResidencyAuthenticatedCommand<SubmitNoticeToVacateRequestResultV1> {
    data: SmarthubNoticeToVacateRequestSubmitData;
}

export function createSubmitNoticeToVacateRequestCommandV1(): SubmitNoticeToVacateRequestCommandV1 {
    return {
        data: createSmarthubNoticeToVacateRequestSubmitData(),
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitNoticeToVacateRequestCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
