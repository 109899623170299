import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetSmarthubResidencyResultV1 } from './GetSmarthubResidencyResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetSmarthubResidencyCommandV1
 */
export interface GetSmarthubResidencyCommandV1
    extends AbstractResidencyAuthenticatedCommand<GetSmarthubResidencyResultV1> {
    payLeaseRedirUrl: string;
    smarthubWebUrl: string;
}

export function createGetSmarthubResidencyCommandV1(): GetSmarthubResidencyCommandV1 {
    return {
        payLeaseRedirUrl: '',
        smarthubWebUrl: '',
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetSmarthubResidencyCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
