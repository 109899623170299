import {
    createSmarthubLoginV1,
    SmarthubLoginV1,
} from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubLoginV1';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { InsertSmarthubLoginResultV1 } from './InsertSmarthubLoginResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.InsertSmarthubLoginCommandV1
 */
export interface InsertSmarthubLoginCommandV1
    extends AbstractResidencyAuthenticatedCommand<InsertSmarthubLoginResultV1> {
    data: SmarthubLoginV1;
}

export function createInsertSmarthubLoginCommandV1(): InsertSmarthubLoginCommandV1 {
    return {
        data: createSmarthubLoginV1(),
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.InsertSmarthubLoginCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
