import { SmarthubResidentCommSubscriptionOpt } from './SmarthubResidentCommSubscriptionOpt';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.residency.SmarthubResidentCommSubscriptionV1
 */
export interface SmarthubResidentCommSubscriptionV1 {
    commCategoryCd: string;
    description: string;
    smarthubDescription: string;
    subscriptionOpts: Array<SmarthubResidentCommSubscriptionOpt>;
}

export function createSmarthubResidentCommSubscriptionV1(): SmarthubResidentCommSubscriptionV1 {
    return { commCategoryCd: '', description: '', smarthubDescription: '', subscriptionOpts: [] };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
