import React from 'react';
import { StyleSheet, View } from 'react-native';
import { DeemphansizedTextSmall, MediumBoldText } from 'components/primitives/StyledText';
import Hyperlink from 'components/primitives/Hyperlink';
import InputDropdown from 'components/input/InputDropdown';
import InputString from 'components/input/InputString';
import { globalStyles } from 'theme/style/GlobalStyles';
import { SmartmovePetV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmovePetV1';
import { SelectOption } from 'models/remotecmds/com/ocs/nirvana/businesslogic/common/dataobjects/SelectOption';
import { FormikErrors, FormikProps } from 'formik';
import { SubmitSmartmoveVehiclesAndPetsCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/SubmitSmartmoveVehiclesAndPetsCommandV1';

type SmartMovePetProps = {
    index: number;
    onRemove?: () => void;
    pet: SmartmovePetV1;
    ownerOptions: Array<SelectOption> | undefined;
    formikHelper: FormikProps<SubmitSmartmoveVehiclesAndPetsCommandV1>;
};

const SmartMovePet: React.FC<SmartMovePetProps> = ({
    index,
    onRemove,
    pet,
    ownerOptions,
    formikHelper,
}: SmartMovePetProps) => {
    const _handleRemove = () => {
        if (onRemove) {
            onRemove();
        }
    };

    let errors: FormikErrors<SmartmovePetV1> | undefined = undefined;
    if (formikHelper.errors.smartmovePets && formikHelper.errors.smartmovePets[index]) {
        errors = formikHelper.errors.smartmovePets[index] as FormikErrors<SmartmovePetV1>;
    }

    const fieldPrefix = `smartmovePets.${index}`;

    return (
        <>
            <View style={styles.heading}>
                <MediumBoldText style={globalStyles.flexContainer}>{`Pet ${index + 1} (${
                    pet.typeName
                })`}</MediumBoldText>
                <Hyperlink
                    style={styles.removeLink}
                    onClick={() => {
                        _handleRemove();
                    }}
                >
                    Remove Pet
                </Hyperlink>
            </View>
            <View style={globalStyles.sectionSpaceBelow}>
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>Breed</DeemphansizedTextSmall>
                <InputDropdown
                    options={pet.breedOptions}
                    selectedValue={pet.breed}
                    error={!!errors?.breed}
                    errorMessage={errors?.breed}
                    disabled={true}
                    onChange={value => {
                        formikHelper.setFieldValue(`${fieldPrefix}.breed`, value);
                    }}
                />
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>Name</DeemphansizedTextSmall>
                <InputString
                    initialValue={pet.name}
                    maxLength={100}
                    error={!!errors?.name}
                    errorMessage={errors?.name}
                    onChange={formikHelper.handleChange(`${fieldPrefix}.name`)}
                    onBlur={formikHelper.handleBlur(`${fieldPrefix}.name`)}
                />
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>Color</DeemphansizedTextSmall>
                <InputString
                    initialValue={pet.colour}
                    maxLength={40}
                    error={!!errors?.colour}
                    errorMessage={errors?.colour}
                    onChange={formikHelper.handleChange(`${fieldPrefix}.colour`)}
                    onBlur={formikHelper.handleBlur(`${fieldPrefix}.colour`)}
                />
                <DeemphansizedTextSmall style={globalStyles.smartMoveFormLabel}>Owner</DeemphansizedTextSmall>
                <InputDropdown
                    options={ownerOptions}
                    selectedValue={String(pet.ownerResidentId)}
                    error={!!errors?.ownerResidentId}
                    errorMessage={errors?.ownerResidentId}
                    onChange={value => {
                        formikHelper.setFieldValue(`${fieldPrefix}.ownerResidentId`, Number(value));
                    }}
                />
            </View>
        </>
    );
};

export default SmartMovePet;

const styles = StyleSheet.create({
    heading: {
        flexDirection: 'row',
    },
    removeLink: {
        flex: 1,
        paddingTop: 4,
        textAlign: 'right',
    },
});
