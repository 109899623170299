import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetTransactionsResultV1 } from './GetTransactionsResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.transactions.GetTransactionsCommandV1
 */
export interface GetTransactionsCommandV1 extends AbstractResidencyAuthenticatedCommand<GetTransactionsResultV1> {
    startIndex: number;
    rowLimit: number;
}

export function createGetTransactionsCommandV1(): GetTransactionsCommandV1 {
    return {
        startIndex: 0,
        rowLimit: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.transactions.GetTransactionsCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
