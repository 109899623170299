import { StyleSheet, Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

const getAdjustedFontSize = (size: number): number => {
    if (width < 375) {
        return size - 2;
    } else {
        return size;
    }
};

export const SmarthubTypography = StyleSheet.create({
    /*eslint-disable react-native/no-color-literals*/
    smaller: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(12),
    },
    smallerBold: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(12),
    },
    small: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(13),
    },
    smallBold: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(13),
    },
    regular: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(16),
    },
    regularBold: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(16),
    },
    medium: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(20),
    },
    mediumBold: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(20),
    },
    large: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(25),
    },
    largeBold: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(25),
    },
    extraLarge: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(34),
    },
    extraLargeBold: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(34),
    },
    deephansizedSmall: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(12),
        color: '#99999A',
    },
    deephansizedRegular: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(16),
        color: '#99999A',
    },
    formLabel: {
        fontFamily: 'Roboto_400Regular',
        fontSize: getAdjustedFontSize(16),
        color: '#99999A',
    },
    boldLabel: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(15),
    },
    buttonText: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(16),
        color: '#FFF',
        alignSelf: 'center',
    },
    buttonTextLarge: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(17),
        color: '#FFF',
        alignSelf: 'center',
    },
    bulletList: {
        fontFamily: 'Roboto_400Regular',
        marginBottom: getAdjustedFontSize(5),
    },
    heading1: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(25),
    },
    heading2: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(22),
    },
    heading3: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(18),
    },
    heading4: {
        fontFamily: 'Roboto_700Bold',
        fontSize: getAdjustedFontSize(16),
    },
    /* eslint-enable react-native/no-color-literals*/
});
