import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { MessageBalancePay } from 'components/cards/MessageBalancePay';
import { observer } from 'mobx-react-lite';
import { LatestNotifications } from 'components/cards/LatestNotifications';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useStore } from 'contexts/StoreContextProvider';

const RightBar: React.FC = observer(() => {
    const { sessionActiveResidency } = useStore().userSessionStore;

    return (
        <View style={styles.container}>
            {sessionActiveResidency.statusCd !== 'Leased' && (
                <View style={styles.messagecontainer}>
                    <MessageBalancePay />
                </View>
            )}
            <View style={styles.latestcontainer}>
                <LatestNotifications />
            </View>
        </View>
    );
});

export default RightBar;

const styles = StyleSheet.create({
    container: {
        height: '100%',
    },
    messagecontainer: {
        minHeight: Platform.OS === 'web' ? 'auto' : 400,
    },
    latestcontainer: {
        height: 'auto',
        marginTop: SmarthubTheme.layout.SURROUNDMARGIN,
    },
});
