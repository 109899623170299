export type SmarthubResidentCommSubscriptionType = 'Email' | 'TextMessage' | 'PushNotification';

export type NullableSmarthubResidentCommSubscriptionType = SmarthubResidentCommSubscriptionType | null;

export const SmarthubResidentCommSubscriptionTypeOptions = [
    { value: 'Email', label: 'Email' },
    { value: 'TextMessage', label: 'Text Message' },
    { value: 'PushNotification', label: 'Push Notification' },
];

export const SmarthubResidentCommSubscriptionTypeOptionsWithBlank = [{ value: '', label: '' }].concat(
    SmarthubResidentCommSubscriptionTypeOptions,
);

/* START CUSTOM CODE */
/* END CUSTOM CODE */
