import 'intl';
import 'intl/locale-data/jsonp/en'; // or any other locale you need

export const formatCentsAsDollars = (cents: number): string => {
    const formatter = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(cents / 100); /* $2,500.00 */
};

export const centsFromNumber = (amt: number): number => {
    return Math.round(amt * 100);
};

export const numberFromCents = (cents: number): number => {
    return cents / 100;
};

/***
 *  round the number to full number , e.g. 100.123 => 100
 * @param cents
 */
export const roundToFullNumber = (cents: number): number => {
    return Number(cents.toFixed());
};

/***
 * return the percentage of the cents amount in full cents amount, eg: 2.5% of $1
 * @param cents $1 will be 100
 * @param percentage %2.5 will be 2.5
 * @return the rounded full cents amount
 */
export const percentageOfCents = (cents: number, percentage: number): number => {
    return roundToFullNumber(cents * (percentage / 100));
};
