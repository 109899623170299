import { RootStore } from '../RootStore';
import { logError } from 'utils/logging/Logger';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { makeAutoObservable } from 'mobx';
import {
    createGetAppVersionCommandV1,
    GetAppVersionCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetAppVersionCommandV1';
import { GetAppVersionResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetAppVersionResultV1';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import * as Updates from 'expo-updates';
import * as Application from 'expo-application';

export class AppStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    /***
     *
     * @param versionCode in {expo sdk}{major}{minor}{patch} format
     * @return version number in {major}.{monior}.{patch}
     */
    parseAndroidVersion = (versionCodeString: number | string | null | undefined): string | undefined => {
        if (!versionCodeString) return undefined;
        const versionCode = Number(versionCodeString);
        const patch = versionCode % 100;
        const minor = Math.floor((versionCode % 10000) / 100);
        const major = Math.floor((versionCode % 1000000) / 10000) || 1;

        return `${major}.${minor}.${patch}`;
    };

    /***
     * return the manifest build number, it can be changed via OTA
     */
    getManifestBuildNumber = (): string | undefined => {
        if (Platform.OS === 'ios') {
            return Constants.expoConfig?.ios?.buildNumber;
        } else if (Platform.OS === 'android') {
            return this.parseAndroidVersion(Constants.expoConfig?.android?.versionCode);
        } else {
            return undefined;
        }
    };

    /***
     * return the native binary build number, it doesn't change via OTA
     */
    getNativeBuildNumber = (): string | null | undefined => {
        if (Constants.expoConfig?.extra && Constants.expoConfig?.extra['environment'] === 'dev') return null;

        if (Platform.OS === 'ios') {
            return Constants.expoConfig?.ios?.buildNumber;
        } else if (Platform.OS === 'android') {
            return this.parseAndroidVersion(Application.nativeBuildVersion);
        } else {
            return undefined;
        }
    };

    getMajorVersionNumber = (versionNumber: string | null | undefined): string | undefined => {
        if (versionNumber) {
            return versionNumber.substr(0, 2);
        }

        return undefined;
    };

    needAppStoreUpdate = async (): Promise<boolean> => {
        const serverVersion = await this.getAppVersionFromServerAsync();
        const appVersion =
            Constants.expoConfig?.extra && Constants.expoConfig?.extra['environment'] === 'dev'
                ? this.getManifestBuildNumber()
                : this.getNativeBuildNumber();

        return !!serverVersion && !!appVersion && this.compareVersionNumber(serverVersion, appVersion) > 0;
    };

    convertVersionStringToNumeric = (version: string) => {
        const vArray = version.split('.');
        return Number(vArray[0]) * 10000 + Number(vArray[1]) * 100 + Number(vArray[2]);
    };

    compareVersionNumber = (version1: string, version2: string) => {
        return this.convertVersionStringToNumeric(version1) - this.convertVersionStringToNumeric(version2);
    };

    goToAppStore = (): void => {
        if (Platform.OS === 'ios' && Constants.expoConfig?.extra?.appleAppStoreUrl) {
            Linking.openURL(Constants.expoConfig.extra.appleAppStoreUrl);
        } else if (Platform.OS === 'android' && Constants.expoConfig?.extra?.googleAppStoreUrl) {
            Linking.openURL(Constants.expoConfig.extra.googleAppStoreUrl);
        }
    };

    checkForUpdate = async () => {
        if (Platform.OS !== 'web') {
            //check if we need to update from app store
            const needAppStoreUpdate = await this.needAppStoreUpdate();
            if (needAppStoreUpdate) {
                this.rootStore.uiStore.showAlert({
                    title: 'An Update is Required',
                    message:
                        "We've made some improvements to the SMARTHUB experience! Please update SMARTHUB from the app store to continue.",
                    notDismissible: true,
                    buttons: [
                        {
                            buttonText: 'Update Now',
                            onClick: this.goToAppStore,
                        },
                    ],
                });
            } else {
                if (this.rootStore.uiStore.alert && this.rootStore.uiStore.alert.title === 'An Update is Required') {
                    this.rootStore.uiStore.hideAlert();
                }

                if (Constants.expoConfig?.extra && Constants.expoConfig.extra['environment'] !== 'dev') {
                    Updates.checkForUpdateAsync()
                        .then(result => {
                            if (result.isAvailable) {
                                this.updateApp();
                            }
                        })
                        .catch((error: string) => {
                            logError(ERROR_LOGGER, 'Can not check App update from server.' + error);
                        });
                }
            }
        }
    };

    updateApp = () => {
        if (!this.rootStore.uiStore.alert || this.rootStore.uiStore.alert.title !== 'Updating') {
            this.rootStore.uiStore.showAlert({
                title: 'Updating',
                message:
                    'Please wait while we download the latest update. The app will relaunch once the update is complete. This may take a couple of minutes.',
                notDismissible: true,
            });
        }
        Updates.fetchUpdateAsync()
            .then(fetchResult => {
                if (fetchResult.isNew) {
                    Updates.reloadAsync();
                }
            })
            .catch((error: string) => {
                logError(ERROR_LOGGER, 'Can not fetch App update from server.' + error);
            })
            .finally(() => {
                if (this.rootStore.uiStore.alert && this.rootStore.uiStore.alert.title === 'Updating') {
                    this.rootStore.uiStore.hideAlert();
                }
            });
    };

    getAppVersionFromServerAsync = async (): Promise<string> => {
        try {
            const cmd: GetAppVersionCommandV1 = createGetAppVersionCommandV1();
            const result: GetAppVersionResultV1 = await dispatchCommandAsync(
                this.rootStore.uiStore,
                cmd,
                true,
                null,
                true,
            );
            return result.versionNumber;
        } catch (e) {
            logError(ERROR_LOGGER, 'Error getting app version from server:   ', JSON.stringify(e));
            return '';
        }
    };
}
