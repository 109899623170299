import { AbstractPropertyAuthenticatedCommand } from '../AbstractPropertyAuthenticatedCommand';
import { GetZegoGatewayTokenResultV1 } from './GetZegoGatewayTokenResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetZegoGatewayTokenCommandV1
 */
export type GetZegoGatewayTokenCommandV1 = AbstractPropertyAuthenticatedCommand<GetZegoGatewayTokenResultV1>;

export function createGetZegoGatewayTokenCommandV1(): GetZegoGatewayTokenCommandV1 {
    return {
        propertyCd: '',
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetZegoGatewayTokenCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
