import { action, IObservableArray, makeAutoObservable, observable } from 'mobx';
import { SmarthubResidencyV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';

class SmarthubUser {
    uid: string | undefined;
    authorizedResidencies: IObservableArray<SmarthubResidencyV1> = observable<SmarthubResidencyV1>([]);

    constructor() {
        makeAutoObservable(this);
    }

    setAuthorizedResidencies = action((newAuthorizedResidencies: IObservableArray<SmarthubResidencyV1>) => {
        this.authorizedResidencies.clear();
        this.authorizedResidencies = newAuthorizedResidencies;
    });

    get hasAuthorizedResidencies() {
        return this.authorizedResidencies && this.authorizedResidencies.length > 0;
    }
}

export default SmarthubUser;
