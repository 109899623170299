import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Surface, useTheme } from 'react-native-paper';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';

interface MainStackSurfaceProps {
    children: any;
}
const MainStackSurface: React.FC<MainStackSurfaceProps> = (props: MainStackSurfaceProps) => {
    const useDrawerNavigation = useResponsiveContext().useDrawerNavigation;
    const theme = useTheme();

    if (useDrawerNavigation) {
        return (
            <Surface style={[styles.surface, { backgroundColor: theme.colors.background }]}>{props.children}</Surface>
        );
    } else {
        return (
            <View style={[styles.surfacenoelevation, { backgroundColor: theme.colors.background }]}>
                {props.children}
            </View>
        );
    }
};

export default MainStackSurface;

const styles = StyleSheet.create({
    surface: {
        flex: 1,
        elevation: Platform.OS === 'web' ? 0 : SmarthubTheme.layout.SURFACEELEVATION,
        marginVertical: SmarthubTheme.layout.SURROUNDMARGIN,
        marginHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
        padding: SmarthubTheme.layout.INNERPAD,
        borderWidth: 0.1,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.lightgrey,
    },
    surfacenoelevation: {
        flex: 1,
    },
});
