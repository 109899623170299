import {
    createGetCreateTicketDataCommandV1,
    GetCreateTicketDataCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetCreateTicketDataCommandV1';
import { useStore } from 'contexts/StoreContextProvider';
import { GetCreateTicketDataResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetCreateTicketDataResultV1';
import {
    generateInfiniteQueryKeyFromCmd,
    generateQueryKeyFromCmd,
    useCommandMutation,
    useCommandQuery,
} from 'queries/hooks/useCommandQuery';
import {
    createSubmitTicketCommandV1,
    SubmitTicketCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/SubmitTicketCommandV1';
import { SubmitTicketResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/SubmitTicketResultV1';
import { SmarthubTicketSubmitData } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubTicketSubmitData';
import { StackNavigationProp } from '@react-navigation/stack';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import {
    createReopenRequestCommandV1,
    ReopenRequestCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/ReopenRequestCommandV1';
import { ReOpenRequestReason } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/maintenance/dataobjects/ReOpenRequestReason';
import {
    CancelRequestCommandV1,
    createCancelRequestCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/CancelRequestCommandV1';
import { SmarthubRequestType } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubRequestType';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { CancelRequestResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/CancelRequestResultV1';
import { createGetMyRequestCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetMyRequestCommandV1';
import {
    createGetRequestDetailCommandV1,
    GetRequestDetailCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetRequestDetailCommandV1';
import { GetRequestDetailResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetRequestDetailResultV1';
import { ReopenRequestResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/ReopenRequestResultV1';
import {
    createReopenTicketCommandV1,
    ReopenTicketCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/ReopenTicketCommandV1';
import { ReopenTicketResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/ReopenTicketResultV1';
import {
    createGetCreateRequestDataCommandV1,
    GetCreateRequestDataCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetCreateRequestDataCommandV1';
import { GetCreateRequestDataResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetCreateRequestDataResultV1';
import {
    createSubmitRequestCommandV1,
    SubmitRequestCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/SubmitRequestCommandV1';
import { SmarthubRequestSubmitData } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubRequestSubmitData';
import { SubmitRequestResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/SubmitRequestResultV1';
import { navigationRoutes } from 'utils/NavigationUtils';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import {
    createSendRequestMessageCommandV1,
    SendRequestMessageCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/SendRequestMessageCommandV1';
import { SendRequestMessageResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/SendRequestMessageResultV1';
import { spaceWordsOnCapitals } from 'utils/StringUtils';

export const requestsRowLimit = 10;

export const generateGetCreateTicketDataCMD = (propertyCd: string, residencyId: number, residentId: number) => {
    const cmd: GetCreateTicketDataCommandV1 = createGetCreateTicketDataCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.residentId = residentId;
    return cmd;
};

export const generateSubmitTicketCMD = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    data: SmarthubTicketSubmitData,
) => {
    const cmd: SubmitTicketCommandV1 = createSubmitTicketCommandV1();
    cmd.data = data;
    cmd.data.propertyCd = propertyCd;
    cmd.data.residencyId = residencyId;
    cmd.data.residentId = residentId;
    return cmd;
};

export const generateCancelRequestCMD = (
    propertyCd: string,
    requestId: number,
    type: SmarthubRequestType,
    message: string,
) => {
    const cmd = createCancelRequestCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.requestId = requestId;
    cmd.requestType = type;
    cmd.message = message;
    return cmd;
};

export const generateReopenRequestCMD = (
    propertyCd: string,
    requestId: number,
    residentId: number,
    reason: ReOpenRequestReason,
    reasonDetail: string,
) => {
    const cmd = createReopenRequestCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.requestId = requestId;
    cmd.residentId = residentId;
    cmd.reason = reason;
    cmd.reasonDetail = reasonDetail;
    return cmd;
};

export const generateRequestsCMD = (
    propertyCd: string,
    residencyId: number,
    gridScreenIncludeCompleted: boolean,
    rowLimit = requestsRowLimit,
    startIndex = 0,
) => {
    const cmd = createGetMyRequestCommandV1();
    cmd.residencyId = residencyId;
    cmd.propertyCd = propertyCd;
    cmd.includeCompletedYn = gridScreenIncludeCompleted;
    cmd.startIndex = startIndex;
    cmd.rowLimit = rowLimit;
    return cmd;
};

export const generateRequestDetailsCMD = (propertyCd: string, requestId: number, type: SmarthubRequestType) => {
    const cmd = createGetRequestDetailCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.requestId = requestId;
    cmd.type = type;
    return cmd;
};

export const generateReopenTicketCMD = (
    propertyCd: string,
    ticketId: number,
    residentId: number,
    fname: string,
    lname: string,
) => {
    const cmd = createReopenTicketCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.ticketId = ticketId;
    cmd.residentId = residentId;
    cmd.message = `The ticket was was not solved and re-opened by ${fname} ${lname}`;
    return cmd;
};

export const generateGetCreateRequestDataCMD = (propertyCd: string, residencyId: number, residentId: number) => {
    const cmd: GetCreateRequestDataCommandV1 = createGetCreateRequestDataCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.residentId = residentId;
    return cmd;
};

export const generateSubmitRequestCMD = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    data: SmarthubRequestSubmitData,
    hasCovidSym: boolean,
) => {
    const cmd = createSubmitRequestCommandV1();
    cmd.data = data;
    if (hasCovidSym) {
        cmd.data.desc =
            "***URGENT: The resident has indicated that one or more people in the apartment have answered 'YES' concerning exposure/symptoms of COVID-19.*** " +
            data.desc;
    }
    cmd.data.propertyCd = propertyCd;
    cmd.data.residencyId = residencyId;
    cmd.data.residentId = residentId;
    return cmd;
};

export const generateSendRequestMessageCMD = (
    propertyCd: string,
    requestId: number,
    type: SmarthubRequestType,
    message: string,
) => {
    const cmd = createSendRequestMessageCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.requestId = requestId;
    cmd.requestType = type;
    cmd.message = message;
    return cmd;
};

/* ******************** CMD generation ends ********************** */

export const useGetCreateTicketData = () => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateGetCreateTicketDataCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
    );

    const queryOptions: UseQueryOptions<unknown, unknown, GetCreateTicketDataResultV1> = {
        enabled: hasSessionActiveResidency,
        onSuccess: result => {
            logInfo(INFO_LOGGER, 'Get create ticket data: ', JSON.stringify(result));
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error getting create ticket data:   ', JSON.stringify(e));
        },
    };

    return useCommandQuery<GetCreateTicketDataCommandV1, GetCreateTicketDataResultV1>(
        cmd,
        uiStore,
        undefined,
        queryOptions,
    );
};

export const useSubmitTicket = (
    navigation: StackNavigationProp<any>,
    isHomeOnNavStack: boolean,
    customConfirmScreenOption?: (refNumber: string) => RequestConfirmScreenOptions,
    onSuccess?: (data: SubmitTicketResultV1) => void,
) => {
    const { uiStore } = useStore();
    const { useDrawerNavigation } = useResponsiveContext();

    return useCommandMutation<SubmitTicketCommandV1, SubmitTicketResultV1>(uiStore, {
        onSuccess: responseResult => {
            const resultMessage = responseResult.refNumber;
            logInfo(INFO_LOGGER, 'Submit ticket: ', JSON.stringify(responseResult));
            if (resultMessage && resultMessage !== 'ERROR') {
                requestOrTicketSubmitted(
                    resultMessage,
                    isHomeOnNavStack,
                    navigation,
                    useDrawerNavigation,
                    'SmartDeskTicket',
                    customConfirmScreenOption,
                );
            }

            if (onSuccess) {
                onSuccess(responseResult);
            }
        },
        onSettled: () => {
            uiStore.hideActivityLoader();
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error submitting ticket:   ', JSON.stringify(e));
        },
    });
};

export const useReopenRequest = (navigation: any, formik: any, requestId: number, requestType: SmarthubRequestType) => {
    const { userSessionStore, uiStore, requestStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    const requestsCMD = generateRequestsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        requestStore.gridScreenIncludeCompleted,
    );

    const requestDetailsCMD = generateRequestDetailsCMD(sessionActiveResidency.propertyCd, requestId, requestType);

    const requestsQueryKey = generateInfiniteQueryKeyFromCmd(requestsCMD, requestStore.gridScreenIncludeCompleted);
    const requestDetailsQueryKey = generateQueryKeyFromCmd(requestDetailsCMD);

    return useCommandMutation<ReopenRequestCommandV1, ReopenRequestResultV1>(uiStore, {
        onSettled: () => {
            queryClient.invalidateQueries(requestsQueryKey);
            queryClient.invalidateQueries(requestDetailsQueryKey);
            uiStore.hideActivityLoader();
        },
        onSuccess: result => {
            logInfo(INFO_LOGGER, 'Reopen Request: ', JSON.stringify(result));
            formik.resetForm();
            navigation.pop(1);
            uiStore.addToast({
                message: `Thanks, we've received your request! We'll get back to you soon with an update.`,
            });
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error reopening request:   ', JSON.stringify(e));
        },
    });
};

export const useCancelRequest = (navigation: any, formik: any, requestType: SmarthubRequestType) => {
    const { userSessionStore, uiStore, requestStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    const requestsCMD = generateRequestsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        requestStore.gridScreenIncludeCompleted,
    );

    const requestsQueryKey = generateInfiniteQueryKeyFromCmd(requestsCMD, requestStore.gridScreenIncludeCompleted);

    return useCommandMutation<CancelRequestCommandV1, CancelRequestResultV1>(uiStore, {
        onSettled: () => {
            queryClient.invalidateQueries(requestsQueryKey);
            uiStore.hideActivityLoader();
        },
        onSuccess: () => {
            formik.resetForm();
            navigation.pop(2);
            uiStore.addToast({ message: `Your ${spaceWordsOnCapitals(requestType)} has been cancelled` });
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error cancelling request:   ', JSON.stringify(e));
        },
    });
};

export const useGetRequestDetails = (requestId: number, type: SmarthubRequestType) => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateRequestDetailsCMD(sessionActiveResidency.propertyCd, requestId, type);

    const queryOptions: UseQueryOptions<unknown, unknown, GetRequestDetailResultV1> = {
        enabled: hasSessionActiveResidency && !!requestId && !!type,
        onSuccess: result => {
            logInfo(INFO_LOGGER, 'Obtained request from server: ', JSON.stringify(result.requestDetail));
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error getting request details:   ', JSON.stringify(e));
        },
    };

    return useCommandQuery<GetRequestDetailCommandV1, GetRequestDetailResultV1>(cmd, uiStore, undefined, queryOptions);
};

export const useReopenTicket = (navigation: any, requestId: number, requestType: SmarthubRequestType) => {
    const { userSessionStore, uiStore, requestStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    const requestsCMD = generateRequestsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        requestStore.gridScreenIncludeCompleted,
    );

    const requestDetailsCMD = generateRequestDetailsCMD(sessionActiveResidency.propertyCd, requestId, requestType);

    const requestsQueryKey = generateInfiniteQueryKeyFromCmd(requestsCMD, requestStore.gridScreenIncludeCompleted);
    const requestDetailsQueryKey = generateQueryKeyFromCmd(requestDetailsCMD);

    return useCommandMutation<ReopenTicketCommandV1, ReopenTicketResultV1>(uiStore, {
        onSettled: () => {
            queryClient.invalidateQueries(requestsQueryKey);
            queryClient.invalidateQueries(requestDetailsQueryKey);
        },
        onSuccess: result => {
            logInfo(INFO_LOGGER, 'Reopen ticket: ', JSON.stringify(result));
            uiStore.addToast({
                message: `Got it! #SD${requestId} has been re-opened and someone will be in touch soon.`,
            });
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error reopening ticket:   ', JSON.stringify(e));
        },
    });
};

export const useGetCreateRequestData = () => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateGetCreateRequestDataCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
    );

    const queryOptions: UseQueryOptions<unknown, unknown, GetCreateRequestDataResultV1> = {
        enabled: hasSessionActiveResidency,
        onSuccess: result => {
            logInfo(INFO_LOGGER, 'Getting create request data: ', JSON.stringify(result));
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error getting create request data:   ', JSON.stringify(e));
        },
    };

    return useCommandQuery<GetCreateRequestDataCommandV1, GetCreateRequestDataResultV1>(
        cmd,
        uiStore,
        undefined,
        queryOptions,
    );
};

export const useSubmitRequest = (
    navigation: any,
    formik: any,
    useDrawerNavigation: boolean,
    isHomeOnNavStack: boolean,
    type: SmarthubRequestType,
) => {
    const { userSessionStore, uiStore, requestStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    const requestsCMD = generateRequestsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        requestStore.gridScreenIncludeCompleted,
    );

    const requestsQueryKey = generateInfiniteQueryKeyFromCmd(requestsCMD, requestStore.gridScreenIncludeCompleted);

    return useCommandMutation<SubmitRequestCommandV1, SubmitRequestResultV1>(uiStore, {
        onSettled: () => {
            queryClient.invalidateQueries(requestsQueryKey);
            uiStore.hideActivityLoader();
        },
        onSuccess: result => {
            const resultMessage = result.refNumber.toString();
            logInfo(INFO_LOGGER, 'Submit request: ', JSON.stringify(result));
            formik.resetForm();
            requestOrTicketSubmitted(resultMessage, isHomeOnNavStack, navigation, useDrawerNavigation, type);
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error submitting request:   ', JSON.stringify(e));
        },
    });
};

export const useSendRequestMessage = (
    navigation: any,
    formik: any,
    requestId: number,
    requestType: SmarthubRequestType,
) => {
    const { userSessionStore, uiStore, requestStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    const requestDetailsCMD = generateRequestDetailsCMD(sessionActiveResidency.propertyCd, requestId, requestType);
    const requestDetailsQueryKey = generateQueryKeyFromCmd(requestDetailsCMD);

    return useCommandMutation<SendRequestMessageCommandV1, SendRequestMessageResultV1>(uiStore, {
        onSettled: () => {
            queryClient.invalidateQueries(requestDetailsQueryKey);
        },
        onSuccess: result => {
            logInfo(INFO_LOGGER, 'Send request message: ', JSON.stringify(result));
            formik.resetForm();
            navigation.pop();
            uiStore.addToast({
                message: `Thanks, we've received your message! We'll get back to you soon with an update.`,
            });
        },
        onError: e => {
            logError(ERROR_LOGGER, 'Error sending request message:   ', JSON.stringify(e));
        },
    });
};

export type RequestConfirmScreenOptions = {
    smartDeskConfirm: boolean;
    header: string;
    desc1: string;
    desc2: string;
    buttonText: string;
    testID: string;
    toHome?: boolean;
};

export const requestOrTicketSubmitted = (
    resultMessage: string,
    isHomeOnNavStack: boolean,
    navigation: any,
    useDrawerNavigation: boolean,
    type: SmarthubRequestType,
    customConfirmScreenOption?: (refNumber: string) => RequestConfirmScreenOptions,
) => {
    const requestHeader = `Thanks, we've received your request!`;
    const messageHeader = `Thanks, we've received your message!`;
    const navOptions = customConfirmScreenOption
        ? customConfirmScreenOption(resultMessage)
        : {
              smartDeskConfirm: type === 'MaintenanceRequest' ? false : true,
              header: type === 'MaintenanceRequest' ? requestHeader : messageHeader,
              desc1: `Your reference number is ${resultMessage}`,
              desc2: `We'll get back to you soon with an update.`,
              buttonText: 'Continue',
              testID: 'continue-button',
          };
    if (useDrawerNavigation) {
        navigation.navigate(navigationRoutes.homeRoutes.confirmScreen, navOptions);
    } else {
        if (isHomeOnNavStack) {
            navigation.navigate(navigationRoutes.homeRoutes.confirmScreen, navOptions);
        } else {
            navigation.navigate(navigationRoutes.homeRoutes.home, {
                screen: navigationRoutes.homeRoutes.confirmScreen,
                params: navOptions,
            });
        }
    }
};
