import React, { useEffect } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import {
    DeemphansizedTextSmall,
    DeephansizedRegularText,
    RegularBoldText,
    RegularText,
} from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { StackNavigationProp } from '@react-navigation/stack';
import { formatDateStringMonthLabelAtTimeFromNumber } from 'utils/DateUtils';
import { useGetSmartPackagesForResidentQuery } from 'queries/hooks/smartpackage/useGetSmartPackagesForResidentQuery';
import { SmartPackageV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartpackage/SmartPackageV1';
import Tag from 'components/primitives/Tag';
import Hyperlink from 'components/primitives/Hyperlink';
import { TagTypes } from 'components/primitives/TagTypes';
import { getScreenOrModalName, navigationRoutes } from 'utils/NavigationUtils';
import {
    generateMarkNotificationsAsSeenByTypeCMD,
    typeHasUnseenNotifications,
    useMarkNotificationTypeAsSeenMutation,
    useSmarthubNotifications,
} from 'screens/home/requests/NotificationsHooks';
import { SmarthubNotificationTypeV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationTypeV1';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';

type SmartPackageProps = {
    navigation: StackNavigationProp<any>;
};

const SmartPackage = observer(({ navigation }: SmartPackageProps) => {
    const { userSessionStore, uiStore } = useStore();
    const { sessionActiveResidency, hasSessionActiveResidency } = userSessionStore;

    const theme = useTheme();

    const { queryKey, queryResult } = useGetSmartPackagesForResidentQuery(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residentId,
        true,
        0,
        uiStore,
        {
            enabled: hasSessionActiveResidency,
        },
    );

    const navigateToHistoryScreen = () => {
        navigation.navigate(getScreenOrModalName(navigationRoutes.homeRoutes.smartPackageHistory, true));
    };

    const notificationType: SmarthubNotificationTypeV1 = 'SmartPackage';
    const notifications: SmarthubNotificationV1[] = useSmarthubNotifications()?.data?.notifications || [];
    const hasUnseenNotices = typeHasUnseenNotifications(notificationType, notifications);
    const markNotificationTypeAsSeen = useMarkNotificationTypeAsSeenMutation(notificationType);

    useEffect(() => {
        if (hasSessionActiveResidency) {
            markNotificationsSeenInNotices();
        }
    }, [sessionActiveResidency]);

    useEffect(() => {
        queryResult.isLoading || queryResult.isFetching ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [queryResult.isLoading, queryResult.isFetching]);

    const markNotificationsSeenInNotices = () => {
        if (hasUnseenNotices) {
            markNotificationTypeAsSeen.mutate(
                generateMarkNotificationsAsSeenByTypeCMD(
                    sessionActiveResidency.propertyCd,
                    sessionActiveResidency.residencyId,
                    sessionActiveResidency.residentId,
                    notificationType,
                ),
            );
        }
    };

    if (!hasSessionActiveResidency || queryResult.isLoading || queryResult.isFetching || !queryResult.data) {
        return null;
    }

    return (
        <ScrollView contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
            <View>
                <RegularText>
                    With Venterra&apos;s SMART<RegularBoldText>PACKAGE</RegularBoldText> system, you can rest assured
                    that your packages are stored safely and securely. Just enter the private locker code we provide you
                    and pick up your package!
                </RegularText>

                {queryResult.data.packages.length === 0 ? (
                    <View style={styles.notice}>
                        <DeephansizedRegularText>You have no packages for pick up</DeephansizedRegularText>
                    </View>
                ) : (
                    <View style={styles.packages}>
                        {queryResult.data.packages.map((parcel: SmartPackageV1, index) => (
                            <View key={`parcel-pending-${index}`} style={styles.package}>
                                <View style={styles.packageLeft}>
                                    <RegularText style={styles.lineItem}>
                                        Package #{parcel.parcelPendingParcelId}
                                    </RegularText>
                                    <RegularText style={styles.lineItem}>Locker Code: {parcel.pickupCode}</RegularText>
                                    <DeemphansizedTextSmall>
                                        Arrived {formatDateStringMonthLabelAtTimeFromNumber(parcel.deliveryTime)}
                                    </DeemphansizedTextSmall>
                                </View>
                                <View style={styles.packageRight}>
                                    <Tag tagType={TagTypes.Blue}>In Locker</Tag>
                                </View>
                            </View>
                        ))}
                    </View>
                )}

                <View style={styles.viewHistory}>
                    <Hyperlink
                        onClick={() => {
                            navigateToHistoryScreen();
                        }}>
                        View my history of package deliveries
                    </Hyperlink>
                </View>
            </View>
        </ScrollView>
    );
});
export default SmartPackage;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: SmarthubTheme.layout.SURROUNDMARGIN,
        marginTop: Platform.OS === 'web' ? SmarthubTheme.layout.SURROUNDMARGIN : SmarthubTheme.layout.GRIDINCREMENT * 7,
        paddingBottom: SmarthubTheme.layout.SECTIONMARGIN * 4,
    },
    notice: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        borderTopColor: SmarthubTheme.colors.border,
        borderTopWidth: 1,
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    packages: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        borderTopColor: SmarthubTheme.colors.border,
        borderTopWidth: 1,
    },
    package: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: SmarthubTheme.colors.border,
        borderBottomWidth: 1,
        paddingVertical: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
    packageLeft: {
        flex: 1,
    },
    packageRight: {
        flex: 1,
        maxWidth: 120,
    },
    lineItem: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
    viewHistory: {
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        paddingTop: SmarthubTheme.layout.SURROUNDMARGIN * 3,
    },
});
