import {
    createSmarthubResidentCommSubscriptionV1,
    SmarthubResidentCommSubscriptionV1,
} from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentCommSubscriptionV1';
import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { GetResidentCommSubscriptionsResultV1 } from './GetResidentCommSubscriptionsResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.UpdateResidentCommSubscriptionCommandV1
 */
export interface UpdateResidentCommSubscriptionCommandV1
    extends AbstractResidentAuthenticatedCommand<GetResidentCommSubscriptionsResultV1> {
    residencyId: number;
    subscription: SmarthubResidentCommSubscriptionV1;
    autoRuleActionInvocationIdFromEmail: string;
}

export function createUpdateResidentCommSubscriptionCommandV1(): UpdateResidentCommSubscriptionCommandV1 {
    return {
        residencyId: 0,
        autoRuleActionInvocationIdFromEmail: '',
        subscription: createSmarthubResidentCommSubscriptionV1(),
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.residency.UpdateResidentCommSubscriptionCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
