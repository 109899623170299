import React, { useEffect, useState } from 'react';
import { SectionList, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'react-native-paper';
import { DeemphansizedTextSmall, Heading1, RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import InputSwitch from 'components/input/InputSwitch';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import { logError, logInfo } from 'utils/logging/Logger';
import Hyperlink from 'components/primitives/Hyperlink';
import Container from 'components/primitives/Container';
import { SmarthubResidentNotificationType } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentNotificationType';
import { StackNavigationProp } from '@react-navigation/stack';
import { formatPhoneNo } from 'utils/StringUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import { QueryKey, useQueryClient } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import {
    createGetResidentNotificationPreferencesCommandV1,
    GetResidentNotificationPreferencesCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentNotificationPreferencesCommandV1';
import { useCommandMutation, useCommandQuery } from 'queries/hooks/useCommandQuery';
import {
    createGetResidentProfileCommandV1,
    GetResidentProfileCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentProfileCommandV1';
import { GetResidentProfileResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentProfileResultV1';
import { GetResidentNotificationPreferencesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentNotificationPreferencesResultV1';
import {
    createUpdateResidentNotificationPreferencesCommandV1,
    UpdateResidentNotificationPreferencesCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/UpdateResidentNotificationPreferencesCommandV1';
import { EmptyVersionedRemoteResult } from 'models/remotecmds/com/ocs/remotecmd/versioned/EmptyVersionedRemoteResult';
import { SmarthubResidentNotificationPreferenceV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentNotificationPreferenceV1';

export type AlertOption = {
    type: SmarthubResidentNotificationType;
    contactMethod: 'email' | 'text' | 'push' | 'paperless';
    title: string;
    enabled: boolean;
    toggleOffTextColor?: string;
    description?: string;
    testID?: string;
    accessibilityLabel?: string;
};

export const alertOptionTitles = new Map<string, string>([
    ['Statement', 'Statements'],
    ['Request', 'My Requests'],
    ['Package', 'Packages'],
    ['CommunityNotice', 'Community Notices'],
]);
export const alertTypeDescriptions = new Map<string, string>([
    ['Statement', 'Notifications about new statements'],
    ['Request', 'Notifications about your SMARTDESK and Maintenance Requests'],
    ['Package', 'Notifications about new packages that are ready for pick up, or other package related updates'],
    ['CommunityNotice', 'Notifications about new notices from community managers and new events'],
]);

type AlertOptionComponentProps = {
    option: AlertOption;
    onChangesMade: () => void;
};

const AlertOptionComponent: React.FC<AlertOptionComponentProps> = observer(({ option, onChangesMade }) => {
    const { title, description, enabled, testID, accessibilityLabel } = option;
    const theme = useTheme();
    const [isSwitchOn, setIsSwitchOn] = useState(enabled);
    const { userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;

    const onToggleSwitch = () => {
        logInfo(INFO_LOGGER, 'Switching preference for ', title);
        setIsSwitchOn(!isSwitchOn);
        onChangesMade();
    };

    useEffect(() => {
        setIsSwitchOn(option.enabled);
    }, [option]);

    return (
        <View style={[styles.oneAlertOption]}>
            <View style={styles.stackedtitledesc}>
                <View style={styles.optiontextview}>
                    <RegularText
                        style={[
                            styles.optiontitle,
                            !isSwitchOn && { color: option.toggleOffTextColor || theme.colors.text },
                        ]}>
                        {title}
                    </RegularText>
                </View>
                {description && (
                    <View style={[styles.optiontextview, globalStyles.singleSpaceAbove]}>
                        <DeemphansizedTextSmall style={styles.optiondescription}>{description}</DeemphansizedTextSmall>
                    </View>
                )}
            </View>
            <View style={styles.optiontoggleview}>
                <InputSwitch
                    initialStatus={isSwitchOn}
                    disabled={sessionActiveResidency.adminYn}
                    onChange={onToggleSwitch}
                    testID={testID}
                    accessibilityLabel={accessibilityLabel}
                />
            </View>
        </View>
    );
});

type AlertsAndNotificationsProps = {
    navigation: StackNavigationProp<any>;
};

const AlertsAndNotifications: React.FC<AlertsAndNotificationsProps> = observer(
    ({ navigation }: AlertsAndNotificationsProps) => {
        const { hasSessionActiveResidency } = useStore().userSessionStore;
        const theme = useTheme();
        const { userSessionStore, uiStore } = useStore();
        const [changesMade, setChangesMade] = useState(false);

        const isMoreOnStack = IsNavigationRouteOnStack(navigationRoutes.moreRoutes.more);

        const queryClient = useQueryClient();

        // Set resident info query key
        const residentProfileQueryKey: QueryKey = [
            QueryKeys.ResidentProfile,
            userSessionStore.sessionActiveResidency.residentId,
        ];

        const residentProfileCmd = createGetResidentProfileCommandV1();
        residentProfileCmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
        residentProfileCmd.residentId = userSessionStore.sessionActiveResidency.residentId;

        const residentProfileQuery = useCommandQuery<GetResidentProfileCommandV1, GetResidentProfileResultV1>(
            residentProfileCmd,
            uiStore,
            residentProfileQueryKey,
            {
                enabled: hasSessionActiveResidency,
            },
        );

        // Set notifications query key
        const notificationsQueryKey: QueryKey = [
            QueryKeys.NotificationPreferences,
            userSessionStore.sessionActiveResidency.propertyCd,
            userSessionStore.sessionActiveResidency.residentId,
        ];

        const cmd = createGetResidentNotificationPreferencesCommandV1();
        cmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
        cmd.residentId = userSessionStore.sessionActiveResidency.residentId;

        const notificationPreferencesQuery = useCommandQuery<
            GetResidentNotificationPreferencesCommandV1,
            GetResidentNotificationPreferencesResultV1
        >(cmd, uiStore, notificationsQueryKey, {
            enabled: !!residentProfileQuery.data?.resident?.residentId,
        });

        const notificationPreferencesMutation = useCommandMutation<
            UpdateResidentNotificationPreferencesCommandV1,
            EmptyVersionedRemoteResult
        >(uiStore, {
            onSuccess: () => {
                queryClient.invalidateQueries(notificationsQueryKey);
                _isEmailOffAndPaperlessOn();
            },
        });

        useEffect(() => {
            //ensure more home page is in the stack for back navigation
            if (hasSessionActiveResidency && !isMoreOnStack) {
                navigation.reset({
                    routes: [
                        { name: navigationRoutes.moreRoutes.more },
                        { name: navigationRoutes.moreRoutes.alertsAndNotifications },
                    ],
                    index: 1,
                });
            }
        }, [hasSessionActiveResidency]);

        React.useEffect(() => {
            const unSubs = [
                navigation.addListener('beforeRemove', () => {
                    if (changesMade) uiStore.addToast({ message: 'Changes saved.' });
                }),
            ];

            return function cleanup() {
                unSubs.forEach(unSub => {
                    unSub();
                });
            };
        }, [changesMade]);

        const _updateAlertOptionOnServer = async (option: AlertOption, enabled: boolean): Promise<void> => {
            option.enabled = enabled;

            try {
                logInfo(
                    INFO_LOGGER,
                    'Update the preference in mobx and then post to server; sending...',
                    JSON.stringify(notificationPreferencesQuery.data?.preferences),
                );

                const foundPreference = notificationPreferencesQuery.data?.preferences.filter(
                    preference => preference.type === option.type,
                )[0];
                if (foundPreference) {
                    if (option.contactMethod === 'email') {
                        foundPreference.email = enabled;
                    } else if (option.contactMethod === 'text') {
                        foundPreference.text = enabled;
                    } else if (option.contactMethod === 'push') {
                        foundPreference.push = enabled;
                    } else if (option.contactMethod === 'paperless') {
                        foundPreference.paperless = enabled;
                    }
                    //now update the server
                    logInfo(
                        INFO_LOGGER,
                        'Saving user preference that alerts for ' +
                            option.title +
                            ' are now ' +
                            (enabled ? 'enabled' : 'disabled'),
                    );
                    logInfo(INFO_LOGGER, 'Preference to save is ', JSON.stringify(foundPreference));

                    const cmd = createUpdateResidentNotificationPreferencesCommandV1();

                    cmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
                    (cmd.residentId = userSessionStore.sessionActiveResidency.residentId),
                        (cmd.preferences = notificationPreferencesQuery.data?.preferences
                            ? notificationPreferencesQuery.data?.preferences
                            : []);

                    notificationPreferencesMutation.mutate(cmd);
                }
            } catch (e) {
                logError(ERROR_LOGGER, 'Error updating notification preferences:   ', JSON.stringify(e));
            } finally {
                logInfo(INFO_LOGGER, 'All done updating notification preference: ', JSON.stringify(option));
            }
        };

        const _isEmailOffAndPaperlessOn = (): void => {
            notificationPreferencesQuery.data?.preferences.map(
                (preference: SmarthubResidentNotificationPreferenceV1) => {
                    if (preference.type === 'Statement' && !preference.email && preference.paperless) {
                        uiStore.showAlert({
                            title: 'Paperless Billing',
                            message:
                                'You have selected the paperless billing option but have not chosen to be notified by email when a new statement is available. ',
                            buttons: [
                                {
                                    buttonType: 'primary',
                                    buttonText: 'Turn ON Email Statements',
                                    testID: 'email-on-button',
                                    accessibilityLabel: 'email-on-button',
                                    onClick: () => {
                                        _turnEmailStatementsOn();
                                        uiStore.hideAlert();
                                    },
                                },
                                {
                                    buttonType: 'secondary',
                                    buttonText: 'Dismiss',
                                    testID: 'dismiss-button',
                                    accessibilityLabel: 'dismiss-button',
                                    onClick: uiStore.hideAlert,
                                },
                            ],
                        });
                    }
                },
            );
        };

        const _turnEmailStatementsOn = () => {
            const activeOption: AlertOption = {
                type: 'Statement',
                contactMethod: 'email',
                title: 'Email',
                enabled: true,
                testID: 'email',
                accessibilityLabel: 'email',
            };
            _updateAlertOptionOnServer(activeOption, true);
        };

        if (uiStore.activityLoaderVisible) {
            return null;
        } else {
            const listData: Array<{ title: string; description: string; data: Array<AlertOption> }> = [];
            listData.length = 0;
            notificationPreferencesQuery.data?.preferences.map(notificationPreference => {
                const typeTitle = alertOptionTitles.get(notificationPreference.type);
                const typeDescription = alertTypeDescriptions.get(notificationPreference.type) || '';

                if (typeTitle) {
                    const options: Array<AlertOption> = [
                        {
                            type: notificationPreference.type,
                            contactMethod: 'email',
                            title: 'Email',
                            enabled: notificationPreference.email,
                            testID: 'email',
                            accessibilityLabel: 'email',
                        },
                        /*
                    { type: notificationPreference.type, contactMethod: 'text', title: 'Text message', enabled: notificationPreference.text },
                    { type: notificationPreference.type, contactMethod: 'push', title: 'Push Notification', enabled: notificationPreference.push },
                    */
                    ];
                    if (notificationPreference.type === 'Statement') {
                        options.push({
                            type: notificationPreference.type,
                            contactMethod: 'paperless',
                            title: 'Go Paperless',
                            enabled: notificationPreference.paperless,
                            description:
                                'If all of our residents go paperless, it would save 10 tons of wood, 58,764 gallons of water, and eliminate 5,048 pounds of solid waste per year!',
                            testID: 'paperless',
                            accessibilityLabel: 'paperless',
                            toggleOffTextColor: SmarthubTheme.colors.orange,
                        });
                    }
                    //TODO remove the if around this to add the other notification types (next phase!)
                    if (notificationPreference.type === 'Statement') {
                        listData.push({
                            title: typeTitle,
                            description: typeDescription,
                            data: options,
                        });
                    }
                }
            });

            if (!residentProfileQuery.data?.resident || !notificationPreferencesQuery.data?.preferences) return null;

            return (
                <SectionList
                    style={[styles.container, { backgroundColor: theme.colors.background }]}
                    contentContainerStyle={styles.innerContainer}
                    stickySectionHeadersEnabled={false}
                    scrollEnabled={true}
                    sections={listData}
                    ListHeaderComponent={() => {
                        const { email, phone1No, phone2No } = residentProfileQuery.data?.resident;
                        if (email || phone1No || phone2No) {
                            return (
                                <View style={globalStyles.sectionSpaceBelow}>
                                    <Container borderStyle={'orange'} dropShadow={true}>
                                        <View
                                            style={[
                                                styles.contactinfowarning,
                                                { backgroundColor: theme.colors.background },
                                            ]}>
                                            <View style={[styles.contactinfotitle, globalStyles.singleSpaceBelow]}>
                                                <View style={styles.infotextcont}>
                                                    <RegularBoldText>
                                                        {'You will receive these at the following:'}
                                                    </RegularBoldText>
                                                </View>
                                                <View style={styles.updatelink}>
                                                    <Hyperlink
                                                        onClick={() =>
                                                            navigation.navigate(navigationRoutes.moreRoutes.profile)
                                                        }>
                                                        Update
                                                    </Hyperlink>
                                                </View>
                                            </View>

                                            <View>
                                                {!!email && <RegularText>{email}</RegularText>}
                                                {!!phone1No && <RegularText>{formatPhoneNo(phone1No)}</RegularText>}
                                                {!!phone2No && <RegularText>{formatPhoneNo(phone2No)}</RegularText>}
                                            </View>
                                        </View>
                                    </Container>
                                </View>
                            );
                        } else {
                            return null;
                        }
                    }}
                    renderItem={({ item }) => (
                        <AlertOptionComponent
                            option={item}
                            onChangesMade={() => {
                                setChangesMade(true), _updateAlertOptionOnServer(item, !item.enabled);
                            }}
                        />
                    )}
                    renderSectionHeader={({ section: { title, description } }) => (
                        <View style={globalStyles.sectionSpaceAbove}>
                            <Heading1 style={styles.sectiontitle}>{title}</Heading1>
                            {description && (
                                <RegularText style={[styles.sectiondescription, globalStyles.doubleSpaceAbove]}>
                                    {description}
                                </RegularText>
                            )}
                        </View>
                    )}
                    renderSectionFooter={({ section: { title, description } }) => (
                        <View style={globalStyles.sectionSpaceBelow}></View>
                    )}
                    keyExtractor={(item, index) => item.title}
                />
            );
        }
    },
);
export default AlertsAndNotifications;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingVertical: SmarthubTheme.layout.SURROUNDMARGIN,
        width: '100%',
    },
    innerContainer: {
        maxWidth: '100%',
    },
    sectiontitle: {},
    sectiondescription: {},
    oneAlertOption: {
        flexDirection: 'row',
        height: SmarthubTheme.layout.GRIDINCREMENT * 8,
        alignItems: 'center',
    },
    stackedtitledesc: {
        flexDirection: 'column',
        width: '85%',
    },
    optiontextview: {
        flexDirection: 'row',
    },
    optiontitle: {
        flex: 1,
        flexWrap: 'wrap',
    },
    optiondescription: {
        paddingRight: SmarthubTheme.layout.INNERPAD,
    },
    optiontoggleview: {
        width: '15%',
    },
    contactinfowarning: { padding: SmarthubTheme.layout.INNERPAD },
    contactinfotitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    updatelink: {
        width: '20%',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
    },
    infotextcont: {
        width: '80%',
    },
});
