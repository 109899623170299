export const getRouteParam = (route: any, paramName: string): any => {
    if (route.params) {
        return route.params[paramName];
    }
    return undefined;
};

export const getRouteParams = (route: any): any => {
    return route.params;
};
