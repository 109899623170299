import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import {
    ExtraLargeText,
    FormLabelText,
    MediumBoldText,
    RegularText,
    SmallerText,
} from 'components/primitives/StyledText';
import { ZegoGatewayPaymentMethodDo } from 'models/remotecmds/com/ocs/nirvana/businesslogic/integration/paylease/dataobjects/ZegoGatewayPaymentMethodDo';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Hyperlink from 'components/primitives/Hyperlink';
import { globalStyles } from 'theme/style/GlobalStyles';
import * as WebBrowser from 'expo-web-browser';
import PaymentMethodDescription from 'components/payments/PaymentMethodDescription';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { formatDateMonthLabel } from 'utils/DateUtils';

type PaymentReviewProps = {
    paymentMethod: ZegoGatewayPaymentMethodDo;
    amount: number;
    fee: number;
    feePercentage: number;
};

const PaymentReview: React.FC<PaymentReviewProps> = ({
    paymentMethod,
    amount,
    fee,
    feePercentage,
}: PaymentReviewProps) => {
    const totalAmount = amount + fee;
    return (
        <View>
            <MediumBoldText>Have a quick review and submit your payment</MediumBoldText>

            <View style={styles.chargeInfo}>
                <ExtraLargeText style={globalStyles.doubleSpaceBelow}>
                    {`${formatCentsAsDollars(totalAmount)} ${fee ? '*' : ''}`}
                </ExtraLargeText>
                <FormLabelText style={[styles.label, globalStyles.doubleSpaceBelow]}>will be charged to</FormLabelText>
                <PaymentMethodDescription paymentMethod={paymentMethod} />
                {!!fee && !!feePercentage && (
                    <FormLabelText
                        style={[
                            styles.label,
                            globalStyles.doubleSpaceAbove,
                        ]}>{`*Includes ${feePercentage}% processing fee`}</FormLabelText>
                )}
                {!!fee && !feePercentage && (
                    <FormLabelText
                        style={[styles.label, globalStyles.doubleSpaceAbove]}>{`*Includes ${formatCentsAsDollars(
                        fee,
                    )} processing fee`}</FormLabelText>
                )}
            </View>
            <SmallerText style={[styles.termsText, globalStyles.doubleSpaceBelow]}>
                Once the payment begins processing, a refund can only be authorized through your management company.
            </SmallerText>
            <SmallerText style={[styles.termsText, globalStyles.doubleSpaceBelow]}>
                Please be advised that attempted chargebacks for Non-Fraudulent transactions through the PayLease system
                will be subject to criminal investigation and these individuals will be prosecuted to the fullest extent
                of the law.
            </SmallerText>
            <SmallerText style={[styles.termsText, globalStyles.doubleSpaceBelow]}>
                E-check Transactions: In the event my bank returns this transaction for Insufficient Funds (NSF), I
                authorize the management company to assess a fee based on my contract agreement.
            </SmallerText>
            <SmallerText style={[styles.termsText, globalStyles.doubleSpaceBelow]}>
                By clicking Submit you agree to our latest{' '}
                <Hyperlink
                    onClick={() => {
                        WebBrowser.openBrowserAsync('https://www.gozego.com/terms-of-use/');
                    }}
                    style={styles.smallText}>
                    Terms and Conditions
                </Hyperlink>
                .
            </SmallerText>
            <RegularText style={[globalStyles.sectionSpaceAbove, styles.regularText]}>
                By clicking the submit button below, I, {paymentMethod.payerFirstName} {paymentMethod.payerLastName},
                confirm that the payment information provided is correct and authorize Zego on{' '}
                {formatDateMonthLabel(new Date())} to debit the account above for {formatCentsAsDollars(totalAmount)}.
            </RegularText>
            <View style={styles.zegoLogoContainer}>
                <Image style={styles.zegoLogo} source={require('../../../assets/images/zego.png')} />
            </View>
        </View>
    );
};

export default PaymentReview;

const styles = StyleSheet.create({
    chargeInfo: {
        paddingVertical: SmarthubTheme.layout.GRIDINCREMENT * 8,
        alignItems: 'center',
    },
    label: {
        fontSize: 14,
    },
    termsText: {
        color: SmarthubTheme.colors.termsgrey,
    },
    regularText: {
        fontSize: 14,
    },
    smallText: {
        fontSize: 12,
    },
    zegoLogoContainer: {
        paddingTop: SmarthubTheme.layout.GRIDINCREMENT * 6,
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 4,
        alignItems: 'center',
    },
    zegoLogo: {
        width: 138,
        height: 50,
    },
});
