import { getItem, removeItem, setItem } from 'utils/storage';
import {
    STORAGE_KEY_REACT_NAVIGATION_STATE_HISTORY,
    STORAGE_KEY_SAVED_RESIDENCY_REFRESH_HISTORY,
} from 'utils/storage-keys';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { useNavigationState } from '@react-navigation/native';
import { Platform } from 'react-native';
import { residencyInfo } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';

export const getScreenOrModalName = (screenName: string, useDrawerNavigation: boolean): string => {
    return useDrawerNavigation ? screenName : `${screenName}Modal`;
};

export const saveNavHistory = async (state: any) => {
    try {
        await setItem(STORAGE_KEY_REACT_NAVIGATION_STATE_HISTORY, JSON.stringify(state));
    } catch (e) {
        logInfo(INFO_LOGGER, 'Error saving navigation history to local storage on reload: ', JSON.stringify(e));
    }
};

export const removeNavHistory = async () => {
    try {
        return await removeItem(STORAGE_KEY_REACT_NAVIGATION_STATE_HISTORY);
    } catch (e) {
        logInfo(INFO_LOGGER, 'Error removing local navigation history: ', JSON.stringify(e));
    }
};

export const loadNavHistory = async () => {
    try {
        return getItem(STORAGE_KEY_REACT_NAVIGATION_STATE_HISTORY);
    } catch (e) {
        logInfo(INFO_LOGGER, 'Error loading local navigation history: ', JSON.stringify(e));
    }
};

export const saveResidencyRefreshHistory = async (info: residencyInfo) => {
    try {
        await setItem(STORAGE_KEY_SAVED_RESIDENCY_REFRESH_HISTORY, JSON.stringify(info));
    } catch (e) {
        logInfo(INFO_LOGGER, 'Error saving residency info to local storage on reload: ', JSON.stringify(e));
    }
};

export const loadResidencyRefreshHistory = async () => {
    try {
        return getItem(STORAGE_KEY_SAVED_RESIDENCY_REFRESH_HISTORY);
    } catch (e) {
        logInfo(INFO_LOGGER, 'Error loading residncy refresh history from local storage: ', JSON.stringify(e));
    }
};

export const removeResidencyRefreshHistory = async () => {
    try {
        return await removeItem(STORAGE_KEY_SAVED_RESIDENCY_REFRESH_HISTORY);
    } catch (e) {
        logInfo(INFO_LOGGER, 'Error removing residency refresh history from local storage: ', JSON.stringify(e));
    }
};

export const GetNavigationState = () => {
    return useNavigationState(state => state);
};

export const saveWebHistoryOnRefresh = (navigationState: any) => {
    if (Platform.OS === 'web') {
        window.onbeforeunload = () => {
            saveNavHistory(navigationState);
        };
    }
};

export const saveWebResidencyOnRefresh = (resInfo: residencyInfo) => {
    if (Platform.OS === 'web') {
        window.onbeforeunload = () => {
            saveResidencyRefreshHistory(resInfo);
        };
    }
};

export const resetNavigationState = (navigation: any, oldNavigationState: any) => {
    loadNavHistory().then(history => {
        let routeHistory: any = null;
        if (history) {
            routeHistory = JSON.parse(history || '{}');
        }
        if (routeHistory && routeHistory.routes?.length > 1 && oldNavigationState.routes?.length === 1) {
            navigation.reset(routeHistory);
        }
        removeNavHistory();
    });
};

interface NavigationState {
    key: string;
    routes: {
        key: string;
        name: string;
        params?: any;
        state?: {
            key?: string;
            routes?: Route[];
        };
    }[];
}

interface Route {
    key?: string;
    name?: string;
}

export const IsNavigationRouteOnStack = (navigationRoute: string): boolean => {
    return useNavigationState((state: NavigationState) => state).routes.some(
        (route: Route) => route.name === navigationRoute,
    );
};

// The difference between this and IsNavigationRouteOnStack is that this will look for which stack the main page is on, not the component.
// Eg. If you are in more, IsNavigationRouteOnStack will say that the LatestNotifications is in LoggedIn stack. This function will return more stack
export const IsNavigationRouteOnDeepStack = (navigationRoute: string): boolean => {
    return (
        useNavigationState((state: NavigationState) => state).routes[0]?.state?.routes?.some(
            (route: Route) => route.name === navigationRoute,
        ) || false
    );
};

export const navigationRoutes = {
    homeRoutes: {
        home: 'Home',
        comingSoon: 'ComingSoon',
        leaseSelector: 'LeaseSelector',
        transactions: 'StatementsAndBalanceUpdates',
        statementDetail: 'StatementDetail',
        paymentDetails: 'PaymentDetails',
        paymentConfirmation: 'PaymentConfirmation',
        makeAPayment: 'MakeAPayment',
        autoPay: 'AutoPay',
        paymentScreen: 'PaymentScreen',
        zegoPaymentScreen: 'ZegoPaymentScreen',
        zegoPaymentReview: 'ZegoPaymentReview',
        previousStatements: 'PreviousStatements',
        paymentPOC: 'PaymentPOC',
        addDebitCreditCard: 'AddDebitCreditCard',
        myRequests: 'MyRequests',
        requestDetail: 'RequestDetail',
        createARequest: 'CreateARequest',
        createARequest2: 'CreateARequest2',
        profile: 'Profile',
        createATicket: 'CreateATicket',
        requestMessage: 'RequestMessage',
        cancelRequest: 'CancelRequest',
        reopenRequest: 'ReopenRequest',
        confirmScreen: 'ConfirmScreen',
        smartPackage: 'SMARTPACKAGE',
        smartPackageHistory: 'SMARTPACKAGEHistory',
        noticeToVacateRequest: 'NoticeToVacateRequest',
        sodaRecipient: 'SodaRecipient',
        addressPicker: 'AddressPicker',
        noticeToVacatePreferContact: 'NoticeToVacatePreferContact',
        noticeToVacateEdit: 'NoticeToVacateEdit',
        noticeToVacateCancel: 'NoticeToVacateCancel',
        contractAndDocuments: 'ContractAndDocuments',
        addAVisitor: 'AddAVisitor',
        manageMyLease: 'ManageMyLease',
        renewMyLease: 'RenewMyLease',
        renewalSigning: 'RenewalSigning',
    },
    moreRoutes: {
        more: 'More',
        profile: 'Profile',
        paymentMethods: 'PaymentMethods',
        pets: 'Pets',
        visitors: 'Visitors',
        awayTimes: 'AwayTimes',
        addEditAwayTime: 'AddEditAwayTime',
        addAwayTime: 'AddAwayTime',
        editAwayTime: 'EditAwayTime',
        referrals: 'Referrals',
        communityInfo: 'CommunityInfo',
        communityPolicyDocuments: 'CommunityPolicyDocuments',
        faq: 'FrequentlyAskedQuestions',
        alertsAndNotifications: 'AlertsAndNotifications',
        alertsAndNotificationsOpt: 'AlertsAndNotifications',
        touchID: 'TouchID',
        signOut: 'SignOut',
        testScreen: 'TestScreen',
        primitivesScreen: 'PrimitivesScreen',
        paymentPOC: 'PaymentPOC',
    },
    noticesRoutes: {
        notices: 'Notices',
        community: 'Community',
    },
    notificationRoutes: {
        notifications: 'Notifications',
        leaseNotificationDetail: 'LeaseNotificationDetail',
    },
    smartmoveRoutes: {
        smartMoveApplication: 'SmartMoveApplication',
        smartMoveReserve: 'SmartMoveReserve',
        smartMovePaymentStart: 'SmartMovePaymentStart',
        smartMovePaymentReview: 'SmartMovePaymentReview',
        smartMovePaymentConfirmationProcessing: 'SmartMovePaymentConfirmationProcessing',
        smartMovePaymentConfirmationPaymentReceivedApartmentReserved:
            'SmartMovePaymentConfirmationPaymentReceivedApartmentReserved',
        smartMovePaymentConfirmationPaymentReceivedMoveInPayment:
            'SmartMovePaymentConfirmationPaymentReceivedMoveInPayment',
        smartMovePaymentConfirmationUnsuccessful: 'SmartMovePaymentConfirmationUnsuccessful',
        smartMoveDeposit: 'SmartMoveDeposit',
        smartMoveVehiclesAndPets: 'SmartMoveVehiclesAndPets',
        smartMoveContractPending: 'SmartMoveContractPending',
        smartMoveContractReady: 'SmartMoveContractReady',
        smartMoveContractSigningComplete: 'SmartMoveContractSigningComplete',
        smartMoveUtilitiesAndRentersInsurance: 'SmartMoveUtilitiesAndRentersInsurance',
        smartMoveUtilitiesAndRentersInsuranceEdit: 'SmartMoveUtilitiesAndRentersInsuranceEdit',
        smartMoveMoveInCountdown: 'SmartMoveMoveInCountdown',
        smartMovePayBeforeMoveIn: 'SmartMovePayBeforeMoveIn',
        smartMoveEditInformation: 'SmartMoveEditInformation',
    },
    loggedInRoutes: {
        loggedIn: 'LoggedIn',
        noticeToVacateRequestModal: 'NoticeToVacateRequestModal',
        sodaRecipientModal: 'SodaRecipientModal',
        addressPickerModal: 'AddressPickerModal',
        noticeToVacatePreferContactModal: 'NoticeToVacatePreferContactModal',
        noticeToVacateCancelModal: 'NoticeToVacateCancelModal',
        onboardingModal: 'OnboardingModal',
        leaseSelectorModal: 'LeaseSelectorModal',
        makeAPaymentModal: 'MakeAPaymentModal',
        previousStatementModal: 'PreviousStatementModal',
        paymentScreenModal: 'PaymentScreenModal',
        zegoPaymentScreenModal: 'ZegoPaymentScreenModal',
        zegoPaymentReviewModal: 'ZegoPaymentReviewModal',
        paymentConfirmationModal: 'PaymentConfirmationModal',
        createARequestModal: 'CreateARequestModal',
        createARequestModal2: 'CreateARequestModal2',
        createATicketModal: 'CreateATicketModal',
        profileModal: 'ProfileModal',
        residentIdModal: 'ResidentIdModal',
        addEditAwayTimeModal: 'AddEditAwayTimeModal',
        manageMyLeaseModal: 'ManageMyLeaseModal',
        renewMyLeaseModal: 'RenewMyLeaseModal',
        renewalSigningModal: 'RenewalSigningModal',
    },
    notLoggedInRoutes: {
        login: 'Login',
    },
};
