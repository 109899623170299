import React from 'react';
import { MediumBoldText, RegularText } from 'components/primitives/StyledText';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useStore } from 'contexts/StoreContextProvider';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { observer } from 'mobx-react-lite';
import { useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { useNavigation } from '@react-navigation/native';
import { navigationRoutes } from 'utils/NavigationUtils';
import { SmartmovePaymentInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmovePaymentInfoV1';
import { daysBetween, formatDateStringMonthLabel, parseDateMMDDYYYY } from 'utils/DateUtils';
import Section from 'components/primitives/Section';
import Container from 'components/primitives/Container';
import { View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';

const SmartMoveReserve: React.FC = observer(() => {
    const navigation = useNavigation();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    ///header
    const applicationHeader = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
    );
    const isLoading = applicationHeader?.query.isLoading;
    const paymentInfoV1: SmartmovePaymentInfoV1 | undefined =
        applicationHeader.query.data?.smartmoveApplicationHeaderV1?.paymentInfoV1;

    const hasLastPaymentYn =
        !!paymentInfoV1?.lastSuccessfulPaymentAmount &&
        daysBetween(
            parseDateMMDDYYYY(paymentInfoV1.lastSuccessfulPaymentDate),
            parseDateMMDDYYYY(sessionActiveResidency.propertySpecificDt),
        ) <= 2;

    const _reviewPayment = () => {
        if (paymentInfoV1) {
            if (!hasLastPaymentYn) {
                navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePaymentStart);
            } else {
                uiStore.showAlert({
                    message: `Please note that a payment of ${formatCentsAsDollars(
                        paymentInfoV1.lastSuccessfulPaymentAmount,
                    )} was made on ${formatDateStringMonthLabel(
                        paymentInfoV1.lastSuccessfulPaymentDate,
                    )}. Do you still want to continue and make another payment?`,
                    buttons: [
                        {
                            buttonText: 'No, go back',
                            buttonType: 'secondary',
                            onClick: uiStore.hideAlert,
                        },
                        {
                            buttonText: 'Yes, continue',
                            buttonType: 'primary',
                            onClick: () => {
                                uiStore.hideAlert();
                                navigation.navigate(navigationRoutes.smartmoveRoutes.smartMovePaymentStart);
                            },
                        },
                    ],
                });
            }
        }
    };

    return isLoading || !paymentInfoV1 ? null : (
        <SmartmoveScreenContainer
            footerButtonProps={{
                text: 'Pay Now',
                onClick: _reviewPayment,
            }}>
            <MediumBoldText style={globalStyles.smartMoveTitleText}>Reserve your apartment now</MediumBoldText>
            <RegularText>
                To reserve your apartment, you need to pay your reservation fee. Your reservation fee is{' '}
                {formatCentsAsDollars(paymentInfoV1?.balance || 0)}.
            </RegularText>

            {hasLastPaymentYn && paymentInfoV1 && (
                <Section>
                    <Container borderStyle={'orange'}>
                        <View style={[globalStyles.flexColumnCenter, globalStyles.innerContainer]}>
                            <MaterialCommunityIcons
                                name='alert-circle'
                                size={SmarthubTheme.layout.LARGEICONSIZE}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <RegularText>
                                {`Please note that a payment of ${formatCentsAsDollars(
                                    paymentInfoV1.lastSuccessfulPaymentAmount,
                                )} was made on ${formatDateStringMonthLabel(paymentInfoV1.lastSuccessfulPaymentDate)}`}
                            </RegularText>
                        </View>
                    </Container>
                </Section>
            )}
        </SmartmoveScreenContainer>
    );
});

export default SmartMoveReserve;
