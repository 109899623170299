import * as Linking from 'expo-linking';
import { LinkingOptions } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import { getDeepLinkUrl } from 'utils/PushNotificationUtils';

export const LinkingConfiguration: LinkingOptions = {
    prefixes: [
        Linking.createURL('/'),
        'https://*.venterra.com',
        'https://*.venterraliving.com',
        'https://*.onecornerstone.com',
    ],
    config: {
        screens: {
            Login: 'smarthub/login',
            LoggedIn: {
                path: 'smarthub',
                screens: {
                    Home: {
                        /**
                         * @see HomeStack
                         */
                        screens: {
                            Home: 'home',
                            ComingSoon: 'coming_soon',
                            StatementsAndBalanceUpdates: 'transactions',
                            StatementDetail: 'statement_detail',
                            PaymentDetails: 'payment_detail',
                            PaymentConfirmation: 'payment_confirmation',
                            MakeAPayment: 'make_payment',
                            PaymentScreen: 'payment_screen',
                            ZegoPaymentScreen: 'zego_payment_screen',
                            ZegoPaymentReview: 'zego_payment_review',
                            PreviousStatements: 'previous_statements',
                            MyRequests: 'requests',
                            RequestDetail: 'request_detail',
                            CreateARequest: 'create_request',
                            CreateARequest2: 'create_request_2',
                            Profile: 'profile',
                            CreateATicket: 'create_ticket',
                            ConfirmScreen: 'request_confirm',
                            SMARTPACKAGE: 'packages',
                            SMARTPACKAGEHistory: 'package_history',
                            ContractAndDocuments: 'lease',
                            NoticeToVacateRequest: 'notice_to_vacate_request',
                            NoticeToVacateEdit: 'notice_to_vacate_edit',
                            NoticeToVacateConfirmation: 'notice_to_vacate_confirmation',
                            LeaseSelector: 'lease_selector',
                            ReopenRequest: 'reopen_request',
                            CancelRequest: 'cancel_request',
                            RequestMessage: 'request_message',
                            AutoPay: 'autopay',
                            ManageMyLease: 'manage_my_lease',
                            RenewMyLease: 'renew_my_lease',
                            RenewalSigning: 'renewal_signing',
                        },
                    },
                    Notices: {
                        /**
                         * @see NoticesStack
                         */
                        screens: {
                            Community: 'community',
                        },
                    },
                    Notifications: {
                        /**
                         * @see NotificationsStack
                         */
                        screens: {
                            Notifications: 'notifications',
                        },
                    },
                    More: {
                        /**
                         * @see MoreStack
                         */
                        screens: {
                            More: 'more',
                            Profile: 'more/profile',
                            ContactInfo: 'more/contact_info',
                            AlertsAndNotifications: 'more/alerts_notifications',
                            TestScreen: 'more/test',
                            AwayTimes: 'more/away_times',
                            AddEditAwayTime: 'more/add_edit_away_time',
                            AddAwayTime: 'more/add_away_time',
                            EditAwayTime: 'more/edit_away_time',
                            CommunityInfo: 'more/community_info',
                        },
                    },
                },
            },
            LeaseSelectorModal: 'smarthub/lease_selector_fullscreen',
            MakeAPaymentModal: 'smarthub/make_payment_fullscreen',
            PreviousStatementsModal: 'smarthub/previous_statements_fullscreen',
            PaymentScreenModal: 'smarthub/payment_screen_fullscreen',
            ZegoPaymentScreenModal: 'smarthub/zego_payment_screen_fullscreen',
            ZegoPaymentReviewModal: 'smarthub/zego_payment_review_fullscreen',
            PaymentConfirmationModal: 'smarthub/payment_confirmation_fullscreen',
            CreateARequestModal: 'smarthub/create_request_fullscreen',
            CreateARequest2Modal: 'smarthub/create_request_2_fullscreen',
            CreateATicketModal: 'smarthub/create_ticket_fullscreen',
            AddEditAwayTimeModal: 'smarthub/add_edit_away_time_full_screen',
            ProfileModal: 'smarthub/profile_fullscreen',
            NoticeToVacateConfirmationModal: 'smarthub/notice_to_vacate_confirmation_fullscreen',
            SmartMoveApplication: 'smartmove/application',
            SmartMoveReserve: 'smartmove/reserve',
            SmartMovePaymentStart: 'smartmove/payment_start',
            SmartMovePaymentReview: 'smartmove/payment_review',
            SmartMovePaymentConfirmationProcessing: 'smartmove/payment_processing',
            SmartMovePaymentConfirmationPaymentReceivedApartmentReserved: 'smartmove/payment_received_apt_reserved',
            SmartMovePaymentConfirmationPaymentReceivedMoveInPayment: 'smartmove/payment_received_movein_payment',
            SmartMovePaymentConfirmationUnsuccessful: 'smartmove/payment_unsuccessful',
            SmartMoveDeposit: 'smartmove/damages',
            SmartMoveVehiclesAndPets: 'smartmove/vehicles_and_pets',
            SmartMoveContractPending: 'smartmove/contract_pending',
            SmartMoveContractReady: 'smartmove/contract_ready',
            SmartMoveContractSigningComplete: 'smartmove/contract_signing_complete',
            SmartMoveUtilitiesAndRentersInsurance: 'smartmove/utilities_and_insurance',
            SmartMoveUtilitiesAndRentersInsuranceEdit: 'smartmove/utilities_and_insurance_edit',
            SmartMoveMoveInCountdown: 'smartmove/countdown',
            SmartMovePayBeforeMoveIn: 'smartmove/pay_before_movein',
            SmartMovePaymentConfirmation3: 'smartmove/pay_before_movein_confirm',
            SmartMoveEditInformation: 'smartmove/edit_info',
            ManageMyLeaseModal: 'smarthub/manage_my_lease_modal',
            RenewMyLeaseModal: 'renew_my_lease_modal',
            RenewalSigningModal: 'renewal_signing_modal',
        },
    },
    async getInitialURL(): Promise<string | null> {
        // First, you may want to do the default deep link handling
        // Check if app was opened from a deep link
        const url = await Linking.getInitialURL();

        if (url != null) {
            return url;
        }

        // Handle URL from expo push notifications
        const response = await Notifications.getLastNotificationResponseAsync();
        const pathToScreen = response?.notification.request.content.data.pathToScreen as string | null | undefined;
        return pathToScreen ? getDeepLinkUrl(pathToScreen) : null;
    },
    subscribe(listener) {
        const onReceiveURL = ({ url }: { url: string }) => listener(url);

        // Listen to incoming links from deep linking
        const onReceiveURLSub = Linking.addEventListener('url', onReceiveURL);

        // Listen to expo push notifications
        const subscription = Notifications.addNotificationResponseReceivedListener(response => {
            const pathToScreen = response?.notification.request.content.data.pathToScreen as string | null | undefined;
            if (pathToScreen) {
                // Let React Navigation handle the URL
                listener(getDeepLinkUrl(pathToScreen));
            }
        });

        return () => {
            // Clean up the event listeners
            onReceiveURLSub.remove();
            subscription.remove();
        };
    },
};
