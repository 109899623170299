import React from 'react';
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { DeephansizedRegularText, MediumBoldText } from 'components/primitives/StyledText';
import { Divider, useTheme } from 'react-native-paper';
import Button from 'components/primitives/Button';
import Hyperlink from 'components/primitives/Hyperlink';
import { getRouteParam } from 'utils/routeUtils';
import { useRoute } from '@react-navigation/native';
import OrangeCheckmark from 'components/animations/OrangeCheckmark';
import { globalStyles } from 'theme/style/GlobalStyles';
import * as WebBrowser from 'expo-web-browser';
import { useStore } from 'contexts/StoreContextProvider';
import { testProps } from 'components/ComponentTypes';

const NoticeToVacateConfirmation: React.FC = observer(() => {
    const theme = useTheme();
    const route = useRoute();
    const { userSessionStore } = useStore();
    const expectedMoveOutDt = getRouteParam(route, 'expectedMoveOutDt');

    return (
        <SafeAreaView style={[globalStyles.container, globalStyles.flexColumnCenter]}>
            <ScrollView>
                <View style={[styles.innerContainer, { backgroundColor: theme.colors.background }]}>
                    <OrangeCheckmark lottieViewStyle={styles.animation} />
                    <MediumBoldText
                        style={[globalStyles.sectionSpaceAround, globalStyles.centeredText]}
                        {...testProps('ntv-header')}>
                        {"Thanks, we've received your request!"}
                    </MediumBoldText>
                    <DeephansizedRegularText style={[globalStyles.doubleSpaceBelow, globalStyles.centeredText]}>
                        We have received your request to move out on {expectedMoveOutDt}.
                    </DeephansizedRegularText>
                    <DeephansizedRegularText style={[globalStyles.singleSpaceBelow, globalStyles.centeredText]}>
                        Is there anything we can do to change your mind? We have communities across the Southeastern
                        United States and are ready to welcome you! You can find all of our locations at&nbsp;
                        {Platform.OS === 'web' ? (
                            <Hyperlink
                                onClick={() => {
                                    window.open('https://www.venterraliving.com', '_blank');
                                }}>
                                www.VenterraLiving.com
                            </Hyperlink>
                        ) : (
                            <Hyperlink onClick={() => WebBrowser.openBrowserAsync('https://www.venterraliving.com')}>
                                www.VenterraLiving.com
                            </Hyperlink>
                        )}
                    </DeephansizedRegularText>
                    <DeephansizedRegularText style={[globalStyles.singleSpaceBelow, globalStyles.centeredText]}>
                        If you must move on, we understand. A member of our team will reach out to you soon to confirm
                        your move and to provide you helpful information.
                    </DeephansizedRegularText>
                    <Divider style={[globalStyles.hundowidth, globalStyles.sectionSpaceAround]} />
                    <View style={globalStyles.hundowidth}>
                        <Button
                            {...testProps('continue-button')}
                            style={[globalStyles.hundowidth]}
                            buttonType={'primary'}
                            onClick={() => {
                                //reload residency to get the correct status and lease adjustment
                                userSessionStore.reloadResidencies(true);
                            }}>
                            Continue
                        </Button>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
});

export default NoticeToVacateConfirmation;

const styles = StyleSheet.create({
    innerContainer: {
        padding: SmarthubTheme.layout.SURROUNDMARGIN,
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    animation: {
        width: 200,
        height: 200,
    },
});
