import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useStore } from 'contexts/StoreContextProvider';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { observer } from 'mobx-react-lite';
import ActionMenu, { ActionMenuItem } from 'components/primitives/ActionMenu';
import { navigationRoutes } from 'utils/NavigationUtils';
import { testProps } from 'components/ComponentTypes';
import { useLogResidencyAuditEventMutation } from 'queries/hooks/residency/useLogResidencyAuditEventMutation';

const QuickActionPlusButton = observer(() => {
    const navigation = useNavigation();
    const route = useRoute();
    const { useDrawerNavigation } = useResponsiveContext();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const logResidencyAuditEventMutation = useLogResidencyAuditEventMutation(uiStore, sessionActiveResidency);

    const isAwayTimesAllowed = useStore().userSessionStore.isFeatureEnabled('ParcelPending');
    const [isAwayTimesHidden, setIsAwayTimesHidden] = useState(false);

    useEffect(() => {
        if (hasSessionActiveResidency) {
            setIsAwayTimesHidden(!isAwayTimesAllowed);
        }
    }, [isAwayTimesAllowed, hasSessionActiveResidency]);

    const QuickActionMenuItemsPrevious: Array<ActionMenuItem> = [
        {
            id: '2',
            icon: 'credit-card',
            iconLibrary: 'material',
            title: 'Make a Payment',
            onSelect: () => {
                if (useDrawerNavigation) {
                    if (route.name === navigationRoutes.homeRoutes.home)
                        navigation.navigate(navigationRoutes.homeRoutes.makeAPayment);
                    else
                        navigation.navigate(navigationRoutes.homeRoutes.home, {
                            screen: navigationRoutes.homeRoutes.makeAPayment,
                        });
                } else {
                    navigation.navigate(navigationRoutes.loggedInRoutes.makeAPaymentModal);
                }
            },
        },
    ];

    const QuickActionMenuItems: Array<ActionMenuItem> = [
        {
            id: '2',
            icon: 'credit-card',
            iconLibrary: 'material',
            title: 'Make a Payment',
            testID: 'quick-make-a-payment',
            onSelect: () => {
                if (useDrawerNavigation) {
                    if (route.name === navigationRoutes.homeRoutes.home)
                        navigation.navigate(navigationRoutes.homeRoutes.makeAPayment);
                    else
                        navigation.navigate(navigationRoutes.homeRoutes.home, {
                            screen: navigationRoutes.homeRoutes.makeAPayment,
                        });
                } else {
                    navigation.navigate(navigationRoutes.loggedInRoutes.makeAPaymentModal);
                }
            },
        },
        {
            id: '3',
            icon: 'build',
            iconLibrary: 'material',
            title: 'Create a Maintenance Request',
            testID: 'quick-maintenance-request',
            onSelect: () => {
                useDrawerNavigation
                    ? navigation.navigate(navigationRoutes.homeRoutes.home, {
                          screen: navigationRoutes.homeRoutes.createARequest,
                      })
                    : navigation.navigate(navigationRoutes.loggedInRoutes.createARequestModal);
            },
        },
        {
            id: '4',
            icon: 'chat',
            iconLibrary: 'material',
            title: 'Send Us a Message',
            testID: 'quick-send-a-message',
            onSelect: () => {
                useDrawerNavigation
                    ? navigation.navigate(navigationRoutes.homeRoutes.home, {
                          screen: navigationRoutes.homeRoutes.createATicket,
                      })
                    : navigation.navigate(navigationRoutes.loggedInRoutes.createATicketModal);
            },
        },
        {
            /* This is what shows up in the plus icon */
            id: '6',
            icon: 'calendar-clock',
            iconLibrary: 'materialcommunity',
            title: 'Add a New Away Time',
            testID: 'quick-away-time',
            onSelect: () => {
                useDrawerNavigation
                    ? navigation.navigate(navigationRoutes.moreRoutes.more, {
                          screen: navigationRoutes.moreRoutes.addAwayTime,
                          params: {
                              awayTimeId: 0,
                          },
                      })
                    : navigation.navigate(navigationRoutes.loggedInRoutes.addEditAwayTimeModal, {
                          params: {
                              awayTimeId: 0,
                              title: 'Add a New Away Time',
                          },
                      });
            },
            hidden: isAwayTimesHidden,
        },
        {
            id: '1',
            icon: 'format-list-bulleted',
            iconLibrary: 'materialcommunity',
            title: 'Manage My Lease',
            testID: 'quick-manage-my-lease',
            onSelect: () => {
                logResidencyAuditEventMutation.mutate({
                    auditLogId: null,
                    eventType: 'SmarthubManageMyLease',
                    currentValue: '',
                    newValue: '',
                });

                useDrawerNavigation
                    ? navigation.navigate(navigationRoutes.homeRoutes.home, {
                          screen: navigationRoutes.homeRoutes.manageMyLease,
                      })
                    : navigation.navigate(navigationRoutes.loggedInRoutes.manageMyLeaseModal);
            },
            hidden: !(sessionActiveResidency.allowNTV || sessionActiveResidency.allowRenewal),
        },
        /*{
            id: '5',
            icon: 'credit-card',
            iconLibrary: 'material',
            title: 'Add a Debit/Credit Card',
            screen: 'ComingSoon',
            stack: 'Home'
        },
        {
            id: '6',
            icon: 'calendar-clock',
            iconLibrary: 'materialcommunity',
            title: 'Add an Away Time',
            screen: 'ComingSoon',
            stack: 'Home'
        },
        {
            id: '7',
            icon: 'person-pin',
            iconLibrary: 'material',
            title: 'Add a Visitor',
            screen: 'ComingSoon',
            stack: 'Home'
        },*/
    ];
    if (!hasSessionActiveResidency) {
        return null;
    } else {
        return (
            <ActionMenu
                menuTrigger={
                    <View style={[styles.buttoncontainer]} {...testProps('plus-button')}>
                        <Image
                            source={require('../../assets/images/buttons/orange_plus.png')}
                            style={styles.addButtonImage}
                        />
                    </View>
                }
                menuItem={
                    sessionActiveResidency.statusCd === 'Cancelled' || sessionActiveResidency.statusCd === 'Previous'
                        ? QuickActionMenuItemsPrevious
                        : QuickActionMenuItems
                }
            />
        );
    }
});

export default QuickActionPlusButton;

const styles = StyleSheet.create({
    buttoncontainer: {
        height: SmarthubTheme.layout.PLUSBUTTONBOTTOMTAB,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    addButtonImage: {
        height: SmarthubTheme.layout.PLUSBUTTONBOTTOMTAB,
        width: SmarthubTheme.layout.PLUSBUTTONBOTTOMTAB,
        resizeMode: 'contain',
        alignSelf: 'center',
    },
});
