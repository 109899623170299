import { LayoutRectangle, StyleSheet, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import * as React from 'react';
import { useState } from 'react';

interface ProgressIndicatorProps {
    numberOfSteps: number;
    currentStep: number;
    currentStepInProgress?: boolean;
}

const defaultProps: ProgressIndicatorProps = {
    numberOfSteps: 1,
    currentStep: 1,
};

const ProgressIndicator: React.FC<ProgressIndicatorProps> = (props: ProgressIndicatorProps) => {
    const _buildRows = () => {
        const rows = [];
        for (let i = 0; i < props.numberOfSteps; i++) {
            const stepActive = i < props.currentStep;
            const inProgress =
                (props.currentStep === i && props.currentStepInProgress) ||
                (i < props.currentStep && props.currentStepInProgress);
            rows.push(<Step key={i} stepIndex={i} active={stepActive} inProgress={inProgress} />);
        }
        return rows;
    };

    return <View style={styles.container}>{_buildRows()}</View>;
};

interface StepProps {
    stepIndex: number;
    active?: boolean;
    inProgress?: boolean;
}

const Step: React.FC<StepProps> = (props: StepProps) => {
    const [leftLineWidth, setLeftLineWidth] = useState(0);

    // Style left hand side of step line/50% overlay
    const lineLeftStyle =
        props.active || props.inProgress
            ? [styles.line, styles.lineLeftActive, { width: leftLineWidth }]
            : [styles.line, styles.lineLeft, { width: leftLineWidth }];
    // Style full step line
    const lineStyle = props.active ? [styles.line, styles.lineActive] : styles.line;
    const chipStyle = props.active ? [styles.chip, styles.chipActive] : styles.chip;

    // Calculate full px width of step and set the left line width accordingly, accounting for width of chip
    const _calculateStepWidthAndSetLineLeftWidth = (layout: LayoutRectangle) => {
        const totalWidth = layout.width;
        setLeftLineWidth(totalWidth / 2 - 13);
    };

    return (
        <View
            style={styles.step}
            onLayout={event => {
                _calculateStepWidthAndSetLineLeftWidth(event.nativeEvent.layout);
            }}>
            <View style={lineLeftStyle} />
            <View style={lineStyle} />
            <View style={chipStyle}>
                {props.active && (
                    <MaterialCommunityIcons
                        name={'check'}
                        size={16}
                        style={styles.chipCheck}
                        color={SmarthubTheme.colors.white}
                    />
                )}
            </View>
        </View>
    );
};

ProgressIndicator.defaultProps = defaultProps;

export default ProgressIndicator;

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 60,
    },
    step: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        textAlign: 'center',
        justifyContent: 'center',
    },
    line: {
        position: 'absolute',
        zIndex: 100,
        width: '100%',
        left: 0,
        height: 6,
        marginTop: 10,
        backgroundColor: SmarthubTheme.colors.lightestgrey,
    },
    lineLeft: {
        zIndex: 101,
        width: 0,
        height: 6,
    },
    lineLeftActive: {
        zIndex: 101,
        width: 0,
        height: 6,
        backgroundColor: SmarthubTheme.colors.lightblue,
    },
    lineActive: {
        backgroundColor: SmarthubTheme.colors.lightblue,
    },
    chip: {
        position: 'absolute',
        zIndex: 102,
        right: 0,
        width: 26,
        height: 26,
        borderWidth: 4,
        borderColor: SmarthubTheme.colors.lightestgrey,
        backgroundColor: SmarthubTheme.colors.white,
        borderRadius: 12,
    },
    chipActive: {
        borderColor: SmarthubTheme.colors.lightblue,
        backgroundColor: SmarthubTheme.colors.lightblue,
    },
    chipCheck: {
        position: 'absolute',
        top: 1,
        left: 1,
    },
});
