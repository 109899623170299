import { SmartHubResidentContactInfoV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/requests/SmartHubResidentContactInfoV1';
import { SmarthubRequestStatus } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubRequestStatus';
import { TagTypes } from 'components/primitives/TagTypes';
import { SmarthubRequestType } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/dataobjects/SmarthubRequestType';
import { InputDropdownOption } from 'components/input/InputDropdown';

export const getContactMethodOptions = (
    contactInfo: SmartHubResidentContactInfoV1,
    allowOther?: boolean,
): Array<InputDropdownOption> => {
    const result: Array<InputDropdownOption> = [];
    if (contactInfo.phone1No) {
        result.push({
            label:
                contactInfo.phone1TypeCd === 'NotSpecified'
                    ? contactInfo.phone1No
                    : `${contactInfo.phone1TypeCd}: ${contactInfo.phone1No}`,
            value: contactInfo.phone1No,
        });
    }

    if (contactInfo.phone2No) {
        result.push({
            label:
                contactInfo.phone2TypeCd === 'NotSpecified'
                    ? contactInfo.phone2No
                    : `${contactInfo.phone2TypeCd}: ${contactInfo.phone2No}`,
            value: contactInfo.phone2No,
        });
    }

    if (contactInfo.email) {
        result.push({ label: contactInfo.email, value: contactInfo.email });
    }

    if (allowOther) {
        result.push({
            label: 'Other',
            value: 'other',
        });
    }
    return result;
};

export function getTagTypeFromStatus(status: SmarthubRequestStatus): TagTypes {
    switch (status) {
        case 'AwaitingParts':
            return TagTypes.Blue;
        case 'InProgress':
            return TagTypes.Blue;
        case 'Completed':
            return TagTypes.DarkGrey;
        default:
            return TagTypes.DarkGrey;
    }
}

export function getRequestTitle(type: SmarthubRequestType, requestId: number): string {
    return type === 'MaintenanceRequest' ? `Maintenance Req. ${requestId}` : `SMARTDESK ${requestId}`;
}
