import React from 'react';

//Helper that allows less boilerplate code upon usage for always checking for undefined, etc, etc...
//REF: https://www.carlrippon.com/react-context-with-typescript-p4/
export const createCtx = <ContextType>() => {
    const ctx = React.createContext<ContextType | undefined>(undefined);

    const useCtx = (): ContextType => {
        const c = React.useContext(ctx);
        if (!c) throw new Error('useCtx must be inside a Provider with a value');
        return c;
    };

    return [useCtx, ctx.Provider] as const;
};
