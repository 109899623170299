import { createSmarthubRequestSubmitData } from '../../businesslogic/smarthub/dataobjects/SmarthubRequestSubmitData';
import { SmarthubRequestSubmitData } from '../../businesslogic/smarthub/dataobjects/SmarthubRequestSubmitData';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SubmitRequestResultV1 } from './SubmitRequestResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.SubmitRequestCommandV1
 */
export interface SubmitRequestCommandV1 extends AbstractResidencyAuthenticatedCommand<SubmitRequestResultV1> {
    data: SmarthubRequestSubmitData;
}

export function createSubmitRequestCommandV1(): SubmitRequestCommandV1 {
    return {
        data: createSmarthubRequestSubmitData(),
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.SubmitRequestCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
