import { createSmarthubResidentV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentV1';
import { SmarthubResidentV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentV1';
import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { AbstractVersionedRemoteResult } from '../../../remotecmd/versioned/AbstractVersionedRemoteResult';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.UpdateResidentProfileCommandV1
 */
export interface UpdateResidentProfileCommandV1
    extends AbstractResidentAuthenticatedCommand<AbstractVersionedRemoteResult> {
    resident: SmarthubResidentV1;
    guarantorAccessEnabledList: Array<number | null>;
}

export function createUpdateResidentProfileCommandV1(): UpdateResidentProfileCommandV1 {
    return {
        resident: createSmarthubResidentV1(),
        guarantorAccessEnabledList: [],
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.UpdateResidentProfileCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
