import { SmarthubRequestType } from '../../businesslogic/smarthub/dataobjects/SmarthubRequestType';
import { AbstractRequestAuthenticatedCommand } from '../AbstractRequestAuthenticatedCommand';
import { GetRequestDetailResultV1 } from './GetRequestDetailResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.GetRequestDetailCommandV1
 */
export interface GetRequestDetailCommandV1 extends AbstractRequestAuthenticatedCommand<GetRequestDetailResultV1> {
    type: SmarthubRequestType;
}

export function createGetRequestDetailCommandV1(): GetRequestDetailCommandV1 {
    return {
        type: 'MaintenanceRequest',
        propertyCd: '',
        requestId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.GetRequestDetailCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
