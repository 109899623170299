import React from 'react';
import { Linking } from 'react-native';
import Hyperlink from 'components/primitives/Hyperlink';

export interface EmailLinkProps {
    testID?: string;
    email: string;
    smallText?: boolean;
}

const EmailLink: React.FC<EmailLinkProps> = (props: EmailLinkProps) => {
    return (
        <Hyperlink
            smallText={props.smallText}
            onClick={() => Linking.openURL(`mailto:${props.email}`)}
        >{`${props.email}`}</Hyperlink>
    );
};

export default EmailLink;
