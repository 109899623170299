import { PhoneNoType } from '../../../../shared/businesslogic/residency/dataobjects/PhoneNoType';
import { PreferredCommunicationMethod } from '../../../../shared/businesslogic/residency/dataobjects/PreferredCommunicationMethod';
import { ResidentType } from '../../../../shared/businesslogic/residency/dataobjects/ResidentType';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.residency.SmarthubResidentV1
 */
export interface SmarthubResidentV1 {
    residentId: number;
    propertyCd: string;
    firstName: string;
    lastName: string;
    phone1No: string;
    phone1TypeCd: PhoneNoType;
    phone1Notes: string;
    phone2No: string;
    phone2TypeCd: PhoneNoType;
    phone2Notes: string;
    email: string;
    typeCd: ResidentType;
    smarthubAccessEnabledYn: boolean;
    preferredCommunicationMethodCd: PreferredCommunicationMethod;
}

export function createSmarthubResidentV1(): SmarthubResidentV1 {
    return {
        residentId: 0,
        propertyCd: '',
        firstName: '',
        lastName: '',
        phone1No: '',
        phone1TypeCd: 'NotSpecified',
        phone1Notes: '',
        phone2No: '',
        phone2TypeCd: 'NotSpecified',
        phone2Notes: '',
        email: '',
        typeCd: 'Applicant',
        smarthubAccessEnabledYn: false,
        preferredCommunicationMethodCd: 'None',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
