import * as React from 'react';
import { View, StyleSheet, Image, TouchableWithoutFeedback, StyleProp } from 'react-native';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { testProps } from 'components/ComponentTypes';
import { SmarthubTheme } from 'theme/SmarthubTheme';

export interface InputRadioSimpleProps {
    testID?: string;
    value: string;
    active: boolean;
    onClick?: (value: string) => void;
    style?: StyleProp<any>;
}

const InputRadioSimple: React.FC<InputRadioSimpleProps> = (props: InputRadioSimpleProps) => {
    const _setActive = () => {
        logInfo(INFO_LOGGER, props.value);

        if (props.onClick && !props.active) {
            props.onClick(props.value);
        }
    };

    return (
        <TouchableWithoutFeedback
            testID={props.testID}
            onPress={() => {
                _setActive();
            }}
        >
            <View style={styles.hotspot}>
                <View style={props.style}>
                    {props.active ? (
                        <Image
                            {...testProps((props.testID ? props.testID : 'generic-radio') + '-selected-icon')} //'generic-radio-selected-icon'
                            style={styles.imageRadio}
                            source={require('../../../assets/images/radio-selected.png')}
                        />
                    ) : (
                        <Image
                            {...testProps((props.testID ? props.testID : 'generic-radio') + '-unselected-icon')} //generic-radio-unselected-icon
                            style={styles.imageRadio}
                            source={require('../../../assets/images/radio.png')}
                        />
                    )}
                    {/*<View style={styles.inputRadioButton}>*/}
                    {/*  <RNPRadioButton value={option.value} />*/}
                    {/*</View>*/}
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
};

export default InputRadioSimple;

const styles = StyleSheet.create({
    imageRadio: {
        width: 16,
        height: 16,
        marginLeft: 8,
    },
    hotspot: {
        width: 32,
        height: 32,
        justifyContent: 'center',
    },
});
