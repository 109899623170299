import { createSmarthubAwayTimeV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubAwayTimeV1';
import { SmarthubAwayTimeV1 } from '../../businesslogic/smarthub/versioneddataobjects/residency/SmarthubAwayTimeV1';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SubmitAwayTimeResultV1 } from './SubmitAwayTimeResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitAwayTimeCommandV1
 */
export interface SubmitAwayTimeCommandV1 extends AbstractResidencyAuthenticatedCommand<SubmitAwayTimeResultV1> {
    data: SmarthubAwayTimeV1;
}

export function createSubmitAwayTimeCommandV1(): SubmitAwayTimeCommandV1 {
    return {
        data: createSmarthubAwayTimeV1(),
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.SubmitAwayTimeCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
