import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { GetSmartmoveApplicationHeaderResultV1 } from './GetSmartmoveApplicationHeaderResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.smartmove.GetSmartmoveApplicationHeaderCommandV1
 */
export interface GetSmartmoveApplicationHeaderCommandV1
    extends AbstractResidencyAuthenticatedCommand<GetSmartmoveApplicationHeaderResultV1> {
    payLeaseRedirUrl: string;
    smarthubWebUrl: string;
    residentId: number;
}

export function createGetSmartmoveApplicationHeaderCommandV1(): GetSmartmoveApplicationHeaderCommandV1 {
    return {
        payLeaseRedirUrl: '',
        smarthubWebUrl: '',
        residentId: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.smartmove.GetSmartmoveApplicationHeaderCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
