import React, { useEffect } from 'react';
import { Platform, View } from 'react-native';
import { FormLabelText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import { useStore } from 'contexts/StoreContextProvider';
import Button from 'components/primitives/Button';
import { observer } from 'mobx-react-lite';
import InputString from 'components/input/InputString';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
    ReOpenRequestReason,
    ReOpenRequestReasonOptions,
} from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/maintenance/dataobjects/ReOpenRequestReason';
import InputDropdown from 'components/input/InputDropdown';
import Section from 'components/primitives/Section';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useConfirmLeave from 'utils/useConfirmLeave';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { generateReopenRequestCMD, useReopenRequest } from 'screens/home/requests/RequestsHooks';
import { useRoute } from '@react-navigation/native';
import { getRouteParams } from 'utils/routeUtils';

type ReopenRequestProps = {
    navigation: StackNavigationProp<any>;
};

type FormData = {
    reason: ReOpenRequestReason;
    reasonDetail: string;
};

const schema = yup.object().shape({
    reason: yup.string().required('Please select an option'),
    reasonDetail: yup.string().when('reason', {
        is: 'Other',
        then: yup.string().max(1024, 'Max length is 1024 characters').required('Please provide more details'),
        otherwise: yup.string(),
    }),
});

const ReopenRequest: React.FC<ReopenRequestProps> = observer(({ navigation }: ReopenRequestProps) => {
    const { userSessionStore, uiStore } = useStore();
    const { smarthubUser, confirmActiveResidency, sessionActiveResidency } = userSessionStore;

    const route = useRoute();
    const { requestId, requestType } = getRouteParams(route) || null;

    const onSubmit = async (data: FormData) => {
        if (requestId) {
            uiStore.showActivityLoader();
            reopenRequestMutation.mutate(
                generateReopenRequestCMD(
                    sessionActiveResidency.propertyCd,
                    requestId,
                    sessionActiveResidency.residentId,
                    data.reason,
                    data.reasonDetail,
                ),
                {
                    onSettled: () => {
                        uiStore.hideActivityLoader();
                    },
                },
            );
        }
    };

    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            reason: 'ProblemNotSolved',
            reasonDetail: '',
        },
        validationSchema: schema,
        onSubmit: (values: FormData) => {
            if (!uiStore.activityLoaderVisible) {
                formik.resetForm({ values: formik.values });
                formik.setSubmitting(true);
                onSubmit(values);
            }
        },
    });

    const reopenRequestMutation = useReopenRequest(navigation, formik, requestId, requestType);

    useConfirmLeave(navigation, formik);

    useEffect(() => {
        confirmActiveResidency(navigation);
    }, [smarthubUser.authorizedResidencies, sessionActiveResidency.key]);

    return (
        <KeyboardAwareScrollView
            keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
            enableResetScrollToCoords={false}
            contentContainerStyle={globalStyles.keyboardAwareScrollView}
        >
            <View style={globalStyles.container}>
                <FormLabelText style={[globalStyles.doubleSpaceBelow]}>
                    {'Reason for Re-Opening Maintenance Request:'}
                </FormLabelText>

                <InputDropdown
                    options={ReOpenRequestReasonOptions}
                    selectedValue={formik.values.reason}
                    onChange={reason => {
                        formik.setFieldValue('reason', reason, false);
                        if (reason !== 'Other') {
                            formik.setFieldValue('reasonDetail', '', false);
                        }
                    }}
                />

                {formik.values.reason === 'Other' && (
                    <Section>
                        <FormLabelText style={globalStyles.doubleSpaceBelow}>Details:</FormLabelText>
                        <InputString
                            onChange={formik.handleChange('reasonDetail')}
                            onBlur={formik.handleBlur('reasonDetail')}
                            initialValue={formik.values.reasonDetail}
                            multiline={true}
                            numberOfLines={6}
                            maxLength={1024}
                            error={!!formik.errors.reasonDetail}
                            errorMessage={formik.errors.reasonDetail}
                        />
                    </Section>
                )}

                <View style={globalStyles.bottomButton}>
                    <Section>
                        <Button
                            {...testProps('reopen-request-button')}
                            inactive={
                                uiStore.activityLoaderVisible ||
                                sessionActiveResidency.adminYn ||
                                reopenRequestMutation.isLoading
                            }
                            onClick={formik.handleSubmit}
                        >{`Re-Open Maintenance Request`}</Button>
                    </Section>
                </View>
            </View>
        </KeyboardAwareScrollView>
    );
});

export default ReopenRequest;
