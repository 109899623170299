import React from 'react';
import { StyleSheet, StyleProp } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { RegularText, SmallText } from 'components/primitives/StyledText';

interface HyperlinkProps {
    testID?: string;
    accessibilityLabel?: string;
    children?: React.ReactNode;
    onClick?: () => void;
    style?: StyleProp<any>;
    smallText?: boolean;
}

const defaultProps: HyperlinkProps = {};

const Hyperlink: React.FC<HyperlinkProps> = (props: HyperlinkProps) => {
    const _handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    if (props.smallText) {
        return (
            <SmallText
                dataDetectorType={'none'}
                testID={props.testID}
                accessibilityLabel={props.accessibilityLabel}
                onPress={() => {
                    _handleClick();
                }}
                style={[styles.link, props.style]}>
                {props.children}
            </SmallText>
        );
    } else {
        return (
            <RegularText
                dataDetectorType={'none'}
                testID={props.testID}
                accessibilityLabel={props.accessibilityLabel}
                onPress={() => {
                    _handleClick();
                }}
                style={[styles.link, props.style]}>
                {props.children}
            </RegularText>
        );
    }
};

Hyperlink.defaultProps = defaultProps;

export default Hyperlink;

const styles = StyleSheet.create({
    link: {
        color: SmarthubTheme.colors.lightblue,
    },
});
