import { SmartmoveRenterInsuranceV1 } from '../../businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveRenterInsuranceV1';
import { SmartmoveUtilityV1 } from '../../businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveUtilityV1';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SubmitSmartmoveUtilitiesAndRenterInsurancesResultV1 } from './SubmitSmartmoveUtilitiesAndRenterInsurancesResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.smartmove.SubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1
 */
export interface SubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1
    extends AbstractResidencyAuthenticatedCommand<SubmitSmartmoveUtilitiesAndRenterInsurancesResultV1> {
    updatedResidentId: number;
    utilities: Array<SmartmoveUtilityV1>;
    insurances: Array<SmartmoveRenterInsuranceV1>;
}

export function createSubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1(): SubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1 {
    return {
        updatedResidentId: 0,
        utilities: [],
        insurances: [],
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.smartmove.SubmitSmartmoveUtilitiesAndRenterInsurancesCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
