import { createSmarthubTicketSubmitData } from '../../businesslogic/smarthub/dataobjects/SmarthubTicketSubmitData';
import { SmarthubTicketSubmitData } from '../../businesslogic/smarthub/dataobjects/SmarthubTicketSubmitData';
import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SubmitTicketResultV1 } from './SubmitTicketResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.SubmitTicketCommandV1
 */
export interface SubmitTicketCommandV1 extends AbstractResidencyAuthenticatedCommand<SubmitTicketResultV1> {
    data: SmarthubTicketSubmitData;
}

export function createSubmitTicketCommandV1(): SubmitTicketCommandV1 {
    return {
        data: createSmarthubTicketSubmitData(),
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.SubmitTicketCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
