export function blankIfNotSet(s: string | null): string {
    return s ? s : '';
}

export function stringIsEmpty(s: string | null): boolean {
    return s == null || s.trim() === '';
}

export function defaultIfEmpty(s: string | null, defaultValue: string): string {
    return s == null || s.trim() === '' ? defaultValue : s;
}

export function intToString(n: number | null): string {
    return n ? n.toFixed(0) : '';
}

export function stringIsNotEmpty(s: string | null): boolean {
    return s != null && s.trim() !== '';
}

export function spaceWordsOnCapitals(s: string): string {
    let o = '';

    for (let i = 0; i < s.length; i++) {
        const c = s.charAt(i);

        if (i !== 0 && c === c.toUpperCase()) {
            o += ' ';
        }
        o += c;
    }
    return o;
}

export function toCamelCase(s: string): string {
    if (!s) return '';

    const sarr = s.toLowerCase().split(' ');
    for (let i = 0; i < sarr.length; i++) {
        sarr[i] = sarr[i].charAt(0).toUpperCase() + sarr[i].slice(1);
    }
    return sarr.join(' ');
}

export function stripNonStandardSpecialCharacters(input: string): string {
    return input
        .replace(/[\u2018\u2019]/g, "'")
        .replace(/[\u201C\u201D]/g, '"')
        .replace(/[^a-zA-Z0-9\s!@#$%&*()_+-={}|^:;\\/\][~?.,'"<>]/gi, '?');
}

export function truncate(s: string, maxLen: number, continuationIndicator: string) {
    const contLength = continuationIndicator.length;

    if (s.length > maxLen) {
        return s.substring(0, maxLen - contLength - 1) + continuationIndicator;
    } else return s;
}

export function buildDateTimeString(dt: string | null, hours: number | null, minutes: number | null): string {
    if (null == dt) {
        return '';
    }

    let fullDateString = dt.split('T')[0];
    fullDateString += 'T';
    fullDateString += null != hours ? hours : '00';
    fullDateString += ':';
    fullDateString += null != minutes ? minutes : '00';
    fullDateString += ':00.000';

    return fullDateString;
}

export function isNumeric(n: string): boolean {
    return !isNaN(Number(n));
}

export function formatPhoneNo(numberToFormat: string): string {
    let phoneNo = numberToFormat.replace(/\D/g, '');
    const match = phoneNo.match(/^(\d{1,3})(\d{0,3})(\d{0,4})(\d{0,4})$/);
    if (match) {
        phoneNo = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? ' - ' : ''}${match[3]}${
            match[4] ? ' x' : ''
        }${match[4]}`;
    }
    return phoneNo;
}

export function stripCountryCode(numberToFormat: string): string {
    if (numberToFormat.startsWith('+1')) {
        return numberToFormat.substring(2);
    } else return numberToFormat;
}
