import React, { useRef } from 'react';
import { View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import type { TooltipOverlayConfig } from 'components/primitives/TooltipOverlay';
import { getItem, setItem } from 'utils/storage';

interface TooltipProps {
    testID?: string;
    accessibilityLabel?: string;
    plinthWidth?: number | string;
    plinthAlignment?: 'top' | 'right' | 'bottom' | 'left'; // Alignment of tooltip to spotlight
    plinthText?: () => React.ReactNode;
    plinthDismissText?: string;
    spotlightShape?: 'rectangle' | 'circle';
    storageKey?: string;
    children?: React.ReactNode;
}

const defaultProps: TooltipProps = {
    testID: 'tooltip-testID',
    accessibilityLabel: 'tooltip-accesssibilityLabel',
    plinthAlignment: 'top',
    plinthDismissText: 'Okay, got it!',
    spotlightShape: 'rectangle',
};

const Tooltip: React.FC<TooltipProps> = observer((props: TooltipProps) => {
    const { uiStore } = useStore();

    const tooltipWrapper = useRef<View>(null);

    React.useEffect(() => {
        return () => {
            uiStore.hideTooltip();
        };
    }, []);

    // Check storage key
    const _verifyStorageKey = async () => {
        // If we have defined a storage key, see if it's been set and, if not, set it and show tooltip
        if (props.storageKey) {
            const storageKey = await getItem(props.storageKey);

            if (!storageKey) {
                await setItem(props.storageKey, 'true');
                await _getChildCoords();
            }
            // If we do not have a storage key, show tooltip
        } else {
            await _getChildCoords();
        }
    };

    // Get size and coordinates of wrapped element and set tooltip
    const _getChildCoords = async () => {
        setTimeout(() => {
            if (tooltipWrapper.current) {
                tooltipWrapper.current.measureInWindow(_setTooltipOverlayCoords);
            }
        }, 500);
    };

    const _setTooltipOverlayCoords = (absX: number, absY: number, width: number, height: number) => {
        const tooltipConfig: TooltipOverlayConfig = {
            plinthWidth: props.plinthWidth,
            plinthAlignment: props.plinthAlignment,
            plinthText: props.plinthText,
            plinthDismissText: props.plinthDismissText,
            testID: props.accessibilityLabel,
            accessibilityLabel: props.accessibilityLabel,
            spotlightShape: props.spotlightShape,
            spotlightWidth: width,
            spotlightHeight: height,
            spotlightX: absX,
            spotlightY: absY,
        };

        uiStore.showTooltip(tooltipConfig);
    };

    return (
        <View>
            <View
                testID={props.testID}
                accessibilityLabel={props.testID}
                ref={tooltipWrapper}
                onLayout={_verifyStorageKey}>
                {props.children}
            </View>
        </View>
    );
});

Tooltip.defaultProps = defaultProps;

export default Tooltip;
