import React from 'react';
import { Linking } from 'react-native';
import Hyperlink from 'components/primitives/Hyperlink';

export interface PhoneLinkProps {
    testID?: string;
    phoneNumber: string;
    smallText?: boolean;
}

const PhoneLink: React.FC<PhoneLinkProps> = (props: PhoneLinkProps) => {
    return (
        <Hyperlink
            smallText={props.smallText}
            onClick={() => Linking.openURL(`tel:${props.phoneNumber}`)}
        >{`${props.phoneNumber}`}</Hyperlink>
    );
};

export default PhoneLink;
