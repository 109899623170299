import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { LargeText, MediumBoldText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { globalStyles } from 'theme/style/GlobalStyles';
import { getTimezoneOffsetHour, parseDateMMDDYYYY, timeBetweenDatesDHM } from 'utils/DateUtils';

type SmartMoveCountdownProps = {
    fontStyle: string;
    asOfDate: Date;
    targetDate: string;
    timezoneCd: string | undefined;
};

/**
 * This component measures time from props.asOfDate to targetDate at 9am and displays
 * a countdown in days, hours and minutes.
 *
 * @param props
 * @constructor
 */
const SmartMoveCountdown: React.FC<SmartMoveCountdownProps> = (props: SmartMoveCountdownProps) => {
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [hoursRemaining, setHoursRemaining] = useState(0);
    const [minsRemaining, setMinsRemaining] = useState(0);

    const currentTimezoneOffset = getTimezoneOffsetHour(props.asOfDate);

    useEffect(() => {
        const setTimeRemaining = () => {
            // make target date a Date() and add 9 hours because we're measuring the Date at 9AM
            const targetDt = parseDateMMDDYYYY(props.targetDate);
            targetDt !== undefined ? targetDt.setHours(targetDt?.getHours() + 9) : null;

            const resultObj = timeBetweenDatesDHM(
                props.asOfDate,
                targetDt !== undefined ? targetDt : new Date(),
                currentTimezoneOffset,
                props.timezoneCd,
            );

            setDaysRemaining(resultObj.days);
            setHoursRemaining(resultObj.hours);
            setMinsRemaining(resultObj.mins);
        };

        setTimeRemaining();
    }, [props]);

    if (daysRemaining < 0 || hoursRemaining < 0 || minsRemaining < 0) {
        return (
            <>
                <View>
                    <MediumBoldText style={[globalStyles.centeredText, styles.paragraphText]}>
                        You&apos;re almost there!
                    </MediumBoldText>
                </View>
            </>
        );
    }

    return (
        <>
            <View>
                <MediumBoldText style={[globalStyles.centeredText, styles.paragraphText]}>
                    You&apos;re almost there!
                </MediumBoldText>
                <LargeText style={[styles.countdownText, globalStyles.centeredText, { fontStyle: props.fontStyle }]}>
                    {daysRemaining} days, {hoursRemaining} hours, {minsRemaining} mins
                </LargeText>
                <MediumBoldText style={[globalStyles.centeredText]}>until move-in</MediumBoldText>
            </View>
        </>
    );
};

export default SmartMoveCountdown;

const styles = StyleSheet.create({
    countdownText: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
        color: SmarthubTheme.colors.orange,
    },
    paragraphText: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
    },
});
