import { PersistentDataObjectBase } from '../../../common/PersistentDataObjectBase';
import { NullableBankAccountType } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/integration/paylease/dataobjects/BankAccountType';
import { PaymentType } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/integration/paylease/dataobjects/PaymentType';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.integration.paylease.dataobjects.ZegoGatewayPaymentMethodDO
 */
export interface ZegoGatewayPaymentMethodDo extends PersistentDataObjectBase {
    paymentMethodId: number;
    payleaseAccount: string;
    gatewayPayerId: number | null;
    paymentType: PaymentType;
    lastFour: string;
    bankOrCardName: string;
    createdUcd: string | null;
    createdDtt: number | null;
    save: boolean;
    paymentReferenceId: string;
    paymentTraceId: string | null;
    payerFirstName: string | null;
    payerLastName: string | null;
    accountType: NullableBankAccountType;
    accountFullName: string | null;
    routingNumber: string | null;
    accountNumber: string | null;
}

export function createZegoGatewayPaymentMethodDo(): ZegoGatewayPaymentMethodDo {
    return {
        paymentMethodId: 0,
        payleaseAccount: '',
        gatewayPayerId: null,
        paymentType: 'Card',
        lastFour: '',
        bankOrCardName: '',
        createdUcd: null,
        createdDtt: null,
        save: false,
        paymentReferenceId: '',
        paymentTraceId: '',
        payerFirstName: null,
        payerLastName: null,
        accountType: null,
        accountFullName: null,
        routingNumber: null,
        accountNumber: null,
        databaseId: '',
    };
}

/* START CUSTOM CODE */

export function createZegoGatewayCreditCardPaymentMethodDO(
    paymentReferenceId: string,
    payleaseAccount: string,
    gatewayPayerId: number,
    lastFour: string,
    bankOrCardName: string,
): ZegoGatewayPaymentMethodDo {
    return {
        paymentReferenceId: paymentReferenceId,
        paymentTraceId: null,
        paymentMethodId: 0,
        payleaseAccount: payleaseAccount,
        gatewayPayerId: gatewayPayerId,
        paymentType: 'Card',
        lastFour: lastFour,
        bankOrCardName: bankOrCardName,
        save: true,
        payerFirstName: null,
        payerLastName: null,
        accountFullName: null,
        accountNumber: null,
        accountType: null,
        createdDtt: null,
        createdUcd: null,
        databaseId: '',
        routingNumber: null,
    };
}
/* END CUSTOM CODE */
