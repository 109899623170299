import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { observer } from 'mobx-react-lite';
import MainStackSurface from './MainStackSurface';
import { getMainStackScreenOptions, NavigatorProps } from './MainStackScreenOptions';

import Notifications from 'screens/notifications/Notifications';
import { useTheme } from 'react-native-paper';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import LeaseNotificationDetail from 'screens/notifications/LeaseNotificationDetail';
import { navigationRoutes } from 'utils/NavigationUtils';

const NotificationsStack = createStackNavigator();

const NotificationsStackNavigator: React.FC<NavigatorProps> = observer(() => {
    const { useDrawerNavigation } = useResponsiveContext();
    const theme = useTheme();
    return (
        <MainStackSurface>
            <NotificationsStack.Navigator
                initialRouteName={navigationRoutes.notificationRoutes.notifications}
                /*headerMode={'none'}*/
                screenOptions={getMainStackScreenOptions(theme, useDrawerNavigation)}>
                <NotificationsStack.Screen
                    name={navigationRoutes.notificationRoutes.notifications}
                    component={Notifications}
                    options={{ headerLeft: () => null }}
                />
                <NotificationsStack.Screen
                    name={navigationRoutes.notificationRoutes.leaseNotificationDetail}
                    component={LeaseNotificationDetail}
                />
            </NotificationsStack.Navigator>
        </MainStackSurface>
    );
});

export default NotificationsStackNavigator;
