import { StyleSheet } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';

export const InputStyles = StyleSheet.create({
    inputWrapper: {
        width: '100%',
    },
    inputLabel: {
        position: 'absolute',
        paddingTop: 13,
        paddingLeft: 8,
    },
    input: {
        marginBottom: 12,
        paddingTop: 12,
        paddingRight: SmarthubTheme.layout.GRIDINCREMENT,
        paddingBottom: 12,
        paddingLeft: SmarthubTheme.layout.GRIDINCREMENT,
        borderWidth: 1,
        borderRadius: 0,
        borderColor: SmarthubTheme.colors.border,
        backgroundColor: SmarthubTheme.colors.white,
    },
    inputTransparent: {
        marginBottom: 12,
        paddingTop: 12,
        paddingRight: SmarthubTheme.layout.GRIDINCREMENT,
        paddingBottom: 6,
        borderBottomWidth: 1,
        borderRadius: 0,
        borderColor: SmarthubTheme.colors.white,
        backgroundColor: SmarthubTheme.colors.transparent,
        color: SmarthubTheme.colors.white,
    },
    inputIcon: {
        paddingLeft: 36,
    },
    inputIconRight: {
        paddingRight: 36,
    },
    inputError: {
        borderColor: SmarthubTheme.colors.red,
        backgroundColor: SmarthubTheme.colors.tagRedFaded,
    },
    inputDisabled: {
        borderColor: SmarthubTheme.colors.lightgrey,
        backgroundColor: SmarthubTheme.colors.lightgrey,
    },
    inputErrorMessage: {
        color: SmarthubTheme.colors.red,
    },
});
