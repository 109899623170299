import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { EmptyVersionedRemoteResult } from '../../../remotecmd/versioned/EmptyVersionedRemoteResult';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.contract.SendSmarthubContractSigningEmailCommandV1
 */
export interface SendSmarthubContractSigningEmailCommandV1
    extends AbstractResidencyAuthenticatedCommand<EmptyVersionedRemoteResult> {
    eSignerViewId: string;
    email: string;
}

export function createSendSmarthubContractSigningEmailCommandV1(): SendSmarthubContractSigningEmailCommandV1 {
    return {
        eSignerViewId: '',
        email: '',
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.contract.SendSmarthubContractSigningEmailCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
