export type PhoneNoType = 'NotSpecified' | 'Home' | 'Work' | 'Cell';

export type NullablePhoneNoType = PhoneNoType | null;

export const PhoneNoTypeOptions = [
    { value: 'NotSpecified', label: 'Not Specified' },
    { value: 'Home', label: 'Home' },
    { value: 'Work', label: 'Work' },
    { value: 'Cell', label: 'Cell' },
];

export const PhoneNoTypeOptionsWithBlank = [{ value: '', label: '' }].concat(PhoneNoTypeOptions);

/* START CUSTOM CODE */
export const ValidPhoneNoTypeOptions = [
    { value: 'Cell', label: 'Cell' },
    { value: 'Home', label: 'Home' },
    { value: 'Work', label: 'Work' },
];
/* END CUSTOM CODE */
