import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Divider, useTheme } from 'react-native-paper';
import { StackNavigationProp } from '@react-navigation/stack';
import { DeephansizedRegularText, FormLabelText, LargeBoldText, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { SafeAreaView } from 'react-native-safe-area-context';
import Tag from 'components/primitives/Tag';
import Button from 'components/primitives/Button';
import ListItem from 'components/lists/SmarthubListItem';
import Section from 'components/primitives/Section';
import { spaceWordsOnCapitals } from 'utils/StringUtils';
import { formatDateStringMonthLabelAtTimeFromNumber } from 'utils/DateUtils';
import LineLimitedText from 'components/primitives/LineLimitedText';
import { getTagTypeFromStatus } from 'screens/home/requests/requestUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { SmarthubTypography } from 'theme/typography/SmarthubTypography';
import { RouteProp } from '@react-navigation/native';
import { getRouteParams } from 'utils/routeUtils';
import { testProps } from 'components/ComponentTypes';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { SmarthubRequestEventV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/requests/SmarthubRequestEventV1';
import Hyperlink from 'components/primitives/Hyperlink';
import { generateReopenTicketCMD, useGetRequestDetails, useReopenTicket } from 'screens/home/requests/RequestsHooks';
import { useMarkNotificationSeenInComponent } from 'screens/home/requests/NotificationsHooks';

type RequestDetailParamList = {
    RequestDetail: {
        requestId: number;
        title: string;
    };
};
type RequestDetailScreenRouteProp = RouteProp<RequestDetailParamList, 'RequestDetail'>;
type RequestDetailProps = {
    navigation: StackNavigationProp<any, string>;
    route: RequestDetailScreenRouteProp;
};

const RequestDetail: React.FC<RequestDetailProps> = observer(({ navigation, route }: RequestDetailProps) => {
    const theme = useTheme();
    const { userSessionStore, uiStore, residentStore } = useStore();
    const { hasSessionActiveResidency, smarthubUser, confirmActiveResidency, sessionActiveResidency } =
        userSessionStore;

    const { requestId, requestType } = getRouteParams(route) || null;

    const { isSuccess, data, isError, isFetching } = useGetRequestDetails(requestId, requestType);
    const request = data?.requestDetail;

    const { windowWidth } = useResponsiveContext();
    const deviceBreakpoint = 412;

    const isMyRequestsOnStack = IsNavigationRouteOnStack(navigationRoutes.homeRoutes.myRequests);

    const reopenTicketMutation = useReopenTicket(navigation, requestId, requestType);

    useMarkNotificationSeenInComponent(Number(requestId), 'MaintenanceRequestUpdate');
    useMarkNotificationSeenInComponent(Number(requestId), 'MaintenanceRequestCorrespondence');

    const onClickedReopen = () => {
        if (request?.type === 'SmartDeskTicket') {
            uiStore.showAlert({
                message: `Are you sure you want to reopen ticket #SD${request.requestId}?`,
                buttons: [
                    {
                        buttonText: 'OK',
                        buttonType: 'primary',
                        onClick: () => {
                            onConfirmReopenTicket();
                            uiStore.hideAlert();
                        },
                    },
                    {
                        buttonText: 'Cancel',
                        buttonType: 'secondary',
                        onClick: uiStore.hideAlert,
                    },
                ],
            });
        } else {
            navigation.navigate(navigationRoutes.homeRoutes.reopenRequest, {
                requestId: request?.requestId,
                requestType: request?.type,
            });
        }
    };

    const onConfirmReopenTicket = () => {
        if (request) {
            uiStore.showActivityLoader();
            reopenTicketMutation.mutate(
                generateReopenTicketCMD(
                    sessionActiveResidency.propertyCd,
                    requestId,
                    sessionActiveResidency.residentId,
                    sessionActiveResidency.firstName,
                    sessionActiveResidency.lastName,
                ),
            );
        }
    };

    const getViewEmailFooter = (listItem: SmarthubRequestEventV1): ReactElement | undefined => {
        if (listItem.emailLogInfos) {
            return (
                <View style={globalStyles.flexColumnStart}>
                    {listItem.emailLogInfos.map(info => (
                        <Hyperlink
                            key={info}
                            style={globalStyles.singleSpaceBelow}
                            onClick={() => residentStore.viewEmailExternalLinkAsync(info)}>
                            View Email
                        </Hyperlink>
                    ))}
                </View>
            );
        } else {
            return undefined;
        }
    };

    useEffect(() => {
        //ensure requests and home page are in the stack for back navigation
        if (hasSessionActiveResidency && !isMyRequestsOnStack) {
            navigation.reset({
                routes: [
                    { name: navigationRoutes.homeRoutes.home },
                    { name: navigationRoutes.homeRoutes.myRequests },
                    { name: navigationRoutes.homeRoutes.requestDetail, params: { requestId, requestType } },
                ],
                index: 2,
            });
        }
    }, [hasSessionActiveResidency]);

    useEffect(() => {
        confirmActiveResidency(navigation);
    }, [smarthubUser.authorizedResidencies]);

    useEffect(() => {
        isFetching ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [isFetching]);

    if (!hasSessionActiveResidency || request?.residencyId === 0) {
        return null;
    }

    if (!isSuccess || !request) return null;

    if (isError) {
        return (
            <SafeAreaView style={[styles.container, { backgroundColor: theme.colors.background }]}>
                <RegularText>Request not found</RegularText>
            </SafeAreaView>
        );
    } else {
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return (
            <ScrollView style={styles.screenPadding}>
                <View style={styles.container}>
                    <View style={styles.statusTag} {...testProps('request-status')}>
                        <Tag tagType={getTagTypeFromStatus(request.status)}>{spaceWordsOnCapitals(request.status)}</Tag>
                    </View>

                    <Section>
                        <FormLabelText style={globalStyles.doubleSpaceBelow}>Date Created</FormLabelText>
                        <View style={styles.inline}>
                            <RegularText>{formatDateStringMonthLabelAtTimeFromNumber(request.createdDt)}</RegularText>
                            {/*todo: uncomment for view photo*/}
                            {/*<View style={styles.viewPhotoLink}>*/}
                            {/*    <Hyperlink>{`View Photo (${request.numMedia})`}</Hyperlink>*/}
                            {/*</View>*/}
                        </View>
                    </Section>

                    <Section>
                        <FormLabelText style={globalStyles.doubleSpaceBelow}>Description</FormLabelText>
                        <LineLimitedText numberOfLines={4} text={request.desc} expandable={true} />
                    </Section>

                    {request.status !== 'Completed' && (
                        <Section
                            style={
                                request.type === 'MaintenanceRequest' && windowWidth > deviceBreakpoint
                                    ? [styles.inlineButtons]
                                    : [globalStyles.flexColumnCenter]
                            }>
                            <View style={[globalStyles.justflex1, globalStyles.hundowidth]}>
                                <Button
                                    {...testProps('send-us-a-message-button')}
                                    onClick={() =>
                                        navigation.navigate(navigationRoutes.homeRoutes.requestMessage, {
                                            requestId: request?.requestId,
                                            requestType: request?.type,
                                        })
                                    }>
                                    Send Us a Message
                                </Button>
                            </View>
                            {request.type === 'MaintenanceRequest' && (
                                <View
                                    style={[
                                        globalStyles.justflex1,
                                        globalStyles.hundowidth,
                                        // eslint-disable-next-line react-native/no-inline-styles
                                        {
                                            marginLeft:
                                                windowWidth > deviceBreakpoint ? SmarthubTheme.layout.GRIDINCREMENT : 0,
                                            marginTop:
                                                windowWidth <= deviceBreakpoint
                                                    ? SmarthubTheme.layout.GRIDINCREMENT
                                                    : 0,
                                        },
                                    ]}>
                                    <Button
                                        {...testProps('cancel-button')}
                                        onClick={() =>
                                            navigation.navigate(navigationRoutes.homeRoutes.cancelRequest, {
                                                title: `Cancel ${
                                                    request?.type === 'MaintenanceRequest'
                                                        ? 'Maintenance Request'
                                                        : 'SMARTDESK Ticket'
                                                }`,
                                                requestId: request?.requestId,
                                                requestType: request?.type,
                                            })
                                        }
                                        buttonType={'secondary'}>
                                        {/*@ts-ignore*/}
                                        {request?.type === 'SmartDeskTicket' ? 'Cancel Ticket' : 'Cancel Request'}
                                    </Button>
                                </View>
                            )}
                        </Section>
                    )}

                    {request.status === 'Completed' && request.completedDays <= 14 && (
                        <Section>
                            <Button {...testProps('reopen-button')} onClick={onClickedReopen}>
                                {`Re-Open ${
                                    request?.type === 'MaintenanceRequest' ? 'Maintenance Request' : 'SMARTDESK Ticket'
                                }`}
                            </Button>
                        </Section>
                    )}
                </View>

                <View style={[styles.container, globalStyles.sectionSpaceAbove]} {...testProps('activity-log')}>
                    <Section style={globalStyles.sectionSpaceAbove}>
                        <LargeBoldText>Activity Log</LargeBoldText>
                    </Section>
                </View>

                <Section>
                    {!request.events.length && (
                        <View style={globalStyles.flexColumnCenter}>
                            <DeephansizedRegularText>There are no activities.</DeephansizedRegularText>
                        </View>
                    )}
                    {request.events.map(listItem => (
                        <React.Fragment key={listItem.eventId.toString()}>
                            <Divider style={styles.divider} />
                            <View style={styles.container}>
                                <ListItem
                                    key={listItem.eventId.toString()}
                                    id={listItem.eventId.toString()}
                                    title={`${listItem.title}`}
                                    titleStyle={SmarthubTypography.regularBold}
                                    subtitle={`${formatDateStringMonthLabelAtTimeFromNumber(listItem.createdDt)}`}
                                    desc={listItem.desc}
                                    descStyle={SmarthubTypography.regular}
                                    descNumberOfLines={4}
                                    descExpandable={true}
                                    footerItem={getViewEmailFooter(listItem)}
                                    testID={listItem.desc}
                                />
                            </View>
                        </React.Fragment>
                    ))}
                </Section>
                {!!request.events?.length && <Divider style={[styles.divider, globalStyles.sectionSpaceBelow]} />}
            </ScrollView>
        );
    }
});

export default RequestDetail;

const styles = StyleSheet.create({
    statusTag: { flexDirection: 'row' },
    inline: { flexDirection: 'row' },
    inlineButtons: { flexDirection: 'row', justifyContent: 'space-between' },
    /*viewPhotoLink: { marginLeft: SmarthubTheme.layout.SURROUNDMARGIN },*/
    divider: {
        height: 8,
        backgroundColor: SmarthubTheme.colors.lightestgrey,
    },
    container: {
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 2,
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    screenPadding: {
        paddingTop: SmarthubTheme.layout.SECTIONMARGIN,
    },
});
