import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { NavigationContainerRef, StackActions, useNavigation } from '@react-navigation/native';
import { navigationRoutes } from 'utils/NavigationUtils';
import { getSmartMoveScreenOptions } from 'navigation/stacks/SmartMoveScreenOptions';
import SmartMoveReserve from 'screens/smartmove/SmartMoveReserve';
import SmartMovePaymentConfirmation from 'screens/smartmove/SmartMovePaymentConfirmation';
import SmartMoveDeposit from 'screens/smartmove/SmartMoveDeposit';
import SmartMoveVehiclesAndPets from 'screens/smartmove/SmartMoveVehiclesAndPets';
import SmartMoveContractPending from 'screens/smartmove/SmartMoveContractPending';
import SmartMoveContractReady from 'screens/smartmove/SmartMoveContractReady';
import SmartMoveContractSigningComplete from 'screens/smartmove/SmartMoveContractSigningComplete';
import SmartMoveUtilitiesAndRentersInsurance from 'screens/smartmove/SmartMoveUtilitiesAndRentersInsurance';
import SmartMoveMoveInCountdown from 'screens/smartmove/SmartMoveMoveInCountdown';
import SmartMovePayBeforeMoveIn from 'screens/smartmove/SmartMovePayBeforeMoveIn';
import SmartMoveEditInformation from 'screens/smartmove/SmartMoveEditInformation';
import SmartMoveApplication from 'screens/smartmove/SmartMoveApplication';
import { NavigatorProps } from 'navigation/stacks/MainStackScreenOptions';
import SmartMovePaymentReview from 'screens/smartmove/SmartMovePaymentReview';
import SmartMovePaymentStart from 'screens/smartmove/SmartMovePaymentStart';

export const navigationRef = React.createRef<NavigationContainerRef>();

const SmartMoveStack = createStackNavigator();

const SmartMoveStackNavigator: React.FC<NavigatorProps> = observer(() => {
    return <SmartMoveNavigator />;
});

const SmartMoveNavigator = observer(() => {
    const theme = useTheme();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();
    const { userSessionStore, uiStore } = useStore();
    const { friendlyAptString, smarthubUser, sessionActiveResidency } = userSessionStore;
    const hasMultipleResidencies = smarthubUser.authorizedResidencies.length > 1;

    useEffect(() => {
        if (sessionActiveResidency.statusCd === 'Leased') {
            navigation.dispatch(StackActions.replace(navigationRoutes.smartmoveRoutes.smartMoveApplication));
        }
    }, [sessionActiveResidency.key]);

    return (
        <SmartMoveStack.Navigator
            screenOptions={{
                safeAreaInsets: insets,
                headerShown: false,
                cardStyle: {
                    backgroundColor: uiStore.useDarkTheme ? theme.colors.darkgrey : theme.colors.lightestgrey,
                },
                animationEnabled: true,
                title: 'SMARTHUB',
            }}
            initialRouteName={navigationRoutes.smartmoveRoutes.smartMoveApplication}
            mode='modal'
        >
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveApplication}
                component={SmartMoveApplication}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                initialParams={{ title: 'SmartMove' }}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveReserve}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMoveReserve}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMovePaymentStart}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMovePaymentStart}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMovePaymentReview}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMovePaymentReview}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationProcessing}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
            >
                {props => (
                    <SmartMovePaymentConfirmation
                        titleText={'Your payment is being processed'}
                        bodyText={'Your current balance will be updated, but your payment is still being processed.'}
                        icon={'check'}
                    />
                )}
            </SmartMoveStack.Screen>
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationPaymentReceivedApartmentReserved}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
            >
                {props => (
                    <SmartMovePaymentConfirmation
                        titleText={`Thank you for your payment`}
                        bodyText={'Your apartment is now reserved!'}
                        icon={'check'}
                    />
                )}
            </SmartMoveStack.Screen>
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationPaymentReceivedMoveInPayment}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
            >
                {props => <SmartMovePaymentConfirmation titleText={`Thank you for your payment`} icon={'check'} />}
            </SmartMoveStack.Screen>
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMovePaymentConfirmationUnsuccessful}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
            >
                {props => (
                    <SmartMovePaymentConfirmation
                        titleText={`Your payment was unsuccessful`}
                        bodyText={'Please go back and try again.'}
                        buttonText={'Go Back'}
                        icon={'alert-circle'}
                        isFail={true}
                    />
                )}
            </SmartMoveStack.Screen>
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveDeposit}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMoveDeposit}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveVehiclesAndPets}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMoveVehiclesAndPets}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveContractPending}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMoveContractPending}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveContractReady}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMoveContractReady}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveContractSigningComplete}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMoveContractSigningComplete}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveUtilitiesAndRentersInsurance}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMoveUtilitiesAndRentersInsurance}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveMoveInCountdown}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMoveMoveInCountdown}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMovePayBeforeMoveIn}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, true, hasMultipleResidencies)
                }
                component={SmartMovePayBeforeMoveIn}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveUtilitiesAndRentersInsuranceEdit}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, false, false)
                }
                component={SmartMoveUtilitiesAndRentersInsurance}
            />
            <SmartMoveStack.Screen
                name={navigationRoutes.smartmoveRoutes.smartMoveEditInformation}
                options={({ navigation, route }) =>
                    getSmartMoveScreenOptions(navigation, friendlyAptString, theme, false, false)
                }
                component={SmartMoveEditInformation}
            />
        </SmartMoveStack.Navigator>
    );
});

export default SmartMoveStackNavigator;
