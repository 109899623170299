import React, { forwardRef, ReactNode, RefObject, useEffect } from 'react';
import { BackHandler, Platform, StyleProp, StyleSheet, View } from 'react-native';

import { Menu, MenuOption, MenuOptions, MenuTrigger, renderers } from 'react-native-popup-menu';

import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useTheme } from 'react-native-paper';
import { RegularText } from 'components/primitives/StyledText';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export type ActionMenuItem = {
    id: string;
    icon: string;
    iconLibrary: 'material' | 'materialcommunity';
    title: string;
    onSelect: () => void;
    hidden?: boolean;
    testID?: string;
    accessibilityLabel?: string;
};

type ActionMenuProps = {
    menuTrigger: ReactNode;
    menuItem: ActionMenuItem[];
    style?: StyleProp<any>;
    ref?: RefObject<Menu>;
    testID?: string;
    accessibilityLabel?: string;
};

const MENU_ITEM_HEIGHT = SmarthubTheme.layout.GRIDINCREMENT * 8;

const ActionMenu: React.FC<ActionMenuProps> = observer(
    // eslint-disable-next-line react/display-name
    forwardRef<Menu, ActionMenuProps>((props, ref) => {
        const insets = useSafeAreaInsets();
        const theme = useTheme();

        if (ref === null) {
            ref = React.createRef<Menu>();
        }

        const { SlideInMenu } = renderers;

        const { uiStore } = useStore();

        const onHardwareBackButtonClick = () => {
            if (ref && typeof ref !== 'function' && ref.current && ref.current?.isOpen()) {
                ref.current.close();
            }
            return true;
        };

        useEffect(() => {
            const eventSubs = BackHandler.addEventListener('hardwareBackPress', onHardwareBackButtonClick);

            return () => {
                eventSubs.remove();
            };
        }, []);

        return (
            <Menu
                ref={ref}
                renderer={SlideInMenu}
                style={[styles.menu, props.style]}
                onOpen={() => {
                    uiStore.showOverlay();
                }}
                onClose={() => {
                    uiStore.hideOverlay();
                }}>
                <MenuTrigger>{props.menuTrigger}</MenuTrigger>
                <MenuOptions
                    // eslint-disable-next-line react-native/no-inline-styles
                    optionsContainerStyle={
                        Platform.OS !== 'web'
                            ? {
                                  //backgroundColor: theme.colors.transparent,
                                  //height: SmarthubTheme.layout.BOTTOMTABHEIGHT + props.menuItem.length * MENU_ITEM_HEIGHT,
                              }
                            : {
                                  backgroundColor: theme.colors.background,
                              }
                    }
                    customStyles={{
                        optionText: [SmarthubTheme.typography.heading3, styles.slideInOption],
                        optionsWrapper: { backgroundColor: theme.colors.background },
                        optionsContainer: {
                            backgroundColor: theme.colors.background,
                            paddingBottom: insets.bottom,
                        },
                        optionWrapper: {
                            //backgroundColor: theme.colors.black,
                        },
                    }}>
                    {props.menuItem
                        .filter(mo => !mo.hidden)
                        .map(qmo => (
                            <MenuOption key={qmo.id} onSelect={qmo.onSelect} style={[styles.menuoptioncontainer]}>
                                {qmo.iconLibrary === 'material' && (
                                    <MaterialIcons
                                        name={qmo.icon as any}
                                        size={30}
                                        color={SmarthubTheme.colors.orange}
                                    />
                                )}
                                {qmo.iconLibrary === 'materialcommunity' && (
                                    <MaterialCommunityIcons
                                        name={qmo.icon as any}
                                        size={30}
                                        color={SmarthubTheme.colors.orange}
                                    />
                                )}
                                <View style={styles.item} accessibilityLabel={qmo.testID} testID={qmo.testID}>
                                    <RegularText testID={qmo.testID + '-alt'}>{qmo.title}</RegularText>
                                </View>
                            </MenuOption>
                        ))}
                </MenuOptions>
            </Menu>
        );
    }),
);

export default ActionMenu;

const styles = StyleSheet.create({
    buttoncontainer: {
        height: SmarthubTheme.layout.BOTTOMTABHEIGHT,
        flexDirection: 'row',
        width: '100%',
    },
    item: { paddingLeft: 40 },
    slideInOption: {
        //padding: 5,
        backgroundColor: SmarthubTheme.colors.green,
    },
    menu: {
        //marginBottom: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    menuoptioncontainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingHorizontal: SmarthubTheme.layout.INNERPAD * 2,
        borderBottomColor: SmarthubTheme.colors.lightestgrey,
        borderStyle: 'solid',
        height: MENU_ITEM_HEIGHT,
    },
});
