import React from 'react';
import { View } from 'react-native';
import { MediumBoldText, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { globalStyles } from 'theme/style/GlobalStyles';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { useTheme } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { routeToCorrectStep, useApplicationHeader } from 'queries/hooks/smartmove/useApplicationHeader';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import PhoneLink from 'components/primitives/PhoneLink';

const SmartMoveContractPending: React.FC = observer(() => {
    const theme = useTheme();
    const isFocused = useIsFocused();
    const navigation = useNavigation();
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    ///header
    const { query: headerQuery, queryKey: headerQueryKey } = useApplicationHeader(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        hasSessionActiveResidency,
        uiStore,
        {
            enabled: hasSessionActiveResidency,
            onSuccess: data => {
                if (isFocused) {
                    routeToCorrectStep(data, navigation, 'Contract', 'AppContractRequired');
                }
            },
        },
    );

    return (
        <SmartmoveScreenContainer
            progressIndicatorProps={{
                currentStep: 2,
            }}
            /*NO footer button*/
        >
            <View style={[globalStyles.smartMoveIcon, globalStyles.sectionSpaceAround]}>
                <MaterialCommunityIcons
                    name={'check'}
                    size={theme.layout.EXTRALARGEICONSIZE}
                    color={SmarthubTheme.colors.orange}
                />
            </View>
            <MediumBoldText style={[globalStyles.smartMoveTitleText, globalStyles.centeredText]}>
                Thanks! Your contract is being created
            </MediumBoldText>
            <RegularText style={[globalStyles.centeredText, globalStyles.smartMoveParagraphText]}>
                There’s nothing left for you to do right now - we are working on creating your contract. We will send it
                to you via email when it’s ready.
            </RegularText>
            <RegularText style={[globalStyles.centeredText, globalStyles.smartMoveParagraphText]}>
                You and all other applicants will need to digitally sign the contract in order to move forward.
            </RegularText>
            {sessionActiveResidency && !!sessionActiveResidency.propertyPhoneNumber && (
                <RegularText style={globalStyles.centeredText}>
                    If you have any questions, contact us at{' '}
                    <PhoneLink phoneNumber={sessionActiveResidency.propertyPhoneNumber} />{' '}
                </RegularText>
            )}
        </SmartmoveScreenContainer>
    );
});

export default SmartMoveContractPending;
