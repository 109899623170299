import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { ButtonText, SmallerText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';

const PrivacyTermsLinks: React.FC = () => {
    async function _openURLInBrowser(url: string) {
        await WebBrowser.openBrowserAsync(url);
    }

    return (
        <View style={styles.bottomLinkRow}>
            <TouchableOpacity
                style={styles.bottomLinks}
                onPress={() =>
                    _openURLInBrowser(
                        'https://venterra.com/privacy-policy/',
                    )
                }>
                <ButtonText>Privacy Policy</ButtonText>
            </TouchableOpacity>
            <SmallerText style={styles.whiteText}>|</SmallerText>
            <TouchableOpacity
                style={styles.bottomLinks}
                onPress={() =>
                    _openURLInBrowser('https://online.venterraliving.com/smarthub/pdf/TermsOfServiceAgreement.pdf')
                }>
                <ButtonText>Terms Of Service</ButtonText>
            </TouchableOpacity>
        </View>
    );
};

export default PrivacyTermsLinks;

const styles = StyleSheet.create({
    bottomLinkRow: {
        width: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    bottomLinks: {
        flex: 1,
        height: 20,
    },
    whiteText: {
        color: SmarthubTheme.colors.white,
        fontSize: 14,
    },
});
