import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { TagTypes } from 'components/primitives/TagTypes';
import { SmallerBoldText } from 'components/primitives/StyledText';
import { useTheme } from 'react-native-paper';

interface TagProps {
    testID?: string;
    tagType?: TagTypes;
    children?: React.ReactNode;
}

const defaultProps: TagProps = {
    tagType: TagTypes.Red,
};

const Tag: React.FC<TagProps> = (props: TagProps) => {
    const theme = useTheme();
    const _getTagClass = () => {
        let tagClass: any = [styles.tag];

        if (props.tagType) {
            switch (props.tagType) {
                case TagTypes.Yellow:
                    tagClass = [...tagClass, styles.tagYellow];
                    break;
                case TagTypes.Green:
                    tagClass = [...tagClass, styles.tagGreen];
                    break;
                case TagTypes.Blue:
                    tagClass = [...tagClass, styles.tagBlue];
                    break;
                case TagTypes.DarkGrey:
                    tagClass = [...tagClass, styles.tagDarkGrey, { borderColor: theme.colors.icon }];
                    break;
            }
        }

        return tagClass;
    };

    const _getTagTextClass = () => {
        let tagTextClass: any = [styles.tagText];

        if (props.tagType) {
            switch (props.tagType) {
                case TagTypes.Yellow:
                    tagTextClass = [...tagTextClass, styles.tagTextYellow];
                    break;
                case TagTypes.Green:
                    tagTextClass = [...tagTextClass, styles.tagTextGreen];
                    break;
                case TagTypes.Blue:
                    tagTextClass = [...tagTextClass, styles.tagTextBlue];
                    break;
                case TagTypes.DarkGrey:
                    tagTextClass = [...tagTextClass, styles.tagTextDarkGrey];
                    break;
            }
        }

        return tagTextClass;
    };

    const style = _getTagClass();
    const textStyle = _getTagTextClass();

    return (
        <View testID={props.testID} style={style}>
            <SmallerBoldText testID={'generic-tag-text'} style={textStyle}>
                {props.children}
            </SmallerBoldText>
        </View>
    );
};

Tag.defaultProps = defaultProps;

export default Tag;

const styles = StyleSheet.create({
    tag: {
        display: 'flex',
        justifyContent: 'center',
        height: 22,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: SmarthubTheme.colors.red,
        paddingRight: SmarthubTheme.layout.INNERPAD,
        paddingLeft: SmarthubTheme.layout.INNERPAD,
        backgroundColor: SmarthubTheme.colors.tagRedFaded,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    tagYellow: {
        borderColor: SmarthubTheme.colors.yellow,
        color: SmarthubTheme.colors.white,
        backgroundColor: SmarthubTheme.colors.tagYellowFaded,
    },
    tagGreen: {
        borderColor: SmarthubTheme.colors.green,
        color: SmarthubTheme.colors.white,
        backgroundColor: SmarthubTheme.colors.tagGreenFaded,
    },
    tagBlue: {
        borderColor: SmarthubTheme.colors.lightblue,
        color: SmarthubTheme.colors.white,
        backgroundColor: SmarthubTheme.colors.tagBlueFaded,
    },
    tagDarkGrey: {
        borderColor: SmarthubTheme.colors.darkgrey,
        color: SmarthubTheme.colors.white,
        backgroundColor: SmarthubTheme.colors.tagDarkGreyFaded,
    },
    tagText: {
        color: SmarthubTheme.colors.red,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    tagTextYellow: {
        color: SmarthubTheme.colors.yellow,
    },
    tagTextGreen: {
        color: SmarthubTheme.colors.green,
    },
    tagTextBlue: {
        color: SmarthubTheme.colors.lightblue,
    },
    tagTextDarkGrey: {
        color: SmarthubTheme.colors.white,
    },
});
