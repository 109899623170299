export const STORAGE_KEY_UNVERIFIED_EMAIL = 'smarthub-unverifiedEmail';
export const STORAGE_KEY_PLUS_BUTTON_HIGHLIGHT_DISMISSED = 'smarthub-plusbuttonhighlight';
export const STORAGE_KEY_REQUEST_PLUS_BUTTON_HIGHLIGHT_DISMISSED = 'smarthub-request-plusbuttonhighlight';
export const STORAGE_KEY_ONBOARDING_COMPLETE = 'smarthub-onboard-complete';
export const STORAGE_KEY_DARK_THEME_ENABLED = 'smarthub-dark_theme_enabled';
export const STORAGE_KEY_APPLE_SIGN_IN_CONSENT = 'smarthub-apple-sign-in-consent';
export const STORAGE_KEY_LEASE_NOTIFICATIONS_SEEN = 'smarthub-lease-notifications-seen';
export const STORAGE_KEY_TEASER_HISTORY = 'smarthub-teaser-history';
export const STORAGE_KEY_REACT_NAVIGATION_STATE_HISTORY = 'smarthub-react-navigation-state-history';
export const STORAGE_KEY_SAVED_RESIDENCY_INFO = 'smarthub-saved-residency-info';
export const STORAGE_KEY_AUTHORIZED_RESIDENCY_INFO_LIST = 'smarthub-authorized-residency-info-list';
export const STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT = 'smarthub-show-opt-in-out-feature-highlight';
export const STORAGE_KEY_SHOW_OPT_IN_OUT_FEATURE_HIGHLIGHT_HISTORY =
    'smarthub-show-opt-in-out-feature-highlight-history';
export const STORAGE_KEY_SAVED_RESIDENCY_REFRESH_HISTORY = 'smarthub-saved-residency-refresh-history';
