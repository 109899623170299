import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import Container from 'components/primitives/Container';
import Address from 'components/primitives/Address';
import { globalStyles } from 'theme/style/GlobalStyles';
import { RegularText } from 'components/primitives/StyledText';
import { SmarthubAddressV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAddressV1';

type AddressContainerProps = {
    label: string;
    address: SmarthubAddressV1;
    onClicked: () => void;
};

const AddressContainer: React.FC<AddressContainerProps> = observer((props: AddressContainerProps) => {
    return (
        <Container borderStyle={'grey'} dropShadow={false}>
            <TouchableWithoutFeedback
                onPress={() => {
                    props.onClicked();
                }}>
                <View style={globalStyles.innerContainer}>
                    {!!props.label && <RegularText style={globalStyles.singleSpaceBelow}>{props.label}</RegularText>}
                    <Address
                        streetLine1={props.address.streetLine1}
                        city={props.address.city}
                        state={props.address.state}
                        zip={props.address.zip}
                    />
                </View>
            </TouchableWithoutFeedback>
        </Container>
    );
});

export default AddressContainer;
