import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { FormLabelText, Heading1, RegularText } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useStore } from 'contexts/StoreContextProvider';
import InputCheckbox from 'components/input/InputCheckbox';
import { observer } from 'mobx-react-lite';
import Section from 'components/primitives/Section';
import InputDropdown from 'components/input/InputDropdown';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { globalStyles } from 'theme/style/GlobalStyles';
import SodaRecipient from 'screens/home/manageMyLease/noticeToVacate/SodaRecipient';
import { action } from 'mobx';
import { daysBetween, parseDateMMDDYYYY } from 'utils/DateUtils';
import {
    createSmarthubSodaRecipientV1,
    SmarthubSodaRecipientV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubSodaRecipientV1';
import { getScreenOrModalName, navigationRoutes } from 'utils/NavigationUtils';
import InputDate from 'components/input/InputDate';
import Container from 'components/primitives/Container';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { testProps } from 'components/ComponentTypes';

type NoticeToVacateRequestProps = {
    navigation: StackNavigationProp<any>;
};

const schema = yup.object().shape({
    expectedMoveOutDt: yup.string().required('Please enter an valid date'),
    moveOutReasonCd: yup.string().required('Please select an option'),
});

const NoticeToVacateRequest: React.FC<NoticeToVacateRequestProps> = observer(
    ({ navigation }: NoticeToVacateRequestProps) => {
        const { useDrawerNavigation } = useResponsiveContext();
        const { userSessionStore, ntvStore, uiStore } = useStore();
        const { hasSessionActiveResidency, smarthubUser, confirmActiveResidency, sessionActiveResidency } =
            userSessionStore;

        const [moveOutDateAlert, setMoveOutDateAlert] = useState('');

        const calculateMoveOutDateAlert = (moveOutDate: string) => {
            const moveOutDt = parseDateMMDDYYYY(moveOutDate)!;
            const today = parseDateMMDDYYYY(ntvStore.ntvScreenData.todayDt)!;
            const leaseEndDt = parseDateMMDDYYYY(ntvStore.ntvScreenData.leaseEndDt)!;
            const { noticePeriod, onMTM } = ntvStore.ntvScreenData;
            const diffDays = daysBetween(today!, moveOutDt!);

            if (diffDays < noticePeriod && (moveOutDt >= leaseEndDt || onMTM)) {
                //If Notice is less than sufficient and move out entered is on or after lease end date:
                setMoveOutDateAlert(
                    `Move out date entered is less than ${noticePeriod} days from today. Moving out without providing a ${noticePeriod}-day notice may result in additional charges.`,
                );
            } else if (diffDays >= noticePeriod && moveOutDt < leaseEndDt && !onMTM) {
                // If Notice is sufficient and move out entered is < lease end date and resident is not on MTM
                setMoveOutDateAlert(
                    `The last day of your contract is ${ntvStore.ntvScreenData.leaseEndDt}. Moving out before ${ntvStore.ntvScreenData.leaseEndDt} may result in additional charges.`,
                );
            } else if (diffDays < noticePeriod && moveOutDt < leaseEndDt && !onMTM) {
                //If Notice is less than sufficient and move out entered is < lease end date and resident is not on MTM
                setMoveOutDateAlert(
                    `Move out date entered is less than ${noticePeriod}  days from today and prior to the last day of your contract ${ntvStore.ntvScreenData.leaseEndDt}. Moving out before ${ntvStore.ntvScreenData.leaseEndDt} and without providing a ${noticePeriod}-day notice may result in additional charges.`,
                );
            } else if (moveOutDt > leaseEndDt && !onMTM) {
                if (diffDays == noticePeriod) {
                    setMoveOutDateAlert(
                        `The date shown above is ${noticePeriod} days from today as required by your contract but extends beyond your lease expiration date which may result in the pro-rating of your lease to a month-to-month rate. One of our team members will reach out to discuss your options with you.`,
                    );
                } else {
                    setMoveOutDateAlert(
                        `Move out date entered extends beyond your lease expiration date which may result in the pro-rating of your lease to a month-to-month rate. One of our team members will reach out to discuss your options with you.`,
                    );
                }
            } else {
                setMoveOutDateAlert('');
            }
        };

        const onSubmit = action((data: { expectedMoveOutDt: string; moveOutReasonCd: string }) => {
            formik.resetForm({ values: formik.values });
            formik.setSubmitting(true);
            ntvStore.ntvRequestSubmitData.expectedMoveOutDt = data.expectedMoveOutDt;
            ntvStore.ntvRequestSubmitData.moveOutReasonCd = data.moveOutReasonCd;
            ntvStore.ntvCurrentRecipientIndx = 0;
            if (ntvStore.ntvRecipientIdAry.length > 0) {
                //delete any removed recipients
                ntvStore.ntvRequestSubmitData.sodaRecipientList =
                    ntvStore.ntvRequestSubmitData.sodaRecipientList.filter(r =>
                        ntvStore.ntvRecipientIdSet.has(r.residentId),
                    );

                //add any missing recipients
                const recipientsToAdd = ntvStore.ntvScreenData.applicants
                    .filter(
                        applicant =>
                            ntvStore.ntvRecipientIdSet.has(applicant.residentId) &&
                            ntvStore.ntvRequestSubmitData.sodaRecipientList.filter(
                                sr => sr.residentId === applicant.residentId,
                            ).length === 0,
                    )
                    .map(resident => {
                        const sodaRecipient: SmarthubSodaRecipientV1 = createSmarthubSodaRecipientV1();
                        sodaRecipient.residencyId = sessionActiveResidency.residencyId;
                        sodaRecipient.residentId = resident.residentId;
                        sodaRecipient.mailToFirstName = resident.firstName;
                        sodaRecipient.mailToLastName = resident.lastName;
                        sodaRecipient.phone1TypeCd = resident.phone1TypeCd;
                        sodaRecipient.phone1No = resident.phone1No;
                        sodaRecipient.phone2TypeCd = resident.phone2TypeCd;
                        sodaRecipient.phone2No = resident.phone2No;
                        return sodaRecipient;
                    });

                ntvStore.ntvRequestSubmitData.sodaRecipientList.push(...recipientsToAdd);

                navigation.push(getScreenOrModalName('SodaRecipient', useDrawerNavigation));
            } else {
                navigation.push(getScreenOrModalName('NoticeToVacatePreferContact', useDrawerNavigation));
            }
        });

        const formik = useFormik({
            validateOnMount: false,
            validateOnChange: false,
            validateOnBlur: false,
            initialValues: {
                expectedMoveOutDt: ntvStore.ntvScreenData.defaultExpectMoveOutDt,
                moveOutReasonCd: '',
            },
            validationSchema: schema,
            onSubmit: onSubmit,
        });

        //handle hardware or browser back button
        useEffect(() => {
            if (hasSessionActiveResidency && !sessionActiveResidency.allowNTV) {
                navigation.navigate(navigationRoutes.homeRoutes.home);
            }
        }, [hasSessionActiveResidency]);

        //load data
        useEffect(() => {
            confirmActiveResidency(navigation);
            if (hasSessionActiveResidency) {
                uiStore.showActivityLoader();
                ntvStore
                    .getNoticeToVacateScreenData()
                    .then(() => {
                        formik.resetForm({
                            values: {
                                ...formik.values,
                                expectedMoveOutDt: ntvStore.ntvScreenData.defaultExpectMoveOutDt,
                                moveOutReasonCd: ntvStore.ntvRequestSubmitData.moveOutReasonCd,
                            },
                        });
                        calculateMoveOutDateAlert(ntvStore.ntvScreenData.defaultExpectMoveOutDt);
                    })
                    .finally(() => uiStore.hideActivityLoader());
            }
        }, [smarthubUser.authorizedResidencies]);

        if (!hasSessionActiveResidency || !ntvStore.ntvScreenData) {
            return null;
        }

        return (
            <SafeAreaView style={globalStyles.container}>
                <KeyboardAwareScrollView
                    keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                    enableResetScrollToCoords={false}>
                    <View style={globalStyles.container}>
                        {!useDrawerNavigation && (
                            <Heading1 {...testProps('header')} style={globalStyles.sectionSpaceBelow}>
                                Notice to Vacate
                            </Heading1>
                        )}

                        <FormLabelText {...testProps('message')} style={globalStyles.doubleSpaceBelow}>
                            Who do you want to receive a copy of the final bill statement?
                        </FormLabelText>

                        {ntvStore.ntvScreenData.applicants.map(resident => (
                            <InputCheckbox
                                style={'small'}
                                initialStatus={
                                    ntvStore.ntvRecipientIdSet.has(resident.residentId) ? 'checked' : 'unchecked'
                                }
                                label={`${resident.firstName} ${resident.lastName}`}
                                {...testProps(`recipient${resident.residentId}-checkbox`)}
                                key={resident.residentId}
                                onChange={action(checkValue => {
                                    checkValue === 'checked'
                                        ? ntvStore.ntvRecipientIdSet.add(resident.residentId)
                                        : ntvStore.ntvRecipientIdSet.delete(resident.residentId);
                                })}
                            />
                        ))}

                        <Section>
                            <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                When do you want to move out?
                            </FormLabelText>
                        </Section>

                        <InputDate
                            {...testProps('move-out-date')}
                            value={formik.values.expectedMoveOutDt}
                            onChange={value => {
                                formik.setFieldValue('expectedMoveOutDt', value);
                                calculateMoveOutDateAlert(value);
                            }}
                            minDate={ntvStore.ntvScreenData.todayDt}
                        />

                        {!!moveOutDateAlert && (
                            <Section>
                                <Container borderStyle={'orange'}>
                                    <View style={[globalStyles.flexColumnCenter, globalStyles.innerContainer]}>
                                        <MaterialCommunityIcons
                                            name='alert-circle'
                                            size={SmarthubTheme.layout.LARGEICONSIZE}
                                            color={SmarthubTheme.colors.orange}
                                            style={globalStyles.doubleSpaceBelow}
                                        />
                                        <RegularText>{moveOutDateAlert}</RegularText>
                                    </View>
                                </Container>
                            </Section>
                        )}

                        <Section>
                            <FormLabelText style={globalStyles.doubleSpaceBelow}>
                                What is the reason for your move out?
                            </FormLabelText>

                            <InputDropdown
                                {...testProps('reason-dropdown')}
                                selectedValue={formik.values.moveOutReasonCd}
                                options={
                                    ntvStore.ntvScreenData
                                        ? [{ value: '', label: '' }, ...ntvStore.ntvScreenData.moveOutReasons]
                                        : []
                                }
                                onChange={value => {
                                    formik.setFieldValue('moveOutReasonCd', value);
                                }}
                                error={!!formik.errors.moveOutReasonCd}
                                errorMessage={formik.errors.moveOutReasonCd}
                            />
                        </Section>

                        <Section>
                            <View style={[styles.button]}>
                                <Button
                                    inactive={uiStore.activityLoaderVisible}
                                    onClick={formik.handleSubmit}
                                    {...testProps('submit-button')}>
                                    Continue
                                </Button>
                            </View>
                        </Section>
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        );
    },
);

export default NoticeToVacateRequest;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: '100%',
    },
});
