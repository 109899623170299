import React, { useEffect } from 'react';
import { Image, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { DeephansizedRegularText, MediumBoldText } from 'components/primitives/StyledText';
import { useTheme } from 'react-native-paper';
import Button from 'components/primitives/Button';
import Hyperlink from 'components/primitives/Hyperlink';
import { getRouteParam } from 'utils/routeUtils';
import { useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useStore } from 'contexts/StoreContextProvider';
import OrangeCheckmark from 'components/animations/OrangeCheckmark';
import { globalStyles } from 'theme/style/GlobalStyles';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { navigationRoutes } from 'utils/NavigationUtils';
import { testProps } from 'components/ComponentTypes';
import { isSafari } from 'react-device-detect';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import { INELIGIBLE_DEBIT_CARD_ERROR_CODE } from 'utils/PayLeaseZegoUtils';

export type ConfirmationType = 'PaymentBeingProcessed' | 'PaymentReceived' | 'PaymentFailed' | 'AutoPaySetupPending';

type PaymentConfirmationProps = {
    navigation: StackNavigationProp<any>;
};

//break out of the iframe if needed
Platform.OS === 'web' &&
    window.top &&
    window.top.location.href !== window.location.href &&
    !isSafari &&
    (window.top.location.href = window.location.href);

if (Platform.OS === 'web' && window.top && window.top.location.href !== window.location.href && isSafari) {
    // This solution will also work for Chrome, however, I want to keep the old method because we know it works for all scenarios and it's a waste of resources to retest
    window.top.location.replace(window.location.href);
}

const PaymentConfirmation: React.FC<PaymentConfirmationProps> = observer(({ navigation }: PaymentConfirmationProps) => {
    const theme = useTheme();
    const route = useRoute();
    const { userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const { useDrawerNavigation } = useResponsiveContext();

    const confirmationType: ConfirmationType = getRouteParam(route, 'confirmationType');
    const errorCode: string | undefined = getRouteParam(route, 'errorCode');
    const paymentFailedMessage: string = getRouteParam(route, 'paymentFailedMessage') || '';

    logInfo(INFO_LOGGER, 'confirmationType : ', confirmationType);

    const _proceedToAutoPay = () => {
        const autopayOpts = {
            navigateTo:
                sessionActiveResidency.onAutoPay || userSessionStore.autoPaySetupPending
                    ? 'autopay'
                    : 'autopay_payment',
            title:
                sessionActiveResidency.onAutoPay || userSessionStore.autoPaySetupPending
                    ? 'Manage AutoPay'
                    : 'Set Up AutoPay',
        };
        userSessionStore.setAutoPaySetupPending(true);
        if (useDrawerNavigation) {
            navigation.navigate(navigationRoutes.homeRoutes.paymentScreen, autopayOpts);
        } else {
            navigation.navigate(navigationRoutes.loggedInRoutes.paymentScreenModal, autopayOpts);
        }
    };

    /*
        //this is what they send back:
        http://exp//127.0.0.1:19001/--/payment_confirmation?
        transaction_id=29471176&transaction_date=2021-02-10+10%3A37%3A45&
        transaction_type=ACH&unit_amount=2.00&total_amount=2.00&fee_amount=0.00&cc_type=&last_four=1103&resident_id=R.FLFS.GAB.CION.99351609222066733&property_id=1073&ipn_custom=&status=SUCCESS&hmac=07a4a381dfc21fd24a0a9bae9b2a710bd0faec00e878c93deedbdd79f259901c

        Two versions of successful payment confirmations:

        transaction_type=CC - PaymentReceived - We've received your payment : https://projects.invisionapp.com/d/main?origin=v7#/console/18712103/405661900/preview?scrollOffset=0#project_console

        transaction_type=ACH - PaymentBeingProcessed - Your payment is being processed : https://projects.invisionapp.com/d/main?origin=v7#/console/18712103/407481665/preview?scrollOffset=12614#project_console

        //TODO payment failed!? need wording...

      */
    let isFailed = false;
    let mainMessage = 'Thank you for making a payment'; //ACH
    let secondaryMessage = 'Please note it can take up to 7 business days for your payment to be cleared.'; //ACH
    /*if (confirmationType === 'PaymentReceived') {
        mainMessage = "We've received your payment"; //Credit card
        secondaryMessage = 'Your payment was successful'; //Credit card
    } else */ if (confirmationType === 'PaymentFailed') {
        mainMessage = 'Your payment was unsuccessful';
        secondaryMessage =
            (errorCode === INELIGIBLE_DEBIT_CARD_ERROR_CODE
                ? 'The payment information you have entered is for a credit card, not a debit card. '
                : '') + 'Please review your payment information and try again.';
        isFailed = true;
    }
    if (paymentFailedMessage.length > 0) {
        secondaryMessage = paymentFailedMessage;
    }

    if (confirmationType === 'AutoPaySetupPending') {
        mainMessage = 'AutoPay setup is complete';
        secondaryMessage = `Payments will be automatically withdrawn from the credit card or bank account you've selected.`;
    }

    const showAutopayLink =
        !sessionActiveResidency.onAutoPay && confirmationType !== 'AutoPaySetupPending' && !isFailed;

    useEffect(() => {
        if (confirmationType === 'AutoPaySetupPending') {
            userSessionStore.setAutoPaySetupPending(true);
        }

        if (confirmationType === 'PaymentBeingProcessed' || confirmationType === 'PaymentReceived') {
            userSessionStore.setBalanceDirty(true);
        }
    }, []);

    return (
        <SafeAreaView style={[globalStyles.container, globalStyles.flexColumnCenter]}>
            <View
                style={[
                    styles.innercontainer,
                    //globalStyles.onePixelBorder,
                    { backgroundColor: theme.colors.background },
                ]}>
                <View style={[styles.topAndBottom]}>
                    <View style={[styles.topAreaContainer]}>
                        {isFailed && (
                            <View style={[styles.animation, globalStyles.flexColumnCenter]}>
                                <MaterialCommunityIcons
                                    name={'alert-circle'}
                                    size={64}
                                    color={SmarthubTheme.colors.orange}
                                />
                            </View>
                        )}
                        {!isFailed && <OrangeCheckmark lottieViewStyle={styles.animation} />}
                        <MediumBoldText
                            style={[styles.mainMessage, globalStyles.sectionSpaceBelow, globalStyles.centeredText]}>
                            {mainMessage}
                        </MediumBoldText>
                        <DeephansizedRegularText style={[globalStyles.centeredText]}>
                            {secondaryMessage}
                        </DeephansizedRegularText>
                        <View style={globalStyles.doubleSpaceAbove}>
                            {confirmationType !== 'AutoPaySetupPending' &&
                                !isFailed &&
                                (sessionActiveResidency.onAutoPay || sessionActiveResidency.autopayConcAmt == 0) && (
                                    <>
                                        <DeephansizedRegularText
                                            style={[globalStyles.singleSpaceBelow, globalStyles.centeredText]}>
                                            You will be able to view the receipt for your payment shortly under
                                        </DeephansizedRegularText>
                                        <Hyperlink
                                            {...testProps('statements-and-payments-link')}
                                            onClick={() => {
                                                navigation.navigate(navigationRoutes.homeRoutes.transactions);
                                            }}
                                            style={[globalStyles.centeredText]}>
                                            Statements and Payments
                                        </Hyperlink>
                                    </>
                                )}
                            {isFailed && (
                                <Hyperlink
                                    style={[globalStyles.centeredText, globalStyles.sectionSpaceAbove]}
                                    onClick={() => {
                                        WebBrowser.openBrowserAsync('https://gozego.force.com/residents/s/');
                                    }}>
                                    Need help? Get Support here
                                </Hyperlink>
                            )}

                            {showAutopayLink && sessionActiveResidency.autopayConcAmt > 0 && (
                                <View style={styles.coinDropPiggyWrapper}>
                                    <Image
                                        source={require('../../../../assets/images/coindroppiggy.png')}
                                        style={[styles.coinDropPiggy]}
                                    />

                                    <DeephansizedRegularText
                                        style={[globalStyles.sectionSpaceAbove, globalStyles.centeredText]}>
                                        Save {formatCentsAsDollars(sessionActiveResidency.autopayConcAmt)} per month by
                                        setting up AutoPay!
                                    </DeephansizedRegularText>
                                </View>
                            )}
                        </View>
                    </View>

                    <View style={[styles.bottomAreaContainer]}>
                        <View style={globalStyles.hundowidth}>
                            {showAutopayLink && (
                                <Hyperlink
                                    onClick={_proceedToAutoPay}
                                    style={[globalStyles.sectionSpaceBelow, globalStyles.centeredText]}>
                                    Set Up AutoPay
                                </Hyperlink>
                            )}
                            {userSessionStore.isFeatureEnabled('ZegoGatewayPayment') && (
                                <Button
                                    {...testProps('continue-button')}
                                    buttonType={'primary'}
                                    onClick={() => {
                                        navigation.pop(3);
                                        if (useDrawerNavigation) {
                                            if (isFailed) {
                                                navigation.navigate(navigationRoutes.homeRoutes.zegoPaymentScreen);
                                            } else {
                                                navigation.navigate(navigationRoutes.homeRoutes.home);
                                            }
                                        } else {
                                            if (isFailed) {
                                                navigation.navigate(
                                                    navigationRoutes.loggedInRoutes.zegoPaymentScreenModal,
                                                );
                                            } else {
                                                navigation.navigate(navigationRoutes.homeRoutes.home, {
                                                    screen: 'Home',
                                                });
                                            }
                                        }
                                    }}>
                                    {isFailed ? 'Go Back' : 'Continue'}
                                </Button>
                            )}

                            {!userSessionStore.isFeatureEnabled('ZegoGatewayPayment') && (
                                <Button
                                    {...testProps('continue-button')}
                                    buttonType={'primary'}
                                    onClick={() => {
                                        if (useDrawerNavigation) {
                                            navigation.navigate(navigationRoutes.homeRoutes.home);
                                        } else {
                                            navigation.navigate(navigationRoutes.homeRoutes.home, { screen: 'Home' });
                                        }
                                    }}>
                                    Continue
                                </Button>
                            )}
                        </View>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    );
});

export default PaymentConfirmation;

const styles = StyleSheet.create({
    innercontainer: {
        padding: SmarthubTheme.layout.SURROUNDMARGIN,
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    surface: {
        padding: SmarthubTheme.layout.INNERPAD * 2,
        width: '90%',
        elevation: SmarthubTheme.layout.SURFACEELEVATION,
        borderWidth: 0.1,
        borderStyle: 'solid',
        borderColor: SmarthubTheme.colors.lightgrey,
    },
    button: {
        alignSelf: 'center',
        width: '100%',
    },
    mainMessage: {
        marginTop: -55, // Add negative margin to cater for unusual amount of white space under icons
    },
    coinDropPiggyWrapper: {
        width: '100%',
        alignItems: 'center',
        marginTop: SmarthubTheme.layout.SECTIONMARGIN,
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    coinDropPiggy: {
        width: 67,
        height: 78,
    },
    animation: {
        width: 150,
        height: 150,
    },
    topAndBottom: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'space-between',
    },
    topAreaContainer: {
        flex: 2,
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    bottomAreaContainer: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
});
