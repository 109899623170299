import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SmallBoldText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Hyperlink from 'components/primitives/Hyperlink';
import { TagTypes } from 'components/primitives/TagTypes';
import Tag from 'components/primitives/Tag';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { globalStyles } from 'theme/style/GlobalStyles';

type SmartMoveSignedCardProps = {
    testID?: string;
    titleText: string;
    tagType?: TagTypes;
    tagText: string;
    linkText?: string;
    onLinkClick?: () => void;
};

const SmartMoveSignedCard: React.FC<SmartMoveSignedCardProps> = (props: SmartMoveSignedCardProps) => {
    const _handleLinkClick = () => {
        if (props.onLinkClick) {
            logInfo(INFO_LOGGER, 'Link Clicked');
            props.onLinkClick();
        }
    };

    return (
        <View testID={props.testID} style={styles.cardWrapper}>
            <View style={globalStyles.flexContainer}>
                <SmallBoldText style={styles.title}>{props.titleText}</SmallBoldText>
                <View style={styles.statusTag}>
                    <Tag tagType={props.tagType}>{props.tagText}</Tag>
                </View>
            </View>
            <View style={styles.cardRight}>
                {props.linkText && <Hyperlink onClick={_handleLinkClick}>{props.linkText}</Hyperlink>}
            </View>
        </View>
    );
};

export default SmartMoveSignedCard;

const styles = StyleSheet.create({
    cardWrapper: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: SmarthubTheme.colors.lightestgrey,
        paddingTop: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingBottom: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    cardRight: {
        flexDirection: 'row',
        flex: 1,
        paddingTop: 22,
        justifyContent: 'flex-end',
    },
    title: {
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    statusTag: {
        maxWidth: 130,
    },
});
