import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Heading1 } from 'components/primitives/StyledText';
import { useTheme } from 'react-native-paper';

const ComingSoon: React.FC = () => {
    const theme = useTheme();

    return (
        <ScrollView contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
            <Heading1>Coming soon!</Heading1>
        </ScrollView>
    );
};
export default ComingSoon;

const styles = StyleSheet.create({
    container: {
        padding: 20,
        flex: 1,
        justifyContent: 'space-between',
    },
});
