import Constants from 'expo-constants';
import axios from 'axios';
import { IS_PRODUCTION } from 'utils/BootstrapHelpers';
import { logError } from 'utils/logging/Logger';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import { CreditCardType } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/integration/paylease/dataobjects/CreditCardType';
import { ZegoGatewayPaymentMethodDo } from 'models/remotecmds/com/ocs/nirvana/businesslogic/integration/paylease/dataobjects/ZegoGatewayPaymentMethodDo';
import { PaymentType } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/integration/paylease/dataobjects/PaymentType';
import { NullableBankAccountType } from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/integration/paylease/dataobjects/BankAccountType';
import * as yup from 'yup';
import { regexDigitsOnly, regexZip } from 'utils/FormUtils';
import { percentageOfCents, roundToFullNumber } from 'utils/CurrencyUtils';

export const CREDIT_CARD_PROCESSING_FEE_PERCENTAGE = 2.25;
export const DEBIT_CARD_PROCESSING_FEE = 4.95;
export const INELIGIBLE_DEBIT_CARD_ERROR_CODE = '347';
export const INELIGIBLE_DEBIT_CARD_ERROR_MESSAGE = 'The card specified is ineligible to be used as a debit card.';

export const PAYLEASE_CREDIT_CARD_API_URL =
    Constants.expoConfig?.extra && Constants.expoConfig.extra['payleaseBaseUrl']
        ? Constants.expoConfig.extra['payleaseBaseUrl'] + '/api/v6/gateway/credit-card-payer-account/'
        : '';

export const errorContainsIneligibleDebitCardErrorMessage = (error: string): boolean => {
    return !!error && error.indexOf(INELIGIBLE_DEBIT_CARD_ERROR_MESSAGE) > -1;
};
export type PaymentFormValues = {
    paymentReferenceId: string;
    payleaseAccount: string;
    payerFirstName: string;
    payerLastName: string;
    amount: number;
    payByMethod: PayByMethod;
    //values the need to pass to server for saving
    paymentMethodId: number;
    gatewayPayerId: number | null;
    paymentType: PaymentType;
    lastFour: string;
    bankOrCardName: string;
    save: boolean;
    //banck account
    accountType: NullableBankAccountType;
    accountFullName: string;
    routingNumber: string;
    accountNumber: string;
    //credit or debit card
    creditCardType: CreditCardType;
    creditCardNumber: string;
    creditCardExpMonth: string;
    creditCardExpYear: string;
    creditCardCvv2: string;
    billingFirstName: string;
    billingLastName: string;
    billingStreetAddress: string;
    billingCity: string;
    billingState: string;
    billingCountry: string;
    billingZip: string;
    isDebitCard: 'Yes' | 'No';
};

export type ZegoRequestTransaction = {
    PayerReferenceId: string;
    PayerFirstName: string;
    PayerLastName: string;
    CreditCardType: CreditCardType;
    CreditCardNumber: string;
    CreditCardExpMonth: string;
    CreditCardExpYear: string;
    CreditCardCvv2: string;
    BillingFirstName: string;
    BillingLastName: string;
    BillingStreetAddress: string;
    BillingCity: string;
    BillingState: string | null;
    BillingCountry: string;
    BillingZip: string;
    IsDebitCard: 'Yes' | 'No';
};

export type ZegoResponseTransaction = {
    TransactionAction: string;
    PayerReferenceId: string;
    GatewayPayerId: string;
    Code: string;
    Status: string;
    Message: string;
};

export type ZegoCreateCreditCardPayerIdRequest = {
    Mode: 'Production' | 'Test';
    Transactions: Array<ZegoRequestTransaction>;
};

export type ZegoCreateCreditCardPayerIdResponse = {
    Mode: 'Production' | 'Test';
    Transactions: Array<ZegoResponseTransaction>;
};

export type PayByMethod = 'savedPaymentMethod' | 'newCard' | 'newBank' | '';

export type ZegoPaymentInfo = {
    payByMethod: PayByMethod;
    amount: number | undefined;
    incurFee: boolean;
    paymentMethod: ZegoGatewayPaymentMethodDo;
    creditCardDetails: ZegoRequestTransaction | null;
};

export const paymentFormValidationSchema = yup.object().shape({
    payByMethod: yup.string().nullable().required('Please select a payment method'),
    amount: yup.number().required('Please enter an valid amount').moreThan(0, 'Please enter an valid amount'),
    accountType: yup.string().when('payByMethod', {
        is: 'newBank',
        then: yup.string().required('Please select an account type').nullable(),
        otherwise: yup.string().nullable(),
    }),
    accountFullName: yup.string().when('payByMethod', {
        is: 'newBank',
        then: yup
            .string()
            .required('Please enter the name on the account')
            .max(100, 'Account full name can not have more than 100 characters'),
        otherwise: yup.string(),
    }),
    routingNumber: yup.string().when('payByMethod', {
        is: 'newBank',
        then: yup
            .string()
            .required('Please enter the routing number')
            .max(9, 'Maximum length of the routing number is 9 digits')
            .matches(regexDigitsOnly, 'Please enter numbers only'),
        otherwise: yup.string(),
    }),
    accountNumber: yup.string().when('payByMethod', {
        is: 'newBank',
        then: yup
            .string()
            .required('Please enter the account number')
            .max(20, 'Maximum length of the account number is 20 digits')
            .matches(regexDigitsOnly, 'Please enter numbers only'),
        otherwise: yup.string(),
    }),
    creditCardNumber: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup
            .string()
            .required('Please enter the card number')
            .matches(regexDigitsOnly, 'Please enter numbers only')
            .max(16, 'The card number exceeds the maximum length, the maximum length is 16'),
        otherwise: yup.string(),
    }),
    creditCardType: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup.string().when('isDebitCard', {
            is: 'No',
            then: yup
                .string()
                .nullable()
                .oneOf(
                    ['Visa', 'MasterCard', 'Discover', 'Amex'],
                    'Only Visa, Mastercard, Discover, American Express are accepted',
                ),
            otherwise: yup
                .string()
                .nullable()
                .oneOf(
                    ['Visa', 'MasterCard', 'Discover'],
                    'Only Visa Debit Card, Debit Mastercard, Discover Debit card are accepted',
                ),
        }),

        otherwise: yup.string(),
    }),
    creditCardExpMonth: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup.string().required('Please select the expiration month').max(2, 'Please enter a valid month'),
        otherwise: yup.string(),
    }),
    creditCardExpYear: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup.string().required('Please select the expiration year').max(2, 'Please enter a valid year'),
        otherwise: yup.string(),
    }),
    creditCardCvv2: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup
            .string()
            .required('Please enter the CVV')
            .max(4, 'The CVV exceeds the maximum length, the maximum length is 4')
            .matches(regexDigitsOnly, 'Please enter numbers only'),
        otherwise: yup.string(),
    }),
    billingFirstName: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup
            .string()
            .required('Please enter the first name')
            .max(100, 'The first name exceeds the maximum length, the maximum length is 100'),
        otherwise: yup.string(),
    }),
    billingLastName: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup
            .string()
            .required('Please enter the last name')
            .max(100, 'The last name exceeds the maximum length, the maximum length is 100'),
        otherwise: yup.string(),
    }),
    billingStreetAddress: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup
            .string()
            .required('Please enter the street address')
            .max(200, 'The street address exceeds the maximum length, the maximum length is 200'),
        otherwise: yup.string(),
    }),
    billingCity: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup
            .string()
            .required('Please enter the city')
            .max(100, 'The city exceeds the maximum length, the maximum length is 100'),
        otherwise: yup.string(),
    }),
    billingState: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup.string().required('Please select the state'),
        otherwise: yup.string(),
    }),
    billingCountry: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup.string().required('Please select the country'),
        otherwise: yup.string(),
    }),
    billingZip: yup.string().when('payByMethod', {
        is: 'newCard',
        then: yup
            .string()
            .required('Please enter the zip code')
            .max(20, 'The zip code exceeds the maximum length, the maximum length is 20')
            .matches(regexZip, 'Please enter a valid zip'),
        otherwise: yup.string(),
    }),
});

export function getPaymentFormInitialValues(
    paymentReferenceId: string,
    payleaseAccount: string,
    firstName: string,
    lastName: string,
    amount: number,
): PaymentFormValues {
    return {
        paymentReferenceId: paymentReferenceId,
        payleaseAccount: payleaseAccount,
        payerFirstName: firstName,
        payerLastName: lastName,
        amount: amount,
        payByMethod: '',
        paymentMethodId: 0,
        gatewayPayerId: null,
        paymentType: 'Bank',
        lastFour: '',
        bankOrCardName: '',
        save: true,

        accountType: null,
        accountFullName: '',
        routingNumber: '',
        accountNumber: '',

        creditCardType: 'Visa',
        creditCardNumber: '',
        creditCardExpMonth: '',
        creditCardExpYear: '',
        creditCardCvv2: '',
        billingFirstName: '',
        billingLastName: '',
        billingStreetAddress: '',
        billingCity: '',
        billingState: '',
        billingCountry: 'US',
        billingZip: '',
        isDebitCard: 'No',
    };
}

export function getZegoCreditCardTypeFromDetectedCardType(detectedCardType: string): CreditCardType | null {
    switch (detectedCardType) {
        case 'visa':
            return 'Visa';
        case 'mastercard':
            return 'MasterCard';
        case 'discover':
            return 'Discover';
        case 'american-express':
            return 'Amex';
    }

    return null;
}

export function buildZegoPaymentInfoFromFormValues(
    formValues: PaymentFormValues | undefined,
    paymentReferenceId: string,
    payleaseAccount: string,
    incurFee: boolean,
): ZegoPaymentInfo | undefined {
    if (formValues) {
        return {
            payByMethod: formValues.payByMethod,
            amount: formValues.amount,
            incurFee: incurFee,
            paymentMethod: {
                paymentReferenceId: paymentReferenceId,
                paymentTraceId: paymentReferenceId,
                paymentMethodId: formValues.paymentMethodId,
                payleaseAccount: payleaseAccount,
                gatewayPayerId: formValues.gatewayPayerId,
                paymentType: formValues.paymentType,
                lastFour: formValues.lastFour,
                bankOrCardName: formValues.bankOrCardName,
                save: formValues.save,
                accountType: formValues.accountType,
                accountFullName: formValues.accountFullName,
                routingNumber: formValues.routingNumber,
                accountNumber: formValues.accountNumber,
                createdUcd: null,
                createdDtt: null,
                payerFirstName: formValues.payerFirstName,
                payerLastName: formValues.payerLastName,
                databaseId: '',
            },
            creditCardDetails: {
                PayerReferenceId: formValues.payleaseAccount,
                PayerFirstName: formValues.payerFirstName,
                PayerLastName: formValues.payerLastName,
                CreditCardType: formValues.creditCardType,
                CreditCardNumber: formValues.creditCardNumber,
                CreditCardExpMonth: formValues.creditCardExpMonth,
                CreditCardExpYear: formValues.creditCardExpYear,
                CreditCardCvv2: formValues.creditCardCvv2,
                BillingFirstName: formValues.billingFirstName,
                BillingLastName: formValues.billingLastName,
                BillingStreetAddress: formValues.billingStreetAddress,
                BillingCity: formValues.billingCity,
                BillingState: formValues.billingState,
                BillingCountry: formValues.billingCountry,
                BillingZip: formValues.billingZip,
                IsDebitCard: formValues.isDebitCard,
            },
        };
    } else {
        return undefined;
    }
}

export function getProcessingFee(paymentInfo: ZegoPaymentInfo): number {
    if (paymentInfo.incurFee) {
        return 0;
    } else {
        if (paymentInfo.paymentMethod.bankOrCardName === 'Debit') {
            return DEBIT_CARD_PROCESSING_FEE * 100;
        } else if (paymentInfo.paymentMethod.bankOrCardName === 'Bank') {
            return 0;
        } else {
            const feePercentage = getProcessingFeePercentage(paymentInfo);
            if (paymentInfo.amount && paymentInfo.amount >= 0) {
                return percentageOfCents(paymentInfo.amount, feePercentage);
            } else {
                return 0;
            }
        }
    }
}

export function getProcessingFeePercentage(paymentInfo: ZegoPaymentInfo): number {
    if (paymentInfo.incurFee) {
        return 0;
    } else {
        if (
            paymentInfo.paymentMethod.bankOrCardName === 'Debit' ||
            paymentInfo.paymentMethod.bankOrCardName === 'Bank'
        ) {
            return 0;
        } else {
            return CREDIT_CARD_PROCESSING_FEE_PERCENTAGE;
        }
    }
}

export async function createZegoCreditCardPayerId(
    requestToken: string,
    zegoRequestTransaction: ZegoRequestTransaction,
): Promise<string> {
    try {
        const zegoRequest: ZegoCreateCreditCardPayerIdRequest = {
            Mode: IS_PRODUCTION ? 'Production' : 'Test',
            Transactions: [zegoRequestTransaction],
        };
        const response = await axios({
            method: 'post',
            params: { RequestToken: requestToken },
            url: PAYLEASE_CREDIT_CARD_API_URL,
            timeout: 60000, //Set the timeout to a minute
            data: zegoRequest,
        });

        const zegoResponse: ZegoCreateCreditCardPayerIdResponse = response.data;

        if (response.status == 202 || response.status == 200) {
            if (zegoResponse.Transactions[0].Status === 'Error') {
                if (zegoResponse.Transactions[0].Code === INELIGIBLE_DEBIT_CARD_ERROR_CODE) {
                    //this is a credit card not debit card
                    return new Promise((resolve, reject) => reject(INELIGIBLE_DEBIT_CARD_ERROR_MESSAGE));
                } else {
                    logError(
                        ERROR_LOGGER,
                        'Zego - create credit card payer ID failed with error ',
                        JSON.stringify(zegoResponse.Transactions),
                    );
                }
            } else if (!zegoResponse.Transactions[0].GatewayPayerId) {
                logError(
                    ERROR_LOGGER,
                    'Zego - create credit card payer ID status ok but gatewayPayerId is empty',
                    JSON.stringify(zegoResponse.Transactions),
                );
            }

            return zegoResponse.Transactions[0].GatewayPayerId;
        } else {
            logError(
                ERROR_LOGGER,
                'Zego - create credit card payer ID failed with status ' + response.status + ' ',
                JSON.stringify(zegoResponse),
            );
            return '';
        }
    } catch (e) {
        logError(ERROR_LOGGER, 'Zego - create credit card payer ID failed with exception ', JSON.stringify(e));
        return '';
    }
}
