import React from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { v4 as uuid } from 'uuid';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { SmarthubTransactionV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionV1';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { globalStyles } from 'theme/style/GlobalStyles';
import { formatDateStringMonthLabelFromNumber } from 'utils/DateUtils';

interface TransactionDetailsListProps {
    currentTransaction: SmarthubTransactionV1;
}

const TransactionDetailsList: React.FC<TransactionDetailsListProps> = observer(({ currentTransaction }) => {
    return (
        <View>
            {currentTransaction.details &&
                currentTransaction.details.map(t => {
                    let desc: string = t.description;
                    let subDesc: string | null = null;
                    const bracketIndx: number = desc.lastIndexOf('(');
                    if (bracketIndx > -1) {
                        subDesc = desc.substring(bracketIndx);
                        desc = desc.substring(0, bracketIndx);
                    }
                    return (
                        <View key={uuid()} style={styles.transactiondetail}>
                            <View style={[globalStyles.flexColumnStart, styles.description]}>
                                <RegularText>{desc}</RegularText>
                                {!!subDesc && <RegularText>{subDesc}</RegularText>}
                            </View>
                            <RegularText style={styles.amount}>{formatCentsAsDollars(t.amount)}</RegularText>
                        </View>
                    );
                })}

            {!!currentTransaction.outstandingBalanceAmt && (
                <View key={uuid()} style={styles.transactiondetail}>
                    <View style={[globalStyles.flexColumnStart, styles.description]}>
                        <RegularText>Outstanding Balance as of</RegularText>
                        <RegularText>
                            {formatDateStringMonthLabelFromNumber(currentTransaction.transactionDtt)}
                        </RegularText>
                    </View>

                    <RegularText>{formatCentsAsDollars(currentTransaction.outstandingBalanceAmt)}</RegularText>
                </View>
            )}

            <View key={uuid()} style={styles.transactiontotal}>
                <RegularBoldText>Total Statement Amount</RegularBoldText>
                <RegularBoldText>{formatCentsAsDollars(currentTransaction.amount)}</RegularBoldText>
            </View>
        </View>
    );
});

export default TransactionDetailsList;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    transactiondetail: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: SmarthubTheme.layout.LINESPACE,
    },
    transactiontotal: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: SmarthubTheme.layout.SECTIONMARGIN,
    },
    description: {
        width: '70%',
        flexWrap: 'wrap',
    },
    amount: {},
});
