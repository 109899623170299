import React from 'react';
import { observer } from 'mobx-react-lite';
import Hyperlink from 'components/primitives/Hyperlink';
import { SmarthubTransactionV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionV1';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import Button from 'components/primitives/Button';
import { viewPdfFromUrl } from 'utils/FileUtils';

export type OutputAs = 'button' | 'link';

interface StatementPDFLinkProps {
    currentTransaction: SmarthubTransactionV1;
    outputAs?: OutputAs;
}

const StatementPDFLink: React.FC<StatementPDFLinkProps> = observer(({ currentTransaction, outputAs }) => {
    if (null == currentTransaction || !currentTransaction?.pdfLink) return null;
    logInfo(INFO_LOGGER, 'Statement link: ', currentTransaction?.pdfLink || 'none');

    const openStatement = () => {
        viewPdfFromUrl(currentTransaction!.pdfLink, 'statement.pdf');
    };
    const statementQualifier = currentTransaction.type === 'FinalStatement' ? 'Final' : 'Full';
    const linkText = `View ${statementQualifier} Statement (PDF)`;

    if (outputAs && outputAs === 'button') {
        return (
            <Button onClick={openStatement} buttonType={'secondary'}>
                {linkText}
            </Button>
        );
    } else {
        return <Hyperlink onClick={openStatement}>{linkText}</Hyperlink>;
    }
});

export default StatementPDFLink;
