export enum QueryKeys {
    Residencies = 'Residencies', //authorized residencies
    //pretty much everything below should be invalidated when you switch leases
    Requests = 'Requests', //maintenance requests and smartdesk tickets
    Request = 'Request',
    AddEditRequestData = 'AddEditRequestData', //data needed for request entry screen
    Transactions = 'Transactions', //statements and payments
    Notifications = 'Notifications', //smarthub notifications and any red dots (seen/unseen)
    Contracts = 'Contracts', //electronic signed contracts
    ResidentProfile = 'ResidentProfile', //current login resident profile info
    CommunityInfo = 'CommunityInfo', //community details
    CommunityOfficeHours = 'CommunityOfficeHours', //community hours
    NotificationPreferences = 'NotificationPreferences', //alerts and notifications settings
    CommSubscriptions = 'CommSubscriptions', //alerts and notifications settings
    AwayTimes = 'AwayTimes', //away times for the current residency
    AddEditAwayTimeData = 'AddEditAwayTimeData', //data needed for away time entry screen

    PaymentMethods = 'PaymentMethods',
    CheckPaymentStatus = 'CheckPaymentStatus',
    PaymentData = 'PaymentData',
    //Smartmove...
    ApplicationHeader = 'ApplicationHeader',
    MoveInCharges = 'MoveInCharges',
    UtilitiesAndInsurances = 'UtilitiesAndInsurances',
    SecurityOptions = 'SecurityOptions',
    VehiclesAndPets = 'VehiclesAndPets',

    //Renewal
    RenewMyLease = 'RenewMyLease',
    //contract
    LeaseRenewalContractSigning = 'LeaseRenewalContractSigning',
    ContractSigningUrl = 'ContractSigningUrl',
    SmartmoveContractSigning = 'SmartmoveContractSigning',
    ContractSigning = 'ContractSigning',
    SmartPackages = 'SmartPackages',
}
