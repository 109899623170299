/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.dataobjects.SmarthubRequestSubmitData
 */
export interface SmarthubRequestSubmitData {
    propertyCd: string;
    residencyId: number;
    residentId: number;
    desc: string;
    contactResidentId: number;
    contactName: string;
    contactDetail: string;
    contactOtherDetail: string;
    allowEntry: boolean;
    alarmCode: string;
    specialInstruction: string;
}

export function createSmarthubRequestSubmitData(): SmarthubRequestSubmitData {
    return {
        propertyCd: '',
        residencyId: 0,
        residentId: 0,
        desc: '',
        contactResidentId: 0,
        contactName: '',
        contactDetail: '',
        contactOtherDetail: '',
        allowEntry: false,
        alarmCode: '',
        specialInstruction: '',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
