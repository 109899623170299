import { AbstractResidencyAuthenticatedCommand } from '../AbstractResidencyAuthenticatedCommand';
import { SecurityChoice } from '../../shared/businesslogic/contract/dataobjects/SecurityChoice';
import { EmptyVersionedRemoteResult } from '../../../remotecmd/versioned/EmptyVersionedRemoteResult';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.smartmove.SaveSmartmoveSecuritySelectionCommandV1
 */
export interface SaveSmartmoveSecuritySelectionCommandV1
    extends AbstractResidencyAuthenticatedCommand<EmptyVersionedRemoteResult> {
    securityChoice: SecurityChoice;
    residentId: number;
}

export function createSaveSmartmoveSecuritySelectionCommandV1(): SaveSmartmoveSecuritySelectionCommandV1 {
    return {
        securityChoice: 'NoChoice',
        residentId: 0,
        propertyCd: '',
        residencyId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.smartmove.SaveSmartmoveSecuritySelectionCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
