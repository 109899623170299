import {
    LOG_LEVEL_ERROR,
    LOG_LEVEL_INFO,
    LOG_LEVEL_NONE,
    registerLogger,
    setGlobalLogLevel,
    setLogLevel,
} from './Logger';

export const ERROR_LOGGER = 0;
export const INFO_LOGGER = 1;
export const RPC_COMMAND_LOGGER = 2;
export const RPC_RESULT_LOGGER = 3;
export const BOOTSTRAP_LOGGER = 4;
export const NAVIGATION_LOGGER = 5;

registerLogger(ERROR_LOGGER);
registerLogger(INFO_LOGGER);
registerLogger(RPC_COMMAND_LOGGER);
registerLogger(RPC_RESULT_LOGGER);
registerLogger(BOOTSTRAP_LOGGER);
registerLogger(NAVIGATION_LOGGER);

//default log level...
setGlobalLogLevel(LOG_LEVEL_ERROR);

//Override default log level...
setLogLevel(ERROR_LOGGER, LOG_LEVEL_ERROR);
setLogLevel(INFO_LOGGER, LOG_LEVEL_NONE);
setLogLevel(RPC_COMMAND_LOGGER, LOG_LEVEL_NONE);
setLogLevel(RPC_RESULT_LOGGER, LOG_LEVEL_NONE);
setLogLevel(BOOTSTRAP_LOGGER, LOG_LEVEL_NONE);
setLogLevel(NAVIGATION_LOGGER, LOG_LEVEL_NONE);
