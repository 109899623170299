import { getAuth } from 'firebase/auth';

export const getFirebaseUserEmail = (): string => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    return currentUser?.email || currentUser?.providerData?.filter(p => null != p && null != p.email)[0]?.email || '';
};

export const getFirebaseUserPhoneNumber = (): string | null | undefined => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    return currentUser?.phoneNumber;
};
