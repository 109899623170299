import * as FileSystem from 'expo-file-system';
import * as IntentLauncher from 'expo-intent-launcher';
import { logError } from 'utils/logging/Logger';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';

export const viewPdfFromUrl = async (pdfLink: string, fileName: string) => {
    if (Platform.OS === 'android') {
        FileSystem.downloadAsync(pdfLink, FileSystem.documentDirectory + fileName)
            .then(({ uri }) => {
                FileSystem.getContentUriAsync(uri)
                    .then(cUri => {
                        IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
                            data: cUri,
                            flags: 1,
                            type: 'application/pdf',
                        });
                    })
                    .catch(e => {
                        logError(ERROR_LOGGER, 'Error opening statement PDF', e.message);
                    });
            })
            .catch(error => {
                logError(ERROR_LOGGER, 'Error downloading statement PDF', error.message);
            });
    } else {
        WebBrowser.openBrowserAsync(pdfLink);
    }
};
