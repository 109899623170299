import React, { ReactElement } from 'react';
import { useNavigation } from '@react-navigation/native';
import { ListRenderItem, StyleProp, StyleSheet, TouchableOpacity, View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { DeemphansizedTextSmall, DeephansizedRegularText, RegularText } from 'components/primitives/StyledText';
import { Badge, useTheme } from 'react-native-paper';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { TagTypes } from 'components/primitives/TagTypes';
import Tag from 'components/primitives/Tag';
import { observer } from 'mobx-react-lite';
import LineLimitedText from 'components/primitives/LineLimitedText';
import { SmarthubNotificationTypeV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationTypeV1';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps, updateStringForIDName } from 'components/ComponentTypes';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import {
    hasUnseenNotificationForTypeAndId,
    typesHaveUnseenNotifications,
    useSmarthubNotifications,
    numberOfNotificationsOfType,
} from 'screens/home/requests/NotificationsHooks';

export type ListItemProps = {
    id: string;
    title: string;
    titleStyle?: StyleProp<any>;
    customTitle?: ReactElement;
    subtitle?: string;
    subtitleStyle?: StyleProp<any>;
    desc?: string;
    descStyle?: StyleProp<any>;
    descExpandable?: boolean;
    descNumberOfLines?: number;
    status?: string;
    statusTagType?: TagTypes;
    routeName?: string;
    doAction?: () => void;
    notifications?: SmarthubNotificationV1[];
    notificationTypes?: Array<SmarthubNotificationTypeV1>;
    notificationRefId?: number;
    showNotificationBubble?: boolean;
    notificationMenuDesc?: string;
    hideChevron?: boolean;
    testID?: string;
    accessibilityLabel?: string;
    hidden?: boolean;
    footerItem?: ReactElement | undefined;
};

const ListItem: React.FC<ListItemProps> = observer(
    ({
        title,
        titleStyle,
        customTitle,
        subtitle,
        subtitleStyle,
        desc,
        descStyle,
        descNumberOfLines,
        descExpandable,
        status,
        statusTagType,
        routeName,
        id,
        doAction,
        notifications,
        notificationTypes,
        notificationRefId,
        showNotificationBubble,
        notificationMenuDesc,
        hideChevron,
        testID,
        hidden,
        footerItem,
    }: ListItemProps) => {
        const navigation = useNavigation();
        const theme = useTheme();
        const idFromTitle = title ? updateStringForIDName(title) : `no-title-given`;
        const indentifier = testID ? `${testID}-${idFromTitle}` : `no-id-given-${idFromTitle}`;

        const newestNotifications = notifications
            ? notifications || ([] as SmarthubNotificationV1[])
            : useSmarthubNotifications(false).data?.notifications || ([] as SmarthubNotificationV1[]);

        const parseNotificationMenuDesc = (): string => {
            let updatedDesc = notificationMenuDesc ? notificationMenuDesc : '';
            const notificationCount: number = numberOfNotificationsOfType('SmartPackage', newestNotifications);

            if (notificationCount < 1) {
                return '';
            }

            if (notificationMenuDesc) {
                updatedDesc = notificationMenuDesc.replace('$count', notificationCount.toString());
                updatedDesc = notificationCount === 1 ? updatedDesc.replace('packages', 'package') : updatedDesc;
            }

            return updatedDesc;
        };

        if (!hidden) {
            return (
                <TouchableOpacity
                    key={id}
                    onPress={() => (doAction && doAction()) || (routeName && navigation.navigate(routeName))}
                    activeOpacity={doAction || routeName ? 0.2 : 1}>
                    <View {...testProps(indentifier)}>
                        <View style={styles.listitemcontainer}>
                            <View style={styles.listitemcontainerleft}>
                                <View style={styles.title}>
                                    <View style={styles.badgedtext}>
                                        {customTitle ? (
                                            customTitle
                                        ) : (
                                            <RegularText style={titleStyle}>{title}</RegularText>
                                        )}
                                        {/*// @ts-ignore */}
                                        <Badge
                                            {...testProps('badge' + idFromTitle)}
                                            dataDetectorType={'none'}
                                            visible={
                                                showNotificationBubble ||
                                                (notificationTypes &&
                                                    ((!notificationRefId &&
                                                        typesHaveUnseenNotifications(
                                                            notificationTypes,
                                                            newestNotifications,
                                                        )) ||
                                                        (notificationRefId &&
                                                            hasUnseenNotificationForTypeAndId(
                                                                notificationTypes,
                                                                notificationRefId,
                                                                newestNotifications,
                                                            )))) ||
                                                false
                                            }
                                            size={10}
                                            style={globalStyles.notificationBadge}
                                        />
                                    </View>
                                    {!!status && (
                                        <View>
                                            <Tag tagType={statusTagType}>{status}</Tag>
                                        </View>
                                    )}
                                </View>
                                {!!subtitle && (
                                    <View>
                                        <DeephansizedRegularText style={subtitleStyle}>
                                            {subtitle}
                                        </DeephansizedRegularText>
                                    </View>
                                )}
                                {!!notificationMenuDesc && useResponsiveContext().useFullExperience && (
                                    <View>
                                        <DeemphansizedTextSmall style={styles.notificationMenuDesc}>
                                            {parseNotificationMenuDesc()}
                                        </DeemphansizedTextSmall>
                                    </View>
                                )}
                                {!!desc && (
                                    <View style={styles.desc}>
                                        <LineLimitedText
                                            numberOfLines={descNumberOfLines}
                                            expandable={descExpandable}
                                            style={descStyle}
                                            text={desc}
                                        />
                                    </View>
                                )}
                            </View>

                            <View style={[styles.listitemcontainerright]}>
                                {(doAction || routeName) && (
                                    <MaterialIcons
                                        name={'chevron-right'}
                                        size={30}
                                        color={theme.colors.icon}
                                        style={
                                            hideChevron
                                                ? [styles.chevronwidth, styles.chevronhide]
                                                : [styles.chevronwidth, styles.chevronshow]
                                        }
                                    />
                                )}
                            </View>
                        </View>
                        {footerItem && <View style={globalStyles.sectionSpaceBelow}>{footerItem}</View>}
                    </View>
                </TouchableOpacity>
            );
        } else {
            return null;
        }
    },
);

export default ListItem;

export const renderListItem: ListRenderItem<ListItemProps> = ({ item }) => <ListItem {...item} />;

const styles = StyleSheet.create({
    listitemcontainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: SmarthubTheme.layout.GRIDINCREMENT * 3,
    },
    listitemcontainerleft: {
        flex: 9,
        flexDirection: 'column',
    },
    listitemcontainerright: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    title: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    chevronwidth: { width: 30 },
    chevronshow: { opacity: 1 },
    chevronhide: { opacity: 0 },
    badgedtext: {
        flexDirection: 'row',
    },
    desc: {
        marginTop: 20,
    },
    notificationMenuDesc: {
        position: 'absolute',
        alignSelf: 'flex-end',
        marginTop: -(SmarthubTheme.layout.GRIDINCREMENT * 2),
        marginRight: 10,
    },
});
