import { isNumeric } from 'utils/StringUtils';

export interface daysHoursMinsObject {
    days: number;
    hours: number;
    mins: number;
    daysMs: number;
    hoursMs: number;
    minsMs: number;
}

export function getCurrentDateDB(): string {
    return formatDateDB(new Date());
}

export function getCurrentTimestampDB(): string {
    return formatTimestampDB(new Date());
}

export function formatDateDB(dt: Date | null | undefined): string {
    if (dt == null) return '';

    return padNumber(dt.getFullYear(), 4) + '-' + padNumber(dt.getMonth() + 1, 2) + '-' + padNumber(dt.getDate(), 2);
}

export function formatTimestampDB(dt: Date | null): string {
    if (dt == null) return '';

    const timeComponent =
        padNumber(dt.getHours(), 2) +
        ':' +
        padNumber(dt.getMinutes(), 2) +
        ':' +
        padNumber(dt.getSeconds(), 2) +
        '.' +
        padNumber(dt.getMilliseconds(), 3);

    return formatDateDB(dt) + 'T' + timeComponent;
}

export function parseDateDB(dt: string | null): Date | null {
    if (dt != null) {
        const parts = dt.split('-');
        if (parts.length !== 3) return null;

        const year = Number.parseInt(parts[0]);
        const month = Number.parseInt(parts[1]) - 1;
        const day = Number.parseInt(parts[2]);

        return new Date(year, month, day);
    } else {
        return null;
    }
}

//Parse a timestamp in the form 2018-08-28 16:43:00.863
export function parseTimestampDB(dt: string | null): Date | null {
    if (dt != null) {
        const parts = dt.split('T');

        if (parts.length !== 2) return null;

        const ts = parseDateDB(parts[0]);

        if (ts != null) {
            const timeParts = parts[1].split(':');

            if (timeParts.length !== 3) return null;

            const hours = Number.parseInt(timeParts[0]);
            const minutes = Number.parseInt(timeParts[1]);

            const secondParts = timeParts[2].split('.');

            if (secondParts.length !== 2) return null;

            const seconds = Number.parseInt(secondParts[0]);
            const ms = Number.parseInt(secondParts[1]);

            ts.setHours(hours);
            ts.setMinutes(minutes);
            ts.setSeconds(seconds);
            ts.setMilliseconds(ms);
        } else {
            return null;
        }

        return ts;
    } else {
        return null;
    }
}

export function parseDateMMDDYYYY(dt: string | null): Date | undefined {
    if (dt != null) {
        const parts = dt.split('/');
        if (parts.length !== 3) return undefined;

        const month = Number.parseInt(parts[0]) - 1;
        const day = Number.parseInt(parts[1]);
        const year = Number.parseInt(parts[2]);

        return new Date(year, month, day);
    } else {
        return undefined;
    }
}

export function formatDate(dt: Date | null | undefined): string {
    if (dt == null) return '';

    return padNumber(dt.getMonth() + 1, 2) + '/' + padNumber(dt.getDate(), 2) + '/' + padNumber(dt.getFullYear(), 4);
}

export function formatDateDBAsDate(dt: string | null): string {
    if (dt == null) return '';

    return formatDate(parseDateDB(dt));
}

export function formatTimestampDBAsTimestamp(dtt: string | null): string {
    if (dtt == null) return '';

    return formatTimeStamp(parseTimestampDB(dtt));
}

export function formatDateStringMonthLabel(dt: string | null | undefined): string {
    if (!dt) return '';

    return formatDateMonthLabel(parseDateMMDDYYYY(dt));
}

export function formatDateStringMonthLabelFromNumber(dt: number | null): string {
    if (!dt) return '';

    return formatDateMonthLabel(new Date(dt));
}

export function formatDateStringMonthLabelAtTimeFromNumber(dt: number | null): string {
    if (!dt) return '';

    const asDt: Date = new Date(dt);

    return formatDateMonthLabel(asDt) + ' at ' + formatTime(asDt);
}

export function formatDateStringMonthLabelAndYearOnly(dt: string | null): string | undefined {
    if (!dt) return '';

    return formatDateMonthLabelAndYearOnly(parseDateMMDDYYYY(dt));
}

export function formatDateStringMonthLabelAndYearOnlyFromNumber(dt: number | null): string {
    if (!dt) return '';

    return formatDateMonthLabelAndYearOnly(new Date(dt));
}

export function formatDateMMDDYYYY(dt: Date | null): string {
    if (!dt) return '';

    return padNumber(dt.getMonth() + 1, 2) + '/' + padNumber(dt.getDate(), 2) + '/' + dt.getFullYear();
}

export function formatDateMonthLabel(dt: Date | null | undefined): string {
    if (!dt) return '';

    return monthLabels[dt.getMonth()] + ' ' + dt.getDate() + ', ' + dt.getFullYear();
}

export function formatDateMonthLabelNoYearFromNumber(dt: number | null | undefined): string {
    if (!dt) return '';

    return formatDateMonthLabelNoYear(new Date(dt));
}

export function formatDateMonthLabelNoYear(dt: Date | null | undefined): string {
    if (!dt) return '';

    return monthLabels[dt.getMonth()] + ' ' + dt.getDate();
}

export function formatDateMonthLabelFullNoYear(dt: Date | null | undefined): string {
    if (!dt) return '';

    return monthLabelsFull[dt.getMonth()] + ' ' + dt.getDate();
}

export function formatDateMonthLabelAndYearOnly(dt: Date | null | undefined): string {
    if (!dt) return '';

    return monthLabelsFull[dt.getMonth()] + ' ' + dt.getFullYear();
}

export function convertDtStringToLongForm(dt: string): string {
    if (!dt) return '';

    return (
        monthLabels[parseInt(dt.substring(dt.indexOf('-') + 1, dt.indexOf('-') + 3)) - 1] +
        ' ' +
        dt.slice(-2) +
        ', ' +
        dt.substring(0, 4)
    );
}

export function formatTimeStamp(ts: Date | null): string {
    if (!ts) return '';

    return (formatDateMonthLabel(ts) + '  ' + formatTime(ts)).trimRight();
}

export function formatTime(ts: Date | null): string {
    if (!ts) return '';

    const hours = ts.getHours() <= 12 ? ts.getHours() : ts.getHours() - 12;
    const amOrPm = ts.getHours() < 12 ? ' AM' : ' PM';

    return padNumber(hours, 2) + ':' + padNumber(ts.getMinutes(), 2) + amOrPm;
}

export function formatMonthFull(month: number) {
    return monthLabelsFull[month];
}

export function getTimeZone(ts: Date | null): string {
    if (!ts) return '';

    const timeString = ts.toString();
    return timeString.substr(timeString.indexOf('(') + 1, 3);
}

export function daysBetween(date1: Date | undefined, date2: Date | undefined): number {
    //Get 1 day in milliseconds
    const one_day = 1000 * 60 * 60 * 24;

    if (date1 && date2) {
        // Convert both dates to milliseconds
        const date1_ms = date1.getTime();
        const date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        const difference_ms = date2_ms - date1_ms;

        // Convert back to days and return
        return Math.round(difference_ms / one_day);
    } else {
        return 0;
    }
}

export function daysBetweenIgnoreTime(date1: Date | undefined, date2: Date | undefined): number {
    return daysBetween(normalizeDate(date1), normalizeDate(date2));
}

export function normalizeDate(date: Date | undefined) {
    if (!date) return undefined;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getShortUniqueIdFromDate(): number {
    const d = new Date();
    d.setDate(d.getDate() - 7);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);

    return new Date().getTime() - d.getTime();
}

function padNumber(num: number, size: number): string {
    return ('000000000' + num).substr(-size);
}

export function formatMilliSecondsToMinutes(s: number): string {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    return padNumber(mins, 2) + ':' + padNumber(secs, 2);
}

export function getDayOfWeekByCode(dayCd: string): string {
    switch (dayCd) {
        case 'MO':
            return 'Monday';
        case 'TU':
            return 'Tuesday';
        case 'WE':
            return 'Wednesday';
        case 'TH':
            return 'Thursday';
        case 'FR':
            return 'Friday';
        case 'SA':
            return 'Saturday';
        case 'SU':
            return 'Sunday';
        default:
            return '';
    }
}

export function getTimePeriodFromHour(h: number): string {
    return h < 12 ? 'am' : 'pm';
}

export function change24HourTo12Hour(h: number): number {
    return h < 13 ? h : h - 12;
}

export const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const monthLabelsFull = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const _describePeriod = (period: string): string => {
    if (!period || !isNumeric(period)) return '';
    if (period.length !== 6) {
        return period;
    } else {
        return monthLabelsFull[Number(period.substring(4)) - 1] + ' ' + Number(period.substring(0, 4));
    }
};

export function parsePeriod(period: string): Date | undefined {
    if (period.length !== 6) return undefined;
    const year = Number.parseInt(period.substr(0, 4));
    const month = Number.parseInt(period.substr(4, 2)) - 1;
    return new Date(year, month, 1);
}

// Returns minimum date between today and date provided
export function minDateTodayOrDateProvided(dt: string | null, now: Date): string {
    const dateProvided = parseDateMMDDYYYY(dt);
    const today = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

    if (dateProvided && dateProvided >= today) {
        return formatDate(new Date(dateProvided.getTime()));
    } else {
        return formatDate(today);
    }
}

export function cloneTime(d1: Date | number, d2?: Date | number): Date {
    if (d2 == null) d2 = new Date();
    if (typeof d1 === 'number') d1 = new Date(d1);
    if (typeof d2 === 'number') d2 = new Date(d2);

    d1.setHours(d2.getHours(), d2.getMinutes(), d2.getSeconds(), d2.getMilliseconds());
    return d1;
}

export function compareDateIgnoreTime(d1: Date, d2: Date): number {
    const dd1 = new Date(d1);
    const dd2 = new Date(d2);
    dd1.setHours(0, 0, 0, 0);
    dd2.setHours(0, 0, 0, 0);

    return dd1.getTime() - dd2.getTime();
}

export function parseDateFormatDate(dt: string | null): string {
    return formatDate(parseDateMMDDYYYY(dt));
}

export function addDaysToDate(dt: Date | undefined, days: number) {
    if (!dt) return;
    dt.setDate(dt.getDate() + days);
}

export function timeBetweenDatesDHM(
    asOfDate: Date,
    targetDate: Date,
    asOfTimezoneOffset = -5,
    targetTimezoneCd = 'US/Eastern',
): daysHoursMinsObject {
    const result = {} as daysHoursMinsObject;

    const targetTimezoneOffset = convertTimezoneOffsetToHours(targetTimezoneCd);

    // You gain an hour for each timezone you go back, you lose an hour for each timezone you go forward
    const asOfTargetDiffMS = (asOfTimezoneOffset - targetTimezoneOffset) * 60 * 60 * 1000;

    // Get the difference of time in ms between the target date and the as of date + the timezone difference
    const timeBetweenMs = targetDate.getTime() - asOfDate.getTime() + asOfTargetDiffMS;

    // Get the remaining days by taking the the integer when you divide the time in ms by 1 day in ms
    const daysRem = Math.floor(timeBetweenMs / (1000 * 60 * 60 * 24));
    // Convert the number of days remaining into ms
    const daysRemMs = daysRem * (1000 * 60 * 60 * 24);

    // Get the remaining hours by subtracting time remaining in ms by days remaining in ms then dividing by 1 hour in ms and taking the integer
    const hoursRem = Math.floor((timeBetweenMs - daysRemMs) / (1000 * 60 * 60));
    // Convert hours remaining to ms
    const hoursRemMs = hoursRem * (1000 * 60 * 60);

    // Get the mins by subtracting time remaining in ms by days and hours remaining in ms then dividing by 1 day in ms and taking the integer
    const minsRem = Math.floor((timeBetweenMs - daysRemMs - hoursRemMs) / (1000 * 60));
    const minsRemMs = minsRem * (1000 * 60);

    // Can keep going with this to add seconds and milliseconds

    result.days = daysRem;
    result.hours = hoursRem;
    result.mins = minsRem;
    result.daysMs = daysRemMs;
    result.hoursMs = hoursRemMs;
    result.minsMs = minsRemMs;

    return result;
}

// Returns timezone offset in hours
export function getTimezoneOffsetHour(date: Date): number {
    return date.getTimezoneOffset() / -60;
}

// Returns in timezone offset in relation to Eastern in hours
export function convertTimezoneOffsetToHours(timezoneCd: string): number {
    switch (timezoneCd) {
        case 'US/Eastern':
            return -5;
            break;
        case 'US/Central':
            return -6;
            break;
        case 'US/Mountain':
            return -7;
            break;
        case 'US/Pacific':
            return -8;
            break;
        default:
            return 0;
    }
}

export const nth = function (dayOfMonth: number) {
    if (dayOfMonth > 3 && dayOfMonth < 21) return 'th';
    switch (dayOfMonth % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};
