import React from 'react';
import { MediumBoldText, RegularText } from 'components/primitives/StyledText';
import Hyperlink from 'components/primitives/Hyperlink';
import { navigationRoutes } from 'utils/NavigationUtils';
import { useNavigation } from '@react-navigation/native';
import { globalStyles } from 'theme/style/GlobalStyles';
import { useStore } from 'contexts/StoreContextProvider';
import SmartmoveScreenContainer from 'screens/smartmove/components/SmartmoveScreenContainer';
import { useTheme } from 'react-native-paper';
import { Platform, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import PhoneLink from 'components/primitives/PhoneLink';

const SmartMoveEditInformation: React.FC = observer(() => {
    const theme = useTheme();
    const navigation = useNavigation();
    const { userSessionStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const _navigate = () => {
        navigation.navigate(navigationRoutes.smartmoveRoutes.smartMoveUtilitiesAndRentersInsuranceEdit);
    };

    if (!hasSessionActiveResidency) return null;
    return (
        <SmartmoveScreenContainer
            showBack={Platform.OS === 'web'}
            showClose={Platform.OS !== 'web'}
            closeRoute={navigationRoutes.smartmoveRoutes.smartMoveMoveInCountdown}
        >
            <View style={Platform.OS === 'web' ? globalStyles.innerContainer : {}}>
                <MediumBoldText style={globalStyles.smartMoveTitleText}>Edit Information</MediumBoldText>
                <RegularText style={globalStyles.smartMoveParagraphText}>
                    <Hyperlink onClick={_navigate}>Change your utilities or insurance info</Hyperlink>
                </RegularText>
                <RegularText>
                    To update your vehicle and/or pet information, you must contact the community at{' '}
                    {hasSessionActiveResidency && (
                        <PhoneLink phoneNumber={sessionActiveResidency.propertyPhoneNumber} />
                    )}
                </RegularText>
            </View>
        </SmartmoveScreenContainer>
    );
});

export default SmartMoveEditInformation;
