import React, { useState } from 'react';
import { createCtx } from './createCtx';

import { useWindowDimensions } from 'react-native';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import useDebouncedEffect from 'utils/useDebouncedEffect';
import { isDesktopNotMobile } from 'utils/uiUtils';

const defaultResponsiveContext: ResponsiveContextType = {
    isPortrait: true,
    isDesktop: false,
    windowWidth: 0,
    windowHeight: 0,
    useDrawerNavigation: false,
    useFullExperience: false,
};

type ResponsiveContextType = {
    isPortrait: boolean;
    isDesktop: boolean;
    windowWidth: number;
    windowHeight: number;
    useDrawerNavigation: boolean;
    useFullExperience: boolean;
};

/**
 * Hook to Setup responsive settings:
 *
 * https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
 *
 * The overriding principle here is we are developing for mobile first. So, instead of changing styles when the width gets
 *  smaller than a certain breakpoint, we will change style when the width gets larger than a certain breakpoint and can support
 *  more content, features, etc...
 *
 * The following breakpoints are applicable:
 *
 * Mobile - One column layout - when width is below 768px
 * Two Column Layout aka "Tablet" aka "Smaller Computer" - no text on side nav and no right side content - when width is at least 768px
 * Three Column Layout aka "Desktop" aka "Larger Laptop/Computer" - full experience - text on side nav and right content when width is at least 1224px
 */
export const [useResponsiveContext, CtxProvider] = createCtx<ResponsiveContextType>();

//REF: https://www.carlrippon.com/react-context-with-typescript-p2/
type Props = {
    children: React.ReactNode;
};
const ResponsiveContextProvider = ({ children }: Props) => {
    const { width, height } = useWindowDimensions();

    defaultResponsiveContext.isDesktop = isDesktopNotMobile();
    defaultResponsiveContext.windowWidth = width;
    defaultResponsiveContext.windowHeight = height;
    defaultResponsiveContext.useDrawerNavigation = width > 767; //|| !isMobileOnly;
    defaultResponsiveContext.useFullExperience = width > 1223;

    const [isPortrait] = useState(defaultResponsiveContext.isPortrait);
    const [isDesktop] = useState(defaultResponsiveContext.isDesktop);
    const [windowWidth, setWindowWidth] = useState(defaultResponsiveContext.windowWidth);
    const [windowHeight, setWindowHeight] = useState(defaultResponsiveContext.windowHeight);
    const [useDrawerNavigation, setUseDrawerNavigation] = useState(defaultResponsiveContext.useDrawerNavigation);
    const [useFullExperience, setUseFullExperience] = useState(defaultResponsiveContext.useFullExperience);

    //debounce strategy is used to wait a bit before adjusting to avoid unnecessary re-renders
    useDebouncedEffect(
        () => {
            logInfo(INFO_LOGGER, 'RESPONSIVE ====== Desktop/Not Mobile Browser?: ', isDesktop);
            logInfo(INFO_LOGGER, 'RESPONSIVE ====== New Screen orientaton is portrait?: ', isPortrait);
            logInfo(INFO_LOGGER, 'RESPONSIVE ====== New Debounced Window dimension width is: ', width);
            logInfo(INFO_LOGGER, 'RESPONSIVE ====== New Debounced Window dimension height is: ', height);

            setWindowWidth(width);
            setWindowHeight(height);
            setUseDrawerNavigation(width > 767); // || !isMobileOnly); //bottom tabs are only for mobile
            setUseFullExperience(width > 1223); //basically the right column and text labeled buttons, etc, etc...

            logInfo(INFO_LOGGER, 'RESPONSIVE ====== Portrait?: ', isPortrait);
            logInfo(INFO_LOGGER, 'RESPONSIVE ====== Window width is now set to : ', windowWidth);
            logInfo(INFO_LOGGER, 'RESPONSIVE ====== Window height is now set to : ', windowHeight);
            logInfo(
                INFO_LOGGER,
                'RESPONSIVE ====== useDrawerNavigation is now set to : ',
                useDrawerNavigation ? 'true' : 'false',
            );
            logInfo(
                INFO_LOGGER,
                'RESPONSIVE ====== useFullExperience is now set to : ',
                useFullExperience ? 'true' : 'false',
            );
        },
        500,
        [width, height],
    );

    return (
        <CtxProvider
            value={{
                isPortrait,
                isDesktop,
                windowWidth,
                windowHeight,
                useDrawerNavigation,
                useFullExperience,
            }}
        >
            {children}
        </CtxProvider>
    );
};
export default ResponsiveContextProvider;
