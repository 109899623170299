import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { GetPaymentMethodsResultV1 } from './GetPaymentMethodsResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetPaymentMethodsCommandV1
 */
export interface GetPaymentMethodsCommandV1 extends AbstractResidentAuthenticatedCommand<GetPaymentMethodsResultV1> {
    isReservationYn: boolean;
    applyMoveInPaymentRestrictions: boolean;
}

export function createGetPaymentMethodsCommandV1(): GetPaymentMethodsCommandV1 {
    return {
        isReservationYn: false,
        applyMoveInPaymentRestrictions: false,
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetPaymentMethodsCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
