import { StyleSheet } from 'react-native';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { isDesktopNotMobile } from 'utils/uiUtils';

export const globalStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    innerContainer: {
        minWidth: '100%',
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingVertical: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    keyboardAwareScrollView: {
        marginTop: SmarthubTheme.layout.SECTIONMARGIN,
    },
    onePixelBorder: { borderColor: SmarthubTheme.colors.border, borderWidth: 1, borderStyle: 'solid' },
    bottomButton: {
        marginTop: 'auto',
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    fullScreenButton: { width: '88%' },
    icon: {
        width: SmarthubTheme.layout.ICONWIDTH,
        height: SmarthubTheme.layout.ICONWIDTH,
    },
    flexContainer: {
        flex: 1,
    },
    flexGrowContainer: {
        flex: 1,
        flexGrow: 1,
    },
    flexRow: {
        flexDirection: 'row',
    },
    flexRowCenter: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexRowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    flexColumn: {
        flexDirection: 'column',
    },
    flexColumnCenter: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexColumnStart: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    flexRowStart: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    horizontalPad: {
        paddingHorizontal: SmarthubTheme.layout.INNERPAD,
    },
    innerMarginLeft: {
        marginLeft: SmarthubTheme.layout.INNERPAD,
    },
    nodisplay: { display: 'none' },
    alignSelfFlexEnd: {
        alignSelf: 'flex-end',
    },
    singleSpaceAbove: { marginTop: SmarthubTheme.layout.LINESPACE },
    doubleSpaceAbove: { marginTop: SmarthubTheme.layout.LINESPACE * 2 },
    tripleSpaceAbove: { marginTop: SmarthubTheme.layout.LINESPACE * 3 },
    sectionSpaceAbove: { marginTop: SmarthubTheme.layout.SECTIONMARGIN },
    singleSpaceBelow: { marginBottom: SmarthubTheme.layout.LINESPACE },
    doubleSpaceBelow: { marginBottom: SmarthubTheme.layout.LINESPACE * 2 },
    tripleSpaceBelow: { marginBottom: SmarthubTheme.layout.LINESPACE * 3 },
    sectionSpaceBelow: { marginBottom: SmarthubTheme.layout.SECTIONMARGIN },
    singleSpaceAround: { marginVertical: SmarthubTheme.layout.LINESPACE },
    doubleSpaceAround: { marginVertical: SmarthubTheme.layout.LINESPACE * 2 },
    sectionSpaceAround: { marginVertical: SmarthubTheme.layout.SECTIONMARGIN },
    indicatorWrapper: {
        zIndex: 98,
        position: 'absolute',
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
    },
    indicator: {
        flex: 1,
        zIndex: 99,
        position: 'absolute',
    },
    centeredText: {
        textAlign: 'center',
    },
    hundowidth: { width: SmarthubTheme.layout.FULLWIDTH },
    halfwidth: { width: '50%' },
    hundoheight: { height: SmarthubTheme.layout.FULLHEIGHT },
    fullheightwidthpct: {
        height: isDesktopNotMobile() ? '100vh' : '100%',
        width: isDesktopNotMobile() ? '100vw' : '100%',
    },
    justflex1: { flex: 1 },
    transparentbackground: { backgroundColor: SmarthubTheme.colors.transparent },
    orangeText: {
        color: SmarthubTheme.colors.orange,
    },
    boldText: {
        fontFamily: 'Roboto_700Bold',
    },
    loginOptionButton: {
        height: 35,
        width: 270,
        alignSelf: 'center',
    },
    loginOptionButtonSelected: {
        height: 35,
        width: 300,
        alignSelf: 'center',
    },
    inputDateContainerTemp: {
        position: 'relative',
        zIndex: 9999,
    },
    notificationBadge: {
        position: 'relative',
        marginBottom: 3,
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT,
    },
    smartMoveFormLabel: {
        fontSize: 14,
        marginTop: SmarthubTheme.layout.GRIDINCREMENT * 3,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    smartMoveContentWrapper: {
        paddingVertical: SmarthubTheme.layout.SURROUNDMARGIN,
        alignItems: 'center',
    },
    smartMoveScrollWrapper: {
        justifyContent: 'space-between',
    },
    smartMoveBottomContent: {
        alignItems: 'center',
    },
    smartMoveTitleText: {
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN * 2,
    },
    smartMoveIcon: {
        alignItems: 'center',
    },
    smartMoveParagraphText: {
        paddingBottom: SmarthubTheme.layout.SURROUNDMARGIN * 2,
    },
    smartMoveStandardContent: {
        paddingTop: SmarthubTheme.layout.SURROUNDMARGIN * 4,
    },
});
