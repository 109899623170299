import { SmarthubNotificationTypeV1 } from './SmarthubNotificationTypeV1';
import { CommunicationType } from '../../../../shared/businesslogic/residency/dataobjects/CommunicationType';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.businesslogic.smarthub.versioneddataobjects.notifications.SmarthubNotificationV1
 */
export interface SmarthubNotificationV1 {
    noticeId: number;
    noticeType: SmarthubNotificationTypeV1;
    noticeRefId: number;
    dateCreated: number;
    propertyCd: string;
    bldgCd: string;
    unitCd: string;
    resiId: number | null;
    seenYn: boolean;
    heading: string;
    body: string;
    billingPeriod: number;
    includeInPerTypeLookups: boolean;
    notificationId: number;
    notificationCode: CommunicationType;
    notification: string;
    effectiveFromDate: string;
    effectiveToDate: string;
    updatedDate: number;
}

export function createSmarthubNotificationV1(): SmarthubNotificationV1 {
    return {
        noticeId: 0,
        noticeType: 'MaintenanceRequestCorrespondence',
        noticeRefId: 0,
        dateCreated: -1,
        propertyCd: '',
        bldgCd: '',
        unitCd: '',
        resiId: null,
        seenYn: false,
        heading: '',
        body: '',
        billingPeriod: -1,
        includeInPerTypeLookups: false,
        notificationId: 0,
        notificationCode: 'Notice_To_Vacate_Reminder',
        notification: '',
        effectiveFromDate: '01/01/1900',
        effectiveToDate: '01/01/1900',
        updatedDate: -1,
    };
}

/* START CUSTOM CODE */
export const notificationKey = (notification: SmarthubNotificationV1) => {
    return notification.noticeType + (notification.noticeRefId || notification.notificationId) + notification.noticeId;
};
/* END CUSTOM CODE */
