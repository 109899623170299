import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { observer } from 'mobx-react-lite';
import QuickActionPlusButton from '../QuickActionMenu';
import { useStore } from 'contexts/StoreContextProvider';
import { useTheme } from 'react-native-paper';

import MoreStackNavigator from 'navigation/stacks/MoreStack';
import NoticesStackNavigator from 'navigation/stacks/NoticesStack';
import NotificationsStackNavigator from 'navigation/stacks/NotificationsStack';
import HomeStackNavigator from 'navigation/stacks/HomeStack';
import { NavigatorProps } from 'navigation/stacks/MainStackScreenOptions';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Tooltip from 'components/primitives/Tooltip';
import { STORAGE_KEY_PLUS_BUTTON_HIGHLIGHT_DISMISSED } from 'utils/storage-keys';
import { RegularText } from 'components/primitives/StyledText';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { globalStyles } from 'theme/style/GlobalStyles';
import DrawerIconWithNotifications from 'components/notifications/DrawerIconWithNotifications';
import { testProps } from 'components/ComponentTypes';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { ParamListBase } from '@react-navigation/routers';
import { navigationRoutes } from 'utils/NavigationUtils';
import { openSmartMove } from 'screens/smartmove/SmartMoveApplication';
import OptInOutFeatureHighlightScreen from 'components/appTeasers/OptInOutFeatureHighlightScreen';
import useShowOptInOutFeatureHighlight from 'utils/useShowOptInOutFeatureHighlight';
import { setOptInOutFeatureHighlightToday } from 'utils/appTeasers';

const BottomTab = createBottomTabNavigator(); //https://reactnavigation.org/docs/bottom-tab-navigator

interface TabBarIconProps {
    testID?: string;
    accessibilityLabel?: string;
    focused: boolean;
    color: string;
    size: number;
}

const BottomTabNavigator: React.FC<NavigatorProps> = observer(({ navigation }: NavigatorProps) => {
    const theme = useTheme();
    const bottomTabIconSize = SmarthubTheme.layout.BOTTOMTABICON;
    const insets = useSafeAreaInsets();
    const { userSessionStore } = useStore();
    const { confirmActiveResidency, smarthubUser, sessionActiveResidency, hasSessionActiveResidency } =
        userSessionStore;

    const { showOptInOptOutFeatureHighlight, setShowOptInOptOutFeatureHighlight } = useShowOptInOutFeatureHighlight();

    useEffect(() => {
        if (!smarthubUser.authorizedResidencies || smarthubUser.authorizedResidencies.length === 0) return;
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        logInfo(INFO_LOGGER, 'Authorized Residencies are ', JSON.stringify(smarthubUser.authorizedResidencies));
        logInfo(
            INFO_LOGGER,
            'smarthubUser.authorizedResidencies.length _______________',
            smarthubUser.authorizedResidencies.length,
        );
        confirmActiveResidency(navigation);
    }, [smarthubUser.authorizedResidencies]);

    useEffect(() => {
        if (sessionActiveResidency.statusCd === 'Leased') openSmartMove(false, navigation);
    }, [sessionActiveResidency]);

    const homeTabBarIcon = ({ focused }: TabBarIconProps) => (
        //https://icons.expo.fyi/MaterialIcons/home

        <View>
            <DrawerIconWithNotifications
                iconSize={bottomTabIconSize}
                identifier={'home'}
                notificationTypes={[
                    'MaintenanceRequestUpdate',
                    'MaintenanceRequestCorrespondence',
                    'Statement',
                    'ElectronicContract',
                    'SmartPackage',
                ]}>
                <MaterialIcons
                    name='home'
                    size={bottomTabIconSize}
                    color={focused ? theme.colors.lightblue : theme.colors.lightgrey}
                    {...testProps('home-icon')}
                />
            </DrawerIconWithNotifications>

            {focused && <View style={styles.iconUnderline} />}
        </View>
    );

    const noticesTabBarIcon = ({ focused }: TabBarIconProps) => (
        //https://icons.expo.fyi/MaterialCommunityIcons/clipboard-text
        <View>
            <DrawerIconWithNotifications
                iconSize={bottomTabIconSize}
                notificationTypes={['CommunityNotice']}
                identifier={'notices'}>
                <MaterialCommunityIcons
                    name='clipboard-text'
                    size={bottomTabIconSize}
                    color={focused ? theme.colors.lightblue : theme.colors.lightgrey}
                    {...testProps('notices-icon')}
                />
            </DrawerIconWithNotifications>
            {focused && <View style={styles.iconUnderline} />}
        </View>
    );

    const renderQuickActionTooltipText = () => {
        return (
            <RegularText style={globalStyles.centeredText}>
                <RegularText style={[globalStyles.centeredText, globalStyles.orangeText, globalStyles.boldText]}>
                    The + button{' '}
                </RegularText>
                <RegularText>
                    is the quickest way to do many things in SMART
                    <RegularText style={globalStyles.boldText}>HUB</RegularText>, like make a payment or create a
                    maintenance request. Check it out!
                </RegularText>
            </RegularText>
        );
    };

    const quickActionTabBarIcon: React.FC = () => {
        if (hasSessionActiveResidency) {
            return (
                <View style={styles.plusButtonWrapper} {...testProps('plus-button-wrapper')}>
                    <Tooltip
                        plinthText={renderQuickActionTooltipText}
                        spotlightShape={'circle'}
                        storageKey={STORAGE_KEY_PLUS_BUTTON_HIGHLIGHT_DISMISSED}>
                        <View style={{ width: SmarthubTheme.layout.PLUSBUTTONBOTTOMTAB }} {...testProps('plus-button')}>
                            <QuickActionPlusButton />
                        </View>
                    </Tooltip>
                </View>
            );
        } else {
            return null;
        }
    };

    const notificationsTabBarIcon = ({ focused }: TabBarIconProps) => (
        //https://icons.expo.fyi/MaterialIcons/notifications
        <View>
            <DrawerIconWithNotifications
                iconSize={bottomTabIconSize}
                notificationTypes={'All'}
                identifier={'notifications'}>
                <MaterialIcons
                    name='notifications'
                    size={bottomTabIconSize}
                    color={focused ? theme.colors.lightblue : theme.colors.lightgrey}
                    {...testProps('notifications-icon')}
                />
            </DrawerIconWithNotifications>
            {focused && <View style={styles.iconUnderline} />}
        </View>
    );

    const moreTabBarIcon = ({ focused }: TabBarIconProps) => (
        //https://icons.expo.fyi/MaterialIcons/more-horiz
        <View>
            <MaterialIcons
                name='more-horiz'
                size={bottomTabIconSize}
                color={focused ? theme.colors.lightblue : theme.colors.lightgrey}
                {...testProps('more-icon')}
            />
            {focused && <View style={styles.iconUnderline} />}
        </View>
    );

    const quickActionComponent = () => {
        return null;
    };

    const tabBarListeners = ({ navigation, route }: { route: RouteProp<ParamListBase, any>; navigation: any }) => ({
        tabPress: (e: any) => {
            e.preventDefault();
            navigation.reset({
                routes: [{ name: route.name }],
            });
        },
    });

    if (sessionActiveResidency.statusCd === 'Leased') {
        return null;
    } else
        return (
            <>
                {showOptInOptOutFeatureHighlight && (
                    <OptInOutFeatureHighlightScreen
                        closeOptInOutFeatureHighlightScreen={() => {
                            setShowOptInOptOutFeatureHighlight(false);
                            setOptInOutFeatureHighlightToday(sessionActiveResidency.residentId.toString());
                        }}
                        navigateToAlerts={() => {
                            setOptInOutFeatureHighlightToday(sessionActiveResidency.residentId.toString());
                            navigation.navigate(navigationRoutes.moreRoutes.more, {
                                screen: navigationRoutes.moreRoutes.alertsAndNotifications,
                            });
                        }}
                    />
                )}
                <BottomTab.Navigator
                    initialRouteName={navigationRoutes.homeRoutes.home}
                    screenOptions={{
                        unmountOnBlur: true,
                        tabBarHideOnKeyboard: true,
                        tabBarActiveBackgroundColor: theme.colors.background,
                        tabBarInactiveBackgroundColor: theme.colors.background,
                        tabBarShowLabel: false,
                        tabBarStyle: {
                            backgroundColor: theme.colors.background,
                        },
                        headerShown: false,
                    }}
                    backBehavior={'history'}
                    sceneContainerStyle={
                        {
                            //backgroundColor: theme.colors.primary,
                        }
                    }>
                    <BottomTab.Screen
                        name={navigationRoutes.homeRoutes.home}
                        component={HomeStackNavigator}
                        options={{
                            tabBarLabel: navigationRoutes.homeRoutes.home,
                            tabBarIcon: homeTabBarIcon,
                            unmountOnBlur: true,
                        }}
                        listeners={tabBarListeners}
                    />
                    <BottomTab.Screen
                        name={navigationRoutes.noticesRoutes.notices}
                        component={NoticesStackNavigator}
                        options={{
                            tabBarLabel: navigationRoutes.noticesRoutes.notices,
                            tabBarIcon: noticesTabBarIcon,
                            unmountOnBlur: true,
                        }}
                        listeners={tabBarListeners}
                    />
                    <BottomTab.Screen
                        name='QuickAction'
                        component={quickActionComponent}
                        options={{
                            tabBarButton: quickActionTabBarIcon,
                            unmountOnBlur: true,
                        }}
                        listeners={tabBarListeners}
                    />
                    <BottomTab.Screen
                        name={navigationRoutes.notificationRoutes.notifications}
                        component={NotificationsStackNavigator}
                        options={{
                            tabBarLabel: navigationRoutes.notificationRoutes.notifications,
                            tabBarIcon: notificationsTabBarIcon,
                            unmountOnBlur: true,
                        }}
                        listeners={tabBarListeners}
                    />
                    <BottomTab.Screen
                        name={navigationRoutes.moreRoutes.more}
                        component={MoreStackNavigator}
                        options={{
                            tabBarLabel: navigationRoutes.moreRoutes.more,
                            tabBarIcon: moreTabBarIcon,
                            unmountOnBlur: true,
                        }}
                        listeners={tabBarListeners}
                    />
                </BottomTab.Navigator>
            </>
        );
});

export default BottomTabNavigator;

const styles = StyleSheet.create({
    plusButtonWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconUnderline: {
        position: 'absolute',
        alignSelf: 'center',
        bottom: -3,
        width: 20,
        height: 2,
        backgroundColor: SmarthubTheme.colors.lightblue,
    },
});
