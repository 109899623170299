import * as React from 'react';
import { useEffect } from 'react';
import { Divider, useTheme } from 'react-native-paper';
import { StackNavigationProp } from '@react-navigation/stack';
import { DeephansizedRegularText, RegularText } from 'components/primitives/StyledText';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { observer } from 'mobx-react-lite';
import { useStore } from 'contexts/StoreContextProvider';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { SafeAreaView } from 'react-native-safe-area-context';
import { spaceWordsOnCapitals } from 'utils/StringUtils';
import SmarthubGrid from 'components/lists/SmarthubGrid';
import InputCheckbox from 'components/input/InputCheckbox';
import { ListItemProps } from 'components/lists/SmarthubListItem';
import { StyleSheet, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Menu } from 'react-native-popup-menu';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import ActionMenu from 'components/primitives/ActionMenu';
import Button from 'components/primitives/Button';
import { SmarthubRequestListItemV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/requests/SmarthubRequestListItemV1';
import { RequestStore } from 'stores/domain/RequestStore';
import { formatDateStringMonthLabelFromNumber } from 'utils/DateUtils';
import { getRequestTitle, getTagTypeFromStatus } from 'screens/home/requests/requestUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { SmarthubTypography } from 'theme/typography/SmarthubTypography';
import { STORAGE_KEY_REQUEST_PLUS_BUTTON_HIGHLIGHT_DISMISSED } from 'utils/storage-keys';
import Tooltip from 'components/primitives/Tooltip';
import { testProps } from 'components/ComponentTypes';
import { navigationRoutes } from 'utils/NavigationUtils';
import {
    generateInfiniteQueryKeyFromCmd,
    useAlterInfiniteQueryDataForFlatList,
    useCommandInfiniteQuery,
} from 'queries/hooks/useCommandQuery';
import { GetMyRequestCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetMyRequestCommandV1';
import { useQueryClient } from 'react-query';
import { GetMyRequestResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/requests/GetMyRequestResultV1';
import { generateRequestsCMD, requestsRowLimit } from 'screens/home/requests/RequestsHooks';

type ScreenProps = {
    navigation: StackNavigationProp<any>;
};

const Requests: React.FC<ScreenProps> = observer(({ navigation }: ScreenProps) => {
    const theme = useTheme();
    const { userSessionStore, requestStore, uiStore } = useStore();
    const { hasSessionActiveResidency, confirmActiveResidency, sessionActiveResidency } = userSessionStore;
    const { useDrawerNavigation } = useResponsiveContext();
    const menu = React.createRef<Menu>();
    const onClickCreateRequestButton = () => {
        if (menu.current) {
            menu.current.isOpen() ? menu.current.close() : menu.current.open();
        }
    };

    const rowLimit = requestsRowLimit;
    const cmd = generateRequestsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        requestStore.gridScreenIncludeCompleted,
        rowLimit,
    );

    const queryKey = generateInfiniteQueryKeyFromCmd<GetMyRequestCommandV1, GetMyRequestResultV1>(
        cmd,
        requestStore.gridScreenIncludeCompleted,
    );
    const queryClient = useQueryClient();

    // There's a weird interaction with interfaces where passing them as a reference doesn't work but manually passing them does
    const query = useCommandInfiniteQuery<
        GetMyRequestCommandV1,
        { requests: Array<SmarthubRequestListItemV1> },
        SmarthubRequestListItemV1
    >(cmd, uiStore, rowLimit, 'requests', queryKey, {
        enabled: hasSessionActiveResidency,
        onSuccess: result => {
            logInfo(INFO_LOGGER, 'Obtained request list from server: ', JSON.stringify(result.pages[0].data));
        },
    });

    useAlterInfiniteQueryDataForFlatList(queryKey);

    useEffect(() => {
        query.isLoading ? uiStore.showActivityLoader() : uiStore.hideActivityLoader();
    }, [query.isLoading]);

    useEffect(() => {
        if (hasSessionActiveResidency) {
            if (
                sessionActiveResidency.statusCd !== 'Cancelled' &&
                sessionActiveResidency.statusCd !== 'Previous' &&
                useDrawerNavigation
            ) {
                navigation.setOptions({
                    // eslint-disable-next-line react/display-name
                    headerRight: () => <PlusIcon />,
                });
            }
        }
    }, [requestStore.gridScreenIncludeCompleted]);

    const PlusIcon = observer(() => {
        const renderPlugIconTooltipText = () => {
            return (
                <RegularText style={globalStyles.centeredText}>
                    <RegularText style={[globalStyles.centeredText]}>
                        Click this button to{' '}
                        <RegularText style={[globalStyles.orangeText, globalStyles.boldText]}>
                            create or add
                        </RegularText>{' '}
                        a new ticket or request. Try it out!
                    </RegularText>
                </RegularText>
            );
        };

        return (
            <ActionMenu
                ref={menu}
                style={styles.plusIcon}
                menuTrigger={
                    useDrawerNavigation ? (
                        <View style={styles.createRequestButton}>
                            <Tooltip
                                plinthText={renderPlugIconTooltipText}
                                plinthAlignment={'left'}
                                plinthWidth={300}
                                storageKey={STORAGE_KEY_REQUEST_PLUS_BUTTON_HIGHLIGHT_DISMISSED}
                            >
                                <Button onClick={onClickCreateRequestButton} {...testProps('create-request-button')}>
                                    Create a Request or Ticket
                                </Button>
                            </Tooltip>
                        </View>
                    ) : (
                        <AntDesign
                            name='pluscircle'
                            size={SmarthubTheme.layout.ICONWIDTH}
                            color={SmarthubTheme.colors.white}
                        />
                    )
                }
                menuItem={[
                    {
                        id: '3',
                        icon: 'build',
                        iconLibrary: 'material',
                        title: 'Create a Maintenance Request',
                        onSelect: () => {
                            useDrawerNavigation
                                ? navigation.navigate(navigationRoutes.homeRoutes.createARequest)
                                : navigation.navigate(navigationRoutes.loggedInRoutes.createARequestModal);
                        },
                    },
                    {
                        id: '4',
                        icon: 'chat',
                        iconLibrary: 'material',
                        title: 'Send Us a Message',
                        onSelect: () => {
                            useDrawerNavigation
                                ? navigation.navigate(navigationRoutes.homeRoutes.createATicket)
                                : navigation.navigate(navigationRoutes.loggedInRoutes.createATicketModal);
                        },
                    },
                ]}
            />
        );
    });

    useEffect(() => {
        logInfo(INFO_LOGGER, 'Authorized Residencies updated, make sure one is active ...');
        confirmActiveResidency(navigation);
    }, [sessionActiveResidency.key]);

    if (!hasSessionActiveResidency) {
        return (
            <SafeAreaView style={[globalStyles.container, { backgroundColor: theme.colors.background }]}>
                <RegularText>No lease selected</RegularText>
            </SafeAreaView>
        );
    }

    if (query.isLoading || query.isIdle || query.isError || !query.data) return null;
    return (
        <>
            <SmarthubGrid
                data={query.data!.pages[0].data.map((listItem: any): ListItemProps => {
                    return getMenuOptionFromRequestListItem(listItem, requestStore, navigation);
                })}
                keyExtractor={item => item.title}
                listEmptyComponent={
                    uiStore.activityLoaderVisible ? null : (
                        <View style={[styles.listEmptyContainer, { backgroundColor: theme.colors.background }]}>
                            <View style={styles.centeredColumn}>
                                <DeephansizedRegularText style={styles.centeredText}>
                                    {"You don't have any requests"}
                                </DeephansizedRegularText>
                                {sessionActiveResidency.statusCd !== 'Cancelled' &&
                                    sessionActiveResidency.statusCd !== 'Previous' && (
                                        <DeephansizedRegularText
                                            style={[globalStyles.doubleSpaceAbove, styles.centeredText]}
                                        >
                                            {`Add one by ${
                                                useDrawerNavigation ? 'clicking the button' : 'tapping the +'
                                            } in the top-right corner`}
                                        </DeephansizedRegularText>
                                    )}
                            </View>
                        </View>
                    )
                }
                listHeaderComponent={
                    <>
                        <View style={[globalStyles.sectionSpaceAbove]}>
                            <InputCheckbox
                                label={'Include my completed requests'}
                                style={'small'}
                                initialStatus={requestStore.gridScreenIncludeCompleted ? 'checked' : 'unchecked'}
                                onChange={requestStore.toggleGridScreenIncludeCompleted}
                            />
                            <Divider style={globalStyles.doubleSpaceAbove} />
                        </View>
                    </>
                }
                onRefresh={() => {
                    logInfo(INFO_LOGGER, 'Refreshing requests from server');
                    queryClient.invalidateQueries([queryKey]);
                }}
                onEndReachedThreshold={0.5}
                onEndReached={() => {
                    logInfo(INFO_LOGGER, 'On end reached - Fetching requests from server');
                    return query.hasNextPage ? query.fetchNextPage() : null;
                }}
            />
        </>
    );
});

function getMenuOptionFromRequestListItem(
    listItem: SmarthubRequestListItemV1,
    requestStore: RequestStore,
    navigation: NavigationProp<ParamListBase>,
): ListItemProps {
    return {
        id: listItem.requestId.toString(),
        title: getRequestTitle(listItem.type, listItem.requestId),
        subtitle: `Created ${formatDateStringMonthLabelFromNumber(listItem.createdDt)}`,
        desc: listItem.desc,
        descStyle: SmarthubTypography.deephansizedRegular,
        descNumberOfLines: 4,
        descExpandable: false,
        status: spaceWordsOnCapitals(listItem.status),
        statusTagType: getTagTypeFromStatus(listItem.status),
        notificationTypes: ['MaintenanceRequestCorrespondence', 'MaintenanceRequestUpdate'],
        notificationRefId: listItem.requestId,
        testID: 'request',
        accessibilityLabel: 'request',
        doAction: () => {
            navigation.navigate(navigationRoutes.homeRoutes.requestDetail, {
                title: getRequestTitle(listItem.type, listItem.requestId),
                requestId: listItem.requestId,
                requestType: listItem.type,
            });
        },
    };
}

export default Requests;

const styles = StyleSheet.create({
    createRequestButton: {
        marginTop: -100,
    },
    plusIcon: {
        marginTop: 'auto',
        // paddingBottom: SmarthubTheme.layout.GRIDINCREMENT,
        paddingRight: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingLeft: SmarthubTheme.layout.SURROUNDMARGIN,
    },
    centeredColumn: { flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
    centeredText: {
        textAlign: 'center',
    },
    listEmptyContainer: {
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
        justifyContent: 'center',
    },
});
