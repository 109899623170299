import { UIStore } from 'stores/domain/UIStore';
import { MutationOptions, useMutation } from 'react-query';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { SmarthubResidencyV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';
import { RemoveZegoPaymentMethodResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/RemoveZegoPaymentMethodResultV1';
import { createRemoveZegoPaymentMethodCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/RemoveZegoPaymentMethodCommandV1';

export const useRemoveZegPaymentMethodMutation = (
    uiStore: UIStore,
    sessionActiveResidency: SmarthubResidencyV1,
    mutationOptions?: MutationOptions<RemoveZegoPaymentMethodResultV1, any, number>,
) => {
    return useMutation<RemoveZegoPaymentMethodResultV1, any, number>(async gatewayPayerId => {
        const removeCmd = createRemoveZegoPaymentMethodCommandV1();
        removeCmd.propertyCd = sessionActiveResidency.propertyCd;
        removeCmd.residentId = sessionActiveResidency.residentId;
        removeCmd.gatewayPayerId = gatewayPayerId;

        return dispatchCommandAsync(uiStore, removeCmd, false);
    }, mutationOptions);
};
