import { UIStore } from 'stores/domain/UIStore';
import { MutationOptions, useMutation } from 'react-query';
import { useStore } from 'contexts/StoreContextProvider';
import { dispatchCommandAsync } from 'services/remotecmd/RemoteCmdMgr';
import { createZegoCreditCardPayerId, ZegoPaymentInfo } from 'utils/PayLeaseZegoUtils';
import { createGetZegoGatewayTokenCommandV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetZegoGatewayTokenCommandV1';
import { GetZegoGatewayTokenResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetZegoGatewayTokenResultV1';
import { SmarthubResidencyV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidencyV1';
import {
    createSubmitZegoPaymentResultV1,
    SubmitZegoPaymentResultV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitZegoPaymentResultV1';
import { createSubmitZegoPaymentCommandV2 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitZegoPaymentCommandV2';
import {
    createZegoGatewayCreditCardPaymentMethodDO,
    ZegoGatewayPaymentMethodDo,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/integration/paylease/dataobjects/ZegoGatewayPaymentMethodDo';

export const useZegoPaymentMutation = (
    uiStore: UIStore,
    sessionActiveResidency: SmarthubResidencyV1,
    mutationOptions?: MutationOptions<SubmitZegoPaymentResultV1, any, ZegoPaymentInfo>,
) => {
    const { transactionsStore } = useStore();
    return useMutation<SubmitZegoPaymentResultV1, any, ZegoPaymentInfo>(async paymentInfo => {
        if (!paymentInfo.amount) {
            return new Promise((resolve, reject) => reject('Payment amount is not specified.'));
        }

        let paymentMethod: ZegoGatewayPaymentMethodDo | null = paymentInfo.paymentMethod;

        if (paymentInfo.payByMethod === 'newCard') {
            if (paymentInfo.creditCardDetails) {
                //get token
                const tokenCmd = createGetZegoGatewayTokenCommandV1();
                tokenCmd.propertyCd = sessionActiveResidency.propertyCd;

                const tokenResult: GetZegoGatewayTokenResultV1 = await dispatchCommandAsync(uiStore, tokenCmd, false);

                //get gateway payer ID
                try {
                    const gatewayPayerId = await createZegoCreditCardPayerId(
                        tokenResult.token,
                        paymentInfo.creditCardDetails,
                    );

                    if (gatewayPayerId) {
                        paymentMethod = createZegoGatewayCreditCardPaymentMethodDO(
                            transactionsStore.uniquePaymentReferenceId,
                            sessionActiveResidency.payleaseAccount,
                            Number(gatewayPayerId),
                            paymentInfo.creditCardDetails.CreditCardNumber.substr(-4),
                            paymentInfo.paymentMethod.bankOrCardName,
                        );
                    } else {
                        return new Promise((resolve, reject) =>
                            reject('Failed to generate ZEGO gateway payer ID for credit card.'),
                        );
                    }
                } catch (e) {
                    return new Promise((resolve, reject) => reject(e));
                }
            } else {
                return new Promise((resolve, reject) => reject('Pay by new card but card detail is empty.'));
            }
        }

        if (paymentMethod) {
            //post payment
            const payerIdCmd = createSubmitZegoPaymentCommandV2();
            payerIdCmd.propertyCd = sessionActiveResidency.propertyCd;
            payerIdCmd.residentId = sessionActiveResidency.residentId;
            payerIdCmd.amount = paymentInfo.amount;
            payerIdCmd.incurFee = paymentInfo.incurFee;

            payerIdCmd.paymentMethod = paymentMethod;

            const result: SubmitZegoPaymentResultV1 = await dispatchCommandAsync(uiStore, payerIdCmd, false);
            return result;
        } else {
            return new Promise((resolve, reject) => reject('Payment method is not selected.'));
        }
    }, mutationOptions);
};
