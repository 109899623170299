import React, { useEffect, useState } from 'react';
import { SectionList, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'react-native-paper';
import { Heading1, RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import { logError } from 'utils/logging/Logger';
import Hyperlink from 'components/primitives/Hyperlink';
import Container from 'components/primitives/Container';
import { StackNavigationProp } from '@react-navigation/stack';
import { formatPhoneNo } from 'utils/StringUtils';
import { globalStyles } from 'theme/style/GlobalStyles';
import { navigationRoutes } from 'utils/NavigationUtils';
import { QueryKey, useQueryClient } from 'react-query';
import { QueryKeys } from 'queries/QueryKeys';
import { useCommandMutation, useCommandQuery } from 'queries/hooks/useCommandQuery';
import {
    createGetResidentProfileCommandV1,
    GetResidentProfileCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentProfileCommandV1';
import { GetResidentProfileResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentProfileResultV1';
import OptionSwitchComponent from 'screens/more/appsettings/OptionSwitchComponent';
import {
    createGetResidentCommSubscriptionsCommandV1,
    GetResidentCommSubscriptionsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentCommSubscriptionsCommandV1';
import { GetResidentCommSubscriptionsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetResidentCommSubscriptionsResultV1';
import {
    createUpdateResidentCommSubscriptionCommandV1,
    UpdateResidentCommSubscriptionCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/UpdateResidentCommSubscriptionCommandV1';
import { SmarthubResidentCommSubscriptionOpt } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentCommSubscriptionOpt';
import {
    createSmarthubResidentCommSubscriptionV1,
    SmarthubResidentCommSubscriptionV1,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentCommSubscriptionV1';
import {
    SmarthubResidentCommSubscriptionType,
    SmarthubResidentCommSubscriptionTypeOptions,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentCommSubscriptionType';
import { SmarthubResidentV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubResidentV1';
import Section from 'components/primitives/Section';
import {
    createToggleDisableAllResidentCommSubscriptionsCommandV1,
    ToggleDisableAllResidentCommSubscriptionsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/ToggleDisableAllResidentCommSubscriptionsCommandV1';
import {
    createTogglePaperlessStatementCommandV1,
    TogglePaperlessStatementCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/TogglePaperlessStatementCommandV1';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { setOptInOutFeatureHighlight } from 'utils/appTeasers';
import { getRouteParam } from 'utils/routeUtils';
import { useRoute } from '@react-navigation/native';
import { testProps, updateStringForIDName } from 'components/ComponentTypes';
import CollapsibleContent from 'components/primitives/CollapsibleContent';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

type AlertsAndNotificationsProps = {
    navigation: StackNavigationProp<any>;
};

const AlertsAndNotificationsOpt: React.FC<AlertsAndNotificationsProps> = observer(
    ({ navigation }: AlertsAndNotificationsProps) => {
        const { hasSessionActiveResidency, sessionActiveResidency } = useStore().userSessionStore;
        const theme = useTheme();
        const route = useRoute();
        const encryptedAutoRuleActionInvocationId: string = getRouteParam(route, 'encryptedARAIId') || '';
        const { userSessionStore, uiStore } = useStore();
        const [changesMade, setChangesMade] = useState(false);

        const queryClient = useQueryClient();

        // Set resident info query key
        const residentProfileQueryKey: QueryKey = [
            QueryKeys.ResidentProfile,
            userSessionStore.sessionActiveResidency.residentId,
        ];

        const residentProfileCmd = createGetResidentProfileCommandV1();
        residentProfileCmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
        residentProfileCmd.residentId = userSessionStore.sessionActiveResidency.residentId;

        useEffect(() => {
            if (hasSessionActiveResidency) {
                setOptInOutFeatureHighlight(sessionActiveResidency.residentId.toString());
            }
        }, [hasSessionActiveResidency]);

        const residentProfileQuery = useCommandQuery<GetResidentProfileCommandV1, GetResidentProfileResultV1>(
            residentProfileCmd,
            uiStore,
            residentProfileQueryKey,
            {
                enabled: hasSessionActiveResidency,
            },
        );

        // Set notifications query key
        const commSubscriptionsQueryKey: QueryKey = [
            QueryKeys.CommSubscriptions,
            userSessionStore.sessionActiveResidency.propertyCd,
            userSessionStore.sessionActiveResidency.residentId,
        ];

        const cmd = createGetResidentCommSubscriptionsCommandV1();
        cmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
        cmd.residencyId = userSessionStore.sessionActiveResidency.residencyId;
        cmd.residentId = userSessionStore.sessionActiveResidency.residentId;

        const optimisticUpdateGetResult = (result: GetResidentCommSubscriptionsResultV1) => {
            queryClient.setQueryData(commSubscriptionsQueryKey, () => result);
        };

        const optimisticUpdateGetResultForSingleType = (
            result: GetResidentCommSubscriptionsResultV1,
            commCategoryCd: string,
            optType: SmarthubResidentCommSubscriptionType,
        ) => {
            queryClient.setQueryData<GetResidentCommSubscriptionsResultV1 | undefined>(
                commSubscriptionsQueryKey,
                old => {
                    if (old) {
                        old.subscriptions.forEach(sub => {
                            if (sub.commCategoryCd === commCategoryCd) {
                                sub.subscriptionOpts.forEach(opt => {
                                    if (opt.type === optType) {
                                        opt.optOutYn = result.subscriptions
                                            .find(rSub => rSub.commCategoryCd === commCategoryCd)!
                                            .subscriptionOpts.find(rOpt => rOpt.type === optType)!.optOutYn;
                                    }
                                });
                            }
                        });

                        return old;
                    } else {
                        return result;
                    }
                },
            );
        };

        const optimisticUpdatePaperless = (result: GetResidentCommSubscriptionsResultV1) => {
            queryClient.setQueryData<GetResidentCommSubscriptionsResultV1 | undefined>(
                commSubscriptionsQueryKey,
                old => {
                    if (old) {
                        old.paperLessYn = result.paperLessYn;
                        return old;
                    } else {
                        return result;
                    }
                },
            );
        };

        const commSubscriptionsQuery = useCommandQuery<
            GetResidentCommSubscriptionsCommandV1,
            GetResidentCommSubscriptionsResultV1
        >(cmd, uiStore, commSubscriptionsQueryKey, {
            enabled: !!userSessionStore.sessionActiveResidency.residentId,
        });

        const updateCommSubscriptionMutation = useCommandMutation<
            UpdateResidentCommSubscriptionCommandV1,
            GetResidentCommSubscriptionsResultV1
        >(uiStore);

        const toggleDisableAllCommSubscriptionMutation = useCommandMutation<
            ToggleDisableAllResidentCommSubscriptionsCommandV1,
            GetResidentCommSubscriptionsResultV1
        >(uiStore, {
            onSuccess: optimisticUpdateGetResult,
        });

        const togglePaperlessMutation = useCommandMutation<
            TogglePaperlessStatementCommandV1,
            GetResidentCommSubscriptionsResultV1
        >(uiStore, {
            onSuccess: optimisticUpdatePaperless,
        });

        const _updateCommSubscriptionOnServer = async (
            subscription: SmarthubResidentCommSubscriptionV1,
            commCategoryCd: string,
            optType: SmarthubResidentCommSubscriptionType,
        ): Promise<void> => {
            try {
                const cmd = createUpdateResidentCommSubscriptionCommandV1();

                cmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
                cmd.residencyId = userSessionStore.sessionActiveResidency.residencyId;
                cmd.residentId = userSessionStore.sessionActiveResidency.residentId;
                cmd.subscription = subscription;
                cmd.autoRuleActionInvocationIdFromEmail = subscriptionOptsOutOfEmail(subscription)
                    ? encryptedAutoRuleActionInvocationId
                    : '';

                updateCommSubscriptionMutation.mutate(cmd, {
                    onSuccess: result => optimisticUpdateGetResultForSingleType(result, commCategoryCd, optType),
                });
            } catch (e) {
                logError(ERROR_LOGGER, 'Error updating communication subscription:   ', JSON.stringify(e));
            }
        };

        const subscriptionOptsOutOfEmail = (subscription: SmarthubResidentCommSubscriptionV1): boolean => {
            return (
                subscription.subscriptionOpts.filter((subscriptionOpts: SmarthubResidentCommSubscriptionOpt) => {
                    return subscriptionOpts.optOutYn && subscriptionOpts.type === 'Email';
                }).length > 0
            );
        };

        const _toggleDisableAllSubscription = async (toggleOnYn: boolean): Promise<void> => {
            try {
                const cmd = createToggleDisableAllResidentCommSubscriptionsCommandV1();

                cmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
                cmd.residencyId = userSessionStore.sessionActiveResidency.residencyId;
                cmd.residentId = userSessionStore.sessionActiveResidency.residentId;
                cmd.toggleOnYn = toggleOnYn;

                toggleDisableAllCommSubscriptionMutation.mutate(cmd);
            } catch (e) {
                logError(ERROR_LOGGER, 'Error toggling all communication subscription:   ', JSON.stringify(e));
            }
        };

        const _togglePaperless = async (toggleOnYn: boolean): Promise<void> => {
            try {
                const cmd = createTogglePaperlessStatementCommandV1();

                cmd.propertyCd = userSessionStore.sessionActiveResidency.propertyCd;
                cmd.residencyId = userSessionStore.sessionActiveResidency.residencyId;
                cmd.residentId = userSessionStore.sessionActiveResidency.residentId;
                cmd.toggleOnYn = toggleOnYn;

                togglePaperlessMutation.mutate(cmd);
            } catch (e) {
                logError(ERROR_LOGGER, 'Error toggling paperless:   ', JSON.stringify(e));
            }
        };

        const CommunicationMethod = ({ resident }: { resident: SmarthubResidentV1 }) => {
            const { email, phone1No, phone2No } = resident;
            if (email || phone1No || phone2No) {
                return (
                    <View style={globalStyles.sectionSpaceBelow}>
                        <Container borderStyle={'orange'} dropShadow={true}>
                            <View style={[styles.contactinfowarning, { backgroundColor: theme.colors.background }]}>
                                <View style={[styles.contactinfotitle, globalStyles.singleSpaceBelow]}>
                                    <View style={styles.infotextcont}>
                                        <RegularBoldText>{'You will receive these at the following:'}</RegularBoldText>
                                    </View>
                                    <View style={styles.updatelink}>
                                        <Hyperlink
                                            testID={'update-link'}
                                            accessibilityLabel={'update-link'}
                                            onClick={() => navigation.navigate(navigationRoutes.moreRoutes.profile)}>
                                            Update
                                        </Hyperlink>
                                    </View>
                                </View>

                                <View>
                                    {!!email && <RegularText>{email}</RegularText>}
                                    {!!phone1No && <RegularText>{formatPhoneNo(phone1No)}</RegularText>}
                                    {!!phone2No && <RegularText>{formatPhoneNo(phone2No)}</RegularText>}
                                </View>
                            </View>
                        </Container>
                    </View>
                );
            } else {
                return null;
            }
        };

        const _renderHeader = () => (
            <>
                {residentProfileQuery.data?.resident && (
                    <CommunicationMethod resident={residentProfileQuery.data?.resident} />
                )}

                {commSubscriptionsQuery.data?.allCellPhonesHardUnsubscribedYn && (
                    <Container borderStyle={'orange'}>
                        <View style={[globalStyles.flexColumnCenter, globalStyles.innerContainer]}>
                            <MaterialCommunityIcons
                                name='alert-circle'
                                size={SmarthubTheme.layout.LARGEICONSIZE}
                                color={SmarthubTheme.colors.orange}
                                style={globalStyles.doubleSpaceBelow}
                            />
                            <RegularText {...testProps('text-disabled')}>
                                {`You have disabled receiving text message notifications. To start receiving text messages again, text 'START' or 'UNSTOP' TO ${commSubscriptionsQuery.data?.resubscribePhoneNumber}`}
                            </RegularText>
                        </View>
                    </Container>
                )}

                <Section>
                    <OptionSwitchComponent
                        type={'switch'}
                        label={'Go paperless for statements'}
                        description={
                            'If all of our residents go paperless, it would save 10 tons of wood, 58,764 gallons of water, and eliminate 5,048 pounds of solid waste per year!'
                        }
                        switchOn={commSubscriptionsQuery.data?.paperLessYn || false}
                        toggleOffTextColor={SmarthubTheme.colors.orange}
                        testID={'go-paperless'}
                        accessibilityLabel={'go-paperless'}
                        onChangesMade={switchOn => {
                            _togglePaperless(switchOn);
                        }}
                    />
                </Section>

                <Section>
                    <OptionSwitchComponent
                        type={'switch'}
                        label={'Disable all communications'}
                        switchOn={commSubscriptionsQuery.data?.smarthubAllCommunicationsDisabledYn || false}
                        testID={'disable-all-communications'}
                        accessibilityLabel={'disable-all-communications'}
                        onChangesMade={switchOn => {
                            _toggleDisableAllSubscription(switchOn);
                        }}
                    />
                </Section>
            </>
        );

        React.useEffect(() => {
            const unSubs = [
                navigation.addListener('beforeRemove', () => {
                    if (changesMade) uiStore.addToast({ message: 'Changes saved.' });
                }),
            ];

            return function cleanup() {
                unSubs.forEach(unSub => {
                    unSub();
                });
            };
        }, [changesMade]);

        if (!residentProfileQuery.data || !commSubscriptionsQuery.data) {
            return null;
        } else {
            // const listData: Array<{ title1: string; description1: string; data: Array<AlertOption> }> = [];
            const listData: Array<{
                commCategoryCd: string;
                description: string;
                smarthubDescription: string;
                data: Array<SmarthubResidentCommSubscriptionOpt>;
            }> = [];
            listData.length = 0;
            commSubscriptionsQuery.data.subscriptions.map(commSubscription => {
                listData.push({
                    commCategoryCd: commSubscription.commCategoryCd,
                    description: commSubscription.description,
                    smarthubDescription: commSubscription.smarthubDescription,
                    data: commSubscription.subscriptionOpts,
                });
            });

            return (
                <KeyboardAwareScrollView style={[styles.container, { backgroundColor: theme.colors.background }]}>
                    {_renderHeader()}
                    {commSubscriptionsQuery.data.subscriptions.map((commSubscription, index) => (
                        <View style={styles.itemwrapper} key={index}>
                            <CollapsibleContent
                                header={
                                    <Section style={globalStyles.sectionSpaceBelow}>
                                        <Heading1 style={styles.sectiontitle}>{commSubscription.description}</Heading1>
                                        <RegularText style={globalStyles.doubleSpaceAbove}>
                                            {commSubscription.smarthubDescription}
                                        </RegularText>
                                    </Section>
                                }
                                expanded={true}>
                                <View>
                                    {commSubscription.subscriptionOpts.map((item, optIndex) => (
                                        <OptionSwitchComponent
                                            key={`option-${index}-${optIndex}`}
                                            type={'checkbox'}
                                            label={
                                                SmarthubResidentCommSubscriptionTypeOptions.find(
                                                    o => o.value === item.type,
                                                )?.label || ''
                                            }
                                            description={item.alwaysOnYn ? 'Always On' : undefined}
                                            switchOn={
                                                item.type === 'TextMessage' &&
                                                commSubscriptionsQuery.data?.allCellPhonesHardUnsubscribedYn
                                                    ? false
                                                    : !item.optOutYn
                                            }
                                            disabled={
                                                (item.type === 'TextMessage' &&
                                                    commSubscriptionsQuery.data?.allCellPhonesHardUnsubscribedYn) ||
                                                item.alwaysOnYn ||
                                                commSubscriptionsQuery.data?.smarthubAllCommunicationsDisabledYn
                                            }
                                            testID={updateStringForIDName(
                                                commSubscription.commCategoryCd + '-' + item.type,
                                            )}
                                            accessibilityLabel={updateStringForIDName(
                                                commSubscription.commCategoryCd + '-' + item.type,
                                            )}
                                            onChangesMade={switchOn => {
                                                setChangesMade(true);
                                                const sub: SmarthubResidentCommSubscriptionV1 =
                                                    createSmarthubResidentCommSubscriptionV1();
                                                sub.commCategoryCd = commSubscription.commCategoryCd;
                                                sub.description = commSubscription.commCategoryCd;
                                                sub.subscriptionOpts = commSubscription.subscriptionOpts;
                                                sub.subscriptionOpts.forEach(s => {
                                                    if (s.type === item.type) {
                                                        s.optOutYn = !switchOn;
                                                    }
                                                });
                                                _updateCommSubscriptionOnServer(
                                                    sub,
                                                    commSubscription.commCategoryCd,
                                                    item.type,
                                                );
                                            }}
                                        />
                                    ))}
                                </View>
                            </CollapsibleContent>
                        </View>
                    ))}
                </KeyboardAwareScrollView>
            );
        }
    },
);
export default AlertsAndNotificationsOpt;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SmarthubTheme.layout.SURROUNDMARGIN,
        paddingVertical: SmarthubTheme.layout.SURROUNDMARGIN,
        width: '100%',
    },
    sectiontitle: {},
    contactinfowarning: { padding: SmarthubTheme.layout.INNERPAD },
    contactinfotitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    itemwrapper: {
        borderBottomWidth: 1,
        borderBottomColor: SmarthubTheme.colors.border,
        paddingBottom: 10,
    },
    updatelink: {
        width: '20%',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
    },
    infotextcont: {
        width: '80%',
    },
});
