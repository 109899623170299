import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { FormLabelText, Heading1 } from 'components/primitives/StyledText';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from 'components/primitives/Button';
import { useStore } from 'contexts/StoreContextProvider';
import { observer } from 'mobx-react-lite';
import Section from 'components/primitives/Section';
import InputDropdown from 'components/input/InputDropdown';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import InputString from 'components/input/InputString';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { globalStyles } from 'theme/style/GlobalStyles';
import { action, runInAction } from 'mobx';
import InputRadio, { InputRadioOption } from 'components/input/InputRadio';
import InputPhone from 'components/input/InputPhone';
import {
    PhoneNoType,
    PhoneNoTypeOptions,
} from 'models/remotecmds/com/ocs/nirvana/shared/businesslogic/residency/dataobjects/PhoneNoType';
import useBeforeLeave from 'utils/useBeforeLeave';
import { createSmarthubAddressV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAddressV1';
import useBackOnDisplay from 'utils/useBackOnDisplay';
import { getScreenOrModalName, navigationRoutes } from 'utils/NavigationUtils';
import PhoneConfirmDialog from 'components/residency/PhoneConfirmDialog';
import { testProps } from 'components/ComponentTypes';

type SodaRecipientProps = {
    navigation: StackNavigationProp<any>;
};

type SodaRecipientSubmitData = {
    usePropertyAddressYn: boolean;
    street: string | null | undefined;
    city: string | null | undefined;
    stateProvince: string | null | undefined;
    zip: string | null | undefined;
    country: string | null | undefined;
    phone1Type: PhoneNoType;
    phone1Number: string;
    phone2Type: PhoneNoType;
    phone2Number: string;
};
const schema = yup.object().shape({
    usePropertyAddressYn: yup.boolean(),
    street: yup.string().when('usePropertyAddressYn', {
        is: true,
        then: yup.string(),
        otherwise: yup.string().required('Please enter a value').max(52, 'Maximum 52 characters are allowed'),
    }),
    city: yup.string().when('usePropertyAddressYn', {
        is: true,
        then: yup.string(),
        otherwise: yup.string().required('Please enter a value').max(25, 'Maximum 25 characters are allowed'),
    }),
    stateProvince: yup.string().when('usePropertyAddressYn', {
        is: true,
        then: yup.string(),
        otherwise: yup.string().required('Please enter a value').max(3, 'Maximum 3 characters are allowed'),
    }),
    zip: yup.string().when('usePropertyAddressYn', {
        is: true,
        then: yup.string(),
        otherwise: yup.string().required('Please enter a value').max(10, 'Maximum 10 characters are allowed'),
    }),
    country: yup.string().when('usePropertyAddressYn', {
        is: true,
        then: yup.string(),
        otherwise: yup.string().required('Please enter a value').max(10, 'Maximum 10 characters are allowed'),
    }),
    phone1Type: yup.string(),
    phone1Number: yup.lazy(value =>
        !value
            ? yup.string().nullable()
            : yup
                  .string()
                  .min(10, 'Please enter a 10 digit phone number')
                  .max(10, 'Please enter a 10 digit phone number'),
    ),
    phone2Type: yup.string(),
    phone2Number: yup.lazy(value =>
        !value
            ? yup.string().nullable()
            : yup
                  .string()
                  .min(10, 'Please enter a 10 digit phone number')
                  .max(10, 'Please enter a 10 digit phone number'),
    ),
});

const SodaRecipient: React.FC<SodaRecipientProps> = observer(({ navigation }: SodaRecipientProps) => {
    const { useDrawerNavigation } = useResponsiveContext();
    const { userSessionStore, ntvStore, uiStore } = useStore();
    const { smarthubUser, confirmActiveResidency, sessionActiveResidency } = userSessionStore;
    const ntvScreenData = ntvStore.ntvScreenData;
    const [recipientIndx] = useState(ntvStore.ntvCurrentRecipientIndx);
    const [currentResidentId] = useState(ntvStore.ntvRecipientIdAry[recipientIndx]);
    const [recipient, setRecipient] = useState(
        ntvStore.ntvRequestSubmitData.sodaRecipientList.find(sr => sr.residentId === currentResidentId),
    );

    //handle hardware or browser back button
    useEffect(() => {
        if (!sessionActiveResidency.allowNTV) {
            navigation.navigate(navigationRoutes.homeRoutes.home);
        }
    });

    if (recipient) {
        const [lastConfirmedPhone1Number, setLastConfirmedPhone1Number] = useState(recipient?.phone1No);
        const [lastConfirmedPhone2Number, setLastConfirmedPhone2Number] = useState(recipient?.phone2No);
        const [showPrimaryPhoneConfirmDialog, setShowPrimaryPhoneConfirmDialog] = useState(false);
        const [showSecondaryPhoneConfirmDialog, setShowSecondaryPhoneConfirmDialog] = useState(false);

        const isBackOnDisplay = useBackOnDisplay(true, true);

        useBeforeLeave(navigation, () => {
            runInAction(() => (ntvStore.ntvCurrentRecipientIndx = recipientIndx > 0 ? recipientIndx - 1 : 0));
        });

        const getNameOfNextRecipientOrPreferContact = action(() => {
            if (recipientIndx === ntvStore.ntvRecipientIdAry.length - 1) {
                return getScreenOrModalName('NoticeToVacatePreferContact', useDrawerNavigation);
            } else {
                ntvStore.ntvCurrentRecipientIndx = recipientIndx + 1;
                return getScreenOrModalName('SodaRecipient', useDrawerNavigation);
            }
        });

        const navigateToNextRecipientOrPreferContact = action(() => {
            navigation.push(getNameOfNextRecipientOrPreferContact());
        });

        const getRecipientFormikValues = () => {
            return {
                usePropertyAddressYn: recipient.mailToAddrId === ntvScreenData.propertyAddress.addressId,
                street: recipient.mailToAddress?.streetLine1,
                city: recipient.mailToAddress?.city,
                stateProvince: recipient.mailToAddress?.state || ntvScreenData.provinceStateOptions[0].value,
                zip: recipient.mailToAddress?.zip,
                country: recipient.mailToAddress?.country || 'USA',
                phone1Type: recipient.phone1TypeCd,
                phone1Number: recipient.phone1No,
                phone2Type: recipient.phone2TypeCd,
                phone2Number: recipient.phone2No,
            };
        };

        const onSubmit = action((data: SodaRecipientSubmitData) => {
            if (!uiStore.activityLoaderVisible) {
                formik.resetForm({ values: formik.values });

                //update phone data in screen data as we use it in the prefer contact screen, we want the updated data
                const resident = ntvScreenData.applicants.find(a => a.residentId === recipient.residentId)!;

                resident.phone1No = recipient.phone1No = data.phone1Number;
                resident.phone1TypeCd = recipient.phone1TypeCd = data.phone1Type;
                resident.phone2No = recipient.phone2No = data.phone2Number;
                resident.phone2TypeCd = recipient.phone2TypeCd = data.phone2Type;

                if (data.usePropertyAddressYn) {
                    recipient.mailToAddrId = ntvScreenData.propertyAddress.addressId;
                    recipient.mailToAddress = ntvScreenData.propertyAddress;
                    navigateToNextRecipientOrPreferContact();
                } else if (recipient.mailToAddress?.validationStatusDv === 'Validated') {
                    navigateToNextRecipientOrPreferContact();
                } else {
                    const addressToValidate = createSmarthubAddressV1();
                    recipient.mailToAddrId = 0;
                    recipient.mailToAddress = createSmarthubAddressV1();
                    recipient.mailToAddress.streetLine1 = addressToValidate.streetLine1 = data.street!;
                    recipient.mailToAddress.city = addressToValidate.city = data.city!;
                    recipient.mailToAddress.state = addressToValidate.state = data.stateProvince!;
                    recipient.mailToAddress.zip = addressToValidate.zip = data.zip!;
                    recipient.mailToAddress.country = addressToValidate.country = data.country!;
                    recipient.mailToAddress.validationStatusDv = 'Unverified';

                    uiStore.showActivityLoader();

                    ntvStore
                        .validateAddressAsync(addressToValidate)
                        .then(validateAddressResult => {
                            if (validateAddressResult.invalidAddressYn) {
                                uiStore.showAlert({
                                    title: 'Invalid Address',
                                    message:
                                        'Address entered could not be found, please check entered data or click continue to use the entered data',
                                    buttons: [
                                        {
                                            buttonType: 'secondary',
                                            buttonText: 'Continue',
                                            onClick: () => {
                                                recipient.mailToAddress!.validationStatusDv = 'Forced';
                                                recipient.mailToAddress!.validationOverrideUcd = 'Smarthub';
                                                uiStore.hideAlert();
                                                navigateToNextRecipientOrPreferContact();
                                            },
                                        },
                                        {
                                            buttonType: 'primary',
                                            buttonText: 'Fix my data',
                                            onClick: () => {
                                                uiStore.hideAlert();
                                            },
                                        },
                                    ],
                                });
                            } else if (validateAddressResult.hasCorrectionYn) {
                                navigation.navigate(
                                    getScreenOrModalName(
                                        navigationRoutes.homeRoutes.addressPicker,
                                        useDrawerNavigation,
                                    ),
                                    {
                                        title: 'Notice to Vacate',
                                        addressEntered: validateAddressResult.addressEntered,
                                        addressValidated: validateAddressResult.validatedAddress,
                                        residentId: recipient.residentId,
                                        nextScreenName: getNameOfNextRecipientOrPreferContact(),
                                    },
                                );
                            } else {
                                recipient.mailToAddress!.validationStatusDv = 'Validated';
                                recipient.mailToAddress!.validationOverrideUcd = 'Smarthub';
                                navigateToNextRecipientOrPreferContact();
                            }
                        })
                        .finally(() => {
                            uiStore.hideActivityLoader();
                        });
                }
            }
        });

        const formik = useFormik({
            validateOnMount: false,
            validateOnChange: false,
            validateOnBlur: false,
            initialValues: getRecipientFormikValues(),
            validationSchema: schema,
            onSubmit: onSubmit,
        });

        useEffect(() => {
            confirmActiveResidency(navigation);
        }, [smarthubUser.authorizedResidencies]);

        useEffect(() => {
            setRecipient(
                ntvStore.ntvRequestSubmitData.sodaRecipientList.find(sr => sr.residentId === currentResidentId),
            );
            formik.resetForm({
                values: getRecipientFormikValues(),
            });
        }, [isBackOnDisplay]);

        const radioOptions: Array<InputRadioOption> = [
            {
                label: 'Mail it to the address below',
                value: 'newAddress',
                testID: 'new-address-input',
                accessibilityLabel: 'new-address-input',
            },
            {
                label: 'Mail it to the property office',
                value: 'propertyAddress',
                testID: 'property-address-input',
                accessibilityLabel: 'property-address-input',
            },
        ];

        return (
            <SafeAreaView style={globalStyles.container}>
                <KeyboardAwareScrollView
                    keyboardDismissMode={Platform.OS === 'web' ? 'none' : 'on-drag'}
                    enableResetScrollToCoords={false}>
                    <View style={globalStyles.container}>
                        {showPrimaryPhoneConfirmDialog && (
                            <PhoneConfirmDialog
                                isPrimary={true}
                                phoneNumber={formik.values.phone1Number}
                                onConfirm={() => {
                                    setLastConfirmedPhone1Number(formik.values.phone1Number);
                                    setShowPrimaryPhoneConfirmDialog(false);
                                }}
                            />
                        )}

                        {showSecondaryPhoneConfirmDialog && (
                            <PhoneConfirmDialog
                                isPrimary={false}
                                phoneNumber={formik.values.phone2Number}
                                onConfirm={() => {
                                    setLastConfirmedPhone2Number(formik.values.phone2Number);
                                    setShowSecondaryPhoneConfirmDialog(false);
                                }}
                            />
                        )}

                        {!useDrawerNavigation && (
                            <Heading1 {...testProps('header-2')} style={globalStyles.sectionSpaceBelow}>
                                Notice to Vacate
                            </Heading1>
                        )}

                        <FormLabelText {...testProps('message-2')} style={globalStyles.doubleSpaceBelow}>
                            {`Where should we send ${recipient.mailToFirstName}'s copy of the final bill?`}
                        </FormLabelText>

                        <InputRadio
                            options={radioOptions}
                            initialValue={formik.values.usePropertyAddressYn ? 'propertyAddress' : 'newAddress'}
                            onChange={selected => {
                                formik.setFieldValue('usePropertyAddressYn', selected === 'propertyAddress');
                            }}
                            style={'small'}
                        />

                        {!formik.values.usePropertyAddressYn && (
                            <>
                                <Section>
                                    <FormLabelText style={globalStyles.doubleSpaceBelow}>Street</FormLabelText>
                                    <InputString
                                        autoFocus={Platform.OS === 'web'}
                                        onChange={formik.handleChange('street')}
                                        onBlur={formik.handleBlur('street')}
                                        initialValue={formik.values.street}
                                        multiline={false}
                                        maxLength={52}
                                        error={!!formik.errors.street}
                                        errorMessage={formik.errors.street}
                                        testID={'street-input'}
                                    />
                                </Section>

                                <Section>
                                    <FormLabelText style={globalStyles.doubleSpaceBelow}>City</FormLabelText>
                                    <InputString
                                        autoFocus={Platform.OS === 'web'}
                                        onChange={formik.handleChange('city')}
                                        onBlur={formik.handleBlur('city')}
                                        initialValue={formik.values.city}
                                        multiline={false}
                                        maxLength={25}
                                        error={!!formik.errors.city}
                                        errorMessage={formik.errors.city}
                                        testID={'city-input'}
                                    />
                                </Section>

                                <Section>
                                    <FormLabelText style={globalStyles.doubleSpaceBelow}>State</FormLabelText>

                                    <InputDropdown
                                        selectedValue={formik.values.stateProvince}
                                        options={ntvScreenData ? ntvScreenData.provinceStateOptions : []}
                                        onChange={value => {
                                            formik.setFieldValue('stateProvince', value);
                                        }}
                                    />
                                </Section>

                                <Section>
                                    <FormLabelText style={globalStyles.doubleSpaceBelow}>ZIP</FormLabelText>
                                    <InputString
                                        autoFocus={Platform.OS === 'web'}
                                        onChange={formik.handleChange('zip')}
                                        onBlur={formik.handleBlur('zip')}
                                        initialValue={formik.values.zip}
                                        multiline={false}
                                        maxLength={10}
                                        error={!!formik.errors.zip}
                                        errorMessage={formik.errors.zip}
                                        testID={'zip-input'}
                                    />
                                </Section>

                                <Section>
                                    <FormLabelText style={globalStyles.doubleSpaceBelow}>Country</FormLabelText>

                                    <InputDropdown
                                        selectedValue={formik.values.country}
                                        options={ntvScreenData ? ntvScreenData.countries : []}
                                        onChange={value => {
                                            formik.setFieldValue('country', value);
                                        }}
                                    />
                                </Section>
                            </>
                        )}
                        <Section>
                            <FormLabelText style={globalStyles.doubleSpaceBelow}>Phone 1</FormLabelText>
                            <View style={styles.phoneField}>
                                <View style={styles.phoneType}>
                                    <InputDropdown
                                        testID={'Phone1TypeField'}
                                        placeholder={'Select'}
                                        options={PhoneNoTypeOptions}
                                        selectedValue={formik.values.phone1Type}
                                        onChange={value => {
                                            formik.setFieldValue('phone1Type', value);
                                        }}
                                        error={!!formik.errors.phone1Type}
                                        errorMessage={formik.errors.phone1Type}
                                    />
                                </View>

                                <View style={styles.phoneNumber}>
                                    <InputPhone
                                        testID={'Phone1NoField'}
                                        onChange={formik.handleChange('phone1Number')}
                                        onBlur={value => {
                                            if (
                                                formik.values.phone1Number &&
                                                lastConfirmedPhone1Number !== formik.values.phone1Number
                                            ) {
                                                setShowPrimaryPhoneConfirmDialog(true);
                                            }
                                        }}
                                        initialValue={formik.values.phone1Number}
                                        error={!!formik.errors.phone1Number}
                                        errorMessage={formik.errors.phone1Number}
                                    />
                                </View>
                            </View>
                        </Section>

                        <Section>
                            <FormLabelText style={globalStyles.doubleSpaceBelow}>Phone 2</FormLabelText>
                            <View style={styles.phoneField}>
                                <View style={styles.phoneType}>
                                    <InputDropdown
                                        testID={'Phone2TypeField'}
                                        placeholder={'Select'}
                                        options={PhoneNoTypeOptions}
                                        selectedValue={formik.values.phone2Type}
                                        onChange={value => {
                                            formik.setFieldValue('phone2Type', value);
                                        }}
                                        error={!!formik.errors.phone2Type}
                                        errorMessage={formik.errors.phone2Type}
                                    />
                                </View>

                                <View style={styles.phoneNumber}>
                                    <InputPhone
                                        testID={'Phone2NoField'}
                                        onChange={formik.handleChange('phone2Number')}
                                        onBlur={value => {
                                            if (
                                                formik.values.phone2Number &&
                                                lastConfirmedPhone2Number !== formik.values.phone2Number
                                            ) {
                                                setShowSecondaryPhoneConfirmDialog(true);
                                            }
                                        }}
                                        initialValue={formik.values.phone2Number}
                                        error={!!formik.errors.phone2Number}
                                        errorMessage={formik.errors.phone2Number}
                                    />
                                </View>
                            </View>
                        </Section>

                        <Section>
                            <View style={[styles.button]}>
                                <Button
                                    inactive={uiStore.activityLoaderVisible}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    {...testProps('submit-button')}>
                                    Continue
                                </Button>
                            </View>
                        </Section>
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        );
    } else {
        //will be navigated to home screen, don't need to render anything
        return null;
    }
});

export default SodaRecipient;

const styles = StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: '100%',
    },
    phoneField: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },
    phoneType: {
        flex: 2,
        marginRight: 10,
    },
    phoneNumber: {
        flex: 3,
    },
});
