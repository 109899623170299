import { AbstractResidentAuthenticatedCommand } from '../AbstractResidentAuthenticatedCommand';
import { GetResidentProfileResultV1 } from './GetResidentProfileResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetResidentNotificationPreferencesCommandV1
 */
export type GetResidentNotificationPreferencesCommandV1 = AbstractResidentAuthenticatedCommand<GetResidentProfileResultV1>;

export function createGetResidentNotificationPreferencesCommandV1(): GetResidentNotificationPreferencesCommandV1 {
    return {
        propertyCd: '',
        residentId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass:
            'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetResidentNotificationPreferencesCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
