import React, { useEffect, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { useAuthentication } from 'contexts/AuthContextProvider';
import { useRoute } from '@react-navigation/native';
import { ButtonText, RegularBoldText, RegularText } from 'components/primitives/StyledText';
import { useStore } from 'contexts/StoreContextProvider';
import { useMediaQuery } from 'react-responsive';
import { Avatar, Divider } from 'react-native-paper';
import Button from '../components/primitives/Button';
import { observer } from 'mobx-react-lite';
import * as SentryHelper from 'utils/logging/SentryHelper';
import Constants from 'expo-constants';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { removeItem } from 'utils/storage';
import * as storageKeys from 'utils/storage-keys';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { globalStyles } from 'theme/style/GlobalStyles';
import Switch from 'expo-dark-mode-switch';
import { getSmarthubRPCHandler } from 'utils/AppConfigHelper';
import { formatDate } from 'utils/DateUtils';
import * as Notifications from 'expo-notifications';
import InputString from 'components/input/InputString';
import { testProps } from 'components/ComponentTypes';
import * as Application from 'expo-application';

const ErrorComponent: React.FC<{ error?: string }> = (props: { error?: string }) => {
    throw new Error('I am an error');
};

const TestScreen = observer(() => {
    //Access navigator using hooks
    const { useDrawerNavigation, useFullExperience, isPortrait } = useResponsiveContext();

    /*hooks.useBackHandler(() => {
        uiStore.addToast({ message: 'Customized back action' });
        return true;
        // let the default thing happen
        //return false;
    });*/

    const [pushToken, setPushToken] = useState('');

    const { userSessionStore, uiStore, appStore } = useStore();

    //Get the current route using hooks
    const route = useRoute();

    //Get the current user using hooks
    const { user } = useAuthentication();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)',
    });
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)',
    });
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

    const [showErrorComponent, setShowErrorComponent] = useState(false);

    const photoUrl: string | undefined | null = user?.photoURL;

    const _clearStoredKeys = async () => {
        for (const [key, value] of Object.entries(storageKeys)) {
            await removeItem(value);
            logInfo(INFO_LOGGER, `Stored key deleted: ${key}`);
        }

        uiStore.addToast({
            message: 'Stored keys deleted',
        });
    };

    useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                try {
                    let token = '';
                    try {
                        token = (await Notifications.getExpoPushTokenAsync()).data;
                    } catch (e) {
                        // @ts-ignore
                        const projectId = Constants.expoConfig.extra.eas.projectId;
                        token = (await Notifications.getExpoPushTokenAsync({ projectId })).data;
                    }

                    setPushToken(token);
                } catch (e) {
                    //ignore
                }
            }
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    logInfo(INFO_LOGGER, 'MANIFEST :::: ' + JSON.stringify(Constants.expoConfig));

    return (
        <ScrollView scrollEnabled={true} style={globalStyles.container}>
            <View style={[globalStyles.sectionSpaceBelow]}>
                <Switch value={uiStore.useDarkTheme} onChange={value => uiStore.toggleDarkTheme()} />
            </View>
            <Button
                onClick={() => {
                    _clearStoredKeys();
                }}>
                Delete Stored Keys
            </Button>
            <Button
                onClick={() => {
                    appStore.goToAppStore();
                }}>
                Go to App Store
            </Button>
            <Button
                onClick={() => {
                    setShowErrorComponent(true);
                }}>
                Crash UI
            </Button>

            <Button
                onClick={() => {
                    throw new Error('I am a JS error');
                }}>
                Crash JS
            </Button>

            <Button
                onClick={() => {
                    uiStore.showFeedbackDialog({ eventId: 'hello' });
                }}>
                {`Show feedback dialog`}
            </Button>
            <Button
                onClick={() => {
                    SentryHelper.captureMessage('Logging test message from test screen');
                    SentryHelper.captureException(new Error('Logging test exception from test screen'));
                }}>
                <ButtonText>Log a sentry message and an exception</ButtonText>
            </Button>

            <Button
                onClick={() => {
                    uiStore.showAlert({
                        title: 'Updating',
                        message:
                            'Please wait while we download the latest update. The app will relaunch once the update is complete. This may take a couple of minutes.',
                    });
                }}>
                Test Force update prompt
            </Button>
            <Button
                onClick={() => {
                    uiStore.showActivityLoader(
                        'Please wait while we generate your contract. This may take a few minutes. We appreciate your patience.',
                    );
                }}>
                Show Activity Loader with Message
            </Button>

            <Divider style={globalStyles.sectionSpaceAround} />

            <RegularBoldText>System Date:</RegularBoldText>
            <RegularText>{formatDate(userSessionStore.propertySpecificDt)}</RegularText>

            <RegularBoldText>Logged in user:</RegularBoldText>
            {user && <RegularText>{user.displayName}</RegularText>}

            {photoUrl && (
                <Avatar.Image
                    size={48}
                    source={{
                        uri: photoUrl,
                    }}
                />
            )}

            {Platform.OS !== 'web' && !!pushToken && (
                <>
                    <RegularBoldText>Expo push token:</RegularBoldText>
                    <InputString initialValue={pushToken} {...testProps('push-token')} />
                </>
            )}

            <RegularBoldText>Remote host:</RegularBoldText>
            <RegularText>{getSmarthubRPCHandler()}</RegularText>

            {showErrorComponent && <ErrorComponent />}

            <RegularBoldText>Firebase User:</RegularBoldText>
            <RegularText>
                UID: {user?.uid} Email/Phone:
                {(user?.email && user?.email) || user?.phoneNumber}
            </RegularText>

            <RegularBoldText>Matched resident is:</RegularBoldText>
            <RegularText>
                {userSessionStore.sessionActiveResidency?.firstName} {userSessionStore.sessionActiveResidency?.lastName}
            </RegularText>
            <RegularBoldText>sessionActiveResidency is:</RegularBoldText>
            <RegularText>{userSessionStore.activeUnitString}</RegularText>
            <RegularBoldText>Paylease account:</RegularBoldText>
            <RegularText>{userSessionStore.sessionActiveResidency.payleaseAccount}</RegularText>
            <RegularBoldText>All authorized residencies are:</RegularBoldText>
            <RegularText>
                {userSessionStore.smarthubUser.authorizedResidencies.map(
                    r => r.bldgCd + '-' + r.unitCd + ':' + r.lastName,
                )}
            </RegularText>

            <RegularBoldText>Responsive:</RegularBoldText>
            <RegularText>{isPortrait ? 'Portrait' : 'Landscape'} orientation</RegularText>
            <RegularBoldText>Navigation in use:</RegularBoldText>
            <RegularText>{useDrawerNavigation ? 'Drawer (side)' : 'Bottom Tab'}</RegularText>
            {isDesktopOrLaptop && (
                <RegularText>
                    {useFullExperience
                        ? 'You are receiving the full experience (3 column layout)'
                        : 'You are receiving the reduced window size experience (2 column layout)'}
                </RegularText>
            )}

            {isDesktopOrLaptop && <RegularText>You are a desktop or laptop</RegularText>}
            {isTabletOrMobileDevice && <RegularText>You are a tablet or mobile phone</RegularText>}
            {isBigScreen && <RegularText>You also have a huge screen</RegularText>}
            {isTabletOrMobile && <RegularText>You are sized like a tablet or mobile phone though</RegularText>}
            {isRetina && <RegularText>You are retina</RegularText>}

            <RegularText>Native build version is {Application.nativeBuildVersion}</RegularText>
            <RegularText>Platform IOS build number is {Application.nativeBuildVersion}</RegularText>
            <RegularText>Platform Android versionCode is {Application.nativeBuildVersion}</RegularText>

            <RegularText>
                Application.nativeBuildVersion : Native build version is {Application.nativeBuildVersion}
            </RegularText>
            {/* @ts-ignore */}
            <RegularText>HostUrl is {Constants.expoConfig?.hostUri}</RegularText>
            {/* @ts-ignore */}
            <RegularText>debuggerHost is {Constants.expoConfig?.debuggerHost}</RegularText>

            <RegularBoldText>Current route (React Navigation):</RegularBoldText>
            <RegularText>{JSON.stringify(route)}</RegularText>

            <Divider style={globalStyles.sectionSpaceAround} />
        </ScrollView>
    );
});
export default TestScreen;
