import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { observer } from 'mobx-react-lite';
import Toast from 'components/primitives/Toast';
import { useStore } from 'contexts/StoreContextProvider';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SmarthubTheme } from 'theme/SmarthubTheme';

export interface ToastInterface {
    toastType?: 'standard' | 'error';
    message: string;
    autoDismiss?: boolean;
    autoDismissInterval?: number;
}

interface ToasterProps {
    testID?: string;
}

const defaultProps: ToasterProps = {};

const Toaster: React.FC<ToasterProps> = (props: ToasterProps) => {
    const { uiStore } = useStore();

    const renderCurrentToast = () => {
        if (uiStore.currentToast != null) {
            return (
                <Toast
                    key={`toast-${uiStore.currentToast.toastId}`}
                    toastType={uiStore.currentToast.toastType}
                    message={uiStore.currentToast.message}
                    autoDismiss={uiStore.currentToast.autoDismiss}
                    autoDismissInterval={uiStore.currentToast.autoDismissInterval}
                />
            );
        } else {
            return null;
        }
    };

    const GetBottomOffset = (): number => {
        const navigationOffset = 60;
        const bottomInset = useSafeAreaInsets().bottom;
        return navigationOffset + bottomInset;
    };

    const toasterStyle = [styles.toaster, { bottom: GetBottomOffset() }];

    return (
        <View style={toasterStyle} testID={props.testID}>
            {renderCurrentToast()}
        </View>
    );
};

Toaster.defaultProps = defaultProps;

export default observer(Toaster);

const styles = StyleSheet.create({
    toaster: {
        position: 'absolute',
        width: '100%',
        maxWidth: Platform.OS === 'web' ? 662 : '100%',
        marginRight: Platform.OS === 'web' ? SmarthubTheme.layout.GRIDINCREMENT * 2 : 0,
        alignSelf: 'center',
    },
});
