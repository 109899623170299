import * as React from 'react';
import { View, StyleSheet, TouchableWithoutFeedback, Image, Platform } from 'react-native';
import { Checkbox as RNPCheckbox } from 'react-native-paper';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import { logInfo } from 'utils/logging/Logger';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { RegularText, SmallText } from 'components/primitives/StyledText';
import { testProps } from 'components/ComponentTypes';
import { useEffect } from 'react';

interface InputCheckboxProps {
    testID?: string;
    accessibilityLabel?: string;
    accessibility?: string;
    style?: 'block' | 'small';
    label?: string;
    initialStatus?: 'checked' | 'unchecked';
    disabled?: boolean;
    onChange?: (checked: string) => void;
}

const defaultProps: InputCheckboxProps = {
    style: 'block',
    initialStatus: 'unchecked',
    disabled: false,
};

const InputCheckbox: React.FC<InputCheckboxProps> = (props: InputCheckboxProps) => {
    const [status, setStatus] = React.useState(props.initialStatus ? props.initialStatus : 'unchecked');

    const _handleChange = () => {
        if (!props.disabled) {
            const newStatus = status === 'checked' ? 'unchecked' : 'checked';

            setStatus(newStatus);

            logInfo(INFO_LOGGER, newStatus);

            if (props.onChange) {
                props.onChange(newStatus);
            }
        }
    };

    let containerStyle = {};

    if (props.style === 'block') {
        containerStyle =
            status === 'checked'
                ? [styles.inputCheckboxContainer, styles.inputCheckboxContainerChecked]
                : [styles.inputCheckboxContainer];
    } else {
        containerStyle =
            status === 'checked'
                ? [styles.inputCheckboxContainerSmall, styles.inputCheckboxContainerSmallChecked]
                : [styles.inputCheckboxContainerSmall];
    }

    useEffect(() => {
        setStatus(props.initialStatus || 'unchecked');
    }, [props.initialStatus]);

    return (
        <TouchableWithoutFeedback
            {...testProps('generic-checkbox-pressable')}
            onPress={() => {
                _handleChange();
            }}>
            <View testID={props.testID} style={containerStyle}>
                <View style={styles.inputCheckboxWrapper}>
                    <View style={styles.inputCheckbox}>
                        <View style={styles.inputCheckboxDefault}>
                            <RNPCheckbox
                                status={status}
                                disabled={props.disabled}
                                onPress={() => {
                                    _handleChange();
                                }}
                            />
                        </View>
                        {!props.disabled && status === 'unchecked' && (
                            <Image
                                testID={props.testID}
                                accessibilityLabel={props.accessibilityLabel}
                                style={styles.imageCheckbox}
                                source={require('../../../assets/images/checkbox.png')}
                            />
                        )}
                        {!props.disabled && status === 'checked' && (
                            <Image
                                testID={props.testID}
                                accessibilityLabel={props.accessibilityLabel}
                                style={styles.imageCheckbox}
                                source={require('../../../assets/images/checkbox-checked.png')}
                            />
                        )}
                        {props.disabled && status === 'unchecked' && (
                            <Image
                                testID={props.testID}
                                accessibilityLabel={props.accessibilityLabel}
                                style={styles.imageCheckbox}
                                source={require('../../../assets/images/checkbox-disabled.png')}
                            />
                        )}
                        {props.disabled && status === 'checked' && (
                            <Image
                                testID={props.testID}
                                accessibilityLabel={props.accessibilityLabel}
                                style={styles.imageCheckbox}
                                source={require('../../../assets/images/checkbox-checked-disabled.png')}
                            />
                        )}
                    </View>
                    {props.label && (
                        <View style={styles.inputCheckboxLabel}>
                            <RegularText {...testProps(props.label)}>{props.label}</RegularText>
                        </View>
                    )}
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
};

InputCheckbox.defaultProps = defaultProps;

export default InputCheckbox;

const styles = StyleSheet.create({
    inputCheckboxContainer: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: SmarthubTheme.layout.GRIDINCREMENT * 8,
        marginBottom: 8,
        borderWidth: 1,
        borderColor: SmarthubTheme.colors.lightestgrey,
    },
    inputCheckboxContainerSmall: {
        minHeight: 0,
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT,
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT * -3,
        borderWidth: 0,
        borderColor: SmarthubTheme.colors.transparent,
    },
    inputCheckboxContainerChecked: {
        borderColor: SmarthubTheme.colors.selectedborder,
        backgroundColor: SmarthubTheme.colors.selected,
    },
    inputCheckboxContainerSmallChecked: {
        borderColor: SmarthubTheme.colors.transparent,
        backgroundColor: SmarthubTheme.colors.transparent,
    },
    inputCheckboxWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    inputCheckboxDefault: {
        display: 'none',
    },
    inputCheckbox: {
        width: 24,
        height: 24,
        marginRight: SmarthubTheme.layout.GRIDINCREMENT * 2,
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT * 3,
        justifyContent: 'center',
    },
    imageCheckbox: {
        width: 24,
        height: 24,
    },
    inputCheckboxLabel: {
        flex: 1,
        paddingTop: 2,
    },
});
