import { useStore } from 'contexts/StoreContextProvider';
import { generateQueryKeyFromCmd, useCommandMutation, useCommandQuery } from 'queries/hooks/useCommandQuery';
import {
    createGetAwayTimesCommandV1,
    GetAwayTimesCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetAwayTimesCommandV1';
import {
    createGetAddEditAwayTimeDataCommandV1,
    GetAddEditAwayTimeDataCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetAddEditAwayTimeDataCommandV1';
import { ToastInterface } from 'components/primitives/Toaster';
import { useQueryClient } from 'react-query';
import { SmarthubAwayTimeV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/residency/SmarthubAwayTimeV1';
import {
    createDeleteAwayTimeCommandV1,
    DeleteAwayTimeCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/DeleteAwayTimeCommandV1';
import {
    createSubmitAwayTimeCommandV1,
    SubmitAwayTimeCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitAwayTimeCommandV1';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { StackNavigationProp } from '@react-navigation/stack';
import { GetAwayTimesResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetAwayTimesResultV1';
import { GetAddEditAwayTimeDataResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/GetAddEditAwayTimeDataResultV1';
import { SubmitAwayTimeResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/SubmitAwayTimeResultV1';
import { DeleteAwayTimeResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/residency/DeleteAwayTimeResultV1';

export const generateGetAwayTimesCmd = (propertyCd: string, residencyId: number) => {
    const cmd = createGetAwayTimesCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    return cmd;
};

export const generateGetAddEditAwayTimeDataCmd = (propertyCd: string, residencyId: number, awayTimeId: number) => {
    const cmd = createGetAddEditAwayTimeDataCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.residentAwayTimeId = awayTimeId;
    return cmd;
};

export const generateSubmitAddEditAwayTimeCmd = (
    propertyCd: string,
    residencyId: number,
    awayTime: SmarthubAwayTimeV1,
) => {
    const cmd = createSubmitAwayTimeCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.data = awayTime;
    return cmd;
};

export const generateDeleteAwayTimeCmd = (propertyCd: string, awayTimeId: number) => {
    const cmd = createDeleteAwayTimeCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residentAwayTimeId = awayTimeId;
    return cmd;
};

export const useGetAwayTimesList = () => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateGetAwayTimesCmd(sessionActiveResidency.propertyCd, sessionActiveResidency.residencyId);

    const queryOptions = {
        enabled: hasSessionActiveResidency,
    };

    return useCommandQuery<GetAwayTimesCommandV1, GetAwayTimesResultV1>(cmd, uiStore, undefined, queryOptions);
};

export const useGetAddEditAwayTimeData = (awayTimeId: number) => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

    const cmd = generateGetAddEditAwayTimeDataCmd(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        awayTimeId,
    );

    const queryOptions = {
        enabled: hasSessionActiveResidency,
    };

    return useCommandQuery<GetAddEditAwayTimeDataCommandV1, GetAddEditAwayTimeDataResultV1>(
        cmd,
        uiStore,
        undefined,
        queryOptions,
    );
};

export const useDeleteAwayTime = () => {
    const { userSessionStore, uiStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const queryClient = useQueryClient();

    return useCommandMutation<DeleteAwayTimeCommandV1, DeleteAwayTimeResultV1>(uiStore, {
        onSettled: () => {
            queryClient.invalidateQueries(
                generateQueryKeyFromCmd(
                    generateGetAwayTimesCmd(sessionActiveResidency.propertyCd, sessionActiveResidency.residencyId),
                ),
            );
        },
        onSuccess: () => {
            const toastInputs: ToastInterface = {
                message: 'Your away time has been deleted',
            };
            uiStore.addToast(toastInputs);
        },
    });
};

export const useSubmitAddEditAwayTime = (
    awayTime: SmarthubAwayTimeV1,
    navigation: StackNavigationProp<any>,
    formik: any,
) => {
    const { userSessionStore, uiStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;
    const { useDrawerNavigation } = useResponsiveContext();

    const isAwayTimesOnNavigationStack = IsNavigationRouteOnStack(navigationRoutes.moreRoutes.awayTimes);
    const isMoreOnNavigationStack = IsNavigationRouteOnStack(navigationRoutes.moreRoutes.more);

    const queryClient = useQueryClient();

    const showSuccessToast = (residentAwayTimeId: number) => {
        const toastInputs: ToastInterface = {
            toastType: 'standard',
            message: 'Your away time has been added',
            autoDismiss: true,
            autoDismissInterval: 5000,
        };
        if (residentAwayTimeId !== 0) {
            toastInputs.message = 'Changes saved';
        }
        uiStore.addToast(toastInputs);
    };

    return useCommandMutation<SubmitAwayTimeCommandV1, SubmitAwayTimeResultV1>(uiStore, {
        onSettled: () => {
            queryClient.invalidateQueries(
                generateQueryKeyFromCmd(
                    generateGetAwayTimesCmd(sessionActiveResidency.propertyCd, sessionActiveResidency.residencyId),
                ),
            );
        },
        onSuccess: (responseResult: SubmitAwayTimeResultV1, responseCommand) => {
            showSuccessToast(responseCommand.data.residentAwayTimeId);
            if (useDrawerNavigation) {
                if (Number(awayTime.residentAwayTimeId) === 0 && !isMoreOnNavigationStack) {
                    navigation.reset({
                        routes: [
                            { name: navigationRoutes.moreRoutes.more },
                            { name: navigationRoutes.moreRoutes.awayTimes },
                        ],
                        index: 1,
                    });
                } else {
                    navigation.navigate(navigationRoutes.moreRoutes.awayTimes);
                }
            } else {
                isAwayTimesOnNavigationStack
                    ? navigation.navigate(navigationRoutes.moreRoutes.awayTimes)
                    : navigation.navigate(navigationRoutes.moreRoutes.more, {
                          screen: navigationRoutes.moreRoutes.awayTimes,
                      });
            }
            formik.resetForm();
        },
    });
};
