import { useCommandQuery } from 'queries/hooks/useCommandQuery';
import { QueryKeys } from 'queries/QueryKeys';
import { UIStore } from 'stores/domain/UIStore';
import {
    createGetSmartmoveSecurityOptionsCommandV1,
    GetSmartmoveSecurityOptionsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveSecurityOptionsCommandV1';
import { GetSmartmoveSecurityOptionsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/smartmove/GetSmartmoveSecurityOptionsResultV1';

export const useSecurityOptionsQuery = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    hasSessionActiveResidency: boolean,
    uiStore: UIStore,
) => {
    // Set query key
    const queryKey = [QueryKeys.SecurityOptions, propertyCd, residencyId];

    const cmd = createGetSmartmoveSecurityOptionsCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;

    const queryResult = useCommandQuery<GetSmartmoveSecurityOptionsCommandV1, GetSmartmoveSecurityOptionsResultV1>(
        cmd,
        uiStore,
        queryKey,
        {
            enabled: hasSessionActiveResidency,
        },
    );

    return { securityQueryKey: queryKey, securityQueryResult: queryResult };
};
