import { GetAppVersionResultV1 } from './GetAppVersionResultV1';
import { AbstractVersionedRemoteCommand } from '../../../remotecmd/versioned/AbstractVersionedRemoteCommand';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.residency.GetAppVersionCommandV1
 */
export type GetAppVersionCommandV1 = AbstractVersionedRemoteCommand<GetAppVersionResultV1>;

export function createGetAppVersionCommandV1(): GetAppVersionCommandV1 {
    return {
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.residency.GetAppVersionCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
