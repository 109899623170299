import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { globalStyles } from 'theme/style/GlobalStyles';
import { StyleSheet, View } from 'react-native';
import { SmallBoldText, SmallText } from 'components/primitives/StyledText';
import { formatPhoneNo } from 'utils/StringUtils';
import FlexContainer from 'components/primitives/FlexContainer';
import FlexItem from 'components/primitives/FlexItem';
import Button from 'components/primitives/Button';
import Dialog from 'components/primitives/Dialog';
import React from 'react';
import { observer } from 'mobx-react-lite';

type PhoneConfirmDialogProps = {
    isPrimary: boolean;
    phoneNumber: string;
    onConfirm: () => void;
    onCancel?: () => void;
};

const PhoneConfirmDialog: React.FC<PhoneConfirmDialogProps> = observer((props: PhoneConfirmDialogProps) => {
    return (
        <Dialog visible={true}>
            <MaterialCommunityIcons
                name='phone'
                size={SmarthubTheme.layout.ICONWIDTH}
                color={SmarthubTheme.colors.orange}
                style={globalStyles.doubleSpaceBelow}
            />
            <View style={styles.dialogBody}>
                <SmallText>{`You have elected to change your ${
                    props.isPrimary ? 'primary' : 'secondary'
                } phone number to:`}</SmallText>
                <SmallBoldText style={[globalStyles.singleSpaceAbove, globalStyles.singleSpaceBelow]}>
                    {formatPhoneNo(props.phoneNumber)}
                </SmallBoldText>
                <SmallText>
                    Please carefully check this phone number and confirm that it belongs to you. You may be asked to log
                    back in.
                </SmallText>
            </View>
            <FlexContainer style={styles.fieldRow}>
                {props.onCancel && (
                    <FlexItem>
                        <Button style={styles.dialogLeftButton} buttonType={'secondary'} onClick={props.onCancel}>
                            Cancel
                        </Button>
                    </FlexItem>
                )}

                <FlexItem>
                    <Button style={styles.dialogRightButton} onClick={props.onConfirm}>
                        Yes, confirm
                    </Button>
                </FlexItem>
            </FlexContainer>
        </Dialog>
    );
});

export default PhoneConfirmDialog;

const styles = StyleSheet.create({
    fieldRow: {
        flexWrap: 'wrap',
        paddingBottom: SmarthubTheme.layout.GRIDINCREMENT * 2,
    },
    dialogBody: {
        marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 4,
    },
    dialogLeftButton: {
        marginRight: SmarthubTheme.layout.GRIDINCREMENT,
    },
    dialogRightButton: {
        marginLeft: SmarthubTheme.layout.GRIDINCREMENT,
    },
});
