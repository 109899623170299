import { DrawerContentComponentProps, DrawerItem } from '@react-navigation/drawer';
import React, { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { useResponsiveContext } from 'contexts/ResponsiveContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { useTheme } from 'react-native-paper';

const CustomDrawerIcon = (
    children: (props: { focused: boolean; size: number; color: string }) => React.ReactNode,
): ((props: { focused: boolean; size: number; color: string }) => ReactNode) => {
    // eslint-disable-next-line react/display-name
    return (props: { focused: boolean; size: number; color: string }): ReactNode => (
        <View style={customDrawerStyles.iconWrapper}>{children(props)}</View>
    );
};

const CustomDrawerItem = (
    props: DrawerContentComponentProps & {
        route: string;
        label: string | ((props: { focused: boolean; color: string }) => React.ReactNode);
        icon?: ((props: { focused: boolean; size: number; color: string }) => React.ReactNode) | undefined;
        onPress?: () => void | undefined;
    },
) => {
    const fullExperience = useResponsiveContext().useFullExperience;
    const theme = useTheme();
    const { routes, index } = props.state;
    const { label, icon, navigation, route, onPress } = props;
    return (
        <View
            style={
                !label && !fullExperience ? customDrawerStyles.iconOnlyDrawerItemContainer : customDrawerStyles.nopad
            }>
            <DrawerItem
                {...props}
                labelStyle={SmarthubTheme.typography.heading3}
                activeBackgroundColor={theme.colors.background}
                activeTintColor={theme.colors.lightblue}
                inactiveTintColor={theme.colors.text}
                focused={(route && routes[index].name === route) || false}
                label={label}
                icon={!label && !fullExperience ? (icon ? CustomDrawerIcon(icon) : undefined) : icon}
                onPress={
                    (onPress && onPress) ||
                    (() => {
                        route &&
                            navigation.reset({
                                routes: [{ name: route }],
                            });
                    })
                }
                style={customDrawerStyles.drawerItemContainer}
            />
        </View>
    );
};

export default CustomDrawerItem;

const iconOnlyContainerHeight = SmarthubTheme.layout.GRIDINCREMENT * 8;
const iconTextContainerHeight = SmarthubTheme.layout.GRIDINCREMENT * 5.5;
export const customDrawerStyles = StyleSheet.create({
    iconOnlyDrawerItemContainer: {
        height: iconOnlyContainerHeight,
    },
    nopad: {
        height: iconTextContainerHeight,
        paddingLeft: SmarthubTheme.layout.INNERPAD,
        alignContent: 'flex-start',
    },
    drawerItemContainer: {
        height: '100%',
    },
    iconWrapper: {
        height: iconOnlyContainerHeight,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
