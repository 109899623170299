import {
    generateInfiniteQueryKeyFromCmd,
    generateQueryKeyFromCmd,
    getCmdClass,
    useCommandInfiniteQuery,
    useCommandMutation,
    useCommandQuery,
} from 'queries/hooks/useCommandQuery';
import { useStore } from 'contexts/StoreContextProvider';
import {
    createGetSmarthubNotificationsCommandV1,
    GetSmarthubNotificationsCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/notifications/GetSmarthubNotificationsCommandV1';
import { SmarthubNotificationV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationV1';
import { logError, logInfo } from 'utils/logging/Logger';
import { ERROR_LOGGER, INFO_LOGGER } from 'utils/logging/Loggers';
import {
    createMarkSmarthubNotificationsSeenCommandV1,
    MarkSmarthubNotificationsSeenCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/notifications/MarkSmarthubNotificationsSeenCommandV1';
import { SmarthubNotificationTypeV1 } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/notifications/SmarthubNotificationTypeV1';
import { InfiniteData, QueryKey, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { setItem } from 'utils/storage';
import { STORAGE_KEY_LEASE_NOTIFICATIONS_SEEN } from 'utils/storage-keys';
import { useEffect } from 'react';
import { arrayIsEmpty } from 'utils/ArrayUtils';
import {
    createMarkSmarthubNotificationsSeenByTypeCommandV1,
    MarkSmarthubNotificationsSeenByTypeCommandV1,
} from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/notifications/MarkSmarthubNotificationsSeenByTypeCommandV1';
import { GetSmarthubNotificationsResultV1 } from 'models/remotecmds/com/ocs/nirvana/externalversionedremotecmd/notifications/GetSmarthubNotificationsResultV1';
import { useGetFromLocalStorageQuery } from 'utils/hooks/useGetFromLocalStorageQuery';
import { useIsFocused } from '@react-navigation/native';

/* *************************
Known notification issues that aren't worth optimizing for.
    1. Mark lease notifications seen will not update the notifications list until it refreshes
    by navigating away from the notifications screen then back to it (or by tabbing away then back in).
    2. Mark lease notifications seen only works on the local device, not between devices.
************************* */

const getLeaseNotificationsQueryKey = 'getLocalLeaseNotifications';

/************* Read hooks ***************/
const useGetSeenLeaseNotificationIdsFromLocalStorageQuery = (
    queryOptions?: UseQueryOptions<unknown, unknown, string[]>,
): UseQueryResult<string[]> => {
    const queryKey = [getLeaseNotificationsQueryKey];
    return useGetFromLocalStorageQuery<string[]>(
        STORAGE_KEY_LEASE_NOTIFICATIONS_SEEN,
        queryKey,
        ids => {
            return ids?.split('|') || ([] as string[]);
        },
        queryOptions,
    );
};

export const useSmarthubNotifications = (enabled = true) => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const isFocused = useIsFocused();
    const cmd = generateGetNotificationsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
    );

    const getLocalSeenLeaseNotifications = useGetSeenLeaseNotificationIdsFromLocalStorageQuery();

    const queryKey = generateQueryKeyFromCmd(cmd);
    return useCommandQuery<GetSmarthubNotificationsCommandV1, GetSmarthubNotificationsResultV1>(
        cmd,
        uiStore,
        queryKey,
        {
            enabled: enabled && hasSessionActiveResidency && getLocalSeenLeaseNotifications.isSuccess && isFocused,
            onSuccess: (data: GetSmarthubNotificationsResultV1) => {
                data.notifications.forEach(notification => {
                    if (
                        getLocalSeenLeaseNotifications.data &&
                        getLocalSeenLeaseNotifications.data?.some(
                            id => Number(id) !== 0 && Number(id) === notification.notificationId,
                        )
                    ) {
                        notification.seenYn = true;
                    }
                });
            },
        },
    );
};

export const useSmarthubNotificationsPaginated = (rowLimit = notificationsRowLimit, startIndex = 0) => {
    const { userSessionStore, uiStore } = useStore();
    const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;
    const isFocused = useIsFocused();
    const cmd = generateGetNotificationsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
        rowLimit,
        startIndex,
    );

    const getLocalSeenLeaseNotifications = useGetSeenLeaseNotificationIdsFromLocalStorageQuery();

    const queryKey = generateInfiniteQueryKeyFromCmd(cmd);

    const query = useCommandInfiniteQuery<
        GetSmarthubNotificationsCommandV1,
        { notifications: Array<SmarthubNotificationV1> },
        SmarthubNotificationV1
    >(cmd, uiStore, rowLimit, 'notifications', queryKey, {
        enabled: hasSessionActiveResidency && getLocalSeenLeaseNotifications.isSuccess && isFocused,
    });

    useAlterInfiniteQueryDataForLeaseNotifications(query.data, queryKey);

    return { notificationsQuery: query, notificationsQueryKey: queryKey };
};

/************ Mutations ****************/

export const useMarkNotificationSeenMutation = (type: SmarthubNotificationTypeV1, notificationRefId?: number) => {
    const { uiStore, userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;

    const notificationsCmd = generateGetNotificationsCMD(
        sessionActiveResidency.propertyCd,
        sessionActiveResidency.residencyId,
        sessionActiveResidency.residentId,
    );
    const notificationsQueryKey = [getCmdClass(notificationsCmd)];

    const queryClient = useQueryClient();

    return useCommandMutation<MarkSmarthubNotificationsSeenCommandV1, unknown>(uiStore, {
        onError: e => {
            logError(ERROR_LOGGER, 'Error marking notification as seen:   ', JSON.stringify(e));
        },
        onSuccess: () => {
            logInfo(INFO_LOGGER, 'Mark notification seen; type:' + type + ' id:' + (notificationRefId || 'none'));
            queryClient.invalidateQueries(notificationsQueryKey);
        },
    });
};

export const useMarkNotificationSeenInComponent = (
    notificationId: number,
    notificationType: SmarthubNotificationTypeV1,
) => {
    const { userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;

    const notifications = useSmarthubNotifications(false).data?.notifications || [];

    const markNotificationAsSeen = useMarkNotificationSeenMutation(notificationType, notificationId);

    useEffect(() => {
        const unseenNotifs = getUnseenNotifications(notifications);
        const matchingNotifications: SmarthubNotificationV1[] = getMatchingUnseenNotifications(
            unseenNotifs,
            notificationType,
            notificationId,
        );

        if (!sessionActiveResidency.adminYn && !arrayIsEmpty(matchingNotifications)) {
            markNotificationAsSeen.mutate(
                generateMarkNotificationAsSeenCMD(
                    sessionActiveResidency.propertyCd,
                    sessionActiveResidency.residencyId,
                    sessionActiveResidency.residentId,
                    matchingNotifications,
                ),
            );
        }
    }, [notificationId]);
};

export const useMarkLeaseNotificationSeenInLocalStorage = async (leaseNotificationId: number) => {
    const { userSessionStore } = useStore();
    const { sessionActiveResidency } = userSessionStore;

    const queryClient = useQueryClient();

    const newestNotificationsQueryKey = generateQueryKeyFromCmd(
        generateGetNotificationsCMD(
            sessionActiveResidency.propertyCd,
            sessionActiveResidency.residencyId,
            sessionActiveResidency.residentId,
        ),
    );

    useGetSeenLeaseNotificationIdsFromLocalStorageQuery({
        onSuccess: data => {
            if (data.some(id => id === leaseNotificationId.toString())) {
                return;
            }
            let seenNotifications = data.join('|');
            //store in local storage for later...
            if (!seenNotifications) {
                seenNotifications = leaseNotificationId + '';
            } else {
                seenNotifications = seenNotifications + '|' + leaseNotificationId;
            }

            setItem(STORAGE_KEY_LEASE_NOTIFICATIONS_SEEN, seenNotifications).finally(() => {
                queryClient.setQueryData<GetSmarthubNotificationsResultV1 | undefined>(
                    newestNotificationsQueryKey,
                    oldNotifications => {
                        if (oldNotifications) {
                            oldNotifications.notifications.forEach((notification: SmarthubNotificationV1) => {
                                if (notification.notificationId == leaseNotificationId) {
                                    notification.seenYn = true;
                                }
                            });
                            oldNotifications.notifications = [...oldNotifications.notifications];
                            return { ...oldNotifications };
                        }
                    },
                );
            });
        },
    });
};

export const useMarkNotificationTypeAsSeenMutation = (type: SmarthubNotificationTypeV1) => {
    const { uiStore } = useStore();

    const cmd = createGetSmarthubNotificationsCommandV1();

    const notificationsInvalidateListQueryKey = [getCmdClass<GetSmarthubNotificationsCommandV1>(cmd)];
    const queryClient = useQueryClient();

    return useCommandMutation<MarkSmarthubNotificationsSeenByTypeCommandV1, unknown>(uiStore, {
        onError: e => {
            logError(ERROR_LOGGER, 'Error marking notification type as seen:   ', JSON.stringify(e));
        },
        onSuccess: () => {
            logInfo(INFO_LOGGER, 'Mark notification type seen; type:' + type);
        },
        onSettled: () => {
            queryClient.invalidateQueries(notificationsInvalidateListQueryKey);
        },
    });
};

/************ Helper functions ****************/
// Unfortunately this relies on the use effect so must remain a hook :(
const useAlterInfiniteQueryDataForLeaseNotifications = (
    data: InfiniteData<{ data: SmarthubNotificationV1[]; nextStartingIndex: number }> | undefined,
    notificationsQueryKey: QueryKey,
) => {
    const queryClient = useQueryClient();
    const getSeenLeaseNotificationsQueryKey = [getLeaseNotificationsQueryKey];
    useEffect(() => {
        if (data) {
            const newPagesArray = [
                {
                    data: [] as Array<SmarthubNotificationV1>,
                    nextStartingIndex: 0,
                },
            ];
            // Map through each page and concat the data into the newPagesArray[0].data
            data.pages.map((page: { data: Array<SmarthubNotificationV1>; nextStartingIndex: number }) => {
                newPagesArray[0].data = [...newPagesArray[0].data.concat(page.data)];
                newPagesArray[0].nextStartingIndex = page.nextStartingIndex;
            });

            const seenLeasedIds: string[] =
                queryClient.getQueryData(getSeenLeaseNotificationsQueryKey) || ([] as string[]);
            newPagesArray[0].data.forEach(notification => {
                if (
                    seenLeasedIds.some(id => notification.notificationId.toString() === id) &&
                    notification.seenYn === false
                ) {
                    notification.seenYn = true;
                }
            });
            queryClient.setQueryData(notificationsQueryKey, () => ({
                pages: newPagesArray,
            }));
        }
    }, [data]);
};

export const getUnseenNotifications = (
    allNotifications: Array<SmarthubNotificationV1>,
): Array<SmarthubNotificationV1> => {
    return allNotifications.slice().filter(n => !n.seenYn);
};

//5 - helper method to return boolean to show or hide red dot based on type
export const typeHasUnseenNotifications = (
    type: SmarthubNotificationTypeV1,
    allNotifications: SmarthubNotificationV1[],
): boolean => {
    return getUnseenNotifications(allNotifications).some(n => n.noticeType === type);
};
export const numberOfNotificationsOfType = (
    type: SmarthubNotificationTypeV1,
    allNotifications: SmarthubNotificationV1[],
): number => {
    return allNotifications.filter(n => n.noticeType === type).length;
};
export const typesHaveUnseenNotifications = (
    types: Array<SmarthubNotificationTypeV1>,
    allNotifications: SmarthubNotificationV1[],
): boolean => {
    return types && types.some(type => typeHasUnseenNotifications(type, allNotifications));
};
export const hasUnseenNotificationForTypeAndId = (
    types: Array<SmarthubNotificationTypeV1>,
    notificationRefId: number,
    allNotifications: SmarthubNotificationV1[],
): boolean => {
    const unseenNotifs = getUnseenNotifications(allNotifications);
    return (
        unseenNotifs &&
        unseenNotifs.some(
            notification =>
                types.some(type => type === notification.noticeType) && notification.noticeRefId === notificationRefId,
        )
    );
};
export const getMatchingUnseenNotifications = (
    unseenNotifications: Array<SmarthubNotificationV1>,
    type: SmarthubNotificationTypeV1,
    notificationRefId: number,
): Array<SmarthubNotificationV1> => {
    return unseenNotifications.filter(
        notification =>
            notification.noticeType === type &&
            !notification.seenYn && //only if not already marked seen
            ((!notificationRefId && (type === 'CommunityNotice' || type === 'ElectronicContract')) ||
                notification.noticeRefId === notificationRefId),
    );
};

/************ Cmd generators ****************/
export const notificationsRowLimit = 10;

export const generateGetNotificationsCMD = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    rowLimit = -1,
    startIndex = -1,
) => {
    const cmd = createGetSmarthubNotificationsCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.residentId = residentId;
    cmd.startIndex = startIndex;
    cmd.rowLimit = rowLimit;
    return cmd;
};

export const generateMarkNotificationAsSeenCMD = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    matchingNotifications: Array<SmarthubNotificationV1>,
) => {
    const cmd = createMarkSmarthubNotificationsSeenCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residencyId = residencyId;
    cmd.residentId = residentId;
    cmd.noticeIds = matchingNotifications.map(notifcation => notifcation.noticeId);
    return cmd;
};

export const generateMarkNotificationsAsSeenByTypeCMD = (
    propertyCd: string,
    residencyId: number,
    residentId: number,
    notificationType: SmarthubNotificationTypeV1,
) => {
    const cmd = createMarkSmarthubNotificationsSeenByTypeCommandV1();
    cmd.propertyCd = propertyCd;
    cmd.residentId = residentId;
    cmd.residencyId = residencyId;
    cmd.smarthubNotificationType = notificationType;
    return cmd;
};
