import { SmarthubRequestType } from '../../businesslogic/smarthub/dataobjects/SmarthubRequestType';
import { AbstractRequestAuthenticatedCommand } from '../AbstractRequestAuthenticatedCommand';
import { SendRequestMessageResultV1 } from './SendRequestMessageResultV1';

/* START CUSTOM IMPORTS */
/* END CUSTOM IMPORTS */

/**
 * Auto generated TypeScript representation of server class
 *
 *    com.ocs.nirvana.server.externalversionedremotecmd.requests.SendRequestMessageCommandV1
 */
export interface SendRequestMessageCommandV1 extends AbstractRequestAuthenticatedCommand<SendRequestMessageResultV1> {
    requestType: SmarthubRequestType;
    message: string;
}

export function createSendRequestMessageCommandV1(): SendRequestMessageCommandV1 {
    return {
        requestType: 'MaintenanceRequest',
        message: '',
        propertyCd: '',
        requestId: 0,
        expoPushToken: '',
        activePropertyCd: '',
        refreshToken: '',
        _cmdSvrClass: 'com.ocs.nirvana.server.externalversionedremotecmd.requests.SendRequestMessageCommandV1',
    };
}

/* START CUSTOM CODE */
/* END CUSTOM CODE */
