import React, { useEffect, useState } from 'react';
import { LoggedInNavigator, NotLoggedInNavigator } from 'navigation/stacks/RootNavigation';
import { getAuth, User as FirebaseUser } from 'firebase/auth';

import { useAuthentication } from 'contexts/AuthContextProvider';
import { useStore } from 'contexts/StoreContextProvider';
import { logInfo } from 'utils/logging/Logger';
import { INFO_LOGGER } from 'utils/logging/Loggers';
import useIsForeground from 'utils/useIsForeground';
import { observer } from 'mobx-react-lite';

export const AuthGuard: React.FC = observer(() => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const { user, setUser } = useAuthentication();
    const { userSessionStore, uiStore, appStore } = useStore();
    const hasComeToForeground = useIsForeground();
    const auth = getAuth();

    const onAuthStateChangedFn = (firebaseUser: FirebaseUser | null) => {
        logInfo(INFO_LOGGER, 'AUTHGUARD // onAuthStateChanged', JSON.stringify(firebaseUser));
        uiStore.setAuthenticationReady(true);
        setAuthenticated(!!firebaseUser);
        if (firebaseUser) {
            //update user in auth context
            setUser(firebaseUser);
            userSessionStore.setUserFromFirebaseUser(firebaseUser);
        } else {
            if (uiStore.showLoginEmailMissing) {
                uiStore.showAlert({
                    message: 'Please open the sign-in link in the same browser that you initiated your login.',
                });
            }
            //clear user from auth context
            setUser(null);
            userSessionStore.setUserFromFirebaseUser(null);
        }

        uiStore.setShowLoginEmailMissing(false);
    };

    useEffect(() => {
        setAuthenticated(false);
        auth.onAuthStateChanged(onAuthStateChangedFn);
        return;
    }, []); //leave this deps array empty!

    useEffect(() => {
        //doesn't work in dev
        appStore.checkForUpdate();
    }, [hasComeToForeground]); //leave this deps array empty!

    if (isAuthenticated) {
        return <LoggedInNavigator />;
    } else {
        return <NotLoggedInNavigator />;
    }
});

export default AuthGuard;
