import React from 'react';
import { StyleSheet } from 'react-native';
import { observer } from 'mobx-react-lite';
import FlexContainer from 'components/primitives/FlexContainer';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import FlexItem from 'components/primitives/FlexItem';
import { SmallBoldText, SmallText } from 'components/primitives/StyledText';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import Container from 'components/primitives/Container';
import { v4 as uuid } from 'uuid';
import { useMoveInCharges } from 'queries/hooks/smartmove/useMoveInCharges';
import { useStore } from 'contexts/StoreContextProvider';
import { SmartmoveMoveInCharge } from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/smartmove/SmartmoveMoveInCharge';

type SmartMoveMoveInChargesBreakdownProps = {
    restricted?: boolean;
};

const defaultProps: SmartMoveMoveInChargesBreakdownProps = {
    restricted: false,
};

const SmartMoveMoveInChargesBreakdown: React.FC<SmartMoveMoveInChargesBreakdownProps> = observer(
    (props: SmartMoveMoveInChargesBreakdownProps) => {
        const { userSessionStore, uiStore } = useStore();
        const { hasSessionActiveResidency, sessionActiveResidency } = userSessionStore;

        ///move in charges
        const moveInChargesQuery = useMoveInCharges(
            sessionActiveResidency.propertyCd,
            sessionActiveResidency.residencyId,
            hasSessionActiveResidency,
            uiStore,
        );
        const moveInCharges: Array<SmartmoveMoveInCharge> | undefined = moveInChargesQuery.data?.moveInCharges;

        const total =
            (moveInCharges &&
                moveInCharges.reduce((prev, curr) => {
                    return prev + curr.amount;
                }, 0)) ||
            0;

        return !moveInCharges ? null : (
            <Container dropShadow={true} borderStyle={'grey'} style={styles.itemsContainer}>
                {moveInCharges.map(charge => (
                    <FlexContainer key={uuid()} style={{ marginBottom: SmarthubTheme.layout.GRIDINCREMENT * 4 }}>
                        <FlexItem>
                            <SmallText>{charge.description}</SmallText>
                        </FlexItem>
                        <FlexItem alignItems={'flex-end'}>
                            <SmallText>{formatCentsAsDollars(charge.amount)}</SmallText>
                        </FlexItem>
                    </FlexContainer>
                ))}
                <FlexContainer>
                    <FlexItem>
                        <SmallBoldText>Total {props.restricted ? '(Due in certified funds)' : ''}</SmallBoldText>
                    </FlexItem>
                    <FlexItem alignItems={'flex-end'}>
                        <SmallBoldText>{formatCentsAsDollars(total)}</SmallBoldText>
                    </FlexItem>
                </FlexContainer>
            </Container>
        );
    },
);

SmartMoveMoveInChargesBreakdown.defaultProps = defaultProps;

export default SmartMoveMoveInChargesBreakdown;

const styles = StyleSheet.create({
    itemsContainer: {
        padding: SmarthubTheme.layout.INNERPAD,
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN * 3,
    },
});
