//https://callstack.github.io/react-native-paper/theming.html
//https://reactnavigation.org/docs/themes/
//https://callstack.github.io/react-native-paper/theming-with-react-navigation.html

import { DarkTheme as NavigationDarkTheme, DefaultTheme as NavigationDefaultTheme } from '@react-navigation/native';
import { configureFonts, DarkTheme as PaperDarkTheme, DefaultTheme as PaperDefaultTheme } from 'react-native-paper';
import merge from 'deepmerge';
import { Theme as ReactNavigationTheme } from '@react-navigation/native/lib/typescript/src/types';
import { SmarthubTypography } from './typography/SmarthubTypography';
import { FontConfig } from './typography/FontConfig';
import { SmarthubLayout } from 'theme/layout/SmarthubLayout';

export const CombinedDefaultTheme = merge(PaperDefaultTheme, NavigationDefaultTheme);
export const CombinedDarkTheme = merge(PaperDarkTheme, NavigationDarkTheme);

// Add new typescript properties to the theme
declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace ReactNativePaper {
        interface ThemeColors {
            //PRIMARY
            navy: string;
            mediumblue: string;
            tealblue: string;

            //ACCENTS
            orange: string;
            lightblue: string;
            yellow: string;
            green: string;

            //NEUTRALS
            darkgrey: string;
            termsgrey: string;
            lightgrey: string;
            lightestgrey: string;
            white: string;

            //SPECIAL
            lightorange: string;
            orangeinactive: string;
            fadedblue: string;
            red: string;
            google: string;
            selected: string;
            selectedborder: string;
            dropshadow: string;
            icon: string;
            paymentbackground: string;

            black: string;
            transparent: string;

            //TAG BACKGROUND COLORS
            tagRedFaded: string;
            tagYellowFaded: string;
            tagGreenFaded: string;
            tagBlueFaded: string;
            tagDarkGreyFaded: string;
        }

        interface Theme {
            layout: typeof SmarthubLayout;
            typography: typeof SmarthubTypography;
        }
    }
}

export const SmarthubTheme: ReactNavigationTheme & ReactNativePaper.Theme = {
    ...CombinedDefaultTheme,
    dark: false,
    roundness: 0,

    //COLOR PALETTE (Ref: https://projects.invisionapp.com/d/main?origin=v7#/console/20651922/434104359/preview?scrollOffset=0)
    colors: {
        ...CombinedDefaultTheme.colors,
        //override paper...
        primary: '#14284B',
        accent: '#000',
        background: '#FFF',
        surface: '#FFF',
        text: '#444444',
        disabled: '#DAD8D6',
        placeholder: '#FFF',
        backdrop: '#FFF',
        notification: '#FF0000',

        //override react navigation
        card: '#FFF',
        border: '#dad8d6',

        //our own custom colors...

        //PRIMARY
        navy: '#14284B',
        mediumblue: '#124A86',
        tealblue: '#005C86',

        //ACCENTS
        orange: '#FF6600',
        lightblue: '#0094D5',
        yellow: '#F7981C',
        green: '#2CB900',

        //NEUTRALS
        darkgrey: '#444444',
        termsgrey: '#99999A',
        lightgrey: '#DAD8D6',
        lightestgrey: '#F1F0F0',
        white: '#FFFFFF',

        //SPECIAL
        lightorange: '#FFF3EB',
        orangeinactive: 'rgba(255, 102, 0, 0.25)',
        fadedblue: '#EEFAFF',
        red: '#FF0000',
        google: '#DB4437',
        selected: '#F0FAFF',
        selectedborder: '#0094D5',
        dropshadow: '#020202',
        icon: '#444444',
        paymentbackground: '#F4F4F4',

        //TAG BACKGROUND COLORS
        tagRedFaded: '#FFF2F1',
        tagYellowFaded: '#FFF3CC',
        tagGreenFaded: '#E7FFDF',
        tagBlueFaded: '#DFEEFF',
        tagDarkGreyFaded: '#444444',

        black: '#000000',
        transparent: 'transparent',
    },
    fonts: configureFonts(FontConfig as any),
    layout: SmarthubLayout,
    typography: SmarthubTypography,
};

export const SmarthubDarkTheme: ReactNavigationTheme & ReactNativePaper.Theme = {
    ...CombinedDarkTheme,
    ...SmarthubTheme,
    dark: true,

    colors: {
        ...CombinedDarkTheme.colors,
        ...SmarthubTheme.colors,

        //override just dark theme stuff...
        background: '#444444',
        surface: '#444444',
        text: '#FFF',
        placeholder: '#444444',
        card: '#444444',
        icon: '#FFF',
    },
};

export const portalTheme = {
    ...PaperDefaultTheme,
    backgroundColor: 'transparent',
};
