import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, TextInput, View } from 'react-native';
import {
    signOut,
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    sendEmailVerification,
} from 'firebase/auth';
import { useAuthentication } from 'contexts/AuthContextProvider';
import { useStore } from 'contexts/StoreContextProvider';
import { sendMagicLoginLink } from 'navigation/linking/DeepLinks';
import { ERROR_LOGGER } from 'utils/logging/Loggers';
import { logError } from 'utils/logging/Logger';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import Button from 'components/primitives/Button';
import InputString from 'components/input/InputString';
import FlexContainer from 'components/primitives/FlexContainer';
import { globalStyles } from 'theme/style/GlobalStyles';
import { testProps } from 'components/ComponentTypes';
import { LoginOptions } from 'components/auth/LoginOptions';

interface EmailPasswordLoginFormProp {
    initialEmail?: string;
    setLoginOption?: (value: LoginOptions) => void;
}

//Ref:https://www.youtube.com/watch?v=pNZks2j2Qaw
const EmailPasswordLoginForm: React.FC<EmailPasswordLoginFormProp> = (props: EmailPasswordLoginFormProp) => {
    const { setLoginOption, loginOption, user } = useAuthentication();
    const auth = getAuth();
    const [email, setEmail] = useState(props.initialEmail || '');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailIsValid, setEmailIsValid] = useState(false);
    const { uiStore } = useStore();
    const [isSubmitting, setIsSubmitting] = useState(false);

    let doButtonText = 'Sign in to SMARTHUB';

    const confirmEmailVerified = () => {
        if (!auth.currentUser?.emailVerified) {
            // @ts-ignore:next-line
            sendEmailVerification(auth.currentUser);
            signOut(auth);
            setLoginOption('email');
            uiStore.showAlert({
                title: 'Verify Your Email',
                message:
                    'Your email address has not been verified yet. Please click the link in the email we sent. You may need to check your spam folder.',
            });
        }
    };

    let doLoginOption: () => void = () => {
        signInWithEmailAndPassword(auth, email, password).then(
            () => {
                confirmEmailVerified();
            },
            error => {
                logError(ERROR_LOGGER, 'Problem Signing In', error.message);
                uiStore.showAlert({
                    title: 'Problem Signing In',
                    message: error.message,
                });
            },
        );
    };

    if (loginOption === 'signup') {
        doButtonText = 'Sign Up';
        doLoginOption = () => {
            if (password !== confirmPassword) {
                uiStore.showAlert({
                    title: 'Problem Signing Up',
                    message: "Passwords don't match",
                });
                return;
            }
            createUserWithEmailAndPassword(auth, email, password).then(
                user => {
                    confirmEmailVerified();
                },
                error => {
                    uiStore.showAlert({
                        title: 'Problem Signing Up',
                        message: error.message,
                    });
                },
            );
        };
    } else if (loginOption === 'forgotpassword') {
        doButtonText = 'Reset Password';
        doLoginOption = () => {
            if (!email) {
                uiStore.showAlert({
                    title: 'Problem Resetting Password',
                    message: 'You must enter a valid email',
                });
                return;
            }
            sendPasswordResetEmail(auth, email).then(
                user => {
                    uiStore.showAlert({
                        title: 'Reset Link Sent',
                        message:
                            'Please click the link in the email we just sent. You may need to check your spam folder.',
                    });
                    setLoginOption('email');
                },
                error => {
                    uiStore.showAlert({
                        title: 'Problem Resetting Password',
                        message: error.message,
                    });
                },
            );
        };
    }

    const doPasswordless = () => {
        if (!email) {
            uiStore.showAlert({
                title: 'Email missing',
                message: 'You must enter a valid email',
            });
        } else {
            uiStore.showActivityLoader();
            setIsSubmitting(true);

            sendMagicLoginLink(email, uiStore)
                .then(() => {
                    uiStore.showAlert({
                        title: 'Sign In Link Sent',
                        message: 'Please click the link we just emailed you. You may need to check your spam folder.',
                    });
                    uiStore.hideActivityLoader();
                    setIsSubmitting(false);
                    setEmail('');
                    setLoginOption('none');
                })
                .catch(reason => {
                    logError(ERROR_LOGGER, 'failed to email magic link because : ', reason.code + '/' + reason.message);
                    uiStore.showAlert({
                        title: 'Login failed',
                        message: reason.message,
                    });
                    uiStore.hideActivityLoader();
                    setIsSubmitting(false);
                    setEmail('');
                    setLoginOption('none');
                });
        }
    };

    const _isValidEmail = (email: string): boolean => {
        const regEx = /\S+@\S+\.\S+/;
        return regEx.test(email);
    };

    const _setAndValidateEmail = (email: string) => {
        if (_isValidEmail(email)) {
            setEmail(email);
            setEmailIsValid(true);
        } else {
            setEmailIsValid(false);
        }
    };

    useEffect(() => {
        if (email) {
            _setAndValidateEmail(email);
        }
    }, []);

    return (
        <View style={styles.loginForm}>
            <FlexContainer style={styles.fieldContainer}>
                <InputString
                    inputStyle={'transparent'}
                    initialValue={email}
                    onChange={email => {
                        _setAndValidateEmail(email);
                    }}
                    keyboardType={'email-address'}
                    placeholder={'Email'}
                    autoCapitalize={'none'}
                    autoFocus={Platform.OS === 'web'}
                    icon={{
                        iconID: 'check',
                        alignment: 'right',
                        color: emailIsValid ? SmarthubTheme.colors.orange : SmarthubTheme.colors.transparent,
                    }}
                    onSubmitEditing={() => doPasswordless()} //allows for submit on hit enter on the web
                />

                {/*{loginOption !== 'forgotpassword' && (
                    <TextInput
                        style={styles.emailPasswordInputs}
                        value={password}
                        onChangeText={(text) => {
                            setPassword(text);
                        }}
                        secureTextEntry={true}
                        placeholder={'Password'}
                        placeholderTextColor={SmarthubTheme.colors.white}
                    />
                )}*/}
                {loginOption === 'signup' && (
                    <TextInput
                        style={styles.emailPasswordInputs}
                        value={confirmPassword}
                        onChangeText={text => {
                            setConfirmPassword(text);
                        }}
                        secureTextEntry={true}
                        placeholder={'Confirm Password'}
                        placeholderTextColor={SmarthubTheme.colors.white}
                    />
                )}
            </FlexContainer>
            {/*<View style={styles.loginSubmitRow}>
                <TouchableOpacity style={styles.orangeButton} onPress={doLoginOption}>
                    <RegularText style={styles.buttonTextBold}>{doButtonText}</RegularText>
                </TouchableOpacity>
            </View>*/}

            <View
                style={[globalStyles.singleSpaceAbove, globalStyles.loginOptionButton]}
                {...testProps('sign-in-button')}>
                {/*{loginOption === 'email' && (
                    <TouchableOpacity style={styles.orangeButton} onPress={doPasswordless}>
                        <RegularText style={styles.buttonTextBold}>Email Sign In Link</RegularText>
                    </TouchableOpacity>
                )}*/}
                <Button
                    style={globalStyles.hundowidth}
                    onClick={doPasswordless}
                    inactive={!emailIsValid || isSubmitting}>
                    Sign in to SMARTHUB
                </Button>
            </View>
        </View>
    );
};

export default EmailPasswordLoginForm;

const styles = StyleSheet.create({
    loginForm: { width: '100%', alignSelf: 'center', alignItems: 'center' },
    emailPasswordInputs: {
        borderBottomWidth: 1.3,
        borderBottomColor: SmarthubTheme.colors.white,
        color: SmarthubTheme.colors.white,
        marginBottom: SmarthubTheme.layout.SURROUNDMARGIN,
        minHeight: 30,
        maxHeight: 30,
        backgroundColor: SmarthubTheme.colors.transparent,
        minWidth: 300,
        maxWidth: 300,
        alignSelf: 'center',
    },
    fieldContainer: {
        minWidth: 300,
        maxWidth: 300,
    },
});
