import React, { useEffect } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { Divider, useTheme } from 'react-native-paper';
import { useStore } from 'contexts/StoreContextProvider';
import { SmarthubTheme } from 'theme/SmarthubTheme';
import { DeephansizedRegularText, RegularText } from 'components/primitives/StyledText';
import { formatCentsAsDollars } from 'utils/CurrencyUtils';
import { formatDateStringMonthLabel, formatDateStringMonthLabelFromNumber } from 'utils/DateUtils';
import TransactionDetailsList from 'screens/home/transactions/TransactionDetailsList';
import StatementPDFLink from 'screens/home/transactions/StatementPDFLink';
import { MessageBalancePay } from 'components/cards/MessageBalancePay';
import { globalStyles } from 'theme/style/GlobalStyles';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { getRouteParam } from 'utils/routeUtils';
import { testProps } from 'components/ComponentTypes';
import {
    isStatementTransaction,
    SmarthubTransactionType,
} from 'models/remotecmds/com/ocs/nirvana/businesslogic/smarthub/versioneddataobjects/transactions/SmarthubTransactionType';
import { useGetTransaction } from 'queries/hooks/home/TransactionsHooks';
import { IsNavigationRouteOnStack, navigationRoutes } from 'utils/NavigationUtils';
import { useMarkNotificationSeenInComponent } from 'screens/home/requests/NotificationsHooks';

type StatementDetailParamList = {
    StatementDetail: {
        statementId: number;
        title: string;
    };
};
type StatementDetailScreenRouteProp = RouteProp<StatementDetailParamList, 'StatementDetail'>;
type StatementDetailProps = {
    navigation: StackNavigationProp<any, string>;
    route: StatementDetailScreenRouteProp;
};

const StatementDetail: React.FC<StatementDetailProps> = observer(({ navigation, route }: StatementDetailProps) => {
    const theme = useTheme();
    const { userSessionStore } = useStore();
    const { sessionActiveResidency, confirmActiveResidency, smarthubUser, hasSessionActiveResidency } =
        userSessionStore;
    const passedTransactionType: SmarthubTransactionType = getRouteParam(route, 'transactionType') || 'Statement';
    const passedStatementId: string = getRouteParam(route, 'referenceNumber') || '';

    const isTransactionsOnStack = IsNavigationRouteOnStack(navigationRoutes.homeRoutes.transactions);

    const query = useGetTransaction(passedStatementId, passedTransactionType);
    const transaction = query.data?.transaction;

    //mark notification seen
    useMarkNotificationSeenInComponent(Number(passedStatementId), 'Statement');

    useEffect(() => {
        //ensure transactions and home page are in the stack for back navigation
        if (hasSessionActiveResidency && !isTransactionsOnStack) {
            navigation.reset({
                routes: [
                    { name: navigationRoutes.homeRoutes.home },
                    { name: navigationRoutes.homeRoutes.transactions },
                    {
                        name: navigationRoutes.homeRoutes.statementDetail,
                        params: { referenceNumber: passedStatementId, transactionType: passedTransactionType },
                    },
                ],
                index: 2,
            });
        }
    }, [hasSessionActiveResidency]);

    useEffect(() => {
        confirmActiveResidency(navigation);
        if (query.data && transaction) {
            navigation.setOptions({ title: transaction.referenceNumber });
        }
    }, [smarthubUser.authorizedResidencies, query.data]);

    if (!query.data || !query.isSuccess || !hasSessionActiveResidency) return null;

    if (!transaction) {
        return <View>Statement not found</View>;
    }

    return (
        <ScrollView
            {...testProps('statement-title')}
            contentContainerStyle={[styles.container, { backgroundColor: theme.colors.background }]}>
            {isStatementTransaction(transaction.type) && (
                <>
                    <View style={styles.section} {...testProps('statement-date')}>
                        <DeephansizedRegularText style={styles.headingbottom}>Statement Date</DeephansizedRegularText>
                        <RegularText>
                            {formatDateStringMonthLabelFromNumber(transaction.transactionDtt) +
                                (!sessionActiveResidency.isEmployee
                                    ? ' (Payment due by ' + formatDateStringMonthLabel(transaction.dueDate) + ')'
                                    : '')}
                        </RegularText>
                    </View>
                    <View style={styles.section} {...testProps('statement-amount')}>
                        <DeephansizedRegularText style={styles.headingbottom}>Statement Amount</DeephansizedRegularText>
                        <RegularText>{formatCentsAsDollars(transaction.amount)}</RegularText>
                    </View>

                    <Divider style={globalStyles.doubleSpaceBelow} />

                    <TransactionDetailsList currentTransaction={transaction} />

                    <StatementPDFLink currentTransaction={transaction} />
                </>
            )}
            {transaction.type === 'FinalStatement' && (
                <>
                    <View style={styles.section}>
                        <DeephansizedRegularText style={styles.headingbottom}>Apartment</DeephansizedRegularText>
                        <RegularText>
                            {sessionActiveResidency.bldgCd +
                                '-' +
                                sessionActiveResidency.unitCd +
                                ' at ' +
                                sessionActiveResidency.propertyName}
                        </RegularText>
                    </View>
                    <View style={[styles.section, styles.datarow]}>
                        <View>
                            <DeephansizedRegularText style={styles.headingbottom}>Date Created</DeephansizedRegularText>
                            <RegularText>
                                {formatDateStringMonthLabelFromNumber(transaction.transactionDtt)}
                            </RegularText>
                        </View>
                        <View>
                            <DeephansizedRegularText style={styles.headingbottom}>
                                Balance Due From SODA
                            </DeephansizedRegularText>
                            <RegularText>{formatCentsAsDollars(transaction.amount)}</RegularText>
                        </View>
                    </View>

                    <StatementPDFLink currentTransaction={transaction} />

                    <MessageBalancePay balancePayOnly={true} />
                </>
            )}
        </ScrollView>
    );
});

export default StatementDetail;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: Platform.OS === 'web' ? 0 : SmarthubTheme.layout.GRIDINCREMENT * 2,
        padding: SmarthubTheme.layout.SURROUNDMARGIN * 2,
    },
    section: {
        marginBottom: SmarthubTheme.layout.SECTIONMARGIN,
    },
    headingbottom: {
        marginBottom: SmarthubTheme.layout.LINESPACE,
    },
    datarow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    sharedwidth: {
        flex: 1,
    },
});
